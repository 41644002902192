import { Button, Grid, Box } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react';
import '../../../Molecule/Elibrary/ElibraryRightContainer.css'
import '../../EbmKit/EbmKit.css';
import '../AppVault.css'
import '../MarketPlaceSidebar.css'
import axios from 'axios';
import URL from '../../../apiurl';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from 'react-redux';
import PrecisionManufacturingImage from '../../../../icons/Group 12191.svg'
import UpcomingImage from '../../../../icons/Icon material-event-available.svg'
import AppGalleryImage from '../../../../icons/Group 12105.svg'
import EventImage from '../../../../icons/Group 12122.svg'
import CertificateCourseImage from '../../../../icons/Group 12427.svg'
import HotvacanciesImage from '../../../../icons/Group 12429.svg'
import AllEventsData from './AllEventsData';
import UpComingData from '../UpComingData';
import CertificateData from './CertificateData';
import HotVacancyData from './HotVacancyData';
import { AppsView } from '../AppVault/appview';
import { getAllApps, getAppsByUserId } from '../../../../redux/Actions/connectActions';

function MedscopeHomepage() {

    const [data, setData] = useState([])
    const [apps, setApps] = useState([])

    // const allApps = useSelector(state => state.connect.allApps)
    const events = useSelector(state => state.userProfile.events)

    const appsbyuserId = useSelector(state => state.connect.appsbyuserId)
    console.log(appsbyuserId, 'ddddddddddd')

    const dispatch = useDispatch()
    const myRef = useRef(null)

    useEffect(() => {
        if (appsbyuserId.length === 0) {
            dispatch(getAppsByUserId())
        }
    }, [appsbyuserId])

    useEffect(() => {
        setApps(appsbyuserId)
    }, [appsbyuserId])

    useEffect(() => {
        axios.get(`${URL.elib}allSpecialities/`)
            .then((res) => {
                // console.log(res.data.data)
                setData(res.data.data)
            })
            .catch(error => { })


    }, [])



    return (
        <>

            {/* // <div ref={myRef} id="display_tablet" className='border_img_medscope'> */}
            <div ref={myRef} >
                < Helmet >
                    <title>Medscope | Docsteth</title>
                    <meta name="description" content="Market place" />
                    <meta name="canonical" content="https://www.docsteth.com/elib/specialities" />
                    <meta name="keyWords" content={` Docsteth, Hyderabad, EBM, EBM Practice Kit, EBM Practice, Specialities `} />
                    <meta name="keywords" content={data.map((data) => (data.speciality))} />
                </Helmet >
                <Box className="medscope_homepage_body " style={{ overflowX: 'hidden' }} sx={{ height: { md: '76vh', sm: 'auto', xs: 'auto' }, overflowY: { md: 'scroll', xs: 'scroll' } }}>
                    <div className='cardmain'>
                        <Grid container className='events' spacing={2}>
                            <Grid item xs={12} sm={6} md={6} className="cardmainmob">
                                <div className='cards'>
                                    <Card id='card_size' elevation={3} sx={{ borderRadius: "20px" }}>
                                        <div className='job_openings'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={UpcomingImage} alt="graduation" height="24px" width="24px" />
                                                <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>My Upcoming</Typography>
                                            </div>
                                        </div>
                                        <CardContent className='mui_remove_padding' >
                                            <UpComingData />
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className='cards'>
                                    <Card id='card_size' elevation={3} sx={{ borderRadius: "20px" }}>
                                        <div className='job_openings'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={EventImage} alt="calendar" height="24px" width="24px" />
                                                <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>Trending Events / Webinars</Typography>
                                            </div>
                                        </div>

                                        <CardContent className='mui_remove_padding'>

                                            <AllEventsData />

                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='cardmain' style={{ position: "relative" }}  >
                        <Grid item xs={12} sm={12} md={12} className="cardmainmob">
                            <div className='cards'>
                                <Card id='card_size' elevation={3} sx={{ borderRadius: "20px" }} >
                                    <div className='job_openings'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={AppGalleryImage} alt="graduation" height="24px" width="24px" />
                                            <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>My Apps</Typography>
                                        </div>
                                    </div>
                                    <CardContent className='mui_remove_padding' >
                                        <AppsView apps={appsbyuserId[0]?.myApps} />
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </div>
                    <div className='cardmain'  >
                        <Grid item xs={12} sm={12} md={12} className="cardmainmob">
                            <div className='cards'>
                                <Card id='card_size' elevation={3} sx={{ borderRadius: "20px" }} >
                                    <div className='job_openings'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={AppGalleryImage} alt="graduation" height="24px" width="24px" />
                                            <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>App Gallery</Typography>
                                        </div>
                                    </div>
                                    <CardContent className='mui_remove_padding' >
                                        <AppsView apps={appsbyuserId[0]?.suggestedApps} suggested heading="All Apps" />
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </div>
                    <div className='cardmain'>
                        <Grid container className='events' spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid container spacing={{ xs: 1, sm: 1, md: 3 }}>

                                    <Grid item xs={12} sm={12} md={12} className="cardmainmob">
                                        <div className='cards'>
                                            <Card id='card_size' elevation={3} sx={{ borderRadius: "20px" }} >
                                                <div className='job_openings'>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={CertificateCourseImage} alt="graduation" height="24px" width="24px" />
                                                        <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>Certificate Courses</Typography>
                                                    </div>
                                                </div>
                                                <CardContent className='mui_remove_padding' >

                                                    <CertificateData />
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='cards'>

                                            <Card id='card_size' elevation={3} sx={{ borderRadius: "20px" }}>
                                                <div className='job_openings'>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={HotvacanciesImage} alt="briefcase" height="24px" width="24px" />
                                                        <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>Hot Vacancies</Typography>
                                                    </div>
                                                </div>
                                                <CardContent className='mui_remove_padding' >

                                                    <HotVacancyData />

                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                <Card sx={{ height: '100%', borderRadius: "20px" }} >
                                    <div className='job_openings'>

                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <img src={PrecisionManufacturingImage} alt="precisionimage" height='25px' width='25px' />


                                            <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>Medical Device Gallery</Typography>

                                        </div>
                                    </div>

                                    <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
                                        <div className='job_title' style={{ padding: '5px', textAlign: 'center', paddingTop: '20px' }} >Coming Soon</div>
                                        {/* <Grid item md={6} >
                                        <div className='galleryItem'></div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className='galleryItem'></div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className='galleryItem'></div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className='galleryItem'></div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className='galleryItem'></div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className='galleryItem'></div>
                                    </Grid> */}
                                    </Grid>
                                </Card>
                            </Grid>

                        </Grid>


                    </div>

                    <div className='mobmedicalgallery'>
                        <Card sx={{ height: '100%', px: '10px' }} style={{ border: "1px solid #e6e6e6" }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <img src={PrecisionManufacturingImage} alt="precisionimage" height='25px' width='25px' />
                                <Typography variant='h3' id="job_opening_text" sx={{ color: "#001F88 !important" }}>Medical Device Gallery</Typography>

                            </div>
                            <p className='research_p_text' >Find latest technology innovations in Medical devices</p>

                            <ul className='mobgallery' >
                                <div className='job_title' style={{ padding: '5px', textAlign: 'center', paddingTop: '10px' }} >Coming Soon</div>
                                {/* <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li>
                            <li ><div className='mobgalleryitem'></div></li> */}
                            </ul>
                        </Card>
                    </div>

                </Box >
            </div >

        </>
    )
}
export default MedscopeHomepage;


