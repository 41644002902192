import React from "react"
import { FormControlLabel, Radio } from "@mui/material"
import { common } from '@mui/material/colors'

const CustomRB = ({ label, value, disabled }) => {
    return (
        <FormControlLabel value={value} disabled={disabled}
            control={
                <Radio sx={{
                    color: common.black,
                    '&.Mui-checked': {
                        color: common.black,
                    },
                    // '& .MuiSvgIcon-root': { fontSize: '17px' }
                }}
                />}
            label={label}
        />
    )
}

export default CustomRB