import React from "react";
import { Card, Grid, Button, Box } from "@mui/material";
import DefaultDoctorImg from "../../../icons/Userprofilelogo.svg";
import EditImg from "../../../../src/icons/edit_icon.svg"
import MessageIcon from "../../../icons/message1.svg";
import LanguageIcon from "../../../icons/language.svg";
import Stethoscope from "../../../icons/stethoscope2.svg";
import { useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";
const DoctorsInfoCard = ({ practitioner, practitionerRole, type, opnEdit, AllowEdit }) => {
    const history = useHistory();

    console.log(practitioner, "practitioner");
    console.log(practitionerRole, "practitionerRole")




    return (

        <Card sx={{
            padding: "10px", minwidth: "300px",
            height: "160px",
            position: "relative",

        }} className="mv1 cursor">


            <div style={{ width: "100%", display: "flex", alignItems: "", gap: "10px", border: "" }}>
                <div style={{
                    width: '75px', height: '70px', border: "3px solid white", borderRadius: "8px", boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    backgroundColor: "white"
                }}>
                    {
                        (practitioner?.resource?.photo?.[0]?.url &&
                            practitioner?.resource?.photo?.[0]?.url !== "undefined" &&
                            practitioner?.resource?.photo?.[0]?.url !== "null" &&
                            practitioner?.resource?.photo?.[0]?.url !== "") ?
                            <img
                                src={practitioner?.resource?.photo?.[0]?.url}
                                width="100%"
                                height="100%"
                                style={{ borderRadius: "5px", objectFit: "cover", objectPosition: "center" }}

                            />
                            :
                            (
                                <img src={DefaultDoctorImg} width="100%"
                                    height="100%"
                                    style={{ borderRadius: "0px", objectFit: "cover", objectPosition: "center" }} />
                            )

                    }



                    {/*                     
                    <img
                        src={
                 
                                ? practitioner.resource.photo[0].url
                                : <DefaultDoctorImg />
                        }
                        alt="Practitioner"
                        style={{ width: "75px", height: "70px", objectFit: "cover", borderRadius: "5px" }}
                    /> */}
                </div>
                <div style={{
                    width: 'calc(100% - 80px)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    <div>
                        <div
                            className="fntLg fntSemiBold capitalize"
                            onClick={() => history.push(`/profile/${practitioner?.resource?.name?.[0]?.text?.replace(/ /g, '').toLowerCase()}-${practitioner?.resource?.identifier?.[0]?.value}`)}

                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}
                        >
                            {practitioner?.resource?.name?.[0]?.text}
                        </div>
                        <div className="font-sm capitalize" style={{
                            color: "#707070",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"


                        }}>
                            {practitioner?.resource?.name?.[0]?.suffix?.length > 0 &&
                                practitioner?.resource?.name?.[0]?.suffix?.join(" , ")
                            }
                        </div>
                        {practitionerRole?.resource?.identifier?.[0]?.value && (
                            <div className="fntMd fntSemiBold capitalize"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}


                            >
                                {practitionerRole?.resource?.identifier?.[0]?.value}
                            </div>
                        )}

                        {practitionerRole?.resource?.specialty?.length > 0 && (
                            <div className="flx-center" >
                                <div>
                                    <img src={Stethoscope} width={15} height={15} alt="Stethoscope" />
                                </div>
                                <div className="fntSm14 capitalize"

                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}


                                >
                                    {practitionerRole?.resource?.specialty?.map(e => e?.coding?.[0]?.display + " ")}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* <div style={{ width: "100%", display: "flex", alignItems: "", gap: 10, border: "1px solid red" }}>
                <div style={{ width: '80px' }}>
                    <img src={(practitioner?.resource?.
                        photo && practitioner?.resource?.
                            photo?.[0]?.url != "undefined" && practitioner?.resource?.
                                photo?.[0]?.url != "null" && practitioner?.resource?.
                                    photo?.[0]?.url != "") ? practitioner?.resource?.
                                        photo?.[0]?.url : DefaultDoctorImg} style={{ width: "80px", height: "80px", borderRadius: "10px", border: "", }} />
                </div>
                <div style={{
                    width: 'calc(100% - 80px)',
                    border: "1px solid green",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }} >
                    <div >
                        <div style={{ border: "1px solid yellow" }} className="fntLg  fntSemiBold capitalize" onClick={() => history.push(`/profile/${practitioner?.resource?.name?.[0]?.text?.replace(/ /g, '').toLowerCase()}-${practitioner?.resource?.identifier?.[0]?.value}`)}>{practitioner?.resource?.name?.[0]?.text} </div>

                        <div className="font-sm capitalize" style={{ color: "#707070", }}>{practitioner?.resource?.name?.[0]?.suffix?.length > 0 &&

                            <>
                                {practitioner?.resource?.name?.[0]?.suffix?.join(" , ")}
                            </>

                        }</div>
                        {
                            practitionerRole?.resource?.identifier?.[0]?.value && <div className="fntMd fntSemiBold  capitalize">{practitionerRole?.resource?.identifier?.[0]?.value}</div>
                        }

                        {
                            practitionerRole?.resource?.specialty?.length > 0 &&
                            <div className="flx-center">
                                <div>
                                    <img src={Stethoscope} width={15} height={15} />
                                </div>
                                <div className="fntSm14 capitalize">{practitionerRole?.resource?.specialty?.map((e) => e?.coding?.[0]?.display + " ")}</div>
                            </div>
                        }
                    </div>
                </div>
            </div> */}

            {/* <Grid container spacing={""} gap={2} className="" >
                <Grid item>
                    <img src={(practitioner?.resource?.
                        photo && practitioner?.resource?.
                            photo?.[0]?.url != "undefined" && practitioner?.resource?.
                                photo?.[0]?.url != "null" && practitioner?.resource?.
                                    photo?.[0]?.url != "") ? practitioner?.resource?.
                                        photo?.[0]?.url : DefaultDoctorImg} style={{ width: "80px", height: "80px", borderRadius: "10px", border: "", }} />
                </Grid>
                <Grid item >
                    <div style={{ width: 'calc(100% - 80px)', border: "1px solid green" }}>
                        <div className="fntLg  fntSemiBold capitalize" onClick={() => history.push(`/profile/${practitioner?.resource?.name?.[0]?.text?.replace(/ /g, '').toLowerCase()}-${practitioner?.resource?.identifier?.[0]?.value}`)}>{practitioner?.resource?.name?.[0]?.text} </div>

                        <div className="font-sm capitalize" style={{ color: "#707070", }}>{practitioner?.resource?.name?.[0]?.suffix?.length > 0 &&

                            <>
                                {practitioner?.resource?.name?.[0]?.suffix?.join(" , ")}
                            </>

                        }</div>
                        {
                            practitionerRole?.resource?.identifier?.[0]?.value && <div className="fntMd fntSemiBold  capitalize">{practitionerRole?.resource?.identifier?.[0]?.value}</div>
                        }
                        {
                            // practitionerRole?.resource?.code?.length > 0 && <div className="fntMd fntSemiBold">{practitionerRole?.resource?.code?.map((e) => e?.coding?.[0]?.display + " ")}</div>
                        }
                        {
                            practitionerRole?.resource?.specialty?.length > 0 &&
                            <div className="flx-center">
                                <div>
                                    <img src={Stethoscope} width={15} height={15} />
                                </div>
                                <div className="fntSm14 capitalize">{practitionerRole?.resource?.specialty?.map((e) => e?.coding?.[0]?.display + " ")}</div>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid> */}

            {
                practitioner?.resource?.communication?.length > 0 &&
                <div className="flx-center mv0-5 pv0-5">

                    <div><img src={LanguageIcon} width={15} height={15} alt="langIcon" /></div>
                    <div className="fntSm"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}

                    >&nbsp;&nbsp;
                        <span className="fntSm fntSemiBold capitalize">Communicate in</span><span style={{ color: "#5465AA" }}> {practitioner?.resource?.communication?.map((e) => e?.coding?.[0]?.display + " ")}</span>
                    </div>
                </div>
            }


            {
                type == "people" &&
                <div style={{ position: "absolute", bottom: "10px", width: "100%", left: "0" }} >
                    <div style={{ display: "flex", justifyContent: AllowEdit === "PERMIT" ? "space-between" : "center", padding: "0 10px" }}>
                        {
                            AllowEdit === "PERMIT" &&
                            <Button
                                variant="outlined"
                                startIcon={<img src={EditImg} width={14} height={14} alt="edit" />}
                                sx={{
                                    width: { xs: "100px", sm: "100px", md: "100px", lg: "100px" },
                                    height: "30px",
                                    fontSize: { xs: "10px", sm: "14px", md: "14px", lg: "14px" },
                                    textTransform: "capitalize",
                                    borderRadius: "10px",
                                    borderColor: '#205072',
                                    color: '#205072',
                                    "&:hover": { borderColor: "#205072" }
                                }}
                                onClick={() => opnEdit(practitioner, practitionerRole)}
                            >
                                Edit
                            </Button>
                        }

                        <Button
                            variant="outlined"
                            startIcon={<img src={MessageIcon} width={14} height={14} alt="message" />}
                            style={{ backgroundColor: "#205072", color: "#FFFFFF", textTransform: "capitalize", borderRadius: "10px" }}
                            sx={{
                                width: { xs: "100px", sm: "100px", md: "100px", lg: "100px" },
                                height: "30px",
                                fontSize: { xs: "10px", sm: "14px", md: "14px", lg: "14px" },
                            }}
                        >
                            Message
                        </Button>
                    </div>
                </div>
            }
            {
                type == "non-people" &&
                <div style={{ position: "absolute", bottom: "10px", width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{}}>
                        <Button
                            variant="outlined"
                            startIcon={<img src={MessageIcon} width={14} height={14} alt="message" />}
                            style={{ backgroundColor: "#205072", color: "#FFFFFF", textTransform: "capitalize", borderRadius: "10px" }}
                            sx={{
                                width: { xs: "150px", sm: "150px", md: "150px", lg: "150px" },
                                height: "30px",
                                fontSize: { xs: "10px", sm: "14px", md: "14px", lg: "14px" },
                            }}
                        >
                            Message
                        </Button>
                    </div>
                </div>
            }

        </Card >


    )
}
export default DoctorsInfoCard;