import React, { useState, useEffect } from 'react';
import '../../../../Molecule/Feed/Feed.css';
import Post from '../../../../Molecule/Post/Post';
import '../../../../utility/style.css';
import '../../../../Molecule/KnowledgeQuiz/QuizModal.css'
import axios from 'axios';
import URL from '../../../../apiurl'
import { useRouteMatch } from 'react-router';
import { Snackbar } from '@mui/material'
import UserService from '../../../../../services/UserService';
import { useParams } from 'react-router';
import PublicPost from '../../../../Molecule/Post/publicPost';
import { headers } from '../../../../../redux/Actions/connectActions'
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';

export default function PublicFeed() {

    const [alert, setAlert] = useState({ open: false, msg: '', post: false })
    const [posts, setPosts] = useState([]);
    const [pageno, setPageno] = useState(0)
    const [noMore, setNoMore] = useState(true)

    const { path } = useRouteMatch();
    let { id } = useParams();

    const limit = 25

    useEffect(() => {

        setPageno(0)

    }, [id]);


    useEffect(() => {
        if (pageno === 0) {
            getFeed()
        }
    }, [pageno])

    const getFeed = () => {
        if (UserService.getToken()) {
            if (path?.includes('organization'))
                postAPIS("tokenOrg")
            else
                postAPIS("tokenUser")
        }
        else {
            if (path?.includes('organization'))
                postAPIS("orgPosts")
            else
                postAPIS("userPosts")
        }
    }

    const postAPIS = async (key) => {

        const APIS = {
            tokenUser: `${URL.getPosts}/${id}`,
            tokenOrg: `${URL.findPostByPageId}/${id}`,
            orgPosts: URL.findPostByPageIdWithoutKeycloak + id,
            userPosts: URL.findUserPost + id
        }

        await axios.get(`${APIS[key]}?limit=${limit}&offset=${pageno}`, UserService.getToken ? headers() : {})
            .then((res) => postSuccess(res.data))
            .catch(() => {
                postFailure()
            })
    }

    const postSuccess = (data) => {
        const nextdata = data.results ? data.results : data
        setPageno(pageno + limit)
        if (pageno === 0) {
            setPosts(nextdata)
        } else {
            setPosts([...posts, ...nextdata])
        }
        if (nextdata?.length === 0 || nextdata?.length < limit) {
            setNoMore(false)
        }
        setAlert({ open: false, msg: '', post: false })
    }

    const postFailure = () => {
        setAlert({ open: true, msg: 'Internal Server Error.Try after sometime' })
    }

    return (
        <div>
            {posts?.length > 0 ?
                <div className="feed">
                    <InfiniteScroll
                        dataLength={posts?.length}
                        next={getFeed}
                        hasMore={noMore}
                        style={{ overflow: "hidden" }}
                        scrollThreshold="75%"
                        loader={<div style={{ textAlign: 'center' }}><CircularProgress disableShrink /></div>}
                        endMessage={<p style={{ textAlign: 'center', margin: "20px" }}> No more posts</p>}
                    >
                        {posts?.map(post => (
                            <div key={post?.id}>
                                {UserService.getToken() ? <Post post={post} /> : <PublicPost post={post} />}
                            </div>
                        ))}
                    </InfiniteScroll>

                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        autoHideDuration={alert.post ? null : 2000}
                        open={alert.open}
                        onClose={() => setAlert({ open: false, msg: '', post: false })}
                        message={alert.msg}
                    />
                </div>
                :
                <div style={{ padding: "15px" }}>
                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Posts</h2>
                </div>
            }
        </div>
    )
}