import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getalljobs } from '../../../../redux/Actions/actions'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const HotVacancyData = () => {

    const alljobs = useSelector((state) => state.userProfile.getalljobs)

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getalljobs())
    }, [])

    return (
        <>
            {alljobs.length > 0 ?
                alljobs.map((item, i) => {
                    return <div key={i} style={{ display: 'flex', gap: "10px", alignItems: 'center' }}>
                        <div className="d-flex sidebar__pt1 textAlign capitalize e-align" style={{ flexGrow: '1' }}>
                            <div style={{ width: '45px', height: '45px', borderRadius: '8px' }}>
                                <img src="https://images.unsplash.com/photo-1658702983847-e974f4ed2d55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=60" alt="organisation" width='45px' height="45px" style={{ borderRadius: "8px" }} />

                            </div>
                            <div className="sidebar__ml sidebar__pt1 ">
                                <p className='job_title'>{item.jobTitle}</p>
                                <p className='job_sub_title'>{item.organisation}</p>
                                <p className='job_city'>{item.city}</p>
                            </div>
                        </div>

                        <div className='sidebar__pt1'><ArrowForwardIosIcon fontSize='small' /></div>
                    </div>
                }) : <div className='job_title' style={{ padding: '5px', textAlign: 'center' }}>No  Jobs</div>
            }
        </>
    )
}

export default HotVacancyData