import { Autocomplete, Box, Button, Snackbar, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { headers } from '../../redux/Actions/connectActions'
import URL from '../apiurl'
import Logo from '../../images/Logo2.svg'
import { useHistory } from 'react-router-dom'

const ForgotUsername = () => {
    const [verifyvia, setVerifyVia] = React.useState("")
    const [phone, setPhone] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [username, setUserName] = React.useState([])
    const [error, setError] = React.useState(false)
    const [otp, setOtp] = React.useState('')
    const [otpError, setOtpError] = React.useState(false)
    const [verifyOpen, setVerifyOpen] = React.useState(false)
    const [otpSend, setOtpSend] = useState(false)
    const [alert, setAlert] = useState({ open: false, msg: '' })


    const history = useHistory()
    // console.log(history)

    // console.log(username, 'kkkkkkkkkkkkkkk')


    const emailcheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const handleEmail = (e) => {
        const value = e.target.value?.trim()?.toLowerCase()
        setEmail(value)
        // const isValidEmail = emailcheck.test(value)
        // setError(!isValidEmail)
    }

    const onBlurHandleEmail = () => {
        const isValidEmail = emailcheck?.test(email)
        setError(!isValidEmail)
    }

    const onBlurHandlePhone = () => {
        const isValidPhone = phonecheck?.test(phone)
        setError(!isValidPhone)
    }

    const OtpCheck = /\b\d{5}\b/g
    const onBlurOtp = () => {
        const isValidOtp = OtpCheck?.test(otp)
        setOtpError(!isValidOtp)
    }


    const onFocusHandle = () => {
        setError(false)
    }


    const phonecheck = /^\d{10}$/;
    const handlePhone = (e) => {
        const value = e.target.value
        setPhone(value)
        // const isValidPhone = phonecheck.test(value)
        // setError(!isValidPhone)
    }

    const isRegistered = () => {
        let data = {
            "phoneNumber": phone ? `+91${phone}` : '',
            "email": email ? email : '',
        }

        let device = verifyvia === "OTP Via SMS" ? "Phone" : "Email"
        axios.post(`${URL.findPeoplebyPhoneOrEmail}`, data)
            .then(res => {
                if (res.status == 200 && res.data?.data?.length !== 0 && verifyvia === "OTP Via SMS") {
                    // console.log(res.data)
                    setUserName(res.data?.data)
                    handleSMSOTP()
                    // setPhone("")
                } else if (res.status == 200 && res.data?.data?.length !== 0 && verifyvia === "OTP Via Email") {
                    setUserName(res.data?.data)
                    handleEmailOTP()
                    // setEmail("")
                } else {
                    setAlert({ open: true, msg: `${device} not registered, sign up to create one !` })

                }
            }).catch(err => {
                console.log(err)
            })
    }


    const handleSMSOTP = () => {
        let data = {
            "phoneNumber": `+91${phone}`,
            "messagetype": "OTP",
            "apptype": "docsteth",
            "email": ""
        }
        // console.log(data, 'ssss')
        window.grecaptcha?.ready(_ => {
            window.grecaptcha
                .execute("6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9", { action: "submit" })
                .then(token => {
                    axios.post(`${URL.forgotUsername}`, data, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(res => {
                            setVerifyOpen(true)
                            setOtpSend(true)
                            setAlert({ open: true, msg: 'OTP send to your Mobile No.' })
                        }).catch(err => {
                            console.log(err)
                        })
                })
        })
    }
    const handleEmailOTP = () => {
        let data = {
            "phoneNumber": "",
            "messagetype": "OTP",
            "apptype": "docsteth",
            "email": email,
            "subject": 'docsteth',
        }
        // console.log(data, 'ssss')
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9", { action: "submit" })
                .then(token => {
                    axios.post(`${URL.forgotUsername}`, data, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(res => {
                            // console.log(res)
                            setVerifyOpen(true)
                            setOtpSend(true)
                            setAlert({ open: true, msg: 'OTP send to your Email ID' })
                        }).catch(err => {
                            console.log(err)
                        })
                })
        })
    }

    const verifySubmit = () => {
        let data = {
            "phoneNumber": verifyvia === "OTP Via SMS" ? `+91${phone}` : '',
            "otp": otp,
            "email": verifyvia === "OTP Via Email" ? email : '',
        }

        axios.post(`${URL.verifyForgotUsername}`, data,)
            .then(res => {
                // console.log(res)
                let device = verifyvia === "OTP Via SMS" ? "Phone" : "Email"
                if (res.status === 200) {
                    // console.log(`username sent to your ${device}`)
                    SendUserName()
                    setAlert({ open: true, msg: `username sent to your ${device}` })
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const SendUserName = () => {
        let data = {
            "phoneNumber": phone ? `+91${phone}` : '',
            "messagetype": "USERNAME",
            "apptype": "docsteth",
            "email": email ? email : '',
            "username": username[username?.length - 1]?.userName,
        }
        axios.post(`${URL.sendUsernametoUser}`, data, headers())
            .then(res => {
                // console.log(res)
                setAlert({ open: true, msg: 'Redirecting to Sign In...' })
                setTimeout(() => {
                    history.push('/')
                }, 3000)

            }).catch(err => {
                console.log(err)
            })
    }


    useEffect(() => {
        if (email !== "") {
            setEmail("")
        } else if (phone !== "") {
            setPhone("")
        }

        if (verifyOpen) {
            setVerifyOpen(false)
            setOtp("")
        }

    }, [verifyvia])


    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9"
        document.body.appendChild(script)
    }, [])
    return (
        <div style={{ display: 'flex', height: '100vh', }}>
            <Box
                sx={{
                    display: { xs: "none", sm: 'flex', md: "flex" },
                    flex: '2',
                    // display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: 'center'
                }}
            >
                <Typography variant="h2" sx={{ color: "#007dcd" }}>Welcome To</Typography>
                <div style={{ display: "flex", }}>
                    <img src={Logo} alt='logo' />
                    <Typography variant='h2' sx={{ color: "#007dcd" }} style={{ alignSelf: "center" }}>Docsteth</Typography>
                </div>
                <Typography variant="h5" sx={{ color: "#007dcd" }}>Global Practitioners Consortium</Typography>
            </Box>

            <div style={{ flex: '1', alignSelf: 'center', display: 'flex', flexDirection: "column", gap: "10px", padding: '30px' }}>

                <Typography m={1} sx={{ textAlign: 'center' }} component="p" >Forgot Your Username ?</Typography>
                <Autocomplete
                    options={['OTP Via Email', 'OTP Via SMS']}
                    getOptionLabel={(option) => option || ""}
                    onChange={(e, newVal) => setVerifyVia(newVal)}
                    value={verifyvia}
                    fullWidth
                    renderInput={(params) => <TextField style={{ marginTop: '16px' }} {...params} label="Verification Method" />}
                />


                {verifyvia === "OTP Via SMS" &&
                    <>
                        <TextField placeholder='enter phone no.' value={phone} onChange={handlePhone} onBlur={onBlurHandlePhone} onFocus={onFocusHandle} />
                        {error && <Typography variant="body2" sx={{ color: "red" }}>Please enter a valid phone no.</Typography>}
                        <Button sx={{ borderRadius: "10px" }} size="small" variant='contained' onClick={isRegistered} disabled={error || phone == "" || otpSend}>Send Otp</Button>
                    </>
                }
                {verifyvia === "OTP Via Email" &&
                    <>
                        <TextField size='small' placeholder='enter email' value={email} onChange={handleEmail} onBlur={onBlurHandleEmail} onFocus={onFocusHandle} />
                        {error && <Typography variant="body2" sx={{ color: "red" }}>Please enter a valid email address.</Typography>}
                        {/* <Button size='small' onClick={handleEmailOTP} disabled={error || email == ""}>Send Otp</Button> */}
                        <Button sx={{ borderRadius: "10px" }} size="small" variant='contained' onClick={isRegistered} disabled={error || email == "" || otpSend}>Send Otp</Button>
                    </>
                }

                {verifyOpen &&
                    <>
                        <TextField size='small' placeholder='enter otp' value={otp} onChange={(e) => setOtp(e.target.value)} onBlur={onBlurOtp} onFocus={onFocusHandle} />
                        {otpError && <Typography variant="body2" sx={{ color: "red" }}>Please enter a valid Otp.</Typography>}
                        <Button sx={{ borderRadius: "10px" }} size="small" variant='contained' onClick={verifySubmit} disabled={otpError || otp == ""}>verify Otp</Button>
                    </>
                }

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    open={alert.open}
                    onClose={() => setAlert({ open: false, msg: '' })}
                    message={alert.msg}
                />
            </div>
        </div>
    )
}

export default ForgotUsername