import { Box, Button, Grid, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAppsByUserId, getSuggestedApps, headers } from "../../../../redux/Actions/connectActions"
import { AppsView } from "./appview"
import SearchApps from "./search"
import '../AppVault.css'
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
import URL from "../../../apiurl"
import UserService from "../../../../services/UserService"
import DocLogo from '../../../../images/docsteth.png'

const AppGallery = () => {

    const [apps, setApps] = useState([])
    const [vaultApps, setVaultApps] = useState({ docsteth: [], clinivault: [] })

    const history = useHistory()
    // const suggestedApps = useSelector(state => state.connect.suggestedApps)
    const appsbyuserId = useSelector(state => state.connect.appsbyuserId)

    const dispatch = useDispatch()
    const { pathname } = useLocation()

    useEffect(() => {
        if (appsbyuserId.length === 0) {
            dispatch(getAppsByUserId())
        }
        axios.get(URL.getAppsByAvailability, headers()).then(res => setVaultApps(res.data.data))
    }, [])

    useEffect(() => {
        setApps(appsbyuserId[0]?.suggestedApps)
    }, [appsbyuserId])

    return (
        <AppsView gallery apps={apps} suggested Component={(props) => <Box>
            <div className="space_btw_center" style={{ padding: " 0 0 10px " }}>
                <Typography className='dflex font-bold b-color'>Suggested Applications</Typography>
                <div className="text-right">
                    <SearchApps />
                </div>
            </div>
            {props.children}
            <Grid container spacing={2} sx={{ mt: 3, marginBottom: { xs: "60px", md: 0 } }}>
                <Grid item xs={12} sm={6} md={6}>
                    {/* <Button sx={{width: '100%',height: '76px',fontSize: '24px',borderRadius: '12px'}} className='capitalize' onClick={() => history.push(pathname + "/docstethapps")} variant="contained">Docsteth Apps</Button> */}
                    <AppsView apps={vaultApps.docsteth.slice(0, 3)} suggested heading={<>Docsteth Apps <span className="add-cover cursor" style={{ color: 'blue', fontWeight: '400' }} onClick={() => history.push(pathname + "/docstethapps")} >See All</span></>} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <AppsView apps={vaultApps.clinivault.slice(0, 3)} suggested heading={<>Clinivault Apps <span className="add-cover cursor" style={{ color: 'blue', fontWeight: '400' }} onClick={() => history.push(pathname + "/clinivaultapps")} >See All</span></>} />

                </Grid>
            </Grid>
        </Box>} />
    )
}

export default AppGallery