import React from 'react'
import Downarrow from '../../../icons/downarrow.svg';
function PublicProfileTimeline() {
    return (
        <div className="margin_myprofile">
            <div className='border_img mt-10'>
                <div className='recent_activity dflex_al_jc' style={{ padding: "15px" }}>
                    <h2 style={{ fontSize: "18px", fontWeight: "400", color: "#47AEE1" }}>Recent Activity</h2>
                </div>
                <div style={{ padding: "15px" }}>
                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                </div>
                {/* <div style={{ padding: "10px 20px" }}>
                    <div className='dflex_al_jc'>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Registered Events</p>
                        </div>
                        <div>
                            <img width="10px" height="10px" src={Downarrow}></img>
                        </div>
                    </div>
                    <div className='mt-10' style={{ display: "flex" }}>
                        <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                    </div>
                </div> */}
                {/* <div style={{ padding: "10px 20px" }}>
                    <div className='dflex_al_jc'>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p># Topics</p>
                        </div>
                        <div>
                            <img width="10px" height="10px" src={Downarrow}></img>
                        </div>
                    </div>
                    <div className='mt-10' style={{ display: "flex" }}>
                        <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seemore</h4>
                    </div>
                </div> */}
                {/* <div style={{ padding: "10px 20px" }}>
                    <div className='dflex_al_jc'>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>Pages</p>
                        </div>
                        <div>
                            <img width="10px" height="10px" src={Downarrow}></img>
                        </div>
                    </div>
                    <div className='mt-10' style={{ display: "flex" }}>
                        <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seemore</h4>
                    </div>
                </div> */}
                {/* <div style={{ padding: "10px 20px" }}>
                    <div className='dflex_al_jc'>
                        <div style={{ fontSize: "15px", fontWeight: "400" }}>
                            <p>People</p>
                        </div>
                        <div>
                            <img width="10px" height="10px" src={Downarrow}></img>
                        </div>
                    </div>
                    <div className='mt-10' style={{ display: "flex" }}>
                        <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default PublicProfileTimeline