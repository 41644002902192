import React, { useState, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Requests from './requests'
import SuggestedPeople from './suggestedPeople'
import SuggestedOrganisations from './suggestedOrganisations'
import Topics from './topics'
import ListHeader from './ListHeader'
import { useDispatch, useSelector } from 'react-redux';
import peers from '../Atom/modifiedicons/peers.svg';
import DialogBox from '../DialogBox/dialog';
import UsersData from '../Molecule/Post/usersData';
import team_icon from '../Atom/modifiedicons/team_icon.svg'
import Organisations from '../Atom/modifiedicons/Organisations.svg';
import Follower from '../../images/followers.svg';
import Following from '../Atom/modifiedicons/followers.svg';
import hashtag from '../Atom/modifiedicons/hashtag.svg';
import { Helmet } from 'react-helmet';
import { handleActiveValue } from '../../redux/Actions/actions';
// import { createTheme, ThemeProvider } from '@mui/material';
import './peer.css';
import { createTheme, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import InvitePeers from './invite_peers';
import URL from '../apiurl';
import UserService from '../../services/UserService';
import { getConnectCount } from '../../redux/Actions/connectActions';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CustomListItem = ({ name, src, Arrow, count, IconMargin, FMargin, data, path, displayname }) => {
  const [openDialog, setOpenDialog] = useState(false)
  return (
    <>
      {data ? data?.length > 0 ?
        <DialogBox width="xs" open={openDialog} handleDialog={() => setOpenDialog(false)}
          Content={
            <UsersData title="Peers" data={data} closeDialog={() => setOpenDialog(false)} />}
        /> : '' : ''}
      <Link to={`/connect${path}`}>
        <ListItem className='text-primary cursor' sx={{ pb: 0 }} onClick={() => setOpenDialog(true)}>
          <ListItemIcon>
            <img src={src} className={`${IconMargin} text-primary`} />
            {Arrow ? <Arrow className={`${FMargin} text-primary`} /> : ''}
          </ListItemIcon>
          <ListItemText className="list-text" disableTypography id="switch-list-label-wifi" primary={name} />
          <p className="sidebar__statNumber">{count}</p>
        </ListItem>
      </Link>
    </>
  );
}


const Suggestion = ({ src }) => {
  return (
    <span>
      {src?.map((ele, i) => (
        <ListItem key={i} className="sugg-list" style={{ color: "#007dcd" }}>
          {ele}
          {/* <Link href="#" className='follow-link'>Follow</Link> */}
        </ListItem>
      ))}

    </span>
  );
}

const ItemCard = ({ CardItem, type, data, name, name1 }) => {
  return (
    <Item className='custom-card' sx={{ maxHeight: '100%', height: `${type ? '81.5vh' : 'auto'}`, overflow: 'auto', pt: 0 }}>
      <CardItem type={type ? type : false} data={data} name={name} name1={name1} />
    </Item>
  )
}
// const ItemCard1 = ({ CardItem, type, data, name, displayname }) => {
//   return (
//     <Item className='custom-card' sx={{ maxHeight: '100%', height: `${type ? '81.5vh' : 'auto'}`, overflow: 'auto', pt: 0 }}>
//       <CardItem type={type ? type : false} data={data} name={name} displayname={displayname} />
//     </Item>
//   )
// }

export default function Peers(props) {

  const Count = useSelector(state => state.connect.count)
  const data = useSelector(state => state.userProfile.user)
  const [open, setOpen] = useState(false)
  let path = document.location?.href?.split('/connect')?.[1]

  const dispatch = useDispatch()

  let count = Count

  useEffect(() => {
    dispatch(handleActiveValue(2))
    dispatch(getConnectCount())
  }, [])


  useEffect(() => {
    count = Count
  }, [Count])

  // console.log(path)

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Docsteth | peers</title>
      </Helmet>
      <DialogBox width="xs" open={open} style={{ padding: '0px' }} handleDialog={() => setOpen(false)} Content={<InvitePeers close={() => setOpen(false)} />} />
      <Box className='peers_header'>
        <Grid container sx={{ mx: '0px!important' }}>
          {/* DESKTOP SCREEN DEVICES */}

          <Grid item md={3} sm={12} xs={12} sx={{ px: '8px!important', pl: { lg: '0px!important' }, mb: '16px!important' }}>
            <Item sx={{ pb: 1, borderRadius: 3, display: { xs: 'none', md: 'block' } }} className="custom-card">
              <ListHeader HeaderName='Manage Network'
              >
                <CustomListItem name="Invites Received" count={count.invitesReceived || 0} src={hashtag} path='/requests' />
                <CustomListItem name="Peers" count={count.peers || 0} src={peers} path="/peers" />
                {/* <CustomListItem name="Groups" count="12" src={team_icon}/>*/}
                <CustomListItem name="Organisations" count={count.organisations || 0} src={Organisations} path="/organisations" />
                <CustomListItem name="Following" count={count.following || 0} src={Follower} path="/following" />
                <CustomListItem name="Followers" count={count.followers || 0} src={Following} path="/followers" />
                <CustomListItem name="Topics" src={hashtag} path="/topics" />
                <Divider sx={{ mt: 2, backgroundColor: '#707070' }} />

              </ListHeader>
              <List
                subheader={<ListSubheader className="sub-header text-primary">Suggested </ListSubheader>}
              >
                <CustomListItem name="People" src={peers} path='/suggestedpeople' />
                <CustomListItem name="Organisations" src={Organisations} path='/suggestedorganisations' />
                <CustomListItem name="Topics" src={hashtag} path='/suggestedtopics' />
                <CustomListItem name="Invites Sent" src={hashtag} count={count.invitesSent || 0} path="/sentrequests" />
                <Divider sx={{ mt: 2, backgroundColor: '#707070' }} />
                <CustomListItem name="Blocked Users" src={peers} count={count.blockedUsers || 0} path="/blockedusers" />

              </List>
            </Item>

            <div className='peer_desktop'>
              <Item sx={{ pb: 1, mt: 2, borderRadius: 3, backgroundColor: '#bf91ca' }} className="custom-card cursor" onClick={() => setOpen(true)}>
                <Button sx={{ p: '0px!important', color: 'white' }} className="capitalize">Invite People to Docsteth</Button>
              </Item>
            </div>

          </Grid>

          {/* ******************************************SMALL SCREEN DEVICES*************************************************** */}

          {path !== '' ?
            <Grid item id='width_mobile' className='cards_margin' md={9} xs={12} sx={{ px: '8px!important' }}>
              {path == '/requests' ? <ItemCard CardItem={Requests} type={true} name="Requests" /> :
                path == '/sentrequests' ? <ItemCard CardItem={Requests} type={true} name="Invites Sent" /> :
                  path == '/suggestedpeople' ? <ItemCard CardItem={SuggestedPeople} type={true} name="Suggested People" /> :
                    path == '/suggestedorganisations' ? <ItemCard CardItem={SuggestedOrganisations} type={true} name="Suggested Organisations" /> :
                      path == '/suggestedtopics' ? <ItemCard CardItem={Topics} type={true} name="Suggested Topics" /> :
                        path == '/topics' ? <ItemCard CardItem={Topics} type={true} name="Topics" /> :
                          path == '/organisations' ? <ItemCard CardItem={SuggestedOrganisations} type={true} name="Organisations" /> :
                            path == '/peers' ? <ItemCard CardItem={SuggestedPeople} type={true} name="Peers" /> :
                              path == '/following' ? <ItemCard CardItem={SuggestedPeople} type={true} name="Following" /> :
                                path == '/followers' ? <ItemCard CardItem={SuggestedPeople} type={true} name="Followers" /> :
                                  path == '/blockedusers' ? <ItemCard CardItem={SuggestedPeople} type={true} name="Blocked Users" /> :
                                    ''}

            </Grid> :

            <Grid item md={9} sm={12} xs={12} sx={{ px: '8px!important', pr: { lg: '0px!important' } }}>
              <ItemCard CardItem={Requests} name="Requests" name1="Show all" />
              <br />
              <Grid sx={{ display: { xs: 'block', md: 'none' } }}>
                <Item sx={{ pb: 1, borderRadius: 3 }} className="custom-card">
                  <CustomListItem name="Peers" count={count.peers} src={peers} path="/peers" />
                </Item>
                <br />
                <Item sx={{ pb: 1, borderRadius: 3 }} className="custom-card">
                  <CustomListItem name="Following" count={count.following} src={Follower} path="/following" />
                </Item>
                <br />
                <Item sx={{ pb: 1, borderRadius: 3 }} className="custom-card">
                  <CustomListItem name="Followers" count={count.followers} src={Following} path="/followers" />
                </Item>
                <br />
                <Item sx={{ pb: 1, borderRadius: 3 }} className="custom-card">
                  <CustomListItem name="Organisations" count={count.organisations || 0} src={Organisations} path="/organisations" />
                </Item>
                <br />
                <Item sx={{ pb: 1, borderRadius: 3 }} className="custom-card">
                  <CustomListItem name="Topics" src={hashtag} path="/topics" />
                </Item>
                <br />
                <Item sx={{ pb: 1, borderRadius: 3 }} className="custom-card">
                  <CustomListItem name="Invites Sent" src={hashtag} path="/sentrequests" count={count.invitesSent || 0} />
                </Item>
              </Grid>
              <Item sx={{ pb: 1, mt: 2, borderRadius: 3, backgroundColor: '#bf91ca', display: { xs: 'block', md: 'none' } }} className="custom-card cursor" onClick={() => setOpen(true)}>
                <Button sx={{ p: '0px!important', color: 'white' }} className="capitalize">Invite People to Docsteth</Button>
              </Item>
              <br />
              <ItemCard CardItem={SuggestedPeople} name1="Show all" name="Suggested People" />
              <br />
              <ItemCard CardItem={SuggestedOrganisations} name1="Show all" name="Suggested Organisations" />
              <br />
              <div id="topic_bottom">
                <ItemCard CardItem={Topics} name="Suggested Topics" name1="Show all" />
              </div>
              <br />
            </Grid>
          }

          {/* <Grid item sm={12} md={4} xs={12} sx={{ mb: '10%!important', px: '8px!important', width: { lg: '25%' }, maxWidth: { lg: '25%' } }}>
            <div className="custom-card" style={{ maxHeight: '550px', overflowY: 'auto' }}>
              <ItemCard CardItem={Followers} />
            </div>
          </Grid> */}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
