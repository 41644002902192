import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomValidator } from './customInput';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function DateInPutIn({ maxDate, handleChange, required, errorMessages, ...props }) {


    return (
        // <LocalizationProvider dateAdapter={AdapterDayjs}>
        //     <DatePicker
        //         className='input_shadow input_padding'
        //         maxDate={maxDate ? dayjs(maxDate) : ''}
        //         {...props}
        //         fullWidth
        //         onChange={value => handleChange(props.name, new Date(value.$d).toISOString())}
        //         inputFormat="DD/MM/YYYY"
        //         renderInput={(params) => <CustomValidator {...params} inputProps={{ ...params.inputProps, readOnly: true }} fullWidth validators={required ? ['required'] : []} errorMessages={errorMessages} {...props} />}
        //     />
        // </LocalizationProvider>

        < LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                className='input_shadow input_padding'

                sx={{
                    width: "100%",
                }}
                views={props.views}
                maxDate={maxDate ? dayjs(maxDate) : null}
                // inputFormat="DD/MM/YYYY"
                format="DD/MM/YYYY"
                value={props.value}
                // value={dayjs(new Date())}
                onChange={value => handleChange(props.name, new Date(value.$d).toISOString())}

            />

        </LocalizationProvider >
    )
}