import React, { useEffect } from "react";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Actions,
  FormDivider,
  OrgLayout,
  Purpose,
} from "../../../../common/components/layout";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { D_API } from "../../../../../services/APIService";
import UserService from "../../../../../services/UserService";

const physicalTypeOption = [
  {
    code: "si",
    display: "Site",
  },
  {
    code: "bu",
    display: "Building",
  },
  {
    code: "wi",
    display: "Wing",
  },
  {
    code: "wa",
    display: "Ward",
  },
  {
    code: "lvl",
    display: "Level",
  },
  {
    code: "co",
    display: "Corridor",
  },
  {
    code: "ro",
    display: "Room",
  },
  {
    code: "bd",
    display: "Bed",
  },
  {
    code: "ve",
    display: "Vehicle",
  },
  {
    code: "ho",
    display: "House",
  },
  {
    code: "ca",
    display: "Cabinet",
  },
  {
    code: "rd",
    display: "	Road",
  },
  {
    code: "area",
    display: "	Area",
  },
  {
    code: "jdn",
    display: "	Jurisdiction",
  },
];

const LocationAmenities = ({ locationState, handleLocationState, contact, addContact, deleteContact, handleContact, close, changeTab, handleSubmitAmenities }) => {

  const [locId, setLocId] = useState(null)
  const [name, setName] = useState(null);
  const [nameOptions, setNameOptions] = useState([]);
  // const [contact, setContact] = useState([]);
  const [mode, setMode] = React.useState("kind");
  const [physical, setPhysical] = useState(null);

  // console.log(contact,"contact")

  const [partOf, setPartOf] = useState(null);
  const [partOfOption, setPartOfOption] = useState([]);

  const location = useSelector((state) => state.registration.location);


  const dispatch = useDispatch()


  // const addContact = (system) => (use) => {
  //   setContact([...contact, { system: system, value: "", use: use }]);
  // };

  // const deleteContact = (index) => () => {
  //   setContact([...contact.filter((con, i) => index !== i)]);
  // };

  // const handleContact = (i) => (name, value) => {
  //   setContact([
  //     ...contact.map((con, ind) =>
  //       i === ind ? { ...con, [name]: value } : { ...con }
  //     ),
  //   ]);
  // };

  const handleSubmit = () => {
    let data = {
      resourceType: "Location",
      partOf: {
        reference: `Location/${location?.id}`,
      },
      name: locationState.name.display,
      type: [{ coding: [locationState.name] }],
      // telecom: locationState.contact,
      telecom: contact,
      mode: locationState.mode,
      physicalType: { coding: [locationState.physical] },
      status: 'active'
    };


    // let data = {
    //   resourceType: "Location",
    //   partOf: {
    //     reference: `Location/${location?.id}`,
    //   },
    //   name: name.display,
    //   type: [{ coding: [name] }],
    //   telecom: contact,
    //   mode: mode,
    //   physicalType: { coding: [physical] },
    //   status:'active'
    // };


    // console.log(data, "datatttttt");

    // D_API.post("Location", data, {
    //   headers: { Authorization: `bearer ${UserService.getToken()}` },
    // }).then((res) => {
    //   // console.log(res.data,"mmm");
    //   dispatch({type: "Level_TWO_ID", payload: res.data })
    //   changeTab()
    // }).catch(err=>{
    // })
    handleSubmitAmenities(data)
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FHIR_SERVER}ValueSet?name=ServiceDeliveryLocationRoleType`
      )
      .then((res) => {
        setNameOptions(
          res?.data?.entry?.[0]?.resource?.compose?.include?.[0]?.concept
        );
      });

    setPartOfOption([location.name]);
  }, []);

  return (
    <div>
      <ValidatorForm
        onSubmit={handleSubmit}
      // style={{ padding: "0 15px", height: "410px", overflow: "auto" }}
      >
        <Grid
          container
          spacing={2}
          sx={{ height: '410px', overflow: 'auto' }}
        >
          <OrgLayout
            label="Location Name"
            input="select"
            options={nameOptions}
            value={locationState.name}
            required
            getOptionLabel={(option) => option.display}
            name="name"
            // handleChange={(name, value) => setName({ ...value })}
            handleChange={(name, value) => handleLocationState(name, value)}
            placeholder="Select Location Name"
            validators={["required"]}
            errorMessages={["enter location name"]}
          />
          <OrgLayout
            label="Location ID"
            value={locationState.locId}
            name="locId"
            // handleChange={(name,value)=>setLocId(value)}
            handleChange={(name, value) => handleLocationState(name, value)}
            placeholder="identifier for location(Room,number,Ward Name"
          // validators={["required"]}
          // errorMessages={["enter location id"]}
          />

          <Grid item xs={12} sm={12} md={12}>
            <Grid container item>
              <Grid item xs={12} sm={4} md={3}>
                Mode
              </Grid>
              <Grid item md={6}>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="mode"
                  value={locationState.mode}
                  // onChange={(e) => {
                  //   setMode(e.target.value);
                  // }}
                  onChange={(e) => handleLocationState("mode", e.target.value)}
                >
                  <FormControlLabel
                    value="instance"
                    control={<Radio />}
                    label="Instance"
                  />
                  <FormControlLabel
                    value="kind"
                    control={<Radio />}
                    label="Kind"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <OrgLayout
            label="Part of"
            input="select"
            options={partOfOption}
            value={locationState.partOf}
            required
            getOptionLabel={(option) => option}
            // handleChange={(name, value) => setPartOf(value)}
            name="partOf"
            handleChange={(name, value) => handleLocationState(name, value)}
            placeholder="Parent Building or address or Location"
          />

          <OrgLayout
            label="Physical Type"
            input="select"
            options={physicalTypeOption}
            value={locationState.physical}
            required
            getOptionLabel={(option) => option.display}
            // handleChange={(name, value) => setPhysical({ ...value })}
            name="physical"
            handleChange={(name, value) => handleLocationState(name, value)}

            placeholder="tell about kind of services"
          />

          <Purpose
            input="phone"
            name_without_public="Telecom"
            contact={contact}
            handleContact={handleContact}
            deleteContact={deleteContact}
            handleType={addContact("phone")}
            options={["mobile", "work"]}
          />
        </Grid>
        <FormDivider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 15px",
          }}
        >
          <Button onClick={close} className="close-btn" variant="outlined">
            Cancel
          </Button>
          <Button
            type="submit"
            sx={{ padding: "4px 20px !important" }}
            name="save"
            className="save-btn"
            variant="contained"
          >
            Create
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default LocationAmenities;
