import { Box, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { RadioButton } from './inputs';

export default function Quantity({ errorMessages, ...props }) {

    let extensionType = props.item?.extension?.[0]?.valueCodeableConcept?.coding?.[0]?.display

    const [val, setVal] = useState(props.value)

    useEffect(() => {
        props?.onChange(val)
    }, [val])

    const handleChange = (value, name) => {
        setVal({ ...val, [name]: value })
    }

    return (
        <Box>
            <div className='d-flex'>
                <TextValidator type='number' placeholder="Value" onWheel={event => event.target?.blur()} {...props}
                    errorMessages={errorMessages}
                    sx={{ '& input': { padding: '5px 10px!important' }, mb: 2, mr: 2, width: '120px' }}
                    value={val.value}
                    onChange={(e) => handleChange(e.target.value, 'value')}
                />
                {extensionType === 'Drop down' ?
                    <div><Select
                        id="demo-simple-select"
                        {...props}
                        value={val.units}
                        onChange={(e) => handleChange(e.target.value, 'units')}
                        sx={{ '& .MuiSelect-select': { padding: '5px 10px!important' }, mb: 2, width: '120px' }}
                    >
                        {props?.item?.answerOption?.map((value, i) => (
                            <MenuItem value={value?.valueCoding?.display} key={i}>{value?.valueCoding?.display}</MenuItem>
                        ))}
                    </Select></div>
                    : extensionType === 'Radio Button' ? <RadioButton {...props} value={val.units} onChange={(e) => handleChange(e.target.value, 'units')} options={props?.item?.answerOption} row={true} units={true} />
                        : ''}
            </div>
        </Box>
    );
}