import React from 'react'

import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

const YoutubeLink = ({ docFeed }) => {

    const history = useHistory();

    const showhashtag = (tag) => {
        history.push({
            pathname: `/hashtagpost`,
            state: tag
        })
    }
    return (
        <div>
            {
                docFeed?.description?.startsWith("https") && docFeed?.postType == "TEXT" &&
                <>
                    <div style={{ padding: "0px 20px" }}>
                        <a href={`${docFeed.description}`} target="_blank" className='cursor' style={{ color: "#007dcd", textDecoration: "underline" }}>Link</a>
                    </div>

                    <div style={{ padding: "0px 20px" }}>
                        {docFeed.tags && docFeed.tags?.length > 30 ?
                            <>
                                {docFeed.tags ? docFeed.tags?.slice(0, 30)?.replace(/,/g, ' ')?.split(' ')?.map((tag, i) => tag !== "null" && <span style={{ paddingLeft: "" }} key={i} onClick={() => showhashtag(tag)}><Typography component="span" style={{ color: "#007dcd" }} className="cursor">{tag}</Typography>&nbsp;</span>) + "..." : ''}
                            </>
                            :
                            <>
                                {docFeed.tags ? docFeed.tags?.replace(/,/g, ' ')?.split(' ')?.map((tag, i) => tag !== "null" && <span style={{ paddingLeft: "" }} key={i} onClick={() => showhashtag(tag)}><Typography component="span" className="cursor" style={{ color: "#007dcd" }}>{tag}</Typography>&nbsp;</span>) : ''}
                            </>
                        }
                    </div>

                </>
            }


        </div >
    )
}

export default YoutubeLink