import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Layout from './layout'
import { useSelector, useDispatch } from 'react-redux';
import Add from './accCrud';
import Snackbar from '@mui/material/Snackbar'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogBox from '../../../DialogBox/dialog'
import Badge from "../../../Atom/svg/ribbon.svg";
import accomplishments from '../../../Atom/modifiedicons/accomplishments.svg'
import Certificate from '../../../Atom/svg/certificate.svg';
import achevments from '../../../Atom/modifiedicons/achevments.svg'
import Achievement from '../../../Atom/svg/medal.svg'
import URL from '../../../apiurl'
import certificate from "../../../Atom/svg/certificate.svg";
import ribbon from "../../../Atom/svg/ribbon.svg";
import medal from "../../../Atom/svg/medal.svg";
import { FiEdit3 } from 'react-icons/fi';
import { GrAddCircle } from 'react-icons/gr';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { getMetaData, accomplishment } from '../../../../redux/Actions/actions';
import { headers } from '../../../../redux/Actions/connectActions';
import '../updateprofile.css'

const ExpView = (props) => {
    // console.log(props)
    return (
        <div className="">
            <div className="sidebar__stat  bg__blue sidebar__padding">
                <div className="sidebar__substat ta__center mt-10">
                    <img src={accomplishments} alt="accomplishment" />
                    <div className="font-caption fw__semibold sidebar__lh  sidebar__ml">Accomplishments</div>
                </div>
            </div>
            {/* Accomplishment */}
            {props.acc.length > 0 ? props.acc.map(e => (
                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" key={e.id}>
                    {/* <div className="sidebar__substat ta__center">
                        <p className="my-img domain-icon" style={{ boxShadow: 'none', borderRadius: '0px' }}>
                            <img src={e.typeOfAccomplishment === "BADGES" ? Badge : e.typeOfAccomplishment === 'CERTIFICATES' ? Certificate : Achievement} alt="ribbon"></img>
                        </p>
                        <div className="sidebar__ml " style={{ textTransform: 'capitalize', fontSize: '13px' }}>
                            <p style={{ fontWeight: 'bold', paddingBottom: '3px' }}>{(e.title) ? e.title : ""} </p>
                            <p style={{ paddingBottom: '3px' }}>{e.summary ? e.summary : 'N/A'}</p>
                            <p style={{ paddingBottom: '3px', textTransform: "lowercase" }}>{e.link ? (e.link).substr(0, 50) : 'N/A'}</p>
                            <p >{(e.date) ? moment(e.date).format('DD MMM yyyy') : ""}</p>
                        </div>
                    </div> */}
                    <div className="sidebar__substat ta__center" style={{ width: "10%" }}>
                        <p style={{ boxShadow: 'none', borderRadius: '0px' }} className="my-img domain-icon">
                            <img src={e.typeOfAccomplishment === "BADGES" ? Badge : e.typeOfAccomplishment === 'CERTIFICATES' ? Certificate : achevments} alt="ribbon"></img>
                        </p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "70%" }}>
                        <div className="sidebar__ml " style={{ textTransform: 'capitalize', fontSize: '13px' }}>
                            <p style={{ fontWeight: 'normal', paddingBottom: '3px', fontSize: "14px" }}>{e.title ? e.title : 'N/A'} </p>
                            {props.isReadMore ?
                                <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary?.slice(0, 150)}</span> : <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary}</span>
                            }
                            {e.summary && e.summary.length > 100 ?
                                <span onClick={props.toggleReadMore} className="read-or-hide">
                                    {props.isReadMore ? "...read more" : " show less"}
                                </span> : ""
                            }
                            <p style={{ paddingBottom: '3px', fontSize: "11px", textTransform: "lowercase", color: "rgb(118, 118, 118)" }}>{e.link ? (e.link).substr(0, 100) : 'N/A'}</p>
                        </div>
                    </div>
                    <div className="sidebar__statNumber" style={{ width: "20%" }}>
                        <Tooltip title='Edit Accomplishment'>
                            <IconButton onClick={() => props.handleEdit(e)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Edit Accomplishment'>
                            <IconButton onClick={() => props.handleDelete(e.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                </div>
            )) :
                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show">
                    <p style={{ textAlign: "center" }}>No Accomplishments</p>
                </div>

            }
        </div>
    )
}


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default function Accomplishments(props) {

    // const [acc, setAcc] = useState([])
    const [image, setimage] = useState("")
    const [fileimg, setFileimg] = React.useState([]);
    const [filename, setFilename] = React.useState("")
    const [alert, setAlert] = useState({ open: false, msg: '', post: false })
    const [open, setOpen] = useState(false)
    const [check, setCheck] = useState(true)
    const [edit, setEdit] = useState(false)
    const [view, setView] = useState(false)
    const [show, setShow] = useState(true)
    const [count, setCount] = useState(5)
    const [isReadMore, setIsReadMore] = useState(true);
    const [metatags, setMetatags] = useState({})
    const [meta, setMeta] = useState([])
    const [url, setUrl] = useState([])
    const [createpost, setCreatepost] = useState([])
    const [badges, setBadges] = useState([])
    const [pdfviewer, setPdfviewer] = useState([])
    const [val, setVal] = React.useState([]);
    // const [show, setShow] = useState(false)
    const [add, setAdd] = React.useState("");
    const dispatch = useDispatch()
    const data = useSelector(state => state.userProfile.userProfile)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    // console.log(data.achievementsCount)

    useEffect(() => {
        if (props.add) {
            addFun()
        }
        else {
            dispatch(accomplishment())
        }
    }, [props.add])

    // useEffect(() => {
    //     if (props.publication) {

    //     }
    // })



    const acc = useSelector(state => state.userProfile.accomplishment)
    // console.log(acc)

    const [state, setState] = useState(
        {
            id: null,
            coAuthors: "",
            date: null,
            accomplishmentCategory: "",
            organisation: null,
            title: "",
            summary: "",
            status: null,
            attachment: null,
            link: null,
        }
    )

    // useEffect(() => {

    //     getAcc()

    // }, [])




    const imageHandler = (e) => {
        if (e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                let types = e.target.files[i].type.split('/')[1]
                // console.log(types)
                if (types === 'jpg' || types === 'png' || types === "jpeg") {
                    const reader = new FileReader()
                    reader.readAsDataURL(e.target.files[i]);
                    reader.onloadend = () => {
                        setCreatepost([reader.result])
                        setFileimg(reader.result)
                        setShow(false)
                    }
                }
                else if (types === 'pdf') {
                    const reader = new FileReader()
                    reader.readAsDataURL(e.target.files[i]);
                    const pdffilename = e.target.files[0].name
                    reader.onloadend = () => {
                        setCreatepost([reader.result])
                        setFilename(pdffilename)
                        setPdfviewer(reader.result)
                        setShow(false)

                    }
                }
                else {
                    setAlert({ open: true, msg: 'Only .jpg, .png, .jpeg, .pdf extensions allowed' })
                    // setImgLoad(false)
                }
            }
        }
    }


    const Imagesubmit = () => {
        // console.log("inside")
        if (pdfviewer.length > 0 || fileimg.length > 0) {
            // console.log("img or pdf")
            const linktype1 = pdfviewer.length > 0 ? pdfviewer.split(';') : ""
            const variable = linktype1.length > 0 ? linktype1[0].split('/') : ""
            const type = variable ? variable[1] : ""
            if (type === 'pdf') {
                let base64 = pdfviewer.split(",")[1].split("=")[0];
                var strLength = base64.length;
                var fileLength = strLength - (strLength / 8) * 2;
                let size = fileLength
                const [, type] = pdfviewer.split(';')[0].split('/');
                let filetype = type
                let filedata = pdfviewer.split(',')[1]

                let picdata = {
                    "file_type": 'PDF',
                    "files": [
                        {
                            "file_size": Number((size / 1048576).toFixed(3)),
                            "file_extension": filetype,
                            "data": filedata
                        }
                    ]
                }
                // console.log(picdata)
                axios.post(`${URL.upload}`, picdata, headers()).then(res => {
                    // console.log(res)
                    // setimage(res.data)
                    setShow(false)
                    handleAdd(res.data)
                })
            }
            else {
                let base64 = fileimg.split(",")[1].split("=")[0];
                var strLength = base64.length;
                var fileLength = strLength - (strLength / 8) * 2;
                let size = fileLength
                const [, type] = fileimg.split(';')[0].split('/');
                let filetype = type
                let filedata = fileimg.split(',')[1]

                let picdata = {
                    "file_type": 'IMAGE',
                    "files": [
                        {
                            "file_size": Number((size / 1048576).toFixed(3)),
                            "file_extension": filetype,
                            "data": filedata
                        }
                    ]
                }
                // console.log(picdata)
                axios.post(`${URL.upload}`, picdata, headers()).then(res => {
                    // console.log(res)
                    // setimage(res.data)
                    setShow(false)
                    handleAdd(res.data)
                })
            }
        } else {
            // console.log('nothing')
            handleAdd()
        }
    }

    const handleVisibility = async (acc) => {
        let data = {
            "date": moment(acc.date).format('YYYY-MM-DD'),
            "summary": acc.summary,
            "name": acc.name,
            "id": acc.id,
            "typeOfAccomplishment": acc.typeOfAccomplishment,
            "userId": sessionStorage.getItem("userId"),
            "status": acc.status == "SHOW" ? "HIDE" : "SHOW",
            "metadata": await getMetaData(acc.metadata),
            "referenceId": acc.referenceId
        };

        UpdateAcc(data, acc.id);
    }



    const handleChange = (name, value) => {
        if (name === 'organisation') {
            // console.log("orzzzz")
            setState({ ...state, [name]: value })
        }
        else {
            setState({ ...state, [name]: value })
            // console.log([name], value)
        }
    }
    const handleOrg = (name, value, newOrg, logo) => {
        // console.log(logo, name, value, newOrg)
        if (newOrg) {
            setState({ ...state, organisationName: name, organisation: 0, unofficialOrganisation: 0, unofficialOrganisationName: name })
        } else {
            setState({ ...state, organisationName: name, organisation: value, unofficialOrganisation: 0, unofficialOrganisationName: "", logo: logo })
        }
    }

    const handleAdd = async (image) => {
        // console.log(image)
        let data = {
            "date": state.date ? (moment(state.date).format('YYYY-MM-DD')) : (state.accomplishmentCategory.value === "News_or_Media") ? new Date() : null,
            "accomplishmentCategory": state.accomplishmentCategory.value,
            "summary": state.summary ? state.summary : metatags?.description || meta?.description,
            "attachment": image ? image[0] : metatags?.image || meta?.image,
            "title": state.title ? state.title : metatags?.title || meta?.title,
            "userId": Number(sessionStorage.getItem("userId")),
            "status": "SHOW",
            "metadata": await getMetaData(),
            "coAuthors": state.coAuthors,
            "link": state.link,
            "organisation": {
                "displayName": state.organisationName,
                "id": state.organisation,
                "system": ""
            }
        }
        // console.log(data)
        axios.post(`${URL.addAccomplishment}`, data, headers())
            .then((response) => response.data)
            .then(data => {
                // console.log(data.data)
                setBadges(data.data)
                dispatch(accomplishment(data.data))
                setShow(true)
                setPdfviewer([])
                setFileimg([])
                // getAcc();
            })
            .catch((error) => { })
    }
    // console.log(badges)




    const handleCancel = () => {
        // console.log("delete")
        setOpen(false)
        setShow(true)
        // handleDelete()
        setEdit(false)
    }

    // console.log(state)
    const handleEdit = (data) => {
        // data.endDate !== null ? setCheck(false) : setCheck(true)
        setEdit(true)
        setOpen(true)
        setState({
            "accomplishmentCategory": data.accomplishmentCategory,
            "attachment": data.attachment ? data.attachment : image,
            "coAuthors": data.coAuthors,
            "date": data.date ? (moment(data.date).format('YYYY-MM-DD')) : "",
            "id": data.id,
            "link": data.link,
            "logo": data.logo,
            "organisation": data.organisation.id,
            "organisationName": data.organisation.displayName,
            "summary": data.summary,
            "title": data.title,
            // "unofficialOrganisation": data.unofficialOrganisation,
            // "unofficialOrganisationName": data.unofficialOrganisationName,
        })
    }
    const handleUpdate = async () => {
        // console.log(state)
        let data = {
            "accomplishmentCategory": state.accomplishmentCategory.value,
            "attachment": state.attachment ? state.attachment : image[0] || metatags?.image || meta?.image,
            "coAuthors": state.coAuthors,
            "date": state.date ? (moment(state.date).format('YYYY-MM-DD')) : "",
            "id": state.id,
            "link": state.link,
            "organisation": {
                "displayName": state.organisationName,
                // "displayName": state.organisation ? state.organisation.displayName : typeof (state.organisationName) === 'string' ? state.organisationName : state.unofficialOrganisationName,
                "id": state.organisation || state.unofficialOrganisation,
                "system": ""
            },
            "summary": state.summary ? state.summary : metatags?.description || meta?.description,
            "title": state.title ? state.title : metatags?.title || meta?.title,
            "userId": Number(sessionStorage.getItem("userId")),
            // "status": badges.status,
            "metadata": await getMetaData(),
            "referenceId": state.referenceId,
            // "typeOfAccomplishment": badges.typeOfAccomplishment,
        }
        // console.log(data)
        UpdateAcc(data, state.id)
    }

    const UpdateAcc = (data, id) => {
        axios.put(`${URL.updateAccomplishment}/${id}`, data, headers())
            .then((response) => response.data)
            .then(data => {
                // console.log(data.data)
                // setBadges(data.data)
                dispatch(accomplishment(data.data))
                // getAcc();
            })
            .catch((error) => { })
        setOpen(false)
        // setEdit(false)
    }

    const handleDelete = (id) => {
        axios.delete(`${URL.deleteAccomplishment}/${id}`, headers())
            .then((response) => {
                dispatch(accomplishment())
                // getAcc();
            })
            .catch((error) => { })
    }

    const addFun = () => {
        setOpen(true)
        setState({
            id: null,
            coAuthors: "",
            date: null,
            attachment: null,
            accomplishmentCategory: "",
            organisation: "",
            title: "",
            summary: "",
            link: "",
        })
    }
    useEffect(() => {
        if (state.accomplishmentCategory.value === "News_or_Media") {
            const url = state?.link?.split(" ")
            if (url) {
                const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
                let val1 = regex.test(url[url?.length - 1])
                if (val1 === true) {
                    // axios.post('http://localhost:3001/scrapUrl', { url: url[i] }).then(res => {
                    axios.post(`${process.env.REACT_APP_API_SCRAPE}`, { url: url[url.length - 1] }).then(res => {
                        // console.log(res.data.og);
                        if (res.data !== null) {
                            setMetatags(res.data.og)
                            setMeta(res.data.meta)
                            setUrl(state.link)
                            // metasub(res.data.data)
                        }
                    })
                }
                else {
                    setMetatags({})
                    setMeta({})
                }
            }
        }
    }, [state.link])

    // console.log(acc)

    const onDragEnd = result => {
        if (!result.destination) return;
        const items = reorder(
            acc,
            result.source.index,
            result.destination.index
        );
        // setAcc(items);
        let id = (items.map(i => {
            // console.log(typeof(i.id))
            return i.id
        }))
        // console.log(id)
        let data = {
            "userId": parseInt(sessionStorage.getItem("userId")),
            "orderList": id,
            "recordsType": "ACCOMPLISHMENT"
        }
        axios.put(`${URL.dragDrop}/1`, data, headers()).then(res => {
            // console.log(res)
            dispatch(accomplishment())
            // getAcc()
        }).catch(err => { })
    };

    const handledeleteimage = () => {
        setimage("")
        setShow(true)
        setPdfviewer([])
        setFileimg([])
    }

    // console.log(pdfviewer)

    return (
        props.add ? <Add state={state} formValue={props.value} handleChange={handleChange} cancel={props.close} handleSubmit={() => !props.publication ? props.handleAdd(state, createpost, metatags, meta) : Imagesubmit()} submit={"Submit"} check={check} handleCheck={(e) => setCheck(e.target.checked)}
            handleOrg={handleOrg} imageHandler={imageHandler} badges={badges} show={show} image={image} metatags={metatags} meta={meta} url={url} handleCancel={handleCancel} pdfviewer={pdfviewer} fileimg={fileimg} filename={filename} createpost={createpost}
        />
            : <div className="sidebar__pt">
                {/* CRUD */}
                <DialogBox id="dialog_box_sizes" open={open} handleDialog={() => setOpen(false)}
                    Content={<Add state={state} formValue={props.value} handleChange={handleChange} cancel={() => handleCancel()} handleSubmit={edit ? handleUpdate : Imagesubmit} submit={edit ? "Update" : "Submit"} check={check} handleCheck={(e) => setCheck(e.target.checked)}
                        handleOrg={handleOrg} imageHandler={imageHandler} badges={badges} show={show} image={image} metatags={metatags} meta={meta} url={url} handledeleteimage={handledeleteimage} pdfviewer={pdfviewer} fileimg={fileimg} filename={filename}
                    />}
                // Actions = { <> {edit ? <Button onClick={(e)=>handleUpdate()}>Update</Button> : <Button type="submit">Add</Button>}
                // <Button onClick={()=>handleCancel()}>Cancel</Button></> } 
                />
                {/* View */}
                <DialogBox open={view} handleDialog={() => setView(false)}
                    Content={<ExpView acc={acc} toggleReadMore={toggleReadMore} isReadMore={isReadMore} handleEdit={handleEdit} handleDelete={handleDelete} />}
                    Actions={<Button onClick={() => setView(false)}>Close</Button>}
                    style={{ padding: '0px' }} show={true}
                />
                {/* <Layout HeadIcon={<img src={accomplishments} alt="accomplishment" />}
                Title="Accomplishments" handleAdd={() => addFun()} handleView={() => setView(true)}
                /> */}
                <div className='badges_responsive pt-2'>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={certificate} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.certificatesCount}</div> */}
                        <div className="sidebar__statNumber"><span>{data.certificatesCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Certifications</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={ribbon} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.badgesCount}</div> */}
                        <div className="sidebar__statNumber"><span>{data.badgesCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <img src={medal} alt="certificate" />
                        {/* <div className="sidebar__statNumber">{user.achievementsCount}</div> */}
                        <div className="sidebar__statNumber"><span>{data.achievementsCount}</span></div>
                        <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                    </div>
                </div>
                <div className='tohideScrollbar' id="margin_acc">
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton aria-label="visible" onClick={() => addFun()}>
                            <GrAddCircle color='#2E3B43' />
                        </IconButton>
                        <IconButton aria-label="visible" onClick={() => setView(true)}>
                            <FiEdit3 color='#2E3B43' />
                        </IconButton>
                    </div>
                    {acc.length > 0 ?
                        acc.slice(0, count).map((e, id) => (
                            <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }} className="sidebar__padding bg__white e-align icon-show" key={e.id}>
                                <div className="sidebar__substat ta__center" style={{ width: "15%" }}>
                                    <p style={{ boxShadow: 'none', borderRadius: '0px' }} className="my-img domain-icon">
                                        <img src={e.typeOfAccomplishment === "BADGES" ? Badge : e.typeOfAccomplishment === 'CERTIFICATES' ? Certificate : achevments} alt="ribbon"></img>
                                    </p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "65%" }}>
                                    <div className="sidebar__ml " style={{ textTransform: 'capitalize', fontSize: '13px' }}>
                                        <p style={{ fontWeight: 'normal', paddingBottom: '3px', fontSize: "14px" }}>{e.title ? e.title : 'N/A'} </p>
                                        {isReadMore ?
                                            <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary?.slice(0, 150)}</span> : <span style={{ paddingBottom: '3px', fontSize: "12px", color: "rgb(118, 118, 118)" }}>{e?.summary}</span>
                                        }
                                        {e.summary && e.summary.length > 100 ?
                                            <span onClick={toggleReadMore} className="read-or-hide">
                                                {isReadMore ? "...read more" : " show less"}
                                            </span> : ""
                                        }
                                        <p style={{ paddingTop: "5px", fontSize: "11px", textTransform: "lowercase", color: "rgb(118, 118, 118)" }}>{e.link ? (e.link).substr(0, 100) : 'N/A'}</p>
                                    </div>
                                </div>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    <p className='date_acc_text'>{(e.date) ? moment(e.date).format('DD MMM yyyy') : ""}</p>
                                </div>
                            </div>
                        ))
                        :
                        <div className="sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                            <div className="sidebar__substat ta__center">
                                <p style={{ textAlign: "center" }}>No Accomplishments</p>
                            </div>
                        </div>
                    }
                    <div className="work__space sidebar__padding bg__white e-align icon-show">
                        <div className="sidebar__substat ta__center">
                            {/* {acc.length > 3 ? count === 3 ? <p onClick={() => setCount(acc.length)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>All Accomplishments</p> : <p onClick={() => setCount(3)} className="cursor" style={{ textDecoration: 'underline', color: '#700eca' }}>See Less</p> : ''} */}
                            {acc.length > 5 ? count === 5 ?
                                <p id="border_exp_btn" onClick={() => setCount(acc.length)} className="cursor d-flex e-align" style={{ color: '#003BFF' }}>All Accomplishments
                                    <MdOutlineKeyboardArrowDown />
                                </p> : <p onClick={() => setCount(5)} className="cursor" style={{ color: '#003BFF' }}>Hide</p> : ''}
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={alert.post ? null : 2000}
                    open={alert.open}
                    onClose={() => setAlert({ open: false, msg: '', post: false })}
                    message={alert.msg}
                />
            </div>
    );
}