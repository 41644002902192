import { Grid, List, ListItem, Divider, ListItemIcon, ListItemText, ListItemButton, RadioGroup, FormControl, FormControlLabel, Radio, Drawer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import { FaSyringe } from 'react-icons/fa';
import { MdDoNotDisturb, MdOutlineTipsAndUpdates, MdOutlineDangerous, MdOutlinePrecisionManufacturing, MdOutlineBloodtype, MdTrackChanges, MdOutlineLocalShipping, MdOutlineLocalLibrary, MdOutlineBadge } from 'react-icons/md';
import { useRouteMatch } from 'react-router';
import warn_icon from '../../Atom/svg/warn_icon.svg'
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios'
import './DoseDetailsRightSide.css'
import DruginformationHeader from '../Elibrary/DruginformationHeader';
import URL from '../../apiurl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import UserService from '../../../services/UserService';
import MenuIcon from '@mui/icons-material/Menu';


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/styles';
import Button from '@mui/material/Button';
import { socket } from '../../../socket';
import { makeStyles } from '@mui/styles';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))
    (({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: '0px',
            width: '100%',
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

const drawerWidth = 250
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '6%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: "rgb(0, 0 ,0)",

    },
    paper: {
        position: 'static!important'
    },
    //warning drawer bg color and text color
    background: {
        backgroundColor: "black",
        // position:"absolute",
        // top:'140px',
        // right:'12px'
    },
    color: {
        color: 'white'
    },

    drawerOpen: {
        width: drawerWidth,

        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,

        }), backgroundColor: "green",
        "& .MuiDrawer-paperAnchorDockedLeft": {
            borderRight: "none",

        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "green",

        overflowX: 'hidden',
        width: theme.spacing(9) + 5,
        //  [theme.breakpoints.up('md')]: {
        //     width: theme.spacing(8)+1
        // },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9) + 5
        }
    },
    selected: {
        borderLeft: '7px solid #A0D890'
    }
})
)

function SpecialCaseRightSide() {

    {/* for listitems  changing*/ }
    const [title, setTitle] = useState("Dosing and Using");
    const [Icon, setIcon] = useState(FaSyringe);
    const classes = useStyles();
    const maxWidth = useMediaQuery('(max-width: 770px)')
    const minWidth = useMediaQuery('(min-width: 769px)')
    const [drugInfo, setDrugInfo] = useState([])
    const [value, setValue] = useState('')
    const [warnData, setWarnData] = useState([])
    const [warn, setWarn] = React.useState(false);
    const { url } = useRouteMatch();
    const [headingtitle, setHeadingtitle] = useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openmenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const list = [
        { title: 'Medication Id', img: <MdOutlineBadge style={{color:"black"}} /> },
        { title: 'Dosing and Using', img: <FaSyringe style={{color:"black", fontSize: '26px' }} /> },
        { title: 'Medication Safety', img: <MdOutlineDangerous style={{color:"black", fontSize: '26px' }} /> },
        // {title: 'Black Box Warning',img: <MdDoNotDisturb style={{fontSize:'26px'}}/>},
        // {title: 'Adverse Effects',img: <MdOutlineDangerous style={{fontSize:'26px'}}/>},
        { title: 'Mechanisms', img: <MdOutlinePrecisionManufacturing style={{color:"black", fontSize: '26px' }} /> },
        { title: 'Kinetics', img: <MdOutlineBloodtype style={{color:"black", fontSize: '26px' }} /> },
        { title: 'Followup', img: <MdTrackChanges style={{ color:"black",fontSize: '26px' }} /> },
        { title: 'Clinical Guide', img: <MdOutlineTipsAndUpdates style={{ color:"black",fontSize: '26px' }} /> },
        { title: 'References', img: <MdOutlineLocalLibrary style={{color:"black", fontSize: '26px' }} /> },
        { title: 'Supply Chain', img: <MdOutlineLocalShipping style={{color:"black", fontSize: '26px' }} /> }
    ]
    const [selected, setSelected] = React.useState(1)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
        setTimer(new Date())
        let path = window.location.pathname
        return () => {
            // console.log(timer)
            socket.emit("unmount", path, 'drugAnalytics')
        }
    }, [])

    useEffect(() => {
        if (timer) socket.emit("articles", ({ start: timer, userId: UserService.getUserId(), path: window.location.pathname, type: 'drugAnalytics' }))
    }, [timer])


    const handlerChange = (event) => {
        setSelected(event.target.value)
    }
    {/*Drawer */ }
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    {/*Warning Popup */ }
    const handleclickOpen = () => {
        setWarn(true);
        getArticle('Black_Box_Warning', false)
    }
    const handleclickClose = () => {
        setWarn(false);
    }

    const handleTitle = (title, Icon, index) => {
        setTitle(title)
        setIcon(Icon)
        setSelected(index)
    }
    //    const handleIcon=(Icon)=>{
    //        setIcon(Icon)
    //    }

    useEffect(() => {

        getArticle(title, true)

    }, [title])

    const getArticle = (title, val) => {
        let u = url.split('/')
        u = u[u.length - 1].split('-')
        // console.log(u[u.length - 2])
        let datatitle = (u[u.length - 2]).replace(/_/g, '')
        setHeadingtitle(datatitle)
        if (title === 'Medication Id') {

            axios.get(`${URL.getDrugSummary}${u[u.length - 1]}-${title.replace(/ /g, '_')}`).then(res => {
                // console.log(res)
                if (res.status === 200) {
                    val ? setDrugInfo(res.data.data.sort((a, b) => a.id > b.id ? 1 : -1)) : setWarnData(res.data)

                }
            }).catch(res => { })
        }
        else if (UserService.getToken() !== undefined) {
            axios.get(`${URL.getArticles}${u[u.length - 1]}-${title.replace(/ /g, '_')}`, {
                headers: {
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            }).then(res => {
                // console.log(res.data)
                val ? setDrugInfo(res.data.data.sort((a, b) => a.id > b.id ? 1 : -1)) : setWarnData(res.data.data)

            }).catch(res => { })
        }
        else {
            UserService.doLogin()
        }
    }

    return (

        <div className="DoseDetails" style={{ width: '100%', overflow: 'hidden', paddingBottom: '0px' }}>
            <DruginformationHeader />

            <div className="DoseDetails_body" style={{ height: 'calc(100vh - 180px)', overflow: "scroll" }}>
                {minWidth ?
                    <Grid container style={{ height: 'inherit', paddingBottom: '0px' }}>
                        {/* <Grid item style={{marginTop:'-10px'}}> */}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                height: '100%'
                            }}
                            anchor="left"
                            open={true}
                            // open={open}
                            variant="permanent"
                            // onMouseOver={handleDrawerOpen}
                            // onMouseOut={handleDrawerClose}
                            className={clsx(classes.drawer, {
                                // [classes.drawerOpen]: open,
                                // [classes.drawerClose]: !open,
                            })}
                            classes={{
                                paper: clsx(classes.paper, {
                                    [classes.drawerOpen]: open,
                                    // [classes.drawerClose]: !open,
                                }),
                            }}
                        >
                            <List sx={{ width: '100%', color: '' }}>
                                {list.map((listItem, index) => (

                                    <ListItem sx={{ width: '100%', paddingX: '0px!important' }} key={index} onClick={() => handleTitle(listItem.title, listItem.img, index)}>
                                        <ListItemButton selected={selected === index} classes={{ selected: classes.selected }} sx={{ paddingX: '24px!important' }}>
                                            <ListItemIcon style={{ color: 'white', fontSize: '26px' }}>
                                                {listItem.img}
                                            </ListItemIcon>
                                            <ListItemText primary={listItem.title} />
                                        </ListItemButton>
                                    </ListItem>

                                ))}
                            </List>
                        </Drawer>
                        {/* </Grid> */}
                        {/* <Grid item className={`${open ? "rightside-width" : 'right-width'}`} sx={{ height: 'inherit', overflow: 'scroll' }}> */}
                        <Grid item className="rightside-width" sx={{ height: 'inherit', overflow: 'scroll' }}>
                            <div className="update_details">
                                <div>
                                    <List>
                                        <ListItem style={{ fontSize: '26px', fontWeight: '500' }}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {/* <MdDoNotDisturb style={{fontSize:'26px'}}/>                    */}
                                                    {/* <Icon style={{color:'#000',fontSize:'26px'}}/> 
                                                {Logo} */}
                                                    {Icon}
                                                </ListItemIcon>
                                                <ListItemText primary={title.replace(/_/g, ' ')} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </div>
                                <div className="updated_content">
                                    {/* <div>
                                            <h6>
                                                Last Updated
                                            </h6>
                                            <p>
                                                12-8-2021
                                            </p> 
                                       </div> */}
                                    <div className="warning">
                                        <img src={warn_icon} style={{ margin: '7px', position: 'absolute', right: '10px', cursor: 'pointer' }} onClick={handleclickOpen} />
                                    </div>
                                    {warn ? <ClickAwayListener onClickAway={handleclickClose}>
                                        <Drawer
                                            // open={open}
                                            // sx={{width: '0px'}}
                                            variant="persistent"
                                            anchor="right"
                                            open={warn}
                                            // onClick={handleclickClose}
                                            onClose={handleclickClose}
                                            classes={{ paper: classes.background }}
                                            style={{ display: `${warn ? "block" : 'none'}` }}
                                        >
                                            <div style={{ width: '300px', color: 'white' }}>
                                                <div style={{ height: '57px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <WarningIcon />
                                                    <h3 className="fonthead" style={{ padding: '5px' }}>Blackbox Warning</h3>
                                                </div>
                                                <Divider style={{ background: 'white' }} />
                                                <div>
                                                    {warnData.map(title =>
                                                        // <p style={{padding:'14px',wordBreak:'break-word'}}>{title.body} </p>
                                                        <div style={{ fontFamily: 'inherit', padding: '10px 15px 10px 5px' }} className="article-para" dangerouslySetInnerHTML={{ __html: title.body }}></div>
                                                    )}
                                                </div>
                                            </div>
                                        </Drawer>
                                    </ClickAwayListener> : ''}
                                </div>
                            </div>
                            <div className="radioGroups">
                                <FormControl>
                                    <RadioGroup row className="radio" value={value}
                                        onChange={(e) => {
                                            setValue(e.target.value)
                                            // console.log(e.target.value)
                                            const element = document.getElementById(`${e.target.value}`);
                                            // console.log(element)
                                            element.scrollIntoView()
                                        }}
                                    >
                                        {drugInfo.map(title => (
                                            <FormControlLabel key={title.id} className="capitalize" value={title.child_subtitle.replace(/\s+/g, '-').toLowerCase()} control={<Radio size="small" />} label={title.child_subtitle} />
                                        ))}

                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div style={{ padding: '10px 20px' }}>
                                {drugInfo.map(title => (
                                    <div key={title.id}>
                                        <p id={`${title.child_subtitle.replace(/\s+/g, '-').toLowerCase()}`} key={title.id} className="capitalize font-weight" style={{ fontSize: '20px' }}>{title.child_subtitle}</p>
                                        <br />
                                        <div style={{ fontFamily: 'inherit' }} className="article-para" dangerouslySetInnerHTML={{ __html: title.body }}></div>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid> : ""}
                {maxWidth ?
                    <div>
                        <Button
                            id="demo-customized-button"
                            aria-controls={openmenu ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openmenu ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            // endIcon={<KeyboardArrowDownIcon />}
                            style={{ width: "100%", display: "block", position: "sticky", zIndex: "1000", top: "0px", backgroundColor: "green" }}
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div>
                                    <span style={{ fontSize: "16px", textTransform: "capitalize" }}>
                                        {headingtitle?.length > 0 ? headingtitle?.substr(0, 60) : ""}
                                    </span>
                                </div>
                                <div>
                                    <MenuIcon />
                                </div>
                            </div>
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={openmenu}
                            onClose={handleClose}
                            style={{ marginTop: "0px" }}
                        >
                            <MenuItem className='padding_menu' style={{ display: "block", backgroundColor: "green", marginRight: "0px" }} onClick={handleClose} disableRipple>
                                {list.map((item, index) => (
                                    <div onClick={() => handleTitle(item.title, index, item.img)} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "10px" }} key={index}>
                                        {/* <img src={item.img} style={{ color: 'white', fontSize: '26px' }}></img> */}
                                        <span style={{ fontSize: "18px", fontWeight: "normal", color: "white" }}>{item.title}</span>
                                    </div>
                                ))}
                            </MenuItem>
                        </StyledMenu>
                        {/* <Grid item className={`${open ? "rightside-width" : ''}`} sx={{ height: 'inherit', overflow: 'scroll' }}> */}
                        <Grid item className="rightside-width" sx={{ height: 'inherit', overflow: 'scroll' }}>
                            <div className="update_details">
                                <div>
                                    <List>
                                        <ListItem style={{ fontSize: '26px', fontWeight: '500' }}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {/* <MdDoNotDisturb style={{fontSize:'26px'}}/>                    */}
                                                    {/* <Icon style={{color:'#000',fontSize:'26px'}}/> 
                                                {Logo} */}
                                                    {Icon}
                                                </ListItemIcon>
                                                <ListItemText primary={title.replace(/_/g, ' ')} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </div>
                                <div className="updated_content">
                                    {/* <div>
                                            <h6>
                                                Last Updated
                                            </h6>
                                            <p>
                                                12-8-2021
                                            </p> 
                                       </div> */}
                                    <div className="warning">
                                        <img src={warn_icon} style={{ margin: '7px', position: 'absolute', right: '10px', cursor: 'pointer' }} onClick={handleclickOpen} />
                                    </div>
                                    {warn ? <ClickAwayListener onClickAway={handleclickClose}>
                                        <Drawer
                                            // open={open}
                                            // sx={{width: '0px'}}
                                            variant="persistent"
                                            anchor="right"
                                            open={warn}
                                            // onClick={handleclickClose}
                                            onClose={handleclickClose}
                                            classes={{ paper: classes.background }}
                                            style={{ display: `${warn ? "block" : 'none'}` }}
                                        >
                                            <div style={{ width: '300px', color: 'white' }}>
                                                <div style={{ height: '57px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <WarningIcon />
                                                    <h3 className="fonthead" style={{ padding: '5px' }}>Blackbox Warning</h3>
                                                </div>
                                                <Divider style={{ background: 'white' }} />
                                                <div>
                                                    {warnData.map(title =>
                                                        // <p style={{padding:'14px',wordBreak:'break-word'}}>{title.body} </p>
                                                        <div style={{ fontFamily: 'inherit', padding: '10px 15px 10px 5px' }} className="article-para" dangerouslySetInnerHTML={{ __html: title.body }}></div>
                                                    )}
                                                </div>
                                            </div>
                                        </Drawer>
                                    </ClickAwayListener> : ''}
                                </div>
                            </div>
                            <div className="radioGroups">
                                <FormControl>
                                    <RadioGroup row className="radio" value={value}
                                        onChange={(e) => {
                                            setValue(e.target.value)
                                            // console.log(e.target.value)
                                            const element = document.getElementById(`${e.target.value}`);
                                            // console.log(element)
                                            element.scrollIntoView()
                                        }}
                                    >
                                        {drugInfo.map(title => (
                                            <FormControlLabel key={title.id} className="capitalize" value={title.child_subtitle.replace(/\s+/g, '-').toLowerCase()} control={<Radio size="small" />} label={title.child_subtitle} />
                                        ))}

                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div style={{ padding: '10px 20px' }}>
                                {drugInfo.map(title => (
                                    <div key={title.id}>
                                        <p id={`${title.child_subtitle.replace(/\s+/g, '-').toLowerCase()}`} key={title.id} className="capitalize font-weight" style={{ fontSize: '20px' }}>{title.child_subtitle}</p>
                                        <br />
                                        <div style={{ fontFamily: 'inherit' }} className="article-para" dangerouslySetInnerHTML={{ __html: title.body }}></div>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </Grid>

                    </div>
                    : ""
                }
            </div>
        </div>
    )
}

export default SpecialCaseRightSide
