import { Box, Button, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import UserService from '../../../services/UserService'
import URL from '../../apiurl'
import axios from 'axios'
import { comp, redirect_uri } from './linkaccount'
import { headers } from '../../../redux/Actions/connectActions'
import './linkaccount.css'
import { HiLink } from 'react-icons/hi'
import { LinkedIn } from '@mui/icons-material'
import ReplyIcon from '@mui/icons-material/Reply';
import TwitterIcon from '@mui/icons-material/Twitter';

const S_APIS = {
    'de71b7e4-6610-47bd-9dd1-1b1886d9abc9': { token: URL.linkedInToken, post: URL.linkedInPost, title: 'linkedin', Icon: <LinkedIn style={{ color: '#007DCD', width: "75px", height: "75px", marginLeft: "20px" }} /> },
    'da8e1439-fb1a-447e-b481-cb5e5028e521': { token: URL.twitterToken, post: URL.twitterPost, title: 'twitter', Icon: <TwitterIcon style={{ color: 'rgb(29, 155, 240)', width: "75px", height: "75px", paddingRight: "20px" }} /> }
}

export default function ShareProfile() {

    const history = useHistory()
    let location = useLocation().search

    const params = new URLSearchParams(location)

    const loc = location?.includes('?')

    const [post, setPost] = useState(``)
    const [metatags, setMetatags] = useState({})
    const [meta, setMeta] = useState({})
    const [share, setShare] = useState(1)
    const [desc, setDesc] = useState('')

    let state = params.get("state") && params.get("state").split("_")
    const id = state && state[1].split("-")[1]

    useEffect(() => {

        if (loc) {

            let data = {
                code: params.get("code"),
                state: state[0],
                redirect_uri: `${process.env.REACT_APP_DOCSTETH}/shareprofile`,
            }


            console.log(state, id, data)

            if (state?.[1]?.startsWith('user')) {
                console.log(data)
                axios.post(S_APIS[data.state].token, data, headers())
                    .then(res => {
                        // alert("Account Linked Successfully")
                        history.push(`/shareprofile/?state=${state[0]}_share-${id}`)
                    })
                    .catch(err => {
                        alert("Account Linking Failed")
                        history.push(`/myprofile/${id}-${UserService.getUserId()}`)
                    })
            }
            else if (state?.[1]?.startsWith('profile')) {
                setShare(0)

                axios.post(S_APIS[data.state].post,
                    {
                        ...data,
                        text: `${process.env.REACT_APP_DOCSTETH}/profile/${id}-${UserService.getUserId()}`,
                        description: desc
                    }, headers())
                    .then(res => {
                        alert(`Your Docsteth Profile has been successfully shared to ${S_APIS[state[0]]?.title}`)
                        goback()
                    })
                    .catch(err => {
                        alert(`Failed sharing your profile to ${S_APIS[state[0]]?.title}`)
                        goback()
                    })
            }
        }
    }, [loc])

    const socialShare = () => {
        setPost(`${process.env.REACT_APP_DOCSTETH}/profile/${id}-${UserService.getUserId()}`)
        setShare(2)
    }

    const goback = () => {
        history.push('/')
    }

    useEffect(() => {
        if (post) {
            axios.post(`${process.env.REACT_APP_API_SCRAPE}`, { url: post }).then(res => {
                console.log(res.data)
                if (res.data !== null) {
                    setMetatags(res.data.og)
                    setMeta(res.data.meta)
                    // setText(res.data.og.url || res.data.meta.url)
                }
            })
        }
    }, [post])

    const sharePost = () => {
        window.location.href = redirect_uri('profile', id)[S_APIS[state[0]]?.title]
    }

    return (
        <Box className="ssr_layout " id='comment_margin_end'>
            {params.get("error") === null && (share === 1 ?
                <div className='textCenter'>
                    <Paper className="display_view" sx={{ height: '250px' }}>
                        <div>
                            <p className='social_share_h'>Your Docsteth Account Successfully Linked</p>

                            <div className='display_view mt-20'>
                                <img src={'https://docstethprodimages.s3.ap-south-1.amazonaws.com/Logo2.svg'} width="70px" height="70px" style={{ marginRight: "20px" }} />
                                <HiLink style={{ fontSize: '35px' }} />
                                {S_APIS[state[0]]?.Icon}
                            </div>
                        </div>
                    </Paper>
                    <div className='f-20'>
                        <p className='mt-20'>Start With</p>
                        <p className='mt-10'>Share Your Docsteth Profile in <span className='capitalize'>{S_APIS[state[0]]?.title}</span></p>
                        <Button sx={{ mt: '20px!important' }} className="capitalize f-20 ss_btn" variant="contained" onClick={socialShare}>Share &nbsp;<ReplyIcon sx={{ transform: 'scaleX(-1)' }} /></Button>
                        <p><Button href={`/myprofile/${id}-${UserService.getUserId()}`} className="capitalize ss_skip">Skip</Button></p>
                    </div>
                </div>

                :
                share === 2 && <Paper className="Mobilepost__header mt-10" style={{ padding: "15px", overflowY: 'auto' }}>
                    {/* <p style={{ fontSize: "18px"}}>Account Linked Successfully.</p> 
                <p className="mt-10 d-flex e-align" style={{ fontSize: "18px"}}>Do you want to share your docsteth profile to linkedin ? &nbsp;
                <Button className="post_btn add-option color_white capitalize" sx={{mt: '0!important'}}>Share</Button>
                </p> */}
                    <h3>Share to <span className='capitalize'>{S_APIS[state[0]]?.title}</span></h3>
                    <div className='mt-10'>
                        <textarea value={desc} onChange={e => setDesc(e.target.value)} placeholder="Write something here" autoFocus className="w-100 ss_textarea" style={{ borderColor: 'transparent', height: '80px' }} />
                    </div>
                    {metatags?.url || meta?.url ?
                        (< div style={{ borderRadius: "12px", padding: "15px" }}>
                            <div style={{ position: "relative" }}>
                                <div>
                                    {(metatags?.image) ? <img className='w-100' style={{ aspectRatio: '5/2' }} src={(metatags?.image) ? metatags?.image : (meta?.image) ? (meta?.image) : ""}></img> : ""}
                                </div>
                            </div>
                            <div style={{ paddingLeft: "10px", backgroundColor: "white" }} >
                                <div style={{ display: "flex", paddingTop: "8px" }}>
                                    <h3 style={{ fontSize: "15px", color: "blue", fontWeight: "300" }}>{(metatags?.url) ? metatags?.url : (meta?.url) ? meta?.url : ""}</h3>
                                </div>
                                <div style={{ display: "flex", paddingTop: "8px" }}>
                                    <h3 style={{ fontSize: "18px", color: "black", fontWeight: "400" }}>{(metatags?.title) ? metatags?.title : (meta?.title) ? meta?.title : ""}</h3>
                                </div>
                                <p className='ssr_desc' style={{ paddingTop: "8px" }}>{(metatags?.description) ? metatags?.description : ""}</p>
                            </div>
                        </div>
                        )
                        :
                        null}
                    <div className='text-right'>
                        <Button className="capitalize" href={`/myprofile/${id}-${UserService.getUserId()}`}>Cancel</Button>
                        <Button className="capitalize ss_btn" variant="contained" onClick={sharePost}>Share</Button>
                    </div>
                </Paper>)}
        </Box>
    )
}