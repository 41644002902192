import { Box, Button, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { headers } from '../../../../redux/Actions/connectActions'
import URL from '../../../apiurl'
import DocstethLogo from '../../../Atom/svg/docsteth.svg'


const TypoComp = ({ name, bold, display, ...props }) => {
    return (
        <Typography component="p" className={bold ? "font-bold" : display ? "d-flex e-align jc__center" : ''} {...props}>{name}</Typography>
    )
}

const AppDesc = ({ heading, description, mt, pricing }) => {
    return (
        <>
            <TypoComp name={heading} sx={{ mt: mt || 2, fontSize: '17px', fontWeight: 500 }} />
            <p style={{ fontWeight: 300, marginTop: "5px", fontSize: "15px" }}>{description}</p>
            {pricing && <p style={{ fontWeight: 300, marginTop: "5px" }}>{pricing}</p>}
        </>
    )
}

const SidebarAppSubscription = ({ appname, id }) => {
    const [data, setData] = useState([])
    const [show, setShow] = useState(false)

    const onShow = () => {
        setShow(!show)
    }

    useEffect(() => {
        axios.get(URL.findAppById + id, headers()).then(res => setData(res.data.data))
    }, [id])

    return (
        <Box sx={{ padding: { xs: 0, sm: 0, md: "16px" } }} >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={data.logo} alt='' width="40" />
                <div>
                    <Typography>{appname}</Typography>
                    <Typography variant="body2">{data.captionAndTagLine}</Typography>
                </div>
            </div>


            <AppDesc heading="Purpose" description="This app is for doctors to get book and manage patient appointments, get patient appointment requests from multiple patient service applications" />
            <Box sx={{ display: { xs: "block", sm: "block", md: "none", } }}>
                {
                    !show && <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        <Button size="small" variant='outlined' sx={{ padding: '0 8px !important', height: "18px", fontSize: "12px", borderRadius: "10px" }} onClick={onShow} >See More</Button>
                    </div>
                }
                {show &&
                    <>
                        <AppDesc heading="Pricing" description="PAY AS YOU GO " pricing="0.5$ Per Out-Patient Record" />
                        <p style={{ fontWeight: 300, marginTop: "5px" }}>Billed Monthly</p>

                        <div style={{ marginTop: "20px" }}>
                            <p >How to Access</p>
                            <img src={DocstethLogo} height="40px" />
                            <Typography variant="body2" >Use your Docsteth Account To Login</Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", height: "15px", marginTop: "10px" }}>
                            <Button size="small" variant='outlined' sx={{ padding: '0 8px !important', height: "18px", fontSize: "12px", borderRadius: "10px" }} onClick={onShow}>See less</Button>
                        </div>
                    </>
                }
            </Box>

            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <AppDesc heading="Pricing" description="PAY AS YOU GO " pricing="0.5$ Per Out-Patient Record" />
                <p style={{ fontWeight: 300, marginTop: "10px" }}>Billed Monthly</p>

                <div style={{ marginTop: "30px" }}>
                    <p >How to Access</p>
                    <img src={DocstethLogo} height="50px" />
                    <p>Use your Docsteth Account To Login</p>
                </div>
            </Box>
        </Box>
    )
}

export default SidebarAppSubscription