//----------------------------------------------react-pdf

import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./inputOption.css";

// import 'react-pdf/dist/esm/entry.webpack';

// import employmentagreement from '../../../icons/employment.pdf'
// const url =
//     "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"

function PdfView({ data, stateview, image }) {
  console.log(data, "fhgjk", stateview);
  let pdf = data ? data.split(",") : "";
  let url = pdf.slice(0, -1);
  console.log(url, "pdfurl");
  // console.log(image)
  // const accpdf = image
  // console.log(pdf)
  // console.log(url[0])
  const previous = "< previous";
  const next = "next >";
  const history = useHistory();
//   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onpreload(loaded, total) {
    // alert('Loading a document: ' + (loaded / total) * 100 + '%');
  }
  const handleclick = () => {
    history.push({
      pathname: `/pdfviewer`,
      state: url,
    });
  };

  return (
    <>
      <div>
        <div onClick={handleclick}>
          <Document
            // canvasBackground="transparent"
            // file='https://feed-dev-storage.s3.ap-south-1.amazonaws.com/files/220608111143424452.pdf'
            // file={'https://docsteth-feed-dev.s3.ap-south-1.amazonaws.com/files/241028081015277676.pdf'}
            file={`${url}`}
            loading="Please wait!"
            renderMode="canvas"
            // options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={onpreload}
            className={`${stateview ? "pdf_post" : "pdf_route"}`}
          >
            <Page
              // width={300}
              // height={300}
              pageNumber={pageNumber}
              renderAnnotationLayer={true}
              renderTextLayer={false}
              renderForms={true}
            />
          </Document>
        </div>
        <div className="bg_transperency">
          <div>
            <Button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className={`${pageNumber <= 1 ? "buttondisable" : "buttonc"}`}
            >
              {`${previous}`}
            </Button>
          </div>
          <div className="pagec">
            {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
          </div>
          <div>
            <Button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className={`${
                pageNumber >= numPages ? "buttondisable" : "buttonc"
              }`}
            >
              {`${next}`}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PdfView;

//test
// import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import { Button } from "@mui/material";
// import { useHistory } from 'react-router-dom';
// import './inputOption.css';

// function PdfView({ data, stateview }) {
//     let pdfUrl = data ? data.split(",")[0] : ""; // Get the first URL directly
//     console.log(pdfUrl,
//         "pdfUrl"
//     )
//     const history = useHistory();
//     pdfjs.GlobalWorkerOptions.workerSrc =
//         `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//     const [numPages, setNumPages] = useState(null);
//     const [pageNumber, setPageNumber] = useState(1);

//     function onDocumentLoadSuccess({ numPages }) {
//         setNumPages(numPages);
//         setPageNumber(1);
//     }

//     function changePage(offset) {
//         setPageNumber(prevPageNumber => prevPageNumber + offset);
//     }

//     return (
//         <div>
//             <div onClick={() => history.push({ pathname: `/pdfviewer`, state: { pdfUrl } })}>
//                 <Document
//                     file={pdfUrl} // Use the extracted URL
//                     loading="Please wait!"
//                     onLoadSuccess={onDocumentLoadSuccess}
//                     className={`${stateview ? "pdf_post" : "pdf_route"}`}
//                 >
//                     <Page pageNumber={pageNumber} />
//                 </Document>
//             </div>
//             <div>
//                 <Button disabled={pageNumber <= 1} onClick={() => changePage(-1)}>
//                     {"< previous"}
//                 </Button>
//                 <span>{pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}</span>

//                 <Button disabled={pageNumber >= numPages} onClick={() => changePage(1)}>
//                     {"next >"}
//                 </Button>

//                  {/* <Button
//                     type="button"
//                           disabled={pageNumber >= numPages}
//                             onClick={nextPage}
//                             className={`${pageNumber >= numPages ? 'buttondisable' : 'buttonc'}`}
//                         >
//                            {`${next}`}
//                         </Button> */}

//             </div>
//         </div>
//     );
// }

// export default PdfView;

//----------------------------------------------react-file-viewer (scroll props is not availiable)

// import React from 'react';
// import FileViewer from 'react-file-viewer';
// import employmentagreement from '../../../icons/employment.pdf'
// // import { CustomErrorComponent } from 'custom-error';

// function PdfView() {

//     const file = employmentagreement
//     const type = 'pdf'
//     return (
//         <>
//             <FileViewer
//                 fileType={type}
//                 filePath={file}
//             // errorComponent={CustomErrorComponent}
//             // onError={this.onError}
//             />
//         </>
//     )
// }

// export default PdfView

//----------------------------------------------PDFViewer(---not working)

// import React from 'react'
// import PDFViewer from 'pdf-viewer-reactjs'
// import employmentagreement from '../../../icons/employment.pdf'
// function PdfView(props) {
//     // console.log(props.post)
//     // const sourcelink = `https://onlinelibrary.wiley.com/doi/epdf/10.1111/apa.16345`
//     const sourcelink = props.post
//     return (
//         <div>
//             <PDFViewer
//                 document={{
//                     url: employmentagreement,
//                 }}
//             />
//         </div>
//     )
// }

// export default PdfView

//----------------------------------------------pdftron

// import React from 'react';
// import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
// import employmentagreement from '../../../icons/employment.pdf'
// import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

// function PdfView(props) {
//     // console.log()
//     const url = props.post
//     return (
//         <>
//             <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
//                 <div style={{ height: '750px' }}>
//                     <Viewer fileUrl={url} />
//                     {/* <Viewer fileUrl={employmentagreement} /> */}
//                 </div>
//             </Worker>
//         </>
//     )
// }

// export default PdfView

//----------------------------------------------mikecousins

// import React, { useState, useRef } from 'react';
// import Pdf from '@mikecousins/react-pdf';
// import employmentagreement from '../../../icons/employment.pdf'
// // import { usePdf } from '@mikecousins/react-pdf';

// function PdfView(props) {
//     const url = props.post
//     console.log(url)
//     const [page, setPage] = useState(1);
// const canvasRef = useRef(null);

// const { pdfDocument, pdfPage } = usePdf({
//     file: url,
//     page,
//     canvasRef,
// });
// console.log()
// return (
//     <>
//         <Pdf file={url} page={page}>
//             {({ pdfDocument, pdfPage, canvas }) => (
//                 <>
//                     {!pdfDocument && <span>Loading...</span>}
//                     {canvas}
//                     {Boolean(pdfDocument && pdfDocument.numPages) && (
//                         <nav>
//                             <ul className="pager">
//                                 <li className="previous">
//                                     <button
//                                         disabled={page === 1}
//                                         onClick={() => setPage(page - 1)}
//                                     >
//                                         Previous
//                                     </button>
//                                 </li>
//                                 <li className="next">
//                                     <button
//                                         disabled={page === pdfDocument.numPages}
//                                         onClick={() => setPage(page + 1)}
//                                     >
//                                         Next
//                                     </button>
//                                 </li>
//                             </ul>
//                         </nav>
//                     )}
//                 </>
//             )}
//         </Pdf>
{
  /* {!pdfDocument && <span>Loading...</span>}
            <canvas ref={canvasRef} />
            {Boolean(pdfDocument && pdfDocument.numPages) && (
                <nav>
                    <ul className="pager">
                        <li className="previous">
                            <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                                Previous
                            </button>
                        </li>
                        <li className="next">
                            <button
                                disabled={page === pdfDocument.numPages}
                                onClick={() => setPage(page + 1)}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            )} */
}
// {/* </>
//     )
// }

// export default PdfView
