import { ValidatorForm } from 'react-material-ui-form-validator'
import TextField from '../Fields/TextFields'
import { Grid, Container, Button } from '@mui/material'
import { useState } from 'react'

const CustomButton = (props) => {
    return (
        <Grid item xs={12} sx={{ textAlign: props.align, mt: 2, px: { md: 5, sm: 2 } }}>
            <Button type="submit" className="con__btn" sx={{ py: '3px!important', px: '20px!important', borderRadius: '10px', fontSize: '17px' }}>
                {props?.name}
            </Button>
        </Grid>
    )
}

export const Form1 = (props) => {
    const { data, handleChange } = props
    return (
        <Container sx={{ px: { lg: 13, md: 10 }, mt: 8, mb: 7 }}>
            <ValidatorForm onSubmit={props.next}>
                <Grid container>
                    <Grid item sm={6} xs={12} sx={{ px: { md: 5, sm: 2 } }}>
                        <TextField name="firstName" label="First Name" handleChange={handleChange} value={data?.firstName} /><br />
                        <TextField name="systemOfPractice" label="System of Practice" handleChange={handleChange} value={data?.systemOfPractice} /><br />
                        <TextField name="speciality" label="Speciality" handleChange={handleChange} value={data?.speciality} /><br />
                        <TextField name="organisation" label="Organisation" handleChange={handleChange} value={data?.organisation} /><br />
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{ px: { md: 5, sm: 2 } }}>
                        <TextField name="lastName" label="Last Name" handleChange={handleChange} value={data?.lastName} /><br />
                        <TextField name="qualification" label="Qualification" handleChange={handleChange} value={data?.qualification} /><br />
                        <TextField name="countryOfPractice" label="Country of Practice" handleChange={handleChange} value={data?.countryOfPractice} /><br />
                        <TextField name="role" label="Role" handleChange={handleChange} value={data?.role} />
                    </Grid>
                    <CustomButton name="Next" align="right" />
                </Grid>
            </ValidatorForm>
        </Container>
    )
}

export const Form2 = () => {

    const [state, setState] = useState(false)
    const [data, setData] = useState({ email: '', phone: '', emailCode: '', otp: '' })

    const verify = () => {
        setState(true)
    }

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value })
    }

    return (
        <Container sx={{ px: { lg: 13, md: 10 }, mt: 8, mb: 7 }}>
            <ValidatorForm onSubmit={() => verify()}>
                <Grid container>
                    <Grid item sm={6} xs={12} sx={{ px: { md: 5, sm: 2 } }}>
                        <TextField name="email" label="Email ID" value={data?.email} handleChange={handleChange}
                            validators={['required', 'isEmail']}
                            errorMessages={['Please Enter Email ID', 'Please enter valid email']}
                        /><br />
                        {state ?
                            <><TextField name="emailCode" label="Email Verification Code" value={data?.emailCode} handleChange={handleChange}
                                validators={['required']}
                                errorMessages={['Please Enter verification code']}
                            /><br /></>
                            : ''}
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{ px: { md: 5, sm: 2 } }}>
                        <TextField name="phone" label="Phone Number" value={data?.phone} handleChange={handleChange}
                            validators={['required']}
                            errorMessages={['Please Enter Phone Number']}
                        /><br />
                        {state ?
                            <><TextField name="otp" label="OTP" value={data?.otp} handleChange={handleChange}
                                validators={['required']}
                                errorMessages={['Please Enter OTP']} /><br /></>
                            : ''}
                    </Grid>
                    {state ? <CustomButton name="Submit" align="center" /> : <CustomButton name="Verify" align="right" />}
                </Grid>
            </ValidatorForm>
        </Container>
    )
}