import React, { useState } from 'react'
import { Typography } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
// import certificate from '../../../../../../icons/Group1.svg';
// import Link from "../../../../../../icons/linksource.svg";
import Link from "../../../../../../../../../icons/linksource.svg";
import certificate from "../../../../../../../../../icons/Group1.svg";

import "./celebrateInDept.css";
import moment from 'moment';
const CelebrateInfoInDept = ({ docFeed }) => {
    const [expand, setExpand] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const filterdata = (docFeed.accomplishmentData) ? docFeed.accomplishmentData : ""
    const linktype = docFeed && (docFeed?.accomplishmentData?.attachment) ? docFeed?.accomplishmentData?.attachment?.split(".") : ""
    const type = linktype ? linktype[linktype.length - 1] : ""
    // console.log(type)
    const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
    const previous = "< previous";
    const next = "next >";
    const history = useHistory()
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    function onpreload(loaded, total) {
        // alert('Loading a document: ' + (loaded / total) * 100 + '%');
    }
    const handleclick = () => {
        // history.push(
        //     {
        //         pathname: `/pdfviewer`,
        //         state: ,
        //     }
        // )
    }

    return (
        <div>
            <div>

                <div className='font-medium' style={{ height: "40px" }}>
                    <div style={{ padding: "0px 10px", fontSize: "14px" }}>{docFeed?.content?.length > 100 ? docFeed?.content?.slice(0, 100) : docFeed?.content}</div>
                    <span>{
                        docFeed?.content?.length > 100 &&
                        <Typography
                            component={"span"}
                            sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                        >
                            {showMore ? "...see less" : "...see more"}{" "}
                        </Typography>

                    }</span>
                </div>
                <div className="font-medium" style={{ height: "60px" }}>

                    <div style={{ padding: "0px 10px", fontSize: "14px" }}>{docFeed?.description?.length > 100 ? docFeed?.description?.slice(0, 100) : docFeed?.description}</div>
                    <span>{
                        docFeed?.description?.length > 100 && <Typography
                            component={"span"}
                            sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                        >
                            {expand ? "...see less" : "...see more"}{" "}
                        </Typography>
                    }
                    </span>
                </div>
                <div style={{ padding: "0px 10px" }}>
                    {docFeed?.tags && docFeed?.tags?.length > 30 ?
                        <>
                            {docFeed.tags ? docFeed.tags?.slice(0, 30)?.replace(/,/g, ' ')?.split(' ')?.map((tag, i) => tag !== "null" && <span style={{ paddingLeft: "" }} key={i} onClick={() => ""}><Typography component="span" style={{ color: "#007dcd" }} className="cursor">{tag}</Typography>&nbsp;</span>) + "..." : ''}
                        </>
                        :
                        <>
                            {docFeed.tags ? docFeed.tags?.replace(/,/g, ' ')?.split(' ')?.map((tag, i) => tag !== "null" && <span style={{ paddingLeft: "" }} key={i} onClick={() => ""}><Typography component="span" className="cursor" style={{ color: "#007dcd" }}>{tag}</Typography>&nbsp;</span>) : ''}
                        </>
                    }
                </div>


                {
                    docFeed && docFeed?.accomplishmentData?.hasOwnProperty("title") ?
                        <div>

                            {

                                (docFeed.accomplishmentData?.typeOfAccomplishment == "CERTIFICATES") ?

                                    <div className='box_shadow'>
                                        {(type === "pdf") ?
                                            <div>
                                                <div onClick={"handleclick"}>
                                                    <Document
                                                        // canvasBackground="transparent"
                                                        file={`${docFeed?.
                                                            accomplishmentData
                                                            ?.attachment}`}
                                                        renderMode="canvas"
                                                        options={{ workerSrc: "/pdf.worker.js" }}
                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                        onLoadProgress={onpreload}
                                                        className={"pdf_post"}
                                                    >
                                                        <Page
                                                            // width={300}
                                                            // height={300}
                                                            pageNumber={pageNumber}
                                                            renderAnnotationLayer={true}
                                                            renderTextLayer={false}
                                                            renderForms={true}
                                                        />
                                                    </Document>
                                                </div>
                                                <div className='bg_transperency'>
                                                    <div>
                                                        <Button
                                                            type="button"
                                                            disabled={pageNumber <= 1}
                                                            onClick={previousPage}
                                                            className="cursor buttonc"
                                                        >
                                                            {`${previous}`}
                                                        </Button>
                                                    </div>
                                                    <div className="pagec">
                                                        {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                                                    </div>
                                                    <div>
                                                        <Button
                                                            type="button"
                                                            disabled={pageNumber >= numPages}
                                                            onClick={nextPage}
                                                            className={`${pageNumber >= numPages ? 'buttondisable' : 'buttonc'}`}
                                                        >
                                                            {`${next}`}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div style={{ display: "flex", justifyContent: "center", height: "200px", overflow: "hidden" }}>
                                                {(docFeed?.accomplishmentData?.attachment) ? <img style={{ width: "100%", height: "inherit", objectFit: "cover" }} src={docFeed?.accomplishmentData?.attachment}></img> : <img style={{ maxWidth: "100%", height: "100px", padding: "30px" }} src={certificate}></img>}
                                            </div>

                                        }
                                    </div>
                                    : ""
                            }


                            <div >
                                <div className='sidebar__pt pl-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

                                    <div style={{ width: "70%" }}>
                                        {(regex.test(filterdata?.link) === true) ? <a href={(regex.test(filterdata?.link) === true) ? filterdata?.link : ""} target="_blank"><p style={{ textTransform: "capitalize", color: "rgb(54, 69, 79)" }} className='title_acc_text sidebar__pt'>{filterdata?.title ? (filterdata?.title) : ""}{" "}<img src={Link}></img></p></a> : <p style={{ textTransform: "capitalize", color: "rgb(54, 69, 79)" }} className='title_acc_text sidebar__pt'>{filterdata.title ? (filterdata.title) : ""}{" "}</p>}
                                        <p style={{ textTransform: "capitalize", marginTop: "10px" }} className='title_acc_text1'>{filterdata?.issuer ? filterdata?.issuer?.displayName : filterdata?.organisation ? filterdata?.organisation?.displayName : filterdata?.organisationName || filterdata?.unofficialOrganisationName}</p>
                                        <div style={{ paddingBlockEnd: "10px", marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: filterdata.coAuthors }} />
                                    </div>
                                    <div style={{ width: "30%", paddingRight: "5px" }}>
                                        {(filterdata?.date) ?
                                            <p style={{ fontSize: "14px", fontWeight: "300", textAlign: "center" }}>DOP:{" "}{moment(filterdata?.date).format('DD-MMM-yyyy')}</p> : ""
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        : ""
                }


                {/* {
                    docFeed.accomplishmentData?.typeOfAccomplishment &&
                    <div>
                        {
                            (docFeed.accomplishmentData?.typeOfAccomplishment == "CERTIFICATES") ?

                                <div className='box_shadow'>
                                    {(type === "pdf") ?
                                        <div>
                                            <div onClick={"handleclick"}>
                                                <Document
                                                    file={`${docFeed?.
                                                        accomplishmentData
                                                        ?.attachment}`}
                                                    renderMode="canvas"
                                                    options={{ workerSrc: "/pdf.worker.js" }}
                                                    onLoadSuccess={onDocumentLoadSuccess}
                                                    onLoadProgress={onpreload}
                                                    className={"pdf_post"}
                                                >
                                                    <Page
                                                        pageNumber={pageNumber}
                                                        renderAnnotationLayer={true}
                                                        renderTextLayer={false}
                                                        renderForms={true}
                                                    />
                                                </Document>
                                            </div>
                                            <div className='bg_transperency'>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        disabled={pageNumber <= 1}
                                                        onClick={previousPage}
                                                        className="cursor buttonc"
                                                    >
                                                        {`${previous}`}
                                                    </Button>
                                                </div>
                                                <div className="pagec">
                                                    {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        disabled={pageNumber >= numPages}
                                                        onClick={nextPage}
                                                        className={`${pageNumber >= numPages ? 'buttondisable' : 'buttonc'}`}
                                                    >
                                                        {`${next}`}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        : <div style={{ display: "flex", justifyContent: "center", maxHeight: "250px", overflow: "hidden" }}>
                                            {(docFeed?.accomplishmentData?.attachment) ? <img style={{ width: "100%", height: "inherit", objectFit: "cover" }} src={docFeed?.accomplishmentData?.attachment}></img> : <img style={{ maxWidth: "100%", height: "100px", padding: "30px" }} src={certificate}></img>}
                                        </div>

                                    }
                                </div>
                                : ""
                        }

                        <div>
                            <div className='sidebar__pt pl-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div style={{ width: "70%" }}>
                                    {(regex.test(filterdata.link) === true) ? <a href={(regex.test(filterdata.link) === true) ? filterdata.link : ""} target="_blank"><p style={{ textTransform: "capitalize", color: "rgb(54, 69, 79)" }} className='title_acc_text sidebar__pt'>{filterdata.title ? (filterdata.title) : ""}{" "}<img src={Link}></img></p></a> : <p style={{ textTransform: "capitalize", color: "rgb(54, 69, 79)" }} className='title_acc_text sidebar__pt'>{filterdata.title ? (filterdata.title) : ""}{" "}</p>}
                                    <p style={{ textTransform: "capitalize", marginTop: "10px" }} className='title_acc_text1'>{filterdata.issuer ? filterdata.issuer.displayName : filterdata.organisation ? filterdata.organisation.displayName : filterdata.organisationName || filterdata.unofficialOrganisationName}</p>
                                    <div style={{ paddingBlockEnd: "10px", marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: filterdata.coAuthors }} />
                                </div>
                                <div style={{ paddingRight: "5px", width: "30%" }}>
                                    {(filterdata.date) ?
                                        <p style={{ fontSize: "14px", fontWeight: "300", textAlign: "center" }}>DOP:{" "}{moment(filterdata.date).format('DD-MMM-yyyy')}</p> : ""
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                } */}


            </div>

        </div>
    )
}

export default CelebrateInfoInDept;