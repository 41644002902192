import peers from '../Atom/modifiedicons/peers.svg';
import team_icon from '../Atom/modifiedicons/team_icon.svg'
import Organisations from '../Atom/modifiedicons/Organisations.svg';
import Grid from '@mui/material/Grid'
import * as React from 'react'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export default function Mutual({ styles, connections }) {

  // console.log("connections"+connections.org)


  const MIcon = ({ num, src, mutual }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
      <>
        {/* <Typography
        
      > */}
        <p
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{ display: 'inline' }} className="cursor"><span style={{ textDecoration: 'underline' }}>{num}</span> <img src={src} style={{ fontSize: '17px', verticalAlign: 'middle' }} /></p>
        {/* </Typography> */}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {mutual?.map(m => (
            <Typography key={m.id} sx={{ p: 1 }}>{m.name}</Typography>
          ))}
        </Popover></>
    )
  }
  return (

    <div>
      <Grid container>
        <Grid item xl={3} lg={4} md={12} sm={2.2} xs={12}>
          {connections?.mutualOrg?.length > 0 || connections?.mutualPeers?.length > 0 || connections?.mutualGroups?.length > 0 ? 'Mutual :' : ''}
        </Grid>
        <Grid item xl={9} lg={8} md={12} sm={9.8} xs={12}>
          {connections?.mutualOrg?.length > 0 ? <MIcon num={connections?.mutualOrg?.length || connections.org} src={Organisations} mutual={connections?.mutualOrg} /> : ''}&nbsp;
          {connections?.mutualPeers?.length > 0 ? <MIcon num={connections?.peers} src={peers} mutual={connections?.peers} /> : ''} &nbsp;&nbsp;
          {connections?.mutualGroups?.length > 0 ? <MIcon num={connections?.groups} src={team_icon} mutual={connections?.groups} /> : ''}
        </Grid>
      </Grid>
    </div>



  );
}
