import * as React from 'react';
import './Post.css';
import { Avatar, Typography, Box } from "@mui/material";
import InputOption from './inputOption';
import Photo from '../feedposts/photo'
import axios from 'axios'
import URL from '../../apiurl'
import { useHistory } from 'react-router';
import Poll from '../feedposts/poll/pollPost'
import PublicIcon from '@mui/icons-material/Public';
import Peers from '../../Atom/svg/peers.svg';
import Doc from '../../../images/Logo2.svg'
import Event from '../feedposts/events/eventPost'
import UserService from '../../../services/UserService';
import moment from 'moment';
import like from '../../../icons/beforeliked.svg'
import Comment from '../../../icons/Discussions.svg'
import Share from '../../../icons/share.svg'
import Divider from '@mui/material/Divider'
import { timeSince, Description, MetaView } from './commonFun';
import ArticleView from '../../Article/articlepost';
import { getPostById as getPost } from '../../../redux/Actions/actions';
import DisplayAcc from '../../Layout/MyProfile/organisation/DisplayAcc';

export const Actions = ({ post }) => {
    // console.log(post)
    const ref = React.useRef();

    return (
        <>
            <Box sx={{ mt: 2 }} className="newcontact-fieldheader ">
                <span style={{ color: '#8b8484', fontSize: '13px' }} className="cursor"><span>{post.likesCount > 0 ? post.likesCount : ''}</span> {post.likesCount > 1 ? 'people liked' : post.likesCount === 1 ? "person liked" : ''}</span>
                <span style={{ color: '#8b8484', fontSize: '13px' }} className="cursor">{post.commentsCount > 0 ? post.commentsCount : ''} {post.commentsCount > 1 ? 'Discussions' : post.commentsCount === 1 ? "Discussion" : ''}</span>
            </Box>
            {post.likesCount > 0 || post.commentsCount > 0 ? <Divider style={{ margin: '10px 0' }} /> : ''}
            <div className="newcontact-fieldheader" ref={ref}>
                <InputOption Src={<img alt="like" src={like} />} likeData={post.likeData} title="Like" color="gray" handleClick={() => UserService.doLogin()} />
                <InputOption Src={<img alt="discussion" src={Comment} />} title="Discussions" color="gray" handleClick={() => UserService.doLogin()} />
                <InputOption Src={<img alt="share" src={Share} />} title="Share" color="gray" handleClick={() => UserService.doLogin()} />
            </div>
        </>
    )
}

export default function PublicPost(props) {
    // console.log(post)
    const history = useHistory()
    const [post, setPost] = React.useState(props.post)
    const [modal, setModal] = React.useState({ open: false, id: '' })
    const [metatags, setMetatags] = React.useState({})
    const [meta, setMeta] = React.useState({})
    const [url, setUrl] = React.useState({})

    const getPostById = async () => {

        let pdata = await getPost(post.id)
        setPost(pdata)
    }
    React.useEffect(() => {
        const url = post.description.replace(/\n/g, ' ').split(' ')
        const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
        let val1 = regex.test(url[url.length - 1])
        if (val1 === true) {
            // axios.post('http://localhost:3001/scrapUrl', { url: url[i] }).then(res => {
            axios.post(`${process.env.REACT_APP_API_SCRAPE}`, { url: url[url.length - 1] }).then(res => {
                if (res.data !== null) {
                    setMetatags(res.data.og)
                    setMeta(res.data.meta)

                }
            })
            // console.log(val1, "vvvvvvvvvvv")
        }
        else {
            setMetatags({})
            setMeta({})
        }
    }, [post.description])

    return (
        <div className="post">
            <div className="post__header">
                {(post.userId !== 0) ?
                    <Avatar src={props.display ? post.sharedData?.profilePic : post.userData?.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important" }} /> :
                    <Avatar src={props.display ? post.sharedData?.profilePic : post.pageData?.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important" }} />
                }
                <div className="post__info">
                    <div>
                        <h2 className="cursor capitalize" style={{ color: 'black' }}><span onClick={() => post.userId !== 0 ? history.push(`/profile/${(post?.userData?.fullName.replace(/ /g, '')).toLowerCase()}-${post.userId}`) : history.push(`/organization/${post.pageId}`)}>{(post.userId !== 0) ? post?.userData?.fullName : post?.pageData?.fullName}</span>
                            <span style={{ textTransform: 'initial', color: '#635660', fontWeight: 'normal', cursor: 'text' }}>
                                {post.shared ? ' shared a post' : ''}
                            </span></h2>
                        <p className="fontItalic" style={{ marginTop: '3px' }}>{(post.userId !== 0) ? post?.userData?.caption : post?.pageData?.caption}</p>
                        <Typography color="black" style={{ marginTop: '4px' }} className="fabdetail e-align">
                            <span>{`${timeSince(moment.utc(post.createDate).local())}`}</span>&nbsp;
                            {post.postPrivacy === 'ANYONE' ? <PublicIcon style={{ fontSize: '19px' }} /> : post.postPrivacy === 'DOCSTETH_USERS' ? <img src={Doc} alt="DOCSTETH USERS" width="22px" /> : <img src={Peers} alt="Peers" width="30px" />}
                        </Typography>
                    </div>

                </div>
            </div>
            {/* {console.log(post.sharedDescription)} */}
            <Typography component="div" style={{ color: '#8b8484', whiteSpace: 'pre-wrap', margin: "0px 10px" }}>
                {post.sharedDescription ?
                    post.sharedDescription !== 'NA' && post.sharedDescription !== null ?
                        <Description description={post.sharedDescription} />
                        : '' : ''}
            </Typography>

            {post.sharedTags ? post.sharedTags.replace(/,/g, ' ').split(' ').map((tag, i) => tag !== "null" && <span style={{ paddingLeft: "10px" }} key={i}><Typography component="span" className="name_hover b-color cursor">{tag}</Typography>&nbsp;</span>) : ''}
            <div className={`${post.shared ? 'shared-styles' : ''}`}>
                {post.shared ?
                    <div className="post__header">
                        <Avatar src={post.sharedData.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important" }} />
                        <div className="post__info">
                            <div>
                                <h2 onClick={() => post.sharedData.sharedFromUserId ? history.push(`/${(post.sharedData.name.replace(/ /g, '')).toLowerCase()}-${post.sharedData.sharedFromUserId}`) : history.push(`/organization/${post.sharedData.sharedFromPageId}`)} className="cursor capitalize" style={{ color: 'black' }}>{post.sharedData.name}</h2>
                                <p className="fontItalic">{post.sharedData.captionAndTagline}</p>
                                <Typography color="black" style={{ marginTop: '4px' }} className="fabdetail e-align">
                                    <span>{`${timeSince(moment.utc(post.sharedData.createDate).local())}`}</span>&nbsp;
                                </Typography>
                            </div>
                        </div>
                    </div> : ''}
                <div className="post__body" style={{ margin: '0px 0px 10px 0px' }}>
                    <Box sx={{ px: 3, wordBreak: 'break-all' }}>
                        {post.content ? <Typography varaint="overline" sx={{ color: '#635660', marginLeft: "10px" }}>{post.content}</Typography> : ''}
                        {post.description && <Description description={post.description} />}
                        {post.tags ? post.tags.replace(/,/g, ' ').split(' ').map((tag, i) => tag !== "null" && <span style={{ marginLeft: "10px" }} key={i}><Typography component="span" className="name_hover b-color cursor">{tag}</Typography>&nbsp;</span>) : ''}
                    </Box>
                </div>

                <Box>

                    {post.postType !== 'TEXT' && post.postType !== 'CELEBRATE' ?

                        post.postType === "IMAGE" ? <Photo url={post.sourceLink.split(',').slice(0, -1)} post={post} getPostById={getPostById} />

                            : post.postType === "POLL" ? <Poll post={post} />

                                : post.postType === 'FILE' ? <iframe height="400px" width="100%" src={`${post.sourceLink[0]}#toolbar=0&navpanes=0&scrollbar=0`} scrolling="no" frameBorder="0" />

                                    : post.postType === 'VIDEO' ? <object width="100%" height="400" data={`${post.sourceLink[0]}`} type="application/pdf">   </object>

                                        : post.postType === 'EVENT' && post.eventData ? <Event post={post} getPostById={getPostById} />

                                            : post.postType === 'ARTICLE' && post.articleData ? <ArticleView post={post} /> : ''

                        : metatags.title || meta.title ? <MetaView metatags={metatags} meta={meta} /> : post.postType === 'CELEBRATE' && post.accomplishmentData && post.accomplishmentData.accomplishmentId !== 0 ? <DisplayAcc badges={post.accomplishmentData} /> : ""}


                </Box>
            </div>
            <Actions post={post} getPostById={getPostById} modal={(id) => setModal({ open: !modal.open, id: id })} />
        </div>
    )
}
