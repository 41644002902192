import SearchIcon from '@mui/icons-material/Search';
import { Box, CircularProgress, ClickAwayListener } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { headers } from '../../../../redux/Actions/connectActions';
import URL from '../../../apiurl';

export const SearchMenu = (props) => {
    const history = useHistory()
    const { load } = props

    return (
        <ClickAwayListener onClickAway={props.handleClick}>
            <div style={{ position: 'relative' }}>
                {props.children}
                <div style={{ maxHeight: '400px', overflow: 'auto', position: 'absolute', backgroundColor: 'white', color: 'black', zIndex: 1, width: '100%', boxShadow: '0px 3px 6px #00000029' }}>
                    {load ? <div style={{ height: '100px' }} className="elib-right__content"><CircularProgress disableShrink size="1.5rem" /></div> :
                        props.data.length > 0 ? props.data.sort((a, b) => a.appName > b.appName ? 1 : -1).map((option, i) => (
                            <Box key={i} sx={{ '& > img': { mr: 2, flexShrink: 0 }, p: '10px' }} className="display_ebm cursor">
                                <p className='text-ellipsis textAlign' style={{ width: '70%' }} onClick={() => history.push(`/marketplace/appvault/${option.appName}-${option.id}`)}>
                                    {option.appName ? option.appName : option.appName}
                                </p>
                                <p style={{ color: 'grey', fontSize: '12px' }} className='capitalize'>
                                    {option.availableFor}
                                </p>
                            </Box>
                        )) : props.value.length > 2 ? <Box sx={{ '& > img': { mr: 2, flexShrink: 0 }, p: '10px' }} className="display_ebm cursor">
                            No Results Found</Box> : ''}
                </div>
            </div>
        </ClickAwayListener>
    )
}


const SearchApps = (props) => {

    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const [load, setLoad] = useState('')

    useEffect(() => {
        if (search) {
            // setLoad(true)
            axios.post(URL.searchApps, {
                searchText: search
            }, headers()).then(res => {
                setData(res.data.data)
                setLoad(false)
            }).catch(() => {
                setData([])
                setLoad(false)
            })
        }
    }, [search])

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    return (
        <>
            <SearchMenu data={data} load={load} value={search} handleClick={() => { setData([]); setSearch('') }}>
                <Box className="header__search" sx={{ marginLeft: '0', width: { xs: "180px", sm: "344px", } }}>
                    <SearchIcon />
                    <input type="text" value={search} onChange={handleSearch} placeholder="Search Apps" className='w-100 h-100' />
                </Box>
            </SearchMenu>
            {props.children}
        </>
    )
}

export default SearchApps