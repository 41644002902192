import { Close, Search } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { getOrganisation, getUserOrg, handleUserOrg } from "../../../../../redux/Actions/regActions";
import { DOC_API, D_API } from "../../../../../services/APIService";
import { URI } from "../../../../utility/uri";
import UserService from "../../../../../services/UserService";
import { FormDivider, FormLayout } from "../../../../common/components/layout";
import { deleteKeys } from "../../../../common/functions/functions";
import EditProfile from "./editProfile";

export const CreateOrg = (props) => {
    const [orgName, setOrgName] = useState('')
    const [org, setOrg] = useState([])
    const [editProfile, setEditProfile] = useState(false)
    const [pageExists, setPageExists] = useState(false)
    const orgData = useSelector(state => state.registration.userOrg);
    const dispatch = useDispatch()

    useEffect(() => {
        DOC_API.get(URI.getPagesByUserId + UserService.getUserId())
            .then(res => {
                console.log(res)
                setOrg(res.data.data)
            })
    }, [])

    const handleEditProfile = (val) => {
        setEditProfile(prev => !prev)
        // dispatch(getUserOrg({}))
        dispatch(handleUserOrg({}));
        dispatch({
            type: "location",
            payload: {}
        });
        if (val == 4) {
            props.close()
        }
    }

    const handlePageExists = () => {
        setPageExists(prev => !prev)
    }

    console.log(editProfile, "editProfile")
    const handleOrg = (name, value) => {
        setOrgName(value)
        console.log(value)
        D_API.get(`${orgData?.id}/${URI.getOrganizationByPageId}pageId/${value?.id}`).then(res => {
            if (res.data?.total === 0) {
                setEditProfile(true)
                DOC_API.get(URI.getPageById + value?.id).then(res => {
                    console.log(res)
                    console.log(res.data.data[0].page)
                    let page = res.data?.data?.[0]?.page
                    let org = page?.organisation
                    const { address } = org
                    let data = {
                        resourceType: "Organization",
                        active: false,
                        name: org?.name,
                        identifier: [{
                            system: `https://${process.env.REACT_APP_DOCSTETH}/`,
                            value: `${UserService.getUserId()}`
                        }, {
                            system: "https://pageId/",
                            value: `${page.id}`
                        }],
                        alias: [],

                        partOf: {
                            display: org?.partOf?.display && org?.partOf?.display !== "NA" ? org?.partOf?.display : typeof (org?.partOf) === 'string' ? org?.partOf : '',
                        },
                        endpoint: [{
                            display: page?.pageWebsite || '',
                            reference: page?.pagePortal || ''
                        }],
                        telecom: org?.telecom?.map(con => ({ system: con?.system?.toLowerCase() || '', use: ['work', 'mobile'].includes(con?.use?.toLowerCase()) ? con?.use?.toLowerCase() : 'work' || '', value: con?.value || '' })).filter(val => val.system && val.use && val.value) || [],
                        address: address?.length > 0 ? [deleteKeys({
                            city: address?.[0]?.city || '',
                            line: typeof (address?.[0]?.line) === 'string' ? address?.[0]?.line?.split(',')?.filter(Boolean) : address?.[0]?.line?.filter(Boolean),
                            state: address?.[0]?.state || '',
                            country: "India",
                            district: address?.[0]?.district || '',
                            postalCode: address?.[0]?.postalCode || '',
                        })] : []
                    }
                    // dispatch(getUserOrg(data))
                    dispatch(handleUserOrg(data))
                })
            }
            else {
                handlePageExists()
            }
        })
    }

    useEffect(() => {
        handleEditProfile()
    }, [])

    return (
        <>
            {editProfile && <EditProfile close={props.close} branch={props.branch} parentOrgObj={props.parentOrgObj} />}
            {/* {editProfile ? <EditProfile close={handleEditProfile}/> : 
        <Dialog id="services" open={true} onClose={props.close} maxWidth="sm" fullWidth>
            {pageExists ? <DialogContent>
            <p>Page already exists. Please select another page.</p>
            <p style={{textAlign:"right"}}><Button onClick={handlePageExists} className="close-btn" variant="outlined">Back</Button></p>
            </DialogContent> : 
            <>
            <DialogTitle sx={{ py: 1,display:'flex',justifyContent:"space-between",fontWeight:"500" }} className="flx-center justify-between fntBold">Create Organisation <Close className="cursPoint" onClick={props.close}/> </DialogTitle>
            <FormDivider />
            <DialogContent sx={{ pt: 0 }}>
                <ValidatorForm onSubmit={() => { }}>
                    <p style={{ fontSize: '17px' }}>Existing Docsteth Organisation Page</p>
                    <Grid container spacing={2}>
                        <FormLayout size={12} type="select"
                            Icon={<Search sx={{ position: "absolute", right: '18px' }} />}
                            options={org} getOptionLabel={(option) => option.name || option || ''}
                            placeholder="Search Docsteth Organisation Page"
                            value={orgName} handleChange={handleOrg} />
                    </Grid>
                </ValidatorForm>

                <p className="pt4 flx-center justify-between" style={{ fontSize: '18px', color: '',paddingTop:"10px",display:'flex',justifyContent:'space-between',alignItems:"center" }}>Docsteth Organisation page do not exist?
                    <Button variant="contained" onClick={handleEditProfile} className="capitalize" sx={{ px: '26px!important', borderRadius: '20px', backgroundColor: '#004D76!important' }}>Create New</Button></p>
            </DialogContent></>}
        </Dialog>
        } */}
        </>
    )
}