import React, { useEffect, useState } from 'react'
import AboutWriteBox from './AboutWriteBox'
import HeaderBox from './HeaderBox'
import ProfileViewSidebar from './ProfileViewSidebar';
import Development from '../EbmKit/development';
import Publication from "./publications/publicaton";
import { handleActiveValue } from '../../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import axios from 'axios';
import UserService from '../../../services/UserService';
import URL from '../../apiurl';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import Header from '../../publicView/header'
import PublicFeed from '../../Layout/MyProfile/organisation/PublicOrganization/publicFeed'
import { useHistory } from 'react-router-dom';
import './ProfileViewSidebar.css';
import '../../Layout/profile/profile.css';
import '../../utility/style.css';
import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tabprofile from './Tabprofile';
import { fetchProfile, getMetaData } from '../../../redux/Actions/actions';
import MenuItem from '@mui/material/MenuItem';
import Overviewpublic from './Overviewpublic';
import PublicProfileTimeline from './PublicProfileTimeline';
import Publicationprofile from './Publicationprofile';
import { headers } from '../../../redux/Actions/connectActions';

function ProfileViewandConnect() {

    let active = JSON.parse(sessionStorage.getItem("public-active")) || '0'

    let { name, id } = useParams()
    const history = useHistory()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [profileData, setProfileData] = useState();
    const [reload, setreload] = useState(false);
    const [profile, setProfile] = useState(parseInt(active.tab));
    const [modal, setModal] = useState({ open: false, name: '' })
    const active_value = useSelector(state => state.userProfile.active)
    const state = useSelector(state => state.userProfile.userProfile)
    const dispatch = useDispatch();
    const maxWidth = useMediaQuery('(max-width: 960px)')
    const minWidth = useMediaQuery('(min-width: 961px)')

    useEffect(() => {
        if (Number(active.id !== id)) {
            handleActive(0)
        }
    }, [id])

    const handleActive = (val) => {
        sessionStorage.setItem("public-active", JSON.stringify({ tab: val, id: id }))
        let v = JSON.parse(sessionStorage.getItem("public-active")).tab
        setProfile(v)
    }

    const request = async (id) => {
        // console.log(id)
        let data = {
            "metadata": await getMetaData(),
            "requestedUser": id,
            "requestingFrom": sessionStorage.getItem('userId'),
            "status": "PENDING",
            "userId": sessionStorage.getItem('userId')
        }
        axios.post(`${URL.addRequest}`, data, headers()).then(res => {
            myreloadingFn()
        }).catch(err => {
            //   setAlert({ open: true, msg: "Internal server error Refresh the page" });
        })
    }
    const requestAcceptFn = async (data, status) => {
        // console.log(data)
        let req = {
            "id": data.request,
            "metadata": await getMetaData(),
            "requestedUser": UserService.getUserId(),
            "requestingFrom": data.user.id,
            "status": status,
            "userId": data.user.id
        }

        axios.put(`${URL.acceptRequest}/${data.request}`, req, headers()).then(res => {
            myreloadingFn()
        })
            .catch(error => { })
    }
    const connectBtnFun = (e, status) => {
        if (status === "Connect") {
            request(profileData.user.id)

        } else if (status === "Connected") {
            setAnchorEl(e.currentTarget);

        } else if (status === 'Requested') {
            setAnchorEl(e.currentTarget);
        }
    }
    const connectionStatusFn = () => {
        let connect = "Connect";
        if (profileData.accepted) {
            connect = "Accepted"
        } else if (profileData.connect) {
            connect = "Connected"
        } else if (profileData.requested) {
            connect = "Requested"
        }
        return connect;
    }
    const requestDisconnectFm = (peerId, userID) => {
        // let data = {
        //     peerId: peerId,
        //     userId: userID
        // };

        axios.delete(`${URL.deletePeersByUser}/${peerId}/user/${userID}`, headers()).then(res => {
            myreloadingFn()
        }).catch(err => {
            //   setAlert({ open: true, msg: "Internal server error Refresh the page" });
        })
    }
    const cancelRequestFn = (reqId) => {
        axios.delete(`${URL.deleteRequestById}/${reqId}`, headers()).then(res => {
            myreloadingFn()
        }).catch(err => {
            //   setAlert({ open: true, msg: "Internal server error Refresh the page" });
        })
    }
    const handleClose = (name) => {
        if (name == 'Disconnect') {
            // handleFollow(value.id)
            if (window.confirm("Do you want to disconnect ?")) {
                requestDisconnectFm(profileData.peerId, profileData.user.id)
            }
        }
        if (name === 'Cancel Request') {
            if (window.confirm("Do you want to cancel request ?")) {
                cancelRequestFn(profileData.requestId)
            }
        }
        setAnchorEl(null);
    }
    const Item = ({ MenuName }) => {
        return (
            <MenuItem onClick={() => handleClose(MenuName)} sx={{ fontSize: '13px' }}>{MenuName}</MenuItem>
        )
    }
    const blockUser = () => {
        axios.post(`${URL.blockUser}/${UserService.getUserId()}/user/${id}`, {}, headers()).then(res => {
            // console.log(res)
            myreloadingFn()
            setModal({ open: false, name: '' })
            dispatch(fetchProfile())
        })
    }

    const unblock = () => {
        axios.delete(`${URL.unblockUser}/${UserService.getUserId()}/user/${id}`, headers()).then(res => {
            // console.log(res)
            setModal({ open: false, name: '' })
            myreloadingFn()
            dispatch(fetchProfile())
        }
        )
    }
    useEffect(() => {
        dispatch(handleActiveValue(0))
    }, [active_value])

    useEffect(() => {
        // let userid = sessionStorage.getItem("userId")
        // dispatch(fetchProfile(3))
        if (id !== null) {
            if (UserService.getToken()) {
                if (UserService.getUserId() == id) {
                    history.push(`/myprofile/${name}-${UserService.getUserId()}`)
                }
                else {
                    axios.get(`${URL.findUser}/${id}`, headers()).then(res => {
                        if (res.status === 200) {
                            setProfileData(res.data.data)
                        }
                    })
                }
            } else {
                axios.get(`${URL.getFindUser}/${id}`).then(res => {
                    if (res.status === 200) {
                        setProfileData(res.data.data)
                    }
                })
            }
        }
    }, [reload, id])

    console.log(profileData, "5555")

    const myreloadingFn = () => {
        setreload(!reload)
    }
    // console.log(profileData)
    return (
        <>
            {minWidth ? <div className='profile__view__Desktop app__one'>
                <Grid container>
                    {profileData ?
                        <Helmet>
                            <title>{`${name}-Profile | Docsteth | ${profileData.user.captionAndTaglines}`}</title>
                            <meta name="description" content={profileData.user.about} />
                            <meta name="canonical" content={`https://www.docsteth.com/${name}-${id}`} />
                            <meta name="keywords" content={`${profileData.user.fullName}, "Docsteth", ${profileData.user.captionAndTaglines} `} />
                        </Helmet>
                        : ""}
                    {(!UserService.getToken()) ? (
                        <div className="org_head" style={{ width: "100%" }}>
                            <Header header={profileData} />
                        </div>
                    ) : (
                        <span></span>
                    )}

                    <Grid item style={{ width: '100%' }}>
                        <HeaderBox active={active.tab} myreloadingFn={myreloadingFn} state={state} profileData={profileData} handleActive={handleActive} request={request} requestAcceptFn={requestAcceptFn}
                            connectBtnFun={connectBtnFun} connectionStatusFn={connectionStatusFn}
                            handleClose={handleClose} blockUser={blockUser} unblock={unblock} Item={Item}
                        />
                    </Grid>
                    {profileData && !profileData.blocked ?
                        <Grid container spacing={""} >
                            <Grid item md={12} xs={12}>
                                <Tabprofile active={active.tab} myreloadingFn={myreloadingFn} state={state} profileData={profileData} handleActive={handleActive} request={request} requestAcceptFn={requestAcceptFn}
                                    connectBtnFun={connectBtnFun} connectionStatusFn={connectionStatusFn}
                                    handleClose={handleClose} blockUser={blockUser} unblock={unblock} Item={Item}
                                ></Tabprofile>
                            </Grid>

                            {profile == 0 ?
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={4} xs={12}>
                                            <ProfileViewSidebar profileData={profileData} />
                                        </Grid>
                                        <Grid item md={8} xs={12} style={{ width: '100%', marginTop: '10px' }}>
                                            <AboutWriteBox profileData={profileData} />
                                        </Grid>
                                    </Grid>
                                </>
                                : " "}
                            {profile == 1 ?
                                <>
                                    <Grid item md={4} xs={12}>
                                        <PublicProfileTimeline />
                                    </Grid>
                                    <Grid item md={8} xs={12} style={{ width: '100%' }}>
                                        <PublicFeed />
                                    </Grid>
                                </>
                                : ""}
                            {profile == 2 ?
                                <Grid item md={12} xs={12} style={{ width: '100%', marginTop: '10px' }}>
                                    <Publicationprofile />
                                </Grid> : ""}
                            {/* {profile == 3 ?
                                <Grid item md={12} xs={12} style={{ width: '100%', marginTop: '10px' }}>
                                    <Development />
                                </Grid> : ""} */}
                        </Grid>
                        : null
                    }
                </Grid>
            </div> : ''}
            {/* <Grid item xs={12}><Development /></Grid> */}
            {/*---------------- SMALL SCREEN DEVICES -------------------*/}

            {maxWidth ? <div className='profile__view__Mobile'>
                <Grid>
                    <Grid item style={{ width: '100%' }}>
                        <HeaderBox active={active.tab} myreloadingFn={myreloadingFn} state={state} profileData={profileData} handleActive={handleActive} />
                    </Grid>
                    {profileData && !profileData.blocked ? <Grid item>
                        {profile == 0 ? <Overviewpublic profileData={profileData} /> : ""}
                        {profile == 1 ? <PublicFeed /> : ""}
                        {profile == 2 ? <Publicationprofile /> : ""}
                        {/* {profile == 3 ? <Development /> : ""} */}
                    </Grid> : ''}
                </Grid>
            </div> : ''}
        </>
    )
}

export default ProfileViewandConnect
