import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CustomValidator } from './customInput';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

export default function TimeInput({ maxDate, handleChange, required, errorMessages, ...props }) {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                className='input_shadow input_padding'
                maxDate={maxDate}
                {...props}
                onChange={value => handleChange(props.name, new Date(value.$d).toISOString())}
                format="DD/MM/YYYY"
                renderInput={(params) => <CustomValidator {...params} inputProps={{ ...params.inputProps, readOnly: true }} fullWidth validators={required ? ['required'] : []} errorMessages={errorMessages} {...props} />}
            />
        </LocalizationProvider>
    )
}