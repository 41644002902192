import React from 'react'

const Amenties = () => {
  return (
    <div  style={{ padding: "0 15px",height: '410px', overflowY: 'auto'  }}>
      Amenties
    </div>
  )
}

export default Amenties