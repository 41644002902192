import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PhoneIcon from '@mui/icons-material/Phone';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EmailIcon from '@mui/icons-material/Email';
import Logo from './docstethLogo';
import './signup.css';
import { Link } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Footer() {

    const TitleList = (props) => {
        return (
            <li style={{ padding: '6px 0', fontSize: '18px' }} className='f-color'>{props.list}</li>
        )
    }

    const Title = (props) => {
        return (
            <React.Fragment>
                <Typography sx={{ mt: 4, fontSize: '18px' }} className='b-color' variant="h6" component="div">
                    {props.title}
                </Typography>
                <ul className='list-style'>
                    {props.children}
                </ul>
            </React.Fragment>
        )
    }

    const Contact = ({ Icon, text }) => {
        return (
            <List>
                <ListItem disablePadding sx={{ alignItems: 'self-start' }}>
                    <ListItemIcon sx={{ minWidth: '35px' }}>
                        <Icon className='f-color' />
                    </ListItemIcon>
                    <ListItemText sx={{ m: 0 }} primary={text} className="f-color" />
                </ListItem>
            </List>
        )
    }
    return (
        <Box className='footer_header' sx={{ flexGrow: 1, backgroundColor: '#495254', mt: 3 }}>
            <Typography variant="h6" component="div" className='footer-logo-wrapper'>
                <div className='footer-logo'>
                    <Logo header="f-logo" />
                    <p className="footer-logo-bottom-text">A Global Practitioners Consortium</p>
                </div>
            </Typography>
            <Container>
                <Grid container className='footer_docsteth footer-alignment-container' >
                    <Grid item md={2} sm={6} xs={6} className='footer-part-sections' >
                        {/* <Title title='General'> */}
                        <TitleList list='Explore' />
                        <TitleList list='Connect' />
                        <TitleList list='E-Practice' />
                        <TitleList list='MedScope' />
                        {/* </Title> */}
                    </Grid>
                    <Grid item md={2} sm={6} xs={6} className='footer-part-sections' >
                        {/* <Title title='Browse Docsteth'> */}
                        <TitleList list='Webinars' />
                        <TitleList list='Job Opportunities' />
                        <TitleList list='CME/CPD' />
                        <TitleList list="Interactive Apps" />
                        {/* </Title> */}
                    </Grid>
                    <Grid item md={2} sm={6} xs={6} className='footer-part-sections' >
                        {/* <Title title='Business Solutions'> */}
                        <TitleList list='Organisations' />
                        <TitleList list='Developers' />
                        <TitleList list="Marketing" />
                        {/* </Title> */}
                    </Grid>
                    <Grid item md={2} sm={6} xs={6} className='footer-part-sections' >
                        {/* <Title title="Support"> */}
                        <TitleList list='About us' />
                        <TitleList list='Career' />
                        {/* </Title> */}
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} >
                        <div className='contact-info'>
                            <div className='footer-logo2'>
                                <Logo header="f-logo" />
                                <p className="footer-logo-bottom-text">A Global Practitioners Consortium</p>
                            </div>
                            <Contact Icon={LocationOnIcon} text={"#1-98/7/A/35, Narra's House, Jai Hindh Enclave, Madhapur, Hyderabad 500081, TG, IN"} />
                            <Contact Icon={PhoneIcon} text="+91 911 911 4934" />
                            <Contact Icon={EmailIcon} text='connect@docsteth.com' />
                        </div>
                        <div className='social-icons'>
                            <a href='https://twitter.com/Docsteth1' target="_blank"><TwitterIcon /></a>
                            <a href='https://www.youtube.com/channel/UCiGi8QFyAIV4jr-OoHagnHA' target="_blank"><YouTubeIcon /></a>
                            <a href='https://www.linkedin.com/company/76177059/admin/' target="_blank"><LinkedInIcon /></a>
                            <a href='https://www.instagram.com/docsteth/' target="_blank"><InstagramIcon /></a>
                        </div>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} >
                    <div className='privacy_footer'>
                        <Link to='/privacypolicy'><h2>Privacy Policy</h2></Link>
                        <Link to='/termsandcondition'><h2>Terms & Conditions</h2></Link>
                        <Link to='/userguideandfaq'><h2>User Guides & FAQ'S</h2></Link>
                    </div>
                </Grid>
            </Container >
        </Box >
    );
}
