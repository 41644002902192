import { Divider, Button, Avatar } from '@mui/material'
import { useHistory } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
export default function UsersData(props) {
    // console.log(props)
    const history = useHistory()

    const { closeDialog } = props

    return (
        <div>
            <h3>{props.title} <span style={{ float: 'right' }} className="cursor" onClick={closeDialog}><CloseIcon /></span></h3>
            <Divider sx={{ mt: '8px!important', mb: '16px!important' }} />
            {props.data.map(user => (
                <div className="post__header" key={user.id}>
                    <Avatar src={user.userData.profilePic} />
                    <div className="post__info e-align">
                        <div>
                            <h2 className="cursor capitalize" onClick={() => history.push(`/${(user.userData.fullName.replace(/ /g, '')).toLowerCase()}-${user.id}`)}>{user.userData.fullName}</h2>
                            <p className="capitalize">{user.userData.caption}</p>
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="text-right">
                <Button variant="outlined" className="btn__primary add-option" onClick={closeDialog}>Close</Button>
            </div> */}
        </div>
    )
}