import UserService from '../../services/UserService';
import Postingdd from '../Atom/postingdropdown/postingdropdown';
import Publicdd from '../Atom/publicdd/publicdd';
import { Avatar, Button } from '@mui/material';
import { MentionsInput, Mention } from "react-mentions";
import { getPeers, getHashtags, headers } from '../../redux/Actions/connectActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import URL from '../apiurl';

const ListItem = (props) => {
    return (
        <li className="d-flex" style={{ whiteSpace: 'nowrap' }}>{props?.title}
            <MentionsInput
                value={props?.value}
                placeholder="@ Mention"
                onChange={(e) => props.handleChange(props?.name, e.target.value)}
                allowSuggestionsAboveCursor={true}
                // allowSpaceInQuery={true}
                className="mentions article_mentions"
            >
                <Mention trigger="@" data={props?.data} type="user"
                    // appendSpaceOnAdd={true}
                    markup={`<a class='add-cover b-color textLowerCase' href='__id__'>@__display__</a> `}
                    renderSuggestion={(entry) => { return (<div className="d-flex e-align"><Avatar src={entry?.profilePic} width="20px" height="20px" />&nbsp;{entry?.name}</div>) }}
                    className="mentions__mention"
                    displayTransform={(id, display) => `@${display} `}
                />
            </MentionsInput>
        </li>
    )
}
export default function Preview(props) {
    const { handleChange } = props

    const [hashtag, setHashtag] = useState(false)
    const [users, setUsers] = useState([])
    const [org, setOrg] = useState([])
    const [men, setMen] = useState([])
    const [hashtags, setHashtags] = useState([])
    const storeHash = useSelector(state => state.connect.hashtags)
    // const mentions = useSelector(state => state.connect.mentions)

    const dispatch = useDispatch()

    useEffect(() => {
        // if (Object.keys(mentions).length === 0) {
        //     dispatch(getMentions())
        // }
        if (Object?.keys(storeHash)?.length === 0) {
            dispatch(getHashtags())
        }
    }, [])

    useEffect(() => {
        let hash = []
        storeHash?.map((data) => hash.push({ id: data?.id, display: data?.hashtag }))
        setHashtags(hash)
    }, [storeHash])

    const mentionsdata = async (data) => {
        // console.log(data)
        if (data !== '') {
            let org = data?.split(' ')
            org = org[org?.length - 1]?.replace(/\n/g, '')?.startsWith('@') ? org[org?.length - 1]?.replace(/@/g, '') : ''
            if (org && !org?.includes('href') && !org?.includes('</a>')) {
                let men = await axios.get(`${URL.mentions}/${org}`, headers()).then(res => {
                    // console.log(res.data)
                    let list = []
                    list = res.data?.data?.map(data => {
                        // console.log(data)
                        return {
                            ...data,
                            display: data?.name?.replace(/ /g, ''),
                            name: data?.name,
                            id: data?.type === 'user' ?
                                `/profile/${data?.name?.replace(/ /g, '')?.toLowerCase()}-${data?.id}` : `/organization/${data?.id}`
                        }
                    })
                    // console.log(list)
                    return list
                })
                return men
            }
        }
    }

    useEffect(() => {
        return async () => {
            let users = await mentionsdata(props?.data?.desc)
            // console.log(coAuthors)
            setMen(users)
        }
    }, [props?.data?.desc])

    useEffect(() => {

        return async () => {
            let coAuthors = await mentionsdata(props?.data?.coAuthors)
            // console.log(coAuthors)
            setUsers(coAuthors)
        }
        // async () => {
        //     let coAuthors = await mentionsdata(props.data.coAuthors)
        //     // console.log(coAuthors)
        //     setUsers(coAuthors)
    }, [props?.data?.coAuthors])



    useEffect(() => {
        return async () => {
            let contributors = await mentionsdata(props?.data?.contributors)
            // console.log(contributors)
            setMen(contributors)
        }

    }, [props?.data?.contributors])

    useEffect(() => {
        return async () => {
            let peers = await mentionsdata(props?.data?.peers)
            // console.log(peers)
            setUsers(peers)
        }
    }, [props?.data?.peers])

    useEffect(() => {
        return async () => {
            let organisations = await mentionsdata(props?.data?.organisations)
            // console.log(organisations)
            setOrg(organisations)
        }

    }, [props?.data?.organisations])



    return (
        <div>
            <div className="dropDown">
                <Postingdd postUser={(uid, pid) => props?.postUser(uid, pid)} value={UserService?.getUserId()} edit={props?.edit} />
                <Publicdd postPrivacy={props?.postPrivacy} value={props?.value} />
            </div>
            <div style={{ textAlign: 'left', padding: '10px 30px' }}>

                <MentionsInput
                    value={props?.data?.desc}
                    placeholder="What would you like to share ?"
                    autoFocus
                    onChange={(e) => handleChange('desc', e.target.value)}
                    allowSuggestionsAboveCursor={true}
                    // allowSpaceInQuery={true}
                    className="mentions"
                >
                    <Mention trigger="@" data={men} type="user"
                        // appendSpaceOnAdd={true}
                        markup="<a class='add-cover b-color textLowerCase' href='__id__'>@__display__</a> "
                        renderSuggestion={(entry) => { return (<div className="d-flex e-align"><Avatar src={entry?.profilePic} width="20px" height="20px" />&nbsp;{entry?.name}</div>) }}
                        className="mentions__mention"
                        displayTransform={(id, display) => `@${display} `}
                    />
                </MentionsInput>

                {props?.data?.tags !== '' || hashtag ? <MentionsInput
                    value={props?.data?.tags}
                    placeholder="Add Hashtag"
                    autoFocus
                    onChange={(e, val, newValue, mentions) => handleChange('tags', newValue)}
                    // allowSpaceInQuery={true}
                    allowSuggestionsAboveCursor={true}
                    className="mentions"
                >
                    <Mention trigger="#" data={hashtags} type="user"
                        // appendSpaceOnAdd={true}
                        markup={`@@@____id__^^____display__@@@^^^ `}
                        className="mentions__mention"
                        displayTransform={(id, display) => `${display} `}
                    />
                </MentionsInput> :
                    <Button onClick={() => setHashtag(true)}>Add Hashtag</Button>}
                <ul className="w-100" style={{ paddingLeft: '10px' }}>
                    <ListItem name="coAuthors" title="Co-authors" data={users} value={props?.data?.coAuthors} handleChange={handleChange} />
                    <ListItem name="contributors" title="Contributors" data={men} value={props?.data?.contributors} handleChange={handleChange} />
                    <ListItem name="organisations" title="Organisations" data={org} value={props?.data?.organisations} handleChange={handleChange} />
                    {/* <ListItem name="peers" title="Send to peers for review" data={users} value={props.data.peers} handleChange={handleChange} /> */}
                </ul>
            </div>
            <div className="fw-bold">
                <div className={`${props?.url ? "p-bg" : 'p-bg'} preview-cover cover-img`}>
                    <p className="preview-class font-weight">Preview</p>
                    {props?.url ? <img src={props?.url} alt="cover-pic" width="100%" height="250px" className="my-2" style={{ objectFit: 'contain' }} /> :
                        <p className="cover-pic font-weight">Cover Picture</p>}
                </div>
                <div className={`p-title font-weight t-bg`}>
                    <p>{props?.title ? props?.title : 'Title  of this Article'}</p>
                </div>
            </div>
        </div>
    )
}