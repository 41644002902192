import React, { useEffect, useState } from "react";
import { CelTextField } from "../../../Fields/PollFields";
import "../poll/poll.css";
import "../../../utility/style.css";
import "./event.css";
import { Autocomplete, Grid } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { Checkbox } from "@mui/material";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import moment from "moment";
import UserService from "../../../../services/UserService";
import { MentionsInput, Mention } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { getPeers } from "../../../../redux/Actions/connectActions";
import { Avatar } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { FiEdit3 } from "react-icons/fi";
import axios from "axios";
import URL from "../../../apiurl";

const Titles = ({ title, children, col }) => {
  return (
    <>
      {/* <Grid item sm={4} xs={12}>
                <div className="normalfont">{title}</div>
            </Grid> */}
      <Grid item sm={col} xs={12} sx={{ my: 0.5, pt: "8px!important" }}>
        {children}
      </Grid>
    </>
  );
};

const TextButton = ({ text, handleClick }) => {
  return (
    <Typography
      component="span"
      onClick={handleClick}
      className="cursor"
      sx={{
        color: "#007CDC!important",
        p: "5px 10px!important",
        borderRadius: "7px!important",
        mr: "10px!important",
      }}
    >
      {text}
    </Typography>
  );
};

function EventsContent(props) {
  const ev = {
    type: "",
    title: "",
    mode: false,
    pre_reg: false,
    reg_form: "",
    web_platform: "",
    link: "",
    organiser: "",
    s_date: null,
    e_date: null,
    payment: "",
    loc: "",
    sponsor: "",
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [event, setEvent] = useState(ev);
  console.log(event, "event here");
  const e_types = ["In Person", "Webinar"];
  const [img, setImg] = useState("");
  const [alert, setAlert] = useState({ open: false, msg: "" });
  const [state, setState] = useState({ organiser: false, sponsers: false });
  const mentions = useSelector((state) => state.connect.mentions);
  const [men, setMen] = useState([]);
  const [sponsor, setSponsor] = useState([]);

  const inputRef = React.useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPeers());
  }, []);

  const mentionsdata = () => {
    if (event.organiser !== "") {
      // console.log(event.organiser, "organiser")
      let org = event.organiser.split(" ");
      org = org[org.length - 1].replace(/\n/g, "").startsWith("@")
        ? org[org.length - 1].replace(/@/g, "")
        : "";
      if (org && !org.includes("href") && !org.includes("</a>")) {
        axios
          .get(`${URL.mentions}/${org}`, {
            headers: {
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          })
          .then((res) => {
            // console.log(res.data)
            let list = [];
            list = res.data.data.map((data) => {
              // console.log(data)
              return {
                ...data,
                display: data.name.replace(/ /g, ""),
                name: data.name,
                id:
                  data.type === "user"
                    ? `/profile/${data.name.replace(/ /g, "").toLowerCase()}-${
                        data.id
                      }`
                    : `/organization/${data.id}`,
              };
            });
            // console.log(list)
            setMen(list);
            // (list)
          });
      }
    }
    if (event.sponsor !== "") {
      // console.log(event.sponsor, "sponsors")
      let org = event.sponsor.split(" ");
      org = org[org.length - 1].replace(/\n/g, "").startsWith("@")
        ? org[org.length - 1].replace(/@/g, "")
        : "";
      if (org && !org.includes("href") && !org.includes("</a>")) {
        axios
          .get(`${URL.mentions}/${org}`, {
            headers: {
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          })
          .then((res) => {
            // console.log(res.data)
            let list = [];
            list = res.data.data.map((data) => {
              // console.log(data)
              return {
                ...data,
                display: data.name.replace(/ /g, ""),
                name: data.name,
                id:
                  data.type === "user"
                    ? `/profile/${data.name.replace(/ /g, "").toLowerCase()}-${
                        data.id
                      }`
                    : `/organization/${data.id}`,
              };
            });
            // console.log(list)
            setSponsor(list);
            // (list)
          });
      }
    }
  };

  useEffect(() => {
    mentionsdata();
  }, [event.organiser, event.sponsor]);

  const handleChange = (name, value) => {
    // console.log(name,value)
    setEvent({ ...event, [name]: value });

    if (name === "type" && value === "Webinar") {
      setEvent({ ...event, type: value, web_platform: "Docsteth" });
    } else if (name === "type") {
      setEvent({ ...ev, type: value, title: event.title });
    }
    if (name === "pre_reg" && value === false) {
      setEvent({ ...event, pre_reg: value, reg_form: "" });
    }
  };

  useEffect(() => {
    if (Object.keys(props.formData).length > 0) {
      let { formData } = props;
      // console.log(formData)
      setEvent({
        title: formData.title,
        type: formData.eventType,
        mode: JSON.parse(formData.eventMode),
        pre_reg: formData.preregistration,
        reg_form: formData.registrationForm,
        web_platform: formData.webinarPlatform,
        link: formData.link,
        organiser: formData.organiser ? formData.organiser + " " : "",
        s_date: moment.utc(formData.openingTime).local(),
        e_date: moment.utc(formData.closingTime).local(),
        loc: formData.eventLocation,
        eventImage: formData.eventImage,
        sponsor: formData.sponsor ? formData.sponsor + " " : "",
        eventPermission: formData.eventPermission,
        eventDescription: formData.eventDescription,
        paymentLink: formData.paymentLink,
        slot: formData.slot,
        certification: formData.certification,
        paymentMode: formData.paymentMode,
        payment: formData.payment,
        registrationTemplate: formData.registrationTemplate,
      });
      // setImg(formData.eventImage)
    }
  }, []);

  useEffect(() => {
    props.handleEvent(event, "event");
  }, [event]);

  return (
    <div>
      <div className="p-3">
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ my: "10px!important", mx: 0, width: "100%" }}
        >
          {/* <Grid item xs={12}>
                        <CelTextField variant="standard" sx={{ width: '100%' }}
                            value={event.title} placeholder="Write your Event Title here"
                            errorMessages={['Enter Event Title']} validators={['required']}
                            onChange={(e) => handleChange('title', e.target.value)}

                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <FiEdit3 style={{ fontSize: '1.2rem' }} />
                                    </InputAdornment>

                            }}
                        />
                    </Grid> */}
          <Titles title="Category" col={6}>
            <Autocomplete
              disableClearable={true}
              value={event.type}
              // onChange={(event, newValue) => {
              //     setEvent({ ...event, type: newValue });
              // }}
              onChange={(e, value) => handleChange("type", value)}
              id="controllable-states-demo"
              options={e_types}
              getOptionLabel={(option) => option}
              // sx={{ width: 300 }}
              renderInput={(params) => (
                <CelTextField
                  {...params}
                  label="Category"
                  value={params.inputProps.value}
                  placeholder="Select Event Category"
                  errorMessages={["Select Event Type"]}
                  validators={["required"]}
                />
              )}
            />
          </Titles>

          {event.type === "In Person" ? (
            <Titles title="Location" col={6}>
              <CelTextField
                sx={{ width: "100%" }}
                value={event.loc}
                onChange={(e) => handleChange("loc", e.target.value)}
                label="Location"
                errorMessages={["Enter Event Location"]}
                validators={["required"]}
              />
            </Titles>
          ) : (
            <Titles col={6}>
              <Autocomplete
                disableClearable={true}
                value={event.web_platform}
                onChange={(e, value) => handleChange("web_platform", value)}
                id="controllable-states-demo"
                options={["Docsteth", "Microsoft Teams", "Google Meet"]}
                getOptionLabel={(option) => option}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <CelTextField
                    {...params}
                    label="Event Type"
                    value={params.inputProps.value}
                    placeholder="Select Webinar Platform"
                    errorMessages={["Select Webinar Platform"]}
                    validators={["required"]}
                  />
                )}
              />
            </Titles>
          )}

          <Titles title="Event Title" col={12}>
            <CelTextField
              sx={{ width: "100%" }}
              value={event.title}
              // placeholder="Write your Event Title here"
              label="Event Title"
              errorMessages={["Enter Event Title"]}
              validators={["required"]}
              onChange={(e) => {
                if (!e.target.value.startsWith(" ")) {
                  handleChange("title", e.target.value);
                }
              }}
            />
          </Titles>
          {event.type === "Webinar" ? (
            <>
              {event.web_platform !== "Docsteth" ? (
                <Titles title="Meeting Link" col={12}>
                  <CelTextField
                    sx={{ width: "100%" }}
                    value={event.link}
                    label="Meeting Link"
                    onChange={(e) => handleChange("link", e.target.value)}
                    errorMessages={["Meeting link to join"]}
                    validators={["required"]}
                  />
                </Titles>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          <Titles title="Start Date & Time" col={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={event.s_date}
                onChange={(value) => handleChange("s_date", new Date(value))}
                minDate={new Date()}
                inputFormat="dd-MM-yyyy hh:mm a"
                label="From"
                inputProps={{ readOnly: true }}
                renderInput={(params) => (
                  <CelTextField
                    fullWidth
                    value={params.inputProps.value}
                    errorMessages={["Select Event Start Date"]}
                    validators={["required"]}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Titles>
          <Titles title="End Date & Time" col={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={event.e_date}
                onChange={(value) => handleChange("e_date", new Date(value))}
                minDate={new Date(event.s_date)}
                inputFormat="dd-MM-yyyy hh:mm a"
                label="To"
                inputProps={{ readOnly: true }}
                disabled={event.s_date ? false : true}
                renderInput={(params) => (
                  <CelTextField
                    fullWidth
                    value={params.inputProps.value}
                    errorMessages={["Select Event End Date"]}
                    validators={["required"]}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Titles>

          {/* <Titles title="Event Mode">
                    <div className="font">Offline<span><Switch color="primary" onChange={(e,value)=> handleChange('mode',e.target.checked)} checked={event.mode}/></span><span className="font">Online</span></div>
                </Titles> */}
          {/* <Titles title="Pre-Registration">
                        <Checkbox checked={event.pre_reg} onChange={(e) => handleChange('pre_reg', e.target.checked)} sx={{ pl: 0 }} />
                        <span className="font">Required</span>
                    </Titles> */}

          {/* <Titles title="Organiser">
                    <CelTextField sx={{width: '100%'}} value={event.organiser} onChange={(e) => handleChange('organiser',e.target.value)} placeholder="Organiser" />
                </Titles> */}

          {/* {event.organiser || state.organiser ?
                        <>
                        <Grid item sm={4} xs={12}>
                            <div className="normalfont">Organisers</div>
                        </Grid> */}
          <Grid item xs={12} sx={{ mt: "10px!important" }}>
            <div style={{ position: "relative" }}>
              <MentionsInput
                value={event.organiser}
                placeholder="@mention"
                // autoFocus
                onChange={(e) => handleChange("organiser", e.target.value)}
                // allowSpaceInQuery={true}
                className="mentions event_mention"
                style={{ margin: "0px" }}
                allowSuggestionsAboveCursor={true}
              >
                <Mention
                  trigger="@"
                  data={men}
                  type="user"
                  // appendSpaceOnAdd={true}
                  markup={`<a class="name_hover" href='__id__'>@__display__</a> `}
                  renderSuggestion={(
                    entry,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                  ) => {
                    return (
                      <div className="d-flex e-align">
                        <Avatar
                          src={entry.profilePic}
                          width="20px"
                          height="20px"
                        />
                        &nbsp;{entry.name}
                      </div>
                    );
                  }}
                  className="mentions__mention"
                  displayTransform={(id, display) => `${display} `}
                />
              </MentionsInput>
              <span
                className="mention_label"
                style={{ color: "rgba(0, 0, 0, 0.6)" }}
              >
                Organisers
              </span>
            </div>
          </Grid>
          {/* </> : ''} */}

          {/* {event.sponsors || state.sponsers ?
                        <>
                        <Grid item sm={4} xs={12}>
                            <div className="normalfont">Sponsors</div>
                        </Grid> */}
          <Grid item xs={12} sx={{ mt: "10px!important" }}>
            <div style={{ position: "relative" }}>
              <MentionsInput
                value={event.sponsor}
                placeholder="@mention"
                // autoFocus
                onChange={(e) => handleChange("sponsor", e.target.value)}
                // allowSpaceInQuery={true}
                allowSuggestionsAboveCursor={true}
                className="mentions event_mention"
                style={{ margin: "0px" }}
              >
                <Mention
                  trigger="@"
                  data={sponsor}
                  type="user"
                  // appendSpaceOnAdd={true}
                  markup={`<a class="name_hover" href='__id__'>@__display__</a> `}
                  renderSuggestion={(
                    entry,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                  ) => {
                    return (
                      <div className="d-flex e-align">
                        <Avatar
                          src={entry.profilePic}
                          width="20px"
                          height="20px"
                        />
                        &nbsp;{entry.name}
                      </div>
                    );
                  }}
                  className="mentions__mention"
                  displayTransform={(id, display) => `${display} `}
                />
              </MentionsInput>
              <span
                className="mention_label"
                style={{ color: "rgba(0, 0, 0, 0.6)" }}
              >
                Sponsors
              </span>
            </div>
          </Grid>
          {/* </> : ''} */}

          {/* {state.sponsers ?
                            <Grid item sm={4} xs={12} sx={{ py: 0 }}>
                                <div className="normalfont">
                                    <div className="font">Free
                                        <span>
                                            <Switch
                                                checked={event.mode}
                                                onChange={(e) => handleChange('mode', e.target.checked)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </span>
                                        <span className="font">Paid</span></div>
                                </div> 
                            </Grid> : '' } */}
          {event.pre_reg ? (
            <>
              <Titles title="Registration Form">
                <CelTextField
                  sx={{ width: "100%" }}
                  value={event.reg_form}
                  label="Registration Link"
                  onChange={(e) => handleChange("reg_form", e.target.value)}
                />
              </Titles>
            </>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            {event.mode ? (
              <CelTextField
                sx={{ width: "100%" }}
                value={event.payment}
                onChange={(e) => handleChange("payment", e.target.value)}
                placeholder="Payment Link / A.C details"
              />
            ) : (
              ""
            )}
          </Grid>

          {props.children}

          {/* {img ? <Grid container direction="row" style={{ maxHeight: '500px', overflow: 'hidden', marginTop: '10px' }}>
                        <Grid item xs={12} style={{ height: '100%', position: 'relative' }} className="elib-right__content">
                            <img alt="Event Image" style={{
                                objectFit: 'cover',
                                borderRadius: 5,
                                width: '100%',
                                height: '100%'
                            }} src={img}></img>
                            <IconButton style={{ position: 'absolute', top: '9px', right: '9px', backgroundColor: 'black', opacity: 0.5, color: 'white' }} onClick={() => { setImg(''); inputRef.current.value = ""; setEvent({ ...event, image: '' }) }}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid> : ''} */}
        </Grid>
        {/* <input type="file" name="uploadfile" multiple id="event-image" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} ref={inputRef} onChange={(e) => uploadFile(e)} />
                <label htmlFor="event-image" className="cursor">
                    <TextButton text="+ cover photo" />
                </label> */}

        {/* <TextButton text="+ cover photo"/> */}
        <div className="flx-center">
          {!event.pre_reg && (
            <Typography component={"div"} sx={{ pl: 2, pt: 0 }}>
              <TextButton
                text="+ Registration"
                handleClick={() => setEvent({ ...event, pre_reg: true })}
              />

              {/* <TextButton text="+ Payment" handleClick={() => setEvent({ ...state, mode: true })} /> */}
            </Typography>
          )}
          {event.pre_reg && (
            <Typography component={"div"} sx={{ pl: 2, pt: 0 }}>
              <TextButton
                text="- Remove Registration Link"
                handleClick={() =>
                  setEvent({ ...event, pre_reg: false, reg_form: "" })
                }
              />
            </Typography>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={alert.post ? null : 2000}
        open={alert.open}
        onClose={() => setAlert({ open: false, msg: "", post: false })}
        message={alert.msg}
      />
    </div>
  );
}

export default EventsContent;
