import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import { ValidatorForm } from "react-material-ui-form-validator"
// import UploadKYC from "../../buildService/editOrg/uploadDocs"
// import UploadKYC from '../../pages/home/organisation/editOrg/uploadDocs';
// import { DigiDoc } from "../../practitioner/forms/connect/digidoc"
// import FacilityContact from "../../practitioner/forms/healthRegistry/HFR"
import { FacilityContact } from '../../Layout/MyProfile/organisation/healthRegistry/LinkHFR';
import { Actions, FormDivider, FormLayout } from "./layout"

export const DialogBox = ({ open, close, maxWidth, ...props }) => {
    return (
        <Dialog id={props.id || "register"} open={open} onClose={close} maxWidth={maxWidth || "sm"} fullWidth>
            <DialogTitle component={"div"} className="flx-center justify-between" sx={{ fontSize: '16px', pt: 1, pb: 0 }}><p className='mb0 mt0'>{props.heading}</p> <CloseIcon onClick={close} className="cursPoint" /></DialogTitle>
            <FormDivider />
            <DialogContent sx={{ pt: 0 }}>
                {props.children}
            </DialogContent>
        </Dialog>
    )
}
export const DialogBox2 = ({ open, close, maxWidth, ...props }) => {
    return (
        <Dialog id={props.id || "register"} open={open} onClose={close} maxWidth={maxWidth || "sm"} sx={{ height: "600px", position: 'absolute', top: "-40px" }} fullWidth>
            <DialogTitle component={"div"} className="flx-center justify-between" sx={{ fontSize: '16px', pt: 1, pb: 0 }}><p className='mb0 mt0'>{props.heading}</p> <CloseIcon onClick={close} style={{ cursor: 'pointer' }} /></DialogTitle>
            <FormDivider />
            <DialogContent sx={{ pt: 0 }}>
                {props.children}
            </DialogContent>
        </Dialog>
    )
}

export const HFRDialog = (props) => {
    return (
        <DialogBox2 {...props} heading={"Set up National Health Facility Registry ID / HFR ID"}>
            <FacilityContact close={props.close} />
        </DialogBox2>
    )
}

export const HPRDialog = (props) => {
    return (
        <DialogBox {...props} heading={"Connect Ayushman Bharat Digital Healthcare Practitioner ID / HPR ID"}>
            <ValidatorForm onSubmit={() => { }}>
                {/* <DigiDoc popup next={props.close} /> */}
            </ValidatorForm>
        </DialogBox>
    )
}

export const FacilityKYC = (props) => {
    return (
        <DialogBox id="services" {...props} heading="Healthcare Facility KYC">
            {/* <UploadKYC close={props.close} status /> */}
        </DialogBox>
    )
}

export const TemplateDialog = (props) => {
    return (
        <DialogBox id="services" heading={props.heading} open={props.open} close={props.close}>
            <ValidatorForm onSubmit={props.handleSubmit}>
                <FormLayout value={props.value} handleChange={props.handleChange} label="Template Name" required errorMessages={props.error} />
                <Actions name={props.heading} close={props.close} />
            </ValidatorForm>
        </DialogBox>
    )
}