import TextField from '@mui/material/TextField';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { TextValidator } from 'react-material-ui-form-validator';

const CssTextField = styled(TextValidator)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '12px',
      height: '48px',
      boxShadow: '0px 3px 4px #756d6d29',
      color: 'grey',
      top: -1
    },
  }
});

export default CssTextField