import { MoreVert, Public } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import ImagesCompInDept from "./feedCompInDept/imagesFd";
import VideoCompInDept from "./feedCompInDept/videoFld";
import EventCompInDept from "./feedCompInDept/eventsFld";
import YoutubeLinkInDept from "./feedCompInDept/YoutubeFld";
import PostActionsInDept from "./feedCompInDept/postActionInDept";
import ArticleViewInDept from "./feedCompInDept/articlesViewFld";
import PollInDept from "./feedCompInDept/pollFolderInDept/pollInDept";
import CelebrateInDept from "./feedCompInDept/celebrateFolderInDept/celebrateInDept";

// import medxpertsIcon from "../../../assets/images/like-icon.svg";

import Doc from "../../../../../../../images/Logo2.svg";
import UserService from "../../../../../../../services/UserService";


export const AttendBtn = ({ text, fun, variant }) => {
    return (
        <Typography component="div">
            <p className="text-right">
                <Button className={`color-head add-option ${variant ? 'pointer_none' : ''}`} variant="outlined">{text}</Button>
            </p>
        </Typography>
    )
}
const AllPostInDepts = ({ feedData }) => {
    const item = feedData;

    // console.log(item, "vijay")

    const [expanded, setExpanded] = useState(false);
    const [sub, setSub] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (item?.postType == "EVENT") {

            subscribers(item.eventData);

        }

    }, [item])

    const subscribers = (event) => {
        let subscribers = event?.subscribers
        // if (subscribers && subscribers !== null && subscribers.includes(',')) {
        //     subscribers = subscribers.split(',')
        //     subscribers.filter(sub => sub == UserService.getUserId()) ? setSub(true) : setSub(false)
        // }
        if (subscribers && subscribers === Number(UserService.getUserId())) setSub(true)
        else setSub(false)
        // else if (subscribers !== null) {
        //     subscribers == UserService.getUserId() ? setSub(true) : setSub(false)
        // }
    }


    const timeSince = (date) => {
        var ms = new Date().getTime() - new Date(date).getTime();
        var seconds = Math.floor(ms / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
        var months = Math.floor(days / 30);
        var years = Math.floor(months / 12);
        if (seconds < 1) {
            return "Just now";
        }
        if (seconds < 60) {
            return seconds + "s";
        }
        if (minutes < 60) {
            return minutes + "m";
        }
        if (hours < 24) {
            return hours + "h";
        }
        if (days < 30) {
            return days + "d";
        }
        if (months < 12) {
            return months + " month";
        } else {
            return years + "y";
        }
    };

    return (
        <div>
            <Box
                sx={{
                    border: "1px solid #e5e5dc",
                    borderRadius: "10px",
                    position: "relative",
                    // width: "500px",
                    height: "500px",
                    m: { xs: '15px 0px', sm: '15px 4px', md: '15px' }
                }}
            >
                <Grid
                    container
                    sx={{
                        height: '80px',
                        padding: "10px",
                    }}
                    justifyContent="space-between"

                >
                    <Grid item>
                        <Grid item container spacing={2}>
                            <Grid item >
                                {item?.pageData?.profilePic?.length > 0 ||
                                    item?.userData?.profilePic?.length > 0 ? (
                                    <Avatar
                                        src={
                                            item?.pageData?.profilePic
                                                ? item?.pageData?.profilePic
                                                : item?.userData?.profilePic
                                        }
                                        sx={{
                                            borderRadius: "7px!important",
                                            width: { sm: 40, md: 40 },
                                            height: { sm: 40, md: 40 },
                                            p: "5px",
                                            ".MuiAvatar-img": { borderRadius: "5px" },
                                        }}
                                    />
                                ) : (
                                    <Avatar
                                        sx={{
                                            borderRadius: "7px!important",
                                            width: { sm: 40, md: 40 },
                                            height: { sm: 40, md: 40 },
                                        }}
                                        src={Doc}
                                    />
                                )}
                            </Grid>

                            <Grid item >
                                {item?.pageData?.fullName?.length > 0 ||
                                    item?.userData?.fullName?.length > 0 ? (
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 14, sm: 14, md: 14 },
                                            fontWeight: 500,
                                            cursor: "pointer",
                                        }}
                                    >
                                        {(item?.pageData?.fullName || item?.userData?.fullName || "")
                                            .charAt(0)
                                            .toUpperCase() +
                                            (
                                                item?.pageData?.fullName ||
                                                item?.userData?.fullName ||
                                                ""
                                            )?.slice(1)}
                                    </Typography>
                                ) : (
                                    <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                                )}

                                {item?.pageData?.caption?.length > 0 ||
                                    item?.userData?.caption?.length > 0 ? (
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 12, sm: 12, md: 12 },
                                        }}
                                    >
                                        {item.pageData?.caption || item.userData?.caption}
                                    </Typography>
                                ) : null}

                                <Typography color="black">
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 12, sm: 12, md: 12 },
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        <Public sx={{ fontSize: "14px", color: "#336ECC" }} />
                                        {`${timeSince(moment.utc(item?.createDate).local())}`}
                                    </Typography>
                                    &nbsp;
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <MoreVert />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box sx={{ height: "370px" }}>

                    {
                        item.postType == "TEXT" && item.description.startsWith("https") ?
                            <YoutubeLinkInDept docFeed={item} /> :
                            item.postType === "IMAGE" ? item.sourceLink !== null && (<ImagesCompInDept docFeed={item} />)
                                : item.postType === "POLL" ?
                                    <PollInDept docFeed={item} border={item.share ? true : false} />
                                    : item?.postType === "VIDEO" ? (
                                        <VideoCompInDept docFeed={item} />

                                    ) : item?.postType === "ARTICLE" ? (
                                        <ArticleViewInDept docFeed={item} />
                                    ) :
                                        item.postType === "CELEBRATE" ? (
                                            <CelebrateInDept docFeed={item} />
                                        ) :
                                            item?.postType === "EVENT" ? (
                                                <EventCompInDept docFeed={item} >
                                                    {item?.userId == UserService.getUserId() ? <></> :
                                                        sub ? <AttendBtn text={item?.eventData.webinarPlatform !== 'Docsteth' ? 'Attending' : 'Registered'} variant="outlined" fun={() => { }} /> : <AttendBtn text="Register" fun={() => { "" }} />
                                                    }
                                                </EventCompInDept>

                                            ) : null}

                </Box>
                <PostActionsInDept item={item} />
            </Box>
        </div>
    );
};
export default AllPostInDepts;