import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Privacyandpolicy from './Privacyandpolicy.js';
import Development from '../Molecule/EbmKit/development.js';
import TermsandCondition from './TermsandCondition.js';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import './Privacyandpolicy.css'

function Privacyandpolicyhomepage() {
    let active = "0";
    const [profile, setProfile] = useState(active);
    const [activ, setActiv] = useState("0")
    const { path } = useRouteMatch();

    useEffect(() => {
        window.scrollTo(0, 0)
        if (path.includes('/termsandcondition')) {
            setActiv("1")
            setProfile("1")
        }
        if (path.includes('/userguideandfaq')) {
            setActiv("2")
            setProfile("2")
        }
    }, [])

    return (
        <div>
            <Box className="elibrary_homepage_body">
                <div className='policy_header'>
                    <ul>
                        <li className={`${profile === "0" ? 'border_line' : ''}`}><Link to='/privacypolicy'><span className='text_link'>Privacy Policy</span></Link></li>
                        <li className={`${profile === "1" ? 'border_line' : ''}`}><Link to='/termsandcondition'><span className='text_link'>Terms & Conditions</span></Link></li>
                        <li className={`${profile === "2" ? 'border_line' : ''}`}><Link to='/userguideandfaq'><span className='text_link'>User Guides & FAQ'S</span></Link></li>
                    </ul>
                </div >

                <div>
                    {profile == 0 ? <Privacyandpolicy /> : ""}
                    {profile == 1 ? <TermsandCondition /> : ""}
                    {profile == 2 ? <Development /> : ""}
                </div>
            </Box >
        </div >
    )
}

export default Privacyandpolicyhomepage
