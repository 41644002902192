import React from "react";
import PhoneValidator from "./PhoneValidator";

export default function PhoneInput ({handleChange,value,...props}) {
    return (
        <PhoneValidator variant="outlined"
            defaultCountry="in"
            id="outlined-number"
            className="input_shadow input_padding"
            fullWidth
            onlyCountries={['in']}
            {...props}
            value={value}
            validators={props.required ? ['required'] : []}
            errorMessages={props.errorMessages}
            onChange={(value) => handleChange(props.name, value)}
        />
    )
}