
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CircularProgress, Typography, IconButton } from "@mui/material";

import axios from "axios";
import PostDataInDept from "./allPostInDepts";
import Carousel from 'react-multi-carousel';
import { KeyboardArrowLeft, KeyboardArrowRight, LensOutlined, LensRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./postInDept.css";
import URL from "../../../../../../apiurl";


function PostInDepts({ PageId }) {


    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    const orgData = useSelector(state => state.registration.userOrgData);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${URL.getFeedByPageIdByWithoutToken}${PageId}?limit=25&offset=0`);
                setPosts(response.data?.results || []);
            } catch (error) {
                console.error("Error fetching posts:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, [PageId]);

    console.log(posts, "vinay")

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        customPaging: i => (
            <div
                className="custom-dot"
            />
        ),
        appendDots: dots => (
            <div style={{
                borderRadius: "10px", padding: "1px",
            }}>
                <ul style={{ margin: "0px", padding: "0px", display: 'flex', justifyContent: 'center' }
                } > {dots}</ ul>
            </div>
        )
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px", md: "0px" } }}>
            <Box sx={{ p: { xs: "5px", sm: "5px 20px", md: "5px 80px" }, mt: 1 }}>
                <Box sx={{ p: { xs: "0px", sm: "10px 10px", md: "5px 10px" } }}>
                    <Box>
                        <div style={{ position: "relative" }}>
                            {loading ? (
                                <CircularProgress sx={{ display: "block", mx: "auto", mt: 3 }} />
                            ) : (
                                posts && posts.length > 0 ? (
                                    <div className="slider-container">
                                        <Slider {...settings}>
                                            {posts.map((post, index) => (
                                                <div key={index} className="post-slide">
                                                    <PostDataInDept feedData={post} />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                ) : (
                                    <Typography sx={{ textAlign: "center", mt: 3 }}>
                                        No posts available.
                                    </Typography>
                                )
                            )}
                        </div>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default PostInDepts;



