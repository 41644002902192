import { Box, Grid, AppBar,Toolbar,Typography,Button } from "@mui/material";
import drug_icon from '../Atom/modifiedicons/dose_calculator.svg';
import bio_icon from '../Atom/modifiedicons/bio_calculator.svg';
import stat_icon from '../Atom/modifiedicons/statical_tool.svg';
import check_icon from '../Atom/modifiedicons/check_list.svg';
import clini_icon from '../Atom/modifiedicons/clinical_calculator.svg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from "react-router-dom";

export default function CalculatorHeader({name,type}) {

    const history = useHistory()

    const images = {
        "drug-calculator": drug_icon,
        "biomedical-calculator": bio_icon,
        "clinical-assessment": clini_icon,
        "check-list" : check_icon,
        "statistical-tools": stat_icon
    }

    return (
        <div className="elibrary_rightcontainer_header">
            <AppBar position="static" style={{ background: '#FFFFFF', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                <Toolbar style={{ boxShadow: '0px 3px 6px #00000029' }}>
                    <Grid container>
                        <Grid item className="w-100">
                            <div className=''>
                                <div className="elibrary_rightcontainer_icon1 w-100">
                                    <div className='smalldevices'>
                                        <Button onClick={() => history.push('/elib/ebmkithomepage')}><ArrowBackIosNewIcon /></Button>
                                    </div>
                                    <img src={images[type]} alt="app_vault" />
                                    <Typography variant="h4" color="black" className="user-data" style={{ marginLeft: '6px', fontSize: '20px' }}>
                                        <span style={{ fontWeight: '600' }} className='capitalize'>{name}</span>
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            </div>
    )
}