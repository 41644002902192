import React from 'react'
import Header from './header'
import Sidebar from '../Molecule/Sidebar/Sidebarleft'
import Grid from '@mui/material/Grid';
import About from '../Molecule/ProfileViewConnect/AboutWriteBox'

export default function Profile(props) {
    return (
        <div>
            <Header />
            <Grid container spacing={2} sx={{ mt: 1, pr: 2 }} className={props.page}>
                <Grid item md={3} sx={{ mb: 1 }}>
                    <Sidebar count={true} hide={props.hide} />
                </Grid>
                <Grid item md={9} style={{ paddingLeft: 0 }}>
                    <div style={{ marginLeft: '10px' }}>
                        <About />
                    </div>
                    {props.children}
                </Grid>
            </Grid>
            <div className={props.overlay}></div>
        </div>
    )
}