import { Button, Grid, RadioGroup, FormControl, FormControlLabel, Radio, Checkbox } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import '../../Molecule/Elibrary/ElibraryRightContainer.css'
import { useRouteMatch } from 'react-router';
import EbmkitHeader from './EbmkitHeader';
import { Link } from 'react-router-dom'
import Search from './search'
import axios from 'axios'
import URL from '../../apiurl'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';
import './EbmKit.css';
// import DialogBox from '../../DialogBox/dialog';


function EbmPracticeCardiology(props) {
    const [openDialog, setOpenDialog] = useState(false)
    const history = useHistory()
    const { url } = useRouteMatch();
    const [path, setPath] = useState('')
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [load, setLoad] = useState('')
    const [check, setCheck] = useState(false)
    const [value, setValue] = useState('')
    const [search, setSearch] = useState('')
    const refs = useRef(null);
    const parentRef = useRef(null);
    const [key, setKeys] = useState([])
    let { diseases } = useParams();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const fetchData=async()=>{
        let u = url
        u = url.split('/');
        let newList = await getList()
        setList(newList);
        setLoad(true)
        if (u[3]) {
            var p = u[3].split('-')
            setPath(p)
            getAllDiseases(p[1])
        }
        if (u[2] === 'conditions') {

            getAllDiseases(u[2])
        }
    }

    useEffect(()=>{
        fetchData()
    },[])

    // useEffect(async () => {
    // }, [])

    useEffect(() => {
        if (list) {
            refs.current = list.reduce((acc, letter) => {
                acc[letter] = React.createRef();
                return acc;
            }, {});
        }
    }, [list])

    const getList = async () => {

        let d = Array(26).fill(0).map((pr, index) => String.fromCharCode(65 + index))
        setList(['#', ...d])
        return ['#', ...d]
    }

    const getAllDiseases = async (p) => {
        setValue('')
        setCheck(false)
        // console.log(p)
        var list = await getList()
        if (url.includes('/conditions')) {
            axios.get(`${URL.elib}allDiseases/`)
                .then((res) => {
                    // console.log(res)
                    getFilter(res.data.data)
                })
                .catch(error => { })

        }
        else if (p || path) {

            axios.get(`${URL.elib}filterDiseases/${p || path[1]}`).then((res) => {
                getFilter(res.data.data.diseases)
            })
                .catch(error => { })

        }
    }

    const getFilter = async (data) => {
        var list = await getList()
        // console.log(list)
        let res = []
        data.map(d => d.status ? res.push(d) : '')
        var alpha = res.map(d => {
            if (d.disease && list.includes(d.disease.charAt(0))) {
                return d.disease.charAt(0)
            }
        })
        // console.log(alpha)
        var key = [... new Set(alpha.filter(v => v !== undefined).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1))]
        setKeys(key)
        // console.log(key)
        var a = [], x = []
        // console.log(list)
        x = ['#']
        res.map(d => {
            if (d.disease && !list.includes(d.disease.charAt(0))) {
                x = [...x, d]
                // console.log(d.disease,x)
                return x
            }

        })

        for (let i = 0; i < key.length; i++) {
            if (key[i] !== '#') {
                a = [...a, key[i]]
            }
            res.sort((a, b) => a.disease.toLowerCase() > b.disease.toLowerCase() ? 1 : -1).map((d, index) => {
                if (d.disease.startsWith(key[i])) {
                    // console.log(index)
                    a = [...a, d]
                }

            })

        }
        // console.log([...x,...a])
        if (x.length > 1) {
            setData([...x, ...a])
            setKeys(['#', ...key])
        }
        else {
            setData([...a])

        }
        setLoad(false)
        const parent = parentRef.current;
        parent.scrollTo({ top: 0 })
    }

    useEffect(() => {
        if (value || check) {

            axios.get(`${url.includes('/conditions') ? `${URL.elib}allDiseases/` : `${URL.elib}filterDiseases/${path[1]}`}`).then((res) => {
                // console.log(res)
                let data = res.data.data.diseases || res.data.data
                if (value && check) {
                    let diseases = data.filter(d => d.type === value && d.emergency === '1')
                    // console.log(diseases)
                    getFilter(diseases)
                }
                else if (value) {
                    let diseases = data.filter(d => d.type === value)
                    // console.log(diseases)
                    getFilter(diseases)
                }
                else if (check) {
                    let diseases = data.filter(d => d.emergency === '1')
                    // console.log(diseases)
                    getFilter(diseases)
                }
                // setData(res.data)
            })
                .catch(error => { })
        }
        else {
            // console.log('all')
            getAllDiseases(path[1])
        }

    }, [value, check])


    const onClick = ({ target: { dataset: { letter } } }) => {
        const element = refs.current[letter].current;
        const parent = parentRef.current;

        const onScroll = () => {
            const relativeTop = window.scrollY > parent.offsetTop ? window.scrollY : parent.offsetTop

            parent.scrollTo({
                behavior: "smooth",
                top: element.offsetTop - relativeTop
            });
        }

        window.removeEventListener("scroll", onScroll);
        window.addEventListener("scroll", onScroll);

        onScroll();

    }

    const tryAssignRef = (letter) => {
        return list.indexOf(letter) > -1 ? { ref: refs.current[letter] } : {};
    }



    return (
        <div className='elibrary_rightcontainer' style={{ height: '100%' }}>
            <EbmkitHeader path={path} href={`/elib/specialities/${path}`} />

            <div className="elibrary_rightcontainer_body" >
                <Grid container className="diseases_classheight" sx={{ height: { md: '100%' } }}>

                    {/*----------------- LARGE SCREEN &&  DESTOPS -----------------*/}

                    <div className='Desktop_display_cardiology'>
                        <Grid item style={{ width: '100%' }} >
                            <div className="components" >
                                <div className="filtering" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <Grid container className="" sx={{ px: 2, pt: 2 }} rowSpacing={2}>
                                        <Grid item sm={9} xs={12}>
                                            <div className="d-flex e-align" >
                                                <ArrowBackIcon onClick={() => history.goBack()} sx={{ mr: 2 }} />
                                                <SortOutlinedIcon />
                                                <Button variant="outlined" style={{ height: '26px', borderRadius: '10px' }} onClick={() => getAllDiseases()}> All </Button>
                                                <FormControl sx={{ ml: 2 }}>
                                                    <RadioGroup row className="radio"
                                                        value={value}
                                                        onChange={(e) => setValue(e.target.value)}
                                                    >
                                                        <FormControlLabel value="Pediatric" control={<Radio size="small" />} label="Pediatric" />
                                                        <FormControlLabel value="Obstetrics" control={<Radio size="small" />} label="Obstetrics" />
                                                        <FormControlLabel
                                                            sx={{ alignItems: 'flex-start', '& .MuiSvgIcon-root': { fontSize: 20 }, alignItems: 'center' }}
                                                            control={<Checkbox disableRipple checked={check} name="Emergency" onChange={() => setCheck(!check)} inputProps={{ 'aria-label': 'controlled' }} />}
                                                            label="Emergency"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item sm={3} xs={4} sx={{ textAlign: { sm: 'right' } }}>
                                            <Search value={search} handleSearch={(value) => setSearch(value)} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </div>

                    {/*----------------- TABLET && MOBILE -----------------*/}

                    <div className='Mobile_display_cardiology'>
                        <Grid item style={{ width: '100%' }} >
                            <div className="components" >
                                <div className="filtering" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <ul className="block-ul">
                                        <li>
                                            <div className="sort_icon">
                                                <ArrowBackIcon onClick={() => url.includes('/elib/specialities') ? history.push('/elib/specialities') : url.includes('/specialities') ? history.push('/elib/specialities') : history.goBack()} sx={{ mr: 2 }} />
                                                {/* <ArrowBackIcon onClick={() => history.goBack()} sx={{ mr: 2 }} /> */}
                                                <h4 className='display__text'>Conditions</h4>
                                            </div>
                                        </li>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <FormControl sx={{ ml: 2 }}>
                                                <RadioGroup row className="radio"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                >
                                                    {/* <Button variant="outlined" style={{ height: '26px', borderRadius: '10px' }} > All </Button> */}
                                                    <FormControlLabel onClick={() => getAllDiseases()} value="All" control={<Radio size="small" />} label="All" />
                                                    <FormControlLabel value="Pediatric" control={<Radio size="small" />} label="Pediatric" />
                                                    <FormControlLabel value="Obstetrics" control={<Radio size="small" />} label="Obstetrics" />
                                                    <FormControlLabel
                                                        sx={{ alignItems: 'flex-start', '& .MuiSvgIcon-root': { fontSize: 20 }, alignItems: 'center' }}
                                                        control={<Checkbox disableRipple checked={check} name="Emergency" onChange={() => setCheck(!check)} inputProps={{ 'aria-label': 'controlled' }} />}
                                                        label="Emergency"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Popover>
                                    </ul>
                                    <ul>
                                        <div className='search__filter'>
                                            <div className='mobile_hide'>
                                                <Search value={search} handleSearch={(value) => setSearch(value)} />
                                            </div>
                                            <SortOutlinedIcon onClick={handleClick} />
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div className='mobile_show'>
                                <Search value={search} handleSearch={(value) => setSearch(value)} />
                            </div>
                        </Grid>
                    </div>
                    {search ? '' : <Grid item className='desktop_show_alphabets'>
                        <div>
                            <div className=" alphabits filtering ul ">
                                <ul>

                                    {list.map((val, i) => {
                                        // console.log(key)
                                        return (
                                            <div key={i}>
                                                {key.includes(val) ? <li onClick={onClick} data-letter={val} key={val} style={{ cursor: 'pointer' }}>{val}</li> :
                                                    <li style={{ color: 'lightgrey' }}>{val}</li>}
                                            </div>
                                        )
                                    })
                                    }
                                </ul>
                                <div style={{ width: '100%' }}></div>
                            </div>
                        </div>
                    </Grid>}

                    <Grid item className={`display_matter ${search ? 'scrollheight' : ''}`}>
                        <div className="drugs_list h-100">
                            <ul ref={parentRef} className="keywords h-100" id='conditions_padding'>
                                {load ? <div style={{ height: '300px' }} className="elib-right__content"><CircularProgress disableShrink /></div> :

                                    data.filter(data => data.disease ? data.disease.toLowerCase().slice(0, search.length).includes(search.toLowerCase()) : data).map(val => {
                                        return (
                                            search !== '' ? val.disease ? <li style={{ cursor: 'pointer' }} {...tryAssignRef(val.disease)} key={val.id}>
                                                <Link to={`${url}/${val.disease.replace(/ /g, '_').replace(/-/g, '__').toLowerCase()}-${val.id}`}>{val.disease}</Link></li> : '' :
                                                val.disease ? <li style={{ cursor: 'pointer' }} {...tryAssignRef(val.disease)} key={val.id}><Link to={`${url}/${val.disease.replace(/ /g, '_').replace(/-/g, '__').toLowerCase()}-${val.id}`}>{val.disease}</Link></li>
                                                    : <li {...tryAssignRef(val)} style={{ borderBottom: '1px solid lightgrey' }} key={val}>{val}</li>

                                        )
                                    })
                                }

                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </div >
    )
}

export default EbmPracticeCardiology;

{/* <DialogBox open={openDialog} handleDialog={() => setOpenDialog(false) ></DialogBox></DialogBox> */ }
