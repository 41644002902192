import React, { useEffect, useState } from 'react';
import { Divider, Button, Avatar } from '@mui/material'
import { useHistory } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import URL from '../../apiurl';
import UserService from '../../../services/UserService';
import { getPost } from '../../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';

function Postuserdata(props) {
    const [data, setData] = useState([])
    // console.log(props)
    const history = useHistory()
    const { closeDialog } = props
    const dispatch = useDispatch()
    const postData = useSelector(state => state.userProfile.getPost)

    useEffect(() => {
        dispatch(getPost(props.data))
    }, [])

    useEffect(() => {
        if (postData.id) {
            setData(postData.likedData)
        }
    }, [postData])

    return (
        <div>
            <h3>{props.title} <span style={{ float: 'right' }} className="cursor" onClick={closeDialog}><CloseIcon /></span></h3>
            <Divider sx={{ mt: '8px!important', mb: '16px!important' }} />
            {data.map(user => (
                <div className="post__header" key={user.id}>
                    <Avatar src={user.userData.profilePic} />
                    <div className="post__info e-align">
                        <div>
                            <h2 className="cursor capitalize" onClick={() => history.push(`/profile/${(user.userData.fullName.replace(/ /g, '')).toLowerCase()}-${user.likedBy}`)}>{user.userData.fullName}</h2>
                            <p className="capitalize">{user.userData.caption}</p>
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="text-right">
                <Button variant="outlined" className="btn__primary add-option" onClick={closeDialog}>Close</Button>
            </div> */}
        </div>
    )
}

export default Postuserdata