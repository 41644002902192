import { React } from "react";
import { Card, Grid } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from "react-redux";
import HealthDepartmentPost from "../feedFolderInDept/postInDept";

const PostsAndArticles = () => {

    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    const orgData = useSelector(state => state.registration.userOrgData);
    console.log(healthServiceOrg, "5")
    return (
        <div>

            <div className="mv1">
                <Card sx={{ padding: "10px", minHeight: "150px" }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div className="font-head fntSemiBold">
                                Posts & Articles
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: "2px" }}>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" />
                            </div>
                        </Grid>
                    </Grid>

                    <HealthDepartmentPost PageId={orgData?.identifier?.[1]?.value} />

                </Card>
            </div>

        </div >
    )
}
export default PostsAndArticles;