import { Paper } from "@mui/material"

const NeonatAI = () => {
    return (
        <div style={{ height: '100%', }}>
            <iframe style={{ border: '1px solid lightgrey', }} src="https://app.neonatai.com" width="100%" height="99%" />
        </div>
    )
}

export default NeonatAI