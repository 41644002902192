import { East } from '@mui/icons-material'
import { Box, Chip, FormControlLabel, Switch } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import { Actions } from '../../../../common/components/layout'
import CustomInput from '../../../../common/inputs/customInput'
import CustomSelect from '../../../../common/inputs/customSelect'
// import { Actions } from '../../../common/components/layout'
// import CustomInput from '../../../common/inputs/customInput'
// import CustomSelect from '../../../common/inputs/customSelect'


const weeks = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const ScheduleAvailabilityAmenities = ({ workHours, appointmentRequired, close, submit }) => {

    const [required, setRequired] = useState(false)

    useEffect(() => {
        setRequired(appointmentRequired || false)
    }, [appointmentRequired])

    return (
        <Box >
            <ValidatorForm style={{ padding: '0px 20px', }} className="ph2" onSubmit={() => submit(required)}>
                <div style={{ height: "410px", overflow: 'auto' }}>
                    {workHours?.length > 0 &&
                        <>
                            <p style={{ display: 'flex', alignItems: "center", padding: "0 10px", fontWeight: '600', marginBottom: "0" }} className='flx-center ph2 pv0-5 fntBold mb0'>Service Availability</p>
                            <hr style={{ marginBottom: "10px" }} />

                            {weeks?.map((w, ind) =>
                                workHours?.map((wh, i) => wh.week === w.slice(0, 3) && (
                                    <p style={{ display: 'flex', padding: '0 20px', marginBottom: '1rem' }} className='flx ph2' key={i}>
                                        <span className="capitalize" style={{ flex: 0.4, textTransform: "uppercase" }}>{w}</span>
                                        <span style={{ flex: 0.8 }}>
                                            {wh?.time?.length > 0 ? wh.time.map((t, i) => (
                                                <Chip className="tal mr1 mt1" sx={{ textAlign: 'left', marginRight: '10px', marginTop: '10px', backgroundColor: '#5145D0', color: 'white', height: '27px' }} label={<>{moment(t.start, 'hh:mm:ss').format('hh:mm a')} <East sx={{ fontSize: '15px!important' }} /> {moment(t.end, 'hh:mm:ss').format('hh:mm a')}</>} />
                                            ))
                                                :
                                                wh.open && <Chip className="tal" sx={{ textAlign: 'left', backgroundColor: wh.open === 'open' ? '#2AB700' : '#FD0505', color: 'white', height: '27px', px: 1 }} label={wh.open === 'open' ? "Open All Day" : "Close"} />
                                            }
                                        </span></p>
                                )))}
                        </>}
                    <hr />

                    <p style={{ padding: '15px 15px', marginBottom: '5px' }} className="ph2 mb0-5">Require Appointment&nbsp;&nbsp;<FormControlLabel control={<Switch onChange={(value) => setRequired(value.target.checked)}
                        slotProps={{
                            endDecorator: {
                                sx: {
                                    minWidth: 24,
                                },
                            },
                        }}
                        checked={required} color="success" endDecorator={required ? 'Yes' : 'No'} />} label={required ? "Yes" : "No"} /></p>
                    <hr style={{ marginTop: '0' }} />
                    {/* <ValidatorForm style={{padding:'0px 20px',}} className="ph2" onSubmit={() => submit(required)}> */}
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }} className="flx-center"><p style={{ flex: 0.3 }}>Slot Duration</p><p style={{ flex: 0.3 }}><CustomSelect value={"30min"} options={['30min', '1hr', '2hrs']} /></p></div>
                    <div style={{ display: 'flex', alignItems: 'center' }} className="flx-center"><p style={{ flex: 0.3 }}>Max Booking Per slot</p><p style={{ flex: 0.3 }}><CustomInput /></p></div>
                </div>
                <Actions close={close} name="Submit" />
            </ValidatorForm>
        </Box>
    )
}

export default ScheduleAvailabilityAmenities