import React from 'react'
import Downloadapp from './Downloadapp'
import './signup.css'


export default function SignUpLeft() {
    return (
        <React.Fragment>
            <div className='signup-left' >
                <div className="global sign-left">
                    A Global Consortium Medical Doctors
                </div>

                <div className="bottom-text">
                    Research - knowledge - Practice
                </div>
                <Downloadapp />
            </div>
            {/* <h5 className='ecosystem'>A healthcare eco-system powered by Social Networking</h5> */}
        </React.Fragment>
    )
}