import React, { useEffect, useState } from 'react';
import Suggestion from './suggestions'
import peers from '../Atom/modifiedicons/peers.svg';
// import team_icon from '../Atom/modifiedicons/team_icon.svg'
import Organisations from '../Atom/modifiedicons/Organisations.svg';
import axios from 'axios';
import URL from '../apiurl'
import { getMetaData } from '../../redux/Actions/actions';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Snackbar from '@mui/material/Snackbar';
import profimage from '../../icons/Userprofilelogo.svg'
import { useSelector } from 'react-redux';
import { socket } from '../../socket';
import { getConnectCount, getPeople, getRequests } from '../../redux/Actions/connectActions';

const Image = ({ image }) => {
  return (
    <div className="my-img">
      <img style={{ height: "65px", width: '65px', borderRadius: "8px", objectFit: "cover" }} src={(image) ? image : profimage} />
    </div>
  )
}

const MutualList = ({ connections, children }) => {

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <span
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleToggle}
        onMouseLeave={handleClose}>
        {children}
      </span>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
              zIndex: 1,
              textAlign: 'left'
            }}
          >
            <Paper sx={{ borderRadius: '3px!important', backgroundColor: 'black', p: 0.5, zIndex: 1 }}>
              {connections?.length > 0 ? connections?.map((user, i) => (<Typography sx={{ backgroundColor: 'black', color: 'white', fontSize: '12px!important', zIndex: 1 }} key={i}> {user.name}</Typography>)) : ''}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const MIcon = ({ num, Icon }) => {
  return (
    <span className="cursor">{num} <img height='15px' src={Icon} alt="icon" /></span>
  )
}

const Peers = (style, connections) => {

  return (
    connections?.mutualOrganisation && <div >
      {connections?.mutualOrganisation?.length > 0 || connections?.mutualPeers?.length > 0 ? 'Mutual : ' : <span style={{ color: "#b7b5b5" }}>No Mutuals</span>}

      {connections?.mutualOrganisation?.length > 0 ?
        <MutualList connections={connections?.mutualOrganisation} >
          <MIcon num={connections?.mutualOrganisation?.length || connections?.org} Icon={Organisations} />
        </MutualList>

        : ''}&nbsp;

      {connections?.mutualPeers?.length > 0 ?
        <MutualList connections={connections?.mutualPeers} >
          <MIcon num={connections?.mutualPeers?.length} Icon={peers} />
        </MutualList> : ''} &nbsp;&nbsp;

      {/* {connections.mutualGroups.length > 0 ? <MIcon num={connections.groups} Icon={team_icon}/> : ''} */}

    </div>
  )
}

export default function People({ type, data, name, name1 }) {
  const [user, setUser] = useState([])
  const dispatch = useDispatch();
  const [myreload, setmyreload] = useState(false);
  const menus = ['Peer Connect', "Follow"];
  const [alert, setAlert] = useState({ open: false, msg: '' })
  const [isLoading,setIsLoading]=useState(false);
  const state = useSelector(state => state.userProfile.userProfile)
  const styles = { display: 'flex', justifyContent: 'center', padding: '2px 10px' }
  const username = state.fullName
  const userID = state.id
  const people = useSelector(state => state.connect.people)
  console.log(people,"vero")
  const receiverimage = state.hasOwnProperty("practioner") ? state?.practioner?.photo?.[0]?.url : ""

  useEffect(() => {
    getUserData(name)
    return () => {
      setUser([])
    }
  }, [myreload, name])

  useEffect(() => {
    setUser(people)
  }, [people])

  const getUserData = (name) => {
    // console.log(name)
    let api = ''
    let key = name === 'Suggested People' ? true : false
    let limit = 20
    let offset = 1
    switch (name) {
      case 'Suggested People': window.location.pathname === '/connect/suggestedpeople' ? api = 'suggestedPeople' : api = 'suggestedPeople'
        break;
      case 'Peers': api = 'getPeers'
        break;
      case 'Following': api = 'getFollowing'
        break;
      case 'Followers': api = 'getFollowers'
        break;
      case 'Blocked Users': api = 'getBlockedList'
        break;
    }
    dispatch(getPeople(api, key, limit, offset,setIsLoading))
  }


  // console.log(isLoading,"ioio")
  // useEffect(() => {
  //   getsuggestedData()
  // }, [])

  // const getsuggestedData = () => {
  //   let api = ''
  //   let userid = sessionStorage.getItem("userId")
  //   if (window.location.pathname === '/connect/suggestedpeople') {
  //     // api = `suggestedPeople / ${userid} / limit / 15 / offset / 1`
  //     dispatch(getPeople(api / userid))
  //   }
  // }

  // FOLLOW FUNCTION START

  const follow = async (id) => {
    let data = {
      "followers": sessionStorage.getItem("userId"),
      "following": id,
      "metadata": await getMetaData()
    }
    axios.post(`${URL.addNetwork}`, data, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
      }
    }).then(res => {
      handleNotification(id, "FOLLOWING")
      // let data = [...user]
      // data = data.map(d => d.id === id ? { ...d, followStatus: "following", networkId: res.data.data.id } : { ...d })
      // setUser(data)
      dispatch(getConnectCount())
      getUserData(name)
    }).catch(err => {
      setAlert({ open: true, msg: "Internal server error. Refresh the page" });
    })
  }

  // CANCEL REQUEST FUNCTION START
  const cancelRequestFn = (id, reqId) => {
    axios.delete(`${URL.deleteRequestById}/${reqId}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
      }
    }).then(res => {
      // setmyreload(!myreload)
      let data = [...user]
      data = data?.map(d => d.requestId === reqId ? { ...d, status: "no request", requestId: null } : { ...d })
      setUser(data)
      dispatch(getConnectCount())
      if (window.location.pathname === '/connect') {
        dispatch(getRequests())
      }
      handleNotification(id, "REQUEST_DELETED")
    }).catch(err => {
      setAlert({ open: true, msg: "Internal server error Refresh the page" });
    })
  }

  // CANCEL FOLLOW REQUEST FUNCTION
  const unFollowedFn = (id, userID) => {
    axios.delete(`${URL.deleteNetworkById}/${id}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
      }
    }).then(res => {
      // setmyreload(!myreload)
      let data = [...user]
      data = data?.map(d => d.networkId === id ? { ...d, followStatus: "no following", networkId: null } : { ...d })
      setUser(data)
      dispatch(getConnectCount())
      getUserData(name)
      handleNotification(userID, "REQUEST_DELETED")
    }).catch(err => {
      setAlert({ open: true, msg: "Internal server error Refresh the page" });
    })
  }

  const handleNotification = (id, notificationFrom) => {
    socket?.emit("sendNotification", {
      name: username,
      senderID: userID,
      receiverID: id,
      notificationFrom: notificationFrom,
      profilePic: receiverimage
    });
  };

  // REQUESTING FUNCTION 
  const request = async (id) => {
    // console.log(id);
    let data = {
      "metadata": await getMetaData(),
      "requestedUser": id,
      "requestingFrom": sessionStorage.getItem('userId'),
      "status": "PENDING",
      "userId": sessionStorage.getItem('userId')
    }
    axios.post(`${URL.addRequest}`, data, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
      }
    }).then(res => {
      let data = [...user]
      // console.log(data)
      data = data?.map(d => d.id === id ? { ...d, status: "requested", requestId: res.data.data.id } : { ...d })
      setUser(data)
      dispatch(getConnectCount())
      handleNotification(id, "REQUESTED")
    }).catch(err => {
      setAlert({ open: true, msg: "Internal server error Refresh the page" });
    })
  }

  const closeCard = (id) => {
    let data = [...user]
    data = data.filter(value =>
      value.id !== id
    )
    setUser(data)
    // console.log(id)
  }

  return (
    <>
      <Suggestion type={type} displayname={name1} HeaderName={name} bgColor={name !== "Suggested People" ? "req-bg2" : 'request'} userdata={user} Image={Image}
        Peer={name !== "Suggested People" ? '' : Peers} style={styles} Follow={() => { return 'Connect' }}
        menus={menus} handleFollow={follow}
        handleConnect={request} handleCancel={cancelRequestFn} handleUnfollow={unFollowedFn}
        closeCard={closeCard} getUserData={getUserData} handleNotification={handleNotification} loading={isLoading} setIsLoading={setIsLoading}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        open={alert.open}
        onClose={() => setAlert({ open: false, msg: '' })}
        message={alert.msg}
      />
    </>
  );
}
