import { Button, Grid, RadioGroup, FormControl, FormControlLabel, Radio, Checkbox } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import './ElibraryRightContainer.css'
import { useRouteMatch } from 'react-router';
import EbmkitHeader from './DruginformationHeader';
import { Link } from 'react-router-dom'
import Search from '../EbmKit/search'
import axios from 'axios'
import URL from '../../apiurl'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popover from '@mui/material/Popover';

function DrugClasses(props) {
    const history = useHistory()
    const { url } = useRouteMatch();
    const [path, setPath] = useState('')

    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [option, setOption] = useState('')
    const [check, setCheck] = useState(false)
    const [value, setValue] = useState('')
    const [search, setSearch] = useState('')
    const refs = useRef(null);
    const parentRef = useRef(null);
    const [key, setKeys] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const fetch = async () => {
        let u = url
        u = url.split('/');
        let newList = await getList()
        setList(newList);

        // console.log(u[u.length-1])
        if (url.includes('drugclasses')) {
            getDrugs(29)
        }
        else {
            getDrugs(u[u.length - 1].split('-')[1])
        }
    }


    useEffect(() => {
        fetch()
    }, [])


    // useEffect(async () => {

        // let u = url
        // u = url.split('/');
        // let newList = await getList()
        // setList(newList);

        // // console.log(u[u.length-1])
        // if (url.includes('drugclasses')) {
        //     getDrugs(29)
        // }
        // else {
        //     getDrugs(u[u.length - 1].split('-')[1])
        // }
        // console.log(u[u.length-1])

    // }, [])

    useEffect(() => {
        if (list) {
            refs.current = list.reduce((acc, letter) => {
                acc[letter] = React.createRef();
                return acc;
            }, {});
        }
    }, [list])

    const getList = async () => {

        let d = Array(26).fill(0).map((pr, index) => String.fromCharCode(65 + index))
        setList(['#', ...d])
        return ['#', ...d]
    }

    const getDrugs = async (p) => {
        setValue('')
        setCheck(false)
        var list = await getList()
        axios.get(`${URL.getClasses}${p}`).then((res) => {
            let drugClasses = res.data.data[0].class_wise_drugs.data
            {
                let data = drugClasses.map(d => Object.create({ name: d.name }))
                getFilter(data)
            }
        })
            .catch(error => { })

        // }
    }

    const getFilter = async (res) => {
        // console.log(res)
        var list = await getList()
        // console.log(list)
        var alpha = res.map(d => {
            if (d.name && list.includes(d.name.charAt(0))) {
                return d.name.charAt(0)
            }
        })
        // console.log(alpha)
        var key = [... new Set(alpha.filter(v => v !== undefined).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1))]
        setKeys(key)
        // console.log(key)
        var a = [], x = []
        // console.log(list)
        x = ['#']
        res.map(d => {
            if (d.name && !list.includes(d.name.charAt(0))) {
                x = [...x, d]
                // console.log(d.name,x)
                return x
            }

        })

        for (let i = 0; i < key.length; i++) {
            if (key[i] !== '#') {
                a = [...a, key[i]]
            }
            res.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((d, index) => {
                if (d.name.startsWith(key[i])) {
                    // console.log(index)
                    a = [...a, d]
                }

            })

        }
        // console.log([...x,...a])
        if (x.length > 1) {
            setData([...x, ...a])
            setKeys(['#', ...key])
        }
        else {
            setData([...a])

        }

        if (parentRef.current) {
            const parent = parentRef.current;
            parent.scrollTo({ top: 0 })
        }
    }


    const onClick = ({ target: { dataset: { letter } } }) => {
        const element = refs.current[letter].current;
        const parent = parentRef.current;

        const onScroll = () => {
            const relativeTop = window.scrollY > parent.offsetTop ? window.scrollY : parent.offsetTop

            parent.scrollTo({
                behavior: "smooth",
                top: element.offsetTop - relativeTop
            });
        }

        window.removeEventListener("scroll", onScroll);
        window.addEventListener("scroll", onScroll);

        onScroll();

    }

    const tryAssignRef = (letter) => {
        return list.indexOf(letter) > -1 ? { ref: refs.current[letter] } : {};
    }


    return (
        <div className='elibrary_rightcontainer'>
            <Helmet>
                {/* <title>Docsteth | Drugs </title> */}
            </Helmet>
            <EbmkitHeader path={path} href={`/elib/specialities/${path}`} />

            <div className="elibrary_rightcontainer_body">
                <Grid container  >

                    {/*----------------- LARGE SCREEN &&  DESTOPS -----------------*/}

                    <Grid item style={{ width: '100%' }}>
                        <div className='Desktop_display_cardiology'>
                            <div className="Ebm_components ta__left" >
                                <div className="Ebm_filtering e-align mt-10">
                                    <div className="sort_icon">
                                        <ArrowBackIcon onClick={() => history.push('/elib/drugspecialities')} sx={{ mr: 2 }} />
                                        <h4>Index</h4>
                                        <SortOutlinedIcon />
                                        <Button variant="outlined" className="drug-btn"><Link style={{ borderColor: 'rgba(25, 118, 210, 0.5)', color: '#1976d2' }} to='/elib/drugclasses'>Classes</Link></Button>

                                    </div>

                                    <div>
                                        <Search value={search} handleSearch={(value) => setSearch(value)} />
                                    </div>

                                </div>

                            </div>

                        </div>
                    </Grid>

                    {/*----------------- TABLET && MOBILE -----------------*/}

                    <Grid item style={{ width: '100%' }}>
                        <div className='Mobile_display_cardiology'>
                            <div className="Ebm_components ta__left" >
                                <div className="Ebm_filtering">
                                    <ul>
                                        <li>
                                            <div className="sort_icon">
                                                <ArrowBackIcon onClick={() => url.includes('/elib/specialities') ? history.push('/elib/specialities') : url.includes('/specialities') ? history.push('/elib/specialities') : history.goBack()} sx={{ mr: 2 }} />
                                                {/* <ArrowBackIcon onClick={() => history.goBack()} sx={{ mr: 2 }} /> */}
                                                <h4 className='display__text'>Classes</h4>
                                            </div>
                                        </li>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <FormControl sx={{ ml: 2 }}>
                                                <RadioGroup row className="radio"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                >
                                                    <Link to='/elib/drugclasses'><FormControlLabel value="Classes" control={<Radio size="small" />} label="Classes"></FormControlLabel></Link>
                                                </RadioGroup>
                                            </FormControl>
                                        </Popover>
                                    </ul>
                                    <ul>
                                        <div className='search__filter'>
                                            <div className='mobile_hide'>
                                                <Search value={search} handleSearch={(value) => setSearch(value)} />
                                            </div>
                                            <SortOutlinedIcon onClick={handleClick} />
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div className='mobile_show'>
                                <Search value={search} handleSearch={(value) => setSearch(value)} />
                            </div>
                        </div>
                    </Grid>

                    {search ? '' : <Grid item className='desktop_show_alphabets'>
                        <div className=" alphabits filtering ul ">
                            <ul>

                                {list.map((val, i) => {
                                    // console.log(key)
                                    return (
                                        <div key={i}>
                                            {key.includes(val) ? <li onClick={onClick} data-letter={val} key={val} style={{ cursor: 'pointer' }}>{val}</li> :
                                                <li key={i} style={{ color: 'lightgrey' }}>{val}</li>}
                                        </div>
                                    )
                                })
                                }
                            </ul>
                            <div style={{ width: '100%' }}></div>
                        </div>
                    </Grid>}

                    <Grid item className={`display_matter ${search ? 'scrollheight' : ''}`}>
                        <div className="drugs_list h-100">
                            <ul ref={parentRef} className="keywords h-100" id='conditions_padding'>
                                {
                                    data.filter(data => data.name ? data.name.toLowerCase().slice(0, search.length).includes(search.toLowerCase()) : data).map(val => {
                                        return (
                                            search !== '' ?
                                                val.name ?
                                                    <li style={{ cursor: 'pointer' }} {...tryAssignRef(val.name)} key={val.name}>
                                                        <Link to={{ pathname: `${url}/${val.name.replace(/ /g, '_').replace(/\//g, '__').replace(/-/g, '--').toLowerCase()}`, state: { data: val.drugs } }}>{val.name}</Link>
                                                    </li>
                                                    : ''
                                                :
                                                val.name ?
                                                    <li style={{ cursor: 'pointer' }} {...tryAssignRef(val.name)} key={val.name}>
                                                        <Link to={{ pathname: `${url}/${val.name.replace(/ /g, '_').replace(/\//g, '__').replace(/-/g, '--').toLowerCase()}`, state: { data: val.drugs } }}>{val.name}</Link>
                                                    </li>
                                                    : <li {...tryAssignRef(val)} style={{ borderBottom: '1px solid lightgrey' }} key={val}>{val}</li>

                                        )
                                    })
                                }

                            </ul>
                        </div>
                    </Grid>


                </Grid>
            </div>

        </div>
    )
}

export default DrugClasses
