import React, { useState } from "react";
import { Button, CardMedia, Grid, Typography } from "@mui/material";
// import dummyimage from '../../../assets/youcandoit.jpg'
// import { OutlinedButton } from "../../atoms/commonbutton";
// import DefaultImg from "../../../../../../images/default_image.jpg";
import DefaultImg from "../../../../../../../../images/default_img.jpg";
import { OutlinedButton } from "../../../../../../../common/components/layout";


// import { OutlinedButton } from "../../../../../common/components/layout";

const ArticleView = ({ docFeed }) => {
    const articleData = docFeed?.articleData;
    console.log(docFeed, "docfeed article");
    const [expand, setExpand] = useState(false)
    return (


        <div style={{ width: "100%" }}>

            <div style={{ height: "30px" }} >
                {docFeed?.articleData?.coAuthors ? (
                    <Typography sx={{ p: "0px 10px", fontSize: "14px" }}>
                        Co-Authors:{" "}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: docFeed?.articleData?.coAuthors,
                            }}
                        />
                    </Typography>
                ) : (
                    ""
                )}
            </div>
            <div style={{ height: "30px" }}>
                {docFeed?.articleData?.contributors ? (
                    <Typography sx={{ p: "0px 10px 5px 10px", fontSize: "14px" }}>
                        Contributors:{" "}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: docFeed?.articleData?.contributors,
                            }}
                        />
                    </Typography>
                ) : (
                    ""
                )}
            </div>
            <div className="font-medium" style={{ height: "60px" }}>

                <div style={{ padding: "0px 10px", fontSize: "14px" }}>{docFeed?.description?.length > 100 ? docFeed?.description?.slice(0, 100) : docFeed?.description}</div>
                <span>{
                    docFeed?.description?.length > 100 && <Typography
                        component={"span"}
                        sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                    >
                        {expand ? "...see less" : "...see more"}{" "}
                    </Typography>
                }
                </span>
            </div>
            <div style={{ width: "100%", height: "250px", position: "relative" }} >

                <CardMedia
                    className="object-fill"
                    component="img"
                    style={{
                        // height: { xs: "150px", sm: "150px", md: "150px" },
                        objectFit: "cover",
                        objectPosition: "center",
                        width: "100%",
                        height: "100%"
                    }}
                    src={docFeed?.sourceLink ? docFeed?.sourceLink : DefaultImg}
                    alt="feedimg"
                />

                <div style={{
                    position: 'absolute', bottom: 0, left: 0, right: 0
                }}>
                    {docFeed?.articleData?.articleTitle !== null ? (
                        // <a href={`/viewarticle/${docFeed.id}`}>
                        <a href={`/viewarticle/${docFeed?.id}`}>
                            <div
                                style={{
                                    backgroundColor: "#e8e8e0",
                                    display: "flex",
                                    padding: "10px 20px", opacity: 0.9,
                                    justifyContent: "space-between",
                                    // marginBottom: "100px",

                                    // border: "1px solid red",
                                }}
                                className="d-flex cursor"
                            >
                                {articleData?.articleTitle ? (
                                    <p style={{ overflow: "hidden", flex: 1 }}>
                                        <Typography
                                            className="font-bold capitalize add-cover text-ellipsis "
                                            style={{ pt: 1, fontSize: "18px" }}
                                        >
                                            {articleData?.articleTitle}
                                        </Typography>
                                    </p>
                                ) : null}

                                <OutlinedButton text="Read Articles" fontSize="14px" />
                            </div>
                        </a>) : null}
                </div >
            </div>

        </div >


    );
};

export default ArticleView;
