
import Logo from '../../images/Logo2.svg'

export default function DLogo({ header, subtext, size }) {
    return (
        <div className={`${header} f-logo`}>
            <img src={Logo} alt='docsteth' className={size ? size : 'logo-height'} />
            <span className={`${subtext}`} style={{ color: "#0077c4" }}>ocsteth</span>
        </div>
    )
}