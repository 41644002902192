
import React, { useState } from "react";
import { Box, CardMedia, Typography, Button } from "@mui/material";
import { OutlinedButton } from "../../../../../common/components/layout";
import event_icon from '../../../../../../images/event.svg';
// import event_icon from '../../../../images/event.svg'
import UserService from "../../../../../../services/UserService";
import { useHistory } from "react-router-dom";
import moment from "moment";



export const AttendBtn = ({ text, fun, variant }) => {
    return (
        <Typography component="div">
            <p className="text-right">
                <Button className={`color-head add-option ${variant ? 'pointer_none' : ''}`} variant="outlined">{text}</Button>
            </p>
        </Typography>
    )
}
const Events = ({ docFeed, ...props }) => {
    const [expand, setExpand] = useState(false);
    const handleExpandClick = () => {
        // setExpanded(true);
    };

    const history = useHistory();

    const expired = () => {
        return new Date(moment.utc(docFeed?.eventData?.closingTime).local()) > new Date()
    }
    return (
        <>
            <div
                style={{
                    position: 'relative'
                }}
            >


                <div className="font-medium" style={{ height: "60px" }}>

                    <div style={{ padding: "0px 10px", fontSize: "14px" }}>{docFeed?.description?.length > 100 ? docFeed?.description?.slice(0, 100) : docFeed?.description}</div>
                    <span>{
                        docFeed?.description?.length > 100 && <Typography
                            component={"span"}
                            sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                        >
                            {expand ? "...see less" : "...see more"}{" "}
                        </Typography>
                    }
                    </span>
                </div>
                <div>


                    {/* image */}
                    {/* <div className="mt-0.5">
                        {docFeed.eventData.eventImage ? (
                            <CardMedia
                                className="object-fill"
                                component="img"
                                // height="194"
                                sx={{
                                    height: { xs: "150px", sm: "200px", md: "220px" },
                                    //   border: "1px solid red",
                                }}
                                src={docFeed.eventData.eventImage}
                                alt=""
                            />
                        ) : (
                            ""
                        )}
                    </div> */}


                    {/* <div style={{
                        position: 'absolute', backgroundColor: "#F6F6F7",
                        bottom: 0, left: 0, right: 0, padding: "10px 20px",
                        opacity: 0.9,

                    }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            className="d-flex cursor"
                        >
                            <div style={{ color: '#1D5775', fontWeight: 600, fontSize: 14 }}>Event</div>
                            <OutlinedButton text="Register" borderRadius="20px" height="25px" fontSize="13px" borderColor="#1D5775" color="#1D5775" />
                        </div>
                
                    </div> */}
                </div>
            </div>

            <Box >
                <div style={{ padding: "0px 10px" }}>
                    {docFeed?.eventData?.organiser ? <><span className="font-bold">Organisers:</span> <span dangerouslySetInnerHTML={{ __html: docFeed?.eventData?.organiser }} /></> : ''}
                    {docFeed?.eventData?.sponsor ? <p style={{ marginTop: '10px' }}><span className="font-bold">Sponsors:</span> <span dangerouslySetInnerHTML={{ __html: docFeed?.eventData?.sponsor }} /></p> : ''}
                    {docFeed?.eventData?.eventLocation ? <p style={{ marginTop: '10px' }}><span className="font-bold">Location:</span> <span>{docFeed?.eventData?.eventLocation}</span></p> : ''}
                    {/* {data.loc || data.eventLocation ? <p style={{ marginTop: '10px' }}><span className="font-bold">Location:</span> <span>{data.loc || data.eventLocation}</span></p> : ''} */}
                    {(docFeed?.eventData?.preregistration) && docFeed?.eventData.link ? <p style={{ marginTop: '10px' }}><a className="add-cover cursor" target="_blank" href={docFeed?.eventData?.registrationForm}>Registration Link</a></p> : ''}
                    {/* {(data.preregistration || data.pre_reg) && data.link ? <p style={{ marginTop: '10px' }}><a className="add-cover cursor" target="_blank" href={data.registrationForm || data.reg_form}>Registration Link</a></p> : ''} */}
                    {docFeed?.eventData?.link ? <p style={{ marginTop: '10px' }}> <a href={docFeed?.eventData.link} target="_blank" className="add-cover cursor">Meeting Link</a></p> : ''}
                </div>
                <Box sx={{ boxShadow: '0 3px 5px lightgrey' }}>
                    <div style={{ position: 'relative' }}>
                        {docFeed?.eventData?.eventImage && docFeed?.eventData?.eventImage?.length > 0 ? <div style={{ height: '160px', overflow: 'hidden' }} className="cursor">
                            <img src={docFeed?.eventData?.eventImage} alt="Event Image" style={{
                                maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', width: '100%',
                            }} />
                            <div style={{ backgroundColor: 'rgb(228 238 245 / 85%)', width: '100%', position: 'absolute', bottom: 0 }} className="newcontact-fieldheader">

                                {/* {sub ? <Button className="evnt-btn add-option" sx={{pt: 0}} variant="outlined">Subscribed</Button> : */}
                                <div className="overflow_hidden" style={{ padding: '5px' }}>
                                    <Typography className="d-flex e-align color_head font-bold cursor capitalize" >
                                        <img src={event_icon} width='20px' height={'20px'} />&nbsp;<span className="text-ellipsis">{docFeed?.eventData?.title}</span>
                                    </Typography>
                                    <Typography sx={{ mt: '2px' }}>{moment(docFeed?.eventData?.openingTime).format('DD/MM/YYYY')} - {moment(docFeed?.eventData?.closingTime).format('DD/MM/YYYY')}</Typography>
                                </div>
                                {/* <Typography component="div"><p className="text-right"><Button className="color-head add-option" sx={{ backgroundColor: 'white !important' }} variant="outlined">Attend</Button></p></Typography> */}
                                {/* } */}

                                {expired() ?
                                    docFeed?.eventData?.eventAdmin != UserService.getUserId() && docFeed?.eventData?.link ? (docFeed?.eventData?.registrationForm) ? <AttendBtn text="Attend" fun={() => window.open(docFeed?.eventData?.registrationForm, '_blank')} /> : '' : props.children
                                    : docFeed?.eventData?.eventAdmin != UserService.getUserId() && <AttendBtn text="Completed" fun={() => { }} variant="outlined" />}
                            </div>

                        </div> : ''}
                    </div>

                </Box>
            </Box>
        </>

    )
}
export default Events;