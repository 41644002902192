import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function FreeSoloAutomplete(props) {
    const [value, setValue] = React.useState(null);
    console.log(value, "sssssdfghj")
    return (
        <Autocomplete
            value={props.value}
            name={props.name}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    // setValue({
                    //     title: newValue,
                    // });
                    props.handleOrg({ title: newValue })

                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    // setValue({
                    //     title: newValue.inputValue,
                    // });
                    console.log(newValue, "22222")
                    // props.handleOrg({ name: props.name, title: newValue.inputValue })
                    props.handleOrg(props.name, newValue.inputValue)
                } else {
                    // setValue(newValue);
                    console.log(newValue, "111111")
                    props.handleOrg(props.name, newValue)

                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.display);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        display: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={props.options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                console.log(option, "gggggg")
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return `${option?.code} - ${option?.display}`;
            }}
            renderOption={(props, option) => <li {...props}>{`${option?.code} - ${option?.display}`}</li>}
            sx={{
                width: "100%",
                border: 'none',
                borderRadius: '10px',
                boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'white',
                '.MuiOutlinedInput-root': {
                    padding: '4px',
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white',
                    // borderRadius: "10px",
                },

            }}
            freeSolo

            renderInput={(params) => (
                <TextField {...params} placeholder={props.placeholder} />
            )}
        />
    );
}

