import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListHeader from "./ListHeader";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import UserInfo from "./userInfo";
import SuggMenu from "./sugg-menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import URL from "../apiurl";
import axios from "axios";
import { useDispatch } from "react-redux";
import "./peer.css";
import UserService from "../../services/UserService";
import { createTheme, ThemeProvider } from "@mui/material";
import DialogBox from "../DialogBox/dialog";
import { getConnectCount, getPeople } from "../../redux/Actions/connectActions";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 450,
      sm: 700,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

export default function Suggestions({
  closeCard,
  type,
  userdata,
  HeaderName,
  bgColor,
  Image,
  Peer,
  style,
  Follow,
  menus,
  handleFollow,
  handleConnect,
  handleCancel,
  handleUnfollow,
  displayname,
  getUserData,
  ...props
}) {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState({
    open: false,
    peerId: "",
    userId: "",
    networkId: "",
    blockId: "",
    name: "",
  });
  const [data, setData] = React.useState(userdata);
  const [count, setCount] = React.useState(false);
  const [limit, setLimit] = React.useState(15);


  console.log(data, "suggested people", userdata, props.loading, props.name,);

  React.useEffect(() => {
    setData(userdata);
    // setCount(limit !== userdata?.length ? false : true);
    setCount(userdata?.length > limit ? true : false);
  }, [userdata]);

  console.log(count,"vero", userdata?.length,limit)
  // console.log(data, "vero");

  const followFun = (value) => {
    if (value.followStatus === "following") {
      setModal({
        open: true,
        networkId: value.networkId,
        name: "unfollow",
        userId: value.id,
      });
    } else {
      handleFollow(value.id);
    }
  };

  const requestDisconnectFn = (peerId, userID) => {
    axios
      .delete(`${URL.deletePeersByUser}/${peerId}/user/${userID}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("kctoken")}`,
        },
      })
      .then((res) => {
        setModal({
          open: false,
          peerId: "",
          userId: "",
          networkId: "",
          name: "",
        });
        dispatch(getConnectCount());
        getUserData("Peers");
        props.handleNotification(userID, "REQUEST_DELETED");
      })
      .catch((err) => {
        //   setAlert({ open: true, msg: "Internal server error Refresh the page" });
      });
  };

  const unblock = (id) => {
    axios
      .delete(`${URL.unblockUser}/${UserService.getUserId()}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
        },
      })
      .then((res) => {
        setModal({
          open: false,
          peerId: "",
          userId: "",
          networkId: "",
          blockId: "",
          name: "",
        });
        dispatch(getConnectCount());
        getUserData("Blocked Users");
      });
  };

  const handlemore = () => {
    let api = "suggestedPeople";
    let key = true;
    setLimit(limit + 15);
    let offset = 1;
    dispatch(getPeople(api, key, limit + 15, offset, props.setIsLoading));
  };

  // console.log(HeaderName, displayname, "0101")

  return (
    <ThemeProvider theme={theme}>
      <DialogBox
        width="xs"
        open={modal.open}
        handleDialog={() =>
          setModal({
            open: false,
            peerId: "",
            userId: "",
            networkId: "",
            blockId: "",
            name: "",
          })
        }
        Content={<p>Do you want to {modal.name}?</p>}
        Actions={
          <div>
            {modal.name === "disconnect" ? (
              <Button
                variant="contained"
                className="add-option"
                sx={{ mr: 2 }}
                onClick={() => requestDisconnectFn(modal.peerId, modal.userId)}
              >
                Yes
              </Button>
            ) : modal.name === "unblock" ? (
              <Button
                variant="contained"
                className="add-option"
                sx={{ mr: 2 }}
                onClick={() => unblock(modal.blockId)}
              >
                Yes
              </Button>
            ) : (
              <Button
                variant="contained"
                className="add-option"
                sx={{ mr: 2 }}
                onClick={() => {
                  handleUnfollow(modal.networkId, modal.userId);
                  setModal({
                    open: false,
                    peerId: "",
                    userId: "",
                    networkId: "",
                    name: "",
                  });
                }}
              >
                Yes
              </Button>
            )}

            <Button
              variant="contained"
              className="add-option"
              onClick={() => setModal({ open: false, peerId: "", userId: "" })}
            >
              No
            </Button>
          </div>
        }
      />
      <ListHeader HeaderName={HeaderName} displayname={displayname}>
        <ListItem
          className="req-list"
          sx={{ maxHeight: `${type ? "100%" : "280px"}` }}
        >
          {props?.loading == false && data?.length > 0 ? (
            <>
              <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    wrap={`${type ? "wrap" : "nowrap"}`}
                    spacing={2}
                  >
                    {data?.map((value) => (
                      <Grid
                        key={value.id || value.userId}
                        item
                        id={value.id}
                        lg={type ? 3 : false}
                        md={type ? 4 : false}
                        sm={type ? 4 : false}
                        xs={type ? 6 : false}
                        xxs={type ? 12 : 0}
                      >
                        <Paper
                          className={`${
                            type ? "res-card" : "card-width"
                          } custom-card`}
                          style={{ height: "260px" }}
                        >
                          <Grid item xs={12} sx={{ height: "50%" }}>
                            <div
                              className={`${bgColor} req-org-bg sugg-bg`}
                              style={{
                                display: "inherit",
                                background: `${
                                  value.coverPic
                                    ? `transparent url(${value.coverPic}) 0% 0% no-repeat padding-box`
                                    : ``
                                }`,
                              }}
                            >
                              <p style={{ padding: "6px 10px 0 10px" }}>
                                {bgColor === "org-bg" ||
                                bgColor === "org-bg2" ||
                                bgColor === "req-bg2" ? (
                                  <span
                                    style={{
                                      width: "20px",
                                      padding: "1px",
                                      height: "20px",
                                    }}
                                  >
                                    &nbsp;
                                  </span>
                                ) : (
                                  <Tooltip
                                    title={`${
                                      value.followStatus === "following"
                                        ? "Unfollow"
                                        : "Follow"
                                    }`}
                                    placement="top"
                                  >
                                    <span
                                      className="sugg-icon"
                                      onClick={() => followFun(value)}
                                    >
                                      <PersonIcon
                                        style={{
                                          fontSize: "15px",
                                          paddingLeft: "2px",
                                        }}
                                        className="icon-clr"
                                      />
                                      <KeyboardArrowRightIcon className="arrow-icon icon-clr" />
                                    </span>
                                  </Tooltip>
                                )}
                                {bgColor === "org-bg2" ||
                                bgColor === "req-bg2" ? (
                                  ""
                                ) : (
                                  <span
                                    className="close-icon"
                                    style={{ float: "right" }}
                                    onClick={() => closeCard(value.id)}
                                  >
                                    <CloseIcon />
                                  </span>
                                )}
                              </p>
                              <Image
                                image={
                                  value.image || value.photo || value.profilePic
                                }
                              />
                            </div>
                            <UserInfo
                              card="card-font"
                              userData={value}
                              Peer={
                                Peer ? Peer(style, value.mutuals || value) : ""
                              }
                              type={bgColor}
                            >
                              <div className="a">
                                {HeaderName === "Followers" ? (
                                  <Button
                                    variant="outlined"
                                    sx={{ px: 2, py: 0, textAlign: "center" }}
                                    className="pad accept capitalize"
                                    onClick={() => {
                                      value.following
                                        ? setModal({
                                            open: true,
                                            networkId: value.networkId,
                                            userId: value.id,
                                            name: "unfollow",
                                          })
                                        : handleFollow(value.id);
                                    }}
                                  >
                                    {value.following ? "Following" : "Follow"}
                                  </Button>
                                ) : HeaderName === "Following" ? (
                                  <Button
                                    variant="outlined"
                                    sx={{ px: 2, py: 0, textAlign: "center" }}
                                    className="pad accept capitalize"
                                    onClick={() =>
                                      setModal({
                                        open: true,
                                        networkId: value.networkId,
                                        name: "unfollow",
                                        userId: value.id,
                                      })
                                    }
                                  >
                                    Following
                                  </Button>
                                ) : HeaderName === "Blocked Users" ? (
                                  <Button
                                    variant="outlined"
                                    sx={{ px: 2, py: 0, textAlign: "center" }}
                                    className="pad accept capitalize"
                                    onClick={() =>
                                      setModal({
                                        open: true,
                                        blockId: value.id,
                                        name: "unblock",
                                      })
                                    }
                                  >
                                    Unblock
                                  </Button>
                                ) : bgColor === "req-bg2" ? (
                                  <Button
                                    variant="outlined"
                                    sx={{ px: 2, py: 0, textAlign: "center" }}
                                    className="pad accept capitalize"
                                    onClick={() =>
                                      setModal({
                                        open: true,
                                        peerId: value.peerId,
                                        userId: value.userId,
                                        name: "disconnect",
                                      })
                                    }
                                  >
                                    Connected
                                  </Button>
                                ) : (
                                  <SuggMenu
                                    Follow={Follow}
                                    userData={value}
                                    menus={menus}
                                    handleFollow={handleFollow}
                                    value={value}
                                    {...props}
                                    handleConnect={handleConnect}
                                    handleCancel={handleCancel}
                                    handleUnfollow={handleUnfollow}
                                    closeCard={closeCard}
                                  />
                                )}
                              </div>
                            </UserInfo>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {window.location.pathname.includes(
                  "/connect/suggestedpeople"
                ) &&
                HeaderName === "Suggested People" &&
                count ? (
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button onClick={handlemore} className="load_more_btn">
                      Load more
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </>
          ) : HeaderName == "Suggested People" && (props?.loading != false) ? (
            <div className="flx-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <p className="text-primary">No {HeaderName}</p>
          )}
          {/* {data?.length > 0 ?
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
              <Grid item xs={12}>
                <Grid container direction='row' wrap={`${type ? 'wrap' : 'nowrap'}`} spacing={2}>
                  {data?.map((value) => (
                    <Grid key={value.id || value.userId} item id={value.id} lg={type ? 3 : false} md={type ? 4 : false} sm={type ? 4 : false} xs={type ? 6 : false} xxs={type ? 12 : 0}>
                      <Paper className={`${type ? 'res-card' : 'card-width'} custom-card`} style={{ height: '260px' }}>
                        <Grid item xs={12} sx={{ height: '50%' }}>
                          <div className={`${bgColor} req-org-bg sugg-bg`} style={{ display: 'inherit', background: `${value.coverPic ? `transparent url(${value.coverPic}) 0% 0% no-repeat padding-box` : ``}` }}>
                            <p style={{ padding: '6px 10px 0 10px' }}>
                              {bgColor === 'org-bg' || bgColor === 'org-bg2' || bgColor === 'req-bg2' ?

                                <span style={{
                                  width: "20px", padding: '1px',
                                  height: "20px"
                                }}>&nbsp;
                                </span>

                                : <Tooltip title={`${(value.followStatus === "following") ? "Unfollow" : "Follow"}`} placement="top" >
                                  <span className='sugg-icon' onClick={() => followFun(value)} >
                                    <PersonIcon style={{ fontSize: '15px', paddingLeft: '2px' }} className="icon-clr" /><KeyboardArrowRightIcon className="arrow-icon icon-clr" />
                                  </span>
                                </Tooltip>}
                              {bgColor === 'org-bg2' || bgColor === 'req-bg2' ? '' : <span className="close-icon" style={{ float: 'right' }} onClick={() => closeCard(value.id)}>
                                <CloseIcon />
                              </span>}
                            </p>
                            <Image image={value.image || value.photo || value.profilePic} />

                          </div>
                          <UserInfo card='card-font' userData={value} Peer={Peer ? Peer(style, value.mutuals || value) : ''} type={bgColor}>
                            <div className="a" >
                              {HeaderName === 'Followers' ?
                                <Button variant="outlined" sx={{ px: 2, py: 0, textAlign: 'center' }} className='pad accept capitalize' onClick={() => { value.following ? setModal({ open: true, networkId: value.networkId, userId: value.id, name: 'unfollow' }) : handleFollow(value.id) }}>{value.following ? "Following" : 'Follow'}</Button>
                                : HeaderName === 'Following' ?
                                  <Button variant="outlined" sx={{ px: 2, py: 0, textAlign: 'center' }} className='pad accept capitalize' onClick={() => setModal({ open: true, networkId: value.networkId, name: 'unfollow', userId: value.id })}>Following</Button>
                                  : HeaderName === 'Blocked Users' ?
                                    <Button variant="outlined" sx={{ px: 2, py: 0, textAlign: 'center' }} className='pad accept capitalize' onClick={() => setModal({ open: true, blockId: value.id, name: 'unblock' })}>Unblock</Button>
                                    : bgColor === 'req-bg2' ?
                                      <Button variant="outlined" sx={{ px: 2, py: 0, textAlign: 'center' }} className='pad accept capitalize' onClick={() => setModal({ open: true, peerId: value.peerId, userId: value.userId, name: 'disconnect' })}>Connected</Button>
                                      :
                                      <SuggMenu Follow={Follow} userData={value} menus={menus} handleFollow={handleFollow} value={value} {...props} handleConnect={handleConnect} handleCancel={handleCancel} handleUnfollow={handleUnfollow} closeCard={closeCard} />
                              }
                            </div>
                          </UserInfo>
                        </Grid >
                      </Paper >
                    </Grid >
                  ))
                  }
                </Grid >
              </Grid >
              {
                (window.location.pathname.includes('/connect/suggestedpeople') && HeaderName === 'Suggested People' && count) ?
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button onClick={handlemore} className='load_more_btn'>Load more</Button>
                  </Grid> : ""
              }
            </Grid > : <p className="text-primary">No {HeaderName}</p>} */}
        </ListItem>
      </ListHeader>
    </ThemeProvider>
  );
}
