import React, { useEffect, useState } from 'react';
import '../../Layout/MyProfile/updateprofile.css';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import URL from '../../apiurl';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import description from '../../../images/article.svg'
import UserService from '../../../services/UserService';
import { FiEdit3 } from 'react-icons/fi';
import Linksource from '../../../icons/linksource.svg';
import Dialog from "@mui/material/Dialog";
import { useDispatch } from 'react-redux';
// import UserService from '../../../services/UserService';
import celebration from '../../../images/celebration.svg';
import MobileStepper from '@mui/material/MobileStepper';
import InputOption from "../../Molecule/Feed/InputOption";
// import axios from 'axios';
import ClearIcon from '@mui/icons-material/Clear';
import Downloadicon from '../../../icons/downloadicon.svg';
// import Accomplishments from '../MyProfile/accomplishments';
import { accomplishment, getMetaData } from '../../../redux/Actions/actions';
import useMediaQuery from '@mui/material/useMediaQuery';
// import fileDownload from 'js-file-download';
import SwipeableViews from 'react-swipeable-views';
import { saveAs } from 'file-saver'
// import Add from '../MyProfile/accCrud'
import { useHistory } from 'react-router-dom';
import '../../Layout/profile/profile.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Grid, Button } from '@mui/material';
function Publicationprofile() {

    const theme = useTheme();
    const maxWidth = useMediaQuery('(max-width: 961px)')
    const minWidth = useMediaQuery('(min-width: 960px)')
    const [state, setState] = useState([])
    const [activeStep, setActiveStep] = React.useState(0);
    const [patientcount, setPatientcount] = useState(5)
    const [count, setCount] = useState(3)
    const [Patientdata, setPatientdata] = useState([])
    const [jrcount, setJrcount] = useState(3)
    const [journalpublic, setJournalpublic] = useState([])
    const [mediapublic, setMediapublic] = useState([])
    const [journal, setJournal] = useState([])
    const [media, setMedia] = useState([])
    const [data, setData] = useState([])
    const [isReadMore, setIsReadMore] = useState(true);
    const [open, setOpen] = useState(false)
    const [badges, setBadges] = useState([])
    const [form, setForm] = useState(3)
    const [publication, setPublication] = useState({})
    const [postopen, setpostopen] = useState(0)
    const { id } = useParams()
    // console.log(id)
    const history = useHistory()
    const dispatch = useDispatch()
    const toggleReadMore = () => {
        // console.log("hello")
        setIsReadMore(!isReadMore);
    };



    useEffect(() => {
        if (UserService.getToken()) {
            axios.get(`${URL.getpublicationbyuserid}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
                    }
                }
            )
                .then((response) => {
                    // console.log(response.data.data)
                    setState(response.data.data)
                })

            axios.get(`${URL.findArticleByUserId}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
                    }
                }
            ).then((res) => {
                // console.log(res.data.data)
                setPatientdata(res.data.data)
            })
        }
        else {
            axios.get(`${URL.getpublicationwithoutkeyloak}/${id}`).then((response) => {
                // console.log(response.data.data)
                setData(response.data.data)
            })
        }
    }, [])

    useEffect(() => {
        if (state?.length > 0) {
            let journaldata = state?.filter((element) => {
                return element.accomplishmentCategory !== "News_or_Media"
            })
            setJournal(journaldata)
        }
        if (state?.length > 0) {
            let mediadata = state?.filter((element) => {
                return element.accomplishmentCategory === "News_or_Media"
            })
            setMedia(mediadata)
            // console.log(mediadata)
        }
    }, [state])

    useEffect(() => {
        if (data?.length > 0) {
            let journaldata = data?.filter((element) => {
                return element.accomplishmentCategory !== "News_or_Media"
            })
            setJournalpublic(journaldata)
        }
        if (data?.length > 0) {
            let mediadata = data?.filter((element) => {
                return element.accomplishmentCategory === "News_or_Media"
            })
            setMediapublic(mediadata)
            // console.log(mediadata)
        }
    }, [data])

    const handleopen = () => {
        // console.log("abcd")
        setOpen(true)
    }

    const handledialogclose = () => {
        // console.log("1234")
        setOpen(false)
    }

    const handlegoback = () => {
        setpostopen(1)
        // setOpen(false)
    }

    const handlepost = () => {
        setpostopen(2)
    }

    const downloadfiles = (value) => {
        const filedata = value?.attachment?.split(".")
        const data = filedata[filedata?.length - 1]
        saveAs(value.attachment)
    }

    const maxSteps = media?.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handlearticle = (value) => {
        history.push(
            {
                pathname: "/patientarticle",
                state: value
            }
        )
    }

    return (
        <div>
            {minWidth ?
                <div>
                    {(UserService.getToken() !== undefined) ?
                        <Grid container>
                            <Grid item xs={12} md={4} className="margin_myprofile">
                                <div className='border_img sidebar__padding'>
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className='gallery_text'>Media Gallery</p>
                                        </div>
                                        {media?.length > 0 ?
                                            <div>
                                                {media?.slice(0, count)?.map((value, index) => {
                                                    return (
                                                        <div key={index} className="mt-15 border_img">
                                                            {value?.attachment ?
                                                                <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                            }
                                                            <div className='ppub' >
                                                                <div className='publication_text' style={{ width: "100%" }}>
                                                                    <div style={{ width: "60%" }}>
                                                                        {value?.title?.length > 90 ?
                                                                            <p>{value?.title?.slice(0, 90)}...</p> : <p>{value?.title}</p>
                                                                        }
                                                                    </div>
                                                                    <div style={{ width: "40%" }}>
                                                                        {value?.date ?
                                                                            <p>DOP : {moment(value?.date).format('DD-MMM-yyyy')} </p> : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='source_text'>
                                                                    {value?.link?.length > 80 ?
                                                                        <a href={value?.link} target="_blank">
                                                                            <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link?.slice(0, 80)}... </p>
                                                                        </a> : <a href={value.link} target="_blank">
                                                                            <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link}</p>
                                                                        </a>
                                                                    }
                                                                    {value?.description?.length > 80 ?
                                                                        <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                    <div className="sidebar__substat ta__center">
                                                        {media !== null && media?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(media?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ padding: "15px" }}>
                                                <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p className='journal_publication_text'>Journal Publications</p>
                                </div>
                                {journal?.length > 0 ?
                                    <div className='border_img'>
                                        {journal?.slice(0, jrcount)?.map((value, index) => {
                                            return (
                                                <div key={index} style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingTop: "20px" }}>
                                                    <div className='pl-2 pr-2' style={{ width: "85%" }}>
                                                        {(value?.accomplishmentCategory === "Original_Research_Article") ? <p className='article_description'> Original Research Article </p> :
                                                            (value?.accomplishmentCategory === "Review_Article") ? <p className='article_description'> Review Article </p> :
                                                                (value?.accomplishmentCategory === "Evidence_Review") ? <p className='article_description'> Evidence_Review </p> :
                                                                    (value?.accomplishmentCategory === "Case_Study_or_Series") ? <p className='article_description'> Case Study/Case Series </p> :
                                                                        (value?.accomplishmentCategory === "Patient_Education") ? <p className='article_description'> Patient Education </p> :
                                                                            (value?.accomplishmentCategory === "Patent") ? <p className='article_description'> Patent </p> : ""
                                                        }
                                                        <p className='art_head'>
                                                            {value?.title ? value?.title : ""}
                                                        </p>
                                                        <p className='article_description'>
                                                            {value?.organisation?.displayName}
                                                        </p>
                                                        {value?.coAuthors ?
                                                            <div style={{ paddingTop: "8px" }} dangerouslySetInnerHTML={{ __html: value?.coAuthors }} /> : ""
                                                        }
                                                        <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "8px" }}>
                                                            {value?.link?.length > 80 ?
                                                                <a href={value.link} target="_blank">
                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link?.slice(0, 80)}... </p>
                                                                </a> : <a href={value.link} target="_blank">
                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link}</p>
                                                                </a>
                                                            }
                                                            {value.date ?
                                                                <p className='publication_dop'>DOP:<span className='publication_date'>{moment(value?.date).format('DD-MMM-yyyy')}</span></p> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* {console.log(value.attachment)} */}
                                                    <div className='space_evenly_center' style={{ width: "15%" }}>
                                                        {value?.attachment ?
                                                            <div onClick={() => { downloadfiles(value) }}>
                                                                {/* <a href={value.attachment} download={value.attachment} target="_blank"> */}
                                                                <img src={Downloadicon} alt="downloadicon" width="15px" height="15px" className='image_download'></img>
                                                                <Button className='download_journal'>Download</Button>
                                                                {/* </a> */}
                                                            </div>
                                                            : ""
                                                        }
                                                        <div>
                                                            <a href={value?.link} target="_blank">
                                                                <img src={Linksource} alt="readicon" width="15px" height="15px" className='image_download'></img>
                                                                <Button className='read_journal'>Read</Button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="sidebar__substat ta__center">
                                                {journal !== null && journal?.length > 3 ? jrcount === 3 ? <p id="border_exp_btn" onClick={() => setJrcount(journal?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{journal?.length - 3} More Article{(journal?.length - 3) > 1 ? 's' : ''}&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setJrcount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='border_img' style={{ padding: "15px" }}>
                                        <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                    </div>
                                }
                                <div className='mt-15'>
                                    <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                        <p style={{ color: "#257FC4", fontSize: "18px", padding: "8px" }}>Patient Education Articles</p>
                                        {/* <IconButton aria-label="visible">
                                            <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                        </IconButton> */}
                                    </div>
                                    {Patientdata?.length > 0 ?
                                        <div className='border_img'>
                                            {Patientdata?.slice(0, patientcount)?.map((value, index) => {
                                                return (
                                                    <div style={{ padding: "15px" }}>
                                                        <div>
                                                            {value.articleTitle ?

                                                                <p className='article_title' onClick={() => handlearticle(value)}>{value.articleTitle}</p> : ""
                                                            }
                                                        </div>
                                                        <div>
                                                            {value.coAuthors ?
                                                                <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} ></p> : ""
                                                            }
                                                            {value.contributors ?
                                                                <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.contributors }} ></p> : ""
                                                            }
                                                            {value.organisation ?
                                                                <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.organisation }} ></p> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                <div className="sidebar__substat ta__center">
                                                    {Patientdata !== null && Patientdata?.length > 5 ? patientcount === 5 ? <p id="border_exp_btn" onClick={() => setPatientcount(Patientdata?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setPatientcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='border_img' style={{ padding: "15px" }}>
                                            <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                        </div>
                                    }
                                </div>
                                {/* <div>
                                            <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                                <p style={{ color: "#257FC4", fontSize: "24px" }}>Evidence Review</p>
                                                <IconButton aria-label="visible">
                                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                                </IconButton>
                                            </div>
                                            <div style={{ height: "100px" }}>

                                            </div>
                                            </div> */}
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={12} md={4} className="margin_myprofile">
                                <div className='border_img sidebar__padding'>
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p className='gallery_text'>Media Gallery</p>
                                        </div>
                                        {mediapublic?.length > 0 ?
                                            <div>
                                                {mediapublic?.slice(0, count)?.map((value, index) => {
                                                    return (
                                                        <div key={index} className="mt-15 border_img">
                                                            {value?.attachment ?
                                                                <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                            }
                                                            <div className='ppub' >
                                                                <div className='publication_text' style={{ width: "100%" }}>
                                                                    <div style={{ width: "60%" }}>
                                                                        {value?.title?.length > 80 ?
                                                                            <p>{value.title?.slice(0, 80)}...</p> : <p>{value.title}</p>
                                                                        }
                                                                    </div>
                                                                    <div style={{ width: "40%" }}>
                                                                        <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                                    </div>
                                                                </div>
                                                                <div className='source_text'>
                                                                    {value.link?.length > 80 ?
                                                                        <a href={value.link} target="_blank">
                                                                            <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link?.slice(0, 80)}... </p>
                                                                        </a> : <a href={value.link} target="_blank">
                                                                            <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link}</p>
                                                                        </a>
                                                                    }
                                                                    {value?.description?.length > 80 ?
                                                                        <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                    <div className="sidebar__substat ta__center">
                                                        {mediapublic !== null && mediapublic?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(mediapublic?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p className='journal_publication_text'>Journal Publications</p>
                                </div>
                                <div className='border_img'>
                                    {journalpublic?.slice(0, jrcount)?.map((value, index) => {
                                        return (
                                            <div key={index} style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingTop: "20px" }}>
                                                <div className='pl-2 pr-2' style={{ width: "85%" }}>
                                                    {(value?.accomplishmentCategory === "Original_Research_Article") ? <p className='article_description'> Original Research Article </p> :
                                                        (value?.accomplishmentCategory === "Review_Article") ? <p className='article_description'> Review Article </p> :
                                                            (value?.accomplishmentCategory === "Evidence_Review") ? <p className='article_description'> Evidence_Review </p> :
                                                                (value?.accomplishmentCategory === "Case_Study_or_Series") ? <p className='article_description'> Case Study/Case Series </p> :
                                                                    (value?.accomplishmentCategory === "Patient_Education") ? <p className='article_description'> Patient Education </p> :
                                                                        (value?.accomplishmentCategory === "Patent") ? <p className='article_description'> Patent </p> : ""
                                                    }
                                                    <p className='art_head'>
                                                        {value.title ? value.title : ""}
                                                    </p>
                                                    <p className='article_description'>
                                                        {value.organisation.displayName}
                                                    </p>
                                                    {value?.coAuthors ?
                                                        <div style={{ paddingTop: "8px" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} /> : ""
                                                    }
                                                    <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "8px" }}>
                                                        {value.link ?
                                                            <a href={value?.link} target="_blank">
                                                                <p className='publication_dop'>DOI:<span className='link_publication'>{value?.link}</span></p>
                                                            </a> : ""
                                                        }

                                                        <p className='publication_dop'>DOP:<span className='publication_date'>{moment(value.date).format('DD-MMM-yyyy')}</span></p>
                                                    </div>
                                                </div>
                                                {/* {console.log(value.attachment)} */}
                                                <div className='space_evenly_center' style={{ width: "15%" }}>
                                                    {value.attachment ?
                                                        <div onClick={() => { downloadfiles(value) }}>
                                                            {/* <a href={value.attachment} download={value.attachment} target="_blank"> */}
                                                            <img src={Downloadicon} alt="downloadicon" width="15px" height="15px" className='image_download'></img>
                                                            <Button className='download_journal'>Download</Button>
                                                            {/* </a> */}
                                                        </div>
                                                        : ""
                                                    }
                                                    <div>
                                                        <a href={value.link} target="_blank">
                                                            <img src={Linksource} alt="readicon" width="15px" height="15px" className='image_download'></img>
                                                            <Button className='read_journal'>Read</Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="sidebar__substat ta__center">
                                            {journalpublic !== null && journalpublic?.length > 3 ? jrcount === 3 ? <p id="border_exp_btn" onClick={() => setJrcount(journalpublic?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{journalpublic?.length - 3} More Article{(journalpublic?.length - 3) > 1 ? 's' : ''}&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setJrcount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='mt-15'>
                                    <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                        <p style={{ color: "#257FC4", fontSize: "24px" }}>Patient Education Articles</p>
                                        <IconButton aria-label="visible">
                                            <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                        </IconButton>
                                    </div>
                                    <div style={{ height: "200px" }}>

                                    </div>
                                    </div> */}
                                {/* <div>
                                        <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                            <p style={{ color: "#257FC4", fontSize: "24px" }}>Evidence Review</p>
                                            <IconButton aria-label="visible">
                                                <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                            </IconButton>
                                        </div>
                                        <div style={{ height: "100px" }}>

                                        </div>
                                        </div> */}
                            </Grid>
                        </Grid>
                    }
                </div> : ""
            }
            {
                maxWidth ?
                    <div>
                        {(UserService.getToken() !== undefined) ?
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <div className='border_img sidebar__padding'>
                                        <div className='react_swipable_view_960'>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p className='gallery_text'>Media Gallery</p>
                                            </div>
                                            {media?.length > 0 ?
                                                <div>
                                                    {media?.slice(0, count)?.map((value, index) => {
                                                        return (
                                                            <div key={index} className="mt-15 border_img">
                                                                {value?.attachment ?
                                                                    <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                                }
                                                                <div className='ppub' >
                                                                    <div className='publication_text' style={{ width: "100%" }}>
                                                                        <div style={{ width: "80%" }}>
                                                                            {isReadMore ?
                                                                                <p>{value?.title?.slice(0, 30)}</p> : <p>{value?.title}</p>
                                                                            }
                                                                            {value?.title && value?.title > 30 ?
                                                                                <span onClick={toggleReadMore} style={{ color: "red" }} className="read-or-hide">
                                                                                    {isReadMore ? "readmore" : "showless"}
                                                                                </span> : ""
                                                                            }
                                                                        </div>
                                                                        <div style={{ width: "20%", textAlign: "center" }}>
                                                                            <p>DOP : {moment(value?.date).format('DD-MMM-yyyy')} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='source_text'>
                                                                        {value?.link?.length > 80 ?
                                                                            <a href={value?.link} target="_blank">
                                                                                <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link?.slice(0, 80)}... </p>
                                                                            </a> : <a href={value?.link} target="_blank">
                                                                                <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link}</p>
                                                                            </a>
                                                                        }
                                                                        {value?.description?.length > 80 ?
                                                                            <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                        <div className="sidebar__substat ta__center">
                                                            {media !== null && media?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(media?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ padding: "15px" }}>
                                                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                                </div>
                                            }
                                        </div>
                                        <div className='react_swipable_view_769'>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p className='gallery_text'>Media Gallery</p>
                                            </div>
                                            {media?.length > 0 ?
                                                <Box sx={{ maxWidth: 400, flexGrow: 1 }} style={{ margin: '0 auto' }}>
                                                    <SwipeableViews
                                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                        index={activeStep}
                                                        onChangeIndex={handleStepChange}
                                                        enableMouseEvents
                                                    >
                                                        {media?.map((value, index) => {
                                                            return (
                                                                <div key={index} className="mt-15 border_img">
                                                                    {value?.attachment ?
                                                                        <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                                    }
                                                                    <div className='ppub' >
                                                                        <div className='publication_text' style={{ width: "100%" }}>
                                                                            <div style={{ width: "80%" }}>
                                                                                {value?.title?.length > 80 ?
                                                                                    <p>{value?.title?.slice(0, 80)}...</p> : <p>{value?.title}</p>
                                                                                }
                                                                            </div>
                                                                            <div style={{ width: "20%", textAlign: "center" }}>
                                                                                <p>DOP : {moment(value?.date).format('DD-MMM-yyyy')} </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='source_text'>
                                                                            {value?.link?.length > 80 ?
                                                                                <a href={value?.link} target="_blank">
                                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link?.slice(0, 80)}... </p>
                                                                                </a> : <a href={value?.link} target="_blank">
                                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link}</p>
                                                                                </a>
                                                                            }
                                                                            {value?.description?.length > 80 ?
                                                                                <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </SwipeableViews>

                                                    <MobileStepper
                                                        style={{ backgroundColor: 'transparent' }}
                                                        steps={maxSteps}
                                                        position="static"
                                                        activeStep={activeStep}
                                                        className="mobile_swipper"
                                                        nextButton={
                                                            <Button
                                                                size="large"
                                                                onClick={handleNext}
                                                                disabled={activeStep === maxSteps - 1}
                                                            >

                                                                {theme.direction === 'rtl' ? (
                                                                    <KeyboardArrowLeft />
                                                                ) : (
                                                                    <KeyboardArrowRight />
                                                                )}
                                                            </Button>
                                                        }
                                                        backButton={
                                                            <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                                                                {theme.direction === 'rtl' ? (
                                                                    <KeyboardArrowRight />
                                                                ) : (
                                                                    <KeyboardArrowLeft />
                                                                )}

                                                            </Button>
                                                        }
                                                    />
                                                </Box>
                                                :
                                                <div style={{ padding: "15px" }}>
                                                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} style={{ marginTop: "10px", marginBlockEnd: "75px" }}>
                                    <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <p className='journal_publication_text'>Journal Publications</p>
                                    </div>
                                    {journal?.length > 0 ?
                                        <div className='border_img'>
                                            {journal?.slice(0, jrcount)?.map((value, index) => {
                                                return (
                                                    <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "10px" }}>
                                                        <div style={{ width: "65%" }}>
                                                            {(value?.accomplishmentCategory === "Original_Research_Article") ? <p className='article_description'> Original Research Article </p> :
                                                                (value?.accomplishmentCategory === "Review_Article") ? <p className='article_description'> Review Article </p> :
                                                                    (value?.accomplishmentCategory === "Evidence_Review") ? <p className='article_description'> Evidence_Review </p> :
                                                                        (value?.accomplishmentCategory === "Case_Study_or_Series") ? <p className='article_description'> Case Study/Case Series </p> :
                                                                            (value?.accomplishmentCategory === "Patient_Education") ? <p className='article_description'> Patient Education </p> :
                                                                                (value?.accomplishmentCategory === "Patent") ? <p className='article_description'> Patent </p> : ""
                                                            }
                                                            <p className='art_head'>
                                                                {value.title ? value.title : ""}
                                                            </p>
                                                            <p className='article_description'>
                                                                {value?.organisation?.displayName}
                                                            </p>
                                                            {value?.coAuthors ?
                                                                <div style={{ paddingTop: "8px" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} /> : ""
                                                            }
                                                            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "8px" }}>
                                                                {value.link ?
                                                                    <a href={value.link} target="_blank">
                                                                        <p className='publication_dop'>DOI:<span className='link_publication'>{value?.link}</span></p>
                                                                    </a> : ""
                                                                }
                                                            </div>
                                                            <div className='sidebar__pt'>
                                                                {value.date ?
                                                                    <p className='publication_dop'>DOP:<span className='publication_date'>{moment(value.date).format('DD-MMM-yyyy')}</span></p> : ""
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* {console.log(value.attachment)} */}
                                                        <div className='space_evenly_center' style={{ width: "35%" }}>
                                                            {value.attachment ?
                                                                <a href={value.attachment} target="_blank" download>
                                                                    <img src={Downloadicon} alt="downloadicon" width="15px" height="15px" className='image_download'></img>
                                                                    <Button className='download_journal'>Download</Button>
                                                                </a> : ""
                                                            }
                                                            <div>
                                                                <a href={value.link} target="_blank">
                                                                    <img src={Linksource} alt="readicon" width="15px" height="15px" className='image_download'></img>
                                                                    <Button className='read_journal'>Read</Button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                <div className="sidebar__substat ta__center">
                                                    {journal !== null && journal?.length > 3 ? jrcount === 3 ? <p id="border_exp_btn" onClick={() => setJrcount(journal?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{journal?.length - 3} More Article{(journal?.length - 3) > 1 ? 's' : ''}&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setJrcount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='border_img' style={{ padding: "15px" }}>
                                            <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                        </div>
                                    }
                                    <div className='mt-15'>
                                        <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                            <p style={{ color: "#257FC4", fontSize: "18px", padding: "8px" }}>Patient Education Articles</p>
                                        </div>
                                        {Patientdata?.length > 0 ?
                                            <div className='border_img'>
                                                {Patientdata?.slice(0, patientcount)?.map((value, index) => {
                                                    return (
                                                        <div style={{ padding: "15px" }}>
                                                            <div>
                                                                {value?.articleTitle ?

                                                                    <p className='article_title' onClick={() => handlearticle(value)}>{value?.articleTitle}</p> : ""
                                                                }
                                                            </div>
                                                            <div>
                                                                {value?.coAuthors ?
                                                                    <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value?.coAuthors }} ></p> : ""
                                                                }
                                                                {value?.contributors ?
                                                                    <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value?.contributors }} ></p> : ""
                                                                }
                                                                {value?.organisation ?
                                                                    <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value?.organisation }} ></p> : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                    <div className="sidebar__substat ta__center">
                                                        {Patientdata !== null && Patientdata?.length > 5 ? patientcount === 5 ? <p id="border_exp_btn" onClick={() => setPatientcount(Patientdata?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setPatientcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='border_img' style={{ padding: "15px" }}>
                                                <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                            </div>
                                        }
                                    </div>
                                    {/* <div>
                                        <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                            <p style={{ color: "#257FC4", fontSize: "24px" }}>Evidence Review</p>
                                            <IconButton aria-label="visible">
                                                <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                            </IconButton>
                                        </div>
                                        <div style={{ height: "100px" }}>

                                        </div>
                                    </div> */}
                                </Grid>
                            </Grid>
                            :
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <div className='border_img sidebar__padding'>
                                        <div className='react_swipable_view_960'>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p className='gallery_text'>Media Gallery</p>
                                            </div>
                                            {mediapublic?.length > 0 ?
                                                <div>
                                                    {mediapublic?.slice(0, count)?.map((value, index) => {
                                                        return (
                                                            <div key={index} className="mt-15 border_img">
                                                                {value?.attachment ?
                                                                    <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                                }
                                                                <div className='ppub' >
                                                                    <div className='publication_text' style={{ width: "100%" }}>
                                                                        <div style={{ width: "80%" }}>
                                                                            {isReadMore ?
                                                                                <p>{value?.title?.slice(0, 30)}</p> : <p>{value?.title}</p>
                                                                            }
                                                                            {value?.title && value?.title > 30 ?
                                                                                <span onClick={toggleReadMore} style={{ color: "red" }} className="read-or-hide">
                                                                                    {isReadMore ? "readmore" : "showless"}
                                                                                </span> : ""
                                                                            }
                                                                        </div>
                                                                        <div style={{ width: "20%", textAlign: "center" }}>
                                                                            <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='source_text'>
                                                                        {value?.link?.length > 80 ?
                                                                            <a href={value?.link} target="_blank">
                                                                                <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link?.slice(0, 80)}... </p>
                                                                            </a> : <a href={value?.link} target="_blank">
                                                                                <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link}</p>
                                                                            </a>
                                                                        }
                                                                        {value?.description?.length > 80 ?
                                                                            <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                        <div className="sidebar__substat ta__center">
                                                            {mediapublic !== null && mediapublic?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(mediapublic?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ padding: "15px" }}>
                                                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                                </div>
                                            }
                                        </div>
                                        <div className='react_swipable_view_769'>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p className='gallery_text'>Media Gallery</p>
                                            </div>
                                            {/* <Carousel
                                                        ssr={false}
                                                        ref={el => (this.Carousel = el)}
                                                        partialVisbile={false}
                                                        customButtonGroup={<CustomSlider />}
                                                        itemClass="slider-image-item"
                                                        // responsive={responsive}
                                                        containerClass="carousel-container-with-scrollbar"
                                                        additionalTransfrom={state.additionalTransfrom}
                                                        beforeChange={nextSlide => {
                                                            if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
                                                                this.setState({ additionalTransfrom: 150 });
                                                            }
                                                            if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
                                                                this.setState({ additionalTransfrom: 0 });
                                                            }
                                                        }}
                                                    >
                                                        {media?.map((value, index) => {
                                                            return (
                                                                <div key={index} className="mt-15 border_img">
                                                                    <img src={value.attachment} className="media_metaimage"></img>
                                                                    <div className='ppub' >
                                                                        <div className='publication_text' style={{ width: "100%" }}>
                                                                            <div style={{ width: "80%" }}>
                                                                                {value?.title?.length > 80 ?
                                                                                    <p>{value.title.slice(0, 80)}...</p> : <p>{value.title}</p>
                                                                                }
                                                                            </div>
                                                                            <div style={{ width: "20%", textAlign: "center" }}>
                                                                                <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='source_text'>
                                                                            <p style={{ paddingTop: "5px" }}>{value.link} </p>
                                                                            {value?.description?.length > 80 ?
                                                                                <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </Carousel> */}
                                            {mediapublic?.length > 0 ?
                                                <Box sx={{ maxWidth: 400, flexGrow: 1 }} style={{ margin: '0 auto' }}>
                                                    <SwipeableViews
                                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                        index={activeStep}
                                                        onChangeIndex={handleStepChange}
                                                        enableMouseEvents
                                                    >
                                                        {mediapublic?.map((value, index) => {
                                                            return (
                                                                <div key={index} className="mt-15 border_img">
                                                                    {value?.attachment ?
                                                                        <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                                    }
                                                                    <div className='ppub' >
                                                                        <div className='publication_text' style={{ width: "100%" }}>
                                                                            <div style={{ width: "80%" }}>
                                                                                {value?.title?.length > 80 ?
                                                                                    <p>{value?.title?.slice(0, 80)}...</p> : <p>{value.title}</p>
                                                                                }
                                                                            </div>
                                                                            <div style={{ width: "20%", textAlign: "center" }}>
                                                                                <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='source_text'>
                                                                            {value?.link?.length > 80 ?
                                                                                <a href={value?.link} target="_blank">
                                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link?.slice(0, 80)}... </p>
                                                                                </a> : <a href={value.link} target="_blank">
                                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value?.link}</p>
                                                                                </a>
                                                                            }
                                                                            {value?.description?.length > 80 ?
                                                                                <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </SwipeableViews>
                                                    <MobileStepper
                                                        style={{ backgroundColor: 'transparent' }}
                                                        steps={maxSteps}
                                                        position="static"
                                                        activeStep={activeStep}
                                                        className="mobile_swipper"
                                                        nextButton={
                                                            <Button
                                                                size="large"
                                                                onClick={handleNext}
                                                                disabled={activeStep === maxSteps - 1}
                                                            >

                                                                {theme.direction === 'rtl' ? (
                                                                    <KeyboardArrowLeft />
                                                                ) : (
                                                                    <KeyboardArrowRight />
                                                                )}
                                                            </Button>
                                                        }
                                                        backButton={
                                                            <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                                                                {theme.direction === 'rtl' ? (
                                                                    <KeyboardArrowRight />
                                                                ) : (
                                                                    <KeyboardArrowLeft />
                                                                )}

                                                            </Button>
                                                        }
                                                    />
                                                </Box>
                                                :
                                                <div style={{ padding: "15px" }}>
                                                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <p className='journal_publication_text'>Journal Publications</p>
                                    </div>
                                    <div className='border_img'>
                                        {journalpublic?.length > 0 ?
                                            <div>
                                                {journalpublic?.slice(0, jrcount)?.map((value, index) => {
                                                    return (
                                                        <div key={index} style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingTop: "20px" }}>
                                                            <div className='pl-2 pr-2' style={{ width: "85%" }}>
                                                                {(value?.accomplishmentCategory === "Original_Research_Article") ? <p className='article_description'> Original Research Article </p> :
                                                                    (value?.accomplishmentCategory === "Review_Article") ? <p className='article_description'> Review Article </p> :
                                                                        (value?.accomplishmentCategory === "Evidence_Review") ? <p className='article_description'> Evidence_Review </p> :
                                                                            (value?.accomplishmentCategory === "Case_Study_or_Series") ? <p className='article_description'> Case Study/Case Series </p> :
                                                                                (value?.accomplishmentCategory === "Patient_Education") ? <p className='article_description'> Patient Education </p> :
                                                                                    (value?.accomplishmentCategory === "Patent") ? <p className='article_description'> Patent </p> : ""
                                                                }
                                                                <p className='art_head'>
                                                                    {value?.title ? value?.title : ""}
                                                                </p>
                                                                <p className='article_description'>
                                                                    {value?.organisation?.displayName}
                                                                </p>
                                                                {value?.coAuthors ?
                                                                    <div style={{ paddingTop: "8px" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} /> : ""
                                                                }
                                                                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "8px" }}>
                                                                    {value.link ?
                                                                        <a href={value?.link} target="_blank">
                                                                            <p className='publication_dop'>DOI:<span className='link_publication'>{value?.link}</span></p>
                                                                        </a> : ""
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {value.date ?
                                                                        <p className='publication_dop'>DOP:<span className='publication_date'>{moment(value.date).format('DD-MMM-yyyy')}</span></p> : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* {console.log(value.attachment)} */}
                                                            <div className='space_evenly_center' style={{ width: "15%" }}>
                                                                {value.attachment ?
                                                                    <div>
                                                                        {/* <a href={value.attachment} download={value.attachment} target="_blank"> */}
                                                                        <img src={Downloadicon} alt="downloadicon" width="15px" height="15px" className='image_download'></img>
                                                                        <Button className='download_journal'>Download</Button>
                                                                        {/* </a> */}
                                                                    </div>
                                                                    : ""
                                                                }
                                                                <div>
                                                                    <a href={value.link} target="_blank">
                                                                        <img src={Linksource} alt="readicon" width="15px" height="15px" className='image_download'></img>
                                                                        <Button className='read_journal'>Read</Button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                                    <div className="sidebar__substat ta__center">
                                                        {journalpublic !== null && journalpublic?.length > 3 ? jrcount === 3 ? <p id="border_exp_btn" onClick={() => setJrcount(journalpublic?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{journalpublic.length - 3} More Article{(journalpublic.length - 3) > 1 ? 's' : ''}&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setJrcount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ padding: "15px" }}>
                                                <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                            </div>
                                        }
                                    </div>
                                    {/* <div className='mt-15'>
                                    <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                        <p style={{ color: "#257FC4", fontSize: "24px" }}>Patient Education Articles</p>
                                        <IconButton aria-label="visible">
                                            <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                        </IconButton>
                                    </div>
                                    <div style={{ height: "200px" }}>

                                    </div>
                                    </div> */}
                                    {/* <div>
                                        <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                            <p style={{ color: "#257FC4", fontSize: "24px" }}>Evidence Review</p>
                                            <IconButton aria-label="visible">
                                                <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                            </IconButton>
                                        </div>
                                        <div style={{ height: "100px" }}>

                                        </div>
                                        </div> */}
                                </Grid>
                            </Grid>
                        }
                    </div> : ""
            }
        </div >
    )
}

export default Publicationprofile