import React from 'react'
import './Article.css'
import '../feedposts/poll/poll.css'
import '../../Molecule/Feed/Feed.css'
import { Button } from '@mui/material'
import Preview from '../../Article/createarticle'
function ArticlePage({ data, handleChange }) {
    // const [data,setData] = React.useState({coAuthors: '',peers: '',contributors: '',organisations:'',tags:''})
    const [title, setTitle] = React.useState('')
    const [open, setOpen] = React.useState(false)
    // const handleChange = (name,value) => {
    //     setData({...data,[name]: value})
    // }
    {/*Sending Photo */ }
    const [url, setUrl] = React.useState('')
    const uploadFile = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files?.[0]);

        reader.onloadend = () => {
            setUrl(reader.result)
            setOpen(true)
        }
        if (e.target.files?.[0]) {
            setUrl(reader.result)

        }
        setOpen(true)
    }

    return (
        <div className="authors ">
            {/* <div className="co_authors">
                    <h5>co-authors</h5>
                    <p>Mentioned @</p>
            </div>

            <div className="contributors">
                    <div className="article_contributors co_authors h5 co_authors p">
                            <h5>contributors</h5>
                            <p>Mentioned @</p>
                    </div>
                    <div className="article_organisation contributors co_authors h5 co_authors p">
                        <h5>organisations</h5>
                        <p>Mentioned @</p>
                     </div>
            </div> 

            <div className=" tag">
            <input type="text" placeholder="tags#" />

            </div> */}
            <div className="a-tags" style={{ borderRadius: '8px', padding: '10px', marginTop: '10px' }}>

                <ul className="w-100" style={{ listStyle: 'unstyled' }}>
                    <li>Co-authors <input type="text" value={data.coAuthors} onChange={(e) => handleChange('coAuthors', e.target.value)} className="form-input" placeholder="Mention @" /></li>
                    <li>Contributors <input type="text" value={data.contributors} onChange={(e) => handleChange('contributors', e.target.value)} className="form-input" placeholder="Mention @" /></li>
                    <li>Organisations <input type="text" value={data.organisations} onChange={(e) => handleChange('organisations', e.target.value)} className="form-input" placeholder="Mention @organisation department" /></li>

                </ul>
            </div>
            <div>
                <input type="text" className="form-input w-100" placeholder="#tags" value={data.tags} onChange={(e) => handleChange('tags', e.target.value)} />

            </div>
            <div className="entered__details">
                <div className="article__title"> <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title of the article" /></div>

                <div className="authorsandcoauthors publishing__details authorsandcoauthors span">
                    <span>Author and Co-author</span>
                    <input type="text" placeholder="Source" />
                </div>
                <div className="publishing__details authorsandcoauthors span  ">
                    <span>Published Date</span>
                    <input type="text" placeholder="url" />
                </div>
            </div>

            <div className="butons">

                <Button style={{ textTransform: 'capitalize' }}>
                    <input type="file" name="uploadfile" id="img" style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
                    <label htmlFor="img" style={{ color: 'blue', cursor: 'pointer' }}  >Add Cover Photo</label>
                </Button>

                <Button style={{ textTransform: 'capitalize' }}>
                    <input type="file" name="uploadfile" id="pdf" style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
                    <label htmlFor="pdf" style={{ color: 'blue', cursor: 'pointer' }}>Add Full Article</label>
                </Button>
                {
                    <Preview data={data} url={url} open={open} title={title} close={() => setOpen(false)} />
                }

                {/* <Button href="#" htmlFor="img" color="primary" style={{textTransform:'capitalize'}}>Add Cover Photo</Button>
                <Button href="#" color="primary" style={{textTransform:'capitalize'}}>Attach Full Article</Button> */}
            </div>

        </div>
    )
}

export default ArticlePage
