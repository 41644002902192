import React from 'react';
import "../../utility/style.css";
import './InputOption.css';
export default function InputOption({ src, alt, title, color, path, handleClick }) {
    return (
        // <a href={path} className='celebrate'>
        //     <div className="inputOption" onClick={handleClick}>
        //         <img src={src} alt={alt} />
        //         <div className="input__title1">{title}</div>
        //     </div>
        // </a>
        <div className="d-flex e-align inputOption post_border" onClick={handleClick}>
            <img src={src} alt={alt} width="20px" height="20px" className="celebrate"/>
            <p className="post_options">{title}</p>
        </div>
    )
}
