import React from 'react'
import peersicon from '../Atom/modifiedicons/peers.svg';
import team_icon from '../Atom/modifiedicons/team_icon.svg'
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

const MIcon = ({ src, num }) => {
    return (
        <span className="cursor">{num} <img className="cursor" alt="icon" src={src} /></span>
    )
}

const ListUsers = ({ connections, children }) => {


    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <span
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onMouseEnter={handleToggle}
                onMouseLeave={handleClose}
            >
                {/* <MIcon num={connections.mutual.length} src={peers} /></span> */}
                {children}</span>
            {connections?.length > 0 ? <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{ zIndex: 1 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            zIndex: 1,
                            textAlign: 'left'
                        }}
                    >
                        <Paper sx={{ borderRadius: '3px!important', backgroundColor: 'black', p: 0.5, zIndex: 1 }}>
                            {/* {connections.map((user, i) => (<Typography sx={{ backgroundColor: 'black', color: 'white', fontSize: '12px!important', zIndex: 1 }} key={i}> {user.name || user}</Typography>))} */}

                            {connections?.length > 4 ?
                                <>
                                    {
                                        connections?.slice(0, 4)?.map((user, i) => (
                                            <Typography sx={{ backgroundColor: 'black', color: 'white', fontSize: '12px!important', zIndex: 1 }} key={i}> {user.name || user}</Typography>
                                        ))
                                    }
                                    <Typography sx={{ backgroundColor: 'black', color: 'white', fontSize: '12px!important', zIndex: 1, textTransform: "lowercase" }} >...more</Typography>

                                </>
                                :
                                connections?.map((user, i) => (<Typography sx={{ backgroundColor: 'black', color: 'white', fontSize: '12px!important', zIndex: 1 }} key={i}> {user.name || user}</Typography>))
                            }
                        </Paper>
                    </Grow>
                )}
            </Popper> : ''}
        </>
    )
}

export default function Mutual({ total, peers }) {


    return (

        <div style={{ display: 'flex', justifyContent: 'space-around', zIndex: 1 }}>
            {total?.length > 0 || peers?.length > 0 ?

                (
                    <>
                        {total?.length > 0 ? <div>Total&nbsp;
                            <ListUsers connections={total ? total : []}>
                                <MIcon num={total?.length} src={team_icon} />
                            </ListUsers></div> : ''}
                        {peers?.length ? <div>&nbsp;Peers&nbsp;

                            <ListUsers connections={peers}>
                                <MIcon num={peers?.length} src={peersicon} />
                            </ListUsers>

                        </div> : ''}
                        {/* {connections.mutual.length > 0 ? <p>Peers {connections.mutual.length} <MIcon src={peers} /></p> : ''} */}
                    </>
                )

                : 'No Mutuals'}
        </div>



    );
}
