import UserService from "../../services/UserService";

export default function UserInfo({ userData, card, Peer, children, type }) {

    const handleSession = () => {
        // console.log("----------------")
        sessionStorage.removeItem("admin-active")
        sessionStorage.removeItem("org-active")
        sessionStorage.removeItem("public-active")
    }
    return (

        <ul className={`${card} list-style capitalize`} >
            {userData.requestData ?
                <li className="font-bold card-pad font user-data pad name_hover" onClick={() => handleSession()}><a href={`/profile/${userData?.name?.toLowerCase()?.replace(/ /g, '')}-${userData?.requestData?.userId === UserService?.getUserId() ? userData?.requestData?.requestedUser : userData?.requestData?.userId}`}>{userData?.name}</a></li>
                : <li className="font-bold card-pad font user-data pad name_hover" onClick={() => handleSession()}><a href={`${type === "org-bg" || type === 'org-bg2' ? `/organization/${userData?.id}` : `/profile/${userData?.name ? userData?.name?.toLowerCase()?.replace(/ /g, '') : ''}-${userData?.userId ? userData?.userId : userData?.id || userData?.requestData?.id}`}`}>{userData?.name}</a></li>}


            <li className="card-pad cap user-data pad">{userData?.captionAndTagLine || userData?.captionAndTagline || userData?.captionsAndTaglines || userData?.captionAndTaglines || `Hey! I'm using Docsteth`}</li>

            <li className="card-pad font pad">
                {Peer}

            </li>
            <li className='card-pad'>
                {children}
            </li>
        </ul>



    );
}
