const initialState = {
  hashtags: [],
  peersData: [],
  mentions: [],
  allApps: [],
  requests: [],
  count: {},
  people: [],
  linkedAccounts: [],
  linkedAccountsOrg: [],
  suggestedApps: [],
  appsbyuserId: []
};

const connectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HASHTAGS':
      return {
        ...state,
        hashtags: action.payload
      }
    case 'REQUESTS':
      return {
        ...state,
        requests: action.payload
      }
    case 'PEERSDATA':
      return {
        ...state,
        peersData: action.payload
      }
    case 'MENTIONS':
      return {
        ...state,
        mentions: action.payload
      }
    case 'ALL_APPS':
      return {
        ...state,
        allApps: action.payload
      }
    case 'COUNT':
      return {
        ...state,
        count: action.payload
      }
    case 'PEOPLE':
      return {
        ...state,
        people: action.payload
      }
    case 'LINKED_ACCOUNTS':
      return {
        ...state,
        linkedAccounts: action.payload
      }
    case 'LINKED_ACCOUNTS_ORG':
      return {
        ...state,
        linkedAccountsOrg: action.payload
      }
    case 'SUGGESTED_APPS':
      return {
        ...state,
        suggestedApps: action.payload
      }
    case 'APPS_BY_USER_ID':
      return {
        ...state,
        appsbyuserId: action.payload
      }
    default:
      return state
  }
}

export default connectReducer