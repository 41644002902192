import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Dropdown from "../../Atom/dropdown/dropdown";
import HeaderOption from "../../Molecule/Header/HeaderOption";
import AppsIcon from "../../Atom/img/Group 11819.png";
import { ReactComponent as posts } from "../../Atom/svg/post.svg";
import { ReactComponent as peers } from "../../Atom/svg/peers.svg";
import { ReactComponent as xepedia } from "../../Atom/svg/xepedia.svg";
import { ReactComponent as market } from "../../Atom/svg/market.svg";
import { Link, useHistory } from 'react-router-dom'
import Explore from '../../../images/explore3.svg'
import Peers from '../../../images/peers.svg'
import Elib from '../../../images/e-library.svg'
import Medscope from '../../../images/medscope.svg'
import msg from '../../../images/msgicon.svg'
import SearchBox from './search'
import { Button, createTheme, ThemeProvider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { handleActiveValue } from '../../../redux/Actions/actions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getChatAppOpen } from '../../../redux/Actions/actions';
// import ConversationsApp from '../../ChatApp/ConversationsApp';
import Notifications from '../../Atom/dropdown/notifications';
import Drawer from '@mui/material/Drawer';
import { ClickAwayListener } from '@mui/material';
import axios from 'axios';
import { getAllApps, getAppsByUserId, getSuggestedApps } from '../../../redux/Actions/connectActions';
import '../../Layout/MyProfile/myprofile.css'
import URL from '../../apiurl';
import { useLocation } from "react-router-dom";
import { headers } from '../../../redux/Actions/connectActions';
import appvault_logo from '../../../images/appvault.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { BootstrapTooltip } from '../Post/commonFun';
import './Header.css'
import SearchApps from '../MarketPlacePage/AppVault/search';
import { AppsView } from '../MarketPlacePage/AppVault/appview';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1536,
        },
    },
});

const T_APIS = {
    'de71b7e4-6610-47bd-9dd1-1b1886d9abc9': URL.linkedInToken,
    'da8e1439-fb1a-447e-b481-cb5e5028e521': URL.twitterToken
}

const P_APIS = {
    'de71b7e4-6610-47bd-9dd1-1b1886d9abc9': URL.linkedInPost,
    'da8e1439-fb1a-447e-b481-cb5e5028e521': URL.twitterPost
}


const DrawerApp = (props) => {

    const { open, toggleDrawer } = props

    const [apps, setApps] = useState([])
    // const [suggestedApps, setSuggestedApps] = useState([])

    // const allApps = useSelector(state => state.connect.allApps)
    // const suggApps = useSelector(state => state.connect.suggestedApps)

    const appsbyuserId = useSelector(state => state.connect.appsbyuserId)

    console.log(appsbyuserId, "appsbyuserId")
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (appsbyuserId.length === 0) {
            dispatch(getAppsByUserId())
        }
    }, [appsbyuserId])

    useEffect(() => {
        setApps(appsbyuserId)
    }, [appsbyuserId])



    return (
        <ClickAwayListener onClickAway={toggleDrawer}>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer}
                // variant="persistent"
                sx={{
                    '& .MuiDrawer-paper': {
                        top: '70px!important',
                        right: '0px!important',
                        height: '92.5%',
                        overflow: 'scroll'
                    },
                    '& .MuiBackdrop-root': {
                        opacity: '0.7!important',
                        backgroundColor: 'white!important',
                        top: '60px!important'
                    },
                    position: 'absolute',
                    top: '60px'
                }}>
                <Box
                    sx={{ width: 350, p: 2 }}
                    role="presentation"
                >
                    <Typography className='dflex'>
                        <Button onClick={() => { history.push('/marketplace/appvault'); toggleDrawer() }} className="capitalize textAlign appvault-btn">
                            <ArrowBackIosIcon />
                            <img src={appvault_logo} alt="logo" style={{ fontSize: '10px' }} />
                            <Typography sx={{ ml: 1 }}>
                                <p>AppVault</p>
                                <p style={{ fontSize: '13px' }}>Find apps for your practice</p>
                            </Typography>
                        </Button>
                        <p style={{ flex: 0.3 }} className="text-right">
                            <BootstrapTooltip title="Close">
                                <HighlightOffOutlinedIcon style={{ color: 'red' }} className='cursor' onClick={toggleDrawer} />
                            </BootstrapTooltip>
                        </p>
                    </Typography>
                    <AppsView heading="My Apps11" apps={appsbyuserId[0]?.myApps} Component={SearchApps} />
                    <AppsView heading="Suggested Apps" apps={appsbyuserId[0]?.suggestedApps} suggested toggleDrawer={toggleDrawer} />
                    {/* <AppInfo img={appvault} title='AppVault' description='Marketplace for more Docsteth Applications'/> */}
                </Box>
            </Drawer>
        </ClickAwayListener>
    )
}

export default function PrimarySearchAppBar({ handlemessageopen }) {
    // console.log(addNameByMessageClick)
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    let value = useSelector(state => state.userProfile.active)
    let item = value
    const [active, setActive] = useState(parseInt(item))
    const [open, setOpen] = useState(false)

    const matches = useMediaQuery('(max-width:960px)');
    const minScreen = useMediaQuery('(min-width:960px)');

    const history = useHistory()
    let location = useLocation().search

    const params = new URLSearchParams(location)

    const loc = location.includes('?')

    useEffect(() => {
        setActive(value)
    }, [value])

    useEffect(() => {

        if (loc) {

            let state = params.get("state").split("_")
            let data = {
                code: params.get("code"),
                state: state[0],
                redirect_uri: process.env.REACT_APP_DOCSTETH,
            }

            const id = state?.[1]?.split("-")?.[1]

            // console.log(id,data)

            if (state?.[1]?.startsWith('post') || state?.[1]?.startsWith('event')) {
                axios.post(P_APIS[data?.state],
                    {
                        ...data,
                        text: state?.[1]?.startsWith('event') ? `${process.env.REACT_APP_WEBINAR}/webinar/eventdata-${id}` : `${process.env.REACT_APP_SHARE_POST}/${id}`,
                        description: ""
                        // shareMediaCategory: "ARTICLE"
                    }, headers())
                    .then(res => {
                        // console.log(res)
                        alert("Post shared successfully")
                        if (state?.[1]?.startsWith('event')) {
                            history.push(`/webinar/eventdata-${id}`)
                        }
                    })
                    .catch(err => {
                        alert("Failed sharing post")
                        if (state?.[1]?.startsWith('event')) {
                            history.push(`/webinar/eventdata-${id}`)
                        }
                    })
            }
        }
    }, [loc])

    const handleActive = (value) => {

        dispatch(handleActiveValue(value))
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{ display: { xs: 'block', md: 'none' } }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <HeaderOption Icon={posts} title="Explore" path='/' value="1" active={active} handleActive={handleActive} />
            <HeaderOption Icon={peers} title="Connect" path='/connect' value="2" active={active} handleActive={handleActive} />
            <HeaderOption Icon={xepedia} title="E-Practice" path='/elib' value="3" active={active} handleActive={handleActive} />
            <HeaderOption Icon={market} title="MedScope" path='/marketplace' value="4" active={active} handleActive={handleActive} />
            <MenuItem disableRipple className={`${active == 5 ? "active-item" : ''} headerOption`} onClick={() => handleActive(5)}>
                <Link to={''} className="path">
                    <div style={{ width: '46px', height: '32px', textAlign: 'center' }}>
                        <img src={AppsIcon} className="header__png" alt="Apps" width="32px" />   </div>
                    <p className="headerOption__title">Apps</p>
                </Link>
            </MenuItem>
            {/* <div className="header__icon" style={{paddingTop: '10px',marginLeft: '30px'}}>
            <img src={AppsIcon} className="header__png" alt="Apps" width="25px"/> Apps
        </div> */}
        </Menu>
    );

    return (
        <ThemeProvider theme={theme}>
            <Box className='border_img' sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" sx={{ backgroundColor: 'white', color: 'black' }}>
                    <Toolbar sx={{ width: { lg: '1280px' }, m: { lg: 'auto' }, p: { lg: "0px !important" } }}>
                        {minScreen ? <Typography component="div" sx={{ display: { md: 'block', xs: 'none' }, width: { lg: '315px', md: '25%' } }}>
                            <Dropdown />
                        </Typography> : ''}
                        <Typography
                            variant="h6"
                            // noWrap
                            component="div"
                            sx={{ display: { md: 'none', xs: 'flex' }, mr: '16px!important' }}
                        >
                            {/* MUI */}
                            {/* <img src={NotificationsNoneIcon} className="header-bell__png" alt="bell" /> */}
                            {matches ? <Notifications /> : ''}
                        </Typography>
                        <SearchBox />
                        {/* <Typography component="div" sx={{ display: { md: 'none', xs: 'flex' } }}> */}
                        {/* <img src={msg} alt="Message" onClick={() => dispatch(getChatAppOpen(true))} /> */}
                        {/* <ConversationsApp></ConversationsApp> */}
                        {/* </Typography> */}
                        {matches ? <Typography component="div" sx={{ display: { md: 'none', xs: 'block' } }}>
                            <Dropdown />
                        </Typography> : ''}
                        {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} /> */}
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {/* <div className="header__right"> */}
                            <HeaderOption Icon={Explore} title="Explore" path='/' value="1" active={active} handleActive={handleActive} />
                            <HeaderOption Icon={Peers} title="Connect" path='/connect' value="2" active={active} handleActive={handleActive} />
                            <HeaderOption Icon={Elib} title="E-Practice" path='/elib' value="3" active={active} handleActive={handleActive} />
                            <HeaderOption Icon={Medscope} title="MedScope" path='/marketplace' value="4" active={active} handleActive={handleActive} />
                            <div className="header__icon cursor" style={{ paddingTop: '10px', marginLeft: '15px' }}>
                                <img src={AppsIcon} className="header__png" alt="app" onClick={() => setOpen(true)} />
                            </div>
                            {/* <MenuItem disableRipple className={`${active == 5 ? "active-item" : ''} headerOption`} onClick={()=> {setOpen(true);handleActive(5)}}>
                                <div className="header__icon" style={{paddingTop: '7px'}}>
                                        <img src={AppsIcon} className="header__png" alt="Apps" />
                                </div>
                            </MenuItem> */}
                            {/* </div> */}
                            {open ? <div style={{ position: 'relative' }}><DrawerApp open={open} toggleDrawer={() => setOpen(false)} /></div> : ''}
                        </Box>
                        <Box sx={{ display: "none" }}>
                            <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <AppBar position="fixed" sx={{ backgroundColor: 'white', color: 'black', display: { sm: 'flex', md: 'none' }, bottom: 0, top: 'auto' }}>
                    <Toolbar style={{ width: '100%', display: 'flex', paddingLeft: '0px' }}>
                        {/* <Box sx={{ flexGrow: 1 }} /> */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                            {/* <div className="header__right"> */}
                            <HeaderOption Icon={Explore} title="Explore" path='/' value="1" active={active} handleActive={handleActive} />
                            <HeaderOption Icon={Peers} title="Connect" path='/connect' value="2" active={active} handleActive={handleActive} />
                            <HeaderOption Icon={Elib} title="E-Practice" path='/elib' value="3" active={active} handleActive={handleActive} />
                            <HeaderOption Icon={Medscope} title="MedScope" path='/marketplace' value="4" active={active} handleActive={handleActive} />

                            {/* </div> */}

                        </Box>
                        {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box> */}
                    </Toolbar>
                </AppBar>
                <Toolbar />
                {renderMobileMenu}
                {renderMenu}
            </Box>
        </ThemeProvider>
    );
}
