import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import "../../utility/style.css";
import ribbon from "../../Atom/svg/ribbon.svg";
import moment from 'moment'
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid'
import peers from '../../Atom/modifiedicons/peers.svg';
import followers from '../../Atom/modifiedicons/followers.svg'
import Publications from '../../Atom/modifiedicons/Publications.svg'
import Reviewed from '../../Atom/modifiedicons/Reviewed.svg'
import Bookmarks from '../../Atom/modifiedicons/Bookmarks.svg'
import Pages from '../../Atom/modifiedicons/Pages.svg'
import achevments from '../../Atom/modifiedicons/achevments.svg'
import certifications from '../../Atom/modifiedicons/certifications.svg'
import Organisation from '../../Atom/modifiedicons/Organisations.svg'
import { useHistory } from 'react-router-dom'
import Doc from '../../../images/Logo2.svg'
import Avatar from '@mui/material/Avatar'
import { Link } from '@mui/material';
import { Link as LinkTag } from 'react-router-dom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FiEdit3 } from 'react-icons/fi'
import CreatePost from '../Feed/createpost';
import axios from 'axios';
import URL from '../../apiurl';
import UserService from '../../../services/UserService';
import OrgLogo from '../../../images/orglogo.svg'
// import { fetchProfile, getMetaData } from "../../../../redux/Actions/actions";
import { fetchProfile, getMetaData } from "../../../redux/Actions/actions";

// import DialogBox from "../../../DialogBox/dialog";
import DialogBox from "../../DialogBox/dialog"
import { useDispatch } from "react-redux";
// import Add from "./expcrud";
// import { Add } from '../../../../expcrud';
import Add from '../../Layout/MyProfile/organisation/expcrud';



const CountComp = ({ img, name, count }) => {
  return (
    <div className="sidebar__stat ta__left d-flex butons">
      <div className="sidebar__substat ta__left d-flex">
        <img src={img} style={{ fontSize: "16px" }} />
        <div className="sidebar__title sidebar__ml ">{name}</div>
      </div>
      <div className="sidebar__statNumber">{count}</div>
    </div>
  )
}

function Sidebar(props) {
  const history = useHistory();
  const [data, setData] = useState([])
  const [exp, setExp] = useState([])
  const state = useSelector(state => state.userProfile.userProfile)
  const expe = useSelector(state => state.userProfile.exp)
  const myPages = useSelector(state => state.userProfile.pages);
  const user = useSelector(state => state.userProfile.user)
  const [post, setPost] = useState({})
  const [bookmarkCount, setBookmarkCount] = useState(null);
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    id: null,
    organisation: null,
    designation: "",
    city: "",
    fromDate: null,
    toDate: null,
    status: "",
  });


  useEffect(() => {
    setData(state)
    if (expe?.length > 0) {
      setExp(expe?.[0])
    }

  }, [state, expe])

  useEffect(() => {
    axios.get(`${URL.getbookmarkcount}`, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`
      }
    }).then(res => setBookmarkCount(res.data.BookmarkCount)).catch(err => console.log(err))

  }, [bookmarkCount])

  const editEvent = (eventpost) => {
    setPost(eventpost)
  }
  const addFun = () => {
    setOpen(true);
    setCheck(false);
    setDetails({ name: "", designation: "", toDate: null, fromDate: null });
  };
  const getData = () => {
    dispatch(fetchProfile(sessionStorage.getItem("userId")));
  };

  const handleAdd = async () => {
    let data = {
      designation: details.designation,
      fromDate: moment(details.fromDate).format("YYYY-MM-DD"),
      metadata: await getMetaData(),
      organisation: details.organisation,
      city: details.city,
      status: "SHOW",
      toDate: check ? "" : moment(details.toDate).format("YYYY-MM-DD"),
      userId: sessionStorage.getItem("userId"),
      unofficialOrganisation: details.unofficialOrganisation,
      unofficialOrganisationName: details.unofficialOrganisationName,
    };
    // console.log(data)
    axios
      .post(`${URL.addExperience}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("kctoken")}`,
        },
      })
      .then((res) => res.data)
      .then((resdata) => {
        // console.log(resdata)
        getData();
      })
      .catch((error) => { });
    setCheck(true);
    setOpen(false);
  };

  const handleCancel = () => {
    // setCheck(false)
    setOpen(false);
    setEdit(false);
  };
  const handleOrg = (name, value, newOrg, logo) => {
    // console.log(logo,name,value,newOrg)
    if (newOrg) {
      setDetails({
        ...details,
        organisationName: "",
        organisation: 0,
        unofficialOrganisation: value,
        unofficialOrganisationName: name,
      });
    } else {
      setDetails({
        ...details,
        organisationName: name,
        organisation: value,
        unofficialOrganisation: 0,
        unofficialOrganisationName: "",
        logo: logo,
      });
    }
  };

  const handleChange = (name, value) => {
    // console.log(name, value)
    if (name === "organisation") {
      setDetails({ ...details, [name]: value });
    } else {
      setDetails({ ...details, [name]: value });
    }
  };

  const handleUpdate = async (id) => {
    setOpen(false);
    setEdit(false);
    let data = {
      designation: details.designation,
      fromDate: moment(details.fromDate).format("YYYY-MM-DD"),
      index: id,
      city: details.city,
      logo: details.logo,
      metadata: await getMetaData(details.metadata),
      id: id,
      organisation: details.organisation,
      organisationName: details.organisationName,
      unofficialOrganisation: details.unofficialOrganisation,
      unofficialOrganisationName: details.unofficialOrganisationName,
      status: details.status,
      toDate: check ? "" : moment(details.toDate).format("YYYY-MM-DD"),
      userId: sessionStorage.getItem("userId"),
    };
    update(data, id);
  };

  const handlecity = (event, value) => {
    // console.log(value, "fghjkl", event)
    setDetails({ ...details, city: value });
  };

  const update = (data, id) => {
    axios
      .put(`${URL.updateExperience}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(
            "kctoken"
          )}`,
        },
      })
      .then((res) => res.data)
      .then((resData) => {
        getData();
      })
      .catch((error) => { });
  };


  return (
    <div className="mt-10 home_border_side">
      <Grid container sx={{ bgcolor: "white", pt: 2 }}>
        {post?.id ? (
          <CreatePost post={post} handleClose={() => setPost({})} />
        ) : (
          ""
        )}
        <Grid item lg={4} md={4}>
          <div className="sidebar__icon">
            <div className="sidebar__title sidebar__pb theader_color fw__bold">
              Certifications
            </div>
            <img src={certifications} alt="certificate" />
            <div className="sidebar__statNumber">
              {data.certificatesCount}
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4}>
          <div className="sidebar__icon">
            <div className="sidebar__title sidebar__pb theader_color fw__bold">
              Badges
            </div>
            <img src={ribbon} alt="certificate" />
            <div className="sidebar__statNumber">{data.badgesCount}</div>
          </div>
        </Grid>
        <Grid item lg={5} md={4}>
          <div className="sidebar__icon">
            <div className="sidebar__title sidebar__pb theader_color fw__bold ">
              Achievements
            </div>
            <img src={achevments} alt="certificate" height="30px" />
            <div className="sidebar__statNumber">
              {data.achievementsCount}
            </div>
          </div>
        </Grid>
      </Grid>
      {/*  experience */}
      <div className="sidebar__stats">
        <div className="sidebar__stat">
          <div className="sidebar__substat">
            <div className="font-caption fw__semibold">Experience</div>
          </div>
        </div>
        {Object?.keys(exp)?.length == 0 ? (
          <>
            {/* <LinkTag to={`/myprofile/${state.fullName.replace(/ /g, '').toLowerCase()}-${state.id}#editdetails`}
                            className="cursor d-flex e-align follow-link"
                            style={{ fontSize: "15px", marginTop: '10px' }}>
                            <AddCircleOutlineIcon sx={{ fontSize: '20px' }} />&nbsp;Add experience
                        </LinkTag> */}
            <p
              onClick={() => addFun()}
              className="cursor d-flex e-align follow-link"
              style={{ fontSize: "15px", marginTop: "10px" }}
            >
              <AddCircleOutlineIcon sx={{ fontSize: "20px" }} />
              &nbsp;Add experience
            </p>
            <p
              style={{
                fontSize: "14px",
                marginTop: "10px",
                color: "#635660",
              }}
            >
              Your work experience comes up here
            </p>
          </>
        ) : (
          <div>
            <div className="d-flex sidebar__pt textAlign capitalize e-align">
              {/* <img src={hamsa} alt="hamsa"></img> */}
              <div>
                <div
                  className="my-img domain-icon"
                  style={{
                    height: "55px",
                    width: "55px",
                    margin: "inherit",
                    borderRadius: "7px",
                  }}
                >
                  {/* <DomainIcon style={{fontSize: '30px'}}/> */}
                  {exp?.logo === "" || exp?.logo === null ? (
                    // <img src={Organisation} alt="organisation" width='25px' height="20px" />
                    <div
                      style={{
                        backgroundColor: "#A8E8FE",
                        width: "55px",
                        height: "55px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "7px",
                      }}
                    >
                      <img
                        src={OrgLogo}
                        alt="organisation"
                        width="25px"
                        height="20px"
                      />
                    </div>
                  ) : (
                    <Avatar
                      src={exp.logo}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "7px",
                      }}
                    />
                  )}
                </div>
              </div>
              &nbsp;&nbsp;
              <div
                className="sidebar__title sidebar__ml sidebar__pt "
                style={{ width: "70%" }}
              >
                <p
                  className="pb-1 text-ellipsis theader_color"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  {exp.organisationName || exp.unofficialOrganisationName}
                </p>
                <p className="pb-1 text-ellipsis tbody_color">
                  {exp.designation}
                </p>
                <p className="pb-1 text-ellipsis tbody_color">
                  {moment(exp.fromDate).format("MMM yyyy")} -{" "}
                  {exp.toDate
                    ? moment(exp.toDate).format("MMM yyyy")
                    : "present"}
                </p>
              </div>
            </div>
            <Link
              href={`/myprofile/${state.fullName
                .replace(/ /g, "")
                .toLowerCase()}-${state.id}`}
              className="ta__right"
              style={{ fontSize: "12px", float: "right" }}
            >
              SeeMore
            </Link>
          </div>
        )}
      </div>

      {/* education */}
      <div className="sidebar__stats">
        <div className="sidebar__stat">
          <div className="sidebar__substat">
            <div className="font-caption fw__semibold">Qualification</div>
          </div>
        </div>
        {state !== undefined &&
          state?.practioner?.qualification !== null &&
          state?.practioner?.qualification?.length > 0 ? (
          <div>
            <div className="d-flex sidebar__pt textAlign capitalize e-align">
              <div>
                <div
                  className="my-img domain-icon"
                  style={{
                    height: "55px",
                    width: "55px",
                    margin: "inherit",
                    borderRadius: "7px",
                  }}
                >
                  {/* <img src={Organisation} alt="organisation" width='25px' height="20px" /> */}
                  <div
                    style={{
                      backgroundColor: "#A8E8FE",
                      width: "55px",
                      height: "55px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "7px",
                    }}
                  >
                    <img
                      src={OrgLogo}
                      alt="organisation"
                      width="25px"
                      height="20px"
                    />
                  </div>
                </div>
              </div>
              <div
                className="sidebar__ml capitalize"
                style={{
                  fontSize: "13px",
                  marginLeft: "20px",
                  width: "70%",
                  color: "#00000099",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "#000000E6",
                  }}
                  className="pb-1 text-ellipsis"
                >
                  {state?.practioner?.qualification?.[0]?.code?.coding?.[0]?.display}
                </p>
                {state?.practioner?.qualification?.[0]?.code?.text ? (
                  <p
                    style={{ paddingBottom: "3px" }}
                    className="text-ellipsis"
                  >
                    {state?.practioner?.qualification?.[0]?.code?.text}
                  </p>
                ) : (
                  ""
                )}
                {state?.practioner?.qualification?.[0]?.issuer ? (
                  <p
                    style={{ paddingBottom: "3px" }}
                    className="text-ellipsis"
                  >
                    {state?.practioner?.qualification?.[0]?.issuer?.display ||
                      state?.practioner?.qualification?.[0]?.issuer}
                  </p>
                ) : (
                  ""
                )}
                <p>
                  {state?.practioner?.qualification?.[0]?.period &&
                    state?.practioner?.qualification?.[0]?.period?.start &&
                    moment(
                      state?.practioner?.qualification?.[0]?.period?.start
                    ).format("MMM yyyy")}{" "}
                  -{" "}
                  {state?.practioner?.qualification?.[0]?.period &&
                    state?.practioner?.qualification?.[0]?.period?.end
                    ? moment(
                      state?.practioner?.qualification?.[0]?.period?.end
                    ).format("MMM yyyy")
                    : "present"}
                </p>
              </div>
            </div>
            <Link
              href={`/myprofile/${state?.fullName
                .replace(/ /g, "")
                .toLowerCase()}-${state?.id}`}
              className="ta__right"
              style={{ fontSize: "12px", float: "right" }}
            >
              SeeMore
            </Link>
          </div>
        ) : (
          <>
            <LinkTag
              to={`/myprofile/${state.fullName
                .replace(/ /g, "")
                .toLowerCase()}-${state.id}#editdetails`}
              className="cursor d-flex e-align follow-link"
              style={{ fontSize: "15px", marginTop: "10px" }}
            >
              <AddCircleOutlineIcon sx={{ fontSize: "20px" }} />
              &nbsp;Add Qualification
            </LinkTag>
            <p
              style={{
                fontSize: "14px",
                marginTop: "10px",
                color: "#635660",
              }}
            >
              Your education and licenses here
            </p>
          </>
        )}
      </div>
      {/* peers */}
      {props?.count ? (
        <div style={{ marginBottom: "15px" }} />
      ) : (
        <div
          className="sidebar__stats border__bottom  sidebar__padding "
          style={{ marginTop: "10px" }}
        >
          {/* <CountComp img={peers} name='Peers' count={data.peersCount} />
                    <CountComp img={followers} name='Followers' count={data.followersCount} />
                    <CountComp img={Publications} name='Publications' count={data.publicationCount} /> */}
          {/* <CountComp img={Bookmarks} name='Bookmarks' count={user.Bookmarks} /> */}
          <CountComp img={Bookmarks} name="Bookmarks" count={bookmarkCount} />
          {/* <CountComp img={Reviewed} name='Reviewed' count={data.reviewedCount} /> */}
        </div>
      )}

      {/* my pages */}
      {props.hide ? (
        ""
      ) : (
        <>
          {myPages?.length > 0 ? (
            <>
              <div className="sidebar__stats  sidebar__padding">
                <div
                  className="sidebar__substat"
                  style={{ alignItems: "center" }}
                >
                  {/* <img src={Pages} style={{ fontSize: "20px" }} /> */}
                  <div className="font-caption fw__semibold sidebar__lh">
                    My Pages
                  </div>
                </div>
                {/* <div className="sidebar__title sidebar__ml flex__end">Likes</div> */}
              </div>
              <div className="sidebar__stats" style={{ paddingTop: "0px" }}>
                {myPages ? (
                  (myPages ?? [])?.map((item, ind) => {
                    return (
                      <div
                        key={ind}
                        className="sidebar_statPage"
                        style={{ borderBottom: "0px" }}
                      >
                        <div
                          className="sidebar_pages e-align"
                          style={{ width: "80%", paddingLeft: "0px" }}
                        >
                          <div style={{ width: "20%" }}>
                            {item.logo ? (
                              <Avatar
                                style={{
                                  height: "32px",
                                  width: "32px",
                                  borderRadius: "7px",
                                }}
                                src={item.logo}
                              />
                            ) : (
                              <div
                                style={{
                                  backgroundColor: "#A8E8FE",
                                  width: "32px",
                                  height: "32px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "7px",
                                }}
                              >
                                <img
                                  src={OrgLogo}
                                  alt="organisation"
                                  width="25px"
                                  height="20px"
                                />
                              </div>
                            )}
                          </div>
                          &nbsp;&nbsp;
                          <div
                            className="cursor capitalize"
                            style={{ fontSize: "14px", width: "80%" }}
                          >
                            {/* <b>{item.name}</b> */}
                            <p
                              className="name_hover  text-ellipsis"
                              onClick={() =>
                                history.push(`/admin/organization/${item.id}`)
                              }
                            >
                              {" "}
                              {item.name}
                            </p>
                            <p
                              className="fontItalic text-ellipsis"
                              style={{
                                color: "#635660",
                                marginTop: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {item.captionAndTagline}
                            </p>
                          </div>
                        </div>
                        <p
                          className="d-flex e-align font-title"
                          style={{ marginTop: "0px", textAlign: "right" }}
                        >
                          {item.count}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p>
                    If your managing an organisation build page for your
                    organisation{" "}
                  </p>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {/* Events */}

          {user?.events &&
            user?.events?.[0]?.myEvents &&
            user?.events?.[0]?.myEvents?.length > 0 ? (
            <>
              <div className="sidebar__stats  sidebar__padding">
                <div
                  className="sidebar__substat"
                  style={{ alignItems: "center" }}
                >
                  {/* <img src={Pages} style={{ fontSize: "20px" }} /> */}
                  <div className="font-caption fw__semibold sidebar__lh">
                    My Events
                  </div>
                </div>
              </div>
              <div className="sidebar__stats" style={{ paddingTop: "0px" }}>
                {user?.events?.[0]?.myEvents?.map((item, ind) => {
                  return (
                    <div
                      key={ind}
                      className="sidebar_statPage"
                      style={{ borderBottom: "0px" }}
                    >
                      <div
                        className="sidebar_pages e-align"
                        style={{ flexGrow: 1, width: "165px" }}
                      >
                        <div
                          style={{
                            width: "20%",
                            backgroundColor: "#3b10cc",
                            color: "white",
                            padding: "3px",
                          }}
                          className="textCenter"
                        >
                          <p>
                            {moment
                              .utc(item.eventData.openingTime)
                              .local()
                              .format("DD")}
                          </p>
                          <p>
                            {moment
                              .utc(item.eventData.openingTime)
                              .local()
                              .format("MMM")}
                          </p>
                        </div>
                        <div
                          className="cursor capitalize"
                          style={{
                            fontSize: "14px",
                            width: "77%",
                            marginLeft: "3%",
                          }}
                        >
                          {/* <b>{item.name}</b> */}
                          <p
                            className="name_hover text-ellipsis cursor"
                            onClick={() =>
                              history.push(
                                `/webinar/eventdata-${item.eventData.id}`
                              )
                            }
                          >
                            {" "}
                            {item.eventData.title}
                          </p>
                          <p
                            className="fontItalic text-ellipsis"
                            style={{
                              color: "#635660",
                              marginTop: "5px",
                              fontSize: "13px",
                            }}
                          >
                            {item.eventData.eventType}
                          </p>
                        </div>
                      </div>
                      <p
                        className="d-flex e-align cursor"
                        style={{ marginTop: "0px" }}
                        onClick={(e) => editEvent(item)}
                      >
                        <FiEdit3 />
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}

          {/* Membership */}
          {/* <div className="sidebar__stats">
                    <div className="sidebar__substat pt-3">
                        <img src={Memberships} style={{ fontSize: "20px" }} />
                        <div className="font-caption fw__semibold sidebar__lh  sidebar__ml">Membership</div>
                    </div>
                    <div className="sidebar__title sidebar__ml flex__end">Members</div>
                </div>
                <div className="sidebar__stats">
                    <div className="sidebar__stat ">
                        <div className="sidebar__substat">
                            <img src={medxpert} alt="medxpert" />
                            <div className="sidebar__member ">
                                <div className="sidebar__title fw__bold sidebar__ml">Organisation Name</div>
                                <div className="sidebar__title sidebar__ml">Role / Membership Type</div>
                            </div>
                            <img src={Organisations} style={{ fontSize: "20px", marginLeft: "10px", color: "gray" }} />
                        </div>
                        <div className="sidebar__statNumber">899</div>
                    </div>
                </div> */}
        </>
      )}
      <DialogBox
        open={open}
        handleDialog={() => setOpen(false)}
        Title={`${edit ? "Edit" : "Add"} Experience`}
        Content={
          <Add
            state={details}
            handleChange={handleChange}
            handleOrg={handleOrg}
            cancel={() => handleCancel()}
            handleSubmit={edit ? handleUpdate : handleAdd}
            submit={edit ? "Update" : "Add"}
            check={check}
            handleCheck={(e) => setCheck(e.target.checked)}
            handlecity={handlecity}
          />
        }
      />

      {/* <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={alert.upload ? null : 2000}
          open={alert.open}
          onClose={() => setAlert({ open: false, msg: "", upload: false })}
          message={alert.msg}
        /> */}
    </div>
  );
}

export default Sidebar
