import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import '../SignUp/signup.css';
import Explore from '../../images/explore3.svg';
import Peers from '../../images/peers.svg';
import Elib from '../../images/e-library.svg';
import Medscope from '../../images/medscope.svg';
import greenBorder from '../../images/border-green.png';
import blueBorder from '../../images/border-blue.png';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

let cardArray = [
  { title: 'Explore', border: greenBorder, icon: Explore, desc: 'Updates on Topics you follow, Healthcare news feed & more' },
  { title: 'Connect', border: blueBorder, icon: Peers, desc: 'We design and build iOS, Android or hybrid apps using react native and flutter or cordova' },
  { title: 'E-Practice', border: greenBorder, icon: Elib, desc: 'How you present your brand is important. We make that process easy for you' },
  { title: 'Medscope', border: blueBorder, icon: Medscope, desc: 'We craft exquisite brand identities that convey your product and service well to your clients' },
];


function CarouselComponent1() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  // const maxSteps = images.length;
  const maxSteps = cardArray?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }} style={{ margin: '0 auto' }} className='carousel-component1'>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >

        {cardArray?.map((item) => {
          return <div className='card carousel-card' key={item.title}>
            <h5>{item.title}</h5>
            <div className='card-image'>
              <img className='image1' src={item.border} alt='image-border' />
              <img className='image2' src={item.icon} alt='icon' />
            </div>
            <p className='card-desc'>{item.desc}</p>
          </div>

        })}
      </AutoPlaySwipeableViews>
      <MobileStepper
        style={{ backgroundColor: 'transparent' }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="large"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >

            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}

          </Button>
        }
      />
    </Box>
  );
}

export default CarouselComponent1;
