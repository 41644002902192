import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import './Privacyandpolicy.css';
import Footer from '../SignUp/footer.js';

function TermsandCondition() {

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Box className="elibrary_homepage_body1">
                <div id="policy_body_padding">
                    <div className='policy_subheader'>
                        <div id="head_terms">
                            <p>
                                Our mission is to connect the world’s professionals to allow them to be more productive and successful. Our services are designed to promote economic
                                opportunity for our members by enabling you and millions of other professionals to meet, exchange ideas, learn, and find opportunities or employees,
                                work, and make decisions in a network of trusted relationships.
                            </p>
                        </div>
                    </div>
                    <div className='1'>
                        <div className='first_heading pt-3' >
                            <h1>User Agreement</h1>
                            <div className='mt-20'>
                                <h3 className='eff_date'>Effective from 8, Dec 2021</h3>
                            </div>
                            <p className='mt-20'>
                                You agree that by clicking “Join Now”, “Join Docsteth”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Docsteth (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.
                            </p>
                        </div>
                        <div className='pt-3 intr_sub_header'>
                            <h2>Services</h2>
                            <p className='font_text mt-10'>This Contract applies to Docsteth.com, Docsteth-branded apps, and other Docsteth-related sites, apps, communications and other services that state that they are offered under this Contract (“Services”), including the offsite collection of data for those Services, such as our ads and the “Signup with Docsteth” and “Share with Docseth” plugins. Registered users of our Services are “Members” and unregistered users are “Visitors”.
                            </p>
                        </div>
                        <div className='pt-3 intr_sub_header'>
                            <h2>Docsteth</h2>
                            <p className='font_text mt-10'>
                                You are entering into this Contract with Vollmond Innovations and Social Entrepreneurs Private Limited parent company and trade mark owner of Docsteth (also referred to as “we” and “us”).
                            </p>
                            <p className='font_text mt-10'>
                                You are entering into this Contract with Docsteth and “Vollmond Innovations and Social Entrepreneurs Private Limited ” will be the controller of your personal data provided to, or collected by or for, or processed in connection with our Services.
                            </p>
                            <p className='font_text mt-10'>
                                As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.
                            </p>
                        </div>
                    </div>

                    <div className='2'>
                        <div className='mt-20'>
                            <h1 className='term_main_head'>Terms and Conditions</h1>
                        </div>
                        <div className='terms_cond pt-2' >
                            <h1>The terms and condition associated with the user agreement</h1>
                        </div>
                        <div className='subpart fw__normal mt-20'>
                            <p className='font_text'>
                                We may modify this Contract, our Privacy Policy and our Cookies Policy from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. If you object to any changes, you may close your account. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms as of their effective date.
                            </p>
                        </div>
                        <div className='2.1'>
                            <div className='subpart fw__normal pt-3'>
                                <h4 className='fw__semibold font_text'>
                                    1.	Service Eligibility
                                </h4>
                                <p className='font_text mt-10'>
                                    The Services are not for use by anyone under the age of 17.
                                </p>
                                <p className='font_text mt-10'>
                                    To use the Services, you agree that: (1) you must be the “Minimum Age”(described below) or older; (2) you will only have one Docsteth account, which must be in your real name; and (3) you are not already restricted by Docsteth from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 17.
                                </p>
                                <p className='font_text mt-10'>
                                    “Minimum Age” means 17 years old. However, if law requires that you must be older in order for Docsteth to lawfully provide the Services to you without parental consent (including using of your personal data) then the Minimum Age is such older age.
                                </p>
                                <p className='font_text mt-10'>
                                    To use Docsteth services, you must be a board certified practitioner, and/or a licensed healthcare professional or Medical students. You must upload related document to prove your identity matching above requirements.
                                </p>
                            </div>

                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.	Notices and Messages
                                </h4>
                                <p className='font_text mt-10'>
                                    You’re okay with us providing notices and messages to you through our websites, apps, and contact information. If your contact information is out of date, you may miss out on important notices.
                                </p>
                                <p className='font_text mt-10'>
                                    You agree that we will provide notices and messages to you in the following ways: (1) within the Service, or (2) sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date.
                                    However, you can review your settings to control and limit messages you receive from us.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    3.	Sharing
                                </h4>
                                <p className='font_text mt-10'>
                                    Our Services allow messaging and sharing of information in many ways, such as your profile, articles, group posts, links to news articles, messages and InMails. Information and content that you share or post may be seen by other Members, Visitors or others (including off of the Services). Where we have made settings available, we will honor the choices you make about who can see content or information (e.g., message content to your addressees, sharing content only to Docsteth connections, restricting your profile visibility from search engines, or opting not to notify others of your Docsteth profile update).
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    4.	Rights and Limits
                                </h4>
                                <p className='font_text mt-10'>
                                    You own all of the content, feedback and personal information you provide to us, but you also grant us a non-exclusive license to it. We’ll honour the choices you make about who gets to see your information and content, including how it can be used
                                </p>
                                <p className='font_text mt-10'>
                                    As between you and Docsteth, you own the content and information that you submit or post to the Services, and you are only granting Docsteth and our affiliates the following non-exclusive license:
                                </p>
                                <p className='font_text mt-10'>
                                    A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish and process, information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:
                                </p>
                                <p className='font_text mt-10'>
                                    You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.
                                </p>
                                <p className='font_text mt-10'>
                                    We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions may be visible and included with ads, as noted in the Privacy Policy. If you use a Service feature, we may mention that with your name or photo to promote that feature within our Services, subject to your settings.
                                </p>
                                <p className='font_text mt-10'>
                                    We will get your consent if we want to give others the right to publish your content beyond the Services. However, if you choose to share your post as “public, everyone or similar”, we will enable a feature that allows other Members to embed that public post onto third-party services, and we enable search engines to make that public content findable though their services. Learn More
                                </p>
                                <p className='font_text mt-10'>
                                    While we may edit and make format changes to your content (such as translating or transcribing it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.
                                </p>
                                <p className='font_text mt-10'>
                                    Because you own your content and information and we only have non-exclusive rights to it, you may choose to make it available to others, including under the terms of a Creative Commons license.
                                </p>
                                <p className='font_text mt-10'>
                                    You and Docsteth agree that if content includes personal data, it is subject to our Privacy Policy.
                                </p>
                                <p className='font_text mt-10'>
                                    You and Docsteth agree that we may access, store, process and use any information and personal data that you provide in accordance with, the terms of the Privacy Policy and your choices (including settings).
                                </p>
                                <p className='font_text mt-10'>
                                    By submitting suggestions or other feedback regarding our Services to Docsteth, you agree that Docsteth can use and share (but does not have to) such feedback for any purpose without compensation to you.
                                </p>
                                <p className='font_text mt-10'>
                                    You promise to only provide information and content that you have the right to share, and that your Docsteth profile will be truthful.
                                    You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information will be truthful. Docsteth may be required by law to remove certain information or content in certain countries.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    5.	Service Availability
                                </h4>
                                <p className='font_text mt-10'>
                                    We may change, suspend or discontinue any of our Services. We may also modify our prices effective prospectively upon reasonable notice to the extent allowed under the law.
                                </p>
                                <p className='font_text mt-10'>
                                    We don’t promise to store or keep showing any information and content that you’ve posted. Docsteth is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    6.	Other Content, Sites and Apps
                                </h4>
                                <p className='font_text mt-10'>
                                    By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Docsteth generally does not review content provided by our Members or others. You agree that we are not responsible for others’ (including other Members’) content or information. We cannot always prevent this misuse of our Services, and you agree that we are not responsible for any such misuse. You also acknowledge the risk that you or your organization may be mistakenly associated with content about others when we let connections and followers know you or your organization were mentioned in the news. Members have choices about this feature.
                                </p>
                                <p className='font_text mt-10'>
                                    Docsteth may help connect Members offering their services (career coaching,accounting, etc.) with Members seeking services. Docsteth does not perform nor employs individuals to perform these services. You must be at least 18 years of age to offer, perform or procure these services. You acknowledge that Docsteth does not supervise, direct, control or monitor Members in the performance of these services and agree that (1) Docsteth is not responsible for the offering, performance or procurement of these services, (2) Docsteth does not endorse any particular Member’s offered services, and (3) nothing shall create an employment, agency, or joint venture relationship between Docsteth and any Member offering services. If you are a Member offering services, you represent and warrant that you have all the required licenses and will provide services consistent with our Professional Community Policies.
                                </p>
                                <p className='font_text mt-10'>
                                    Similarly, Docsteth may help you register for and/or attend events organized by Members and connect with other Members who are attendees at such events. You agree that (1) Docsteth is not responsible for the conduct of any of the Members or other attendees at such events, (2) Docsteth does not endorse any particular event listed on our Services, (3) Docsteth does not review and/or vet any of these events, and (4) that you will adhere to these terms and conditions that apply to such events.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    7.	Intellectual Property rights
                                </h4>
                                <p className='font_text mt-10'>
                                    Vollmond Innovations and Social entrepreneurs Private limited reserves all of its intellectual property rights in the Services related to Docsteth. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. Docsteth logo and other Docsteth trademarks, service marks, graphics and logos used for our Services are trademarks or registered trademarks of Vollmond Innovations and Social Private Limited.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    8.	Automated Processing
                                </h4>
                                <p className='font_text mt-10'>
                                    We use the information and data that you provide and that we have about Members to make recommendations for connections, content and features that may be useful to you. Keeping your profile accurate and up to date helps us to make these recommendations more accurate and relevant.
                                </p>
                            </div>
                            <div className='fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    9.	Disclaimer and Limit of Liability
                                </h4>
                                <div className='mt-10'>
                                    <h4 className='fw__semibold font_text'>
                                        a.	No Warranty
                                    </h4>
                                    <p className='font_text mt-10'>
                                        DOCSTETH (VOLLMOND INNOVATIONS AND SOCIAL ENTREPRENEURS PRIVATE LIMITED) AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, DOCSTETH AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
                                    </p>
                                </div>
                                <div className='mt-10'>
                                    <h4 className='fw__semibold font_text'>
                                        b.	Exclusion of Liability
                                    </h4>
                                    <p className='font_text mt-10'>
                                        TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS DOCSTETH HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), DOCSTETH, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
                                    </p>
                                    <p className='font_text mt-10'>
                                        DOCSTETH AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO DOCSTETH FOR THE SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US $1000.
                                    </p>
                                    <p className='font_text mt-10'>
                                        These limitations of liability do not apply to liability for death or personal injury or for fraud, gross negligence or intentional misconduct, or in cases of negligence where a material obligation has been breached, a material obligation being such which forms a prerequisite to our delivery of services and on which you may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of this Contract and to the extent that they are typical in the context of this Contract.
                                    </p>
                                </div>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    10.	Termination
                                </h4>
                                <p className='font_text mt-10'>
                                    Both you and Docsteth may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services. The following shall survive termination:
                                </p>
                                <p className='font_text mt-10'>
                                    Our rights to use and disclose your feedback;
                                    Members and/or Visitors’ rights to further re-share content and information you shared through the Services;
                                    Any amounts owed by either party prior to termination remain owed after termination.
                                </p>
                                <p className='font_text mt-10'>
                                    Docsteth Reserve the rights to terminate your account on the basis of mis-information, fraud, harassment when reported by other users and on investigation of facts.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    11.	Governing Law and Dispute Resolution
                                </h4>
                                <p className='font_text mt-10'>
                                    If a court with authority over this Contract finds any part of it unenforceable, you and we agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract.
                                </p>
                                <p className='font_text mt-10'>
                                    This Contract (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.
                                </p>
                                <p className='font_text mt-10'>
                                    If we don’t act to enforce a breach of this Contract, that does not mean that Docsteth has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without our consent. However, you agree that Docsteth may assign this Contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Contract.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    12.	Prohibition of Misuse (Don’ts )
                                </h4>
                                <p className='font_text mt-10'>
                                    Create a false identity on Docsteth, misrepresent your identity, create a Member profile for anyone other than yourself (a real person), or use or attempt to use another’s account;
                                </p>
                                <p className='font_text mt-10'>
                                    Develop, support or use software, devices, scripts, robots or any other means or processes (including crawlers, browser plugins and add-ons or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services;
                                    Override any security feature or bypass or circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views);
                                    Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Docsteth;
                                </p>
                                <p className='font_text mt-10'>
                                    Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer));
                                    Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license;
                                </p>
                                <p className='font_text mt-10'>
                                    Violate the intellectual property or other rights of Docsteth, including, without limitation, (i) copying or distributing our learning videos or other materials or (ii) copying or distributing our technology, unless it is released under open source licenses; (iii) using the word “Docsteth” or our logos in any business name, email, or URL except as provided in the Brand Guidelines;
                                </p>
                                <p className='font_text mt-10'>
                                    Post anything that contains software viruses, worms, or any other harmful code;
                                    Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;
                                    Imply or state that you are affiliated with or endorsed by Docsteth without our express consent (e.g., representing yourself as an accredited Docsteth trainer);
                                    Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without Docsteth’s consent;
                                </p>
                                <p className='font_text mt-10'>
                                    Deep-link to our Services for any purpose other than to promote your profile or a Group on our Services, without Docsteth’s consent;
                                </p>
                                <p className='font_text mt-10'>
                                    Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;
                                </p>
                                <p className='font_text mt-10'>
                                    Monitor the Services’ availability, performance or functionality for any competitive purpose;
                                </p>
                                <p className='font_text mt-10'>
                                    Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;
                                </p>
                                <p className='font_text mt-10'>
                                    Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);
                                </p>
                                <p className='font_text mt-10'>
                                    Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms); and/or
                                    Violate the Professional Community Policies or any additional terms concerning a specific Service that are provided when you sign up for or start using such Service, and the Bing Maps terms where applicable.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    13.	Complaints Regarding Content
                                </h4>
                                <p className='font_text mt-10'>
                                    We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. We provide a policy and process for complaints concerning content posted by our Members.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20 terms_end'>
                                <h4 className='fw__semibold font_text'>
                                    14.	How to Contact US
                                </h4>
                                <p className='font_text mt-10'>
                                    For general inquiries, you may contact us connect@docsteth.com  For legal notices or service of process, you may write us at these #1-98/7/A/35, Narra's House, Jai Hindh Enclave, Madhapur, Hyderabad 500081, TG, IN
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </Box>
        </>
    )
}

export default TermsandCondition
