import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { FormGroup, RadioGroup } from '@mui/material';


class RadioValidator extends ValidatorComponent {

    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, options, validatorListener, getOptions, ...rest } = this.props;

        return (
            <div>
                <FormGroup>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        {...rest}
                    >
                        {this?.props?.children}
                    </RadioGroup>
                </FormGroup>
                {this?.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: '#d32f2f', fontSize: '12px' }}>
                {this?.getErrorMessage()}
            </div>
        );
    }
}

export default RadioValidator;