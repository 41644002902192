import { Box, Grid, Typography, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import URL from "../apiurl"
import { useHistory, useParams, Redirect } from 'react-router-dom'
import CalculatorHeader from "./calHeader";
import './calculator.css'
import { useDispatch } from "react-redux";
import { headers } from "../../redux/Actions/connectActions";
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { borderRadius } from "@mui/system";

const Calculator = (props) => {

    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const [nameUrl, setNameUrl] = useState('')
    const { name } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()


    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        axios.get(`${URL.searchCalculator}?type=${nameUrl}&title=${search}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            setData(res.data?.data)
        }).catch(err => {
            console.log(err)
        })
    }, [search])

    useEffect(() => {
        let cal = name === 'drug-calculator' ? 'Drug-Calculator' : name === 'biomedical-calculator' ? "Biomedical-Calculator" : name === 'clinical-assessment' ? 'Clinical-Assessment' : name === 'check-list' ? 'Check-List' : ''
        setNameUrl(cal)
        if (search) { setSearch('') }
        if (cal !== '') {
            axios.get(process.env.REACT_APP_QUESTIONNAIRE === 'false' ? `${URL.getQuestionnaireByType}/${cal}` : `${URL.getQuestionnaireByStatus}/${cal}/Status/active`, headers()).then(res => setData(res.data?.data)).catch(err => setData([]))
        }
        else {
            <Redirect to="/elib/ebmkithomepage" />
        }
    }, [name])

    useEffect(() => {

        dispatch({
            type: 'GET_QUESTIONNAIRE',
            payload: {}
        })

    }, [])

    return (
        <Box sx={{ px: '2px' }}>
            <CalculatorHeader name={name} type={name} />
            <Paper style={{ boxShadow: '0px 3px 6px #00000029' }}>
                <Box sx={{ display: 'flex', justifyContent: { md: 'end' }, padding: '10px 20px' }} >
                    <TextField
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search here..."
                        size="small"
                        sx={{ width: { xs: "100%", sm: "300px", md: "300px" } }}

                        InputProps={{
                            endAdornment: <InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>,
                        }} />
                </Box>
                <div style={{ height: `calc(100vh - 220px)`, backgroundColor: 'white', overflow: 'scroll' }}>
                    <Grid container direction='row' sx={{ pb: '2px', pl: '5px' }}>
                        {data?.length > 0 ? data?.sort((a, b) => a.title > b.title ? 1 : -1)?.map(form => (
                            <Grid item md={4} sm={6} xs={12} key={form.id} sx={{ height: 'auto', mt: 2, px: 1 }} onClick={() => history.push(`/elib/${name}/${form.id}`)}>
                                <div className="elib_sub_text" style={{ lineHeight: 1.5, height: '50px', overflow: 'hidden', padding: '15px', boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px' }}>
                                    <p className='cal-ellipsis'>{form.title}</p>
                                </div>
                            </Grid>
                        )) : <Typography sx={{ p: 2 }}>No Calculators</Typography>}
                    </Grid>
                </div>
            </Paper>
        </Box>
    )
}

export default Calculator