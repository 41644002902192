import React, { useState, useEffect } from "react";
import { Box, Dialog, DialogTitle, DialogContent, Tab, Card, } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HealthDeptBasicDetails from "./healthdeptbasicDetails";
import HealthDeptLocation from "./healthdeptLocation";
import HealthDeptWorkhours from "./healthdeptWorkhours";
import { gethealthDeptListByPgId } from "../../../../../../redux/Actions/regActions";


const TabInfo = [
    { name: <span style={{ fontSize: '18px' }}><span>Basic Details</span></span>, Component: HealthDeptBasicDetails },
    { name: <span style={{ fontSize: '18px' }}><span>Locations Details</span></span>, Component: HealthDeptLocation },
    { name: <span style={{ fontSize: '18px' }}><span>WorksHours</span></span>, Component: HealthDeptWorkhours }
]


const AddHealthcareDept = ({ opn, cls, id, switchToTab }) => {
    const [tab, setTab] = useState("1");

    const handleTabs = () => {
        setTab((prev) => `${parseInt(prev) + 1}`);
    }

    // useEffect(() => {
    // }, [tab])

    useEffect(() => {
        if (switchToTab) {
            setTab(switchToTab);
        } else {
            setTab("1")
        }

    }, [switchToTab])


    return (
        <div>
            <Dialog open={opn} onClose={cls} maxWidth={"sm"} fullWidth sx={{ height: "600px", position: 'absolute', top: "40px" }}>
                <TabContext value={tab}>
                    <DialogTitle sx={{ padding: '0px' }}>
                        <Card>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(event, newValue) => setTab(newValue)} aria-label="lab API tabs example">
                                    {
                                        TabInfo?.map((val, i) => {
                                            return (<Tab className='capitalize m-auto' key={i} label={val.name} value={`${i + 1}`} />)
                                        })
                                    }
                                </TabList>
                            </Box>
                        </Card>
                    </DialogTitle>
                    <DialogContent sx={{ padding: '0px' }} className="tohideScrollbar">
                        {
                            TabInfo?.map((tabs, i) => tab == i + 1 && <TabPanel key={i} sx={{ padding: i === 3 ? '10px 0' : '20px 20px' }} value={`${i + 1}`}><tabs.Component switchTabs={handleTabs} clsn={cls} healthid={id} Reset={() => setTab("1")} /></TabPanel>)
                        }
                    </DialogContent>
                </TabContext>

            </Dialog>
        </div>

    )
}
export default AddHealthcareDept;


{/* <Dialog id="services" open={true} onClose={props.close} maxWidth={"sm"} sx={{ height: "600px", position: 'absolute', top: "40px" }} fullWidth>
                <TabContext value={tab}>
                    <DialogTitle sx={{ padding: '0px' }}>
                        <Card sx={{ borderRadius: 0, boxShadow: '0px 3px 6px #00000029' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(e, newval) => { setTab(newval) }} classes={{ indicator: { backgroundColor: '#0F22B5!important' } }} aria-label="lab API tabs example" variant="scrollable">
                                    {props.edit ?
                                        TabsListEdit.map((tabs, i) => <Tab className='capitalize m-auto' key={i} classes={{ selected: { color: '#0F22B5!important' } }} label={tabs.name} value={`${ i + 1 } `} />) :
                                        TabsList.map((tabs, i) => <Tab className='capitalize m-auto' key={i} classes={{ selected: { color: '#0F22B5!important' } }} label={tabs.name} value={`${ i + 1 } `} />)
                                    }
                                </TabList>
                            </Box>
                        </Card>
                    </DialogTitle>
                    <DialogContent sx={{ padding: '0px' }}>
                        {props.edit ?
                            TabsListEdit.map((tabs, i) => tab == i + 1 && <TabPanel key={i} sx={{ padding: i === 3 ? '10px 0' : '20px 20px 10px 20px' }} value={`${ i + 1 } `}><tabs.Component close={props.close} submitData={submitData} currTab={tab} /></TabPanel>) :
                            TabsList.map((tabs, i) => tab == i + 1 && <TabPanel key={i} sx={{ padding: i === 3 ? '10px 0' : '20px 20px' }} value={`${ i + 1 } `}><tabs.Component close={props.close} submitData={submitData} /></TabPanel>)
                        }
                    </DialogContent>
                </TabContext>
            </Dialog> */}