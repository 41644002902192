import React, { useState, useEffect } from 'react'
import { AppBar, Grid, Toolbar, Typography } from '@mui/material'
import GroupEbm from "../../Atom/svg/GroupEbm.svg"
import { useRouteMatch } from 'react-router';
import './EbmkitHeader.css'
import { Link } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom';

function EbmkitHeader(props) {

    const { url } = useRouteMatch();
    const history = useHistory()
    const [path, setPath] = useState([])

    useEffect(() => {

        let u = url
        u = url.split('/');
        setPath([u[3], u[4]])

    }, [])

    const changePath = () => {
        let urlpath = [];
        urlpath = url.split('/')
        urlpath.pop()
        history.push(`${urlpath.toString().replace(/,/g, '/').toLowerCase()}`)
    }

    return (
        <div className="elibrary_rightcontainer_header">
            <AppBar position="static" style={{ background: '#FFFFFF', boxShadow: '0px 3px 6px #00000029', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                <Toolbar className='ebm_app_bar' style={{ boxShadow: '0px 3px 6px #00000029' }}>
                    <Grid container alignItems="center" justifyContent="space-between" style={{ display: 'flex' }}>
                        <Grid item>
                            <div className='desktopview_header'>
                                <div className="elibrary_rightcontainer_icon1">
                                    <div className='smalldevices'>
                                        <Button onClick={() => changePath()}><ArrowBackIosNewIcon /></Button>
                                    </div>
                                    <img src={GroupEbm} alt="app_vault" />
                                    <Typography variant="h4" color="black" style={{ marginLeft: '6px', fontSize: '23px', cursor: 'pointer' }}>
                                        <span className="font-weight"><a href="/elib/specialities">EBM Practice Kit
                                        </a></span>
                                        {path[0] ? <span> {'/'}<Link className={`specialities ${path[1] ? "" : path[0] ? 'active-link' : ''}`} to={`/elib/specialities/${path[0]}`}>{path[0].split('-')[0].replace(/__/g, '-').replace(/_/g, ' ')}</Link></span> : ""}
                                        {path[1] ? <> /<Link className={`specialities ${path[1] ? 'active-link' : ''}`} to={`/elib/specialities/${path[1]}`}>{path[1].split('-')[0].replace(/__/g, '-').replace(/_/g, ' ')}</Link> </> : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='mobileview_header'>
                                <div className="elibrary_rightcontainer_icon1">
                                    <div id='ml_arrow'>
                                        <Button onClick={() => changePath()}><ArrowBackIosNewIcon /></Button>
                                    </div>
                                    {/* <img src={GroupEbm} alt="app_vault" style={{ fontSize: "12px" }} /> */}
                                    <div className='display_icon_back'>
                                        <Typography variant="h4" color="black" style={{ marginLeft: '6px', fontSize: '12px', cursor: 'pointer' }}>
                                            <span className="font-weight"><Link to="/elib/specialities">EBM{" "}/{" "}</Link></span>
                                            {path[0] ? <span><Link className={`edm_header_title ${path[1] ? "" : path[0] ? 'active-link' : ''}`} to={`/elib/specialities/${path[0]}`}>{path[0].split('-')[0].replace(/__/g, '-').replace(/_/g, ' ')}{' '}{'/'}</Link></span> : ""}
                                            {path[1] ? <><Link className={`edm_header_title ${path[1] ? 'active-link' : ''}`} to={`/elib/specialities/${path[1]}`}>{path[1].split('-')[0].replace(/__/g, '-').replace(/_/g, ' ')}</Link> </> : ""}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div >
    )
}

export default EbmkitHeader
