import React, { useState, useEffect } from "react";
import { Box, Dialog, DialogTitle, DialogContent, Tab, Card, } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ServiceDetails from "./individalHealthserviceTabs/serviceDetails";
import AdditionalServiceDetails from "./individalHealthserviceTabs/addnServiceDetails";
import ServiceLocation from "./individalHealthserviceTabs/createNewServiceLoc";
import ServiceWorkhours from "./individalHealthserviceTabs/serviceWorkHours";




const TabInfo = [
    { name: <span style={{ fontSize: '18px' }}><span>Services Details</span></span>, Component: ServiceDetails },
    { name: <span style={{ fontSize: '18px' }}><span>Additional Info</span></span>, Component: AdditionalServiceDetails },
    { name: <span style={{ fontSize: '18px' }}><span>Location </span></span>, Component: ServiceLocation },
    { name: <span style={{ fontSize: '18px' }}><span>Work Hours</span></span>, Component: ServiceWorkhours }
]


const ShowHealthServiceTabs = ({ opn, cls, healthid }) => {
    const [tab, setTab] = useState("1");



    const handleTabs = () => {
        setTab((prev) => `${parseInt(prev) + 1}`);
    }

    // useEffect(() => {
    // }, [tab])



    return (
        <div>

            <Dialog open={opn} onClose={cls} maxWidth={"sm"} fullWidth style={{ height: "600px", position: 'absolute !important', top: "5px !important", }}>
                <TabContext value={tab}>
                    <DialogTitle sx={{ padding: '0px' }}>
                        <Card>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(event, newValue) => setTab(newValue)} aria-label="lab API tabs example">
                                    {
                                        TabInfo?.map((val, i) => {
                                            return (<Tab className='capitalize m-auto' key={i} label={val.name} value={`${i + 1}`} />)
                                        })
                                    }
                                </TabList>
                            </Box>
                        </Card>
                    </DialogTitle>
                    <DialogContent sx={{ padding: '0px' }} className="tohideScrollbar">
                        {
                            TabInfo?.map((tabs, i) => tab == i + 1 && <TabPanel key={i} sx={{ padding: i === 3 ? '10px 0' : '20px 20px' }} value={`${i + 1}`}><tabs.Component switchTabs={handleTabs} clsn={cls} healthid={healthid} Reset={() => setTab("1")} /></TabPanel>)
                        }
                    </DialogContent>
                </TabContext>

            </Dialog>
        </div>


    )
}
export default ShowHealthServiceTabs;