import { Box, Typography } from "@mui/material";
import CalculatorHeader from "./calHeader";
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import axios from 'axios'
import URL from '../apiurl'
import FormRender from './formrender'
import { getQuestionnaire } from "../../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";

export default function CalculatorForm() {

    const { id, name } = useParams()
    const [data, setData] = useState({})

    const history = useHistory()
    const dispatch = useDispatch()

    const questionnaire = useSelector(state => state.userProfile.getQuestionnaire)

    useEffect(() => {
        dispatch(getQuestionnaire(id))
    }, [id])

    useEffect(() => {
        setData(questionnaire)
    }, [questionnaire])

    return (
        <Box sx={{ pr: '2px' }}>
            <CalculatorHeader name={data?.title} type={name} />
            <div style={{ backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029', height: 'calc(100vh - 172px)' }}>

                <Box sx={{ px: 2, pt: 2 }}>
                    <ArrowBack onClick={() => history.goBack()} />
                </Box>

                <Box sx={{ height: 'calc(100vh - 240px)', pr: 2, pl: 4, overflowY: 'scroll' }}>
                    {/* <p className="d-flex e-align">
                    <Typography sx={{mx: 2}}>Overview</Typography>
                    <Typography sx={{mx: 2}}>Calculator</Typography>
                    <Typography sx={{mx: 2}}>Method</Typography>
                    <Typography sx={{mx: 2}}>Reference</Typography>
                </p> */}
                    {data?.questionnaireData && data?.questionnaireData?.purpose !== `<p></p>` ? <Typography sx={{ px: 2, mt: 1 }}>
                        <span className="font-bold">Overview</span>
                        <div dangerouslySetInnerHTML={{ __html: data?.questionnaireData?.purpose }} />
                    </Typography> : ''}
                    <FormRender data={data} />
                    {data?.questionnaireData && data?.questionnaireData?.description !== `<p></p>` ? <Typography sx={{ px: 2, mt: 1 }}>
                        {/* <span className="font-bold">Method</span> */}
                        <div dangerouslySetInnerHTML={{ __html: data?.questionnaireData?.description }} />
                    </Typography> : ''}
                </Box>
            </div>
        </Box>
    )
}