import React from 'react'
import Workhours from '../AdminOrganization/Workhours'
import WorkhoursAmenities from './WorkhoursAmenities'

const TimeAnimities = ({changeTab,close,handleSubmitAmenities}) => {
  return (
    // <div style={{height: "410px", overflow:"auto" }}>

      // <WorkhoursAmenities  close={changeTab}/>
      <WorkhoursAmenities  close={close} changeTab={changeTab} handleSubmitAmenities={handleSubmitAmenities}/>

  )
}

export default TimeAnimities