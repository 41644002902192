
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";




const Video = ({ docFeed }) => {
    // console.log(docFeed, "vamm")

    const videoUrl = docFeed?.sourceLink?.replace(/,\s*$/, "");
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        // setExpanded(true);
    };
    return (
        <Box>

            <div>
                {
                    docFeed.description?.startsWith("https") ?

                        <div style={{ padding: "0px 10px 10px" }}>

                            <a href={`${docFeed.description}`} target="_blank" className='cursor' style={{ color: "#007dcd", textDecoration: "underline" }}>Link</a>
                        </div>
                        :
                        <div style={{ padding: "0px 10px", height: "50px" }}>
                            {docFeed.description?.length > 100 ? docFeed.description?.slice(0, 100) : docFeed?.description}

                            <span>
                                {
                                    docFeed?.description?.length > 100 && <Typography
                                        component={"span"}
                                        sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                                    >
                                        {expanded ? "...see less" : "...see more"}{" "}
                                    </Typography>
                                }
                            </span>
                        </div>

                }


            </div>

            <div style={{ height: "290px" }}>

                <video
                    controls

                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                >
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Box>
    );

}
export default Video;