import { FormControlLabel, Radio, Grid } from "@mui/material"
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React, { useEffect, useState } from "react";
import DateTimePicker from '@mui/lab/DateTimePicker';
import TimePicker from '@mui/lab/TimePicker';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
// import CheckboxValidator from "./CheckboxValidator";
import RadioValidator from "./RadioValidator";
import CheckboxValidator from "./CheckboxValidator";

const DefaultInput = (props) => {
    return <TextValidator sx={{ width: { sm: '300px', xs: '100%' } }}
        fullWidth
        variant="standard"
        value={props.value}
        {...props.params}
        {...props}
    />
}

export const DateInput = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                // label='Date Of Accomplishment'
                // value={date}
                // onChange={(value) => setDate(value)}
                {...props}
                // inputFormat="dd/MM/yyyy"
                format="DD/MM/YYYY"
                renderInput={(params) => {
                    return (
                        <DefaultInput params={params} {...props} value={params?.inputProps?.value} />
                    )
                }

                }
            />
        </LocalizationProvider>
    )
}

export const Time = (props) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                // value={date}
                // onChange={(value) => setDate(value)}
                // minDate={new Date(state.fromDate)}
                // inputFormat="dd-MM-yyyy hh:mm a"
                {...props}
                renderInput={(params) =>
                    <DefaultInput params={params} {...props} value={params?.inputProps?.value} />
                }
            />
        </LocalizationProvider>
    )
}

export const DateTime = (props) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                // value={date}
                // onChange={(value) => setDate(value)}
                // minDate={new Date(state.fromDate)}
                {...props}
                inputFormat="dd-MM-yyyy hh:mm a"
                renderInput={(params) =>
                    <DefaultInput params={params} {...props} value={params?.inputProps?.value} />
                }
            />
        </LocalizationProvider>
    )
}

const NumberInput = (props) => {
    return (
        <DefaultInput type="number" onWheel={event => event.target?.blur()} {...props} />
    )
}

export const Integer = (props) => {
    return (
        <NumberInput placeholder="0" {...props} />
    )
}

export const Decimal = (props) => {
    return (
        <NumberInput placeholder="0.00" {...props}
            InputProps={{
                inputProps: {
                    step: 0.01
                }
            }} />
    )
}

export const String = (props) => {
    return (
        <TextValidator variant="standard" {...props} helperText={<span style={{ float: 'right' }} placeholder='Your text here'>0/75</span>} />
    )
}

export const Text = (props) => {
    return (
        <TextValidator multiline rows={4} {...props} helperText={<span style={{ float: 'right' }}>0/300</span>} placeholder='Your text here' />
    )
}

export const CheckboxView = (props) => {

    const [values, setValues] = useState([])
    const [val, setVal] = useState(props.value !== null ? props?.value : [])

    useEffect(() => {
        ValidatorForm.addValidationRule('check', (value) => {
            let data = [];
            value.map(check => check?.checked ? data?.push(check?.checked) : '')
            if (data?.includes(true)) {
                return true;
            }
        });
    }, [])

    useEffect(() => {
        let data = props?.options?.map((option, i) => ({ ...option, checked: false }))
        setValues(data)
    }, [props?.options])

    useEffect(() => {
        props?.onChange(val?.map(value => value?.value))
    }, [val])

    const handleChange = (checked, i, value) => {
        let form = [...values]
        form = form.map((val, index) => i === index ? { ...val, checked: checked } : { ...val })
        if (checked) {
            setVal([...val, { checked: checked, id: i, value: value }])
        }
        else {
            let a = [...val]
            a = a?.filter((val) => val.id !== i)
            setVal(a)
        }
        setValues(form)
    }

    return (
        <CheckboxValidator options={values} handleChange={handleChange} value={val} validators={props.required ? ['required', 'check'] : []}
            errorMessages={props.required ? ['Please Select an option', 'Please Select an option'] : []} />
    );
}

export const RadioButton = (props) => {

    return (
        <RadioValidator options={props?.options} {...props}>
            <Grid container>
                {props?.options?.map((option, i) => (
                    <React.Fragment key={i}>{!props.row &&
                        <Grid item lg={props?.options?.length > 4 ? 4 : 12} sm={props?.options?.length > 4 ? 6 : 12} xs={12}>
                            <FormControlLabel sx={{ '& span': { fontSize: '15px' } }} key={i} value={option?.valueCoding?.display} control={<Radio />} label={option?.valueCoding?.display} />
                        </Grid>}
                        {props?.row && <FormControlLabel sx={{ '& span': { fontSize: '15px' } }} key={i} value={option?.valueCoding?.display} control={<Radio />} label={option?.valueCoding?.display} />}
                    </React.Fragment>
                ))}
            </Grid>
        </RadioValidator>
    )
}
