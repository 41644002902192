import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import StethscopeImg from '../../../../../images/stethoscope1.svg'
import { DOC_API_WITHOUT_TOKEN, D_API, D_API_FHIR_WITHOUT_TOKEN } from '../../../../../services/APIService';
import URL from '../../../../apiurl';
import { useParams } from 'react-router-dom';


const PublicServices = () => {
  const [showAll, setShowAll] = useState(true)
  const [checkedItems, setCheckedItems] = useState([]); // object of checked checkboxes
  const [allServices, setAllServices] = useState([])
  const [selected, setSelected] = useState(null)
  const [filteredItems, setFilteredItems] = useState([])
  const [allspecialty, setAllSpeciality] = useState([])
  const [withOutCheckGetData, setWithOutCheckGetData] = useState([])
  const [withCheckGetData, setWithCheckGetData] = useState([])
  const [ibmOrgdata, setIBMOrgdata] = useState()
  const userOrg = useSelector(state => state.userProfile.publicorgdata)
  const orgData = useSelector(state => state.registration.userOrg)
  console.log(userOrg, "rfff", orgData)
  const location = useSelector((state) => state.registration.location_withouttoken)
  const dispatch = useDispatch()
  const { id } = useParams();

  useEffect(() => {
    defaultSpecialtyVal()
  }, [])

  useEffect(() => {
    if (showAll) {
      setFilteredItems(allspecialty)
    } else {
      setFilteredItems([])
    }
  }, [showAll])


  useEffect(() => {
    // let data = allspecialty?.length > 0 && allspecialtyTrue ? allspecialty : allhealthCare
    // let data = allspecialty
    // let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : allspecialty    
    // let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : withOutCheckGetData.length == 0 && checkedItems.length !== 0  ? [] : allspecialty
    // let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : withOutCheckGetData.length == 0 && checkedItems.length !== 0  ? [] : allspecialty
    let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : withOutCheckGetData.length == 0 && checkedItems.length !== 0 ? [] : []
    if (selected == null) {
      data = allspecialty
    }

    // if(selected == null && checkedItems.length == 0){
    //   setFilteredItems([])
    //   return
    // }

    console.log(data, allspecialty, 'ppppp')

    if (checkedItems.length == 0) {
      setFilteredItems(data)
    } else {
      const filteredItems = data?.filter((item) => {
        return checkedItems.includes(item?.resource?.specialty?.[0]?.coding?.[0]?.code);

      });
      setFilteredItems(filteredItems)
    }
  }, [checkedItems, withCheckGetData, withOutCheckGetData, allspecialty])

  console.log(filteredItems, "file")


  useEffect(() => {
    if (selected) {
      getServiceCode(selected.id)
      console.log("hey")
    } else if (showAll) {

    }
  }, [checkedItems])

  useEffect(() => {
    // below code comment while using /v1 flow
    D_API.get(`${orgData?.id}/Organization/without_token/identifier/pageId/${id}`).then((res) => {
      setIBMOrgdata(res.data?.entry?.[0]?.resource)
    }).catch((err) => console.log(err))

    // below code uncomment while using /v1 flow
    // axios.get(`${URL.getbyPageId}${userOrg?.id}`, headers1())
    //   .then((res) => {
    //     setIBMOrgdata(res.data?.entry?.[0]?.resource)
    //   }).catch((err) => console.log(err))
  }, [])

  const headers1 = () => {
    return (
      {
        headers: {
          'X-FHIR-TENANT-ID': 'docsteth',
          'X-FHIR-DSID': 'fhirDocsteth',
          'Realm': 'docsteth'
        }
      }
    )
  }

  console.log(userOrg)

  useEffect(() => {
    D_API_FHIR_WITHOUT_TOKEN.get(`Location?partof=${location.id}`, headers1()).then(res => {
      // console.log(res.data.id, "llllllll1gfrftghjkl");
      // console.log(res.data?.entry?.[0]?.resource?.id, "5656")
      dispatch({ type: "Level_TWO_ID", payload: res.data?.entry?.[0]?.resource?.id ? res.data?.entry?.[0]?.resource?.id : "" })
      setAllServices(res.data.entry?.[0] ? [{ ...res.data.entry?.[0] }] : [])
    })

  }, [location])

  // console.log(allServices, "allServices")

  useEffect(() => {
    // D_API_FHIR_WITHOUT_TOKEN.get(`PractitionerRole?organization=${userOrg?.id}`, headers1()).then((res) => {
    //   console.log(res)
    // }).catch((err) => console.log(err))
    if (ibmOrgdata) {
      DOC_API_WITHOUT_TOKEN.get(`GetListOfApprovedWithoutToken/Practitioner/${ibmOrgdata?.id}`, headers1()).then((res) => {
        console.log(res)
      }).catch((err) => console.log(err))
    }
  }, [])

  const handleChange = (event) => {
    const value = event.target.value;
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value))
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  const getServiceCode = async (l2id) => {
    if (checkedItems.length == 0) {
      setFilteredItems([])
      await D_API_FHIR_WITHOUT_TOKEN.get(`/HealthcareService?coverage-area=${l2id}`, headers1())
        .then(res => {
          console.log(res.data.entry, "response new")
          let data = res.data?.entry ? res.data?.entry : []
          console.log(data, "getServiceCode function res one")
          setWithOutCheckGetData(data)
        })
        .catch(err => {
          console.log(err)
        })
    }
    else {
      let array = []
      setFilteredItems([])
      for (let i = 0; i < checkedItems.length; i++) {
        await D_API_FHIR_WITHOUT_TOKEN.get(`/HealthcareService?specialty=${checkedItems[i]}&coverage-area=${l2id}`, headers1())
          .then(res => {
            console.log(res.data.entry, "response new")
            let data = res.data?.entry ? res.data?.entry : []
            console.log(data, "getServiceCode function res")
            if (data[0]) {
              array.push(data[0])
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      console.log(array, "push")
      setWithCheckGetData(array)
    }
  }

  const handleSelect = (item) => {
    console.log(item, '890-', selected === item)
    setFilteredItems([])
    setSelected(null)
    setShowAll(false)

    getServiceCode(item.id)
    setSelected(item)

    // if (!selected) {
    //   setSelected(item)
    //   getServiceCode(item.id)
    // } else {
    //   setSelected(null)
    // }
  }

  const handelAll = (() => {
    setShowAll(true)
    setSelected(null)
  })

  const defaultSpecialtyVal = async () => {
    console.log(userOrg, "185");
    let resdata;



    // below code comment while using /v1 flow
    D_API.get(`${orgData?.id}/Organization/without_token/identifier/pageId/${id}`).then((res) => {
      D_API_FHIR_WITHOUT_TOKEN.get(
        `HealthcareService?organization=${res.data?.entry?.[0]?.resource?.id}`,
        headers1()
      )
        .then((res) => {
          resdata = res?.data?.entry;
          setAllSpeciality(res?.data?.entry);
          console.log(resdata, "hhhhhhl");
        })
        .catch((err) => {
          console.log(err);
        });
    }).catch((err) => console.log(err))


    // below code uncomment while using /v1 flow
    // axios
    //   .get(`${URL.getbyPageId}${userOrg?.id}`, headers1())
    //   .then((res) => {
    //     console.log(res.data?.entry?.[0]?.resource, "281");
    //     D_API_FHIR_WITHOUT_TOKEN.get(
    //       `HealthcareService?organization=${res.data?.entry?.[0]?.resource?.id}`,
    //       headers1()
    //     )
    //       .then((res) => {
    //         resdata = res?.data?.entry;
    //         setAllSpeciality(res?.data?.entry);
    //         console.log(resdata, "hhhhhhl");
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err, "PagesByIdWithoutKeycloak");
    //   });


  };


  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={3}>
        <Card sx={{ padding: '1rem', boxShadow: 3 }}>
          <Typography variant='h6' sx={{ color: '#750083', display: 'flex', alignItems: 'center', margin: '12px 0' }}><img src={StethscopeImg} alt="error" />&nbsp;&nbsp;Specialities</Typography>

          <FormGroup>
            {allspecialty?.map((item) => {
              return <Tooltip title={item?.resource?.specialty?.[0]?.coding?.[0]?.display}>
                <FormControlLabel className='text-ellipsis'
                  control={<Checkbox checked={checkedItems.item} onChange={handleChange} value={item?.resource?.specialty?.[0]?.coding?.[0]?.code} />}
                  label={item?.resource?.specialty?.[0]?.coding?.[0]?.display}
                />
              </Tooltip>
            })}
          </FormGroup>

        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={9}>
        <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>&nbsp;Service Areas</Typography>
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>

          <Button onClick={() => handelAll()} variant={showAll ? "contained" : 'outlined'} sx={{ boxShadow: 3, borderRadius: '20px', textTransform: 'capitalize', color: showAll ? "white" : "black", border: '.5px solid #EBEBEB', padding: "4px 10px !important" }}>all</Button>

          {allServices?.map((item) => {
            return <Button onClick={() => handleSelect(item?.resource)} variant={item?.resource?.id == selected?.id ? "contained" : 'outlined'} sx={{ boxShadow: 3, borderRadius: '20px', textTransform: 'capitalize', color: item?.resource?.id == selected?.id ? 'white' : 'black', border: '.5px solid #EBEBEB', padding: "4px 10px !important" }}>{item?.resource?.name}</Button>
          })}

        </div>
        <Typography variant='h6' sx={{ color: '#750083', display: 'flex', alignItems: 'center', margin: '12px 0' }}>&nbsp;&nbsp;Medical Service</Typography>
        <Grid container item spacing={2}>
          {filteredItems?.map((item) => {
            return (<Grid xs={12} sm={12} item md={6}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', gap: '15px' }}>
                      <Typography variant='h6' sx={{ fontWeight: '500' }} mb={1}>{item?.resource?.name}</Typography>
                      <Typography sx={{ display: 'flex', alignItems: 'center' }}><TaskAltIcon fontSize='small' />&nbsp;Active</Typography>
                    </div>
                  </div>
                  <Typography sx={{ fontWeight: '500' }} variant='body1'  >{item?.resource?.category?.[0]?.coding?.[0]?.display}</Typography>
                  <Typography sx={{ color: '#5C5C5C' }}>{item?.resource?.specialty?.[0]?.coding?.[0]?.display}</Typography>

                  <Typography sx={{ fontWeight: '500' }} variant='body1' my={1}>{item?.resource?.name}</Typography>
                  <Typography sx={{ color: '#5C5C5C' }}>Eligibility</Typography>

                </CardContent>
                <div style={{ display: 'flex' }}>
                  <Button sx={{ flex: 1, borderRadius: '0', textTransform: 'capitalize', backgroundColor: "#EBF4F9", color: "#348FC6" }} variant='contained'>Learn more</Button>
                  <Button sx={{ flex: 1, borderRadius: '0', textTransform: 'capitalize' }} variant='contained'>Request Appointment</Button>
                </div>
              </Card>
            </Grid>)
          })}

        </Grid>
        <Typography variant='h6' sx={{ color: '#750083', display: 'flex', alignItems: 'center', margin: '12px 0' }}>&nbsp;&nbsp;Practitioners</Typography>
        {/* 
        <Card sx={{ maxWidth: "300px", display: 'flex', justifyContent: 'space-between', borderRadius: '0' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '10px' }}>
            <Box sx={{ boxShadow: 4, width: '50px', height: '50px' }}>
              <img style={{ borderRadius: '4px', border: '2px solid white' }} width="48" height="48" src="https://images.unsplash.com/photo-1680462155279-4d73bdbf46ba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60" />
            </Box>
            <div>
              <Typography variant='body1' sx={{ fontWeight: '500' }}>Dr Name</Typography>
              <Typography variant='body2'>mbbs</Typography>
              <Typography variant='body2'>role</Typography>
            </div>
          </div>
          <div style={{ background: '#7D288B', display: 'flex', padding: '5px', justifyContent: 'center', alignItems: 'center' }}>
            <ArrowForwardIosIcon sx={{ color: 'white' }} />
          </div>
        </Card> */}
      </Grid>
    </Grid>
  )
}

export default PublicServices