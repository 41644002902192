import React from 'react'
import DoseDetailsRightSide from './DoseDetailsRightSide'
// import DoseDetailsSideBar from './DoseDetailsSideBar'

function DoseDetails() {
    return (
        // <Grid container spacing={2} style={{padding:'14px'}}>
        //     <Grid item lg={2} md={4} sm={12} style={{width:"100%"}}>
        //         {/* <DoseDetailsSideBar/> */}
        //     </Grid>
        // <Grid item lg={10} md={8} sm={12}>
        <DoseDetailsRightSide />
        // </Grid>
        // </Grid>
    )
}

export default DoseDetails
