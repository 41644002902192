import React, { useEffect, useState } from 'react'
import { Typography, Box, Divider, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './HeaderBox.css'



const useStyles = makeStyles({
    paper: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '4px',
        opacity: 1,
        color: '#0054C7',
    },
    pos: {
        "& .css-6hp17o-MuiList-root-MuiMenu-list": {
            paddingTop: "0px",
            paddingBottom: "0px",
            width: "125px"
        },
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    parent: {
        position: "relative"
    }
});

const CustomButton = (props) => {
    return (
        <Button className="add-option" sx={{ mt: '0px!important', mr: 2, fontWeight: '550!important', padding: '5px 12px!important' }} variant="outlined">{props.children}&nbsp;{props.name}</Button>
    )
}

const CustomRow = (props) => {
    return (
        <Typography component="div" sx={{ px: 2, pt: 2 }} >
            <Typography component="p" sx={{ pb: 1 }} className="e-align d-flex">
                {props.children}
            </Typography>
            {props.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
        </Typography>
    )
}

function Tabprofile({ active, handleActive, myreloadingFn, profileData, state, request, requestAcceptFn, connectBtnFun, connectionStatusFn, handleClose, blockUser, unblock, Item }) {
    const [modal, setModal] = useState({ open: false, name: '' })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open1, setOpen1] = React.useState(false);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const title = (profileData === undefined) ? " " : profileData?.user?.fullName;
    const url1 = (profileData === undefined) ? " " : `https://www.docsteth.com/profile/${profileData?.user?.fullName}-${profileData?.user?.id}`;
    const image = (profileData === undefined) ? " " : profileData?.user?.hasOwnProperty("practioner") ? profileData?.user?.practioner?.photo?.[0]?.url : "";
    const description = (profileData === undefined) ? " " : profileData?.user?.about

    // useEffect(() => {
    //     request()
    // }, [])

    return (
        <div className='border_img1'>
            <div className="profile__link1" style={{ position: "relative" }}>
                {profileData?.blocked ? '' :
                    <div className="links">
                        <ul style={{ paddingLeft: "10px" }}>
                            <li className={`${active == 0 ? 'profile-active' : ''}`} onClick={() => handleActive('0')}>Overview</li>
                            <li className={`${active == 1 ? 'profile-active' : ''}`} onClick={() => handleActive('1')}>Timeline</li>
                            <li className={`${active == 2 ? 'profile-active' : ''}`} onClick={() => handleActive('2')}>Publication</li>
                            {/* <li className={`${active == 3 ? 'profile-active' : ''}`} onClick={() => handleActive('3')}>Bookmarks</li> */}
                        </ul>
                    </div>}
            </div>
        </div >
    )
}

export default Tabprofile