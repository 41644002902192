
import React, { useState, useEffect } from 'react'
import { Avatar, Grid, CircularProgress, Card, FormControlLabel, Snackbar, IconButton } from '@mui/material';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import axios from 'axios';
import { FormDivider } from '../../../../utility/utils';
import { Actions, FormLayout, Purpose } from '../../../../common/components/layout';
import { useDispatch, useSelector } from "react-redux";
import { DOC_API, D_API, TERMINOLOGY_VALUESET } from "../../../../../../src/services/APIService";
import { deleteKeys, getLanguages } from '../../../../common/functions/functions';
import { handleHealthServiceOrgInfo, getLocation } from "../../../../../../src/redux/Actions/regActions";
import { headers } from '../../../../../redux/Actions/connectActions';
import URL from "../../../../apiurl";
import { useParams, useHistory } from 'react-router-dom';
import { URI } from '../../../../utility/uri';
import EditIcon from '@mui/icons-material/Edit';
import "./healthserviceDept.css";
import DefaultHealthServiceIcon from "../../../../../icons/Healthcare_Service.svg";
import UserService from '../../../../../services/UserService';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import DialogBox from '../../../../DialogBox/dialog';
import { CloseBtn } from '../../../LinkAccount/linkaccount';
import CloseIcon from '@mui/icons-material/Close';
import DeleteHealthServiceDialog from './deleteHealthserviceDialog';

const communicateList = ["Telugu", "English", "Hindi"]

const HealthServiceInfo = ({ clsn, switchTabs }) => {


    const parentOrgInfo = useSelector((state) => state.registration.userOrgData);
    const parentOrgLocInfo = useSelector(state => state.registration.location);

    // let orgData = useSelector(state => state.registration.userOrgData);
    // const orgLoc = useSelector(state => state.registration.location);

    const healthServiceLoc = useSelector(state => state.registration.healthServiceLocInfo);
    console.log(healthServiceLoc, "healthServiceLoc")

    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    console.log(healthServiceOrg, "healthServiceOrg")

    console.log(parentOrgInfo, "61")
    console.log(parentOrgLocInfo, "62")


    const [healthServiceDetails, setHealthServiceDetails] = useState({
        serviceName: "",
        alias: "",
        communicate: [],
        description: "",
        referType: "false"
        // programsInfo: "",
    })

    // console.log(healthServiceDetails, "healthServiceDetails")
    const [appointmentReq, setAppointmentReq] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState({});
    const [url, setUrl] = useState('')
    const [uploadLoader, setUploadLoader] = useState({ open: false, msg: '' });
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [toUpdateKey, setToUpdateKey] = useState("");
    const [programsList, setProgramsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [contact, setContact] = useState([]);
    const [languagesList, setLanguagesList] = useState([]);
    const [openDeleteServiceDialog, setOpenDeleteServiceDialog] = useState(false);
    const [lengthOfServices, setLengthOfServices] = useState("0");

    const [alert, setAlert] = useState({ open: false, msg: '' })


    const { id } = useParams();
    // console.log(uploadedLogo, "uploadedLogo")

    const dispatch = useDispatch();
    const history = useHistory();
    // useEffect(() => {
    //     if (healthServiceLoc?.id) {
    //         setHealthServiceDetails((prev) => {
    //             return {
    //                 ...prev, serviceName: healthServiceLoc?.name ? healthServiceLoc?.name : "",

    //             }
    //         })
    //     }
    // }, [])



    useEffect(() => {
        axios.get("https://devfhir.docsteth.com/fhir-server/api/v4/ValueSet/languages").then((res) => {
            // return res.data.compose?.include?.[0]?.concept;
            setLanguagesList(res?.data?.compose?.include?.[0]?.concept)
        })
    }, [])
    // console.log(languagesList, "getLanguages")
    useEffect(() => {
        let val = "http://hl7.org/fhir/ValueSet/program"
        TERMINOLOGY_VALUESET.get(`$expand?url=${val}`).then((res) => {
            setProgramsList(res.data?.expansion?.contains)
        }).catch((err) => console.log(err))
    }, [])



    useEffect(() => {
        if (parentOrgInfo?.id) {
            D_API.get(`${parentOrgInfo?.id}/${URI.getHealthCareServiceList}${parentOrgInfo?.id}`).then((res) => {
                if (res.data?.entry) {
                    setLengthOfServices(res?.data?.entry?.length?.toString());
                } else {
                    setLengthOfServices("0");
                }
            }).catch((err) => console.log(err));
        }

    }, [parentOrgInfo?.id])

    useEffect(() => {
        if (healthServiceOrg?.id) {
            setHealthServiceDetails((prev) => {
                return {
                    ...prev,
                    serviceName: healthServiceOrg?.name ? healthServiceOrg?.name : "",
                    description: healthServiceOrg?.extraDetails ? healthServiceOrg?.extraDetails : "",
                    // programsInfo: healthServiceOrg?.program?.[0]?.coding?.[0]?.display ? healthServiceOrg?.program?.[0]?.coding?.[0] : "",
                    alias: healthServiceOrg?.comment ? healthServiceOrg?.comment : "",
                    referType: healthServiceOrg?.referralMethod ? "true" : "false",
                    communicate: healthServiceOrg?.communication ? healthServiceOrg?.communication?.map((e) => {
                        return { ...e?.coding?.[0] }
                    }) : []

                }
            })
            setUploadedLogo(healthServiceOrg?.photo?.url ? healthServiceOrg?.photo?.url : null);

            setContact(healthServiceOrg?.telecom ? healthServiceOrg?.telecom : []);
            setAppointmentReq(healthServiceOrg?.appointmentRequired && healthServiceOrg?.appointmentRequired);

        }
    }, [healthServiceOrg?.id])


    useEffect(() => {
        if (parentOrgInfo?.id) {
            dispatch(getLocation(parentOrgInfo?.id))
        }
    }, [parentOrgInfo?.id])






    const handleServiceLogo = (e, fileType, state, name) => {
        // console.log(e.target.files[0], "iiii");
        if (e.target.files?.length > 0) {
            let a = [];
            // let b = [];
            let c = [];
            for (let i = 0; i < e.target.files.length; i++) {
                let types = e.target.files[0].type.split("/")[1]
                const eTypes = types === 'jpg' || types === "png" || types == "jpeg"
                const imgTypes = eTypes || types == "gif"

                if (fileType == "photo") {
                    const reader = new FileReader()
                    // console.log(reader, "reader")
                    reader.readAsDataURL(e.target.files[0]);

                    reader.onloadend = () => {
                        // console.log(reader.result, "result")
                        a.push(reader.result)
                        let base64 = reader.result.split(",")[1].split("=")[0]
                        var strLength = base64.length;
                        var fileLength = strLength - (strLength / 8) * 2;
                        const [, type] = reader.result.split(";")[0].split('/');
                        c.push(type)
                        // setCoverPhoto({ file: base64, fileType: type })
                        // setCoverPhoto((prev) => {
                        //     return [...prev, { file: base64, fileType: type }]
                        // })
                        setCoverPhoto({ file: base64, fileType: type })
                        fileUpload(base64, type, state == "coverpic" ? 'userCoverPhoto' : 'profilePic', name)
                    }
                }
                else {
                    setUploadLoader({ open: true, msg: 'Only .jpg, .png, .jpeg, .gif extensions allowed' })
                }
            }

        }
    }

    // console.log(coverPhoto, "coverphoto")

    const fileUpload = (file, type, subType, name) => {
        // setUploadLoader({ open: true, msg: "uploading ..." })
        setIsLoading(true);

        let data = {
            "file": [file],
            "fileType": type,
            "multiple": false,
            "subType": subType,
            "type": "userProfilePics"
        }
        // console.log(URL.upload, "upload");
        axios.post(`${URL.uploadFile}`, data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            // console.log(res.data.data[0], "success")
            setUploadedLogo(res.data.data[0]);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false)
            alert("uploading failed, try again.")
        })

    }


    const handleServiceDetails = (name, value) => {
        setHealthServiceDetails((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const handleAppointment = () => {
        setAppointmentReq((prev) => !prev);
    }


    function adjustRows() {
        const textarea = document.getElementById('myTextarea');
        const rows = textarea.value.split('\n').length;
        textarea.rows = rows;
    }



    const addContact = (system) => (use) => {
        setContact([...contact, { system: system, value: "", use: use }]);
    };

    const deleteContact = (index) => () => {
        setContact([...contact.filter((con, i) => index !== i)]);
    };


    const handleContact = (i) => (name, value) => {
        setContact([
            ...contact?.map((con, ind) =>
                i === ind ? { ...con, [name]: value } : { ...con }
            ),
        ]);
    };


    const handleCloseAlert = () => {
        setAlert((prev) => {
            return {
                ...prev, open: false, msg: ""
            }
        })
    }

    const handleSubmit = () => {


        let data = {
            resourceType: "HealthcareService",
            ...(healthServiceOrg?.id && { id: healthServiceOrg?.id }),
            ...(healthServiceOrg?.identifier && { identifier: healthServiceOrg?.identifier }),
            // identifier: [
            //     { system: "https://pageId/", value: id }
            // ].filter((e) => e != null || "" || undefined),
            name: healthServiceDetails?.serviceName ? healthServiceDetails?.serviceName : "",
            comment: healthServiceDetails?.alias ? healthServiceDetails?.alias : "",
            ...(parentOrgInfo?.id && { providedBy: { "reference": `${"Organization" + "/" + parentOrgInfo?.id}` } }),
            ...(parentOrgLocInfo?.id && { coverageArea: [{ "reference": `${"Location" + "/" + parentOrgLocInfo?.id}` }] }),
            // ...(healthServiceLoc?.id && { location: [{ "reference": `${"Location" + "/" + healthServiceLoc?.id}` }] }),
            ...(healthServiceOrg?.id && healthServiceOrg?.Location && { location: healthServiceOrg?.Location?.map((e) => { "reference"`:${"Location" + "/" + e?.reference?.split("/")?.[1]}` }) }),
            extraDetails: healthServiceDetails?.description ? healthServiceDetails?.description : "",
            // ...(healthServiceDetails?.programsInfo && { program: [{ coding: [{ ...healthServiceDetails?.programsInfo }] }] }),
            // ...(healthServiceLoc?.telecom && { telecom: healthServiceLoc?.telecom }),
            ...(uploadedLogo && { photo: { url: uploadedLogo } }),
            telecom: contact.filter((con) => con.value?.replace(/\+91/, "") !== ""),
            ...(healthServiceOrg?.category && { category: healthServiceOrg?.category }),
            ...(healthServiceOrg?.specialty && { specialty: healthServiceOrg?.specialty }),
            ...(healthServiceOrg?.characteristic && { characteristic: healthServiceOrg?.characteristic }),
            ...(healthServiceOrg?.eligibility && { eligibility: healthServiceOrg?.eligibility }),
            ...(healthServiceOrg?.type && { type: healthServiceOrg?.type }),
            ...(healthServiceOrg?.program && { program: healthServiceOrg?.program }),
            ...(healthServiceOrg?.availableTime && { availableTime: healthServiceOrg?.availableTime }),
            ...(healthServiceDetails.referType == "true" && {
                referralMethod: [{
                    coding: [{
                        "code": "elec",
                        "display": "Secure Messaging",
                        "system": "http://terminology.hl7.org/CodeSystem/service-referral-method",
                    }]

                }]
            }),
            appointmentRequired: appointmentReq == false ? false : true,
            communication: healthServiceDetails?.communicate ? healthServiceDetails?.communicate?.map((e) => {
                return {
                    coding: [
                        { ...e, "system": "urn:ietf:bcp:47" }
                    ]
                }
            }) : []

        }
        let website = data?.endpoint?.[0]?.display;
        let val = deleteKeys(data);

        // dispatch(handleHealthServiceOrgInfo(val));

        // console.log(val, "val")
        if (healthServiceOrg?.id) {
            D_API.put(`${parentOrgInfo?.id}/${URI.updateHealthServiceDept}/${healthServiceOrg?.id}`, val).then((res) => {
                // console.log(res.data, "7")
                D_API.get(`${parentOrgInfo?.id}/${URI.getHealthCareService}/${res.data}`).then((res) => {
                    dispatch(handleHealthServiceOrgInfo(res.data));
                    switchTabs();
                    console.log(res.data)
                }).catch((err) => console.log(err));

            }).catch((err) => console.log(err));
        } else {
            // test mode
            // D_API.post(URI.uploadHealthServiceDept, val).then((res) => {
            //     console.log(res.data, "after post")
            //     D_API.get(`${URI.getHealthCareService}/${res.data}`).then((res) => {
            //         dispatch(handleHealthServiceOrgInfo(res.data));
            //         switchTabs();
            //         console.log(res.data)
            //     }).catch((err) => console.log(err));

            // }).catch(err => {
            //     alert(err)
            // })

            let orgPage = {
                organisation: {
                    ...val,

                },
                pageName: val.name,
                "pageAdmin": `${UserService.getUserId()}`,
                // "pageStatus": "ACTIVE",
                "pageStatusUpdatedDate": new Date().toISOString(),
                // "pageWebsite": "",
                "kycStatus": "PENDING",
                "date": new Date().toISOString(),
                "metadata": {},
                "status": true,
                "userId": Number(UserService.getUserId())
            }

            DOC_API.post(URI.addSubPage, orgPage).then(async res => {
                let response = { ...res.data.data.organsation }
                console.log(res.data, "zzz")

                // setPageId(res.data.data.id)
                let pageId = res.data.data.id;
                let page = {
                    ...orgPage.organisation, identifier: [
                        // ...(orgPage?.identifier?.[0]?.hasOwnProperty("value") ? [{ ...orgPage.identifier[0] }] : []),
                        {
                            system: `${process.env.REACT_APP_DOCSTETH}/`,
                            value: `${UserService.getUserId()}`
                        }, {
                            system: "https://pageId/",
                            value: `${res.data.data.id}`
                        },
                        {
                            system: "https://positionofservices/", value: (parseInt(lengthOfServices))?.toString()
                        }

                    ]

                }

                if (website) {
                    page.telecom = [...orgPage.organisation.telecom, { system: 'other', value: website }]
                }

                D_API.post(`${parentOrgInfo?.id}/${URI.uploadHealthServiceDept}`, page).then((res) => {
                    console.log(res.data, "after post")
                    D_API.get(`${parentOrgInfo?.id}/${URI.getHealthCareService}/${res.data}`).then((res) => {
                        dispatch(handleHealthServiceOrgInfo(res.data));
                        switchTabs();
                        console.log(res.data)
                    }).catch((err) => console.log(err));

                }).catch(err => {
                    alert(err)
                })

            }).catch((err) => {
                if (err?.response?.status == 409) {
                    setAlert((prev) => {
                        return {
                            ...prev, open: true, msg: "Page name already exists"
                        }
                    })

                }
            })


        }

    }

    const handleOpnHealthDeleteDialog = () => {
        setOpenDeleteServiceDialog(true)
    }

    const handleClsHealthDeleteDialog = () => {
        setOpenDeleteServiceDialog(false)
    }

    const deleteOrgFn = () => {
        handleOpnHealthDeleteDialog()
    }


    //     if (healthServiceOrg.id) {
    //         if (healthServiceLoc?.id && (healthServiceLoc?.managingOrganization?.reference?.split("/")?.[1] != undefined || null || "")) {
    //             D_API.delete(`${URI.addLocation}/${healthServiceLoc?.id}/${healthServiceLoc?.managingOrganization?.reference?.split("/")?.[1]}`).then((res) => {
    //                 if (healthServiceOrg?.id && healthServiceOrg?.identifier?.[1]?.value) {
    //                     axios.delete(`${process.env.REACT_APP_API_URL}v1/deletePagesById/${healthServiceOrg?.identifier?.[1]?.value}`, headers()).then((res) => {
    //                         if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
    //                             D_API.delete(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
    //                                 clsn();
    //                                 history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`)
    //                                 console.log(res.data)
    //                             }).catch((err) => console.log(err))
    //                         }
    //                     })
    //                 } else if (healthServiceOrg?.id && healthServiceOrg?.identifier?.[1]?.value) {
    //                     axios.delete(`${process.env.REACT_APP_API_URL}v1/deletePagesById/${healthServiceOrg?.identifier?.[1]?.value}`, headers()).then((res) => {
    //                         if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
    //                             D_API.delete(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
    //                                 clsn();
    //                                 history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`)
    //                                 console.log(res.data)
    //                             }).catch((err) => console.log(err))
    //                         }
    //                     })
    //                 }
    //                 else if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
    //                     D_API.delete(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
    //                         clsn();
    //                         history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`)
    //                         console.log(res.data)
    //                     }).catch((err) => console.log(err))
    //                 } else {
    //                     clsn();
    //                     history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`)
    //                 }
    //             }).catch((err) => console.log(err))
    //         }
    //         else if (healthServiceOrg?.id && healthServiceOrg?.identifier?.[1]?.value) {
    //             axios.delete(`${process.env.REACT_APP_API_URL}v1/deletePagesById/${healthServiceOrg?.identifier?.[1]?.value}`, headers()).then((res) => {
    //                 if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
    //                     D_API.delete(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
    //                         clsn();
    //                         history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`)
    //                         console.log(res.data)
    //                     }).catch((err) => console.log(err))
    //                 }
    //             })
    //         } else if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
    //             D_API.delete(`${URI.updateHealthServiceDept}/${healthServiceOrg?.id}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
    //                 clsn();
    //                 history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`)
    //                 console.log(res.data)
    //             }).catch((err) => console.log(err))
    //         } else {
    //             clsn();
    //             history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`);
    //         }

    //     } else {
    //         clsn();
    //         history.push(`/admin/organization/${parentOrgInfo?.identifier?.[1]?.value}`);
    //     }
    // }


    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseAlert}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>

            <ValidatorForm onSubmit={handleSubmit} >
                <Grid
                    container
                    spacing={2}
                    className="ph1 tohideScrollbar"
                    sx={{ height: "410px", overflow: "auto" }}
                >


                    <Grid item container sx={{ gap: 2 }}>
                        {/* 
                        <Grid item xs={12} sm={3} md={3}> */}
                        <Grid item >


                            <div className="showImg" style={{ width: "100%" }}  >
                                <div style={{ position: "absolute", top: "20px", left: "15px", zIndex: 100 }}>
                                    {
                                        isLoading && <CircularProgress size={20} sx={{ color: "white" }} />
                                    }
                                </div>

                                <Card sx={{ width: "70px", height: "70px", borderRadius: '10px 10px 10px 10px', boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "1px solid #fff", }} className="flx-center justify-center" >
                                    <Avatar variant="square" sx={{
                                        borderRadius: '', border: "", width: '50px !important', height: '50px !important', backgroundColor: isLoading ? "grey" : ""
                                        // boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "1px solid #fff",
                                    }} src={uploadedLogo ? uploadedLogo : DefaultHealthServiceIcon} />
                                </Card>
                                {/* <Avatar variant="square" sx={{
                                    borderRadius: "10px 10px 10px 10px", border: "2px solid grey", width: '50px !important', height: '50px !important', backgroundColor: isLoading ? "grey" : "",
                                    // boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "1px solid #fff",
                                }} src={uploadedLogo ? uploadedLogo : DefaultHealthServiceIcon} /> */}

                                <div className="">
                                    <>
                                        <label >
                                            <p className="editImg uploadImgBg">
                                                <EditIcon className="uploadIcon"
                                                />
                                                <span>  <input
                                                    id="file-upload"
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg"
                                                    onChange={(e) => handleServiceLogo(e, "photo", "coverpic", "coverPhoto")}
                                                    style={{ display: "none" }}
                                                /></span>
                                            </p>
                                        </label>
                                    </>

                                </div>

                            </div>
                        </Grid>

                        {/* <Grid item md={9} sm={9} xs={12} sx={{ paddingTop: "8px" }}> */}
                        <Grid item sx={{ paddingTop: "8px" }}>
                            <Grid container alignItems="center" >
                                <Grid item>
                                    <div style={{ fontSize: "14px", fontWeight: 500 }}>Provided by</div>
                                </Grid>
                                <Grid item>
                                    <div>
                                        : {parentOrgInfo?.name}
                                    </div>
                                </Grid>

                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item style={{ fontSize: "14px", fontWeight: 500 }}>
                                    <div>CoverageArea</div>
                                </Grid>
                                <Grid item>
                                    <div>
                                        : {parentOrgInfo?.name}
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>


                    <FormLayout
                        label="Name of Healthcare Service"
                        name="serviceName"
                        size={12}
                        value={healthServiceDetails.serviceName}
                        handleChange={handleServiceDetails}
                        placeholder="Name of Location"
                    />
                    <FormLayout
                        label="Tagline"
                        size={12}
                        value={healthServiceDetails.alias}
                        name="alias"
                        handleChange={handleServiceDetails}
                        placeholder="Any Other name with which this Service is known"
                    />
                    <FormLayout
                        size={12}
                        input="select"
                        value={healthServiceDetails.communicate}
                        multiple
                        label="Communication"
                        getOptionLabel={(option) => option.display || option || ""}
                        options={languagesList}
                        name="communicate"
                        handleChange={handleServiceDetails}
                        placeholder="Enter the communication"
                    />



                    {/* <Grid item xs={12} sm={12} md={12}>
                        <label>Details</label>
                        <textarea name="description" value={healthServiceDetails.description} onChange={(e) => handleServiceDetails("description", e.target.value)} placeholder="Write something here" autoFocus id="myTextarea" rows="3" onInput={() => adjustRows()} className="w-100 ss_textarea" style={{
                            boxShadow: "2px 3px 10px lightgrey",
                            borderRadius: "10px",
                            backgroundColor: "white",
                            width: "100%",
                            fontSize: "16px",
                            lineHeight: "1.5",
                            padding: "10px", 
                        }}></textarea>
                    </Grid> */}


                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6}>
                                <>
                                    <span>Appointment Required</span>
                                    <Switch
                                        checked={appointmentReq == true ? true : false}
                                        value={appointmentReq}
                                        onChange={handleAppointment}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLayout
                                    size={12}
                                    input="select"
                                    value={healthServiceDetails.referType}
                                    label="Referal Type"
                                    getOptionLabel={(option) => option || ""}
                                    options={["false", "true"]}
                                    name="referType"
                                    handleChange={handleServiceDetails}
                                    placeholder="Select Referal Type"
                                />
                            </Grid>

                        </Grid>



                    </Grid>





                    {/* <FormLayout
                        size={12}
                        input="select"
                        label="Healthcare Programs"
                        name="programsInfo"
                        value={healthServiceDetails.programsInfo}
                        options={programsList || []}
                        getOptionLabel={(option) => option?.display || ""}
                        handleChange={handleServiceDetails}
                        placeholder="Enter healthcare programs Info"
                    /> */}





                    {/* Telecom */}
                    <div style={{ paddingLeft: "16px", paddingTop: "16px", fontWeight: 500 }}>
                        Contact Details
                    </div>


                    {/* <ProfileLayout title="Contact Details" /> */}
                    {/* <div>
                    <h5>Contact Details</h5>
                </div> */}
                    <Purpose
                        input="phone"
                        name="Facility Phone"
                        contact={contact}
                        handleContact={handleContact}
                        deleteContact={deleteContact}
                        handleType={addContact("phone")}
                        options={["mobile", "work"]}
                    />


                    <Purpose
                        input="email"
                        name="Facility Email"
                        placeholder="Email Address"
                        contact={contact}
                        handleContact={handleContact}
                        deleteContact={deleteContact}
                        handleType={addContact("email")}
                        options={["work"]}
                    />




                    <Grid item xs={12} />

                </Grid>
                <FormDivider />

                <div className="flx-center justify-between">

                    <div style={{ textAlign: 'right', marginTop: "10px" }} className="cursor">
                        <DeleteIcon sx={{ color: "red" }} onClick={deleteOrgFn} />
                    </div>
                    <div>
                        <Actions close={clsn} name={healthServiceOrg?.id ? "Update" : "Create"} />
                    </div>
                </div>

                <DeleteHealthServiceDialog open={openDeleteServiceDialog} close={handleClsHealthDeleteDialog} healthServiceObj={healthServiceOrg} healthServiceLocObj={healthServiceLoc} parentOrg={parentOrgInfo} parentLoc={parentOrgLocInfo} closeEditDialog={clsn} />


            </ValidatorForm >

            {/* <DialogBox open={alert.open} handleDialog={handleCloseAlert} Title="Warning Message" Content={<p>{alert.msg}</p>} Actions={
                <>
                    <CloseBtn name="Ok" onClick={handleCloseAlert} />
                </>
            } /> */}


            <Snackbar
                open={alert.open}
                onClose={handleCloseAlert}
                message={alert.msg}
                action={action}
            />

        </div >
    )
}

export default HealthServiceInfo;