import React, { useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button, Grid } from '@mui/material';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import '../profile/profile.css'

function Patientarticle(props) {
    const [count, setCount] = useState(5)
    const history = useHistory()
    // console.log(props.location.state)
    const articledata = props?.location?.state
    // console.log(articledata)
    return (
        <Grid container>
            <Grid item md={12} xs={12} style={{ alignItems: "center", marginTop: "20px", padding: "10px 20px 10px 0px " }} className="d-flex border_img">
                <Button className='butn_arrow_back' onClick={() => { history.goBack() }}><ArrowBackIosNewIcon style={{ marginLeft: "-8px" }} /></Button>
                <p style={{ fontSize: "18px", fontWeight: "550" }}>Article</p>
            </Grid>
            <Grid item md={12} xs={12} className="border_img">
                <div className="sidebar__padding">
                    <div>
                        {articledata.sourceLink ?
                            <img className='patient_img' src={articledata.sourceLink}></img> : ""
                        }
                    </div>
                    <div>
                        {articledata.articleTitle ?
                            <p className='article_title_patient'>{articledata.articleTitle}</p> : ""
                        }
                    </div>
                    <div style={{ display: "flex" }}>
                        {articledata.coAuthors ?
                            <p style={{ padding: "8px 8px 0px 0px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: articledata.coAuthors, }} ></p> : ""
                        }
                        {articledata.contributors ?
                            <p style={{ padding: "8px 8px 0px 0px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: articledata.contributors, }} ></p> : ""
                        }
                        {articledata.organisation ?
                            <p style={{ padding: "8px 8px 0px 0px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: articledata.organisation, }} ></p> : ""
                        }
                    </div>
                    <div style={{ padding: "10px" }}>
                        {articledata.description ?
                            <p className='organisation_setinnerhtml' dangerouslySetInnerHTML={{ __html: articledata.description, }} ></p> : ""
                        }
                    </div>
                </div>
                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="sidebar__substat ta__center">
                        {articledata !== null && articledata.length > 5 ? count === 5 ? <p id="border_exp_btn" onClick={() => setCount(articledata.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default Patientarticle