import * as React from 'react';
import Suggestion from './suggestions'
import SuggMenu from './sugg-menu'
import DomainIcon from '@mui/icons-material/Domain'
import Connections from './connections';
import axios from "axios";
import URL from '../apiurl'
import UserService from '../../services/UserService';
// import Avatar from '@mui/material/Avatar'
import docimage from '../../icons/Orgnaisationprofile.svg'
import DialogBox from '../DialogBox/dialog'
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { getConnectCount } from '../../redux/Actions/connectActions';
import OrgLogo from '../../images/orglogo.svg'

export default function Organisation({ type, name1, name }) {

    const [user, setUser] = React.useState([]);
    const [myreload, setmyreload] = React.useState(false);


    const menus = ['Member', 'Employee', 'Follow']

    React.useEffect(() => {
        getOrganization()
        return () => {
            setUser([])
        }
    }, [myreload, name])

    const getOrganization = () => {
        var api;
        if (name === 'Suggested Organisations') {
            api = 'suggestedOrganization'
        }
        else if (name === 'Organisations') {
            api = 'getPages'
        }
        axios.get(`${URL[api]}/${sessionStorage.getItem("userId")}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => res.data).then(data => {
            setUser(data.data)
        }
        ).catch(err => {
            setUser([])
        })
    }

    const Join = (props) => {


        const [follow, setFollow] = React.useState(name === 'Organisations' ? 'Following' : 'Follow')
        const [open, setOpen] = React.useState(false)
        const dispatch = useDispatch()

        const followOrg = (id) => {
            let uid = UserService.getUserId()
            let data = {
                "userId": uid
            }
            axios.post(`${URL.followOrganisation}/${id}/user/${uid}`, data, {
                headers: {
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            }).then(res => {
                // console.log(res)
                if (res.data?.status === 201) {
                    getOrganization()
                    dispatch(getConnectCount())
                }
            })
        }

        const unfollow = (id) => {
            // console.log(id)
            let uid = UserService.getUserId()
            // let data = {
            //     "userId": uid
            // }
            axios.delete(`${URL.removePageFollower}/${id}/User/${uid}`, {
                headers: {
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            }).then(res => {
                // console.log(res)
                if (res.data?.status === 200) {
                    getOrganization()
                    dispatch(getConnectCount())
                }
            })
        }
        return (
            <>
                <DialogBox width="xs" open={open} handleDialog={() => setOpen(false)} Content={<p>Do you want to unfollow ?</p>}
                    Actions={<div>
                        <Button variant="contained" className="add-option" sx={{ mr: 2 }} onClick={() => unfollow(props.value)}>Yes</Button>
                        <Button variant="contained" className="add-option" onClick={() => setOpen(false)}>No</Button>
                    </div>} />
                <div className="d-flex e-align" onClick={() => follow === 'Follow' ? followOrg(props.value) : setOpen(true)}>
                    <DomainIcon style={{ fontSize: '15px' }} /> &nbsp;&nbsp; {follow}
                </div>
            </>
        )
    }

    const Image = ({ image }) => {
        return (
            <div className="my-img">
                {image ?
                    <img style={{ height: "65px", width: '65px', borderRadius: "8px" }} src={(image) ? image : docimage} />
                    :
                    <div style={{ backgroundColor: '#A8E8FE', width: '65px', height: "65px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "7px" }}>
                        <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                    </div>

                }
            </div>
        )
    }

    const Peer = (style, connections) => {
        return (
            connections.pageFollowersName && <Connections total={connections.pageFollowersName} peers={connections.mutual} />
        )
    }

    const closeCard = (id) => {
        let data = [...user]
        data = data?.filter(value =>
            value.id !== id
        )
        setUser(data)
        // console.log(id)
    }


    return (

        <Suggestion type={type} displayname={name1} closeCard={closeCard} HeaderName={name} bgColor={name === 'Organisations' ? "org-bg2" : 'org-bg'} userdata={user} Image={Image} Peer={Peer} Follow={Join} menus={menus} />
        //       {/* <SuggMenu Follow={Join} menus={menus}/>
        // </Suggestion>   */}

    );
}
