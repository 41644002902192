import React, { useState, useEffect } from 'react'
import '../../Molecule/EbmKit/EbmKit.css';
import drug_icon from '../../Atom/modifiedicons/drug_icon.svg'
import '../../Molecule/Elibrary/ElibraryRightContainer.css'
import { useRouteMatch } from 'react-router';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from '@mui/material';
import '../EbmKit/EbmkitHeader.css'
import { useHistory } from 'react-router-dom';

function DruginformationHeader() {
    const { url } = useRouteMatch();

    const [path, setPath] = useState([])
    const [com, setCom] = useState(0)
    const [link, setLink] = useState([])
    const history = useHistory()

    useEffect(() => {

        let u = url
        u = url.split('/');
        // console.log(u)
        let hpath = []

        for (let i = 2; i < u.length; i++) {
            if (u[i] !== undefined) {
                let name = u[i].charAt(0).toUpperCase() + u[i].slice(1).toLowerCase()
                let p = name.replace(/__/g, '/').replace(/_/g, ' ').replace(/--/g, '___')
                if (p.includes('-')) {
                    p = p.split('-')
                    p.pop()
                    hpath.push(p.join('-').replace(/___/g, '-'))
                }
                else {
                    hpath.push(p.replace(/___/g, '-'))
                }
            }
            setPath(hpath)
        }
        setLink([u[2], u[3], u[4], u[5]])
    }, [])

    const changePath = () => {
        let urlpath = [];
        urlpath = link.filter(l => l !== undefined)
        urlpath.pop()
        history.push(`/elib/${urlpath.toString().replace(/,/g, '/').toLowerCase()}`)
    }

    return (
        <div className="elibrary_rightcontainer_header">
            <AppBar position="static" style={{ background: '#FFFFFF', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                <Toolbar className='ebm_app_bar' style={{ boxShadow: '0px 3px 6px #00000029' }}>
                    <Grid container>
                        <Grid item className="w-100">
                            <div className='desktopview_header'>
                                <div className="elibrary_rightcontainer_icon1 w-100">
                                    <div className='smalldevices'>
                                        <Button onClick={() => changePath()}><ArrowBackIosNewIcon /></Button>
                                    </div>
                                    <img src={drug_icon} alt="app_vault" />
                                    <Typography variant="h4" color="black" className="user-data" style={{ marginLeft: '6px', fontSize: '23px' }}>
                                        <span onClick={() => { setCom(0) }} style={{ fontWeight: '600' }}><Link to="/elib/drugspecialities">Drug Information</Link></span>
                                        {path[1] ? <span className="capitalize"> {'/'}<Link className={`specialities ${path[2] || path[3] ? "" : path[1] ? 'active-link' : ''}`} to={`/elib/${link[0]}/${link[1]}`}>{path[1]}</Link></span> : ""}
                                        {path[2] ? <> / <Link style={{ textTransform: 'capitalize' }} className={`specialities ${path[3] ? 'capitalize' : path[2] ? 'active-link' : 'capitalize'}`} to={`/elib/${link[0]}/${link[1]}/${link[2]}`}>{path[2]}</Link></> : ""}
                                        {path[3] ? <> / <Link className={`specialities ${path[3] ? 'active-link' : ''}`} to={''}>{path[3]}</Link></> : ""}
                                    </Typography>
                                </div>
                            </div>
                            <div className='mobileview_header'>
                                <div className="elibrary_rightcontainer_icon1 w-100">
                                    <div className='display_icon_back' id='ml_arrow'>
                                        <Button onClick={() => changePath()}><ArrowBackIosNewIcon /></Button>
                                    </div>
                                    {/* <img src={drug_icon} alt="app_vault" /> */}
                                    <Typography variant="h4" color="black" className="user-data" style={{ marginLeft: '6px', fontSize: '23px' }}>
                                        {/* <span className="font-weight"><a href="/elib/specialities">EBM/{" "}</a></span> */}
                                        {path.length === 1 ? <span style={{ fontWeight: '600' }}>Drug Information</span> : ''}
                                        {path[1] ? <span className="capitalize"><Link className={`drug_header_text ${path[2] || path[3] ? "" : path[1] ? 'active-link' : ''}`} to={`/elib/${link[0]}/${link[1]}`}>{path[1]}</Link></span> : ""}
                                        {path[2] ? <> / <Link style={{ textTransform: 'capitalize' }} className={`drug_header_text ${path[3] ? 'capitalize' : path[2] ? 'active-link' : 'capitalize'}`} to={`/elib/${link[0]}/${link[1]}/${link[2]}`}>{path[2]}</Link></> : ""}
                                        {path[3] ? <> / <Link className={`drug_header_text ${path[3] ? 'active-link' : ''}`} to={''}>{path[3]}</Link></> : ""}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>

                        <Grid item>
                            <div className="elibrary_rightcontainer_icon2 elibrary_rightcontainer_icon1">
                                {/* <InputBase endAdornment={<SearchOutlinedIcon/>}/> */}
                                {/* <MoreVertIcon style={{color:'#000'}}/> */}
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default DruginformationHeader
