import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useHistory } from 'react-router-dom';
import { D_API } from '../../../../../services/APIService';
import { URI } from '../../../../utility/uri';





const DeleteHealthServiceDialog = ({ open, close, healthServiceObj, healthServiceLocObj, parentOrg, parentLoc, closeEditDialog }) => {



    const [alert, setAlert] = useState({
        open: false,
        msg: ""
    })

    const history = useHistory();


    // const contextObj = useContext(ContextInfo);

    // console.log(contextObj, "contextObj")



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({
            open: false,
            msg: ""
        })
    };


    const DeleteOrgFn = (Obj, parentOrg) => {

        let mangOrId = healthServiceLocObj?.managingOrganization && parseInt(healthServiceLocObj?.managingOrganization?.reference?.split("/")?.[1]) != NaN ? healthServiceLocObj?.managingOrganization?.reference?.split("/")?.[1] : null

        let pageId
        let copyObj = { ...Obj };
        let indx = copyObj?.identifier?.findIndex((val) => val.system == 'https://pageId/');
        pageId = indx != -1 && parseInt(copyObj?.identifier?.[indx]?.value) != NaN ? copyObj?.identifier?.[indx]?.value : null;

        let parentIndx = parentOrg?.identifier?.findIndex((val) => val.system == 'https://pageId/');
        // console.log(parentIndx, "888", indx)
        let parentPageId = parentIndx != -1 ? parentOrg?.identifier?.[parentIndx]?.value : null;
        // console.log(parentPageId, "parentPageId", parentOrg)

        if (healthServiceLocObj && healthServiceLocObj?.id) {
            D_API.delete(`${parentOrg?.id}/${URI.addLocation}/${healthServiceLocObj?.id}`).then((res) => {
                if (copyObj?.id && pageId) {
                    D_API.delete(`${parentOrg?.id}/${URI.updateHealthServiceDept}/${copyObj?.id}/${pageId}`).then((res) => {
                        close();
                        setAlert({ open: true, msg: "Successfully Deleted Organization" });
                        closeEditDialog();
                        if (parentPageId) {
                            history.push(`/admin/organization/${parentPageId}`);
                        } else {
                            history.push("/");
                        }

                    }).catch((err) => {
                        close();
                        setAlert({ open: true, msg: "Something went wrong Plz try after sometime" });
                        console.log(err)
                    })

                }
            }).catch((err) => {
                close();
                setAlert({ open: true, msg: "Something went wrong Plz try after sometime" });
                console.log(err)
            })

        } else if (copyObj?.id && pageId) {
            D_API.delete(`${parentOrg?.id}/${URI.updateHealthServiceDept}/${copyObj?.id}/${pageId}`).then((res) => {
                close();
                // history.push(`/ admin / organization / ${ copyObj?.identifier?.[1]?.value }`)
                setAlert({ open: true, msg: "Successfully Deleted Organization" });
                closeEditDialog();
                if (parentPageId) {
                    history.push(`/admin/organization/${parentPageId}`);
                } else {
                    history.push("/");
                }
            }).catch((err) => {
                close();
                setAlert({ open: true, msg: "Something went wrong Plz try after sometime" });
                console.log(err)

            })

        } else {
            close();
            setAlert({
                open: true, msg: "Organization reference not found"
            })
            // closeEditDialog();
        }










        // test

        //     if (healthServiceOrg.id) {
        //         if (healthServiceLoc?.id && (healthServiceLoc?.managingOrganization?.reference?.split("/")?.[1] != undefined || null || "")) {
        //             D_API.delete(`${ URI.addLocation } / ${ healthServiceLoc?.id } / ${ healthServiceLoc?.managingOrganization?.reference?.split("/")?.[1]}`).then((res) => {
        //                 if (healthServiceOrg?.id && healthServiceOrg?.identifier?.[1]?.value) {
        //                     axios.delete(`${ process.env.REACT_APP_API_URL }v1 / deletePagesById / ${ healthServiceOrg?.identifier?.[1]?.value }`, headers()).then((res) => {
        //                         if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
        //                             D_API.delete(`${ URI.updateHealthServiceDept } / ${ healthServiceOrg?.id } / ${ healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
        //                                 clsn();
        //                                 history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`)
        //                                 console.log(res.data)
        //                             }).catch((err) => console.log(err))
        //                         }
        //                     })
        //                 } else if (healthServiceOrg?.id && healthServiceOrg?.identifier?.[1]?.value) {
        //                     axios.delete(`${ process.env.REACT_APP_API_URL }v1 / deletePagesById / ${ healthServiceOrg?.identifier?.[1]?.value }`, headers()).then((res) => {
        //                         if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
        //                             D_API.delete(`${ URI.updateHealthServiceDept } / ${ healthServiceOrg?.id } / ${ healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
        //                                 clsn();
        //                                 history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`)
        //                                 console.log(res.data)
        //                             }).catch((err) => console.log(err))
        //                         }
        //                     })
        //                 }
        //                 else if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
        //                     D_API.delete(`${ URI.updateHealthServiceDept } / ${ healthServiceOrg?.id } / ${ healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
        //                         clsn();
        //                         history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`)
        //                         console.log(res.data)
        //                     }).catch((err) => console.log(err))
        //                 } else {
        //                     clsn();
        //                     history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`)
        //                 }
        //             }).catch((err) => console.log(err))
        //         }
        //         else if (healthServiceOrg?.id && healthServiceOrg?.identifier?.[1]?.value) {
        //             axios.delete(`${ process.env.REACT_APP_API_URL }v1 / deletePagesById / ${ healthServiceOrg?.identifier?.[1]?.value }`, headers()).then((res) => {
        //                 if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
        //                     D_API.delete(`${ URI.updateHealthServiceDept } / ${ healthServiceOrg?.id } / ${ healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
        //                         clsn();
        //                         history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`)
        //                         console.log(res.data)
        //                     }).catch((err) => console.log(err))
        //                 }
        //             })
        //         } else if (healthServiceOrg?.id && healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
        //             D_API.delete(`${ URI.updateHealthServiceDept } / ${ healthServiceOrg?.id } / ${ healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
        //                 clsn();
        //                 history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`)
        //                 console.log(res.data)
        //             }).catch((err) => console.log(err))
        //         } else {
        //             clsn();
        //             history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`);
        //         }

        //     } else {
        //         clsn();
        //         history.push(`/ admin / organization / ${ parentOrgInfo?.identifier?.[1]?.value }`);
        //     }
        // }














    }

    // this piece of code should keep above the return only don't change it
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>


            <React.Fragment>

                <Dialog
                    open={open}
                    onClose={close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Caution</div>
                    </DialogTitle>
                    <DialogContent>
                        Are sure do you want to delete the HealthcareService because it will not revert back further !
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={() => DeleteOrgFn(healthServiceObj, parentOrg)}>Yes</Button>
                        <Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>No</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

            <Snackbar
                open={alert?.open}
                // autoHideDuration={6000}
                message={alert.msg}
                onClose={handleClose}
                action={action}
            />
        </div>
    )
}

export default DeleteHealthServiceDialog;