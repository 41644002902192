import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { FormDivider, OrgLayout } from "../../../../common/components/layout";
import { D_API } from "../../../../../services/APIService";
import UserService from "../../../../../services/UserService";

const physicalTypeOption = [
  {
    code: "si",
    display: "Site",
  },
  {
    code: "bu",
    display: "Building",
  },
  {
    code: "wi",
    display: "Wing",
  },
  {
    code: "wa",
    display: "Ward",
  },
  {
    code: "lvl",
    display: "Level",
  },
  {
    code: "co",
    display: "Corridor",
  },
  {
    code: "ro",
    display: "Room",
  },
  {
    code: "bd",
    display: "Bed",
  },
  {
    code: "ve",
    display: "Vehicle",
  },
  {
    code: "ho",
    display: "House",
  },
  {
    code: "ca",
    display: "Cabinet",
  },
  {
    code: "rd",
    display: "	Road",
  },
  {
    code: "area",
    display: "	Area",
  },
  {
    code: "jdn",
    display: "	Jurisdiction",
  },
];

const InsatanceAminities = ({ details, addPhysicalAnimities, handleDetailsMultiple, close, handleSubmitAmenities, handlePhysicalInstDel }) => {
  // const [details, setDetails] = useState([
  //   {
  //     name: "",
  //     subLocationName: "",
  //     physicalTypeRoom: "",
  //     units: 0,
  //     physicalType: null,
  //     physicalTypeRoom: null,
  //     hasSubLocation: "yes",
  //   },
  // ]);
  // const [details, setDetails] = useState(details)

  // console.log(details,"details")

  // const addPhysicalAnimities = () => {
  //   setDetails([
  //     ...details,
  //     {
  //       name: "",
  //       subLocationName: "",
  //       physicalTypeRoom: "",
  //       units: 0,
  //       physicalType: null,
  //       physicalTypeRoom: null,
  //       hasSubLocation: "yes",
  //     },
  //   ]);
  // };

  const { l2id } = useSelector((state) => state.registration);
  // console.log(l2id, "222", details);


  const dispatch = useDispatch()

  // const handleDetails = (name, value) => {
  //   setDetails({ ...details, [name]: value });
  // };

  // const handleDetailsMultiple = (i) => (name, value) => {
  //   setDetails([
  //     ...details.map((item, ind) =>
  //       i == ind ? { ...item, [name]: value } : { ...item }
  //     ),
  //   ]);
  // };

  // const handleSublocationSubmit = (data)=>{
  //   //submit sub location
  //   console.log(data,"sublocation data")

  //   D_API.post("Location", data, {
  //     headers: { Authorization: `bearer ${UserService.getToken()}` },
  //   }).then((res) => {
  //     console.log(res.data,"final");
  //   }).catch(err=>{ })

  // }

  const handleSubmit = () => {
    // details.map(async(item)=>{

    //   let l3id;

    //   let data = {
    //     resourceType: "Location",
    //     partOf: {
    //       reference: `Location/${l2id}`,
    //     },
    //     name:item.name,
    //     physicalType:{coding:[item.physicalType]},
    //   }

    //   await D_API.post("Location", data, {
    //     headers: { Authorization: `bearer ${UserService.getToken()}` },
    //   }).then((res) => {
    //     console.log(res.data,"for l3");
    //     l3id = res.data
    //   }).catch(err=>{ })


    //   if(item.hasSubLocation === "yes"){
    //     let data = {
    //       resourceType: "Location",
    //       partOf: {
    //         reference: `Location/${l3id}`,
    //       },
    //       name:item.subLocationName,
    //       physicalType:{coding:[item.physicalTypeRoom],text:`${item.units}`},
    //     }
    //     handleSublocationSubmit(data)
    //   }else{
    //     //delete sub location api
    //   }
    //   console.log(data,"ddd")
    // })

    // console.log(details)

    handleSubmitAmenities(details)

    // console.log("submitted");
    // close()
  };

  const handleInstanceDelete = ((index) => {
    // console.log('i', index)
    const data = details.filter((val, i) => i != index)
    console.log(data)
    handlePhysicalInstDel(data)
    // setDetails()
  })

  return (
    <div>
      <ValidatorForm
        onSubmit={handleSubmit}
      // style={{ padding: "0 15px", height: "410px", overflowY: "auto" }}
      >
        <div
          style={{ height: '410px', overflow: 'auto' }}

        >
          {details?.map((item, i) => {
            return (
              <>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                  <Grid item xs={10}>
                    <Typography
                      variant="h4"
                      style={{ margin: "15px 0 15px 0", fontWeight: "500" }}
                      component="h3"
                    >
                      Physical Instance {i + 1}
                    </Typography>

                  </Grid>
                  {i > 0 &&
                    <Grid item xs={2} className="cursor t-right">
                      <DeleteIcon onClick={() => handleInstanceDelete(i)} />
                    </Grid>
                  }
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <OrgLayout
                    label="Name"
                    value={item.name}
                    name="name"
                    required
                    // handleChange={handleDetails}
                    handleChange={handleDetailsMultiple(i)}
                    placeholder="Consultation Room"
                    validators={["required"]}
                    errorMessages={["enter location id"]}
                  />

                  <OrgLayout
                    label="Physical Type"
                    input="select"
                    options={physicalTypeOption}
                    name="physicalType"
                    value={item.physicalType}
                    required
                    getOptionLabel={(option) => option.display}
                    // handleChange={(name, value) => handleDetails(name, value)}
                    handleChange={(name, value) =>
                      handleDetailsMultiple(i)(name, value)
                    }
                    placeholder="Select Physical Type"
                    validators={["required"]}
                    errorMessages={["select physical Type"]}
                  />

                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container item sx={{ alignItems: "center" }}>
                      <Grid item xs={12} sm={4} md={4}>
                        Has sub-location
                      </Grid>
                      <Grid item md={8}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="hasSubLocation"
                          value={item.hasSubLocation}
                          // onChange={(e) => { setHasSubLocation(e.target.value) }}
                          // onChange={(e) => handleDetails("hasSubLocation", e.target.value)}
                          onChange={(e) =>
                            handleDetailsMultiple(i)(
                              "hasSubLocation",
                              e.target.value
                            )
                          }
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            sx={{ marginLeft: { sm: "20px", md: "40%" } }}
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  {item.hasSubLocation == "yes" && (
                    <>
                      <OrgLayout
                        label="Sub-Location Name"
                        value={item.subLocationName}
                        name="subLocationName"
                        // handleChange={handleDetails}
                        required
                        handleChange={handleDetailsMultiple(i)}
                        placeholder="Consultation Room"
                        validators={["required"]}
                        errorMessages={["enter sub-location name"]}
                      />

                      <OrgLayout
                        label="Physical Type"
                        input="select"
                        options={physicalTypeOption}
                        name="physicalTypeRoom"
                        value={item.physicalTypeRoom}
                        required
                        getOptionLabel={(option) => option.display}
                        // handleChange={(name, value) => handleDetails(name, value)}
                        handleChange={(name, value) =>
                          handleDetailsMultiple(i)(name, value)
                        }
                        placeholder="Select Physical Type"
                        validators={["required"]}
                        errorMessages={["select physicall type"]}
                      />

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container item sx={{ alignItems: "center" }}>
                          <Grid item xs={12} sm={3} md={3}>
                            Units
                          </Grid>
                          <Grid item md={8}>
                            <Card sx={{ boxShadow: 2, width: "max-content" }}>
                              {/* <Button disabled={item.units == 0} onClick={() => handleDetails("units", item.units - 1)}>-</Button>
                          <Box sx={{ display: 'inline-block', padding: '10px 20px', boxShadow: 3 }}>{item.units}</Box>
                          <Button onClick={() => handleDetails("units", item.units + 1)}>+</Button> */}
                              <Button
                                disabled={item.units == 0}
                                onClick={() =>
                                  handleDetailsMultiple(i)(
                                    "units",
                                    item.units - 1
                                  )
                                }
                              >
                                -
                              </Button>
                              <Box
                                sx={{
                                  display: "inline-block",
                                  padding: "10px 20px",
                                  boxShadow: 3,
                                }}
                              >
                                {item.units}
                              </Box>
                              <Button
                                onClick={() =>
                                  handleDetailsMultiple(i)(
                                    "units",
                                    item.units + 1
                                  )
                                }
                              >
                                +
                              </Button>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            );
          })}
          <div
            onClick={addPhysicalAnimities}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px  10px",
            }}
            className="cursor"
          >
            <AddCircleRoundedIcon sx={{ color: "#00C73D" }} /> Physical Instance
          </div>
        </div>


        <FormDivider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 15px",
          }}
        >
          <Button onClick={close} className="close-btn" variant="outlined">
            Cancel
          </Button>
          <Button
            type="submit"
            sx={{ padding: "4px 20px !important" }}
            name="save"
            className="save-btn"
            variant="contained"
          >
            Create
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default InsatanceAminities;
