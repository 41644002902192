import React, { useState } from 'react';
import './HeaderBox.css';
import "../../utility/style.css";
import { Avatar, Button, Grid } from '@mui/material';
import UserService from '../../../services/UserService';
import axios from 'axios';
import URL from '../../apiurl';
import DialogBox from '../../DialogBox/dialog';
import { useParams } from 'react-router';
import { fetchProfile } from '../../../redux/Actions/actions';
import { useDispatch } from 'react-redux';
import profilecoverpic from "../../../icons/backgroundpeople.png";
import Connectbutton from './Connectbutton';
import profimage from '../../../icons/Userprofilelogo.svg'
import useMediaQuery from '@mui/material/useMediaQuery';
import { headers } from '../../../redux/Actions/connectActions';

export default function HeaderBox({ active, handleActive, myreloadingFn, profileData }) {

  const maxWidth = useMediaQuery('(max-width: 960px)')
  const minWidth = useMediaQuery('(min-width: 961px)')
  const { id } = useParams();


  const [modal, setModal] = useState({ open: false, name: '' })
  const dispatch = useDispatch()


  console.log(profileData, "profileData")
  const blockUser = () => {
    axios.post(`${URL.blockUser}/${UserService.getUserId()}/user/${id}`, {}, headers()).then(res => {
      // console.log(res)
      myreloadingFn()
      setModal({ open: false, name: '' })
      dispatch(fetchProfile())
    })
  }

  const unblock = () => {
    axios.delete(`${URL.unblockUser}/${UserService.getUserId()}/user/${id}`, headers()).then(res => {
      // console.log(res)
      setModal({ open: false, name: '' })
      myreloadingFn()
      dispatch(fetchProfile())
    }
    )
  }

  return (
    <>
      <DialogBox
        width="xs"
        open={modal.open}
        handleDialog={() => setModal({ open: false, name: "" })}
        Content={<p>Do you want to {modal?.name} this account ?</p>}
        Actions={
          <div>
            <Button
              variant="contained"
              className="add-option"
              sx={{ mr: 2 }}
              onClick={() =>
                modal?.name === "block" ? blockUser() : unblock()
              }
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="add-option"
              onClick={() => setModal({ open: false, name: "" })}
            >
              No
            </Button>
          </div>
        }
      ></DialogBox>
      {profileData !== undefined ? (
        <Grid container className="header_view_Desktop"  >
          <Grid item md={12} xs={12}>
            {minWidth ? (
              <div className="profile">
                <div
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    height: `${profileData?.user?.coverPic ? "" : "200px"}`,
                  }}
                >
                  <img
                    src={
                      profileData?.user?.coverPic
                        ? profileData?.user?.coverPic
                        : profilecoverpic
                    }
                    className="profile__cover"
                    alt=""
                    width="100%"
                  />
                  {/* <img src={profileData.user.coverPic ? profileData.user.coverPic : ''} className="profile__cover" alt="" width="100%" /> */}
                </div>
              </div>
            ) : (
              ""
            )}
            {maxWidth ? (
              <div className="profile">
                <div
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    height: `${profileData?.user?.coverPic ? "" : "150px"}`,
                  }}
                >
                  <img
                    src={
                      profileData?.user?.coverPic
                        ? profileData?.user?.coverPic
                        : profilecoverpic
                    }
                    className="profile__cover"
                    alt=""
                    width="100%"
                  />
                  {/* <img src={profileData.user.coverPic ? profileData.user.coverPic : ''} className="profile__cover" alt="" width="100%" /> */}
                </div>
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid container className="border_img1" id="personal_details">

            <Grid item md={4} xs={12}>
              <div className="header___profile">
                <div className="profile__left">
                  <Avatar
                    className="profile_picture"
                    src={
                      profileData?.user?.practioner?.photo?.[0]?.url
                        ? profileData?.user?.practioner?.photo?.[0]?.url
                        : profimage
                    }
                  />
                </div>
              </div>

              <div className="sidebar__user_details" id="padding__topview" style={{ height: "100px" }} >
                <div className="sidebar__pb" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: "100%" }}>
                  <span className=" sidebar__title capitalize heading_full_name" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {profileData?.user?.fullName}
                  </span>
                </div>
                <span
                  className="sidebar__title capitalize heading_caption"
                  style={{ marginTop: "-10px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {profileData?.user?.captionAndTaglines !== null
                    ? profileData?.user?.captionAndTaglines?.replace(/,/g, ' , ')
                    : ""}
                </span>
                {profileData?.user?.practioner &&
                  (profileData?.user?.practioner?.address?.[0]?.city ||
                    profileData?.user?.practioner?.address?.[0]?.state ||
                    profileData?.user?.practioner?.address?.[0]?.country) ? (
                  <div style={{ paddingTop: "4px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span className="sidebar__title heading_address" style={{ fontSize: "13px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {profileData?.user?.hasOwnProperty("practioner")
                        ? profileData?.user?.practioner?.address?.[0]?.city
                        : ""}
                    </span>
                    {profileData?.user?.practioner &&
                      profileData?.user?.practioner?.address?.[0]?.city &&
                      profileData?.user?.practioner?.address?.[0]?.state
                      ? ","
                      : ""}{" "}
                    <span className="sidebar__title heading_address" style={{ fontSize: "13px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {profileData?.user?.hasOwnProperty("practioner")
                        ? profileData?.user?.practioner?.address?.[0]?.state
                        : ""}
                    </span>
                    {profileData?.user?.practioner &&
                      profileData?.user?.practioner?.address?.[0]?.state &&
                      profileData?.user?.practioner?.address?.[0]?.country
                      ? ","
                      : ""}{" "}
                    <span className="sidebar__title heading_address" style={{ fontSize: "13px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {profileData?.user?.hasOwnProperty("practioner")
                        ? profileData?.user?.practioner?.address?.[0]?.country
                        : ""}
                    </span>
                    <div style={{ paddingTop: "2px" }} >
                      {
                        <span className="sidebar__title heading_address" style={{ fontSize: "14px", fontWeight: 500 }} >
                          {Number(profileData?.user?.followersCount) >= 0 ? `${profileData?.user?.followersCount} Followers` : ""}
                        </span>
                      }
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      paddingTop: "10px",
                      fontSize: "18px",
                      color: "rgb(54, 69, 79)",
                      fontWeight: "300",
                      margin: "0px",
                    }}
                  ></p>
                )}
              </div>
              <div id="padding_btn_view">
                <div style={{ position: "relative" }}>
                  <Connectbutton profileData1={profileData} />
                </div>
              </div>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={5}></Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <div></div>

      <div className="header_view_Mobiles">
        {/* {profileData !== undefined ? (
                    <div>
                        <div style={{ width: '100%', overflow: 'hidden', height: `${profileData.user.coverPic ? '' : '140px'}` }}>
                            <img src={profileData.user.coverPic ? profileData.user.coverPic : profilecoverpic} className="Mobile_profile__cover" alt="" width="100%" />
                        </div>
                        <div id="profileview__header" style={{ backgroundColor: 'white' }}>
                            <Avatar className='profile__view__Image' src={profileData.user.practioner.photo[0].url} />
                            <div className='profile__top__view'>
                                <div className='details__were' id='text_name'>
                                    <div className="sidebar__stat1" style={{ paddingLeft: '10px' }}>
                                        <div>
                                            <span className="sidebar__title" style={{ fontSize: "14px", fontWeight: "500" }}> {profileData.user.practioner.name[0].text}</span>
                                        </div>
                                        <div>
                                            <p className="sidebar__title" style={{ fontSize: "14px", paddingTop: '8px' }}>{profileData.user.captionAndTaglines}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='badges_responsive' style={{ paddingTop: '8px' }}>
                                    <div className="sidebar__icon1">
                                        <div className="sidebar__title sidebar__pb fw__normal">Certifications</div>
                                        <img src={certificate} alt="certificate" />
                                        <div className="sidebar__statNumber">{statedata && statedata.certificatesCount}</div>
                                    </div>
                                    <div className="sidebar__icon1">
                                        <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                                        <img src={ribbon} alt="certificate" />
                                        <div className="sidebar__statNumber">{statedata && statedata.certificatesCount}</div>
                                    </div>
                                    <div className="sidebar__icon1">
                                        <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                                        <img src={medal} alt="certificate" />
                                        <div className="sidebar__statNumber">{statedata && statedata.certificatesCount}</div>
                                    </div>
                                </div>
                                <div >
                                    <Connectbutton />
                                </div>
                            </div>
                        </div>
                    </div>


                ) : ("")
                } */}
        <div className="profile__view__link">
          <div className="links1 border_img">
            <ul style={{ padding: "0px" }}>
              <li
                className={`${Number(active) === 0 ? "profile-active1" : ""}`}
                onClick={() => handleActive("0")}
              >
                Overview
              </li>
              <li
                className={`${Number(active) === 1 ? "profile-active1" : ""}`}
                onClick={() => handleActive("1")}
              >
                Timeline
              </li>
              <li
                className={`${Number(active) === 2 ? "profile-active1" : ""}`}
                onClick={() => handleActive("2")}
              >
                Publications
              </li>
              {/* <li className={`${active == 3 ? 'profile-active1' : ''}`} onClick={() => handleActive('3')}>Bookmarks</li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
