import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid';
import './peer.css'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import UserInfo from './userInfo'
import ListHeader from './ListHeader'
import Follow from './followButton'
import Peer from './mutual'
import URL from '../apiurl'
import axios from 'axios'
import { getMetaData } from '../../redux/Actions/actions';
import { useDispatch } from 'react-redux';
import profimage from '../../icons/Userprofilelogo.svg'
import { useSelector } from 'react-redux';
import { socket } from '../../socket';
import { getConnectCount, getPeople, getRequests, headers } from '../../redux/Actions/connectActions';
import { sendPushNotification } from '../Molecule/Post/commonFun';

const RequestCard = ({ data, id, cardStyle, Accept, pad, reqPending, name, receiverimage, username, userID }) => {


  const styles = { display: 'flex', padding: '2px 0px' }
  const [receiveid, setReceiveid] = useState(data)
  const dispatch = useDispatch();

  const handleNotification = (notificationFrom) => {
    setReceiveid(receiveid)
    socket?.emit("sendNotification", {
      name: username,
      senderID: userID,
      receiverID: receiveid.requestData.userId,
      notificationFrom: notificationFrom,
      profilePic: receiverimage
    });
  };

  const AcceptRequest = async (data, status) => {
    let req = {
      "id": data.id,
      "metadata": await getMetaData(data.metadata),
      "requestedUser": data.requestedUser,
      "requestingFrom": data.requestingFrom,
      "status": status,
      "userId": data.userId
    }
    // console.log(req)
    axios.put(`${URL.acceptRequest}/${data.id}`, req, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
      }
    }).then(res => {
      // console.log(res.data)
      sendNotification(res.data?.data?.requestedUser, res.data?.data?.requestingFrom,)
      reqPending();
      dispatch(getConnectCount())
      handleNotification("REQUEST_ACCEPTED")
      if (window.location.pathname === '/connect') {
        dispatch(getPeople('suggestedPeople'))
      }
    })
      .catch(error => { })
  }

  const sendNotification = async (loggedInUserID, requestedUserId) => {
    axios.get(`${URL.findUser}/${loggedInUserID}`, headers()).then(res => {
      if (res.status === 200) {
        // console.log(res.data.data.user.fullName, 'cccc')
        sendPushNotification("friend_request_accepted", requestedUserId, res.data.data.user.fullName)
      }
    })
  }

  const cancelRequest = (reqId) => {
    axios.delete(`${URL.deleteRequestById}/${reqId}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
      }
    }).then(res => {
      // console.log(res)
      reqPending()
      dispatch(getConnectCount())
      if (window.location.pathname === '/connect') {
        dispatch(getPeople('suggestedPeople'))
      }
      handleNotification("REQUEST_DELETED")
    }).catch(err => { })
  }

  return (
    <Card className="req-card custom-card">
      <CardContent sx={{ minHeight: 'inherit', p: 0, paddingBottom: '0px!important' }}>
        <Grid container sx={{ minHeight: 'inherit' }}>
          <Grid item lg={3} md={6} sm={4} xs={6} className={`req-org-bg ${cardStyle}`} style={{ display: 'inherit', background: `${data.coverPic ? `transparent url(${data.coverPic}) 0% 0% no-repeat padding-box` : ``}` }}>
            <div className="my-img">
              {/* <Avatar style={{ height: "65px", width: '65px' }} src={data.image} /> */}
              <img style={{ height: "65px", width: '65px', borderRadius: "8px", objectFit: "cover" }} src={(data.image) ? data.image : profimage} />
            </div>
          </Grid>
          <Grid item lg={9} md={6} sm={8} xs={6}>
            <Grid container>
              <Grid item lg={6} xs={12} sx={{ p: 2 }}>
                <UserInfo userData={data} Peer={<Peer styles={styles} connections={data} />} />
              </Grid>
              <Grid item lg={6} xs={12} sx={{ m: 'auto', pr: 3, pl: 1 }}>
                <Stack className='request__css' direction="row" alignItems="center"
                  sx={{
                    height: '30px',
                    justifyContent: {
                      lg: 'flex-end',
                      md: 'flex-start'
                    }
                  }}
                >
                  {name === 'Requests' ?
                    <>
                      <Button variant="text" sx={{ color: '#EA2D00', textTransform: 'capitalize' }} onClick={() => cancelRequest(data.requestData.id)}>Decline</Button>
                      {Accept === 'Follow' ?
                        <Follow pad={pad} /> : <Button variant="outlined" style={{ padding: '30px!important' }} className={`${pad ? 'acc' : ''} accept`} onClick={() => AcceptRequest(data.requestData, "ACCEPTED")}>Accept</Button>
                      }
                    </> :
                    <Button variant="outlined" style={{ padding: '30px!important' }} className={`${pad ? 'acc' : ''} accept`} onClick={() => cancelRequest(data.requestData.id)}>Cancel Request</Button>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function Requests(props) {

  const [req, setReq] = useState([]);
  let id = sessionStorage.getItem("userId")
  const state = useSelector(state => state.userProfile.userProfile)
  const username = state.fullName
  const userID = state.id
  const receiverimage = state.hasOwnProperty("practioner") ? state?.practioner?.photo?.[0]?.url : ""
  const requestReceived = useSelector(state => state.connect.requests)

  const dispatch = useDispatch()

  useEffect(() => {

    reqPending();

  }, [props.name]);

  useEffect(() => {
    setReq(requestReceived)
  }, [requestReceived])

  const reqPending = () => {
    if (props.name === 'Requests') {
      dispatch(getRequests(id))
    }
    else {
      axios.get(`${URL.sentRequests}/${id}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("kctoken")}`
        }
      })
        .then(res => {
          setReq(res.data.data)
        })
    }
  }

  return (
    <ListHeader HeaderName={props.name} displayname={props.name1}
    >
      <ListItem className="req-list" sx={{ pb: 0, maxHeight: `${props.type ? '100%' : '250px'}` }}>
        {req?.length > 0 ? req?.map((data, i) => {
          return (
            <RequestCard key={data.requestData.id} data={data} id={id} cardStyle='request' Accept='Accept' pad='30px' name={props.name} reqPending={reqPending} username={username} userID={userID} receiverimage={receiverimage} />
          )
        }) : <p className="text-primary">No {props.name}</p>}

      </ListItem>
    </ListHeader>
  );
}
