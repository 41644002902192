import { List, ListItemText, ListItemIcon, ListItem } from '@mui/material'
import React from 'react'
import './MarketPlaceSidebar.css';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import Briefcasemedical from '../../../images/Briefcasemedical.svg';
import appvault from '../../../images/appvault.svg';
import graduation_cap from '../../../images/graduation_cap.svg';
import feather_calendar from '../../../images/feather_calendar.svg';
import Pharma from '../../../images/Pharma.svg';
import MDevice from '../../../images/MDevice.svg';

const CardList = (props) => {
    return (
        <ListItem className="li">
            <ListItemIcon>
                <img src={props.image} alt={props.label.toLowerCase()} width="30px" height='30px' />
            </ListItemIcon>
            <ListItemText primary={props.label} style={{ fontSize: '14px', flexWrap: 'wrap !important' }} />
        </ListItem>
    )
}
function MarketPlaceSidebar() {
    return (

        <Card className='border_img' style={{ height: '100%', borderTopLeftRadius: '17px', borderTopRightRadius: '17px' }}>
            <CardHeader style={{ backgroundColor: "#007AC8", fontSize: "18px", boxShadow: '0px 3px 6px #00000029' }}
                title={
                    <Typography variant="h5" style={{ color: '#FAFAFA', fontSize: '20px', textAlign: 'center' }}>Market Place</Typography>
                }
            />
            <CardContent className="marketplace__card__content" style={{ backgroundColor: 'B1D6ED' }}>

                <List>
                    <CardList image={appvault} label="APP VAULT" />
                    <CardList image={Briefcasemedical} label="Career Opening" />
                    <CardList image={graduation_cap} label="Job Options" />
                    <CardList image={feather_calendar} label="Events" />
                    <CardList image={MDevice} label="Device Gallery" />
                    <CardList image={Pharma} label="Formulary Updates" />
                </List>

            </CardContent>

        </Card>

    )
}

export default MarketPlaceSidebar