import { Button, Grid, Box, FormControlLabel } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import '../../Molecule/Elibrary/ElibraryRightContainer.css'
import './EbmKit.css'
import Cardiology from './conditions'
import CardiologyRadio from './disease_articles'
import EbmkitHeader from './EbmkitHeader';
import { Link, useHistory } from 'react-router-dom'
import Search from './search'
import axios from 'axios';
import URL from '../../apiurl';
import { Helmet } from 'react-helmet';
import Popover from '@mui/material/Popover';
import { RadioGroup, FormControl, Radio } from '@mui/material'
import { d_name, drugName } from './Ebmkithomepage';
import { ClickAwayListener, CircularProgress } from '@mui/material';

export const Search_Elib = (props) => {
    const history = useHistory()
    const { load } = props

    const handleProp = () => {
        setTimeout(() => {
            props.handleClick()
        }, 100)
    }

    return (
        <ClickAwayListener onClickAway={handleProp}>
            <div style={{ position: 'relative' }}>
                {props.children}
                <div style={{ maxHeight: '400px', overflow: 'auto', position: 'absolute', backgroundColor: 'white', color: 'black', width: '100%', boxShadow: '0px 3px 6px #00000029' }}>
                    {load ? <div style={{ height: '100px' }} className="elib-right__content"><CircularProgress disableShrink size="1.5rem" /></div> :
                        props.data.length > 0 ? props.data.sort((a, b) => a.name > b.name ? 1 : -1).map((option, i) => (
                            <Box key={i} sx={{ '& > img': { mr: 2, flexShrink: 0 }, p: '10px' }} tabIndex={i} className="display_ebm cursor"
                                onClick={() => option.type === 'diseases' ? history.push(`/elib/conditions/${d_name(option.name)}-${option.id}`) : option.type === 'drugs' ? history.push(`/elib/alldrugs/${drugName(option.name)}-${option.id}`) : option.type === 'classes' ? history.push(`/elib/drugclasses/${drugName(option.name)}`) : {}}
                            >
                                <p className='text-ellipsis' style={{ width: '70%' }}>
                                    {option.name ? option.name : option.name}
                                </p>
                                <p style={{ color: 'grey', fontSize: '12px' }} className='capitalize'>
                                    {option.type}
                                </p>
                            </Box>
                        )) : props.value.length > 2 ? <Box sx={{ '& > img': { mr: 2, flexShrink: 0 }, p: '10px' }} className="display_ebm cursor">
                            No Results Found</Box> : ''}
                </div>
            </div>
        </ClickAwayListener>
    )
}

function EbmKit(props) {
    const [path, setPath] = useState({ speciality: '', disease: '' })
    const [com, setCom] = useState(0)
    const handlePath = (val, name, value) => {
        setCom(val)
        setPath({ ...path, [name]: value })
    }
    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const [value, setValue] = useState('')
    const [filter, setFilter] = useState([])
    const myRef = useRef(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [load, setLoad] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    useEffect(() => {
        axios.get(`${URL.elib}allSpecialities/`)
            .then((res) => {
                // console.log(res.data.data)
                setData(res.data.data.sort((a, b) => a.id > b.id ? 1 : -1))
            })
            .catch(error => { })
    }, [])

    const handleSearch = (data) => {
        setSearch(data)
        if (data.length > 2) {
            setLoad(true)
            axios.get(`${URL.search_diseases}/${data}`)
                .then(res => {
                    setFilter(res.data.data)
                    setLoad(false)
                })
        }
    }

    const SearchView = () => {
        return (
            <Search_Elib data={filter} load={load} value={search} handleClick={() => { setFilter([]); setSearch('') }}>
                <Search value={search} handleSearch={(value) => handleSearch(value)} placeholder="Search Diseases" />
            </Search_Elib>
        )
    }

    return (
        <div className='elibrary_rightcontainer' ref={myRef} >
            <EbmkitHeader />
            <Box className="elibrary_rightcontainer_body" sx={{ height: { md: 'calc(100vh - 171px)', sm: 'auto', xs: 'auto' }, overflow: { md: 'scroll', xs: 'hidden' } }}>
                {com == 0 ? <Grid container style={{ height: '100%', display: 'block' }} >
                    <Grid item style={{ width: '100%' }}>
                        <div className='Ebm_specialities_Desktop'>
                            <div className="Ebm_components ta__left">
                                <div className='display_index'><h4>Index</h4></div>
                                <div className="Ebm_filtering">
                                    <ul>
                                        <li className='sort_display_icon'>
                                            <div className="sort_icon">
                                                <SortOutlinedIcon />
                                                <h4 className='display__text'>Index</h4>
                                                <Button variant="outlined" className="sp-header1">Specialities</Button>
                                            </div>
                                        </li>
                                        <li className='condition_display_icon'>
                                            <div className="sort_icon">
                                                <Button variant="outlined" className="sp-header"><Link style={{ borderColor: 'rgba(25, 118, 210, 0.5)', color: '#1976d2' }} to='/elib/conditions'>Conditions</Link></Button>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul id='search_ul'>
                                        {SearchView()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='Ebm_specialities_Mobile'>
                            <div className="Ebm_components ta__left">
                                <div className="Ebm_filtering">
                                    <ul>
                                        <li>
                                            <div className="sort_icon">
                                                {/* <ArrowBackIcon onClick={() => url.includes('/elib/specialities') ? history.push('/elib/specialities') : url.includes('/specialities') ? history.push('/elib/specialities') : history.goBack()} sx={{ mr: 2 }} /> */}
                                                {/* <ArrowBackIcon onClick={() => history.goBack()} sx={{ mr: 2 }} /> */}
                                                <h4 className='display__text'>Specialities</h4>
                                            </div>
                                        </li>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <FormControl sx={{ ml: 2 }}>
                                                <RadioGroup row className="radio"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                >
                                                    <FormControlLabel value="Specialities" control={<Radio size="small" />} label="Specialities" ></FormControlLabel>
                                                    <Link to='/elib/conditions'><FormControlLabel value="Conditions" control={<Radio size="small" />} label="Conditions"></FormControlLabel></Link>
                                                </RadioGroup>
                                            </FormControl>
                                        </Popover>

                                    </ul>
                                    <div className='search__filter' style={{ paddingTop: '14px' }}>
                                        <div className='mobile_hide'>
                                            {SearchView()}
                                        </div>
                                        <div>
                                            <SortOutlinedIcon onClick={handleClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mobile_show'>
                                {SearchView()}
                            </div>
                        </div>
                    </Grid>
                    <Grid item className='speciality_height'>
                        <div className="druglist">
                            <Grid container className='Drug__list'>
                                <Grid item sm={6} className="sp-padding w-100">
                                    {data.length > 0 ? data.slice(0, data.length / 2).map((val, i) => (

                                        <p key={val.id} className="text-ellipsis capitalize cursor" onClick={props.scroll}><Link to={`/elib/specialities/${val.speciality ? `${val.speciality.replace(/ /g, '_').toLocaleLowerCase()}-${val.id}` : ''}`}>{val.speciality}</Link></p>
                                    )) : ''}</Grid>
                                <Grid item sm={6} className="sp-padding w-100">
                                    {data.length > 0 ? data.slice(data.length / 2, data.length).map((val, i) => (

                                        <p key={val.id} className="text-ellipsis capitalize cursor" onClick={props.scroll}><Link to={`/elib/specialities/${val.speciality ? `${val.speciality.replace(/ /g, '_').toLocaleLowerCase()}-${val.id}` : ''}`}>{val.speciality}</Link></p>
                                    )) : ''}</Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid> : com == 1 ? <Cardiology handlePath={handlePath} /> : <CardiologyRadio />}
            </Box>
        </div >
    )
}
export default EbmKit;