import { Box, MenuItem, TextField, Typography, Grid } from "@mui/material"
import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { headers } from "../../../../redux/Actions/connectActions"
import URL from "../../../apiurl"
import { AppsView } from "./appview"
import SearchApps from "./search"

const AppTypes = [
    { heading: "Individual Practice Apps", value: 'practice' },
    { heading: "Individual Learning Apps", value: 'learning' },
    { heading: "Organisation Business Apps", value: 'business' }
]
const categories = [
    { name: 'Select Category', value: 'all' },
    { name: 'Learning Apps', value: 'learning' },
    { name: 'Practice Apps', value: 'practice' },
    { name: 'Business Apps', value: 'business' },
]

const DocstethApps = () => {

    const [category, setCategory] = useState('all')
    const [apps, setApps] = useState({ learning: [], business: [], practice: [] })
    const suggestedApps = useSelector(state => state.connect.suggestedApps)
    const dispatch = useDispatch()
    const { vault } = useParams()

    useEffect(() => {
        axios.get(URL.getAppsByVault + `${vault.replace(/apps/g, '')}/all`, headers()).then(res => {
            // console.log({...res.data.data,all: [...res.data.data.learning,...res.data.data.business,...res.data.data.practice]})
            setApps(res.data.data)
        })
    }, [vault, category])

    const handleChange = (e) => {
        setCategory(e.target.value)
    }

    return (
        <Box>

            <Typography variant="h5" className="b-color font-bold capitalize">{vault.replace(/apps/g, '')} Apps</Typography>
            {/* <div className="space_btw_center" style={{ flexWrap: 'wrap' }}> */}
            <Grid container spacing={2} mb={2} mt={1} >
                <Grid item xs={12} md={6}>
                    <TextField
                        id="outlined-select-app"
                        select
                        value={category}
                        onChange={handleChange}
                        sx={{ '.MuiOutlinedInput-root': { width: '200px', borderRadius: '8px' } }}
                    >
                        {categories.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>

                </Grid>
                <Grid item xs={12} md={6}>

                    <SearchApps />
                </Grid>
            </Grid>
            {/* <TextField
                    id="outlined-select-app"
                    select
                    value={category}
                    onChange={handleChange}
                    sx={{ '.MuiOutlinedInput-root': { width: '200px', borderRadius: '8px' }, mb: 2, mt: 1 }}
                >
                    {categories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
                <SearchApps /> */}
            {/* </div> */}

            {AppTypes.filter(typ => category === 'all' || typ.value === category).map(type => <AppsView apps={apps[type.value]} suggested sx={{ color: 'black', my: 1 }} heading={type.heading} />)}

        </Box>
    )
}

export default DocstethApps