import UserService from "./UserService";

/**
 * Request handler
 * @param {Object} reqConfig - Request config object
 */
export const RequestInterceptor = (reqConfig) => {
    try {
        reqConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        const kctoken = UserService.getToken();
        reqConfig.headers['Authorization'] = `Bearer ${kctoken}`
        return reqConfig;
    } catch (error) {
        return Promise.reject(error);
    }
};
/**
 * Response handler
 * @param {Object} error - Denotes API error
 */
export const ResponseInterceptor = (resConfig) => {
    try {
        return resConfig;
    } catch (error) {
        return Promise.reject(error);
    }
};

/**
 * Error handler
 * @param {Object} error - Denotes API error
 */
export const ResponseErrorInterceptor = (reqConfig) => {
    if (reqConfig.response) {
        if (reqConfig.response.status === 401) {
            window.location.href = "/";
        } else {
            return Promise.reject(reqConfig.response);
        }
    }
};


export default RequestInterceptor;
