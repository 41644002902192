import React, { useState, useEffect, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import Table from '@editorjs/table';
import axios from 'axios';
import { headers } from '../../redux/Actions/connectActions';
import URL from '../apiurl';

const ReactEditorJS = createReactEditorJS();

const EditorComponent1 = () => {
    const editorRef = useRef(null);
    const [check, setCheck] = useState('');

    useEffect(() => {
        // Apply table styles when check is updated
        const tableElements = document.querySelectorAll('.editor-content table');

        tableElements.forEach(table => {
            table.style.width = '100%';
            table.style.borderCollapse = 'collapse';

            const thElements = table.querySelectorAll('th');
            thElements.forEach(th => {
                th.style.backgroundColor = '#f4f4f4';
                th.style.padding = '8px';
                th.style.border = '1px solid #ddd';
            });

            const tdElements = table.querySelectorAll('td');
            tdElements.forEach(td => {
                td.style.padding = '8px';
                td.style.border = '1px solid #ddd';
            });

            const thead = table.querySelector('thead');
            if (thead) {
                thead.style.backgroundColor = '#f0f0f0';
            }
        });
    }, [check]);

    // Parser functions for EditorJS blocks
    const headerParser = (block) => {
        return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
    };

    const paragraphParser = (block) => {
        return `<p>${block.data.text}</p>`;
    };

    const linkToolParser = (block) => {
        const { link, meta } = block.data;
        const title = meta?.title || 'Link';
        return `<a href="${link}" target="_blank" rel="noopener noreferrer">${title}</a>`;
    };

    const tableParser = (block) => {
        const { content } = block.data;
        const headerRow = content[0]; // First row will be the header
        const dataRows = content.slice(1); // The rest will be table rows
        const thead = `<thead><tr>${headerRow.map(cell => `<th>${cell}</th>`).join('')}</tr></thead>`;
        const tbody = `<tbody>${dataRows.map(row => {
            return `<tr>${row.map(cell => `<td>${cell}</td>`).join('')}</tr>`;
        }).join('')}</tbody>`;

        return `<table>${thead}${tbody}</table>`;
    };

    const listParser = (block) => {
        const items = block.data.items.map(item => `<li>${item}</li>`).join('');
        return `<ul>${items}</ul>`;
    };

    const checklistParser = (block) => {
        const items = block.data.items.map(item => {
            return `<li>${item.checked ? '[x]' : '[ ]'} ${item.text}</li>`;
        }).join('');
        return `<ul>${items}</ul>`;
    };

    const uploadImage = (block) => {
        return `<img src="${block?.data?.file?.url}" alt="${block?.data?.caption}" style="width: 100%; height: 300px;" />`;
    }
    // Define parserOptions
    const parserOptions = {
        header: headerParser,
        paragraph: paragraphParser,
        linkTool: linkToolParser,
        table: tableParser,
        list: listParser,
        checklist: checklistParser,
        image: uploadImage,
    };

    const handleSave = async () => {
        let res;
        try {
            res = await editorRef.current.saver.save();
            console.log(res, "res");
        } catch (error) {
            console.error("Error saving:", error);
            return;
        }

        if (!res?.blocks || !Array.isArray(res.blocks)) {
            console.error("No valid blocks found.");
            return;
        }
        console.log(res.blocks, "Parsed blocks before conversion");

        // Parsing using custom parser functions for each block
        const parsedHtml = res.blocks.map((block) => {
            const type = block.type;
            const parser = parserOptions[type];
            if (parser) {
                return parser(block);
            }
            return ''; // Return an empty string if no parser is found for the block type
        }).join('');

        console.log(parsedHtml, "Parsed HTML");

        setCheck(parsedHtml);
    };

    const handleOnChange = (outputData) => {
        editorRef.current = outputData;
    };




    // Upload image by file (base64 encoded)
    const uploadByFile = async (file) => {
        let reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = async () => {
                try {
                    const result = await toconvertImgtoimgUrl(reader.result);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const toconvertImgtoimgUrl = async (imgUrl) => {
        if (!imgUrl) {
            return { success: 0, message: "No image provided" };
        }

        const image = imgUrl.split(',')[1];
        const type = imgUrl.split(',')[0].split('/')[1].split(';')[0];

        const data = {
            file_type: 'IMAGE',
            files: [
                {
                    file_size: (image.length / 1048576).toFixed(3), // file size in MB
                    file_extension: type,
                    data: image,
                },
            ],
        };

        try {
            const res = await axios.post(`${URL.upload}`, data, headers());
            console.log(res.status, "Response from API", res.data);
            if (res.status === 200 && res.data?.length > 0) {
                return {
                    success: 1,
                    file: {
                        url: res.data?.[0],
                    },
                };
            } else {
                console.error("Upload failed, no file URL in response");
                return {
                    success: 0,
                    message: "Upload failed, no file URL",
                };
            }
        } catch (error) {
            console.error("API error:", error);
            return {
                success: 0,
                message: "Upload failed due to an error",
            };
        }
    };


    const uploadByUrl = async (file) => {
        return file;
    };

    return (
        <>
            <ReactEditorJS
                ref={editorRef}
                onChange={handleOnChange}
                tools={{
                    header: {
                        class: Header,
                        inlineToolbar: ['link'],
                    },
                    paragraph: {
                        class: Paragraph,
                        inlineToolbar: true,
                    },
                    list: {
                        class: List,
                        inlineToolbar: true,
                    },
                    checklist: {
                        class: Checklist,
                        inlineToolbar: true,
                    },
                    linkTool: LinkTool,
                    image: {
                        class: ImageTool,
                        config: {
                            uploader: {
                                uploadByFile,
                                uploadByUrl,
                            },
                        },
                    },
                    table: Table,
                }}
            />
{/* 
            <h2>After converting into string format</h2>
            <div
                className="editor-content"
                dangerouslySetInnerHTML={{ __html: check }}
                style={{
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    whiteSpace: 'pre-wrap',
                }}
            />
            <button onClick={handleSave}>Save</button> */}
        </>
    );
};

export default EditorComponent1;





// old code
// import React, { useState, useRef } from 'react';
// import { createReactEditorJS } from 'react-editor-js';
// import Header from '@editorjs/header';
// import Paragraph from '@editorjs/paragraph';
// import List from '@editorjs/list';
// import Checklist from '@editorjs/checklist';
// import ImageTool from '@editorjs/image';
// import LinkTool from '@editorjs/link';
// import Table from '@editorjs/table';
// import edjsHTML from 'editorjs-html';
// import axios from 'axios';
// import { headers } from '../../redux/Actions/connectActions';
// import URL from '../apiurl';

// const ReactEditorJS = createReactEditorJS();

// const EditorComponent1 = () => {
//     const editorRef = useRef(null);
//     const [check, setCheck] = useState('');




//     // Parser functions
//     const headerParser = (block) => {
//         return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
//     };

//     const paragraphParser = (block) => {
//         return `<p>${block.data.text}</p>`;
//     };

//     const linkToolParser = (block) => {
//         const { link, meta } = block.data;
//         const title = meta?.title || 'Link';
//         return `<a href="${link}" target="_blank" rel="noopener noreferrer">${title}</a>`;
//     };

//     const tableParser = (block) => {
//         const rows = block.data.content.map(row => {
//             const cells = row.map(cell => `<td>${cell}</td>`).join('');
//             return `<tr>${cells}</tr>`;
//         }).join('');
//         return `<table>${rows}</table>`;
//     };

//     const listParser = (block) => {
//         const items = block.data.items.map(item => `<li>${item}</li>`).join('');
//         return `<ul>${items}</ul>`;
//     };

//     const checklistParser = (block) => {
//         const items = block.data.items.map(item => {
//             return `<li>${item.checked ? '[x]' : '[ ]'} ${item.text}</li>`;
//         }).join('');
//         return `<ul>${items}</ul>`;
//     };

//     // Define parserOptions
//     const parserOptions = {
//         header: headerParser,
//         paragraph: paragraphParser,
//         linkTool: linkToolParser,
//         table: tableParser,
//         list: listParser,
//         checklist: checklistParser,
//     };
//     const handleSave = async () => {
//         let res;
//         try {
//             res = await editorRef.current.saver.save();
//             console.log(res, "res");
//         } catch (error) {
//             console.error("Error saving:", error);
//             return;
//         }

//         // Check if blocks are present and valid
//         if (!res?.blocks || !Array.isArray(res.blocks)) {
//             console.error("No valid blocks found.");
//             return;
//         }

//         // Log the structure of res.blocks for debugging
//         console.log(res.blocks, "Parsed blocks before conversion");

//         const edjsParser = edjsHTML();

//         try {
//             // Check each block's structure
//             // res.blocks.forEach((block, index) => {
//             //     console.log(`Block ${index}:`, block);
//             //     if (!block.data) {
//             //         console.warn(`Block ${index} does not have data:`, block);
//             //     }
//             // });
//             const parsedHtml = edjsParser.parse(res);
//             console.log(parsedHtml, "Parsed HTML");

//             const convertIntoHtml = parsedHtml.join('');
//             setCheck(convertIntoHtml);
//         } catch (error) {
//             console.error("Error parsing blocks:", error);
//         }
//     };

//     console.log(check, "check");

//     const handleOnChange = (outputData) => {
//         editorRef.current = outputData;
//     };

//     const toconvertImgtoimgUrl = async (imgUrl) => {
//         if (!imgUrl) {
//             return { success: 0, message: "No image provided" };
//         }

//         let image = imgUrl.split(',')[1];
//         let type = imgUrl.split(',')[0].split('/')[1].split(';')[0];
//         let base64 = imgUrl.split(",")[1].split("=")[0];
//         let strLength = base64.length;
//         let fileLength = strLength - (strLength / 8) * 2;

//         let data = {
//             file_type: 'IMAGE',
//             files: [
//                 {
//                     file_size: Number((fileLength / 1048576).toFixed(3)),
//                     file_extension: type,
//                     data: image.toString(),
//                 },
//             ],
//         };

//         try {
//             const res = await axios.post(`${URL.upload}`, data, headers());
//             console.log(res, "after");

//             if (res.status === 200) {
//                 return {
//                     success: 1,
//                     file: {
//                         url: res.data?.[0], // Adjust based on your actual response structure
//                     },
//                 };
//             } else {
//                 return {
//                     success: 0,
//                     message: "Upload failed",
//                 };
//             }
//         } catch (error) {
//             console.error("API error:", error);
//             return {
//                 success: 0,
//                 message: "Upload failed",
//             };
//         }
//     };




//     const uploadByFile = async (file) => {
//         console.log(file, "file")
//         let reader = new FileReader()
//         reader.readAsDataURL(file);
//         console.log(reader.readAsDataURL, "RESULT")
//         reader.onloadend = () => {
//             toconvertImgtoimgUrl(reader.result)
//         }

//     }

//     const uploadByUrl = async (file) => {
//         return file;
//     };

//     return (
//         <>
//             <ReactEditorJS
//                 ref={editorRef}
//                 onChange={handleOnChange}
//                 tools={{
//                     header: {
//                         class: Header,
//                         inlineToolbar: ['link'],
//                     },
//                     paragraph: {
//                         class: Paragraph,
//                         inlineToolbar: true,
//                     },
//                     list: {
//                         class: List,
//                         inlineToolbar: true,
//                     },
//                     checklist: {
//                         class: Checklist,
//                         inlineToolbar: true,
//                     },
//                     linkTool: LinkTool,
//                     image: {
//                         class: ImageTool,
//                         config: {
//                             uploader: {
//                                 uploadByFile,
//                                 uploadByUrl
//                             },
//                         },
//                     },
//                     table: Table,
//                 }}
//             />

//          <h2>After converting into string format</h2>
//             <div
//                 dangerouslySetInnerHTML={{ __html: check }}
//                 style={{
//                     WebkitBoxOrient: 'vertical',
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     WebkitLineClamp: 2,
//                     whiteSpace: 'pre-wrap',
//                 }}
//             />
//             <button onClick={handleSave}>Save</button>
//         </>
//     );
// };

// export default EditorComponent1;
