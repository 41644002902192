import SearchIcon from "@mui/icons-material/Search";
import { styled } from '@mui/styles';

import InputBase from "@mui/material/InputBase";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  // borderRadius: theme.shape.borderRadius,
  backgroundColor: " #FFFFF",
  "&:hover": {
    backgroundColor: "#FFFFF",
  },
  // marginRight: theme.spacing(2),
  //   marginLeft: 0,
  width: "42%",
  // [theme.breakpoints.up("xs")]: {
  //   marginLeft: theme.spacing(3),
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: '20%',
  // },
  display: "flex",
  //   flexDirection: "row-reverse",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  // padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  border: "1px solid lightgrey",
  paddingLeft: 25,
  borderRadius: "12px",
  "& .MuiInputBase-input": {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingRight: `calc(1em + ${theme.spacing(4)})`,
    paddingLeft: "10px",
    // transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "inherit",
    // },
  },
}));

export default function ExploreTopicsSearchPage(props) {
  return (
    <Search className='explore-topics-search-wrapper'>
      <SearchIconWrapper>
        <SearchIcon style={{ color: "#227BC9" }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search topics, posts, articles"
        inputProps={{ "aria-label": "search" }}
        value={props.value}
        onChange={(e) => props.handleSearch(e.target.value)}
      />
    </Search>
  );
}
