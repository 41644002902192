import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator';
// import { useController } from "react-hook-form";

export const CustomValidator = (props) => {
    return (
        <TextValidator {...props} />
    )
}

export default function CustomInput({ Icon, type, handleChange, required, validators, ...props }) {

    let PIcon = Icon ? Icon : '';

    return (

        <TextValidator
            fullWidth

            // disabled={props.disabled || false}
            id={props.label}
            // multiline={props.multiline}
            // rows={props.rows}
            // minRows={props.minRows}
            // maxRows={props.maxRows}
            // label={props.label}
            variant='outlined'
            className='input_shadow input_padding'
            type={type ? type : "text"}
            // value={props.value}
            // onKeyDown={props.onKeyDown}
            onChange={(e) => handleChange(props.name, e.target.value)}
            // placeholder={props.placeholder}
            inputProps={{
                autoComplete: `${type === 'password' ? 'new-password' : "off"}`,
            }}
            InputProps={{
                endAdornment: (
                    Icon ? PIcon : ''
                )
            }}
            validators={required ? ['required'] : validators}
            // errorMessages={props.errorMessages}
            {...props}
        />
    )
}