import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button'
import { useHistory } from 'react-router';

export default function Publish(props) {

    const history = useHistory()

    return (
        <Dialog open={props?.open} onClose={props?.close} fullWidth>

            <DialogContent sx={{ p: 3, px: 0, textAlign: 'center' }}>
                <p className="draft-close">Do you wish to close ?</p>
                {/* <Button className="continue" onClick={(e)=> props.close()}>Continue Editing</Button> */}
                <p style={{ textAlign: 'right', padding: '0px 15px', marginTop: '15px' }}>
                    <Button className="close-article article-common" onClick={() => history.goBack()}>Yes</Button>
                    <Button className="article-common draft" onClick={(e) => props?.close()}>Continue Editing</Button>
                </p>
            </DialogContent>
        </Dialog>
    )
}