import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Card1 from '../../images/card1.svg'
import Card2 from '../../images/card2.svg'
import Card3 from '../../images/card3.svg'
import Card4 from '../../images/card4.svg'
import Card5 from '../../images/card5.svg'
import Card6 from '../../images/card6.svg'
import { makeStyles } from '@mui/styles';
import './signup.css'

const useStyles = makeStyles({
  title: {
    fontSize: '18px',
    fontWeight: 'medium',
    whiteSpace: 'break-spaces'
  },
  subtitle: {
    fontSize: '14px',
    whiteSpace: 'break-spaces',
    padding: '3px'
  },
});

export default function CardsList() {
  const classes = useStyles();
  return (
    <>
      <div className='SignupLargeDevices'>
        <ImageList sx={{ width: '100%', padding: '10px 10px', mb: 3 }} className="content-clr" cols={3} >
          {itemData?.map((item, i) => (
            <ImageListItem key={i} className="image-item">
              <img
                src={`${item.img}`}
                srcSet={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.subtitle}
                position="below"
                sx={{ width: '100%' }}
                classes={{ title: classes.title, subtitle: classes.subtitle }}

              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
      <div className='SignupsmallDevices'>
        <ImageList sx={{ width: '100%', padding: '10px 10px', mb: 3 }} className="content-clr" cols={1} >
          {itemData?.map((item, i) => (
            <ImageListItem key={i} className="image-item">
              <img
                src={`${item.img}`}
                srcSet={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.subtitle}
                position="below"
                sx={{ width: '100%' }}
                classes={{ title: classes.title, subtitle: classes.subtitle }}

              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </>
  );
}

const itemData = [
  {
    img: Card1,
    title: 'Practitionar Profile',
    subtitle: 'A profile that speaks about Your professional Achievements',
  },
  {
    img: Card2,
    title: 'New Opportunities',
    subtitle: 'Join CME Programs, Openings & Fellowships',
  },
  {
    img: Card3,
    title: 'Evidence Based Medicine',
    subtitle: 'Access to journals, Clinical Guidelines & latest Developments',
  },
  {
    img: Card4,
    title: 'Contribute to Profession',
    subtitle: 'Publish your research, patient information  Resources',
  },
  {
    img: Card5,
    title: 'Collaborated Practice',
    subtitle: `Learn together, attend webinar's, & knowledge sharing`,
  },
  {
    img: Card6,
    title: 'Connected Net-working',
    subtitle: `Connect your profile and publish your work to App's of your choice`,
  },

];
