import React, { useEffect, useState } from 'react';
import './Privacyandpolicy.css';
import { Box } from '@mui/material';
import Footer from '../SignUp/footer.js';

function Privacyandpolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Box className="elibrary_homepage_body1">
                <div id="policy_body_padding">
                    <div className='policy_subheader'>
                        <h2>Your Privacy Matters</h2>
                        <div id="policy_margin">
                            <p>Docsteth’s mission is to connect the healthcare practitioners/professionals
                                across the world, creating a more efficient and productive eco-system for access
                                to knowledge.Central to this mission is our commitment to be transparent about
                                the data we collect about you, how it is used and with whom it is shared.
                            </p>
                        </div>
                    </div>
                    <div className='setpolicy_text'>
                        This Privacy Policy applies when you use our Services. We offer
                        our users choices about the data we collect, use and share as
                        described in this Privacy Policy.
                    </div>

                    {/* -------------------------------first---------------------------------*/}

                    <div>
                        <div className='first_heading pt-3' >
                            <h1>1.Introduction</h1>
                            <p className='mt-10'>
                                We are a social network and online platform for professionals. Healthcare
                                Professionals can use our Services to find and be found for business opportunities,
                                to connect with others and find information. Our Privacy Policy applies to any
                                Member or Visitor to our Services.
                            </p>
                        </div>
                        <div className='subpart fw__normal mt-10'>
                            <p>
                                Our registered users (“Members”) share their professional identities, engage with
                                their network,exchange knowledge and professional insights, post and view relevant
                                content, learn and develop skills, and find business and career opportunities. Content
                                and data on some of our Services is viewable to non-members (“Visitors”).
                            </p>
                        </div>
                        <div className='pt-3 intr_sub_header'>
                            <h2>1.1. Services</h2>
                            <p className='font_text mt-10'>This Privacy Policy, including our Cookie Policy applies to your use of our
                                Services.This Privacy Policy applies to Docsteth.com, Docsteth-branded
                                apps, Docsteth web and mobile applications, communications and services (“Services”),
                                including off-site Services, and the “Apply with Docsteth” and “Share with Docsteth” plugins,
                                but excluding services that state that they are offered under a different privacy policy.
                            </p>
                        </div>
                        <div className='pt-3 intr_sub_header'>
                            <h2>1.2. Data Controllers and Contracting Parties</h2>
                            <p className='font_text mt-10'>
                                Docsteth will be the controller of your personal data provided to, or collected by or for, or processed in connection with our Services
                            </p>
                            <p className='font_text mt-10'>
                                As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.
                            </p>
                        </div>
                        <div className='pt-3 intr_sub_header'>
                            <h2>1.3. Change</h2>
                            <p className='font_text mt-10'>
                                Docsteth (“we” or “us”) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.
                            </p>
                            <p className='font_text mt-10'>
                                You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date.
                            </p>
                        </div>
                    </div>

                    {/* -------------------------------second---------------------------------*/}

                    <div className='2'>
                        <div className='first_heading pt-3' >
                            <h1>2. Data We Collect</h1>
                        </div>
                        <div className='2.1'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.1. Data You Provide to Us</h2>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.1.1.	Registration
                                </h4>
                                <p className='font_text mt-10'>
                                    Our registered users (“Members”) share their professional identities, engage with
                                    their network,exchange knowledge and professional insights, post and view relevant
                                    content, learn and develop skills, and find business and career opportunities. Content
                                    and data on some of our Services is viewable to non-members (“Visitors”).
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.1.2.	Profile
                                </h4>
                                <p className='font_text mt-10'>
                                    You have choices about the information on your profile, such as your education, work experience, photo, city or area and accomplishments.  You don’t have to provide additional information on your profile; however, profile information helps you to get more from our Services. It’s your choice whether to include sensitive information on your profile and to make that sensitive information public. Please do not post or add personal data to your profile that you would not want to be publicly available.
                                </p>
                            </div>

                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.1.3.	Posting and Uploading
                                </h4>
                                <p className='font_text mt-10'>
                                    We collect personal data from you when you provide, post or upload it to our Services, such as when you fill out a form, respond to a survey, or submit a resume or fill out a job application on our Services.
                                    we will collect/store your contacts. However, you can sync your calendar and address book for meeting information to keep growing your network by suggesting connections for you and others, and by providing information about events, e.g., times, places, attendees and contacts.
                                </p>
                                <p className='font_text mt-10'>
                                    You don’t have to post or upload personal data; though if you don’t, it may limit your ability to grow and engage with your network over our Services.
                                </p>
                            </div>
                        </div>

                        <div className='2.2'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.2. Data from Others</h2>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.2.1. Content and News
                                </h4>
                                <p className='font_text mt-10'>
                                    You and others may post content that includes information about you (as part of articles, posts, comments, videos) on our Services. We also may collect public information about you, such as professional-related news and accomplishments, and make it available as part of our Services, including, as permitted by your settings, in notifications to others of mentions in the news.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.2.2. Contact and Calendar Information
                                </h4>
                                <p className='font_text mt-10'>
                                    We receive personal data (including contact information) about you when others import or sync their contacts or calendar with our Services, associate their contacts with Member profiles, scan and upload business cards, or send messages using our Services (including invites or connection requests). If you or others opt-in to sync email accounts with our Services, we will also collect “email header” information that we can associate with Member profiles.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.2.3. Partnerss
                                </h4>
                                <p className='font_text mt-10'>
                                    We receive personal data (e.g., your job title and work email address) about you when you use the services of our customers and partners, such as employers or prospective employers and applicant tracking systems providing us job application data.
                                </p>
                            </div>
                            <div className='subpart fw__normal mt-20'>
                                <h4 className='fw__semibold font_text'>
                                    2.2.4. Related Companies and Other Services
                                </h4>
                                <p className='font_text mt-10'>
                                    We receive data about you when you use some of the other services provided by us or our affiliates, including CLINIVAULT Entreprise applications.
                                </p>
                            </div>
                        </div>
                        <div className='2.3'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.3. Service Use</h2>
                                <p className='font_text mt-10'>
                                    We log usage data when you visit or otherwise use our Services, including our sites, app and platform technology, such as when you view or click on content (e.g., learning video) or ads (on or off our sites and apps), perform a search, install or update one of our mobile apps, share articles or apply for jobs. We use log-ins, cookies, device information and internet protocol (“IP”) addresses to identify you and log your use.
                                </p>
                            </div>
                        </div>
                        <div className='2.4'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.4. Cookies and Similar Technologies</h2>
                                <p className='font_text mt-10'>
                                    We collect data through cookies and similar technologies.
                                    As further described in our Cookie Policy, we use cookies and similar technologies (e.g., pixels and ad tags) to collect data (e.g., device IDs) to recognize you and your device(s) on, off and across different services and devices where you have engaged with our Services. We also allow some others to use cookies as described in our Cookie Policy. If you are outside the Designated Countries, we also collect (or rely on others who collect) information about your device where you have not engaged with our Services (e.g., ad ID, IP address, operating system and browser information) so we can provide our members with relevant ads and better understand their effectiveness. Learn more. You can opt out from our use of data from cookies and similar technologies that track your behaviour on the sites of others for ad targeting and other ad-related purposes. For Visitors, the controls are here.
                                </p>
                            </div>
                        </div>
                        <div className='2.5'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.5. Your Device and Location</h2>
                                <p className='font_text mt-10'>
                                    When you visit or leave our Services (including some plugins and our cookies or similar technology on the sites of others),  We receive information about your network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. We will ask you to opt-in before we use GPS or other tools to identify your precise location.
                                </p>
                            </div>
                        </div>
                        <div className='2.6'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.6.	Messages</h2>
                                <p className='font_text mt-10'>
                                    If you communicate through our Services, all your messages are encrypted to protect your privacy. However, if any user reports your abuse of our communication services, we will access your data free and fair trial. All your communications are subjected to rule of law and under request for judicial system they will be disclosed in a sealed manner for judicial proceedings.
                                </p>
                            </div>
                        </div>
                        <div className='2.7'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.7.	Organisation Provided Information</h2>
                                <p className='font_text mt-10'>
                                    User can add organisation pages and connect people associated with the organisation. We store your professional details and display when appropriate.
                                </p>
                            </div>
                        </div>
                        <div className='2.8'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.8.	Sites and Services of Others</h2>
                                <p className='font_text mt-10'>
                                    We get data when you visit sites that include our ads, cookies or some of our plugins or when you log-in to others’ services with your Docsteth account.
                                </p>
                                <p className='font_text mt-10'>
                                    We receive information about your visits and interaction with services provided by others when you log-in with Docsteth or visit others’ services that include some of our plugins (such as “Apply with Docsteth”) or our ads, cookies or similar technologies.
                                </p>
                            </div>
                        </div>
                        <div className='2.9'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>2.9.	Other</h2>
                                <p className='font_text mt-10'>
                                    We are improving our Services, which means we get new data and create new ways to use data.
                                </p>
                                <p className='font_text mt-10'>
                                    Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we collect, use or share your data, we will notify you and may also modify this Privacy Policy.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------third---------------------------------*/}

                    <div className='3'>
                        <div className='first_heading pt-3' >
                            <h1>3.	How We Use Your Data</h1>
                            <p className='mt-10'>
                                We use your data to provide, support, personalize your Docsteth experience
                                How we use your personal data will depend on which Services you use, how you use those Services and the choices you make in your settings. We use the data that we have about you to provide and personalize our Services, including with the help of automated systems and inferences we make, so that our Services (including ads) can be more relevant and useful to you and others.
                            </p>
                        </div>
                        <div className='3.1'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.1.	Services</h2>
                                <p className='font_text mt-10'>
                                    Our Services help you connect with others, find and be found for work and business opportunities, stay informed, get training and be more productive.
                                </p>
                                <p className='font_text mt-10'>
                                    We use your data to authorize access to our Services and honor your settings.
                                </p>
                            </div>
                        </div>
                        <div className='3.2'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.2.	Stay Connected</h2>
                                <p className='font_text mt-10'>
                                    Our Services allow you to stay in touch and up to date with colleagues, partners, clients, and other professional contacts. To do so, you can “connect” with the professionals who you choose, and who also wish to “connect” with you. Subject to your and their settings, when you connect with other Members, you will be able to search each other's connections in order to exchange professional opportunities.
                                </p>
                                <p className='font_text mt-10'>
                                    We use data about you (such as your profile, profiles you have viewed or data provided through address book uploads or partner integrations) to help others find your profile, suggest connections for you and others.
                                </p>
                                <p className='font_text mt-10'>
                                    It is your choice whether to invite someone to our Services, send a connection request, or allow another Member to become your connection. When you invite someone to connect with you, your invitation will include your network and basic profile information (e.g., name, profile photo, job title, region). We will send invitation reminders to the person you invited. Only mutual connections will be shared with your connections.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.3.	Stay Informed</h2>
                                <p className='font_text mt-10'>
                                    Our Services allow you to stay informed about news, events and ideas regarding professional topics you care about, and from professionals you respect. Our Services also allow you to improve your professional skills, or learn new ones. We use the data we have about you (e.g., data you provide, data we collect from your engagement with our Services and inferences we make from the data we have about you), to personalize our Services for you, such as by recommending or ranking relevant content and conversations on our Services. We also use the data we have about you to suggest skills you could add to your profile and skills that you might need to pursue your next opportunity. So, if you let us know that you are interested in a new skill (e.g., by watching a learning video), we will use this information to personalize content in your feed, suggest that you follow certain members on our site, or suggest related learning content to help you towards that new skill. We use your content, activity and other data, including your name and photo, to provide notices to your network and others. For example, subject to your settings, we may notify others that you have updated your profile, posted content, took a social action, used a feature, made new connections or were mentioned in the news.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.4.	Career</h2>
                                <p className='font_text mt-10'>
                                    Our Services allow you to explore careers, evaluate educational opportunities, and seek out, and be found for, career opportunities. Your profile can be found by those looking to hire (for a job or a specific task) or be hired by you. We will use your data to recommend jobs or mentees, show you and others relevant professional contacts (e.g., who work at a company, in an industry, function or location or have certain skills and connections). You can signal that you are interested in changing jobs and share information with recruiters. We will use your data to recommend jobs to you and you to recruiters. We may use automated systems to provide content and recommendations to help make our Services more relevant to our Members, Visitors and customers. Keeping your profile accurate and up-to-date may help you better connect to others and to opportunities through our Services.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.5.	Productivity</h2>
                                <p className='font_text mt-10'>
                                    Our Services allow you to collaborate with colleagues, search for potential clients, customers, partners and others to do business with. Our Services allow you to communicate with other Members and schedule and prepare meetings with them. If your settings allow, we scan messages to provide “bots” or similar tools that facilitate tasks such as scheduling meetings, drafting responses, summarizing messages or recommending next steps.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.6.	Communications</h2>
                                <p className='font_text mt-10'>
                                    We contact you and enable communications between Members. We offer settings to control what messages you receive and how often you receive some types of messages.
                                </p>
                                <p className='font_text mt-10'>
                                    We will contact you through email, mobile phone, notices posted on our websites or apps, messages to your Docsteth inbox, and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use our Services, network updates, reminders, job suggestions and promotional messages from us and our partners. You may change your communication preferences at any time. Please be aware that you cannot opt out of receiving service messages from us, including security and legal notices.
                                </p>
                                <p className='font_text mt-10'>
                                    We also enable communications between you and others through our Services, including for example invitations, InMail, groups and messages between connections.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.7.	 Ad Content & Choices</h2>
                                <p className='font_text mt-10'>
                                    We do not allow display of advertising content to your time line. Sharing advertising content through any of Docsteth communication channels is strictly prohibited. We do not share your contact details with any third party, in any case.
                                </p>
                                <p className='font_text mt-10'>
                                    Ad content is confined to the designated space provided in the application. Medscope Medical Device Gallery and Formulary Updates contain Promoted content.
                                </p>
                                <p className='font_text mt-10'>
                                    We do not offer any assurance on accuracy of information provided by advertisers.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.8.	Marketing</h2>
                                <p className='font_text mt-10'>
                                    We promote our Services to you and others. In addition to advertising our Services, we use Members’ data and content for invitations and communications promoting membership and network growth, engagement and our Services, such as by showing your connections that you have used a feature on our Services.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.9.	Developing Services and Research </h2>
                                <p className='font_text mt-10'>
                                    Surveys
                                </p>
                                <p className='font_text mt-10'>
                                    Polls and surveys are conducted by us and others through our Services. You are not obligated to respond to polls or surveys, and you have choices about the information you provide. You may opt-out of survey invitations.
                                </p>
                                <p className='font_text mt-10'>
                                    However, surveys conducted by Docsteth and privacy protected and used only  internally by Docsteth team, for improving our services.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.10.	Customer Support</h2>
                                <p className='font_text mt-10'>
                                    We use data to help you and fix problems.
                                </p>
                                <p className='font_text mt-10'>
                                    We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.11.	Insights That Do Not Identify You</h2>
                                <p className='font_text mt-10'>
                                    We use data to generate insights that do not identify you. We use your data to produce and share insights that do not identify you. For example, we may use your data to generate statistics about our members, their profession or industry, to calculate ad impressions served or clicked on, or to publish visitor demographics for a Service or create demographic workforce insights.
                                </p>
                            </div>
                            <div className='pt-3 intr_sub_header'>
                                <h2>3.12.	Security and Investigations</h2>
                                <p className='font_text mt-10'>
                                    We use data for security, fraud prevention and investigations. We use your data (including your communications) for security purposes or to prevent or investigate possible fraud or other violations of our User Agreement and/or attempts to harm our Members, Visitors or others.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------FOURTH---------------------------------*/}

                    <div className='4'>
                        <div className='first_heading pt-3' >
                            <h1>4.	How We Share Information</h1>
                        </div>
                        <div className='4.1'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.1.	Our Services</h2>
                                <p className='font_text mt-10'>
                                    Any data that you include on your profile and any content you post or social action (e.g., likes, follows, comments, shares) you take on our Services will be seen by others, consistent with your settings.
                                </p>
                            </div>
                        </div>
                        <div className='4.2'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.2.	Profile</h2>
                                <p className='font_text mt-10'>
                                    Your profile is fully visible to all Members and customers of our Services. Subject to your settings, it can also be visible to others on or off of our Services (e.g., Visitors to our Services or users of third- party search engines). As detailed in our Help Center, your settings, degree of connection with the viewing Member, the subscriptions they may have, their usage of our Services, access channels and search types (e.g., by name or by keyword) impact the availability of your profile and whether they can view certain fields in your profile.
                                </p>
                            </div>
                        </div>
                        <div className='4.3'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.3.	Posts, Likes, Follows, Comments, Messages</h2>
                                <p className='font_text mt-10'>
                                    Our Services allow viewing and sharing information including through posts, likes, follows and comments.
                                </p>
                                <p className='font_text mt-10'>
                                    When you share an article or a post (e.g., an update, image, video or article) publicly it can be viewed by everyone and re-shared anywhere (subject to your settings). Members, Visitors and others will be able to find and see your publicly-shared content, including your name (and photo if you have provided one).
                                </p>
                            </div>
                        </div>
                        <div className='4.4'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.4.	Others’ Services</h2>
                                <p className='font_text mt-10'>
                                    You may link your account with others’ services so that they can look up your contacts’ profiles, post your shares on such platforms, or enable you to start conversations with your connections on such platforms. Excerpts from your profile will also appear on the services of others.
                                </p>
                                <p className='font_text mt-10'>
                                    Subject to your settings, other services may look up your profile. When you opt to link your account with other services, personal data will become available to them. The sharing and use of that personal data will be described in, or linked to, a consent screen when you opt to link the accounts. For example, you may link your Twitter or WeChat account to share content from our Services into these other services, or your email provider may give you the option to upload your Docsteth contacts into its own service. Third-party services have their own privacy policies, and you may be giving them permission to use your data in ways we would not. You may revoke the link with such accounts.
                                </p>
                            </div>
                        </div>
                        <div className='4.4'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.5. Related Services</h2>
                                <p className='font_text mt-10'>
                                    We share your data across our different Services and Docsteth affiliated entities.
                                </p>
                                <p className='font_text mt-10'>
                                    We will share your personal data with our affiliates to provide and develop our Services. We may combine information internally across the different Services covered by this Privacy Policy to help our Services be more relevant and useful to you and others. For example, we may personalize your feed or job recommendations based on your learning history.
                                </p>
                            </div>
                        </div>
                        <div className='4.6'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.6. Service Providers</h2>
                                <p className='font_text mt-10'>
                                    Docsteth is a trade mark of Vollmond innovations and social entrepreneurs Pvt ltd.  We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.
                                </p>
                            </div>
                        </div>
                        <div className='4.7'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.7.	Legal Disclosures</h2>
                                <p className='font_text mt-10'>
                                    We may need to share your data when we believe it’s required by law or to help protect the rights and safety of you, us or others.
                                </p>
                                <p className='font_text mt-10'>
                                    It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you; (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect the security or integrity of our Services (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of Docsteth, our Members, personnel or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand. To learn more, see our Data Request Guidelines and Transparency Report.
                                </p>
                            </div>
                        </div>
                        <div className='4.8'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>4.8.	Change in Control or Sale</h2>
                                <p className='font_text mt-10'>
                                    We may share your data when our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.
                                </p>
                                <p className='font_text mt-10'>
                                    We may choose to share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------Fifth---------------------------------*/}

                    <div className='5'>
                        <div className='first_heading pt-3' >
                            <h1>5.	Your Choices & Obligations</h1>
                        </div>
                        <div className='5.1'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>5.1.	Data Retention</h2>
                                <p className='font_text mt-10'>
                                    We keep most of your personal data for as long as your account is open.
                                    We generally retain your personal data as long as you keep your account open or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. Even if you only use our Services when looking for a new job every few years, we will retain your information and keep your profile open, unless you close your account. In some cases we choose to retain certain information (e.g., insights about Services use) in a depersonalized or aggregated form.
                                </p>
                            </div>
                        </div>
                        <div className='5.2'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>5.2.	Rights to Access and Control Your Personal Data</h2>
                                <p className='font_text mt-10'>
                                    You can access or delete your personal data. You have many choices about how your data is collected, used and shared.
                                </p>
                                <p className='font_text mt-10'>
                                    We provide many choices about the collection, use and sharing of your data, from deleting or correcting data you include in your profile and controlling the visibility of your posts to advertising opt-outs and communication controls. We offer you settings to control and manage the personal data we have about you.
                                </p>
                            </div>
                        </div>
                        <div className='5.3'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>5.3.	For personal data that we have about you, you can:</h2>
                                <p className='font_text mt-10'>
                                    Delete Data: You can ask us to erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you).
                                </p>
                                <p className='font_text mt-10'>
                                    Change or Correct Data: You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases, particularly if it’s inaccurate.
                                </p>
                                <p className='font_text mt-10'>
                                    Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or some of your personal data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal data is inaccurate or unlawfully held).
                                </p>
                                <p className='font_text mt-10'>
                                    Right to Access and/or Take Your Data: You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form.
                                </p>
                                <p className='font_text mt-10'>
                                    Visitors can learn more about how to make these requests here. You may also contact us using the contact information below, and we will consider your request in accordance with applicable laws.
                                </p>
                                <p className='font_text mt-10'>
                                    Residents in the Designated Countries and other regions may have additional rights under their laws.
                                </p>
                            </div>
                        </div>
                        <div className='5.4'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>5.4.	Account Closure</h2>
                                <p className='font_text mt-10'>
                                    We keep some of your data even after you close your account.
                                </p>
                                <p className='font_text mt-10'>
                                    If you choose to close your Docsteth account, your personal data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.
                                </p>
                                <p className='font_text mt-10'>
                                    We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse (e.g., if we have restricted your account for breach of our Professional Community Policies), enforce our User Agreement, or fulfill your request to "unsubscribe" from further messages from us. We will retain de-personalized information after your account has been closed.
                                </p>
                                <p className='font_text mt-10'>
                                    Information you have shared with others (e.g., through InMail, updates or group posts) will remain visible after you close your account or delete the information from your own profile or mailbox, and we do not control data that other Members have copied out of our Services. Groups content and ratings or review content associated with closed accounts will show an unknown user as the source. Your profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------Fifth---------------------------------*/}

                    <div className='6'>
                        <div className='first_heading pt-3' >
                            <h1>6.	Other Important Information</h1>
                        </div>
                        <div className='6.1'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>6.1.	Security</h2>
                                <p className='font_text mt-10'>
                                    We monitor for and try to prevent security breaches. Please use the security features available through our Services.
                                </p>
                                <p className='font_text mt-10'>
                                    We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. Please visit our Safety Center for additional information about safely using our Services, including two-factor authentication.
                                </p>
                            </div>
                        </div>
                        <div className='6.2'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>6.2.	Cross-Border Data Transfers</h2>
                                <p className='font_text mt-10'>
                                    We store and use your data outside your country.
                                </p>
                                <p className='font_text mt-10'>
                                    We process data both inside and outside of the United States and rely on legally-provided mechanisms to lawfully transfer data across borders. Learn more. Countries where we process data may have laws which are different from, and potentially not as protective as, the laws of your own country.
                                </p>
                            </div>
                        </div>
                        <div className='6.2'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>6.2.	Cross-Border Data Transfers</h2>
                                <p className='font_text mt-10'>
                                    We store and use your data outside your country.
                                </p>
                                <p className='font_text mt-10'>
                                    We process data both inside and outside of the United States and rely on legally-provided mechanisms to lawfully transfer data across borders. Learn more. Countries where we process data may have laws which are different from, and potentially not as protective as, the laws of your own country.
                                </p>
                            </div>
                        </div>
                        <div className='6.3'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>6.3.	Lawful Bases for Processing</h2>
                                <p className='font_text mt-10'>
                                    We have lawful bases to collect, use and share data about you. You have choices about our use of your data. At any time, you can withdraw consent you have provided by going to settings.
                                </p>
                                <p className='font_text mt-10'>
                                    We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you (e.g., to deliver the Docsteth Services you have requested) and “legitimate interests.” Learn more.
                                </p>
                                <p className='font_text mt-10'>
                                    Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object. Learn More. If you have any questions about the lawful bases upon which we collect and use your personal data, please contact our Data Protection Officer here.
                                </p>
                            </div>
                        </div>
                        <div className='6.4'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>6.4.	Direct Marketing and Do Not Track Signals</h2>
                                <p className='font_text mt-10'>
                                    Our statements regarding direct marketing and “do not track” signals.
                                </p>
                                <p className='font_text mt-10'>
                                    We currently do not share personal data with third parties for their direct marketing purposes without your permission. Learn more about this and about our response to “do not track” signals.
                                </p>
                            </div>
                        </div>
                        <div className='6.5'>
                            <div className='pt-3 intr_sub_header'>
                                <h2>6.5.	Contact Information</h2>
                                <p className='font_text mt-10'>
                                    You can contact us or use other options to resolve any complaints.
                                </p>
                                <p className='font_text mt-10'>
                                    If you have questions or complaints regarding this Policy, please first contact Docsteth online. You can also reach us by physical mail. If contacting us does not resolve your complaint, you have more options. Residents in the Designated Countries and other regions may also have the right to contact our Data Protection Officer here. If this does not resolve your complaint, Residents in the Designated Countries and other regions may have more options under their laws.
                                    You can write to us about your privacy and data concerns at <span><a style={{ color: '#3e7ea5' }} href='https://www.gmail.com/' target='_blank'>privacy@docsteth.com</a></span>
                                </p>
                            </div>
                        </div>
                        <div className='6.6 terms_end' >
                            <div className='pt-3 intr_sub_header'>
                                <h2>6.6.	Consent</h2>
                                <p className='font_text mt-10'>
                                    Where we process data based on consent, we will ask for your explicit consent. You may withdraw your consent at any time, but that will not affect the lawfulness of the processing of your personal data prior to such withdrawal. Where we rely on contract, we will ask that you agree to the processing of personal data that is necessary for entering into or performance of your contract with us. We will rely on legitimate interests as a basis for data processing where the processing of your data is not overridden by your interests or fundamental rights and freedoms.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </Box>
        </>
    )
}

export default Privacyandpolicy
