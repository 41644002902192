import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';

function Webinar() {

    const data = {title:'Event Title here Maximum Upto One Like', organizers: '', from:'', to:'', sponcers:''}
    let webinarData = []
    for(let i=0; i<10; i++){
        webinarData.push(data);
    }
  return (<>
    <div className='webinar-card-container'>
    {webinarData.map((item,index)=>{
        return <article key={index} className='articles'>
            <div className='article-pic'></div>
            <div className='article-desc'>
                <h3>{item.title}</h3>
                <p>Organizers:</p>
                <div className='article-date-time'>
                    <p>From Date Time:</p>
                    <p>To Date Time:</p>
                    <ArrowForwardIosIcon/>
                </div>
                <p>Sponsers:</p>
            </div>
        </article>
        
    })}
    
    </div>
    <div style={{width:'100%', textAlign:'center'}}>
    <Button variant='contained' className='webinar-bottom-button'>View All</Button>
    </div>  
    </>
  )
}

export default Webinar