import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import axios from "axios"
import { useState, useEffect } from "react"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import { headers } from "../../../../redux/Actions/connectActions"
import URL from "../../../apiurl"
import StarIcon from '@mui/icons-material/Star';
import MonitorIcon from '@mui/icons-material/Monitor';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Rating from '@mui/material/Rating';
import { ImgView } from "./appview"
import DocstethLogo from '../../../../images/docsteth.png'
import ClinivaultLogo from '../../../../images/clinivault.png'

const LayoutComp = ({ divider, ...props }) => {
    return (
        <>
            <div className="textCenter" style={{ flex: 1 }}>
                {props.children}
            </div>
            {!divider && <Divider orientation="vertical" variant="middle" flexItem />}
        </>
    )
}

const TypoComp = ({ name, bold, display, ...props }) => {
    return (
        <Typography component="p" className={bold ? "font-bold" : display ? "d-flex e-align jc__center" : ''} {...props}>{name}</Typography>
    )
}

const AppDesc = ({ heading, description, mt }) => {
    return (
        <>
            <TypoComp name={heading} sx={{ mt: mt || 2, fontSize: '17px', fontWeight: 500 }} />
            <p style={{ fontWeight: 300 }}>{description}</p>
        </>
    )
}

const availableFor = {
    Docsteth: DocstethLogo,
    Clinivault: ClinivaultLogo
}

const AppData = () => {

    const [data, setData] = useState({})
    const [view, setView] = useState(2)
    const { appname, id } = useParams()

    let appid = !id ? appname.split('-')[1] : id

    useEffect(() => {
        axios.get(URL.findAppById + appid, headers()).then(res => setData(res.data.data))
    }, [appid])



    return (
        <Box sx={{ p: 1 }}>
            <div className="space_btw_center">
                <div className="space_btw_center">
                    <ImgView logo={data.logo} size />
                    <Typography component="div" sx={{ pl: 2 }}>
                        <TypoComp variant="h6" bold name={<a href={data.link}>{data.appName}</a>} />
                        <p>{data.captionAndTagLine}</p>
                    </Typography>
                </div>
                <div>
                    {console.log(data, "datadata")}
                    {
                        data.subscribed ? <a href={data.link}>
                            <Button className="app_gallery" sx={{ px: '16px!important' }}>{"Subscribed"}</Button>
                        </a> :
                            <Link to={`/subscribe/${data.appName}/${appid}`}>
                                <Button className="app_gallery" sx={{ px: '16px!important' }}>{"Subscribe"}</Button>
                            </Link>
                    }
                    <p className="font-title textCenter">{data.paid ? "Paid Application" : "Free Application"}</p>
                </div>
            </div>
            <Grid container>
                <Grid item md={9} xs={12}>
                    {/* <Box className="space_around_center" sx={{ mt: 2, padding: '10px', boxShadow: '2px 3px 10px grey', borderRadius: '10px', }}> */}
                    <Box sx={{ mt: 2, padding: '10px', boxShadow: '2px 3px 10px grey', borderRadius: '10px', display: { xs: "block", sm: "flex" } }}>
                        <LayoutComp>
                            <TypoComp name="Rating" bold />
                            <TypoComp name={''} sx={{ fontSize: '13px' }} />
                            <TypoComp name={<>{data.totalRating} <Rating sx={{ color: '#E2FF00' }} name="read-only" precision={0.5} value={data.totalRating} readOnly /></>} display />
                        </LayoutComp>

                        <LayoutComp>
                            <TypoComp name="Category" bold />
                            <TypoComp name={<StarIcon />} />
                            <TypoComp name={data.category} sx={{ fontSize: '13px' }} />
                        </LayoutComp>

                        <LayoutComp>
                            <TypoComp name="Devices" bold />
                            <TypoComp name={<><MonitorIcon style={{ color: '#0012A0' }} /><SmartphoneIcon style={{ color: '#397B00' }} /></>} />
                            <TypoComp name="Browser & App" sx={{ fontSize: '13px' }} />
                        </LayoutComp>

                        <LayoutComp divider>
                            <TypoComp name="Available for" bold />
                            <p className="d-flex e-align jc__center" style={{ marginLeft: '10px' }}>
                                {data.availableFor?.split(',').map(val => <img src={availableFor[val]} alt="logo" />)}
                            </p>
                        </LayoutComp>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
                <TypoComp name="Screenshots" sx={{ fontSize: '19px', fontWeight: 500 }} />
                <span style={{ fontSize: '17px' }}>Desktop</span> <span style={{ color: 'grey' }}>|</span> <span style={{ fontSize: '17px' }}>Mobile</span>
            </Box>

            <AppDesc heading="Pre-requisite" description="Must have docsteth account for individual use or clinivault account for hospital" />
            <AppDesc heading="Purpose" description="This app is for doctors to get book and manage patient appointments, get patient appointment requests from multiple patient service applications" />
            <AppDesc heading="Description of App" description={data.description} />
            <AppDesc heading="Pricing" description="Pay As You Go (0.5$ Per Out-Patient Record)" />
            <AppDesc heading="Developer" description="Vollmond Innovations and Social Entrepreneurs Private limited" />

            <div className="space_btw_center">
                <TypoComp name={`Reviews ${data.reviews?.length > 0 ? `(${data.reviews?.length} Reviews)` : ''}`} sx={{ my: 2, fontSize: '17px', fontWeight: 500 }} />
                {data.reviews?.length > 2 && <p className="cursor" style={{ color: 'blue' }} onClick={() => setView(data.reviews?.length)}>View all</p>}
            </div>

            <Grid container spacing={2} sx={{ marginBottom: { xs: "60px", md: 0 } }}>
                {data.reviews?.length > 0 ? data.reviews?.slice(0, view).map((review, i) => (
                    <Grid item xs={12} sm={6} md={6} key={i} >
                        <div style={{ backgroundColor: '#DBF1FC', padding: '16px', borderRadius: '10px' }} key={review.id}>
                            <div className="space_btw_center">
                                <AppDesc heading={review.userName} mt="1" />
                                {/* <p className="space_btw_center">
                                    {review.rating}&nbsp;
                                    <Rating sx={{color: 'black'}} size="small" name="read-only" precision={0.5} value={review.rating} readOnly />
                                </p> */}
                            </div>
                            <AppDesc description={review.review} mt="8px" />
                        </div>
                    </Grid>
                )) : <Grid item xs={12} sx={{ pt: '0px!important' }}><TypoComp name="No reviews" /></Grid>}
            </Grid>
        </Box >
    )
}

export default AppData