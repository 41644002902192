
import React, { useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";

const Media = ({ docFeed }) => {
    const [expanded, setExpanded] = useState(false);
    const image = docFeed?.sourceLink?.replace(/,\s*$/, "");


    const handleExpandClick = () => {
        // setExpanded(true);
    };
    return (
        <Box sx={{ width: "100%" }}>
            {/* <Typography sx={{ p: "0px 10px", fontSize: 14, fontWeight: 500 }}>

                {docFeed?.description ? (
                    <>
                        {expanded
                            ? docFeed.description
                            : `${docFeed.description.slice(0, 75)}`}
                        {docFeed.description.length > 80 ? (
                            <Typography
                                component={"span"}
                                onClick={handleExpandClick}
                                sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                            >
                                {expanded ? "...see less" : "...see more"}{" "}
                            </Typography>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <Box sx={{ height: { sm: 63, md: 42 } }}></Box>
                )}
            </Typography> */}


            <div className="font-medium" style={{ height: "60px" }}>

                <div style={{ padding: "0px 10px", fontSize: "14px" }}>{docFeed?.description?.length > 100 ? docFeed?.description?.slice(0, 100) : docFeed?.description}</div>
                <span>{
                    docFeed?.description?.length > 100 && <Typography
                        component={"span"}
                        sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                    >
                        {expanded ? "...see less" : "...see more"}{" "}
                    </Typography>
                }
                </span>
            </div>

            <div style={{ width: "100%", height: "290px" }}>
                <CardMedia
                    className="object-fill"
                    component="img"
                    style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        width: "100%",
                        height: "100%"
                    }}
                    src={image}
                    alt="Paella dish"
                />

            </div>
        </Box>
        // {docFeed.sourceLink ? (
        //     <Box>
        //         <CardMedia
        //             className="object-fill"
        //             component="img"
        //             style={{
        //                 objectFit: "contain",
        //                 objectPosition: "center",
        //                 width: "100%",
        //                 height: "100%"
        //             }}
        //             src={image}
        //             alt="Paella dish"
        //         />
        //     </Box>
        // ) : null}
    )
}
export default Media;