import React, { useState, useEffect } from 'react'
import axios from 'axios';
import URL from '../../apiurl';
import UserService from '../../../services/UserService';
import Post from '../../Molecule/Post/Post'
import { headers } from '../../../redux/Actions/connectActions'
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';


function Bookmarkposts() {
    const [post, setPost] = useState([])
    const [pageno, setPageno] = useState(0)
    const [noMore, setNoMore] = useState(true)

    const limit = 25


    const postsdata = () => {
        // feed/getcollectionbyuserid/1425645477?limit=1&offset=0
        axios.get(`${URL.getcollectionsbyuserid}/${UserService.getUserId()}?limit=${limit}&offset=${pageno}`,
            headers()
        ).then((res) => {
            setPageno(pageno + limit)
            const nextdata = res.data.results ? res.data.results : res.data

            if (pageno === 0) {
                setPost(nextdata)
            } else {
                // const nextdata = res.data.results
                setPost([...post, ...nextdata])
            }
            if (nextdata.length === 0 || nextdata.length < limit) {
                setNoMore(false)
                console.log(noMore, 'noMore')
            }
        })
    }
    useEffect(() => {
        postsdata()
    }, [])
    return (
        <div>
            <InfiniteScroll
                dataLength={post.length}
                next={postsdata}
                hasMore={noMore}
                style={{ overflow: "hidden" }}
                scrollThreshold="75%"
                loader={<div style={{ textAlign: 'center' }}><CircularProgress disableShrink /></div>}
                endMessage={<p style={{ textAlign: 'center', margin: "20px" }}> No more posts</p>}
            >
                {post.map(post => (
                    <div key={post.id}>
                        <Post
                            post={post} bookmark getBookmarks={postsdata}
                        />
                    </div>
                ))
                }
            </InfiniteScroll>

        </div >
    )
}

export default Bookmarkposts