import React, { useState } from 'react';
import '../../Layout/E-library/elib.css';
import '../../Molecule/Sidebar/Sidebar.css';
import '../../utility/style.css';
import './ElibrarySidebar.css'
import PracticeTools from "../../../images/stethoscope.svg";
import DecisionTools from "../../../images/decisiontools.svg";
import neon from "../../Atom/svg/neon.svg";
import lexi from "../../Atom/svg/lexi.svg";
import bookmark from "../../../images/bookmarks.svg";
import { useRouteMatch } from 'react-router';
import ebmpractice_kit from '../../Atom/modifiedicons/ebmpractice_kit.svg';
import drug_info from '../../Atom/modifiedicons/drug_info.svg';
import video_guides from '../../Atom/modifiedicons/video_guides.svg';
import protocols from '../../Atom/modifiedicons/protocols.svg';
import standard from '../../Atom/modifiedicons/standard.svg';
import dose_calculator from '../../Atom/modifiedicons/dose_calculator.svg';
import bio_calculator from '../../Atom/modifiedicons/bio_calculator.svg';
import statical_tool from '../../Atom/modifiedicons/statical_tool.svg';
import check_list from '../../Atom/modifiedicons/check_list.svg';
import knowledge_base from '../../Atom/modifiedicons/knowledge_base.svg';
import research_generals from '../../Atom/modifiedicons/research_generals.svg';
import clinical_calculator from '../../Atom/modifiedicons/clinical_calculator.svg';
import interactive_apps from '../../../images/interactiveapps.svg'
import e_book from '../../Atom/modifiedicons/e_book.svg'
import { Divider } from '@mui/material';
import { Link } from "react-router-dom";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { Button } from '@mui/material'

const SidebarOpt = ({ img, url, name, alt, scroll, val }) => {

    const active = sessionStorage.getItem("e-active") || '1'

    React.useEffect(() => {
        if (document.location.href.includes('/specialities')) {
            sessionStorage.setItem('e-active', '1')
        }
    })

    return (
        <>
            <div className="d-flex sidebar__padding ta__left" onClick={() => sessionStorage.setItem("e-active", val)}>
                {img ? <img src={img} alt={name} /> : ''}
                <div className={`${active === val ? 'font-bold' : ''} font-caption sidebar__ml`} onClick={scroll}><Link to={url} style={{ color: '#000000E6' }}>{name}</Link></div>
            </div>
            <Divider />
        </>
    )
}

const SidebarOpt1 = ({ img, url, name, alt, scroll, val }) => {

    const active = sessionStorage.getItem("e-active") || '1'

    React.useEffect(() => {
        if (document.location.href.includes('/specialities')) {
            sessionStorage.setItem('e-active', '1')
        }
        else if (document.location.href.includes('/ebmkithomepage')) {
            sessionStorage.setItem('e-active', '0')
        }
    })

    return (
        <>
            <div className="d-flex sidebar__padding ta__left" onClick={() => sessionStorage.setItem("e-active", val)}>
                {img ? <img src={img} alt={name} /> : ''}
                <div className={`${active === val ? '' : ''} font-caption sidebar__ml`} onClick={scroll}><Link to={url} style={{ color: '#00000099' }}>{name}</Link></div>
            </div>
            <Divider />
        </>
    )
}
export default function ElibrarySideBar(props) {
    const { url } = useRouteMatch();

    return (
        <>
            <div className="allElibrary_laptop">
                <div style={{ paddingBottom: '20px' }}>
                    <div className="elib bg__white border_img_radius">
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100 ta__left" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                <img src={PracticeTools} alt="light" />
                                <div className="font-caption fw__semibold  sidebar__ml white"><Link to={`${url}/specialities`} style={{ color: 'white' }}>Practice Tools</Link></div>
                            </div>
                        </div>
                        <SidebarOpt val="1" img={ebmpractice_kit} url={`${url}/specialities`} name='Evidence Review' scroll={props.scroll} />
                        <SidebarOpt val="2" img={drug_info} url={`${url}/drugspecialities`} name='Drug Information' scroll={props.scroll} />
                        <SidebarOpt val="3" img={protocols} url={`${url}/development`} name='Protocols & Guidelines' scroll={props.scroll} />
                        <SidebarOpt val="4" img={video_guides} url={`${url}/development`} name='Video Guides' scroll={props.scroll} />
                        <SidebarOpt val="5" img={standard} url={`${url}/development`} name='Standard Practices' scroll={props.scroll} />
                        {/* </div> */}
                        {/* card */}
                        {/* <div className="elib sidebar__mt bg__white"> */}
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100 ta__left">
                                <img src={DecisionTools} alt="box" />
                                <div className="font-caption fw__semibold  sidebar__ml white">Decision Tools</div>
                            </div>
                        </div>
                        <SidebarOpt val="6" img={dose_calculator} url={`${url}/drug-calculator`} name='Dose Calculators' scroll={props.scroll} />
                        <SidebarOpt val="7" img={bio_calculator} url={`${url}/biomedical-calculator`} name='Biomedical Calculators' scroll={props.scroll} />
                        <SidebarOpt val="8" img={clinical_calculator} url={`${url}/clinical-assessment`} name='Assessment Tools' scroll={props.scroll} />
                        <SidebarOpt val="9" img={statical_tool} url={`${url}/development`} name='Statistical Tools Kit' scroll={props.scroll} />
                        <SidebarOpt val="10" img={check_list} url={`${url}/check-list`} name='Checklists' scroll={props.scroll} />

                        {/* <Divider/> */}
                        <div className="sidebar__stat">
                            <div className="sidebar__substat bg-blue width__100 ta__left">
                                <img src={interactive_apps} alt="interact" />
                                <div className="font-caption fw__semibold  sidebar__ml white" >Interactive applications</div>
                            </div>
                        </div>
                        <><div className="d-flex sidebar__padding ta__left" style={{ padding: '2px 10px' }}>
                            <img src={neon} alt={"NeonAI"} />
                            <div className="font-caption sidebar__ml" onClick={props.scroll}><Link to={`${url}/neonatai`} style={{ color: '#000000E6' }}>{'NeonatAI'}</Link></div>
                        </div>
                            <Divider /></>
                        <div className="d-flex ta__left" style={{ padding: '1px 10px' }}>
                            <img src={lexi} alt={"LEXICOM"} />
                            <div className="font-caption sidebar__ml" onClick={props.scroll}><Link to={`${url}/development`} style={{ color: '#000000E6' }}>{'LEXICOM'}</Link></div>
                        </div>
                        <Divider />

                        {/* </div> */}
                        {/* card */}
                        {/* <div className="elib sidebar__mt bg__white"> */}
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100  ta__left">
                                <img src={knowledge_base} alt="knowledge" />
                                <div className="font-caption fw__semibold  sidebar__ml white">Knowledge Base</div>
                            </div>
                        </div>
                        <SidebarOpt val="11" img={research_generals} url={`${url}/development`} name='Research Journals' scroll={props.scroll} />
                        <SidebarOpt val="12" img={e_book} url={`${url}/development`} name='E-books' scroll={props.scroll} />
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100  ta__left">
                                <img src={bookmark} alt="Bookmark" />
                                <div className="font-caption fw__semibold  sidebar__ml white">Bookmarks</div>
                            </div>
                        </div>
                        <SidebarOpt val="13" url={`${url}/development`} name='Evidence Based Articles' scroll={props.scroll} />
                        <SidebarOpt val="14" url={`${url}/development`} name='Research Readings' scroll={props.scroll} />
                        <SidebarOpt val="15" url={`${url}/development`} name='Notes' scroll={props.scroll} />
                        <SidebarOpt val="16" url={`${url}/development`} name='Annotations' scroll={props.scroll} />
                        <SidebarOpt val="17" url={`${url}/development`} name='Posts' scroll={props.scroll} />
                    </div>
                </div>
            </div>
            <div></div>


            {/* RESPONSIVE */}


            <div className="allElibrary_responsive">
                <div className="" style={{ paddingBottom: '20px' }}>
                    <div className="elib bg__white">
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100 ta__left" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                <img src={PracticeTools} alt="light" />
                                <div className="font-caption fw__semibold  sidebar__ml white"><Link to={`${url}/specialities`} style={{ color: 'white' }}>Evidence Based Medicine</Link></div>
                            </div>
                        </div>
                        <SidebarOpt1 val="1" img={ebmpractice_kit} url={`${url}/specialities`} name='Evidence Review' scroll={props.scroll} />
                        <SidebarOpt1 val="2" img={drug_info} url={`${url}/drugspecialities`} name='Drug Information' scroll={props.scroll} />
                        <SidebarOpt1 val="3" img={protocols} url={`${url}/development`} name='Protocols & Guidelines' scroll={props.scroll} />
                        <SidebarOpt1 val="4" img={video_guides} url={`${url}/development`} name='Video Guides' scroll={props.scroll} />
                        <SidebarOpt1 val="5" img={standard} url={`${url}/development`} name='Standard Practices' scroll={props.scroll} />
                        {/* </div> */}
                        {/* card */}
                        {/* <div className="elib sidebar__mt bg__white"> */}
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100 ta__left">
                                <img src={DecisionTools} alt="box" />
                                <div className="font-caption fw__semibold  sidebar__ml white">Clinical Tools</div>
                            </div>
                        </div>
                        <SidebarOpt val="6" img={dose_calculator} url={`${url}/drug-calculator`} name='Dose Calculators' scroll={props.scroll} />
                        <SidebarOpt val="7" img={bio_calculator} url={`${url}/biomedical-calculator`} name='Biomedical Calculators' scroll={props.scroll} />
                        <SidebarOpt val="8" img={clinical_calculator} url={`${url}/clinical-assessment`} name='Assessment Tools' scroll={props.scroll} />
                        <SidebarOpt val="9" img={statical_tool} url={`${url}/development`} name='Statistical Tools Kit' scroll={props.scroll} />
                        <SidebarOpt val="10" img={check_list} url={`${url}/check-list`} name='Checklists' scroll={props.scroll} />

                        {/* <Divider/> */}
                        <div className="sidebar__stat">
                            <div className="sidebar__substat bg-blue width__100 ta__left">
                                <img src={interactive_apps} alt="interact" />
                                <div className="font-caption fw__semibold  sidebar__ml white" >Interactive applications</div>
                            </div>
                        </div>
                        <><div className="d-flex sidebar__padding ta__left" style={{ padding: '2px 10px' }}>
                            <img src={neon} alt={"NeonAI"} />
                            <div className="font-caption sidebar__ml" onClick={props.scroll}><Link to={`${url}/neonatai`} style={{ color: '#000000E6' }}>{'NeonatAI'}</Link></div>
                        </div>
                            <Divider /></>
                        <div className="d-flex ta__left" style={{ padding: '1px 10px' }}>
                            <img src={lexi} alt={"LEXICOM"} />
                            <div className="font-caption sidebar__ml" onClick={props.scroll}><Link to={url} style={{ color: '#000000E6' }}>{'LEXICOM'}</Link></div>
                        </div>
                        <Divider />

                        {/* </div> */}
                        {/* card */}
                        {/* <div className="elib sidebar__mt bg__white"> */}
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100  ta__left">
                                <img src={knowledge_base} alt="knowledge" />
                                <div className="font-caption fw__semibold  sidebar__ml white">Knowledge Base</div>
                            </div>
                        </div>
                        <SidebarOpt1 val="11" img={research_generals} url={`${url}/development`} name='Research Journals' scroll={props.scroll} />
                        <SidebarOpt1 val="12" img={e_book} url={`${url}/development`} name='E-books' scroll={props.scroll} />
                        <div className="sidebar__stat">
                            <div className="elib__substat bg-blue width__100  ta__left">
                                <img src={bookmark} alt="Bookmark" />
                                <div className="font-caption fw__semibold  sidebar__ml white">Bookmarks</div>
                            </div>
                        </div>
                        <SidebarOpt1 val="13" url={`${url}/development`} name='Evidence Based Articles' scroll={props.scroll} />
                        <SidebarOpt1 val="14" url={`${url}/development`} name='Research Readings' scroll={props.scroll} />
                        <SidebarOpt1 val="15" url={`${url}/development`} name='Notes' scroll={props.scroll} />
                        <SidebarOpt1 val="16" url={`${url}/development`} name='Annotations' scroll={props.scroll} />
                        <SidebarOpt1 val="17" url={`${url}/development`} name='Posts' scroll={props.scroll} />

                    </div>
                </div>
            </div>

        </>

    )
}