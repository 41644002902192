import React from 'react'
import Photogrid from "./photogrid";
import '../Feed/Feed.css'
// import Mobileviewpost from './Mobileviewpost';

export default function photo(props) {
    // console.log(props)
    // let data = props.url.split(",")
    // let image = data.slice(0, -1)
    // console.log(data)
    // console.log(image)
    return (
        <>
            {/* <div className='view_post_Desktop'> */}
            <div className="grid-image">
                <Photogrid {...props}
                    images={props.url} //required
                    maxWidth={'100%'} //optional according to your need
                />
            </div>
            {/* </div> */}
            {/* <div className='view_post_Mobile'>
                <div className="grid-image">
                    <Mobileviewpost {...props}
                        images={props.url} //required
                        maxWidth={'100%'} //optional according to your need
                    />
                </div>
            </div> */}
        </>

    )
}
