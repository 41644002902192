import { Grid } from '@mui/material'
import React from 'react'
import AppVault from './AppVault'
import MarketPlaceSidebar from './MarketPlaceSidebar'
import { createTheme, ThemeProvider } from '@mui/material';
import { handleActiveValue } from '../../../redux/Actions/actions';
import { useDispatch } from 'react-redux';
import './MarketPlaceSidebar.css';
import MedscopeHomepage from './homepage/MedscopeHomepage';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

function MarketPlace() {

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(handleActiveValue(4))
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} className='spacing_mediscope' sx={{ height: { md: '88vh', xs: 'auto' }, width: '100%!important', m: '0px!important' }}>
        {/* <Grid item md={3} xs={12} className="e-leftsidebar" sx={{ pl: '8px!important', width: { md: '315px' }, height: 'inherit!important' }}>
          <MarketPlaceSidebar />
        </Grid> */}
        {/* <Grid item md={3} className="grid-hide" /> */}

        <Grid item md={12} xs={12} sm={12} sx={{ pb: 0, mb: { md: 0, xs: '12%' }, height: 'inherit!important' }} style={{ overflowY: 'hidden' }} className="e-rightsidebar">
          {/* <AppVault /> */}
          <MedscopeHomepage />
        </Grid>

        {/* <Button variant="contained" style={{position:'fixed',bottom:'0px',right:'0px',fontSize:'18px'}} startIcon={<MessageIcon/>}>Messages</Button> */}
      </Grid>
    </ThemeProvider>
  )
}

export default MarketPlace
