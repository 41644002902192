import { Button, Grid, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import ElibrarySideBar from './ElibrarySideBar'
import { Box } from '@mui/material'
import { useRef } from 'react'
import './ElibraryRightContainer.css'
import { createTheme, ThemeProvider } from '@mui/material';
import { handleActiveValue } from '../../../redux/Actions/actions';
import { useDispatch } from 'react-redux';
import Ebmkithomepage from '../EbmKit/Ebmkithomepage'
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './ElibraryRightContainer.css';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

function ElibraryPage(props) {
  console.log(props, 'h')
  const myRef = useRef(null)
  const dispatch = useDispatch()
  const [show, setShow] = useState(true)
  const screenWidth = useMediaQuery('(min-width:960px)')

  const handleshow = () => {
    setShow(!show)
  }


  React.useEffect(() => {
    if (window.location.pathname !== '/elib/ebmkithomepage') {
      setShow(false)
    }
    else {
      setShow(true)
    }
  }, [window.location.pathname])

  React.useEffect(() => {
    dispatch(handleActiveValue(3))
  }, [])

  const executeScroll = () => {
    var element = document.getElementById('targetElement');
    var headerOffset = 56.5;
    var elementPosition = myRef.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
  return (
    <ThemeProvider theme={theme}>
      <Box className='elibrary_remove'>
        {screenWidth ?
          <div className='largedevices'>
            <Grid container spacing={2} sx={{ height: { md: 'calc(100vh - 90px)', xs: '100%' }, overflow: { md: 'hidden', xs: 'visible' } }}>
              <Grid item md={3} xs={12} className="e-leftsidebar"
                sx={{ width: { md: '315px' }, pl: { lg: '8px!important' } }}
              >
                <ElibrarySideBar scroll={executeScroll} />
              </Grid>
              <Grid item md={3} className="grid-hide" />

              <Grid item md={9} sm={12} sx={{ display: { xs: 'none', sm: 'block' }, pb: 0, mb: { md: 0, xs: '4%' }, width: { lg: '79.5%!important', md: '75%' }, maxWidth: { lg: '79.5%!important', md: '75%' }, height: { md: 'inherit', xs: '100%' } }} id="#targetElement" ref={myRef} style={{ overflowY: 'hidden' }} className="e-rightsidebar" >
                {props.children}
              </Grid>
              {/* <Button variant="contained" style={{position:'fixed',bottom:'10px',right:'0px',fontSize:'18px'}} startIcon={<MessageIcon/>}>Messages</Button> */}
            </Grid>
          </div> :
          <div className='smalldevices' style={{ height: 'calc(100vh - 155px)', overflowY: 'auto' }}>
            <Grid container spacing={2} sx={{ height: { md: '88vh', xs: '100%' }, overflow: { md: 'hidden', xs: 'visible' } }}>
              {(show) ?
                <Grid item xs={12} className="e-leftsidebar">
                  <ElibrarySideBar scroll={handleshow} />
                </Grid>
                :
                <Grid item xs={12} sm={12} sx={{ pb: 0, mb: { md: 0, xs: '4%' }, height: { md: 'inherit', xs: '100%' } }} id="#targetElement" ref={myRef} className="e-rightsidebar">
                  {/* <Button onClick={handleshow} style={{ color: "black", fontWeight: '550', textTransform: 'capitalize' }}><ArrowBackIcon />Back</Button> */}
                  {props.children}
                </Grid>
              }
            </Grid>
          </div>
        }
      </Box>
    </ThemeProvider>
  )
}

export default ElibraryPage
