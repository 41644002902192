import * as React from 'react';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import './peer.css'
import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPeople } from '../../redux/Actions/connectActions';
// import Requests from './requests';
// import SuggestedPeople from './suggestedPeople';
// import SuggestedOrganisation from './suggestedOrganisations';
// import SuggestedTopics from './topics'

export default function Header({ HeaderName, children, displayname }) {
  // console.log(displayname, HeaderName)
  // const { path } = useRouteMatch();
  // console.log(path)
  const { pathname } = useLocation();
  // console.log(pathname)
  const [show, setShow] = React.useState(false)
  const [limit, setLimit] = React.useState(15)
  const dispatch = useDispatch();

  const handlemore = () => {
    let api = 'suggestedPeople'
    let key = true
    setLimit(limit + 15)
    dispatch(getPeople(api, key, limit + 15))
  }

  return (
    <>
      <div className='List_Desktop'>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper', pb: 0 }}
          subheader={
            <div className='req_button_display'>
              <div>
                <ListSubheader className="sub-header text-primary">
                  {HeaderName}
                </ListSubheader>
              </div>
              <div>
                <ListSubheader className="sub-header text-primary">
                  {
                    // (HeaderName === 'Requests') ? <Link to={`/connect/requests`}><Button className='show__all'>{displayname}</Button></Link>
                    //   : 
                    (HeaderName === 'Suggested People') ? <Link to={`/connect/suggestedpeople`}><Button className='show__all'>{displayname}</Button></Link>
                      : (HeaderName === 'Suggested Organisations') ? <Link to={`/connect/suggestedorganisations`}><Button className='show__all' id='line_height'>{displayname}</Button></Link>
                        : (HeaderName === 'Suggested Topics') ? <Link to={`/connect/suggestedtopics`}><Button className='show__all'>{displayname}</Button></Link>
                          : null
                  }
                  {/* <a href={`/connect/requests`}><Button className='show__all'>{displayname}</Button></a> */}
                </ListSubheader>
              </div>
            </div>
          }
        >
          {children}
        </List>
        {/* {
          (pathname.includes('/connect/suggestedpeople') && HeaderName === 'Suggested People') ?
            <div>
              <Button onClick={handlemore} className='load_more_btn'>Load more</Button>
            </div> : ""
        } */}
      </div>
      <div className='List_Mobile'>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper', pb: 0 }}
          subheader={
            <div className='req_button_display'>
              <div>
                <ListSubheader id='line_height' className="sub-header text-primary">{HeaderName}</ListSubheader>
              </div>
              <div className='request_display_mobile'>
                {(displayname) ?
                  <ListSubheader className="sub-header text-primary">
                    {/* <a href={`/connect/requests`} onClick={() => setShow(!show)} ><Button className='show__all'>{displayname}</Button></a> */}
                    {
                      (HeaderName === 'Requests') ? <Link to={`/connect/requests`}><Button className='show__all'>{displayname}</Button></Link>
                        : (HeaderName === 'Suggested People') ? <Link to={`/connect/suggestedpeople`}><Button className='show__all'>{displayname}</Button></Link>
                          : (HeaderName === 'Suggested Organisations') ? <Link to={`/connect/suggestedorganisations`}><Button className='show__all' id='line_height'>{displayname}</Button></Link>
                            : (HeaderName === 'Suggested Topics') ? <Link to={`/connect/suggestedtopics`}><Button className='show__all'>{displayname}</Button></Link>
                              : null
                    }
                  </ListSubheader> : <Link to='/connect'><ClearIcon></ClearIcon></Link>
                }
                {/* <div className='clear__button'><Link to='/connect'><ClearIcon></ClearIcon></Link></div>
                <ListSubheader className="sub-header text-primary">
                  <a href={`/connect/requests`} onClick={() => setShow(!show)} >
                    <Button className='show__all'>showall</Button></a></ListSubheader> */}

              </div>
            </div>
          }
        >
          {children}
        </List>
        {/* {
          (pathname.includes('/connect/suggestedpeople') && HeaderName === 'Suggested People') ?
            <div>
              <Button onClick={handlemore} className='load_more_btn'>Load more</Button>
            </div> : ""
        } */}
      </div>
    </>
  );
}

// (HeaderName === 'Suggested People') ?
//   <div>
//     <Button onClick={handlemore} className='load_more_btn'>Load more</Button>
//   </div> : ""
