import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CustomTextField from "../Fields/TextFields";
import Header from './header'
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import axios from "axios";
import URL from "../apiurl";
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import UserService from "../../services/UserService";
import Snackbar from '@mui/material/Snackbar';
import "../common/common.css"
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CssTextField from '../Fields/CssTextField';
import Checkbox from '@mui/material/Checkbox';
// eslint-disable-next-line no-unused-vars
const filter = createFilterOptions();
let icon1 = <h5 style={{ position: "absolute", right: "10px", color: "#A2A2A2" }}>@docsteth</h5>



export default function Account(props) {
    // console.log(props)
    const [state, setState] = React.useState({
        email: "",
        prefix: "",
        firstName: "",
        lastName: "",
        suffix: [],
        DisplayName: "",
        verifyvia: '',
        otp: "",
        userName: "",
        password: "",
        confirmPassword: "",
    });

    const [suffixes, setSuffixes] = React.useState(['MBBS', "MD"])
    const [create, setcreate] = useState(false);
    const [verifyUsername, setVerifyUserName] = useState(null);
    const [verifyemail, setVerifyemail] = useState(null)
    const history = useHistory();
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [location, setLocation] = React.useState('')
    const [form1, setForm1] = useState(true)
    const [form2, setForm2] = useState(false)
    const [accept, setAccept] = React.useState(false)
    const [avaliable, setAvaliable] = React.useState(false)
    const [possible, setPossible] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const [counter, setCounter] = React.useState(30);
    const [showFinalBtn, setShowFinalBtn] = useState(false);
    let validate = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // let usernamevalidate = new RegExp("^[A-Za-z0-9]+$");
    // let toEliminateNumericValue = new RegExp("^[0-9]+$");
    // let toEliminateNumericValue = new RegExp("^[0-9]+$");
    let usernamevalidate = new RegExp("^[a-zA-Z][a-zA-Z0-9]*$");
    // const [credentials, setCredentials] = useState(false)

    let icon = null;
    const handleChange = (name, value) => {
        if (name === "prefix") {
            setState({ ...state, prefix: value, DisplayName: value + " " + state?.firstName + " " + state?.lastName });
        }
        else if (name === 'firstName') {
            setState({ ...state, firstName: value, DisplayName: state?.prefix + " " + value + " " + state?.lastName });
        }
        else if (name === 'lastName') {
            setState({ ...state, lastName: value, DisplayName: state?.prefix + " " + state?.firstName + " " + value });
        }
        else {
            setState({ ...state, [name]: value });
        }
    };



    useEffect(() => {
        ValidatorForm?.addValidationRule('isUser', (value) => {
            if (value !== null) {
                // const regex = new RegExp("^[A-Za-z0-9]+$");
                const regex = new RegExp("^[a-zA-Z][a-zA-Z0-9]*$");
                // const regex = new RegExp("/^(?!\d)[0-9]*$/");
                // const regex = new RegExp("^(?![\d ])[a-zA-Z0-9]*$");
                let val = regex.test(value)
                if (!val) {
                    return false;
                }
                return true;
            }
            else {
                return true
            }
        });
    }, [state.userName])


    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9"
        document.body?.appendChild(script)
    }, [])

    useEffect(() => {
        if (props?.location?.prevUrl !== '/' || props?.location?.prevUrl === null) {
            history.push("/");
        }
        const Location = window?.navigator && window?.navigator?.geolocation
        if (Location) {
            Location?.getCurrentPosition((position) => {
                setLocation(`${position?.coords?.latitude} , ${position?.coords?.longitude}`)
            }, (error) => {
                setAlert({ open: true, msg: `${error}` });
            })
        }
    }, []);
    useEffect(() => {
        let suffix = [...suffixes]
        setSuffixes(suffix)
    }, [])



    const emailIdCheck = (name, value) => {
        handleChange(name, value)
        if (value?.length >= 8) {
            axios.get(`${URL.verifyemailId}${value}`).then(res => {
                if (res?.status === 200) {
                    if (res.data?.status === 200) {
                        setVerifyemail(<BsCheckCircle style={{ color: "green", fontWeight: "600" }} />)
                        setPossible(false)
                    } else if (res.data?.status === 409) {
                        setVerifyemail(<AiOutlineCloseCircle style={{ color: "red", fontWeight: "600" }} />)
                        setPossible(true)
                        setAlert({ open: true, msg: `${res.data.message}` });
                    }

                } else {
                    // console.log(res.data.message, "11")
                    setVerifyemail(null)
                    // setAlert({ open: true, msg: `${res.data.message}` });
                }
            }, error => {
                // setAlert({ open: true, msg: `${error}` });
            })
        }
        else {
            setVerifyemail(null)
            // setPossible(false)
        }
    }

    //----------------------SEND EMAIL-OTP--------------------

    const handleEmailOTP = () => {
        if (!show) {
            setShow(true)
        }
        // const data = {
        //     metadata: {
        //         location: location
        //     },
        //     email: state.email,
        // }
        const data = {
            "messagetype": "OTP",
            "apptype": "docsteth",
            "phoneNumber": "",
            "email": state.email,
        }
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9", { action: "submit" })
                .then(token => {
                    // axios.post(`${process.env.REACT_APP_API_URL}v1/addOTP`, data, { headers: { "docsteth": token } }).then(res => {
                    axios.post(`${process.env.REACT_APP_API_URL_OTP}signupotp`, data, { headers: { "Authorization": `Bearer ${token}` } }).then(res => {

                        if (res["status"] === 200) {
                            if (res.data?.status === 201) {
                                setAlert({ open: true, msg: `${res.data.message}` });
                            } else if (res.data?.status === 409) {
                                setAlert({ open: true, msg: `${res.data.message}` })
                            }
                        } else {
                            setAlert({ open: true, msg: "Internal server error" });
                        }
                    }, error => {
                        setAlert({ open: true, msg: "Error " });
                    })
                })
        })
    }

    // const timeinterval = () => {
    //     if (state.verifyvia === "OTP Via Email" || state.verifyvia === "OTP Via SMS") {
    //         const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    //         return () => clearInterval(timer);
    //     }
    // }
    //----------------------SEND MOBILE-OTP--------------------
    const handleSMSOTP = () => {
        if (!show) {
            setShow(true)
        }
        let phone = props?.location?.state?.phoneNumber
        let number = phone?.replace(/[^A-Z0-9]+/ig, "")
        // const data = {
        //     metadata: {
        //         location: location
        //     },
        //     // phone: props.location.state.phoneNumber
        //     phoneNumber: `+${number}`,
        // }
        const data = {
            "messagetype": "OTP",
            "apptype": "docsteth",
            "phoneNumber": `+${number}`,
            "email": ""
        }
        // timeinterval();
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9", { action: "submit" })
                .then(token => {
                    // axios.post(`${process.env.REACT_APP_API_URL}v1/addOTP`, data, { headers: { "docsteth": token } }).then(res => {
                    axios.post(`${process.env.REACT_APP_API_URL_OTP}signupotp`, data, { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
                        if (res["status"] === 200) {
                            if (res.data?.status === 201) {
                                setAlert({ open: true, msg: `${res.data.message}` });
                            } else if (res.data?.status === 409) {
                                setAlert({ open: true, msg: `${res.data.message}` })
                            }
                        } else {
                            setAlert({ open: true, msg: "Internal server error" });
                        }
                    }, error => {
                        setAlert({ open: true, msg: "Error " });
                    })
                })
        })
    }

    useEffect(() => {
        if (state.verifyvia === "OTP Via Email") {
            handleEmailOTP();
        }
    }, [state.verifyvia])


    useEffect(() => {
        if (state.verifyvia === "OTP Via SMS") {
            handleSMSOTP();
        }
    }, [state.verifyvia])

    useEffect(() => {
        if (state.verifyvia === "OTP Via Email" || state.verifyvia === "OTP Via SMS") {
            const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [state.verifyvia, counter])

    const countertime = () => {
        if (state.verifyvia === "OTP Via Email") {
            handleEmailOTP();
            setCounter(30)
            // timeinterval();
            const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
        if (state.verifyvia === "OTP Via SMS") {
            handleSMSOTP();
            setCounter(30)
        }
    }

    // old code
    // const verifySubmit = () => {
    //     let phone = props.location.state.phoneNumber
    //     let number = phone.replace(/[^A-Z0-9]+/ig, "")
    //     const data = {
    //         otp: state.otp,
    //         metadata: {
    //             location: location
    //         },
    //         email: (state.verifyvia === "OTP Via Email") ? state.email : null,
    //         phoneNumber: (state.verifyvia === "OTP Via SMS") ? `+${number}` : null
    //     };
    //     axios.post(`${process.env.REACT_APP_API_URL}v1/verifyOTP`, data).then(res => {
    //         if (res["status"] === 200) {
    //             setAlert({ open: true, msg: `${res.data.message}` });
    //             // setOtp(true)
    //             handlenextpage();
    //         } else {
    //             setAlert({ open: true, msg: `${res.data.message}` });
    //         }
    //     }, error => {
    //         setAlert({ open: true, msg: "Either otp is expired or invalid." });
    //     })
    // };

    const verifySubmit = () => {
        let phone = props?.location?.state?.phoneNumber
        let number = phone?.replace(/[^A-Z0-9]+/ig, "")

        const data = {
            phoneNumber: (state.verifyvia === "OTP Via SMS") ? `+${number}` : '',
            otp: state.otp,
            email: (state.verifyvia === "OTP Via Email") ? state.email : '',
        }


        axios.post(`${process.env.REACT_APP_API_URL_OTP}verifyOtp`, data,).then(res => {

            if (res["status"] === 200) {
                // setAlert({ open: true, msg: `${res.data.message}` });
                setAlert({ open: true, msg: "Sucessfully valid Given otp" });
                // setOtp(true)
                handlenextpage();
            } else {
                setAlert({ open: true, msg: `${res.data.message}` });
            }
        }, error => {
            setAlert({ open: true, msg: "Either otp is expired or invalid." });
        })



    };

    const handlenextpage = () => {
        setForm1(false)
        setForm2(false)

    }

    const userNameCheck = (name, value) => {
        handleChange(name, value)
        let usname = `${value}@docsteth`
        if (value?.length > 3) {
            axios.get(`${URL.verifyUserName}${usname}`).then(res => {
                if (res?.status === 200) {
                    if (res.data?.status === 200) {
                        setVerifyUserName(<BsCheckCircle style={{ color: "green", fontWeight: "600" }} />)
                        setAvaliable(true)
                    } else if (res.data?.status === 409) {
                        setVerifyUserName(<AiOutlineCloseCircle style={{ color: "red", fontWeight: "600" }} />)
                        setAvaliable(false)
                        // setAlert({ open: true, msg: `${res.data.message}` });
                    }
                } else {
                    setVerifyUserName(null)
                    setAlert({ open: true, msg: `${res.data.message}` });
                }
            }, error => {
                setAlert({ open: true, msg: `${error}` });
            })

        } else {
            setVerifyUserName(null)
        }
    }

    const handleConfirmPassword = (name, value) => {
        setState({ ...state, [name]: value });
        if (state.password === value) {
            setcreate(true)
        } else if (state.password !== value) {
            setcreate(false)
        }
    }


    useEffect(() => {
        if (accept === true) {
            setState({
                ...state,
                DisplayName: state.DisplayName
                    ? state.DisplayName + " " + state.suffix
                    : state.prefix +
                    " " +
                    state.firstName +
                    " " +
                    state.lastName +
                    " " +
                    state.suffix,
            });
        }
        else {
            if (state.DisplayName) {
                let name = state?.DisplayName?.split(" ")
                name = name?.filter(k => k?.replace(/ /g, '') != state?.suffix ? name = k : '')
                setState({ ...state, DisplayName: name?.toString()?.replace(/,/g, ' ') })
            }
        }
    }, [accept])

    // useEffect(() => {
    //   if (accept === true) {
    //     setState((prevState) => ({
    //       ...prevState,
    //       DisplayName: prevState.DisplayName
    //         ? prevState.DisplayName +
    //           ` <span style="font-size: smaller">${prevState.suffix}</span>`
    //         : `${prevState.prefix} ${prevState.firstName} ${prevState.lastName} <span style="font-size: smaller">${prevState.suffix}</span>`,
    //     }));
    //   } else if (state.DisplayName) {
    //     let name = state.DisplayName.split(" ");
    //     name = name.filter((k) => k.replace(/ /g, "") !== state.suffix);
    //     setState((prevState) => ({
    //       ...prevState,
    //       DisplayName: name.join(" "),
    //     }));
    //   }
    // }, [accept]);



    // console.log(state, "0101")

    let phone = (props?.hasOwnProperty("location")) ? props?.location?.state?.phoneNumber : null
    let number = phone?.replace(/[^A-Z0-9]+/ig, "")
    let country = "India"

    const Register = () => {

        setShowFinalBtn(true);
        const data = {
            userName: `${state.userName}@docsteth`,
            metadata: { "location": `${location}` },
            fullName: state.firstName + " " + state.lastName,
            profession: props?.location?.state?.profession,
            profileType: "PUBLIC",
            captionAndTaglines: "Hey! I'm using Docsteth.",
            password: state.password,
            phoneNumber: `+${number}`,
            registrationPlatform: 'browser',
            practioner: {
                resourceType: "Practitioner",
                active: false,
                name: [
                    {
                        use: "official",
                        text: (state.DisplayName) ? (state.DisplayName) : state.prefix + " " + state.firstName + " " + state.lastName,
                        given: [state.firstName],
                        prefix: [(state.prefix) ? state.prefix : ""],
                        suffix: [...state.suffix],
                        family: state.lastName

                    }
                ],
                telecom: [
                    {
                        system: "phone",
                        value: `+${number}`,
                        use: "work",
                        public: "SHOW",
                        verify: (state.verifyvia === "OTP Via SMS") ? true : false
                    },
                    {
                        system: "email",
                        value: state.email,
                        use: "work",
                        public: "SHOW",
                        verify: (state.verifyvia === "OTP Via Email") ? true : false
                    }
                ],
                address: [
                    {
                        country: country
                    }
                ],
                photo: [{ "url": null }],
                birthDate: props?.location?.state?.dob,
            }
        }
        // console.log(data, "data")

        axios.post(`${URL.registerUser}`, data).then(res => {

            if (res['status'] === 200) {
                setAlert({ open: true, msg: `${res.data.message}` });
                // history.push('/')
                history.push('/');
                setShowFinalBtn(false)
                return UserService.doLogin()
            } else {
                setAlert({ open: true, msg: `${res.data.message}` });
                setShowFinalBtn(false)
            }
        }, error => {
            setAlert({ open: true, msg: `${error}` });
            setShowFinalBtn(false)
        })


        // old code
        // const data = {
        //     userName: `${state.userName}@docsteth`,
        //     metadata: { "location": `${location}` },
        //     fullName: state.firstName + " " + state.lastName,
        //     profession: props.location.state.profession,
        //     profileType: "PUBLIC",
        //     captionAndTaglines: "Hey! I'm using Docsteth.",
        //     password: state.password,
        //     phoneNumber: `+${number}`,
        //     registrationPlatform: 'browser',
        //     practioner: {
        //         resourceType: "Practitioner",
        //         active: true,
        //         name: [
        //             {
        //                 use: "official",
        //                 text: (state.DisplayName) ? (state.DisplayName) : state.prefix + " " + state.firstName + " " + state.lastName,
        //                 given: [state.firstName],
        //                 prefix: [(state.prefix) ? state.prefix : ""],
        //                 suffix: [state.suffix],
        //                 family: state.lastName

        //             }
        //         ],
        //         telecom: [
        //             {
        //                 system: "phone",
        //                 value: `+${number}`,
        //                 use: "work",
        //                 public: "SHOW",
        //                 verify: (state.verifyvia === "OTP Via SMS") ? true : false
        //             },
        //             {
        //                 system: "email",
        //                 value: state.email,
        //                 use: "work",
        //                 public: "SHOW",
        //                 verify: (state.verifyvia === "OTP Via Email") ? true : false
        //             }
        //         ],
        //         address: [
        //             {
        //                 country: country
        //             }
        //         ],
        //         photo: [{ "url": null }],
        //         birthDate: props.location.state.dob,
        //     }
        // }
        // console.log(data)
        // axios.post(`${URL.registerUser}`, data).then(res => {
        //     if (res['status'] === 200) {
        //         setAlert({ open: true, msg: `${res.data.message}` });
        //         // history.push('/')
        //         history.push('/completeprofile')
        //         return UserService.doLogin()
        //     } else {
        //         setAlert({ open: true, msg: `${res.data.message}` });
        //     }
        // }, error => {
        //     setAlert({ open: true, msg: `${error}` });
        // })
    }


    return (
        <div>
            <Header />

            {form1 ?

                /* ----------------FIRST FORM-------------------- */
                (
                    <div className="form-bg  form1-bg" >
                        <div className="text-container">
                            <div className="main-text-container">
                                <h1 className="head " >A Profile thats Speaks about You</h1>
                                <p className="para" >Start with adding your Name and how people search for your</p>
                            </div>
                            <div className="bottom-text-container">
                                <p>Share your profile with other healthcare </p>
                                <p> apps and social media platforms </p>
                            </div>

                        </div>
                        <div className="form-background">
                            <div style={{ textAlign: "center", paddingTop: "30px", fontSize: "22px", color: "#3E7EA5" }}>
                                <p>Create</p>
                                <p >Your Docsteth Profile</p>
                            </div>
                            <ValidatorForm
                                onSubmit={Register}
                                onError={(errors) => { setAlert({ open: true, msg: 'Please fill required fields' }); console.log(errors, "errors") }}
                            >
                                <div>
                                    <CustomTextField
                                        label="Email ID"
                                        value={state.email}
                                        name="email"
                                        placeholder="Enter Your Email ID"
                                        handleChange={emailIdCheck}
                                        validators={["required", "isEmail"]}
                                        errorMessages={["Please Enter Your Email", "Enter Valid Email"]}
                                    />
                                    {
                                        (state?.email && possible == true) ?
                                            <h4 style={{ color: "red", display: "flex", justifyContent: "flex-start" }}>
                                                <AiOutlineCloseCircle style={{ color: "red", fontWeight: "600", paddingRight: "10px" }} />Email Already Exist</h4> : ""
                                    }
                                </div>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div id="prefix_input">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={['Dr', "Mr", "Ms"]}
                                            getOptionLabel={(option) => option}
                                            isOptionEqualToValue={(option, value) => option === value || ''}
                                            onChange={(event, newValue) => {
                                                handleChange('prefix', newValue)
                                            }}
                                            value={state.prefix}
                                            // fullWidth
                                            renderInput={(params) =>
                                                <CssTextField style={{ marginTop: '17px', height: '56px' }}
                                                    {...params} label="Prefix" />}
                                        />
                                    </div>
                                    <div id="firstname_input">
                                        <CustomTextField
                                            label="First Name"
                                            value={state.firstName}
                                            name="firstName"
                                            placeholder="Enter Your FirstName"
                                            handleChange={handleChange}
                                            validators={["required"]}
                                            errorMessages={["Please Enter Your firstName"]}
                                        />
                                    </div>
                                </div>
                                <CustomTextField
                                    label="Last Name"
                                    value={state.lastName}
                                    name="lastName"
                                    placeholder="Enter Your Last Name"
                                    handleChange={handleChange}
                                    validators={["required"]}
                                    errorMessages={["Please Enter Your LastName"]}
                                />
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue !== '') {
                                            filtered.push(
                                                `${params.inputValue}`
                                            );
                                        }
                                        return filtered;
                                    }}
                                    disablePortal
                                    // id="combo-box-demo"
                                    options={suffixes}
                                    getOptionLabel={(option) => option}
                                    isOptionEqualToValue={(option, value) => option === value || ''}
                                    onChange={(event, newValue) => {
                                        handleChange('suffix', newValue)
                                    }}
                                    value={state.suffix}
                                    fullWidth
                                    renderInput={(params) =>
                                        <CssTextField style={{ marginTop: '17px', height: '56px', fontSize: "30px", color: "red" }}
                                            {...params} label="Suffix" />}
                                />
                                <div>
                                    <CustomTextField
                                        label="Display Name"
                                        // value={(accept) ? (state.DisplayName + " " + state.suffix) : (!accept) ? (state.DisplayName) : ""}
                                        // value={state.DisplayName}
                                        value={state.DisplayName}
                                        // style={{color:"red"}}
                                        name="DisplayName"
                                        placeholder="Enter Your Display Name"
                                        handleChange={handleChange}

                                    />
                                    {/* <Checkbox checked={accept} name="accept" onChange={() => setAccept(!accept)}></Checkbox><span >Include Suffix in Display</span> */}
                                </div>
                                <div>
                                    {(state.firstName?.length > 0 && state.lastName?.length > 0 && state.email?.length > 0 && possible == false) ?
                                        <Button
                                            className="btn-signup contained-btn"
                                            style={{ color: "white", display: "flex" }}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => { setForm1(false); setForm2(true) }}
                                        >
                                            Next
                                        </Button>
                                        : <Button
                                            className="disable-btn-signup"
                                            style={{ color: "rgb(51 51 51 / 39%)", background: "rgb(206,206,206)", display: "flex" }}
                                            variant="contained"
                                            type="submit"
                                        >
                                            Next
                                        </Button>}

                                </div>
                            </ValidatorForm>
                        </div>

                    </div>
                )


                /* ----------------SECOND FORM-------------------- */

                : (form2) ? (
                    <div className="form-bg  form2-bg" >
                        <div className="text-container">
                            <div className="main-text-container">
                                <h1 className="head head2">Help us keep your account and this network safe </h1>
                                <p className="para para2">Your reset password link will be sent to the verified contact point </p>

                            </div>
                            <div className="bottom-text-container1 ">
                                <p>Your contact details are protected </p>
                                <p>Under personal information privacy policies</p>

                            </div>

                        </div>
                        <div className="form-background " style={{ marginBottom: "50px" }}>
                            <div style={{ textAlign: "center", paddingTop: "30px", fontSize: "24px", color: "#3E7EA5" }}>

                                <p >Verify If It's You</p>
                            </div>
                            <ValidatorForm
                                onSubmit={verifySubmit}
                                onError={(errors) => setAlert({ open: true, msg: 'Please fill required fields' })}
                            >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={['OTP Via Email', 'OTP Via SMS']}
                                    getOptionLabel={(option) => option || ""}
                                    onChange={(event, newValue) => {
                                        handleChange('verifyvia', newValue)
                                    }}
                                    value={state.verifyvia}
                                    fullWidth
                                    renderInput={(params) => <CssTextField style={{ marginTop: '16px' }} {...params} label="Verification Method"
                                    // validators={['required']}
                                    // errorMessages={['Please select Verification Method']}
                                    // value=""
                                    />}
                                />
                                {(show) ?
                                    <div>
                                        <h3 style={{ display: "flex", justifyContent: "flex-end", color: "#A2A2A2", fontSize: "16px" }}>One time Password (OTP) will be sent to </h3>
                                        <h3 style={{ display: "flex", justifyContent: "flex-end", color: "#000000", fontWeight: 500, marginTop: "8px" }}>{(state.verifyvia === "OTP Via Email") ? state.email : props?.location?.state?.phoneNumber}</h3>
                                        <CustomTextField
                                            label="Verify OTP"
                                            value={state.otp}
                                            name="otp"
                                            placeholder="Enter Your Otp"
                                            handleChange={handleChange}
                                            InputProps={{
                                                endAdornment: icon
                                            }}

                                            validators={["required", 'minNumber:6']}
                                            errorMessages={["Please Enter Your 6 digits OTP"]}
                                        />
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {(counter === 0) ? <Button className="resend_otp" onClick={countertime}>ResendOTP</Button> : <h3>00:{counter}</h3>}
                                        </div>
                                    </div>
                                    : ""}
                                <div>
                                    {
                                        ((state.verifyvia === "OTP Via Email" || state.verifyvia === "OTP Via SMS") && (state.otp?.length > 0)) ?
                                            < Button
                                                className="btn-next contained-btn"
                                                style={{ color: "white", display: "flex" }}
                                                variant="contained"
                                                type="submit"
                                            >
                                                Next
                                            </Button>
                                            :
                                            <Button
                                                className="disable-btn-signup"
                                                style={{ color: "rgb(51 51 51 / 39%)", background: "rgb(206,206,206)", display: "flex" }}
                                                variant="contained"
                                                type="submit"
                                            >
                                                Next
                                            </Button>
                                    }
                                </div>
                            </ValidatorForm>

                        </div>

                    </div>
                ) : (

                    /* ----------------THIRD FORM-------------------- */

                    <div className="form-bg  form3-bg" >
                        <div className="text-container">
                            <div className="main-text-container">
                                <h1 className="head head3">Secure Your account with a strong password </h1>
                                <p className="para para3 ">Remember Your username and password but do not share it with any one </p>
                            </div>
                            <div className="bottom-text-container bt3">
                                <p>8 characters alphanumeric</p>
                                <p>At least one special Character !@#$%^&* </p>
                                <p>A upper case and a lower case alphabet</p>
                            </div>

                        </div>
                        <div className="form-background">
                            <div style={{ textAlign: "center", paddingTop: "30px", fontSize: "20px", color: "#3E7EA5" }}>
                                <p>Setup Login</p>
                                <p >CREDENTIALS FOR YOUR ACCOUNT</p>
                            </div>
                            <div className="third-form">

                                <ValidatorForm
                                    onSubmit={Register}
                                    onError={errors => { }}
                                >
                                    <div>
                                        <CustomTextField
                                            label="UserName"
                                            value={state.userName}
                                            name="userName"
                                            placeholder="Enter Your UserName"
                                            // handleChange={(e) => userNameCheck('userName', e.target.value)}
                                            handleChange={userNameCheck}
                                            required
                                            validators={["required", 'isUser']}
                                            // validators={["required", 'matchRegexp:/^[a-zA-Z$/']}
                                            // validators={["required", `matchRegexp:/^(?![0-9])[^!@#$%^&*()_+={}\[\]|\\:;"' <,>.? /]*$/`]}
                                            errorMessages={['User Name should not contain spaces and Special Characters']}
                                            // errorMessages={(((state.userName).length > 0) && (!toEliminateNumericValue.test(state.userName?.charAt(0))) && (usernamevalidate.test(state.userName)) === false) ? ['User Name should not contain spaces and Special Characters'] : ""}
                                            customName='username'
                                            Icon={icon1}
                                        />
                                        {
                                            (!avaliable && state.userName) ? <h4 style={{ color: "red", display: "flex", justifyContent: "flex-start" }}><AiOutlineCloseCircle style={{ color: "red", fontWeight: "600", paddingRight: "10px" }} />Username already exist</h4> : ""
                                        }

                                        {(((state.userName)?.length > 0) && (usernamevalidate.test(state.userName)) === false) ?
                                            <h5 style={{ color: "#A2A2A2", paddingLeft: "8px" }}>Should not Contain First Character a Numeric Value and Special Characters and White Spaces</h5> : ""
                                        }
                                    </div>

                                    <div>
                                        <CustomTextField
                                            label="Password"
                                            type="password"
                                            value={state.password}
                                            name="password"
                                            placeholder="Enter Your Password"
                                            handleChange={handleChange}
                                            validators={['required', 'matchRegexp:^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})']}
                                        // errorMessages={['this field is required']}
                                        />
                                        {(((state.password)?.length > 0) && (validate?.test(state.password) === false) ? <h5 style={{ color: "#A2A2A2", paddingLeft: "8px" }}>
                                            8 Alpha numeric characters with atleast one Uppercase Character & One Special Character</h5> : "")}
                                    </div>

                                    <CustomTextField
                                        label="Confirm Password"
                                        type="password"
                                        value={state.confirmPassword}
                                        placeholder="Confirm Your Password"
                                        name="confirmPassword"
                                        handleChange={handleConfirmPassword}
                                    />


                                    <div style={{ textAlign: "center" }}>
                                        {create && avaliable == true && usernamevalidate?.test(state?.userName) == true ? (
                                            <Button
                                                className="btn-signup contained-btn"
                                                // style={{ color: "white" }}
                                                variant="contained"
                                                type="submit"
                                                disabled={showFinalBtn == true ? true : false}
                                            // style={{ color: showFinalBtn == true ? "rgb(51 51 51 / 39%)" : "white", background: showFinalBtn == true ? "rgb(206,206,206)" : "" }}
                                            >
                                                Create
                                            </Button>
                                        ) : (
                                            <Button
                                                className="disable-btn-signup"
                                                style={{ color: "rgb(51 51 51 / 39%)", background: "rgb(206,206,206)" }}
                                                variant="contained"
                                                type="submit"
                                            >
                                                Create
                                            </Button>
                                        )}
                                    </div>
                                </ValidatorForm>



                            </div>

                        </div>

                    </div>)}


            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />

        </div>
    )
}
