import React from 'react'
import ExploreTopicsSearchPage from './ExploreTopicsSearchPage';

const topics = ['All', 'Pediatrics', 'Internal Medicine', 'Neonatology', 'Gynecology', 'Cardiology', 'Laparoscopic surgery', 'Neurosurgery'];



function ExploreTopics() {

  return (
    <div className='explore-container'>
      <h2 >Explore topics you are interested in</h2>
      <p >Explore latest updates in healthcare, know what your peers work, share your insights</p>
      {/* <input placeholder='Search topics, posts, articles'/> */}
      <div >
        <ExploreTopicsSearchPage />
      </div>
      <div className='explore-items-wrapper' id="explore_home">
        {topics?.map((items, index) => {
          return <span key={index} className='explore-items'>{items}</span>
        })}
      </div>
    </div>
  )
}

export default ExploreTopics