import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import '../SignUp/signup.css'
import Logo from '../SignUp/docstethLogo';
import UserService from '../../services/UserService';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
function Policyheader(props) {
    const history = useHistory()

    const login = () => {
        history.push('/');
        UserService.doLogin();
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1 }} className="bg-header">
                <AppBar id="disease_header" position="static" className="tab-app">
                    <Toolbar className='width_header' sx={{ mt: 1, justifyContent: { xs: 'space-between' } }}>
                        <div style={{ display: 'grid' }}>
                            <Link to='/'>
                                <Logo subtext='header-logo-text' header='header-logo' />
                                <p className='logo-sub'><i>A Global Practitioners Consortium</i></p>
                            </Link>
                        </div>
                        <Typography variant="h6" component="p" sx={{ display: { md: 'flex', sm: 'flex', xs: `${props.children ? 'none' : 'flex'}` } }} className="nav-header sign-color">
                            <Box component="span" sx={{ display: { sm: 'inline-flex', xs: 'flex' } }}>Site Map</Box>
                            <Box component="span" sx={{ pr: { sm: '7px', xs: '0px!important' } }}>FAQs</Box>
                        </Typography>
                        {props.search}
                        <Button color="inherit" className="header-signin sign-color" sx={{ display: { sm: 'flex', xs: `${props.children ? 'none' : 'flex'}` } }} onClick={() => login()}>SignIn</Button>
                    </Toolbar>
                    <div className='width_header'>
                        {props.children ?
                            <Toolbar sx={{ mt: 1, display: { sm: 'none' }, justifyContent: 'space-between' }}>
                                <Typography variant="h6" component="p" sx={{ pl: 0 }} className="nav-header sign-color">
                                    <Box component="span" >Site Map</Box>
                                    <Box component="span" >FAQs</Box>
                                </Typography>
                                <Link to='/home'>
                                    <Button color="inherit" className="header-sign sign-color">SignIn</Button>
                                </Link>
                            </Toolbar> : ''}
                    </div>
                </AppBar>
            </Box>
            {props.children}
        </div>
    )
}

export default Policyheader;
