import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import { getallevents } from '../../../../redux/Actions/actions';

const AllEventsData = () => {
    
    const allevents = useSelector((state) => state.userProfile.getallevents)
    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(getallevents())
    }, [])



    return (
        <>
            {
                allevents.length > 0 ?
                    allevents.map((item, i) => {
                        return <a key={i} href={`/webinar/eventdata-${item.id}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="d-flex sidebar__pt1 textAlign capitalize e-align" style={{ overflow: "hidden" }}>
                                <div style={{ width: '45px', height: '45px', borderRadius: '8px' }}>
                                    {/* <img src="https://images.unsplash.com/photo-1658702983847-e974f4ed2d55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=60" alt="organisation" width='45px' height="45px" style={{ borderRadius: "8px" }} /> */}
                                    <img src={item.eventImage ? item.eventImage : "https://docstethdevimages.s3.ap-south-1.amazonaws.com/userProfilePics/profilePic/webinar.png"} alt="organisation" width='45px' height="45px" style={{ borderRadius: "8px" }} />
                                </div>
                                <div className="sidebar__ml sidebar__pt1 " style={{ overflow: "hidden" }}>
                                    <p className='job_title text-ellipsis'>{item.title}</p>
                                    {/* {item.organiser ? <p className='job_sub_title'>Provider: {item.organiser}</p> : ""} */}
                                    {item.organiser ? <p className='job_sub_title text-ellipsis' dangerouslySetInnerHTML={{ __html: item.organiser }} /> : ""}
                                    <p className='job_sub_title'>{moment.utc(item.openingTime).local().format('DD MMM YYYY | hh:mm A')}</p>

                                </div>
                            </div>
                            <div className='sidebar__pt1'><ArrowForwardIosIcon fontSize='small' /></div>
                        </a>
                    }) : <div className='job_title' style={{ padding: '5px', textAlign: 'center' }}>No  Events</div>
            }
        </>


    )
}

export default AllEventsData