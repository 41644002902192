import React, { useState, useEffect, useRef } from "react";
import { Button, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Typography, Grid, Popover, Paper, Snackbar, FormLabel, TextField, CircularProgress, IconButton, Modal, Stack } from '@mui/material';
import videoImg from "../../../../../../images/evideo.svg";
import clear from "../../../../../../images/clear_meta.svg";
import LinkIcon from '@mui/icons-material/Link';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
// import "../../../../../../Molecule/Post/inputOption.css";
// import "../../HealthCareDept/healthCareDept.css";
import { FaRubleSign } from "react-icons/fa";
import axios from "axios";
import { headers } from "../../../../../../redux/Actions/connectActions"
import { useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
// import media from "../../../../../../../images/media.svg";
import media from "../../../../../../images/media.svg";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'

import { Close } from "@mui/icons-material";
import moment from "moment";
import URL from "../../../../../apiurl";
import { FormDivider } from "../../../../../utility/utils";





export const getLoc = async () => {
    const position = await getCurrentPosition()
    const loc = position.coords ? `${position.coords.latitude},${position.coords.longitude}` : null

    return loc
}

function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        const Location = window.navigator && window.navigator.geolocation
        if (Location) {
            Location.getCurrentPosition(
                position => { resolve(position) },
                error => { resolve({}) }
            )
        }
    })
}





const UploadMediaGallery = ({ open, close, getListOfGallery }) => {


    const [anchorEll, setAnchorEll] = React.useState(null);
    const [imgLoad, setImgLoad] = useState(false);
    const [url, setUrl] = useState([]);

    const [filesize, setFilesize] = useState([])
    const [imgtype, setImgtypes] = useState([]);
    const [filetype, setFiletype] = useState('')
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [celebratesize, setCelebratesize] = useState('')
    const [selectedFileType, setSelectedFileType] = useState({
        video: false,
        photo: false,
    })
    const [addVideoLink, setAddVideoLink] = useState(false);
    const [input, setInput] = useState("");
    const [addDescription, setAddDescription] = useState("");
    const [metatags, setMetatags] = useState({});
    const [meta, setMeta] = useState({});
    const [text, setText] = useState({});
    const [show, setShow] = useState(false);
    const [stat, setStat] = React.useState();
    const [uploadingType, setUploadingType] = useState("");
    const [modalOpn, setModalOpn] = useState(false);
    const [crop, setCrop] = useState();
    const [finalCropUrl, setFinalCropUrl] = useState(null);
    const [dimensionsOfImgs, setDimensionsOfImgs] = useState({
        width: "",
        height: ""
    })
    const imageRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const croppedImgRef = useRef(null);

    const [cropImgErrorMsg, setCropImgErrorMsg] = useState("");
    const [convertLink, setConvertLink] = useState(null);
    const [error, setError] = useState();
    const [alert, setAlert] = useState({ open: false, msg: '' });

    const healthServiceLoc = useSelector(state => state.registration.healthServiceLocInfo);
    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    // console.log(healthServiceLoc, "healthServiceLoc")
    // console.log(healthServiceOrg, "healthServiceOrg")
    // console.log(previewCanvasRef, "preview")


    const openn = Boolean(anchorEll);
    const idd = open ? 'simple-popover' : undefined;

    const [openPopup, setOpenPopup] = useState(false);


    const MIN_DIMENSION = 150;
    const ASPECT_RATIO = 1;



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const url = input.replace(/\n/g, ' ').split(' ')
            const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&%\$#\=~])*$');
            let val1 = regex.test(url[url.length - 1])
            setStat(val1)
            if (val1 === true) {
                // axios.post('http://localhost:3001/scrapUrl', { url: url[i] }).then(res => {
                axios.post(process.env.REACT_APP_API_SCRAPE, { url: url[url.length - 1] }).then(res => {
                    setShow(true)
                    // setView(true)
                    if (res.data !== null) {
                        setMetatags(res.data.og)
                        setMeta(res.data.meta)
                        setText(res.data.og.url || res.data.meta.url)
                    }
                })
            } else {
                setMetatags({})
                setMeta({})
                setText("")
            }
        }, 1000);


        return () => clearTimeout(timeoutId);

    }, [input])



    const handleOnChangeTextarea = (e) => {
        setDescription(e.target.value)
    }

    // console.log(description, "description")

    const removecontent = () => {
        setMetatags([])
        setMeta([])
        setInput('')
        setShow(false)
        setImgLoad(false)
        setUrl([])
    }


    const uploadGallery = (data) => {
        axios.post(URL.addGallery, data).then((res) => {
            if (res?.status == 200) {
                setAlert({
                    open: true,
                    msg: "Sucessfully uploaded"
                })
                getListOfGallery();
            } else {
                setAlert({
                    open: true,
                    msg: "Something went wrong PLz try again"
                });
            }
            close();
            setUrl();
            setImgLoad(false);
            setInput("");
            setUploadingType("");
            setTitle("");
            setFinalCropUrl(null);
            setDimensionsOfImgs({
                width: "",
                height: ""
            })
            setDescription("");
        }).catch((err) => {
            close();
            setUrl();
            setImgLoad(false);
            setInput("");
            setUploadingType("");
            setTitle("");
            setFinalCropUrl(null);
            setDimensionsOfImgs({
                width: "",
                height: ""
            })
            setDescription("");
            console.log(err)
        })
    }

    const uploadPostObj = async (sourceLink, postType, location, description, title) => {
        let data = {
            sourceLink: sourceLink,
            context: title,
            description: description,
            mediaType: postType,
            locationId: null,
            healthCareServiceId: healthServiceOrg?.id,
            dimensions: {
                width: dimensionsOfImgs.width,
                height: dimensionsOfImgs.height
            },
            uploadedOn: moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')

        }
        uploadGallery(data);
    }

    const handleOpenPopup = (event) => {
        setAnchorEll(event.currentTarget);
    }

    const handleClosePopup = () => {
        setAnchorEll(null);

    }


    const handleUploadFromYouTube = (e) => {
        setAddVideoLink(true);
        handleClosePopup();

    };


    const uploadPhoto = (e, fileType) => {
        console.log(e.target.files[0], fileType, "see")
        // console.log(e.target.files.length, "length")
        if (e.target.files.length > 0) {
            setImgLoad(true)
            let a = [];
            let b = [];
            let c = [];

            let type = e.target?.files[0]?.type?.split("/")?.[1];
            if (type == "mp4") {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0])
                reader.onloadend = () => {
                    a.push(reader.result);
                    let base64 = reader.result.split(",")[1].split("=")[0];
                    var strLength = base64.length;
                    var fileLength = strLength - (strLength / 8) * 2;
                    b.push(fileLength);
                    const [, type] = reader.result.split(';')[0].split('/');
                    c.push(type);
                    if (a.length || b.length || c.length == e.target.files.length && type == "mp4") {
                        setUrl(a);
                        setFilesize(b)
                        setImgtypes(c)
                        setUploadingType("VIDEO")
                        setImgLoad(false)
                    }

                }
            } else if (type == "png" || "jpg" || "jpeg" || "gif") {

                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0])
                reader.onloadend = () => {
                    a.push(reader.result);
                    let img = new Image();
                    img.src = reader.result;
                    setDimensionsOfImgs((prev) => {
                        return { ...prev, width: img.width, height: img.height }
                    })
                    let base64 = reader.result.split(",")[1].split("=")[0];
                    var strLength = base64.length;
                    var fileLength = strLength - (strLength / 8) * 2;
                    b.push(fileLength);
                    const [, type] = reader.result.split(';')[0].split('/');
                    c.push(type);
                    if (a.length || b.length || c.length == e.target.files.length && type == "png" || "jpg" || "jpeg" || "gif") {
                        setUrl(a);
                        setFilesize(b)
                        setImgtypes(c)
                        setUploadingType("IMAGE")
                        setImgLoad(false)
                    }

                }


            }
        }
    }



    // console.log(url, uploadingType, "vvv")

    const uploadData = (url, type) => {

        let images = []
        for (let i = 0; i < url?.length; i++) {
            images?.push(url[i].split(',')[1])
        }
        const filedata = images?.map((value, i) => {
            return (
                {
                    "file_size": Number((filesize[i] / 1048576).toFixed(3)) || Number((celebratesize / 1048576).toFixed(3)),
                    "file_extension": type,
                    "data": value.toString()
                }
            )
        })
        let data = {
            "file_type": type,
            "files": filedata
        }

        if (input != "" && url?.length > 0) {
            uploadThroughUrlLink(input, type, data);
        } else {
            convertEncrpitedToUrl(data, type);
        }
    }


    const convertEncrpitedToUrl = (data, type) => {

        let loc = getLoc();
        axios.post(`${URL.upload}`, data, headers()).then(res => {

            if (res.status === 200) {
                uploadPostObj(res.data?.[0], type, loc, description, title);
            }
            else {
                setAlert({ open: true, msg: 'Error while uploading...' })
                close();
                setUrl();
                setImgLoad(false);
                setUploadingType("");
                setTitle("");
                setDescription("");
                setFinalCropUrl(null);
                setDimensionsOfImgs({
                    width: "",
                    height: ""
                })
            }
        }).catch(error => {
            setAlert({ open: true, msg: 'Error while uploading...' })
            close();
            setUrl();
            setImgLoad(false);
            setUploadingType("");
            setTitle("");
            setDescription("");
            setFinalCropUrl(null);
            setDimensionsOfImgs({
                width: "",
                height: ""
            })

        })
    }
    const uploadThroughUrlLink = (input, type, data) => {
        let loc = getLoc();
        axios.post(`${URL.upload}`, data, headers()).then(res => {
            if (res.status === 200) {
                uploadPostObj(res.data?.[0], type, loc, input);
            }
            else {
                setAlert({ open: true, msg: 'Error while uploading...' })
                close();
                setUrl();
                setInput("");
                setImgLoad(false)
                setUploadingType("");
                setTitle("");
                setDescription("")
                setFinalCropUrl(null)
            }
        }).catch(error => {
            setAlert({ open: true, msg: 'Error while uploading...' })
            close();
            setUrl();
            setInput("");
            setImgLoad(false)
            setUploadingType("");
            setTitle("");
            setDescription("");
            setFinalCropUrl(null)
        })

    }


    const handleSubmit = () => {
        if (url?.length > 0 && input == "" && uploadingType == "VIDEO") {
            uploadData(url, "VIDEO");
        } else if (url?.length > 0 && input != "" && uploadingType == "VIDEO") {
            uploadData(url, "VIDEO");
        } else if (url?.length > 0 && uploadingType == "IMAGE") {
            uploadData(url, "IMAGE");
        } else {
            setAlert({ open: true, msg: "Something went wrong while Uploading" })
        }
    }
    console.log(dimensionsOfImgs, "dimensions")


    const handleCloseModal = () => {
        setModalOpn(false);
    }


    const onImageLoad = (e) => {
        const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
        // const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
        // if (naturalHeight < MIN_DIMENSION || naturalWidth < MIN_DIMENSION) {
        //     setError("Image must be atleast 150 x 150 pixels");
        //     setUrl("");
        //     setUploadingType("");
        //     return
        // }
        // const crop = makeAspectCrop({ unit: "%", width: cropWidthInPercent }, ASPECT_RATIO, width, height,)
        // const centeredCrop = centerCrop(crop, width, height)
        // setCrop(centeredCrop)
        // setError('')
        // setCropImgErrorMsg("")

    }

    console.log(error, "error")
    console.log(url, "url")

    console.log(previewCanvasRef, "previewCanvasRef")

    const SubmitButton = ({ text, handleSubmit }) => {
        return (
            <Button className="post_btn color_white capitalize" type="submit" onClick={handleSubmit} >{text}</Button>
        )
    }



    // don't move the code any where it should be always above the return only
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                    setAlert({
                        open: false,
                        msg: ""
                    })
                }}
            >
                <Close fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <div>
            <ValidatorForm onClick={() => { }}>
                <Dialog open={open} onClose={handleClosePopup} maxWidth="sm" fullWidth>
                    <DialogTitle style={{ padding: "5px" }}>
                        <div className="flx-center justify-between">
                            <div>Upload </div>
                            <div >
                                <Close sx={{ fontSize: "16px" }} onClick={() => {
                                    close();
                                    setDescription("");
                                    setAddVideoLink(false);
                                    setUrl([]);
                                    setSelectedFileType({
                                        photo: false,
                                        video: false
                                    });
                                    setMetatags({});
                                    setMeta([]);
                                    setText("");
                                    setInput("");
                                    setImgLoad(false);
                                    setUploadingType("");
                                    setTitle("");
                                    setDescription("")
                                    setCropImgErrorMsg("");
                                    setError("");
                                }}
                                    className="cursor " />
                            </div>

                        </div>

                    </DialogTitle>
                    <FormDivider />
                    <DialogContent>

                        <p><label for="title">Add Title:</label></p>
                        <TextField
                            fullWidth
                            size="small"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter title"


                        />
                        <p><label for="description">Add Description:</label></p>
                        <textarea id="description" name="description" value={description} rows="4" style={{ width: "100%" }} onChange={handleOnChangeTextarea}></textarea>

                        {addVideoLink &&
                            <>
                                <TextField id="outlined-basic" variant="outlined"
                                    fullWidth
                                    placeholder='Enter YouTube link here...'
                                    sx={{
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                        '.MuiOutlinedInput-input': {
                                            padding: '8px 8px',
                                        },
                                    }}
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)} />

                            </>

                        }


                        <div style={{ paddingTop: "20px", maxHeight: "350px" }} >
                            {

                                ((addVideoLink && metatags?.url)) ?
                                    <div style={{ border: "0.65px solid lightgray" }}>
                                        <div style={{ position: "relative" }}>
                                            <div style={{ width: '100%', height: '500px' }}>
                                                <iframe src={`https://www.youtube.com/embed/${metatags?.url.split('=')[1]}`} width="100%" height="100%" title='YouTube video' allowFullScreen allow='autoplay' ></iframe>

                                            </div>
                                            <div onClick={removecontent} style={{ position: "absolute", top: "10px", right: "5px" }}>
                                                <img src={clear}></img>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        {
                            imgLoad == true && <div style={{ height: '120px' }} className='textCenter'><CircularProgress disableShrink /></div>
                        }


                        {
                            imgLoad == false && url?.length > 0 && uploadingType == "VIDEO" ?

                                <video width="100%" height="500" controls style={{ border: '1px solid lightgrey' }}>
                                    <source src={url?.[0]} type="video/mp4" />
                                </video>
                                : ''

                        }

                        {
                            imgLoad == false && url?.length > 0 && uploadingType == "IMAGE" &&
                            <div width="100%" style={{ border: '1px solid lightgrey' }} >
                                <img src={url?.[0]} alt="Image" style={{ maxHeight: "70vh" }} onLoad={onImageLoad} ref={imageRef} />
                            </div>
                        }
                    </DialogContent>
                    <FormDivider />

                    <div style={{ padding: "5px" }} >
                        <div className="flx-center  justify-between" style={{ paddingBottom: "5px" }}>

                            <Stack direction="row" spacing={3} className="flx-center">
                                <div className="item" style={{ width: "100px" }}>
                                    <input type="file" name="uploadVideo" multiple id="uploadVideo" accept=".mp4" style={{ display: 'none' }} onChange={(e) => {
                                        uploadPhoto(e, 'video')
                                    }} />
                                    <div>
                                        <label
                                            onClick={handleOpenPopup}
                                            className="cursor"
                                            aria-describedby="popover-content"
                                        >
                                            <Paper elevation={5} className="video_btn">
                                                <img src={videoImg} alt="video" width="20px" height="24px" />
                                                <div className="input__title icon__ml">Videos</div>
                                            </Paper>
                                        </label>
                                        <Popover
                                            id="popover-content"
                                            open={Boolean(anchorEll)}
                                            anchorEl={anchorEll}
                                            onClose={handleClosePopup}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <div style={{ padding: '8px' }}>
                                                <Typography variant="body1" gutterBottom>
                                                    Upload From
                                                </Typography>
                                                <Grid container spacing={2} className="video_btn">
                                                    <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                                        <>
                                                            <LaptopMacIcon
                                                                onClick={() => {
                                                                    document.getElementById("uploadVideo").click();
                                                                    handleClosePopup();
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                            <div className="input__title">device</div>
                                                        </>
                                                    </Grid>
                                                    {/* <Grid item xs={6}>
                                                        <LinkIcon
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={(e) => { handleUploadFromYouTube(e) }}
                                                        />
                                                        <div className="input__title ">YouTube</div>
                                                    </Grid> */}
                                                </Grid>
                                            </div>
                                        </Popover>
                                    </div>
                                </div>
                                <div className="item" style={{ width: "100px" }}>
                                    <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .gif" style={{ display: 'none' }} onChange={(e) => uploadPhoto(e, 'photo')} />
                                    <div style={{ width: "100px" }}>
                                        <label htmlFor="post-img2" className="cursor">
                                            <Paper className="video_btn">
                                                <img src={media} alt="media" width="24px" height="24px" />
                                                <div className="input__title icon__ml">Photo</div>
                                            </Paper>
                                        </label>
                                    </div>
                                </div>
                            </Stack>


                            <div>
                                {
                                    url?.length > 0 || input != "" ? <SubmitButton text="Post" type="submit" handleSubmit={handleSubmit}
                                    /> : <Button className="add-option" variant="outlined" disabled >Post</Button>
                                }

                            </div>

                        </div>

                    </div>




                </Dialog>

            </ValidatorForm>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={alert.open}
                action={action}
                message={alert.msg}
            />

        </div >
    )
}

export default UploadMediaGallery;