import React, { useState, useEffect } from "react";
import { Card, Grid, Button, InputLabel, Select, MenuItem, Paper, Avatar, Typography, Dialog, IconButton, DialogTitle, DialogContent, DialogActions, Divider, Tooltip } from "@mui/material";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import OrgLogo from "../../../../../../images/orglogo.svg";
import Settings from "../../../../../icons/settings.svg";
// import EditHealthCareDept from "../HealthCareDept/addHealthcareDept";
import EditHealthServiceDept from "../HealthServicesDept/addServiceDept";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { OrgLayout } from "../../../../common/components/layout";
// import { handleHealthDeptLoc, handleHealthDeptOrg } from "../../../../../../redux/Actions/regActions";
import { handleHealthServiceOrgInfo, handleHealthServiceLoc, getUserOrg, } from "../../../../../redux/Actions/regActions";
import { URI } from "../../../../utility/uri";
import { DOC_API_WITHOUT_TOKEN, D_API } from "../../../../../services/APIService";
import { ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from '@mui/icons-material/Edit';
import RearrangingServiceComponents from "./rearrangingServiceCompnts";
// import "./healthCareDept.css";
// import Stethoscope from "../../../../../../../src/images/stethoscope1.svg";
// import Location from "../../../../../../../src/images/location.svg";
// import DoctorImg from "../../../../../../images/doctorImg.png";
// import EventAvailableIcon from '@mui/icons-material/EventAvailable';
// import LaunchIcon from '@mui/icons-material/Launch';
// import Referal from "../../../../../../images/referral-3.svg";
import Communicate from "../../../../../images/Communication-language.svg";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DefaultHealthServiceIcon from "../../../../../../src/icons/Healthcare_Service.svg";
import EligibilityCriteria from "./EligibilityDetails";
import PractitionerTeam from "../HealthServicesDept/addServiceContent/practitionerTeam";
import PostsAndArticles from "../HealthServicesDept/addServiceContent/postandArticles";
import PatientEduArticles from "./addServiceContent/patientEduArticles";
import HealthCareService from "./addServiceContent/healthServices";
import CatalogInfo from "./addServiceContent/catalogInfo";
import { debounce } from "lodash";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import axios from "axios";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import FAQ from "../AdminOrganization/HealthCareDept/addContent/Faqs";
import GalleryMedia from "../HealthServicesDept/addServiceContent/galleryMedia";
import MenuIcon from '@mui/icons-material/Menu';









const contentList = ["Eligibility Criteria", "Practitioners Team", "Similar Services", "Posts & Articles", "Patient Education", "Media Gallery", "Frequently Asked Question"]
const ParticularHealthServiceDemo = ({ serviceId }) => {


    const [opneditHealthServiceDept, setOpneditHealthServiceDept] = useState(false);

    const [selectedContent, setSelectedContent] = useState([]);
    const [practitionerList, setPractitionerList] = useState([]);
    const [about, setabout] = useState(false);
    const [showAddContent, setShowAddContent] = useState(false);
    const [openMenuItems, setOpenMenuItems] = useState(false);

    const [serviceInfo, setServiceInfo] = useState(null);
    const [showAbout, setShowAbout] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [editAbout, setEditAbout] = useState(false);
    // const [editorState, setEditorState] = useState(editorContent);

    const html = ``
    const blocksFromHTML = convertFromHTML(html)
    const content = ContentState.createFromBlockArray(blocksFromHTML)
    const editorContent = EditorState.createWithContent(content)


    const [editAboutText, setEditAboutText] = useState('');
    const [editorState, setEditorState] = useState(editorContent);
    const [markdownContent, setMarkdownContent] = useState('');
    const [hover, setHover] = useState(false);
    const [showRearrangeServiceDialog, setShowRearrangeServiceDialog] = useState(false);




    let locObj = useLocation();
    const history = useHistory();
    const id = serviceId?.id;
    const dispatch = useDispatch();

    // let healthserviceInfo = locObj?.state?.resource;

    console.log(serviceInfo, "healthserviceInfo")
    const userOrg = useSelector(state => state.registration.userOrg);
    console.log(userOrg, "330")

    const healthServiceLoc = useSelector(state => state?.registration?.healthServiceLocInfo);
    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);

    const isAdmin = useSelector((state) => state?.registration?.isAdmin);


    console.log(healthServiceOrg, "101", healthServiceLoc)

    console.log(editAboutText, "editAboutText")


    useEffect(() => {
        if (userOrg?.id) {
            D_API.get(`${userOrg?.id}/${URI.getPermissionForPractitionerRole}${userOrg?.id}`).then((res) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: res.data
                })
            }).catch((err) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: null
                })
                console.log(err)
            })

        } else {
            dispatch({
                type: "IS_ADMIN",
                payload: null
            })
        }
    }, [userOrg?.id])

    useEffect(() => {
        setServiceInfo({ ...healthServiceOrg })
    }, [healthServiceOrg, healthServiceLoc])


    useEffect(() => {
        setServiceInfo(locObj?.state?.resource)
    }, [locObj])


    // useEffect(() => {
    //     getParentOrganizationDetails();
    // }, [userOrg])



    // console.log(healthDeptLoc, healthDeptOrg, "123")


    useEffect(() => {
        if (healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {
            D_API.get(`${userOrg?.id}/${URI.organization}/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
                dispatch(getUserOrg(res.data))
            }).catch((err) => console.log(err))
        }

        if (healthServiceOrg?.location) {
            let listOfIds = healthServiceOrg?.location?.map((e) => e?.reference?.split("/")?.[1]);
            getLocationsByLocId(listOfIds)
        }
    }, [healthServiceOrg])




    const getLocationsByLocId = async (Ids) => {
        try {
            let List = await Promise.all(
                Ids?.map(async (id) => {
                    try {
                        let res = await D_API.get(`${userOrg?.id}/${URI.addLocation}/${id}`);
                        return res.data;
                    } catch (err) {
                        console.log(err)
                    }
                })
            );
            // setLocationList(List)
            setLocationList(List)
        } catch (err) {
            // setLocationList([])
            setLocationList([])
        }
    }


    useEffect(() => {
        D_API.get(`${userOrg?.id}/${URI.getHealthCareService}/${id}`).then((res) => {
            // dispatch(handleHealthDeptOrg(res.data));
            dispatch(handleHealthServiceOrgInfo(res.data))
            console.log(res.data, "1")
        }).catch((err) => console.log(err));

        if (serviceInfo?.location?.[0]?.reference.split("/")?.[1]) {
            D_API.get(`${userOrg?.id}/${URI.addLocation}/${serviceInfo?.location?.[0]?.reference.split("/")?.[1]}`).then((res) => {
                console.log(res.data, "2")
                let data = res.data?.entry?.[0]?.resource;
                dispatch(handleHealthServiceLoc(data))

            }).catch((err) => console.log(err))
        }


    }, [id])



    useEffect(() => {

        // if (serviceInfo?.extraDetails) {
        //     if (about == false) {
        //         let filterData = serviceInfo?.extraDetails.length > 400 ? serviceInfo?.extraDetails?.slice(0, 400) : serviceInfo?.extraDetails;
        //         setEditAboutText(filterData)
        //     } else {
        //         setEditAboutText(serviceInfo?.extraDetails)
        //     }
        //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(serviceInfo?.extraDetails || `<p></p>`))))
        // }

        if (serviceInfo?.extraDetails) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(serviceInfo?.extraDetails || `<p></p>`))))
            setEditAboutText(serviceInfo?.extraDetails)
        }

    }, [serviceInfo, about])

    useEffect(() => {
        if (healthServiceOrg?.id) {
            getAddedComponentsList();
        }

    }, [healthServiceOrg?.id])


    useEffect(() => {
        if (selectedContent?.length > 0) {
            addComponentsFn();
        }

    }, [selectedContent])


    console.log(selectedContent, "selectedContent")


    const uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Data1 = reader.result.split(",")[1];

                // const base64Data = reader.result.replace("data:image/png;base64,", "");
                const type = file.type.split("/")[1];
                console.log(base64Data1, "basebase")
                const payload = {
                    file: [base64Data1],
                    fileType: type,
                    multiple: false,
                    subType: "profilePic",
                    type: "userProfilePics"
                };

                axios.post(`${URL.uploadFile}`, payload, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {

                    console.log(response, "rereddss")
                    resolve({ data: { link: response.data?.data?.[0] } });
                }).catch(error => {
                    reject(error);
                });
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    };


    const getParentOrganizationDetails = () => {
        D_API.get(`${userOrg?.id}/${URI.organization}/${userOrg?.id}`).then((res) => {
            console.log(res.data, "ind")
        }).catch((err) => console.log(err))
    }


    const addComponentsFn = debounce(() => {
        if (selectedContent?.length > 0 && healthServiceOrg?.id) {
            DOC_API_WITHOUT_TOKEN.post(`${URI.addComponents}${healthServiceOrg?.id}`, [...selectedContent]).then((res) => {
                console.log(res.data, "add")
                // getAddedComponentsList();
            }).catch((err) => console.log(err))
        }

    }, 2000);


    const getAddedComponentsList = () => {
        if (healthServiceOrg?.id) {
            DOC_API_WITHOUT_TOKEN.get(`${URI.getAddedComponents}${healthServiceOrg?.id}`).then((res) => {
                console.log(res.data, "get")
                setSelectedContent(res.data?.hashcomponents ? res.data?.hashcomponents : [])
            }).catch((err) => console.log(err));
        }
    }



    const handleAddContent = (value, idx) => {

        if (selectedContent?.map((e) => e?.name)?.includes(value)) {
            setOpenMenuItems(false);
            setShowAddContent(false);

        } else {
            setSelectedContent([...selectedContent, { id: idx, name: value }]);
            setOpenMenuItems(false);
            setShowAddContent(false);
        }

    }

    console.log(selectedContent, "selectedContent");





    const editUpdate = async () => {

        let servicedata = { ...serviceInfo }

        servicedata.extraDetails = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        console.log(servicedata, "servicedata")


        if (healthServiceOrg?.id) {
            D_API.put(`${userOrg?.id}/${URI.updateHealthServiceDept}/${healthServiceOrg?.id}`, servicedata).then((res) => {
                console.log(res.data, "7")
                D_API.get(`${userOrg?.id}/${URI.getHealthCareService}/${res.data}`).then((res) => {
                    dispatch(handleHealthServiceOrgInfo(res.data));
                    setEditAbout(false);
                    // switchTabs();
                    console.log(res.data)
                }).catch((err) => console.log(err));

            }).catch((err) => {
                setEditAbout(false);
                console.log(err)
            });
        } else {
            setEditAbout(false);
        }

    }

    const handleOpnHealthDept = () => {
        setOpneditHealthServiceDept(true);
    }

    const handleClsHealthDept = () => {
        setOpneditHealthServiceDept(false);
    }





    const handleOpenAddContent = () => {
        setShowAddContent(true);
    };



    const handleOpenMenuItems = () => {
        setOpenMenuItems(!openMenuItems);
    };

    const handleMouseLeave = () => {
        setShowAddContent(false);
        setOpenMenuItems(false);
    };


    const editState = (editorState) => {
        setEditorState(editorState)
    }

    const handleClose = () => {
        setEditAbout(false)
    }



    const handleOpnRearrangeServiceComps = () => {
        setShowRearrangeServiceDialog(true);
    }


    const handleClsRearrangeServiceComps = () => {
        setShowRearrangeServiceDialog(false);
    }




    const renderComponents = (list) => (
        list.map((val) => {
            switch (val.name) {

                case "Eligibility Criteria":
                    return <EligibilityCriteria />
                case "Practitioners Team":
                    return <PractitionerTeam />
                case "Posts & Articles":
                    return <PostsAndArticles />
                case "Patient Education":
                    return <PatientEduArticles />
                case "Similar Services":
                    return <HealthCareService />
                case "Patient Education":
                    return <PatientEduArticles />

                case "Media Gallery":
                    return <GalleryMedia />

                case "Frequently Asked Question":
                    return <FAQ healthCare={serviceInfo} />

                default: return;
            }
        })
    );


    return (
        <div style={{ paddingTop: "10px" }}>
            <ValidatorForm onSubmit={() => ""}>

                <EditHealthServiceDept opn={opneditHealthServiceDept} cls={handleClsHealthDept} id={serviceInfo?.id} />

                {/* old code */}

                {/* <Card sx={{ padding: "10px", height: "80px" }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={1} sm={1} md={1}>
                            <ArrowBackIcon onClick={() => { history.push(`/admin/organization/${userOrg?.identifier?.[1]?.value}`) }} />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10}>
                            <div style={{ display: "flex", alignItems: "", gap: 4 }}>
                                <div>
                                    <Avatar variant="square" sx={{
                                        width: '30px !important', height: '30px !important',
                                        // backgroundColor: "", boxShadow:
                                        //     "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "0.5px solid #fff",
                                    }} src={serviceInfo?.photo?.url || DefaultHealthServiceIcon} />

                                </div>
                                <div>
                                    <h2>{serviceInfo?.name}</h2>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <img width="18px" height="18px" src={Settings} onClick={handleOpnHealthDept}></img>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={1} sm={1} md={1}>

                        </Grid>
                        <Grid item xs={10} sm={10} md={10}>
                            <div>
                                <p className="pv1 fntSm">{serviceInfo?.extraDetails}</p>
                            </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                        </Grid>
                    </Grid>
                </Card> */}


                <Card sx={{ padding: "10px", height: "60px" }}>
                    <Grid container spacing={1} alignItems="center">
                        {/* <Grid item xs={1} sm={1} md={1}>
                            <ArrowBackIcon onClick={() => { history.push(`/admin/organization/${userOrg?.identifier?.[1]?.value}`) }} />
                        </Grid> */}
                        <Grid item xs={11} sm={11} md={11}>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                {/* <div className="cursor">
                                    <ArrowBackIcon onClick={() => { history.push(`/admin/organization/${userOrg?.identifier?.[1]?.value}`) }} />
                                </div> */}
                                <div>
                                    <Avatar variant="square" sx={{
                                        // width: '30px !important', height: '30px !important',
                                        // backgroundColor: "", boxShadow:
                                        //     "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "0.5px solid #fff",
                                    }} src={serviceInfo?.photo?.url || DefaultHealthServiceIcon} />
                                    {/* <img src={healthdeptInfo?.identifier?.filter((info) => info["system"]?.includes("logo") && info["value"])?.[0]?.value || OrgLogo} style={{ width: "30px", height: "30px" }} /> */}
                                </div>
                                <div>
                                    <div className="font-head fntSemiBold capitalize">{serviceInfo?.name}</div>
                                    <div className="fntSm capitalize">{serviceInfo?.comment}</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            {
                                isAdmin == "PERMIT" &&
                                <div className="flx-center justify-between">
                                    {
                                        selectedContent.length >= 2 &&
                                        <div>
                                            <MenuIcon className="cursor" sx={{ width: "25px", height: "25px", color: "#197FC4", '.MuiSvgIcon-root': { width: "20px", height: "20px" } }} onClick={handleOpnRearrangeServiceComps} />
                                        </div>
                                    }

                                    <div>
                                        <img width="18px" height="18px" className="cursor" src={Settings} onClick={handleOpnHealthDept}></img>
                                    </div>
                                </div>
                            }
                            {/* <img width="18px" height="18px" className="cursor" src={Settings} onClick={handleOpnHealthDept}></img> */}
                        </Grid>
                    </Grid>
                </Card>



                <div className="mv1">
                    <Card sx={{}}>
                        <div style={{ padding: '10px' }}>
                            <div>
                                <div className="font-head fntSemiBold flx-center">About &nbsp;&nbsp;
                                    {
                                        isAdmin == "PERMIT" &&
                                        <>
                                            {
                                                serviceInfo?.id ?
                                                    <EditIcon
                                                        sx={{ cursor: "pointer", paddingTop: "4px" }}
                                                        onClick={() => {
                                                            setEditAbout(!editAbout);
                                                            setEditAboutText(serviceInfo?.extraDetails);
                                                        }}
                                                        fontSize={"small"}
                                                    />
                                                    :
                                                    <Tooltip title="Complete your healthService profile form" arrow>
                                                        <EditIcon
                                                            sx={{ cursor: "pointer", }}
                                                            fontSize={"small"}
                                                        />
                                                    </Tooltip>
                                            }
                                        </>
                                    }
                                </div>
                            </div>







                            {editAbout && (
                                <Dialog
                                    // fullWidth="lg"
                                    maxWidth={"md"}
                                    open={editAbout}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    className="tohideScrollbar"

                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"About Hospital"}
                                    </DialogTitle>
                                    {/* <IconButton
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                    }}
                                >
                                    <Close />
                                </IconButton> */}
                                    <DialogContent sx={{ padding: "2px 8px" }}>

                                        <Editor
                                            editorStyle={{
                                                overflow: "hidden !important ",

                                                height: '250px',
                                            }}
                                            // className=".tohideScrollbar"
                                            editorState={editorState}
                                            toolbar={{

                                                image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                                            }}
                                            // toolbarClassName="editorToolbar"
                                            // wrapperClassName="overViewEditor"
                                            // editorClassName="editorAbout"

                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(editorState) => editState(editorState)}
                                        />


                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            fontSize="small"
                                            // sx={{ float: 'right', p: "", m: "" }}
                                            variant="outlined"
                                            color="error"
                                            onClick={handleClose}
                                            className="capitalize"
                                        >
                                            cancel
                                        </Button>
                                        <Button
                                            fontSize="small"
                                            sx={{ float: 'right', p: 1, m: 1 }}
                                            variant="contained"
                                            className="capitalize"
                                            disabled={editAboutText?.length == 0}
                                            onClick={editUpdate}
                                        >
                                            Update
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}





                            {/* 
                        {editAbout && (
                            <div>

                                <Editor
                                    editorStyle={{
                                        overflow: 'auto',
                                        height: 'auto',
                                    }}
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(editorState) => editState(editorState)}
                                />
                                <Button
                                    fontSize="small"
                                    sx={{ float: 'right', p: 1, m: 1 }}
                                    variant="contained"
                                    className="capitalize"
                                    disabled={editAboutText?.length == 0}
                                    onClick={editUpdate}
                                >
                                    Update
                                </Button>
                            </div>
                        )} */}



                            {!editAbout && serviceInfo?.extraDetails && (
                                <Typography my={1}>
                                    {/* <div
                                        dangerouslySetInnerHTML={{ __html: editAboutText }}
                                        style={{ whiteSpace: 'pre-wrap' }}

                                    /> */}
                                    <div
                                        dangerouslySetInnerHTML={{ __html: editAboutText }}
                                        style={{
                                            display: !showAbout ? '-webkit-box' : "",
                                            WebkitBoxOrient: 'vertical ',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 2,
                                            lineswhiteSpace: 'normal',
                                            whiteSpace: 'pre-wrap'
                                        }}

                                    />
                                    <span type="label">

                                    </span>


                                    {/* <ReactMarkdown>{htmlToMarkdown(editAboutText || "")}</ReactMarkdown> */}




                                </Typography>
                            )}


                            {/* 
                        {
                            serviceInfo?.extraDetails && (
                                <p className="fntSm"> {showAbout
                                    ? serviceInfo?.extraDetails
                                    : `${serviceInfo?.extraDetails?.slice(0, 400)} ${serviceInfo?.extraDetails?.length > 400 ? "..." : ""
                                    }`} <span>
                                        {serviceInfo?.extraDetails?.length > 400 && (
                                            <Button
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding: "0 !important",
                                                    paddingLeft: "10px !important",
                                                    color: "#000",
                                                    fontWeight: "600",
                                                }}
                                                onClick={() => setShowAbout(!showAbout)}
                                            >
                                                {showAbout ? "Learn less" : "Learn more"}
                                            </Button>
                                        )}</span>
                                </p>
                            )
                        } */}
                        </div>

                        <Divider />
                        {serviceInfo?.extraDetails?.length > 400 &&
                            <div
                                style={{ padding: '8px', cursor: 'pointer', fontWeight: 'bold', background: hover ? "#F4F2EE" : "" }}
                                onClick={() => setabout(!about)}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >

                                {
                                    !showAbout ?
                                        <div className='flx-center justify-center' onClick={() => setShowAbout(true)} >
                                            <div  >
                                                Show More
                                            </div>
                                            <KeyboardArrowDown style={{ marginTop: '3px' }} />
                                        </div>
                                        :
                                        <div className='flx-center justify-center' onClick={() => setShowAbout(false)}>
                                            <div > Show Less </div>
                                            <KeyboardArrowUp style={{ marginTop: '3px' }} />
                                        </div>

                                }
                            </div>
                        }
                    </Card>
                </div>


                {
                    healthServiceOrg &&

                    <div className="mv1">
                        <Card sx={{ padding: "10px" }}>
                            <div className="font-head fntSemiBold">Service Details </div>
                            {healthServiceOrg?.specialty?.length > 0 &&
                                <Grid container alignItems="center" pt={1} xs={12}>
                                    <Grid item>
                                        <div className="fntSm fntSemiBold">Speciality:</div>
                                    </Grid>
                                    <Grid item>
                                        <div className="fntSm flx-center"> &nbsp;{healthServiceOrg?.specialty ? healthServiceOrg?.specialty?.map((e) => e.coding?.[0]?.display + "," + " ") : ""}</div>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container alignItems="center" xs={12} pt={1}>
                                <Grid item>
                                    <div className="flx-center fntSm "> <img src={Communicate} />&nbsp;<span>Communicate in</span> &nbsp;<span style={{ color: "#5465AA" }}>Telugu,English,Hindi</span></div>
                                </Grid>
                            </Grid>


                            <Grid container justifyContent="space-between" alignItems="center" pt={1} xs={12} >
                                {/* Category */}
                                {healthServiceOrg?.category?.[0]?.coding?.[0]?.display &&
                                    <Grid item  >
                                        <Grid item container alignItems="center">
                                            <Grid item>
                                                <div className="fntSm fntSemiBold">Category:</div>
                                            </Grid>
                                            <Grid item>
                                                <div className="fntSm flx-center">
                                                    &nbsp;{healthServiceOrg?.category ? healthServiceOrg?.category?.[0]?.coding?.[0]?.display : ""}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {/* Type */}

                                <Grid item>
                                    {healthServiceOrg?.type?.[0]?.coding?.[0]?.display &&
                                        <Grid item container alignItems="center">
                                            <Grid item>
                                                <div className="fntSm fntSemiBold">Type:</div>
                                            </Grid>
                                            <Grid item>
                                                <div className="fntSm flx-center">
                                                    &nbsp;{healthServiceOrg?.type ? healthServiceOrg?.type?.[0]?.coding?.[0]?.display : ""}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            {locationList?.length > 0 &&
                                <Grid container pt={1} xs={12} >
                                    <Grid item>
                                        <div className="pb0-5">
                                            <div className="pv0-5 fntSm fntSemiBold">Location Area</div>
                                            {locationList?.length > 0 &&
                                                <div className="flx-center" style={{ gap: 10 }} >
                                                    {locationList?.map((e) => (
                                                        <div key={e?.id} style={{ backgroundColor: "#E2DDDD", borderRadius: "15px", height: "25px" }} className="ph1 fntSm flx-center">
                                                            {e?.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                            {healthServiceOrg?.characteristic?.length > 0 &&
                                <Grid container pt={1} xs={12}>
                                    <Grid item>
                                        <div className="pb0-5">
                                            <div className="fntSm fntSemiBold">Service characteristics</div>
                                            {healthServiceOrg?.characteristic?.length > 0 &&
                                                <div className="flx-center" style={{ gap: 10 }}>
                                                    {healthServiceOrg?.characteristic?.map((e) => (
                                                        <div className="fntSm" style={{ color: "#C5A5E7" }}>
                                                            #{e?.coding?.[0]?.display}
                                                        </div>

                                                    ))}
                                                </div>
                                            }

                                        </div>
                                    </Grid>

                                </Grid>
                            }

                        </Card>
                    </div>


                }

                <div>{renderComponents(selectedContent)}</div>


                {/* {
                    selectedContent?.map((e) => e?.name).includes("Eligibility Criteria") && <EligibilityCriteria />
                }

                {
                    selectedContent?.map((e) => e?.name).includes("Practitioners Team") && <PractitionerTeam />
                }
                {
                    selectedContent?.map((e) => e?.name).includes("Posts & Articles") && <PostsAndArticles />
                }
                {
                    selectedContent?.map((e) => e?.name).includes("Patient Education") && <PatientEduArticles />
                }
                {
                    selectedContent?.map((e) => e?.name).includes("Similar Services") && <HealthCareService />

                }
                {
                    selectedContent?.map((e) => e?.name).includes("Media Gallery") && <GalleryMedia />

                }
                {
                    selectedContent?.map((e) => e?.name).includes("Frequently Asked Question") && <FAQ healthCare={serviceInfo} />
                } */}


                {/* {
                    selectedContent.includes("Catalog & Offers") && <CatalogInfo />
                } */}

                {/* {
                    selectedContent.length > 0 && selectedContent.map((e, i) => {
                        return (
                            <div className="mv1" key={i}>
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <div className="fntMd fntSemiBold">
                                                {e}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" />
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Card>

                            </div>
                        )
                    })
                } */}
                {
                    isAdmin == "PERMIT" &&
                    <div style={{ width: "280px", cursor: "pointer" }} className="mv1" onMouseLeave={handleMouseLeave} onClick={handleOpenMenuItems}>
                        <div
                            style={{
                                boxShadow: showAddContent ? "2px 3px 10px lightgrey" : "",
                                backgroundColor: "#ffffff",
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                height: "30px",
                                borderRadius: showAddContent ? "" : "",
                                paddingTop: "5px"
                            }}
                            onMouseEnter={handleOpenAddContent}
                        >
                            <div style={{ display: "flex", justifyContent: showAddContent ? "space-between" : "", paddingRight: "5px", width: "100%", paddingLeft: "5px", borderRadius: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <div>
                                        <AddCircleIcon
                                            fontSize="small"
                                            sx={{ color: "#008114", paddingTop: "3px" }}
                                            className="cursor"
                                        />
                                    </div>
                                    <div>
                                        <div style={{ display: showAddContent ? "block" : "none", fontSize: "15px" }}> &nbsp; {openMenuItems ? "Select Content Type" : "Add Content"} </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: showAddContent ? "block" : "none" }}> <KeyboardArrowDownIcon sx={{ height: "20px", paddingTop: "2px" }} /></div>
                                </div>
                            </div>
                        </div>
                        {openMenuItems && (

                            <div style={{ width: "100%", paddingLeft: "", borderRadius: showAddContent ? "" : "", backgroundColor: "#ffffff", boxShadow: showAddContent ? "2px 3px 10px lightgrey" : "", }}>
                                {
                                    contentList?.map((e, i) => {
                                        return (
                                            <div className="menuHoverEffect" style={{ padding: "5px", cursor: "pointer", }} onClick={() => handleAddContent(e, i)}>{e}</div>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div >
                }
                <RearrangingServiceComponents open={showRearrangeServiceDialog} close={handleClsRearrangeServiceComps} componentsList={selectedContent} updateGetList={setSelectedContent} healthServiceId={healthServiceOrg?.id} />
                {/* <RearrangingServiceComponents open={showRearrangeServiceDialog} close={handleClsRearrangeServiceComps} componentsList={selectedContent} updateGetList={setSelectedContent} healthServiceId={healthServiceOrg?.id} /> */}
            </ValidatorForm >

        </div >
    )
}
export default ParticularHealthServiceDemo;