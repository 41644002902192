import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useFormik, getIn } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
// import { styled } from '@mui/material/styles'
import { styled } from '@mui/styles';
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup';
import Snackbar from '@mui/material/Snackbar';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import '../myprofile.css';
import './organization.css';
import { getAPI, allCountries } from './userupdate';
import Plus from '../../../../images/plus.svg';
import URL from '../../../apiurl';
import { useDispatch } from 'react-redux';
import docimage from '../../../../icons/Orgnaisationprofile.svg'
import { fetchProfile, getMetaData, getPageById } from '../../../../redux/Actions/actions';
import { BootstrapTooltip } from '../../../Molecule/Post/commonFun';
import { InfoOutlined } from '@mui/icons-material';

export const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '12px',
            height: '43px',
            boxShadow: '0px 3px 4px #756d6d29',
            color: 'grey',
            top: 3
        }
    },
    '& .MuiInputLabel-shrink': {
        top: '5px'
    }
});

const useStyles = makeStyles(() => ({
    container: {
        alignItems: 'flex-start'
    },
    paper: {
        marginTop: '65px'
    },
    acordian: {
        margin: "12px"
    },
    icon: {
        position: "absolute",
        right: "50px"
    },
    label: {
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '12px 0px 5px 0px'
    },
    subtext: {
        fontSize: '15px',
        margin: '0px'
    },
    email1: {
        fontSize: '14px'
    },
    itemSpace: {
        paddingLeft: '5%',
    },

}));

// console.log(sessionStorage.getItem("userId"))

const initialValues = {
    "date": new Date(),
    "description": "",
    "location": "",
    "metadata": {},
    "coverPhoto": "",
    "kycStatus": "PENDING",
    "followersCount": 0,
    "organisation": {
        "resourceType": "Organization",
        "name": "",
        "identifier": [
            {
                "system": "",
                "type": {
                    "text": "",
                    "coding": [{
                        "display": "",
                    }]
                },
                "value": "",
            }

        ],
        "type": [
            {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/organization-type",
                        "display": ""
                    }
                ]
            }
        ],
        "telecom": [
            {
                "system": "",
                "value": "",
                "use": "",
                "rank": 1
            }
        ],
        "address": [
            {
                "use": "",
                "line": [""],
                "country": "",
                "state": "",
                "city": "",
                "district": "",
                "postalCode": "",
                'text': 'Primary'
            }
        ],
        "contact": [
            {
                "purpose": {
                    "coding": [
                        {
                            "system": "http://terminology.hl7.org/CodeSystem/contactentity-type",
                            "code": "ADMIN"
                        }
                    ]
                },
                "name": {
                    "text": ""
                },
                "telecom": [],
                "address": {}
            }
        ]
    },
    "pageName": "",
    "orgPageImage": "",
    "pageAdmin": `${sessionStorage.getItem("userId")}`,
    "pagePortal": "",
    "pageStatus": "ACTIVE",
    "pageStatusUpdatedDate": new Date(),
    "pageWebsite": "",
    "status": true,
    "userId": Number(sessionStorage.getItem("userId"))
}




export default function DialogBox({ open, handleDialog, width }) {
    // console.log(open, "open")
    // console.log(handleDialog, "handle")

    const typedata = ["Healthcare Provider", "Organizational team", "Government", "Insurance Company",
        "Payer", "Educational Institute", "Religious Institution", "Clinical Research Sponsor",
        "Community Group", "Non - Healthcare Businessor Corporation", "Other"]

    const classes = useStyles();
    // const [pimage, setPimage] = useState([Userimage])
    const [image, setimage] = useState("")
    const [location, setLocation] = React.useState('')
    const [check, setCheck] = React.useState(false)
    const [accept, setAccept] = React.useState(false)
    const [link, setLink] = useState([{ assigner: '', type: '', id: '' }])
    const [countries, setCountries] = React.useState([])
    const [states, setStates] = React.useState([])
    const [cities, setCities] = React.useState([])
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [loc, setLoc] = React.useState({ country: null, state: null, city: null })
    const dispatch = useDispatch()
    const state = useSelector(state => state.userProfile.userProfile)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open1 = Boolean(anchorEl);
    const id = open1 ? 'simple-popover' : undefined;


    // console.log(state)
    React.useEffect(async () => {
        const countries = await allCountries
        setCountries(countries)
        // return () => { console.log("countries") }
    }, [])

    const imageHandler = (e) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            let type = e.target.files[0].type.split("/");
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = x => {
                let file = [];
                file.push(reader.result.split(',')[1])
                handlesubmit(file, type[1])
            }
        }
    };
    const handlesubmit = (Image, type) => {
        let picdata = {
            "file": Image,
            "fileType": type,
            "multiple": false,
            "subType": "profilePic",
            "type": "userProfilePics"
        }
        let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.post(`${process.env.REACT_APP_API_URL}v1/upload/file`, picdata, { headers: { "Authorization": auth } }).then(res => {
            // console.log(res.data.data[0])
            setimage(res.data.data[0])
        })
    }

    const handleAdd = () => {
        let contact = formik.values.organisation.telecom
        let length = formik.values.organisation.telecom.length
        if (length == 0) {
            let telecom = [...formik.values.organisation.telecom]
            telecom.push({ system: '', use: '', value: '' })
            formik.setValues({ ...formik.values, organisation: { ...formik.values.organisation, telecom } })
        }
        else if (contact.length > 0 && contact[length - 1].system !== '' && contact[length - 1].use !== '' && contact[length - 1].value !== '') {
            let telecom = [...formik.values.organisation.telecom]
            telecom.push({ system: '', use: '', value: '' })
            formik.setValues({ ...formik.values, organisation: { ...formik.values.organisation, telecom } })
        }
        else {
            alert("Please fill the empty fields")
        }
    }

    const handleAddFacility = () => {
        let length = link.length
        if (link[length - 1].assigner !== '' &&
            link[length - 1].type !== '' &&
            link[length - 1].id !== '') {
            setLink([...link, { assigner: '', type: '', id: '' }])
        }
        else {
            alert("Please fill the empty fields")
        }
    }

    const handlevalidate = () => {
        alert("validated")
    }


    // const getlocation = () => {
    //     const Location = window.navigator && window.navigator.geolocation
    //     if (Location) {
    //         Location.getCurrentPosition((position) => {
    //             setLocation(`${position.coords.latitude} ,${position.coords.longitude}`)
    //         }, (error) => {
    //             { }
    //         })
    //     }
    // }


    // console.log(initialValues)
    const formik = useFormik({
        initialValues,
        validationSchema: yup.object({
            organisation: yup.object().shape({
                name: yup.string().required("Organisation Name is required"),
            }),
        }),
        onSubmit: async (values) => {
            // console.log(values)
            values.organisation.contact[0].name.text = state.fullName
            values.organisation.contact[0].address = state.practioner.address[0]
            values.organisation.contact[0].telecom = state.practioner.telecom
            values.orgPageImage = image
            values.location = location
            values.pageName = values.organisation.name
            // values.organisation.telecom = values.organisation.telecom.filter(con => con.system !== '')
            values.userId = Number(sessionStorage.getItem("userId"))
            values.pageAdmin = `${sessionStorage.getItem("userId")}`
            values.metadata = await getMetaData()
            let auth = `Bearer ${sessionStorage.getItem('kctoken')}`
            handleDialog()
            const baseurl = `${process.env.REACT_APP_API_URL}v1/addPages`;
            axios.post(baseurl, values, { headers: { "Authorization": auth } }).then((res) => {
                // console.log(res.data)
                if (res.data.status === 200 || 201) {
                    // console.log("200")
                    let id = res.data.data.id
                    dispatch(getPageById(id))
                    dispatch(fetchProfile())
                } else if (res.data.status === 409) {
                    setAlert({ open: true, msg: "Organisation Page Already Exist" });
                }
                setimage("")

            }).catch(err => {
                // console.log(err,"helllllllllllllllllllllllllll")
            })
            formik.resetForm();
        }
    })




    return (
        <>
            <Dialog open={open} onClose={() => { handleDialog(); formik.resetForm() }} fullWidth maxWidth={'lg'} sx={{ mb: 2, alignItems: 'flex-start' }} className={classes.paper} >
                <DialogTitle className='orz_title'>
                    Create Organisation Page
                </DialogTitle>
                <DialogContent className='create_page' >
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <Grid container className='orz_margin'>
                            <Grid item sm={2} xs={12}>
                                <div className='organosation_image'>
                                    <div>
                                        {image ? <img src={image} height="98px" width="105px" style={{ borderRadius: "20px" }} /> :

                                            <img src={docimage} height="98px" width="105px" style={{ border: "none" }} />}
                                    </div>
                                </div>
                                <div className="uploadbtn2">
                                    <label htmlFor="filePicker">
                                        Upload</label>
                                    <BootstrapTooltip title='Choose only Images'>
                                        <InfoOutlined onClick={handleClick} style={{ width: "20px", height: "20px", marginBottom: "-5px", marginLeft: "5px" }} />
                                    </BootstrapTooltip>
                                    <input id="filePicker" style={{ visibility: "hidden", display: 'contents' }} type={"file"} onChange={imageHandler} />
                                </div>
                                <Popover
                                    id={id}
                                    open={open1}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                >
                                    <p style={{ fontSize: "10px", fontWeight: "200", padding: "4px" }}>Choose only Images</p>
                                </Popover>
                            </Grid>
                            <Grid item sm={9} xs={12} className="margin_768px">
                                <Grid container>
                                    <Grid item xs={12} sm={6} className='org_name'>
                                        <CssTextField
                                            className='orz_input_field'
                                            label="Organisation Name"
                                            // placeholder="Enter Organisation Name"
                                            name="organisation.name"
                                            fullWidth
                                            onBlur={formik.handleBlur}
                                            value={formik.values.organisation.name}
                                            onChange={formik.handleChange}
                                            error={Boolean(
                                                getIn(formik.errors, "organisation.name")
                                            )}
                                            helperText={
                                                getIn(formik.errors, "organisation.name")
                                            }
                                            {...formik.getFieldProps("organisation.name")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{ padding: '0px 10px' }}>
                                        <Autocomplete
                                            id="outlined-identifier.partOf-native-simple"
                                            options={typedata}
                                            getOptionLabel={option => option}
                                            isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                            name="organisation.type[0].coding[0].display"
                                            value={formik.values.organisation.type[0].coding[0].display || null}
                                            // value={formik.values.orgType}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    formik.setFieldValue("organisation.type[0].coding[0].display", value);
                                                }
                                            }}
                                            renderInput={params => (
                                                <CssTextField
                                                    {...params}
                                                    name="organisation.type[0].coding[0].display"
                                                    label="Type"
                                                    variant="outlined"
                                                    value={formik.values.organisation.type[0].coding[0].display || null}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} className='orz_caption_field'>
                                        <CssTextField
                                            className='orz_input_field'
                                            label="Caption and Tagline"
                                            // placeholder="Enter Caption or Tagline"
                                            name="organisation.identifier[0].type.text"
                                            fullWidth

                                            onBlur={formik.handleBlur}
                                            value={formik.values.organisation.identifier[0].type.text}
                                            onChange={formik.handleChange}
                                            error={Boolean(
                                                getIn(formik.errors, "organisation.identifier[0].type.text")
                                            )}
                                            helperText={
                                                getIn(formik.errors, "organisation.identifier[0].type.text")
                                            }
                                            {...formik.getFieldProps("organisation.identifier[0].type.text")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} className='orz__check' >
                                        <FormGroup>
                                            <Checkbox className="r-check" disableRipple checked={accept} name="accept" onChange={() => setAccept(!accept)} inputProps={{ 'aria-label': 'controlled' }} />
                                        </FormGroup>
                                        I here by declare , I'm official point of contact and have right to create this organisation page
                                    </Grid>
                                </Grid>
                            </Grid>


                            {accept ? <>
                                <Grid xs={12} item style={{ padding: '10px 0px' }}>
                                    <p className="p-heading flex-center" >Link Facility Id <img onClick={() => handleAddFacility()} src={Plus} alt="add" /></p>
                                </Grid>
                                {link.map((facility, i) => (
                                    <React.Fragment key={i}>
                                        <Grid item sm={6} xs={12} className="identifier_pd">
                                            <Autocomplete
                                                fullWidth
                                                name="organisation.identifier[0].system"
                                                value={formik.values.organisation.identifier[0].system || null}
                                                id="outlined-identifier.partOf-native-simple"
                                                options={["Government of India"]}
                                                getOptionLabel={option => option || ''}
                                                isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue("organisation.identifier[0].system", value);
                                                }}
                                                renderInput={params => (
                                                    <CssTextField
                                                        {...params}
                                                        name="organisation.identifier[0].system"
                                                        label="Assigner"
                                                        {...formik.getFieldProps("organisation.identifier[0].system")}
                                                        variant="outlined"
                                                        value={formik.values.organisation.identifier[0].system || null}
                                                        fullWidth
                                                    />
                                                )}
                                            />


                                        </Grid>

                                        <Grid item sm={6} xs={12} className="identifier_pd">
                                            <Autocomplete
                                                fullWidth
                                                name="organisation.identifier[0].type.coding[0].display"
                                                value={formik.values.organisation.identifier[0].type.coding[0].display || null}
                                                id="outlined-identifier.partOf-native-simple"
                                                options={["Provider Number"]}
                                                getOptionLabel={option => option || ''}
                                                isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue("organisation.identifier[0].type.coding[0].display", value);
                                                }}
                                                renderInput={params => (
                                                    <CssTextField
                                                        {...params}
                                                        name="organisation.identifier[0].type.coding[0].display"
                                                        label="ID Type"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={formik.values.organisation.identifier[0].type.coding[0].display || null}
                                                    />
                                                )}
                                            />
                                        </Grid>


                                        <Grid item sm={6} xs={12} className="identifier_pd">

                                            <CssTextField className='orz_input_field' label="ID" placeholder="xxxxxxxxxx"
                                                fullWidth

                                                name="organisation.identifier[0].value"
                                                value={formik.values.organisation.identifier[0].value}
                                                onChange={formik.handleChange}
                                                {...formik.getFieldProps("organisation.identifier[0].value")}

                                            />

                                        </Grid>
                                        <Grid item md={6} xs={12} sx={{ my: 'auto' }} className="validate_alignment">
                                            <Button onClick={handlevalidate} className="validate">Validate</Button>
                                        </Grid>
                                    </ React.Fragment>
                                ))}


                                <Grid xs={12} item style={{ padding: '15px 0px' }}>
                                    <p className="p-heading">Official Billing Address</p>
                                </Grid>
                                <Grid sm={6} xs={12} item className='orz_input_field'>
                                    <CssTextField
                                        fullWidth

                                        label="Facility Name"
                                        // placeholder="Facility Name"
                                        name="organisation.address[0].use"
                                        value={formik.values.organisation.address[0].use}
                                        onChange={formik.handleChange}

                                        {...formik.getFieldProps("organisation.address[0].use")}
                                    />
                                </Grid>
                                <Grid sm={6} xs={12} item className='orz_input_field'>
                                    <CssTextField

                                        fullWidth

                                        label="Address Line 1"
                                        // placeholder="Enter your address"
                                        name="organisation.address[0].line[0]"

                                        value={formik.values.organisation.address[0].line[0]}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("organisation.address[0].line[0]")}

                                    />
                                </Grid>
                                <Grid sm={6} xs={12} item className='orz_input_field1'>
                                    <CssTextField

                                        fullWidth

                                        label="Address Line 2"
                                        // placeholder="Enter your address"
                                        name="organisation.address[0].line[1]"
                                        value={formik.values.organisation.address[0].line[1]}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("organisation.address[0].line[1]")}

                                    />
                                </Grid>
                                <Grid item sm={6} xs={12} className='orz_input_field1'>
                                    <Autocomplete
                                        id="foreignCountry"
                                        // className="country-select"
                                        name="organisation.address[0].country"
                                        options={countries}
                                        getOptionLabel={option => option.name || ''}
                                        isOptionEqualToValue={(option, value) => option.name === value.name || ''}
                                        value={loc.country}
                                        onChange={async (e, value) => {
                                            formik.setFieldValue("organisation.address[0].state", null);
                                            formik.setFieldValue("organisation.address[0].district", '');
                                            formik.setFieldValue("organisation.address[0].city", null);
                                            formik.setFieldValue("organisation.address[0].postalCode", '');
                                            setStates([])
                                            setCities([])
                                            if (value) {
                                                setLoc({ country: value, state: null, city: null })
                                                formik.setFieldValue("organisation.address[0].country", value.name);
                                                // const states = State.getStatesOfCountry(value.isoCode);
                                                let states = await getAPI(`${URL.elib}country_wise_states/${value.iso2}`)
                                                setStates(states)
                                            }
                                            else {
                                                formik.setFieldValue("organisation.address[0].country", null);
                                            }
                                        }}
                                        renderInput={params => (
                                            <CssTextField
                                                {...params}
                                                name="organisation.address[0].country"
                                                label="Country"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12} className='orz_input_field'>

                                    <Autocomplete
                                        id="foreignCountry"
                                        // className="country-select"
                                        name="organisation.address[0].state"
                                        options={states}
                                        getOptionLabel={option => option.name || ''}
                                        isOptionEqualToValue={(option, value) => option.name === value.name || ''}
                                        value={loc.state}
                                        onChange={async (e, value) => {
                                            formik.setFieldValue("organisation.address[0].district", null);
                                            formik.setFieldValue("organisation.address[0].city", null);
                                            formik.setFieldValue("organisation.address[0].postalCode", '');
                                            setCities([])
                                            if (value) {
                                                setLoc({ ...loc, state: value, city: null })
                                                formik.setFieldValue("organisation.address[0].state", value.name);
                                                let citiesList = await getAPI(`${URL.elib}state_wise_cities/${loc.country.iso2}-${value.iso2}`)
                                                setCities(citiesList)
                                            }
                                            else {
                                                formik.setFieldValue("organisation.address[0].state", null);
                                            }
                                        }}
                                        renderInput={params => (
                                            <CssTextField
                                                {...params}
                                                name="organisation.address[0].state"
                                                label="State"

                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item sm={6} xs={12} className='orz_input_field'>
                                    <Autocomplete
                                        name="organisation.address[0].city"
                                        options={cities}
                                        getOptionLabel={option => option.name || ''}
                                        isOptionEqualToValue={(option, value) => option.name === value.name || ''}
                                        value={loc.city}
                                        onChange={(e, value) => {
                                            // formik.setFieldValue("organisation.address[0].city", '');
                                            formik.setFieldValue("organisation.address[0].postalCode", '');
                                            if (value) {
                                                setLoc({ ...loc, city: value })
                                                formik.setFieldValue("organisation.address[0].city", value.name);
                                            }
                                            else {
                                                formik.setFieldValue("organisation.address[0].city", null);
                                            }
                                        }}
                                        renderInput={params => (
                                            <CssTextField
                                                {...params}
                                                name="organisation.address[0].city"
                                                label="City"
                                                variant="outlined"

                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>


                                {/*<Grid item sm={6} xs={12} className='orz_input_field1'>
                                <CssTextField
                                    label="city"
                                    fullWidth
                                    id="outlined-identifier.city-native-simple"
                                    name="formik.values.organisation.address[0].city"
                                    value={formik.values.organisation.address[0].city}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    className="formcontrol"
                                    {...formik.getFieldProps("organisation.address[0].city")}
                                >
                                </CssTextField>
                            </Grid> */}
                                <Grid sm={6} xs={12} item className='orz_input_field1'>
                                    <CssTextField

                                        label="Pincode"
                                        fullWidth

                                        // placeholder="Enter your pincode"
                                        name="postalcode"
                                        value={formik.values.organisation.address[0].postalCode}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("organisation.address[0].postalCode")}

                                    />
                                </Grid>
                                {/* <Grid xs={12} item sx={{ padding: '10px 0px' }}>
                                <span className="location inputfield">Facility Location
                                    <div className="setinput">
                                        <div className="inputstyle">
                                            <div className="input-container">
                                                <input
                                                    className="input-field"
                                                    type="text"
                                                    placeholder="latitude,longitude"
                                                    name="location"
                                                    value={location}
                                                    onChange={formik.handleChange}
                                                />
                                                <i className="material-icons" onClick={getlocation}>&#xe55c;</i>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </Grid> */}
                                <Grid xs={12} item sx={{ padding: '10px 0px' }}>
                                    <span className="p-heading flex-center">Facility Contact Point
                                        <div className="d-flex e-align">
                                            <p>Public</p>
                                            <FormGroup>
                                                <Checkbox className="check-pad" disableRipple checked={check} name="check" onChange={() => setCheck(!check)} inputProps={{ 'aria-label': 'controlled' }} />
                                            </FormGroup>
                                            <img onClick={() => handleAdd()} src={Plus} alt="add" />
                                        </div>
                                    </span>
                                </Grid>

                                {formik.values.organisation.telecom[0] !== null && formik.values.organisation.telecom.map((contact, i) => (

                                    <React.Fragment key={i}>
                                        <Grid container id="create_telecom">
                                            <Grid item sm={4} xs={12}>
                                                <Autocomplete
                                                    fullWidth
                                                    name={`organisation.telecom[${i}].system`}
                                                    value={formik.values.organisation.telecom[i].system}
                                                    id="outlined-identifier.partOf-native-simple"
                                                    options={["Phone", "Email"]}
                                                    getOptionLabel={option => option}
                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                    onChange={(e, value) => {
                                                        formik.setFieldValue(`organisation.telecom[${i}].use`, null);
                                                        formik.setFieldValue(`organisation.telecom[${i}].value`, '');
                                                        formik.setFieldValue(`organisation.telecom[${i}].system`, value);
                                                    }}
                                                    renderInput={params => (
                                                        <CssTextField
                                                            {...params}
                                                            name={`organisation.telecom[${i}].system`}
                                                            label="System"
                                                            variant="outlined"
                                                            value={formik.values.organisation.telecom[i].system}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item sm={4} xs={12} className="orz_telecom_use">
                                                <Autocomplete
                                                    fullWidth
                                                    name={`organisation.telecom[${i}].use`}
                                                    value={formik.values.organisation.telecom[i].use}
                                                    id="outlined-identifier.partOf-native-simple"
                                                    options={["Official", "Work", "Home"]}
                                                    getOptionLabel={option => option}
                                                    onChange={(e, value) => {
                                                        formik.setFieldValue(`organisation.telecom[${i}].use`, value);
                                                    }}
                                                    renderInput={params => (
                                                        <CssTextField
                                                            {...params}
                                                            name={`organisation.telecom[${i}].use`}
                                                            label="Use"
                                                            variant="outlined"
                                                            value={formik.values.organisation.telecom[i].use}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item sm={4} xs={12}>
                                                <CssTextField
                                                    className='orz_input_field'
                                                    label="Value"
                                                    // placeholder="value"
                                                    fullWidth

                                                    name={`formik.values.organisation.telecom[${i}].value`}
                                                    value={formik.values.organisation.telecom[i].value}
                                                    onChange={formik.handleChange}
                                                    // handleChange={handleContact}
                                                    {...formik.getFieldProps(`organisation.telecom[${i}].value`)}
                                                >
                                                </CssTextField>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ))}

                                <div style={{ textAlign: 'right', marginTop: '15px', marginLeft: 'auto' }}>
                                    <Button onClick={() => { handleDialog(); formik.resetForm() }} className="close-article">Cancel</Button>
                                    <Button type="submit" className="save-btn btn__primary create-pad">Create</Button>
                                </div>
                            </> :
                                <div style={{ textAlign: 'right', marginTop: '15px', marginLeft: 'auto' }}>
                                    <Button onClick={() => { handleDialog(); formik.resetForm() }} className="save-btn btn__primary create-pad">Close</Button>
                                </div>
                            }
                        </Grid>

                    </form>

                </DialogContent>
            </Dialog >
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />
        </>

    )
}