import React, { useState } from 'react';
import axios from 'axios';
import hashtag from '../../Atom/modifiedicons/hashtag.svg';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import URL from "../../apiurl";
import './InputOption.css';
import { BootstrapTooltip } from '../Post/commonFun';


function Hashtagtrending() {
    const [trending, setTrending] = useState([])
    const history = useHistory()
    React.useEffect(() => {
        axios.get(`${URL.suggestedTopics}trendinghashtags`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => res.data).then(data => {
            // console.log(data)
            // seth(data)
            setTrending(data)
            // setSuggestedTopics(data.slice(5, 10))
        }).catch(err => {

        })
    }, [])
    const showhashtag = (data) => {
        const tag = data;
        // console.log(tag)
        history.push({
            pathname: `/hashtagpost`,
            state: tag
        })
    }
    return (
        <>
            <Grid container>
                <Grid item md={12} xs={12}>
                    {/* trending */}
                    <div className="sidebar__recent trending_padding border__bottom">
                        <div>
                            {trending.map((data, i) => (
                                <div key={i} className='hashtag_display'>
                                    <div>
                                        <img src={hashtag} height="20px" width="20px" alt="hashtag" />
                                    </div>
                                    <div className="sidebar__ml sidebar__lh media_display" style={{ fontSize: "18px", fontWeight: "400" }}>
                                        {/* <span className='trending_text'>Trending Hash Tag</span> */}
                                        <BootstrapTooltip title={`${data}`}>
                                            <span style={{ color: "#007dcd" }} key={i} className="pl-1 cursor" onClick={() => showhashtag(data)}>{data}</span>
                                        </BootstrapTooltip>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Hashtagtrending