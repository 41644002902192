import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip, IconButton, Grid } from '@mui/material';
import OrgLogo from '../../../../../../images/orglogo.svg';
import { FiEdit3 } from 'react-icons/fi';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from "./deleteFacilityDialog";
import { useSelector } from 'react-redux';
import scroll from "../../../../../Atom/svg/scroll.svg"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { URI } from '../../../../../utility/uri';
import { D_API } from '../../../../../../services/APIService';
import { ContextInfo } from "../adminOrganisationNew"
import { FormDivider } from '../../../../../utility/utils';


const EditFacilityListDailog = ({ open, close, facilityList, editFacilityFn }) => {


    const [info, setInfo] = useState([]);

    const contextDetails = useContext(ContextInfo);
    const orgData = useSelector((state) => state.registration.userOrg);
    console.log(orgData, "snow")



    useEffect(() => {
        let sortList = facilityList?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value);
        if (sortList?.length > 0) {
            setInfo(sortList);
        } else {
            setInfo([]);
        }

    }, [facilityList])



    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [deleteObj, setDeleteObj] = useState(null);

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    }

    console.log(facilityList, "facilityList");

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setDeleteObj(null);
    }

    const updateSuborganization = (data) => {
        const { resource } = data;
        let Id = resource?.id;
        if (Id) {
            D_API.put(`${orgData?.id}/${URI.updateOrg}${Id}`, { ...resource }).then((res) => {
                if (resource?.partOf) {
                    let valId = resource?.partOf?.reference?.split("/")?.[1];
                    if (valId != null && valId != undefined && valId != "") {
                        contextDetails.getsubOrgDetails(valId);
                    }

                }


            }).catch((err) => console.log(err));
        }

    }


    const handleDragEnd = (result) => {


        let list = [...info];
        if (!result?.destination) {
            return;
        }

        const { source, destination } = result;
        console.log(result, "result")
        const [sourceObj] = list?.splice(source?.index, 1);
        list?.splice(destination?.index, 0, sourceObj);
        let updatedList = list?.map((item, index) => {
            if (item?.resource?.identifier?.some((val) => val.system === "https://positionofsuborganization/")) {
                let data = {
                    ...item,
                    resource: {
                        ...item.resource,
                        identifier: item.resource?.identifier?.map((e) =>
                            e.system === "https://positionofsuborganization/"
                                ? { ...e, value: (index).toString() }
                                : e
                        ),
                    }

                }
                updateSuborganization(data);
                return {
                    ...item,
                    resource: {
                        ...item.resource,
                        identifier: item.resource?.identifier?.map((e) =>
                            e.system === "https://positionofsuborganization/"
                                ? { ...e, value: (index).toString() }
                                : e
                        ),
                    }

                }
            } else {
                let data = {
                    ...item, resource: { ...item.resource, identifier: item.resource?.identifier ? [...item?.resource?.identifier, { system: "https://positionofsuborganization/", value: index?.toString() }]?.filter(((val) => val != undefined)) : [{ system: "https://positionofsuborganization/", value: index?.toString() }] }
                }
                updateSuborganization(data);
                return {
                    ...item, resource: {
                        ...item.resource, identifier: item.resource?.identifier ? [...item?.resource?.identifier, {
                            system: "https://positionofsuborganization/", value: (index)?.toString()
                        }]?.filter(((val) => val != undefined)) : [{ system: "https://positionofsuborganization/", value: (index)?.toString() }]
                    }
                }
            }
        })

        setInfo(updatedList);

    }

    return (
        <div>
            <React.Fragment>
                <DeleteDialog open={openDeleteDialog} close={handleCloseDeleteDialog} delObj={deleteObj} closeEditDialog={close} parentOrg={orgData} />
                <Dialog
                    open={open}
                    onClose={close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                    fullWidth

                >
                    <DialogTitle id="alert-dialog-title">
                        <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Edit Facility Details</div>
                    </DialogTitle>
                    <DialogContent className="tohideScrollbar" sx={{ height: "410px", overflow: "auto" }} >
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="facilities">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            overflowY: "auto",
                                            height: "100%",
                                        }}
                                    >
                                        {
                                            info?.length > 0 ? (
                                                <div>
                                                    {
                                                        info?.map((val, i) => {
                                                            return (
                                                                <Draggable
                                                                    key={i.toString()}  // Use one-based index as string for key
                                                                    draggableId={i.toString()}  // Use one-based index as string for draggableId
                                                                    index={i}  // Use zero-based index for sorting
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={{
                                                                                ...provided.draggableProps.style,
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: "10px",
                                                                                padding: "10px",
                                                                                marginBottom: "10px",
                                                                                backgroundColor: snapshot.isDragging ? "#e3f2fd" : "white",
                                                                                borderRadius: "7px",
                                                                                border: "1px solid #ddd"

                                                                            }}
                                                                        >
                                                                            <Grid container justifyContent="space-between" alignItems="" className='pb1' >
                                                                                <Grid item>
                                                                                    <div className='flx-center' style={{ gap: "20px" }}>
                                                                                        <div id="" style={{ height: '50px', width: '50px', borderRadius: '7px' }}>
                                                                                            <div style={{ backgroundColor: '#A8E8FE', width: '55px', height: "55px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "7px" }}>
                                                                                                <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='fntMd fntSemiBold'>{val?.resource?.name?.length > 60 ? <span>{val?.resource?.name?.slice(0, 60)}...</span> : <span>{val?.resource?.name}</span>}</div>
                                                                                            <div className='fntXSm'>{val?.resource?.alias?.[0]?.length > 60 ? `${val?.resource?.alias?.[0]?.slice(0, 60)}...` : val?.resource?.alias?.[0]}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>

                                                                                    <div className="sidebar__statNumber d-flex  ">
                                                                                        <div style={{ padding: '8px', marginRight: '8px' }}>
                                                                                            <img src={scroll} alt="scroll" />
                                                                                        </div>
                                                                                        <Tooltip title="Edit Facility">
                                                                                            <IconButton onClick={() => editFacilityFn(val.resource?.id, orgData)} >
                                                                                                <FiEdit3 style={{ fontSize: '1.3rem', color: '#000000bd' }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Delete Facility">
                                                                                            {/* <IconButton onClick={() => setModal({ open: true, id: i })}> */}
                                                                                            <IconButton onClick={() => {
                                                                                                handleOpenDeleteDialog();
                                                                                                setDeleteObj(val)
                                                                                            }} >
                                                                                                <DeleteIcon sx={{ color: 'red' }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </div>

                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                </ Draggable>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            ) : (
                                                <p className="flx-center justify-center fntSemiBold fntSm14">No Data Available</p>
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </DialogContent>
                    <FormDivider />
                    <DialogActions>
                        <Button variant="text" onClick={close}>close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}

export default EditFacilityListDailog;