import { FormControl, Select, MenuItem, Box, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';

export default function Dropdown({ getOptions, ...props }) {
    const [value, setValue] = useState(props?.multiple ? [] : '')

    useEffect(() => {

        if (props?.multiple) {
            setValue(props?.value && props?.value?.isArray() ? props?.value : [])
        }
        else {
            setValue(props?.value && !props?.value?.isArray() ? props?.value : '')
        }

    }, [props?.multiple])

    return (
        props?.multiple ?
            <FormControl fullWidth variant="standard">
                <TextValidator
                    // classes={{ root: classes.root }}
                    select
                    variant="standard"
                    validators={['required']}
                    errorMessages={['Select an option']}
                    sx={{ width: { sm: '300px', xs: '100%' } }}
                    value={value}
                    SelectProps={{
                        multiple: true,
                        value: value?.length === 0 ? ['Select'] : value,
                        renderValue: (selected) => {
                            return (
                                selected?.[0] === 'Select' ? 'Select' :
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected?.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                            )
                        },
                        onChange: (e) => {
                            if (value?.length === 0) {
                                let a = []
                                let y = [e.target.value?.shift()]
                                a.push(e.target.value?.shift())
                                setValue(a)
                                props.onChange(a)
                            }
                            else {
                                setValue(typeof e.target.value === 'string' ? e.target.value?.split(',') : e.target.value)
                                props?.onChange(e.target.value)

                            }
                        }
                    }}
                >
                    {props?.options?.map((value, i) => (
                        <MenuItem key={i} value={value?.valueCoding?.display}>{value?.valueCoding?.display}</MenuItem>
                    ))}
                </TextValidator>
            </FormControl>
            :
            <FormControl fullWidth variant="standard">
                <TextValidator
                    select
                    variant="standard"
                    {...props}
                    sx={{ width: { sm: '300px', xs: '100%' } }}
                    value={value}
                    SelectProps={{
                        multiple: false,
                        value: value === '' ? 'Select' : value,
                        onChange: (e) => { setValue(e.target.value); props.onChange(e.target.value) },
                        renderValue: (value) => value === '' ? 'Select' : value
                    }}
                >
                    <MenuItem value={''}>--Select--</MenuItem>
                    {props.options?.map((value, i) => (
                        <MenuItem key={i} value={value?.valueCoding?.display}>{value?.valueCoding?.display}</MenuItem>
                    ))}
                </TextValidator>
            </FormControl>
    );
}