
import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Webinar from "./Webinar";

function MedscopeSection() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{ width: "100%", typography: "body1", backgroundColor: 'transparent' }}
    >
      <TabContext value={value}>
        <Box >
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered className="medscope-header-tab" sx={{ '& .MuiTabs-indicator': { backgroundColor: 'white' }, '& .Mui-selected': { color: 'white' } }}>
            <Tab label="Webinar" value="1" className="webinar-styling" />
            <Tab label="Career Options" value="2" className="webinar-styling" />
            <Tab label="Job Opening" value="3" className="webinar-styling" />
            <Tab label="AppVault" value="4" className="webinar-styling" />
          </TabList>
        </Box>
        <TabPanel value="1"><Webinar /></TabPanel>
        <TabPanel value="2">Career Options</TabPanel>
        <TabPanel value="3">Job Opening</TabPanel>
        <TabPanel value="4">AppVault</TabPanel>
      </TabContext>
      <Tabs value={value} onChange={handleChange} centered></Tabs>
    </Box>
  );
}

export default MedscopeSection;
