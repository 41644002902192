import { Typography, Box, Button, Divider } from '@mui/material'
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import './peer.css'


const CustomButton = (props) => {
    return (
        <Button className="add-option" id='share_social' variant="outlined" onClick={() => navigator.clipboard.writeText('https://www.docsteth.com')}>{props.children}&nbsp;{props.name}</Button>
    )
}

const CustomRow = (props) => {
    return (
        <Typography component="div" className='custom_padding' >
            <Typography component="p" sx={{ pb: 1 }} className="e-align d-flex">
                {props.children}
            </Typography>
            {props.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
        </Typography>
    )
}

export default function InvitePeers(props) {

    const Handleclick = async () => {
        // console.log("hello");
        if ("contacts" in navigator &&
            "select" in navigator.contacts &&
            "getProperties" in navigator.contacts) {
            try {
                const availableProperties = await navigator.contacts.getProperties();
                if (availableProperties.includes("address")) {
                    const contactProperties = ['name', 'tel'];
                    const contacts = await navigator
                        .contacts
                        .select(
                            contactProperties,
                            { multiple: true }
                        );

                    // console.log("Your first contact: " + contacts[0].name + " " + contacts[0].tel);
                } else {
                    // console.log("Contact Picker API on your device doesn't support address property");
                }
            } catch (err) {
                // console.log("Unexpected error happened in Contact Picker API");
            }
        } else {
            // console.log("Your browser doesn't support Contact Picker API");
        }
    }

    return (
        <Box>
            <Typography sx={{ p: '15px', backgroundColor: '#007dcd', color: 'white' }}>
                Invite Your Peers
            </Typography>

            <CustomRow>
                <WhatsappShareButton
                    title={"Hello this an invitation for docsteth SignUp"}
                    separator={"    =>  "}
                    url={"https://www.docsteth.com/"}
                    windowWidth='800px'
                    windowHeight='800px'
                    onClick={() => { }}
                    openShareDialogOnClick
                >
                    <CustomButton name="DM Invitation">
                        <WhatsAppIcon sx={{ color: 'black' }} />
                    </CustomButton>
                </WhatsappShareButton>

                <TwitterShareButton
                    url='https://www.docsteth.com/'
                    title='Hello this an invitation for docsteth SignUp'
                    via='Dr Akhil Kolli'
                    windowWidth='800px'
                    windowHeight='800px'
                >
                    <CustomButton name="Tweet Invite">
                        <TwitterIcon sx={{ color: 'black' }} />
                    </CustomButton>
                </TwitterShareButton>
            </CustomRow>


            <CustomRow>
                <LinkedinShareButton
                    url='https://www.docsteth.com/'
                    title='Docsteth invitation'
                    summary='Hello this an invitation for docsteth SignUp'
                    source='Docsteth'
                    windowWidth='800px'
                    windowHeight='800px'
                >
                    <CustomButton name="Post in Linkedin" >
                        <LinkedInIcon sx={{ color: 'black' }} />
                    </CustomButton>
                </LinkedinShareButton>
                <FacebookShareButton
                    url='https://www.docsteth.com/'
                    quote='Hello this an invitation for docsteth SignUp'
                    hashtag='#Docsteth'
                    windowWidth='800px'
                    windowHeight='800px'
                >
                    <CustomButton name="post in Facebook">
                        <FacebookIcon sx={{ color: 'black' }}></FacebookIcon>
                    </CustomButton>
                </FacebookShareButton>
            </CustomRow>


            <CustomRow true={true}>
                <EmailShareButton
                    subject={'Hello this an invitation for docsteth SignUp'}
                    body={"share with your friends "}
                    url='https://www.docsteth.com/'
                >
                    <CustomButton name="Email Invite">
                        <EmailIcon className='inside_icon' sx={{ color: 'black' }} />
                    </CustomButton>
                </EmailShareButton>
                {/* <CustomButton name="SMS" onClick={Handleclick}>
                    <SmsIcon className='inside_icon' sx={{ color: 'black' }} />
                </CustomButton> */}
                <CustomButton name="Copy Invite">
                    <ContentCopyIcon className='inside_icon' sx={{ color: 'black' }} />
                </CustomButton>
            </CustomRow>


            <Typography onClick={props.close} className="textCenter font-bold cursor" sx={{ mt: 1, p: '10px', backgroundColor: '#e4eef5', color: '#0f3f5d' }}>
                Done
            </Typography>
        </Box>
    )
}