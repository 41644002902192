import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { FormGroup, RadioGroup, FormControlLabel, Grid, Checkbox } from '@mui/material';


class CheckboxValidator extends ValidatorComponent {

    renderValidatorComponent() {
        const { errorMessages, validators, options, validatorListener, handleChange, ...rest } = this.props;

        return (
            <div>
                <FormGroup {...rest} value={this.props.value}>
                    <Grid container>
                        {options?.map((option, i) => (
                            <Grid key={i} item lg={options?.length > 4 ? 4 : 12} sm={options?.length > 4 ? 6 : 12} xs={12}>
                                <FormControlLabel
                                    label={option?.valueCoding?.display}
                                    value={option?.checked}
                                    checked={option?.checked}
                                    onChange={(e) => handleChange(e.target.checked, i, option?.valueCoding?.display)}
                                    control={<Checkbox checked={option?.checked} />}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </FormGroup>

                {this?.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid, requiredError, validators, withRequiredValidator } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: '#d32f2f', fontSize: '12px' }}>
                {this?.getErrorMessage()}
            </div>
        );
    }
}

export default CheckboxValidator;