import React, { useEffect, useState } from "react";
// import Layout from "../../MyProfile/layout"
import LinkAccountImg from "../../../images/linkaccount.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import ParamithLogos from "../../../../src/images/paramithaLogo.svg"
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import DialogBox from "../../DialogBox/dialog";
import { FiEdit3 } from "react-icons/fi";
import Dropdown from "../../Fields/CssTextField";
import { ValidatorForm } from "react-form-validator-core";
import { FaSpotify, FaInstagramSquare } from "react-icons/fa";
import axios from "axios";
import URL from "../../apiurl";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../../services/UserService";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditAccount from "./editAccount";
import { useDispatch, useSelector } from "react-redux";
import {
  getLinkedAccounts,
  getLinkedAccounts1,
} from "../../../redux/Actions/connectActions";
import { headers } from "../../../redux/Actions/connectActions";
import Layout from "../MyProfile/organisation/layout";
import {
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { GrGoogle } from "react-icons/gr";
import DeleteIcon from "@mui/icons-material/Delete";

const Twitter = () => (
  <TwitterIcon
    style={{
      color: "rgb(29, 155, 240)",
      width: "25px",
      height: "25px",
      paddingRight: "7px",
    }}
  />
);
const Facebook = () => (
  <FacebookIcon
    style={{
      color: "#385898",
      width: "25px",
      height: "25px",
      paddingRight: "7px",
    }}
  />
);
const Google = () => (
  <GrGoogle
    style={{
      color: "#385898",
      width: "20px",
      height: "20px",
      paddingRight: "7px",
    }}
  />
);
const LinkedIn = () => (
  <LinkedInIcon
    style={{
      color: "#007DCD",
      width: "25px",
      height: "25px",
      paddingRight: "7px",
    }}
  />
);
const WhatsApp = () => (
  <WhatsAppIcon
    style={{
      color: "green",
      width: "25px",
      height: "25px",
      paddingRight: "7px",
    }}
  />
);
const Spotify = () => (
  <FaSpotify
    style={{
      color: "#1DB954",
      width: "25px",
      height: "25px",
      paddingRight: "7px",
    }}
  />
);
const Insta = () => (
  <FaInstagramSquare
    style={{ width: "25px", height: "25px", paddingRight: "7px" }}
  />
);


const RenderParamithaLogo = () => {
  return (

    <img src={ParamithLogos} style={{ width: "30px !important", height: "30px", paddingRight: "10px" }} />
  )
}


export const comp = {
  linkedin: <LinkedIn />,
  whatsapp: <WhatsApp />,
  spotify: <Spotify />,
  facebook: <Facebook />,
  google: <Google />,
  instagram: <Insta />,
  twitter: <Twitter />,
  Private: <VisibilityOffOutlinedIcon />,
  Public: <VisibilityOutlinedIcon />,
  paramitha: <RenderParamithaLogo />
};

export const redirect_uri = (type, id) => {
  const url =
    type === "post"
      ? process.env.REACT_APP_DOCSTETH
      : `${process.env.REACT_APP_DOCSTETH}/shareprofile`;
  return {
    linkedin: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78v81nxtfzvqns&redirect_uri=${url}&scope=r_basicprofile%20r_emailaddress%20w_member_social&state=de71b7e4-6610-47bd-9dd1-1b1886d9abc9_${type}-${id}`,
    twitter: `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=b2M2OHdXUEdfZTRSMGhadzhoaXk6MTpjaQ&redirect_uri=${url}&scope=tweet.read%20tweet.write%20users.read%20offline.access%20follows.read%20follows.write&state=da8e1439-fb1a-447e-b481-cb5e5028e521_${type}-${id}&code_challenge=challenge&code_challenge_method=plain`,
  };
};

const providerOptions = [
  "linkedin",
  "twitter",
  "Facebook",
  "instagram",
  "whatsapp",
  "spotify",
  "Google",
  "Others",
];

const serviceProfile = ["Medxperts", "Practo", "Paramitha", "Others"];

const CellData = ({ name }) => {
  return <TableCell>{name}</TableCell>;
};

export const CloseBtn = ({ name, ...props }) => {
  return (
    <Button sx={{ color: "black" }} className="capitalize" {...props}>
      {name}
    </Button>
  );
};

export const CreateBtn = ({ name, ...props }) => {
  return (
    <Button
      sx={{ padding: "3px 10px!important", borderRadius: "12px" }}
      className="capitalize"
      variant="contained"
      {...props}
    >
      {name}
    </Button>
  );
};

// export const Inputs = ({ options, value, handleChange, placeholder, hide, ...props }) => {

//     console.log(options, "options11")
//     return (
//         <Autocomplete
//             options={options}
//             getOptionLabel={(option) => typeof option === 'string' ? option : option?.page || option} // Render page if option is an object
//             isOptionEqualToValue={(option, value) => option?.page === value?.page || option === value}
//             value={value}
//             onChange={handleChange}
//             filterOptions={(x) => x}
//             renderOption={(props, option) => (
//                 <li {...props}>

//                     <span className="capitalize">{typeof option === 'string' ? option : option?.page}</span> {/* Render page if option is an object */}
//                 </li>
//             )}
//             sx={{ '& input': { textTransform: 'capitalize' } }}
//             renderInput={(params) => (
//                 <TextField
//                     {...params}
//                     {...props}
//                     margin="dense"
//                     sx={{
//                         borderRadius: '7px',
//                         ".MuiOutlinedInput-root": { padding: '4px' }, boxShadow: 1
//                     }}
//                     placeholder={placeholder}
//                     variant="outlined"
//                     fullWidth
//                     InputProps={{
//                         ...params.InputProps,
//                         startAdornment: !hide && value && typeof value !== 'string' ? (
//                             <span style={{ marginRight: 8 }}>

//                             </span> // Render page if value is an object
//                         ) : null
//                     }}
//                 />
//             )}
//         />
//     )
// }
export const Inputs = ({
  options,
  value,
  handleChange,
  placeholder,
  hide,
  ...props
}) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => (option ? option : "")}
      // getOptionDisabled={(option) =>
      //     option !== providerOptions[0] && option !== providerOptions[1]
      // }
      isOptionEqualToValue={(option, value) => option === value || ""}
      value={value}
      onChange={handleChange}
      filterOptions={(x) => x}
      className="capitalize"
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {(!hide && comp[option.toLowerCase()]) || ""}
          <span className="capitalize">{option || ""}</span>
        </li>
      )}
      sx={{ "& input": { textTransform: "capitalize" } }}
      renderInput={(params) => {
        // console.log(params)
        return (
          <Dropdown
            {...params}
            {...props}
            margin="dense"
            placeholder={placeholder}
            variant="outlined"
            value={value}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {!hide && value ? comp[value] : ""}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

const AccountsList = ({ linkedAcc, getAccounts, ...props }) => {
  let header = [
    "Provider",
    "Profile/Page",
    "Purpose",
    "Visibility",
    "Status",
    "",
    "",
  ];
  const [open, setOpen] = useState(0);

  console.log(linkedAcc, "zero");
  // const [edit, setEdit] = useState(0)
  // const deleteAcc = () => {
  //     console.log(linkedAcc, "linkedAcc", UserService.getToken())

  //     axios.delete(process.env.REACT_APP_API_URL_PYI + `/deleteLinkedAccounts`, data, {
  //         headers: {
  //             "Authorization": `bearer ${UserService.getToken()}`
  //         }
  //     })
  //         .then(res => {
  //             getAccounts()
  //             handleDialog()
  //         })
  //         .catch(() => { })
  // }
  console.log(open, "linkedAcc");

  useEffect(() => {
    getAccounts();
  }, []);

  const deleteAcc = () => {
    const token = UserService.getToken();
    const data = {
      // platform: open,
      platform: open == "paramitha" ? `keycloak-${open}` : open,
    };

    console.log("Data:", data, "Token:", token); // Log to ensure data and token are retrieved correctly

    if (token) {
      axios
        .delete(`${process.env.REACT_APP_API_URL_PYI}/deleteLinkedAccounts`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
          data: data, // Include data in the request body
        })
        .then((res) => {
          console.log("Delete successful:", res.data); // Optionally log the response
          getAccounts(); // Assuming getAccounts is defined elsewhere
          handleDialog(); // Assuming handleDialog is defined elsewhere
        })
        .catch((error) => {
          console.error("Delete failed:", error); // Log any errors
        });
    } else {
      console.error("Token not available");
    }
  };

  // const deleteAcc = () => {
  //     axios.delete(URL.deleteAccount + open, headers())
  //         .then(res => {
  //             getAccounts()
  //             handleDialog()
  //         })
  //         .catch(() => { })
  // }

  const handleDialog = () => {
    setOpen(0);
  };

  return (
    <>
      <DialogBox
        open={open !== 0}
        handleDialog={handleDialog}
        title="Delete Account"
        Content={<p>Do you want to delete account</p>}
        Actions={
          <>
            <CloseBtn name="No" onClick={handleDialog} />
            <CreateBtn onClick={deleteAcc} name="Yes" />
          </>
        }
      />
      <Table className="link_border">
        <TableHead>
          {header?.map((key, i) => (
            <React.Fragment key={i}>
              <CellData name={key} />
            </React.Fragment>
          ))}
        </TableHead>
        <TableBody>
          {console.log(linkedAcc, "linkedAcc")}
          {linkedAcc.length > 0 ? (
            linkedAcc.map((acc) => (
              <TableRow key={acc.id}>
                <CellData
                  name={
                    <div className="d-flex ta__center  capitalize">
                      {comp[acc.platform]} <p>{acc.platform}</p>
                    </div>
                  }
                />
                <CellData
                  name={
                    <a
                      className="d-flex e-align capitalize"
                      href={acc.socialAccountLink}
                      target="_blank"
                    >
                      <Avatar src={acc.profile} />
                      &nbsp;{acc.name}
                    </a>
                  }
                />
                <CellData name={acc.purpose} />
                <CellData
                  name={
                    <div className="d-flex e-align capitalize">
                      {comp[acc.visibility]}&nbsp;<p>{acc.visibility}</p>
                    </div>
                  }
                />
                <CellData name={acc.status} />
                <TableCell>
                  <FiEdit3
                    style={{ fontSize: "20px" }}
                    onClick={() => props.handleEdit(acc)}
                  />
                </TableCell>
                <TableCell className="cursor">
                  <DeleteIcon
                    sx={{ color: "red" }}
                    onClick={() => setOpen(acc?.platform)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={12} align="center">
              No Linked Accounts
            </TableCell>
          )}
        </TableBody>
      </Table>

      <DialogActions>
        <CloseBtn name="Close" onClick={props.handleDialog} />
        <CreateBtn onClick={props.handleAcc} name="Add New" />
      </DialogActions>
    </>
  );
};

const AddAccount = ({ close }) => {
  let formData = {
    purpose: "",
    accProvider: "",
    providerName: "",
    profile: "",
  };
  const [state, setState] = useState(formData);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const accounts = useSelector((state) => state.connect.linkedAccounts);
  console.log(location, "paramsssss", params, accounts);

  const handleChange = (name, value) => {
    if (name === "purpose") setState({ ...formData, [name]: value });
    else setState({ ...state, [name]: value });
  };
  console.log(state, "330");
  const handlePurposeChange = (event) => {
    const selectedPurpose = event.target.name;
    setState((prevState) => ({ ...prevState, purpose: selectedPurpose }));
  };

  const linkAccount1 = () => {
    let data = {
      purpose: state.purpose,
      providerName: state.providerName,
    };
    console.log(data, "888888");

    // console.log(redirect_uri(params.name)[state.providerName])
    // window.location.href = redirect_uri('user',params.name)[state.providerName]

    axios
      .get(`https://dev.api.docsteth.com/feed/FacebookOauth`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));

    // if (state.providerName === 'linkedin' || state.providerName === 'twitter') {
    //     axios.post(URL.linkAccount, data, headers())
    //         .then(res => {
    //             window.location.href = redirect_uri('user', params.name)[state.providerName]
    //         }).catch(err => console.log(err))
    // }
  };

  // useEffect(() => {
  //     history.push(`http://localhost:3000/myprofile/drdharani-1425645864`)
  // }, [])

  const linkToParamitha = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL_PYI}/paramithaOID/auth`, headers())
      .then((res) => {
        console.log(res?.data?.url, "p0c");
        if (res.data.url) {
          window.location.href = res.data.url;
          // history.push(`/${res.data?.url}`)
          // <Redirect to={`${res.data.url}`}/>
        }
      })
      .catch((err) => console.log(err));
  };

  const linkAccount = () => {
    console.log(state);
    const data = {
      purpose: state.purpose,
      providerName: state.providerName,
    };

    if (state.providerName === "twitter") {
      axios
        .post(URL.linkAccount, data, headers())
        .then((res) => {
          window.location.href = redirect_uri("user", params.name)[
            state.providerName
          ];
        })
        .catch((err) => console.error(err));
    } else if (
      state.providerName === "Facebook" ||
      state.providerName === "Google" ||
      state.providerName === "linkedin"
    ) {
      axios
        .get(process.env.REACT_APP_API_URL_PYI + `/${state.providerName}Oauth`)
        .then((res) => {
          console.log(res.data, "linkedinnnnnnnnn");
          sessionStorage.setItem("valueOfAouth", state.providerName);
          window.location.href = res.data?.facebookurl || res.data?.url;
        })
        .catch((err) => console.log(err));
      // const facebookUrl = "https://facebook.com/v20.0/dialog/oauth?response_type=code&client_id=1004038077840144&redirect_uri=https%3A%2F%2F127.0.0.1%3A8001%2Ffeed%2FGetFacebookToken&scope=public_profile+email+pages_show_list+pages_read_engagement+pages_manage_metadata+pages_manage_posts&state=7k7E8SzLFxaxLW4ez6TX8McLYFfA4L";
      // window.location.href = facebookUrl;
    } else if (state.purpose === "Service Profile") {
      if (state.providerName) {
        switch (state.providerName) {
          case "Paramitha":
            linkToParamitha();
            break;
          default:
            break;
        }
      }
    }
    sessionStorage.setItem("backLinkPath", location?.pathname);
  };

  return (
    <Box>
      <ValidatorForm onSubmit={linkAccount}>
        <>
          {/* 
                    <Inputs placeholder="Account Purpose"
                        handleChange={(e, value) => handleChange('purpose', value)}
                        options={['Network & Outreach', 'Service Profile']}
                        value={state.purpose}
                        validators={['required']}
                        errorMessages={['Please select account purpose']}
                    /> */}

          <div className="d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.purpose === "Network & Outreach"}
                  name="Network & Outreach"
                  onChange={handlePurposeChange}
                />
              }
              label="Network & Outreach"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.purpose === "Service Profile"}
                  name="Service Profile"
                  onChange={handlePurposeChange}
                />
              }
              label="Service Profile"
            />
          </div>
          {console.log(serviceProfile, "100100", providerOptions)}
          {state.purpose && (
            <Inputs
              placeholder="Select Account Provider"
              handleChange={(e, value) => handleChange("providerName", value)}
              options={
                state.purpose && state.purpose === "Service Profile"
                  ? serviceProfile
                  : providerOptions.filter(
                    (op) =>
                      !accounts.some(
                        (acc) => acc.platform === op.toLowerCase()
                      )
                  )
              }
              value={state.providerName}
              validators={["required"]}
              errorMessages={["Please select account providerr"]}
            />
          )}
          {state.providerName &&
            (state.providerName !== "Others" ? (
              <div className="d-flex">
                {/* <FormControlLabel control={<Checkbox />} label="Social Share" />
                            <FormControlLabel control={<Checkbox />} label="Business Profile" /> */}
              </div>
            ) : (
              <>
                <Dropdown
                  margin="dense"
                  placeholder="Account Provider / Application Name"
                  variant="outlined"
                  value={state.accProvider}
                  fullWidth
                  onChange={(e) => handleChange("accProvider", e.target.value)}
                  validators={["required"]}
                  errorMessages={[
                    "Please enter Account Provider / Application Name",
                  ]}
                />
                <Dropdown
                  margin="dense"
                  placeholder="Your Profile / Page URL"
                  variant="outlined"
                  value={state.profile}
                  fullWidth
                  onChange={(e) => handleChange("profile", e.target.value)}
                  validators={["required"]}
                  errorMessages={["Please enter Your Profile / Page URL"]}
                />
              </>
            ))}
        </>

        <DialogActions>
          <CloseBtn name="Close" onClick={close} />
          <CreateBtn type="submit" name={"Link"} />
        </DialogActions>
      </ValidatorForm>
    </Box>
  );
};

const LinkAccount = () => {
  const [open, setOpen] = useState(false);
  const [acc, setAcc] = useState(false);
  const [linkedAcc, setLinkedAcc] = useState([]);
  const [editData, setEditData] = useState(null);
  const [anchorEl, setAnchorEl] = useState({});
  const openPop = Boolean(anchorEl);

  const [anchorEls, setAnchorEls] = useState({});

  const handlePopoverOpen = (event, platform) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [platform]: event.currentTarget,
    }));
  };

  const handlePopoverClose = (platform) => {
    console.log(`Closing popover for platform: ${platform}`);

    setAnchorEls((prevState) => ({
      ...prevState,
      [platform]: null,
    }));
  };

  const accounts = useSelector((state) => state.connect.linkedAccounts);
  const dispatch = useDispatch();

  console.log(accounts, "2222344232ed");

  const handleDialog = () => {
    setOpen((prev) => !prev);
  };

  const handleAcc = () => {
    setAcc((prev) => !prev);
  };

  useEffect(() => {
    setLinkedAcc(accounts);
  }, [accounts]);

  useEffect(() => {
    // if (accounts.length === 0)

    getAccounts();
  }, []);

  const getAccounts = () => {
    dispatch(getLinkedAccounts1());
  };

  const handleEdit = (data) => {
    setEditData(data);
  };
  // const handlePopoverOpen = (event) => {
  //     setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //     setAnchorEl(null);
  // };
  return (
    <div className="border_img mt-10">
      <DialogBox
        open={open}
        handleDialog={() => { }}
        headerStyle={{ backgroundColor: "#EDDAFD" }}
        Title={
          <p className="font-bold">
            {acc
              ? "Link New Accounts"
              : editData
                ? "Edit Account"
                : "Link Accounts"}
          </p>
        }
        width={acc ? "sm" : "md"}
        style={{ paddingBottom: "0px" }}
        Content={
          acc ? (
            <AddAccount close={handleAcc} />
          ) : editData ? (
            <EditAccount
              data={editData}
              close={handleEdit}
              getAccounts={getAccounts}
            />
          ) : (
            <AccountsList
              linkedAcc={linkedAcc}
              getAccounts={getAccounts}
              handleDialog={handleDialog}
              handleAcc={handleAcc}
              handleEdit={handleEdit}
            />
          )
        }
      />

      <Layout
        HeadIcon={<img src={LinkAccountImg} alt="Accounts" />}
        Title="Link Accounts"
        handleAdd={() => {
          handleDialog();
          handleAcc();
        }}
        handleView={handleDialog}
      />
      {console.log(linkedAcc, "linkedAcc111111")}
      {/* {linkedAcc?.length > 0 ?
                <>
                    <div className='pl-3'>
                        <h3 className='fw__normal' style={{ color: "#000000", fontSize: "16px" }}>Social Profile</h3>
                    </div>
                    <div className='mt-10 pl-3 d-flex e-align sidebar__avatar'>
                        {linkedAcc?.map((acc, i) => (
                            <React.Fragment key={i}>
                                {console.log(acc.platform, "7878rfghjm")}
                                <Typography
                                    aria-owns={anchorEls[acc?.platform] ? acc?.platform : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) => handlePopoverOpen(event, acc?.platform)}

                                >
                                    {comp[acc.platform]}
                                </Typography>


                            </React.Fragment>
                        ))}
                    </div>
                </> : <p className='pl-3 sidebar__pb'>No Linked Accounts</p>} */}

      {/* <Popover
                                    id={acc.platform}
                                    sx={{ pointerEvents: 'none' }}
                                    open={Boolean(anchorEls[acc?.platform])}
                                    anchorEl={anchorEls[acc?.platform]}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={() => handlePopoverClose(acc?.platform)}
                                >
                                    {acc?.pages?.length > 0 && acc?.pages.map((e, j) => (
                                        <div key={j} style={{ textAlign: 'center', padding: '8px' }}>
                                            <a href={`${acc.link}${e.pageid}`} target="_blank" rel="noopener noreferrer">
                                                <div style={{ display: 'flex' }}>
                                                    <div>{comp[acc?.platform]}</div>
                                                    <div>{e?.page}</div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </Popover> */}
    </div>
  );
};

export default LinkAccount;

// const LinkAccount = () => {

//     const [open, setOpen] = useState(false)
//     const [acc, setAcc] = useState(false)
//     const [linkedAcc, setLinkedAcc] = useState([])
//     const [editData, setEditData] = useState(null)

//     const accounts = useSelector(state => state.connect.linkedAccounts)
//     const dispatch = useDispatch()

//     console.log(accounts, "2222344232ed")

//     const handleDialog = () => {
//         setOpen(prev => !prev)
//     }

//     const handleAcc = () => {
//         setAcc(prev => !prev)
//     }

//     useEffect(() => {
//         setLinkedAcc(accounts)
//     }, [accounts])

//     useEffect(() => {
//         if (accounts.length === 0)
//             getAccounts()
//     }, [])

//     const getAccounts = () => {
//         dispatch(getLinkedAccounts())
//     }

//     const handleEdit = (data) => {
//         setEditData(data)
//     }

//     return (
//         <div className="border_img mt-10">
//             <DialogBox open={open} handleDialog={() => { }} headerStyle={{ backgroundColor: '#EDDAFD' }} Title={<p className="font-bold">{acc ? 'Link New Accounts' : editData ? 'Edit Account' : 'Link Accounts'}</p>} width={acc ? "sm" : "md"}
//                 style={{ paddingBottom: '0px' }}
//                 Content={acc ? <AddAccount close={handleAcc} /> :
//                     editData ? <EditAccount data={editData} close={handleEdit} getAccounts={getAccounts} /> :
//                         <AccountsList
//                             linkedAcc={linkedAcc}
//                             getAccounts={getAccounts}
//                             handleDialog={handleDialog}
//                             handleAcc={handleAcc}
//                             handleEdit={handleEdit}
//                         />}
//             />
//             <Layout HeadIcon={<img src={LinkAccountImg} alt="Accounts" />}
//                 Title="Link Accounts" handleAdd={() => {
//                     handleDialog();
//                     handleAcc()
//                 }} handleView={handleDialog}
//             />
//             {linkedAcc.length > 0 ?
//                 <>
//                     <div className='pl-3'>
//                         <h3 className='fw__normal' style={{ color: "#000000", fontSize: "16px" }}>Social Profile</h3>
//                     </div>
//                     <div className='mt-10 pl-3 d-flex e-align sidebar__avatar'>
//                         {linkedAcc.map((acc, i) => <a href={acc.socialAccountLink} key={i} target="_blank">{comp[acc.providerName]}</a>)}
//                     </div>
//                 </> : <p className='pl-3 sidebar__pb'>No Linked Accounts</p>}
//         </div>
//     )
// }

// export default LinkAccount

// organization page link code..................................

const AccountsListOrg = ({
  linkedAcc,
  getAccounts,
  displayLinkedIcons,
  orgData,
  ...props
}) => {
  let header = [
    "Provider",
    "Profile/Page",
    "Purpose",
    "Visibility",
    "Status",
    "",
    "",
  ];
  const [open, setOpen] = useState(0);

  useEffect(() => {
    getAccounts();
  }, []);
  // const [edit, setEdit] = useState(0)
  // const deleteAcc = () => {
  //     console.log(linkedAcc, "linkedAcc", UserService.getToken())

  //     axios.delete(process.env.REACT_APP_API_URL_PYI + `/deleteLinkedAccounts`, data, {
  //         headers: {
  //             "Authorization": `bearer ${UserService.getToken()}`
  //         }
  //     })
  //         .then(res => {
  //             getAccounts()
  //             handleDialog()
  //         })
  //         .catch(() => { })
  // }

  console.log(open, "linkedAcc", linkedAcc, displayLinkedIcons);
  const deleteAcc = () => {
    const token = UserService.getToken();
    const data = {
      platform: open,
    };

    console.log("Data:", data, "Token:", token); // Log to ensure data and token are retrieved correctly

    if (token) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL_PYI}/deleteLinkedAccountsDocstethPage/${orgData?.id}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
            data: data, // Include data in the request body
          }
        )
        .then((res) => {
          console.log("Delete successful:", res.data); // Optionally log the response
          getAccounts(); // Assuming getAccounts is defined elsewhere
          handleDialog();
        })
        .catch((error) => {
          console.error("Delete failed:", error); // Log any errors
        });
    } else {
      console.error("Token not available");
    }
  };

  // const deleteAcc = () => {
  //     axios.delete(URL.deleteAccount + open, headers())
  //         .then(res => {
  //             getAccounts()
  //             handleDialog()
  //         })
  //         .catch(() => { })
  // }

  const handleDialog = () => {
    setOpen(0);
  };

  return (
    <>
      <DialogBox
        open={open !== 0}
        handleDialog={handleDialog}
        title="Delete Account"
        Content={<p>Do you want to delete account</p>}
        Actions={
          <>
            <CloseBtn name="No" onClick={handleDialog} />
            <CreateBtn onClick={deleteAcc} name="Yes" />
          </>
        }
      />
      <Table className="link_border">
        <TableHead>
          {header.map((key, i) => (
            <React.Fragment key={i}>
              <CellData name={key} />
            </React.Fragment>
          ))}
        </TableHead>
        <TableBody>
          {console.log(linkedAcc, "displayLinkedIcons1", displayLinkedIcons)}
          {displayLinkedIcons.length > 0 ? (
            displayLinkedIcons.map((acc) => (
              <TableRow key={acc.id}>
                <CellData
                  name={
                    <div className="d-flex e-align capitalize">
                      {comp[acc.platform]} <p>{acc.platform}</p>
                    </div>
                  }
                />
                <CellData
                  name={
                    <a
                      className="d-flex e-align capitalize"
                      href={acc.socialAccountLink}
                      target="_blank"
                    >
                      <Avatar src={acc.profile} />
                      &nbsp;{acc.name}
                    </a>
                  }
                />
                <CellData name={acc.purpose} />
                <CellData
                  name={
                    <div className="d-flex e-align capitalize">
                      {comp[acc.visibility]}&nbsp;<p>{acc.visibility}</p>
                    </div>
                  }
                />
                <CellData name={acc.status} />
                <TableCell>
                  <FiEdit3
                    style={{ fontSize: "20px" }}
                    onClick={() => props.handleEdit(acc)}
                  />
                </TableCell>
                {/* <TableCell className="cursor"><BackspaceOutlinedIcon onClick={() => setOpen(acc?.platform)} /></TableCell> */}
                <TableCell className="cursor">
                  <DeleteIcon
                    sx={{ color: "red" }}
                    onClick={() => setOpen(acc?.platform)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={12} align="center">
              No Linked Accounts
            </TableCell>
          )}
        </TableBody>
      </Table>

      <DialogActions>
        <CloseBtn name="Close" onClick={props.handleDialog} />
        <CreateBtn onClick={props.handleAcc} name="Add New" />
      </DialogActions>
    </>
  );
};

const AddAccountOrg = ({
  close,
  orgData,
  googleDetails,
  displayLinkedIcons,
}) => {
  let formData = {
    purpose: "",
    accProvider: "",
    providerName: "",
    profile: "",
    AccountNumber: "",
    Location: null,
    googleUser: null,
  };
  const [state, setState] = useState(formData);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const accounts = useSelector((state) => state.connect.linkedAccounts);
  console.log(location, "paramsssss", params, accounts);

  const handleChange = (name, value) => {
    console.log(name, value);
    if (name === "purpose") setState({ ...formData, [name]: value });
    else setState({ ...state, [name]: value });
  };
  const handlePurposeChange = (event) => {
    const selectedPurpose = event.target.name;
    setState((prevState) => ({ ...prevState, purpose: selectedPurpose }));
  };

  const linkAccount1 = () => {
    let data = {
      purpose: state.purpose,
      providerName: state.providerName,
    };
    console.log(data, "888888");
    axios
      .get(`https://dev.api.docsteth.com/feed/FacebookOauth`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));

    // if (state.providerName === 'linkedin' || state.providerName === 'twitter') {
    //     axios.post(URL.linkAccount, data, headers())
    //         .then(res => {
    //             window.location.href = redirect_uri('user', params.name)[state.providerName]
    //         }).catch(err => console.log(err))
    // }
  };

  const linkAccount = () => {
    if (state.purpose === "Facebook") {
      const data = {
        docstethPageId: orgData?.id,
      };
      console.log(data, "sssssssffff", state);
      axios
        .post(
          `https://dev.api.docsteth.com/feed/linkFacebookDocstethPages/${state.providerName?.id}`,
          data,
          {
            headers: {
              Authorization: `bearer ${UserService.getToken()}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          close();
        })
        .catch((err) => console.log(err));
    } else if (state.purpose === "Google") {
      console.log("hhhhhhhhhhhhhhhhh", state);
      let dataGoogle = {
        docstethPageId: orgData?.id,
        accountId: state?.googleUser?.name?.split("/")[1],
        accountName: state?.googleUser?.accountName,
        locationName: state?.Location?.title,
        profileId: state?.AccountNumber,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL_PYI + `/linkGoogleDocstethPage`,
          dataGoogle,
          {
            headers: {
              Authorization: `bearer ${UserService.getToken()}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          close();
        });
    }
  };

  const getOptions = () => {
    if (state.purpose && state.purpose === "Facebook") {
      return accounts.flatMap((account) => account.pages);
    } else {
      return []; // Return an empty array or other options as needed
    }
  };
  return (
    <Box>
      <ValidatorForm onSubmit={linkAccount}>
        <>
          <Inputs
            placeholder="Account Purpose"
            handleChange={(e, value) => handleChange("purpose", value)}
            // options={['Google Business Account', 'Service Profile']}
            options={providerOptions.filter(
              (op) =>
                !displayLinkedIcons?.some(
                  (acc) => acc.platform === op?.toLowerCase()
                )
            )}
            // options={providerOptions}
            value={state.purpose}
            validators={["required"]}
            errorMessages={["Please select account purpose"]}
          />

          {/* <div className="d-flex">
                        <FormControlLabel control={<Checkbox
                            checked={state.purpose === 'Service Profile'}
                            name="Service Profile"
                            onChange={handlePurposeChange} />} label="Service Profile" />
                        <FormControlLabel control={<Checkbox
                            checked={state.purpose === 'Google Business Account'}
                            name="Google Business Account"
                            onChange={handlePurposeChange} />} label="Google Business Account" />
                    </div> */}
          {state.purpose === "Facebook" && (
            <Inputs
              placeholder="Select Account Provider"
              handleChange={(e, value) => handleChange("providerName", value)}
              options={getOptions()}
              // options={accounts?.[0]?.pages}
              // options={state.purpose && state.purpose === 'Service Profile' ? accounts : providerOptions.filter(op => !accounts.some(acc => acc.platform === op))}
              value={state.providerName}
              validators={["required"]}
              errorMessages={["Please select account provider"]}
            />
          )}

          {state.purpose === "Google" && (
            <Autocomplete
              name="googleUser"
              value={state.googleUser}
              onChange={(e, value) => handleChange("googleUser", value)}
              // onChange={handleChange}
              id="controllable-states-demo"
              getOptionLabel={(option) => option.accountName || option}
              options={googleDetails?.accounts}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    borderRadius: "7px",
                    ".MuiOutlinedInput-root": { padding: "8px" },
                    boxShadow: 1,
                    mt: "10px",
                  }}
                  {...params}
                  placeholder="select business account holder"
                />
              )}
            />
          )}
          {console.log(state, "state11")}
          {state.purpose === "Google" && state?.googleUser?.accountName && (
            <>
              <Autocomplete
                name="Location"
                value={state.Location}
                onChange={(e, value) => handleChange("Location", value)}
                // onChange={handleChange}
                id="controllable-states-demo"
                getOptionLabel={(option) => option.title || option}
                options={state?.googleUser?.locations || []}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      borderRadius: "7px",
                      ".MuiOutlinedInput-root": { padding: "8px" },
                      boxShadow: 1,
                      mt: "10px",
                    }}
                    {...params}
                    placeholder="Select Location"
                  />
                )}
              />

              <TextField
                name="AccountNumber"
                type="number"
                placeholder="Enter Your Business Profile ID"
                value={state.AccountNumber}
                onChange={(e, value) =>
                  handleChange("AccountNumber", e.target.value)
                }
                sx={{
                  borderRadius: "7px",
                  // ".MuiOutlinedInput-root": { padding: '4px' }, boxShadow: 1,
                  mt: "10px",
                  width: "100%",
                  ".MuiOutlinedInput-input": { padding: "15px 14px" },
                }}
              />
            </>
          )}

          {state.providerName &&
            (state.providerName !== "Others" ? (
              <div className="d-flex">
                {/* <FormControlLabel control={<Checkbox />} label="Social Share" />
                            <FormControlLabel control={<Checkbox />} label="Business Profile" /> */}
              </div>
            ) : (
              <>
                <Dropdown
                  margin="dense"
                  placeholder="Account Provider / Application Name"
                  variant="outlined"
                  value={state.accProvider}
                  fullWidth
                  onChange={(e) => handleChange("accProvider", e.target.value)}
                  validators={["required"]}
                  errorMessages={[
                    "Please enter Account Provider / Application Name",
                  ]}
                />
                <Dropdown
                  margin="dense"
                  placeholder="Your Profile / Page URL"
                  variant="outlined"
                  value={state.profile}
                  fullWidth
                  onChange={(e) => handleChange("profile", e.target.value)}
                  validators={["required"]}
                  errorMessages={["Please enter Your Profile / Page URL"]}
                />
              </>
            ))}
        </>

        <DialogActions>
          <CloseBtn name="Close" onClick={close} />
          <CreateBtn type="submit" name={"Link"} />
        </DialogActions>
      </ValidatorForm>
    </Box>
  );
};
// export const LinkAccountOrg = () => {
//   const orgData = useSelector((state) => state.registration.userOrgData);
//   console.log(orgData, "orgData22222");
//   const [open, setOpen] = useState(false);
//   const [acc, setAcc] = useState(false);
//   const [linkedAcc, setLinkedAcc] = useState([]);
//   const [editData, setEditData] = useState(null);
//   const [anchorEl, setAnchorEl] = useState({});
//   const [displayLinkedIcons, setDisplayLInkedIcons] = useState([]);
//   const [googleDetails, setGoogleDetails] = useState([]);

//   const openPop = Boolean(anchorEl);

//   const [anchorEls, setAnchorEls] = useState({});

//   useEffect(() => {
//     // getListLinked()
//     // console.log("vinay0001")
//     if (orgData?.id) {

//       axios
//         .get(
//           process.env.REACT_APP_API_URL_PYI +
//             `/getLinkedAccountsDocstethPage/${orgData?.id}`,
//           {
//             headers: {
//               Authorization: `bearer ${UserService.getToken()}`,
//             },
//           }
//         )
//         .then((res) => {
//           console.log(res, "90u90saiii");
//           setDisplayLInkedIcons(res?.data || []);
//         })
//         .catch((err) => console.log(err));
//     }
//   }, [orgData]);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_API_URL_PYI + `/getGoogleAccounts`, {
//         headers: {
//           Authorization: `bearer ${UserService.getToken()}`,
//         },
//       })
//       .then((res) => {
//         console.log(res);
//         setGoogleDetails(res.data || []);
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const getListLinked = () => {};
//   console.log(displayLinkedIcons, "displayLinkedIcons",orgData?.id,acc);

//   const handlePopoverOpen = (event, platform) => {
//     setAnchorEls((prevState) => ({
//       ...prevState,
//       [platform]: event.currentTarget,
//     }));
//   };

//   const handlePopoverClose = (platform) => {
//     console.log(`Closing popover for platform: ${platform}`);

//     setAnchorEls((prevState) => ({
//       ...prevState,
//       [platform]: null,
//     }));
//   };

//   const accounts = useSelector((state) => state.connect.linkedAccounts);
//   const dispatch = useDispatch();

//   console.log(accounts, "2222344232ed");

//   const handleDialog = () => {
//     setOpen((prev) => !prev);
//   };

//   const handleAcc = () => {
//     setAcc((prev) => !prev);
//   };

//   useEffect(() => {
//     setLinkedAcc(accounts);
//   }, [accounts]);

//   useEffect(() => {
//     // if (accounts.length === 0)
//     getAccounts();
//   }, []);

//   const getAccounts = () => {
//     dispatch(getLinkedAccounts1());
//   };

//   const handleEdit = (data) => {
//     setEditData(data);
//   };

//   return (
//     <div className="border_img mt-10">
//       <DialogBox
//         open={open}
//         handleDialog={() => {}}
//         headerStyle={{ backgroundColor: "#EDDAFD" }}
//         Title={
//           <p className="font-bold">
//             {acc
//               ? "Link New Accounts"
//               : editData
//               ? "Edit Account"
//               : "Link Accounts"}
//           </p>
//         }
//         width={acc ? "sm" : "md"}
//         style={{ paddingBottom: "0px" }}
//         Content={
//           acc ? (
//             <AddAccountOrg
//               close={handleAcc}
//               orgData={orgData}
//               googleDetails={googleDetails}
//               displayLinkedIcons={displayLinkedIcons}
//             />
//           ) : editData ? (
//             <EditAccount
//               data={editData}
//               close={handleEdit}
//               getAccounts={getAccounts}
//             />
//           ) : (
//             <AccountsListOrg
//               linkedAcc={linkedAcc}
//               getAccounts={getAccounts}
//               handleDialog={handleDialog}
//               handleAcc={handleAcc}
//               handleEdit={handleEdit}
//               displayLinkedIcons={displayLinkedIcons}
//               orgData={orgData}
//             />
//           )
//         }
//       />
//       <Layout
//         HeadIcon={<img src={LinkAccountImg} alt="Accounts" />}
//         Title="Link Accounts"
//         handleAdd={() => {
//           handleDialog();
//           handleAcc();
//         }}
//         handleView={handleDialog}
//       />
//       {console.log(linkedAcc, "linkedAcc111111")}
//       {linkedAcc?.length > 0 ? (
//         <>
//           <div className="pl-3">
//             <h3
//               className="fw__normal"
//               style={{ color: "#000000", fontSize: "16px" }}
//             >
//               Social Profiles
//             </h3>
//           </div>
//           <div className="mt-10 pl-3 d-flex e-align sidebar__avatar">
//             {displayLinkedIcons?.map((acc, i) => (
//               <React.Fragment key={i}>
//                 <Typography
//                   aria-owns={
//                     anchorEls[acc?.platform] ? acc?.platform : undefined
//                   }
//                   aria-haspopup="true"
//                   // onClick={(event) => handlePopoverOpen(event, acc?.platform)}
//                   // onMouseLeave={() => handlePopoverClose(acc.platform)}
//                 >
//                   {comp[acc.platform]}
//                 </Typography>

//                 {/* <Popover
//                                     id={acc.platform}
//                                     sx={{ pointerEvents: 'none' }}
//                                     open={Boolean(anchorEls[acc?.platform])}
//                                     anchorEl={anchorEls[acc?.platform]}
//                                     anchorOrigin={{
//                                         vertical: 'bottom',
//                                         horizontal: 'left',
//                                     }}
//                                     transformOrigin={{
//                                         vertical: 'top',
//                                         horizontal: 'left',
//                                     }}
//                                     onClose={() => handlePopoverClose(acc?.platform)}
//                                 >
//                                     {acc?.pages?.length > 0 && acc?.pages.map((e, j) => (
//                                         <div key={j} style={{ textAlign: 'center', padding: '8px' }}>
//                                             <a href={`${acc.link}${e.pageid}`} target="_blank" rel="noopener noreferrer">
//                                                 <div style={{ display: 'flex' }}>
//                                                     <div>{comp[acc?.platform]}</div>
//                                                     <div>{e?.page}</div>
//                                                 </div>
//                                             </a>
//                                         </div>
//                                     ))}
//                                 </Popover> */}
//               </React.Fragment>
//             ))}
//           </div>
//         </>
//       ) : (
//         <p className="pl-3 sidebar__pb">No Linked Accounts</p>
//       )}
//     </div>
//   );
// };
