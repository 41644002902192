import React, { useEffect, useState } from 'react'
import './article.css'
import Container from '@mui/material/Container';
import { convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import URL from '../apiurl'
import { Snackbar } from '@mui/material';
import { headers } from '../../redux/Actions/connectActions';
import { CssText, Accord } from './addArticle';


export default function Article() {
    // const html = ``
    // const blocksFromHTML = convertFromHTML(html)
    // const content = ContentState.createFromBlockArray(blocksFromHTML)
    // const editorContent = EditorState.createWithContent(content)

    // const [editorState, setEditorState] = useState(editorContent)

    const [classes, setClasses] = useState([])
    const [classListName, setClassListName] = useState(null)
    const [specialities, setSpecialities] = useState([])
    const [speciality, setSpeciality] = useState(null)
    const [drugs, setDrugs] = useState([])
    const [drugName, setDrugName] = useState(null)
    const [expanded, setExpanded] = React.useState(false);
    const [subexpanded, setSubExpanded] = React.useState(false);
    const list = [
        { name: 'Medication_Id', panel: 'panel-1' },
        { name: 'Dosing_and_Using', panel: 'panel-4' },
        { name: 'Medication_Safety', panel: 'panel-5' },
        // { name: 'Adverse_Effects', panel: 'panel-6' },
        { name: 'Mechanisms', panel: 'panel-7' },
        { name: 'Kinetics', panel: 'panel-8' },
        { name: 'Followup', panel: 'panel-9' },
        { name: 'Clinical_Guide', panel: 'panel-10' },
        { name: 'References', panel: 'panel-2' },
        { name: 'Supply_Chain', panel: 'panel-3' },
        { name: 'Black_Box_Warning', panel: 'panel-6' }
    ]
    const [sub, setSub] = useState({ subtitle: '', child_subtitle: '', body: null })
    const [add, setAdd] = useState(false)
    const [article, setArticle] = useState({
        Medication_Id: [],
        Dosing_and_Using: [],
        Medication_Safety: [],
        Mechanisms: [],
        Kinetics: [],
        Followup: [],
        Clinical_Guide: [],
        References: [],
        Supply_Chain: [],
        Black_Box_Warning: []
    })

    const [subtitle, setSubtitle] = useState('Medication_Id')
    const [alert, setAlert] = useState({ open: false, msg: '' })

    const errorMessages = (err) => {
        if (err?.response?.status === 500) {
            setAlert({ open: true, msg: `${err?.response?.statusText}. Try reloading the page` })
        }
        else if (err?.response?.status === 502) {
            setAlert({ open: true, msg: `${err?.response?.statusText}. Try reloading the page` })
        }
        else if (err?.response?.status === 401) {
            setAlert({ open: true, msg: `Unauthorized.Please login with valid credentials` })
        }
        else {
            setAlert({ open: true, msg: `Please login with valid credentials` })
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setSub({ subtitle: '', child_subtitle: '', body: null })
        setSubtitle(panel)
        setAdd(false)
    };
    const handleSub = (panel) => (event, isExpanded) => {
        setSubExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        axios.get(`${URL.dSpecialities}`).then(res => {
            // console.log(res)
            setSpecialities(res.data.data)
        }).catch(err => errorMessages(err))

    }, [])

    useEffect(() => {
        setClassListName(null)
        // setDrugName(null)
        // setClasses(null)
        if (speciality !== null) {
            axios.get(`${URL.getClasses}${speciality?.id}`).then(res => {
                setClasses(res.data?.data?.[0]?.class_wise_drugs?.data)
                // setDrugs(drugList)
            }).catch(err => errorMessages(err))
        }
    }, [speciality])

    useEffect(() => {
        // setClassListName(null)
        setDrugName(null)
        if (classListName !== null) {

            var d;
            classes?.map(name => name?.name === classListName?.name ? d = name?.drugs : null)
            setDrugs(d)
        }
    }, [classListName])



    useEffect(() => {
        if (drugName !== null) {
            getArticle()
        }
    }, [drugName, subtitle])

    const getArticle = async () => {
        axios.get(`${URL.getArticles}${drugName?.id}-${subtitle}`, headers()).then(res => {
            // console.log(res)
            setArticle({ ...article, [subtitle]: res.data?.data })
        }).catch(err => {
            errorMessages(err)
            setArticle({ ...article, [subtitle]: [] })
        })
    }

    // console.log(article)

    const getFileBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        // Since FileReader is asynchronous,
        // we need to pass data back.
        reader.onload = () => callback(reader.result);
        // TODO: catch an error
        reader.onerror = error => { };
    };

    const uploadImageCallBack = file => new Promise(
        (resolve, reject) => getFileBase64(
            file,
            data => resolve({ data: { link: data } })
        )
    );

    const handleSubtitle = async (e, name) => {
        e.preventDefault()
        let s = [...article[name]]
        if (drugName !== null) {
            let t = {
                drug_id: drugName?.id,
                drug_name: drugName?.name,
                subtitle: name,
                child_subtitle: sub?.child_subtitle,
                body: `<p></p>`,
                medication_id: ''
            }
            s.push(sub)
            // setArticle({ ...article, [name]: s })
            axios.post(`${URL.getArticles}post`, t, headers())
                .then(async res => {
                    await getArticle()
                    setSubExpanded(false)
                }).catch(err => errorMessages(err))

            setAdd(false)
            setSub({ child_subtitle: '' })
        }
        else {
            alert('Select Drug')
        }
    }

    const handleText = (editorState, i, name) => {
        let text = [...article[name]]
        text[i]['body'] = editorState
        // console.log(text, text[i]['body'])
        // console.log(convertToRaw(editorState.getCurrentContent()))
        setArticle({ ...article, [name]: text })
    }

    const submitArticle = (data, type) => {
        // console.log(draftToHtml(convertToRaw(data.text.getCurrentContent())))
        if (drugName !== null) {
            var tempDivElement = document.createElement("div");
            // console.log(tempDivElement)
            tempDivElement.innerHTML = typeof (data?.body) === "string" ? data?.body : draftToHtml(convertToRaw(data?.body?.getCurrentContent()));

            let article = {
                id: data?.id,
                drug_id: drugName?.id,
                subtitle: type,
                child_subtitle: data?.child_subtitle,
                body: typeof (data?.body) === "string" ? data?.body : `${draftToHtml(convertToRaw(data?.body.getCurrentContent()))}`,
                // disease: disease.id,
                medication_id: type === 'Medication_Id' ? tempDivElement?.textContent || tempDivElement?.innerText || "" : ''
            }
            axios.put(`${URL.getArticles}update`, article, headers())
                .then(res => {
                    setSubtitle(type)
                    setSubExpanded(false)
                }).catch(err => errorMessages(err))
        }
        else {
            alert("Select Disease")
        }
    }

    const handleAdd = (name, value) => {

        setSub({ ...sub, subtitle: name, child_subtitle: value, body: '' })

    }

    const handleEdit = (name, value, i) => {

        let text = [...article[name]]
        text[i]['child_subtitle'] = value
        setArticle({ ...article, [name]: text })

    }

    const deleteArticle = (id) => {

        axios.delete(`${URL.getArticles}${id}`, headers())
            .then(async res => {
                // console.log(res)
                getArticle()
            }).catch(err => errorMessages(err))
    }
    // console.log(expanded,article)
    return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
            <Container>
                <br />
                <h2 className="textCenter">Drug Information</h2>
                <br />
                <div style={{ display: 'flex' }}><Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={specialities}
                    getOptionLabel={(option) => option?.speciality}
                    onChange={(event, newValue) => {
                        setSpeciality(newValue)
                    }}
                    value={speciality}
                    sx={{ mb: 3, width: '30%', mx: 'auto' }}
                    renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Specialities" value={speciality}
                    />}
                />

                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={classes}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                            setClassListName(newValue)
                        }}
                        value={classListName}
                        sx={{ mb: 3, width: '30%', mx: 'auto' }}
                        renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Classes" value={classListName}
                        />}
                    />

                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={drugs}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                            setDrugName(newValue)
                        }}
                        value={drugName}
                        sx={{ mb: 3, width: '30%', mx: 'auto' }}
                        renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Drugs" value={drugName}
                        />}
                    /></div>

                {drugName !== null ?
                    list?.map((l, i) => (

                        <Accord key={i} array={article[l?.name]} subexpanded={subexpanded} handleSub={handleSub}
                            uploadImageCallBack={uploadImageCallBack} handleText={handleText}
                            submitArticle={submitArticle} addfun={() => setAdd(true)} name={l?.name} panel={l?.panel} handleEdit={handleEdit}
                            deleteArticle={deleteArticle}
                            expanded={expanded} sub={sub} handleAdd={handleAdd} add={add} handleSubtitle={handleSubtitle} handleChange={handleChange}
                        />

                    )) : ''}

            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2500}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />
        </div>
    )
}