// import { TextField } from "@mui/material";
import React from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CustomValidator } from "./customInput";

const filterData = createFilterOptions();

export default function CustomSelect({ filter, Icon, handleChange, getOptionLabel, errorMessages, validators, required, ...props }) {
    let IComp = Icon ? Icon : '';

    return (
        <Autocomplete
            {...props}
            //props required - options,value,onChange,placeholder
            //for multiselect pass multiple prop
            id="tags-outlined"
            filterOptions={(options, params) => {
                const filtered = filterData(options, params);
                if (filter && params.inputValue?.trim() !== '') {
                    filtered.push(
                        `${params.inputValue}`
                    );
                }

                return filtered;
            }}
            value={props.value}
            onChange={(e, value) => handleChange(props.name, value)}
            className="input_shadow select_padding"
            // disablePortal
            getOptionLabel={getOptionLabel || ((option) => option.value || option || '')}
            renderOption={({ key, ...props }, i) => <li key={key} {...props} style={{ textTransform: 'capitalize' }}>{key}</li>}
            isOptionEqualToValue={(option, value) => option === value || ''}
            fullWidth
            renderInput={(params) => <CustomValidator {...params}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: IComp || params.InputProps.endAdornment
                }}
                sx={{
                    '& input': { textTransform: 'capitalize' },
                }} placeholder={props.placeholder} value={props.value} errorMessages={errorMessages} validators={required ? ['required'] : validators} />} />
    )
}