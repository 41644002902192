import React, { useState, useEffect } from "react";
import { Box, Card, CardMedia, Grid, Paper, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from "react-redux";
import { D_API } from "../../../../../../services/APIService";
import { URI } from "../../../../../utility/uri";
import Stethoscope from "../../../../../../images/stethoscope1.svg";
import Location from "../../../../../../images/location.svg";
import DoctorImg from "../../../../../../images/docsteth.png";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LaunchIcon from '@mui/icons-material/Launch';
import Referal from "../../../../../../images/referral-3.svg";
import Communicate from "../../../../../../images/Communication-language.svg"
import MaleDoctorImg from "../../../../../../images/MaleDoctor-no-photo.svg";
import FemaleDoctorImg from "../../../../../../images/FemaleDoctor-No Photo.svg";
import TeleIcon from "../../../../../../../src/images/Telemedicine.svg";
import DefaultDoctorImg from "../../../../../../icons/Userprofilelogo.svg";
import CloseIcon from '@mui/icons-material/Close';
import { FormDivider } from "../../../../../utility/utils";
import { FormLayout } from "../../../../../common/components/layout";
import SearchIcon from '@mui/icons-material/Search';
import DoctorsInfoCard from "../../../../../common/components/doctorsInfoCard";
import { forEach } from "lodash";


const PractitionerTeam = () => {

    const [practitionerInfoByhealthId, setPractitionerInfoByHealthId] = useState([]);
    const [practitionerListByOrgId, setPractitionerListByOrgId] = useState([]);
    const [alreadyExistPractitioners, setAlreadyExistPractitioners] = useState([]);
    const [inputValue, setInputValue] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectParticularPractitionerInfo, setSelectParticularPractitionerInfo] = useState(null);


    const userOrg = useSelector(state => state.registration.userOrg);

    console.log(userOrg, "8989")

    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    console.log(healthServiceOrg, "5")



    useEffect(() => {
        if (healthServiceOrg?.id) {
            getPractitionerByHealthServId(healthServiceOrg?.id)
        }

    }, [])


    const getPractitionerByHealthServId = (id) => {
        setIsLoading(true)
        D_API.get(`${userOrg?.id}/${URI.getPractitionersByHealthCareServiceId}${id}`).then((res) => {
            // console.log(res.data, "p1")
            if (res.data) {
                setPractitionerInfoByHealthId(res.data);
                setIsLoading(false)

            }

        }).catch((err) => {
            console.log(err)
            setIsLoading(false);
        })

    }

    // console.log(practitionerInfoByhealthId, "practitionerInfo")
    // console.log(practitionerListByOrgId, "org")

    useEffect(() => {
        if (userOrg?.id) {
            getPractitionerInfoOrgId(userOrg?.id)
        }

    }, [userOrg?.id])


    const getPractitionerInfoOrgId = (id) => {
        D_API.get(`${userOrg?.id}/${URI.getPractitionersByOrganizationId}${id}`).then((res) => {
            setPractitionerListByOrgId(res.data);
        }).catch((err) => console.log(err))
    }


    const filterExistingPractitoners = () => {
        let list = [];
        let PractitionershealthserviceIds = practitionerInfoByhealthId?.map((e) => e?.practitioner?.resource?.id);
        if (practitionerListByOrgId?.length > 0) {
            list = practitionerListByOrgId?.map((val) => {
                if (PractitionershealthserviceIds?.includes(val?.practitioner?.resource?.id)) {
                    return val;
                }
            }).filter((e) => e != undefined);
        }
        setAlreadyExistPractitioners([...list])

        // console.log(list, "live")
        // setAlreadyExistPractitioners

    }


    useEffect(() => {
        filterExistingPractitoners();
    }, [practitionerListByOrgId])



    const handleOpnDialog = () => {
        // getPractitionerRoleByOrgId(userOrg?.id);
        setOpenDialog(true);
        getPractitionerInfoOrgId(userOrg.id)
        // setSelectParticularPractitionerInfo(null)
        filterExistingPractitoners();
    }
    const handleClsDialog = () => {
        setOpenDialog(false)
    }

    const handleOnSearchField = (name, value) => {

        let list = []
        if (value?.length > 0) {
            list = practitionerListByOrgId?.filter((e) => {
                if (e?.practitioner?.resource?.name?.[0]?.text?.toLowerCase()?.includes(value?.toLowerCase())) {
                    return e;
                }
            })?.filter((val) => val != undefined)
            // console.log(list, "ree")
            setPractitionerListByOrgId(list);
        } else {
            getPractitionerInfoOrgId(userOrg?.id);
        }
    }
    // console.log(inputValue, "input");
    // console.log(selectParticularPractitionerInfo, "input2")

    const addPractntoParticularHealthcareService = (data) => {
        // console.log(data, "datat")

        let info = {
            ...data.resource,
            ...(data?.resource?.healthcareService ? {
                healthcareService: [...data?.resource?.healthcareService, { reference: `HealthcareService/${healthServiceOrg?.id}` }]
            }
                : { healthcareService: [{ reference: `HealthcareService/${healthServiceOrg?.id}` }] })
        };
        // console.log(info, "info1")

        if (healthServiceOrg?.id) {
            D_API.put(`PractitionerRole/${data?.resource?.id}`, info).then((res) => {
                // console.log(res.data)
                // getPractitionerRoleByOrgId(userOrg?.id);
                // getPractitionerRoleByHealthServiceId(healthServiceOrg?.id);
                getPractitionerByHealthServId(healthServiceOrg?.id)
                handleClsDialog();
            }).catch((err) => console.log(err));
        }

    };



    const removePractntoParticularHealthcareService = (data) => {
        // console.log(data, "datat")

        let info = {
            ...data.resource,
            ...(data?.resource?.healthcareService && { healthcareService: data?.resource?.healthcareService.filter((e) => e.reference?.split("/")?.[1] != healthServiceOrg?.id) })

        };
        // console.log(info, "info1")

        if (healthServiceOrg?.id) {
            D_API.put(`PractitionerRole/${data?.resource?.id}`, info).then((res) => {
                // console.log(res.data)
                getPractitionerByHealthServId(healthServiceOrg?.id);
                getPractitionerInfoOrgId(userOrg?.id);
                // getPractitionerRoleByOrgId(userOrg?.id);
                // getPractitionerRoleByHealthServiceId(healthServiceOrg?.id);
                handleClsDialog();
            }).catch((err) => console.log(err));
        }

    };






    return (
        <div>

            <div className="mv1">
                <Card sx={{ padding: "10px", minHeight: "150px", }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div className="font-head fntSemiBold">
                                PractitionerTeam
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: "2px" }}>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" onClick={handleOpnDialog} />
                            </div>
                        </Grid>
                    </Grid>
                    {
                        isLoading && <div className="flx-center jc__center " style={{ height: "150px", }}> <CircularProgress />
                        </div>
                    }
                    {
                        practitionerInfoByhealthId?.length > 0 &&
                        <Grid container spacing={2}>
                            {
                                practitionerInfoByhealthId?.map((e, i) => {
                                    return (
                                        <Grid item key={i} xs={12} sm={6} md={4}>
                                            <DoctorsInfoCard practitioner={e.practitioner} practitionerRole={e.practitionerRole} type="non-people" />
                                        </Grid>

                                    )
                                })
                            }
                        </Grid>

                    }
                    {
                        (!isLoading && practitionerInfoByhealthId?.length <= 0) &&
                        <div className="flx-center justify-center fntMd  fntSemiBold " style={{ height: "150px", }} >
                            <div >
                                No Data is Available
                            </div>

                        </div>
                    }
                </Card>
            </div>


            <Dialog onClose={handleClsDialog} open={openDialog} maxWidth={"sm"} fullWidth style={{ height: "600px", position: 'absolute !important', top: "5px !important", }}>
                <Card style={{ display: 'flex', flexDirection: 'column' }} className="p1">
                    <DialogTitle sx={{ padding: '0px' }} className="space_btw_center">
                        <Box>
                            Add Practitioners
                        </Box>
                        <Box>
                            < CloseIcon className="" sx={{ color: "red", fontSize: "14px" }} onClick={handleClsDialog} />
                        </Box>

                    </DialogTitle>
                </Card>

                <DialogContent className="tohideScrollbar ph1" sx={{ height: "410px", overflow: "auto", padding: '0px' }}>

                    <div className="mv1 ph1 ">
                        <FormLayout
                            label="Search Practitoners"
                            name="selectParticularPractitionerInfo"
                            Icon={<SearchIcon />}
                            size={12}
                            value={selectParticularPractitionerInfo}
                            handleChange={handleOnSearchField}
                            placeholder="Search for required Speciality"
                            getOptionLabel={option => option?.practitioner?.resource?.name?.[0]?.text}
                            sx={{ '.MuiOutlinedInput-root': { paddingRight: '10px !important' } }}
                        />
                    </div>
                    {
                        practitionerListByOrgId?.length > 0 &&
                        <>
                            {

                                practitionerListByOrgId?.map(({ practitioner, practitionerRole }, indx) => (
                                    <div>
                                        <Grid container alignItems="center" sx={{ paddingTop: "5px" }} className="ph1 ">
                                            <Grid item xs={10} sm={10} md={10}>
                                                <Grid item container>
                                                    <Grid item xs={2} sm={2} md={2}>
                                                        <img src={(practitioner?.resource?.photo && (practitioner?.resource?.photo?.[0]?.url != "null" && practitioner?.resource?.photo?.[0]?.url != "undefined" && practitioner?.resource?.photo?.[0]?.url != "")) ? practitioner?.resource?.photo?.[0]?.url : DefaultDoctorImg} style={{
                                                            width: "50px", height: "50px"
                                                            // height: "45px", width: "50px", borderRadius: '20px', border: "4px solid #C6C6C6 ",
                                                            // objectFit: "cover"
                                                        }} />
                                                    </Grid>
                                                    <Grid item xs={10} sm={10} md={10}>
                                                        <div className="fntMd fntSemiBold">{practitioner?.resource?.name?.[0]?.text}</div>
                                                        <div className="fntXSm fntSemiBold" style={{ color: "#7F7F7F" }}>{practitionerRole?.resource?.code?.map((e) => e?.coding?.[0]?.display)}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2} sm={2} md={2}>
                                                {
                                                    alreadyExistPractitioners?.map((e) => e.practitioner?.resource?.id).includes(practitioner?.resource?.id) ?
                                                        <Button sx={{ padding: "4px 20px !important" }} name="" className="remove-btn capitalize" variant="contained" onClick={() => removePractntoParticularHealthcareService(practitionerRole)}>Remove</Button>
                                                        :
                                                        <Button sx={{ padding: "4px 20px !important" }} name="" className="add-btn capitalize" variant="contained" onClick={() => addPractntoParticularHealthcareService(practitionerRole)}> Add</Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>

                                ))

                            }
                        </>

                    }


                    {/* {
                        basedOnOrgPractitionerList.length <= 0 &&
                        <div className="flx-center justify-center fntMd  fntSemiBold " style={{ height: "410px", }} >
                            <div >
                                No Data is Available
                            </div>

                        </div>
                    } */}

                </DialogContent>
                {/* <FormDivider /> */}
                <hr></hr>
                <DialogActions>
                    <Button sx={{ padding: "4px 20px !important" }} name="save" className="save-btn" variant="contained" onClick={handleClsDialog}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
export default PractitionerTeam;

