import React, { useState } from 'react'
import SidebarLeft from './Sidebar/Sidebarleft';
import SidebarRight from './Sidebar/Sidebarright';
import Feed from "./Feed/Feed";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
// import ConversationsApp from '../ChatApp/ConversationsApp';
import { handleActiveValue } from '../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import { useHistory } from 'react-router';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

export default function Trending() {

  const dispatch = useDispatch()
  const history = useHistory()
  const ref = React.useRef()
  const userData = useSelector(state => state.userProfile.userProfile)
  const exp = useSelector(state => state.userProfile.user)
  // console.log(userData, "userData")

  // console.log(userData, "paractioner")

  const [key, setKey] = useState('none')

  React.useEffect(() => {
    dispatch(handleActiveValue(1))
  }, [])

  React.useEffect(() => {
    if (userData?.practioner) {
      if (userData?.practioner?.photo?.[0]?.url === null && (userData?.practioner?.qualification === null || userData?.practioner?.qualification?.length === 0) && (exp && exp?.experience?.length === 0)) {
        history.push({
          // pathname: `/completeprofile`
          pathname: `myprofile/${userData?.fullName ? userData?.fullName?.replace(/ /g, '').toLowerCase() : ''}-${userData?.id}`,
          // state: {
          //   opnEditProfile: true
          // }
        })
      }
    }
  }, [userData, exp])
  // console.log(userData.practioner)
  return (
    (userData?.practioner && (userData?.practioner?.photo?.[0]?.url !== null || (userData.practioner?.qualification !== null && userData.practioner?.qualification?.length > 0)) || (exp && exp?.experience?.length > 0)) ?
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Docsteth | Feed</title>
        </Helmet>
        <Box sx={{ flexGrow: 1, px: { md: 2, xs: 2 }, pb: { md: 0, xs: 10 }, position: 'relative' }}>
          <Grid ref={ref} sx={{
            position: "absolute", zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: key
          }}>
            <span className="elib-right__content w-100" style={{ height: '100vh', position: 'fixed', fontSize: '20px', color: 'white' }}>Loading...</span>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3} className="scroll" sx={{ zIndex: 0, display: { xs: 'none', md: 'block' }, position: { md: 'fixed' }, overflow: 'scroll', height: 'calc(100% - 70px)', width: { md: '315px' }, px: { lg: '8px!important' } }}>
              <SidebarLeft />
            </Grid>
            <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
            <Grid item md={6} sm={12} xs={12} sx={{ mt: '24px' }} className="dialog-content">
              <Feed />
            </Grid>

            <Grid item md={3} sm={4} sx={{
              display: { xs: 'none', sm: 'block' }, width: '100%',
              // ml: {lg: '82.5%',md: '74%',sm: '65%'},
              // position: 'fixed', overflow: 'scroll',height: {md: 'calc(100% - 70px)',sm: 'calc(100% - 20%)'}
            }}>
              <Box className="scroll" sx={{ zIndex: 0, display: { xs: 'none', md: 'block' }, position: { md: 'fixed' }, overflow: 'scroll', height: 'calc(100% - 70px)', width: { md: '300px' }, px: { lg: '8px!important' } }}>
                <SidebarRight />
              </Box>
              {/* <SidebarRight /> */}
            </Grid>
            {/* <Grid item md={3}/> */}
            {/* <Grid item md={9} sx={{ display: { xs: 'none', lg: 'block' } }}/> */}
            {/* <Grid item md={3} className="scroll" sx={{ zIndex: 0, display: { xs: 'none', md: 'block' }, position: { lg: 'fixed' }, overflow: 'scroll', height: 'calc(100% - 70px)', width: { lg: '300px' }, px: { lg: '8px!important' } }}>
              <SidebarRight />
            </Grid> */}
          </Grid>
        </Box>
        {/* <ConversationsApp /> */}
      </ThemeProvider> : <></>
  )
}