import React from 'react'
import './Sidebar.css';
import "../../utility/style.css";
import view from '../../../images/view.svg';
import Events from '../../../images/Eventscalendar.svg';
import upcoming from '../../Atom/modifiedicons/upcoming.svg'
import education_cap from '../../Atom/modifiedicons/education_cap.svg'
import job_role from '../../Atom/modifiedicons/job_role.svg'
import Organisations from '../../Atom/modifiedicons/Organisations.svg'
import hashtag from '../../Atom/modifiedicons/hashtag.svg'
import suggestion_icon from '../../Atom/modifiedicons/suggestion_icon.svg'
import team_icon from '../../Atom/modifiedicons/team_icon.svg'
import hashtag_icon from '../../Atom/modifiedicons/hashtag_icon.svg';
import axios from "axios";
import URL from '../../apiurl';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import UserService from '../../../services/UserService';
import { Button, Divider } from '@mui/material';
import { getHashtags } from '../../../redux/Actions/connectActions';
import { useHistory } from 'react-router-dom';
// import { Link } from 'react-router-dom';

export default function SidebarRight() {

    const [suggestedTopics, setSuggestedTopics] = React.useState([])
    const [trending, setTrending] = React.useState([])
    const [haashtag, setHaashtag] = React.useState([])
    const [count, setCount] = React.useState(3)
    const history = useHistory()
    const events = useSelector(state => state.userProfile.events)
    // console.log(events)
    const userData = useSelector(state => state.userProfile.userProfile)
    const suggestedHashtags = useSelector(state => state.connect.hashtags)

    const dispatch = useDispatch()

    const openWebinarfn = (event) => {
        window.open(`${process.env.REACT_APP_WEBINAR}/webinar/room/${event.title}/${event.id}`)
        // window.open(`${process.env.REACT_APP_API_WEBINER_URL}room/${event.title}/${event.id}/user/${userData.id}`)
    }
    const recentItem = (topic) => (
        <div className="sidebar__recentItem">
            <img src={hashtag_icon} style={{ fontSize: "16px" }} alt="hashtag" />
            <div className="sidebar__title sidebar__ml">{topic}</div>
        </div>
    )
    const groupItem = (group) => (
        <div className="sidebar__groupItem">
            <img src={team_icon} style={{ fontSize: "16px" }} alt="team" />
            <div className="sidebar__title sidebar__ml">{group}</div>
        </div>
    )
    const orgItem = (organisation) => (
        <div className="sidebar__recentItem">
            <img src={Organisations} style={{ fontSize: "16px" }} alt="orgainsations" />
            <div className="sidebar__title sidebar__ml">{organisation}</div>
        </div>
    )

    React.useEffect(() => {
        axios.get(`${URL.suggestedTopics}trendinghashtags`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => res.data).then(data => {
            // console.log(data)
            // seth(data)
            setTrending(data?.slice(0, 5))
            // setSuggestedTopics(data.slice(5, 10))
        }).catch(err => {

        })
    }, [])

    React.useEffect(() => {
        if (suggestedHashtags?.length === 0) {
            dispatch(getHashtags())
        }
    }, [])

    React.useEffect(() => {
        setSuggestedTopics(suggestedHashtags?.slice(0, 5))
    }, [suggestedHashtags])

    const showhashtag = (data) => {
        const tag = data;
        // console.log(tag)

        history.push({
            pathname: `/hashtagpost`,
            state: tag
        })
    }



    return (
        // <div className="responsivesidebar">
        <div className="sidebar__right home_border_side sidebar_width">
            {/* upcomming event*/}
            <div className="sidebar__recent border__bottom sidebar__padding" >
                <div className="sidebar__substat" style={{ marginBottom: '10px' }}>
                    <img src={upcoming} alt="upcoming" />
                    <div className="font-caption fw__semibold sidebar__ml sidebar__lh" style={{ fontSize: '15px' }}>Upcoming Events</div>
                </div>
                {events && events?.[0]?.calenderEvents?.length > 0 ? events?.[0]?.calenderEvents?.slice(0, count)?.map((event, i) => (
                    <div key={event.id}><div className="profile__top sidebar__pb pl-1" style={{ alignItems: 'end' }}>
                        <div className='overflow_hidden'>
                            <p className="capitalize font-bold name_hover cursor text-ellipsis" onClick={() => history.push(`/webinar/eventdata-${event.eventId}`)}>{event.title}</p>
                            <p className="font-title" style={{ paddingTop: '5px' }}>{moment.utc(event.openingTime).local().format('DD-MM-YYYY hh:mm A')}</p>
                        </div>
                        {new Date(moment.utc(event.openingTime).local()) > new Date() ?
                            <Button variant='outlined' sx={{ p: '0!important' }} disabled>{event.eventAdmin == UserService.getUserId() ? 'Start' : 'Join'}</Button> :
                            <button className="join-webinar cursor" onClick={() => openWebinarfn(event)}>{event.eventAdmin == UserService.getUserId() ? 'Start' : 'Join'}</button>
                        }
                    </div>
                        {i !== count - 1 && i !== events?.[0]?.calenderEvents?.length - 1 ?
                            <Divider sx={{ mb: '10px' }} /> :
                            <>
                                {count !== events?.[0]?.calenderEvents?.length && events?.[0]?.calenderEvents?.length > 3 ? <p className='add-cover cursor see-more textCenter' style={{ color: '#700eca' }} onClick={() => setCount(events?.[0]?.calenderEvents?.length)}>ShowAll</p> : ''}

                            </>
                        }
                    </div>
                )) : <div className="e-align profile__top sidebar__pb t_black2 font-caption"><p>No upcoming events</p></div>}

            </div>
            {/* on going program */}
            <div className="sidebar__recent sidebar__padding  border__bottom">
                <div className="sidebar__substat" style={{ marginBottom: '10px' }}>
                    <img src={education_cap} style={{ fontSize: "" }} width="18px" height="18px" alt="degree" />
                    <div className="font-caption fw__semibold  sidebar__ml sidebar__lh">Ongoing Programs</div>
                </div>
                <div className="e-align profile__top sidebar__pb t_black2 font-caption"><p>No ongoing programs</p></div>
            </div>
            {/*job*/}
            <div className="sidebar__recent sidebar__padding  border__bottom">
                <div className="sidebar__substat" style={{ marginBottom: '10px' }}>
                    <img src={job_role} alt="job" />
                    <div className="font-caption  fw__semibold sidebar__ml sidebar__lh">Job Openings</div>
                </div>
                <div className="e-align profile__top sidebar__pb t_black2 font-caption"><p>No job openings</p></div>
            </div>
            {/* audience */}
            <div className="sidebar__suggest sidebar__padding border__bottom">
                <div className="sidebar__substat">
                    <img src={view} alt="view" style={{ fontSize: "10px" }} />
                    <div className="font-caption fw__semibold  sidebar__ml sidebar__lh">Audience</div>
                </div>
                {/* <div className="sidebar__Audience sidebar__ml ">
                        <div className="sidebar__title ml-2">Profile Reach</div>
                        <div className="sidebar__statNumber">899</div>
                    </div>
                    <div className="sidebar__Audience sidebar__ml ">
                        <div className="sidebar__title ml-2">Post Reach</div>
                        <div className="sidebar__statNumber">79</div>
                    </div> */}
            </div>
            {/* <div className="sidebar__recent sidebar__padding border__bottom">
                {/* trending */}
            {/* <div className="sidebar__substat">
                    <img src={hashtag} style={{ fontSize: "20px" }} alt="hashtag" />
                    <div className="font-caption fw__semibold  sidebar__ml sidebar__lh">Trending</div>
                </div> */}
            {/* <div className='cursor'>
                    {trending.map((data, i) => (
                        // <Link to={`/post/${haashtag}`}>
                        <p key={i} className="pl-1 sidebar__pt" onClick={() => showhashtag(data)}>{data}</p>
                        // </Link>
                    ))} */}
            {/* {console.log(data)} */}

            {/* </div> */}
            {/* {recentItem('reactjs')}
                    {groupItem('medXperts')}
                    {orgItem('medXperts')} */}
            {/* </div> */}

            {/* suggested */}
            {/* <div className="sidebar__suggest sidebar__padding border__bottom">
                <div className="sidebar__substat">
                    <img src={suggestion_icon} style={{ fontSize: "20px" }} alt="suggestion" />
                    <div className="font-caption fw__semibold  sidebar__ml sidebar__lh">Suggested</div>
                </div>
                <div>
                    {suggestedTopics.map((data, i) => (
                        <p key={i} className="pl-1 sidebar__pt">{data.hashtag}</p>
                    ))}

                </div>*/}
            {/* {recentItem('reactjs')}
                    {groupItem('medXperts')}
                    {orgItem('medXperts')}*/}
            {/* </div> * /}
            {/* recent */ }
            {/* <div className="sidebar__recent sidebar__padding">
                    <div className="sidebar__substat">
                        <HistoryIcon style={{ fontSize: "20px" }} />
                        <div className="sidebar__heading fw__semibold  sidebar__ml sidebar__lh">Recent</div>
                    </div>
                    {recentItem('reactjs')}
                    {groupItem('medXperts')}
                    {orgItem('medXperts')}
                </div> */}
        </div >
        // </div>
    )
}
