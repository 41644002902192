import React, { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ViewPost from "./viewPost";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import '../Feed/Feed.css';
import '../../../../../../Components/Molecule/Feed/Feed.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
// import URL from '../../apiurl';
// import './feedpost.css';
import '../../../../../../Components/Molecule/feedposts/feedpost.css'
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../../../../../../services/UserService';
import { useState } from 'react';
import { D_API } from '../../../../../../services/APIService';
import { getUserOrg } from '../../../../../../redux/Actions/regActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';

// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



function Tabletviewpost() {
    const [post, setPost] = useState([])
    const [index, setIndex] = useState(0)

    const handleDelImage = (indextobedel) => {
        // console.log(indextobedel, "del")
    }


    const goToNextSlide = () => {
        const newPointer = index === post.length - 1 ? 0 : index + 1;
        setIndex(newPointer)
    }

    const goToPrevSlide = () => {
        const newPointer = index === 0 ? post.length - 1 : index - 1;
        // console.log(newPointer)
        setIndex(newPointer)
    }

    // const location = useLocation()
    const { id } = useParams()
    // console.log(id, "ppp")
    const history = useHistory()

    const dispatch = useDispatch()
    const userOrg = useSelector(state => state.registration.userOrg)
    console.log(userOrg, "pppp")

    useEffect(() => {
        if (Object?.keys(userOrg)?.length === 0) {
            D_API.get(`${userOrg?.id}/Organization/identifier/pageId/${id}`).then(res => {
                console.log(res, "rrrrrrr")
                dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
                // setOrgData(res?.data?.entry[0]?.resource)
            })
            // console.log("hi",'hi')
        } else {
            getImagePost()
        }
    }, [id])

    const getImagePost = () => {
        if (userOrg?.id) {
            // D_API.get(`https://devwebhooks.docsteth.com/api/v1/getImage/${userOrg.id}/${UserService.getUserId()}`, {
            axios.get(`${process.env.REACT_APP_API_URL}v1/getImagesByOrgIdAndUsrId/${UserService.getUserId()}/${userOrg?.id}`, {
                headers: { Authorization: `bearer ${UserService.getToken()}` },
            }).then(res => {
                console.log(res.data, "get")
                if (res.data?.length > 0) {
                    setPost(res.data)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }


    useEffect(() => {
        getImagePost()
    }, [userOrg])


    return (
        <div>
            <Grid container direction="row" className='icon_arrowback'>
                {(UserService.getToken()) ?
                    <Grid item xs={12} className='margin_arrow_icon' onClick={() => history.goBack()}>
                        <ArrowBackIosIcon className='back_view_post cursor'></ArrowBackIosIcon>
                    </Grid> : ""
                }

                <Grid item xs={12} style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
                    {((post?.length === 1)) ?
                        <div>
                            <img alt="Post Image" style={{ width: "100%" }} className="image_sizes" src={post[0]} />
                            {/* </img> */}
                        </div>
                        :
                        <div style={{ maxHeight: '500px', overflow: 'hidden', position: 'relative', }}>
                            <img alt="Post Image" className="image_sizes" src={post[index]} width="100%"></img>
                            <ArrowBackIosIcon onClick={() => goToPrevSlide()} className="left_arrow_image" />
                            <ArrowForwardIosIcon onClick={() => goToNextSlide()} className="right_arrow_image" />
                            <div onClick={() => handleDelImage(index)} style={{ position: 'absolute', right: "10px", top: "10px", background: "rgba(255, 255, 255, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                                <DeleteIcon />
                            </div>
                        </div>


                    }
                </Grid>
            </Grid>
        </div >
    )
}

export default Tabletviewpost
