import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import MuiPhoneNumber from "material-ui-phone-number";

const style = {
    right: 0,
    fontSize: '12px',
    color: '#d32f2f',
    // position: 'absolute',
    marginTop: '5px',
};

class PhoneValidator extends ValidatorComponent {

    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, validatorListener, value, ...rest } = this.props;

        return (
            <div>
                <MuiPhoneNumber
                    {...rest}
                    onlyCountries={['in', 'us', 'ca', 'au', 'gb', 'ae']}
                    countryCodeEditable={false}
                    value={value}
                    ref={(r) => { this.input = r; }}
                />
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={style}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default PhoneValidator;