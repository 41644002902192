import { Card, useMediaQuery } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import demoimg from "../../Atom/modifiedicons/ebmpractice_kit.svg";
import './AppVault.css'

import PrecisionManufacturingImage from '../../../icons/Group 12191.svg'
import UpcomingImage from '../../../icons/Icon material-event-available.svg'
import AppGalleryImage from '../../../icons/Group 12105.svg'
import EventImage from '../../../icons/Group 12122.svg'
import CertificateCourseImage from '../../../icons/Group 12427.svg'
import HotvacanciesImage from '../../../icons/Group 12429.svg'

import { createTheme, ThemeProvider } from '@mui/material';


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 769,
            md: 960,
            lg: 1280,
            xl: 1536,
        },
    },
});



const array1 = [
    {
        name: "My Upcoming",
        caption: "Registered Events, Course deadlines, scheduled interviews",
        img: UpcomingImage,
        value: 1,
        url: `upcomingevents`
    },
    {
        name: "MY APPS",
        caption: "My Apps",
        img: AppGalleryImage,
        value: 2,
        url: `myapps`
    },
    {
        name: "APP GALLERY",
        caption: "Find Best application for your Practice Needs",
        img: AppGalleryImage,
        value: 3,
        url: `appvault`
    },
    {
        name: "Trending Events / Webinars",
        caption: "Find CME programs, Online Workshops and More .",
        img: EventImage,
        value: 4,
        url: `trendingevents`
    },
    {
        name: "Certificate Courses",
        caption: "Skill certification and training programs for CPD/CME",
        img: CertificateCourseImage,
        value: 5,
        url: `certificatecourses`
    },
    {
        name: "Hot Vacancies",
        caption: "Apply for your dream Job today",
        img: HotvacanciesImage,
        value: 6,
        url: `hotvacancies`
    },
    {
        name: "Medical Device Gallery",
        caption: "Find Latest Medical Devices information and sellers",
        img: PrecisionManufacturingImage,
        value: 7,
        url: `medicaldevicegallery`
    },
];

const MedscopeSideBar = () => {

    const { url } = useRouteMatch();

    const active = sessionStorage.getItem("m-active")

    useEffect(() => {
        if (document.location.href.includes('/home')) {
            sessionStorage.setItem('m-active', '0')
        }
    })

    const screenWidth = useMediaQuery('(min-width:960px)')



    return (
        <>
            <ThemeProvider theme={theme}>
                {screenWidth ?
                    <>
                        {array1.map((item, i) => {
                            return (
                                <Link key={i} to={`${url}/${item.url}`}>
                                    <Card elevation={3} sx={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px", paddingLeft: "10px", borderRadius: "10px", height: "60px" }} onClick={() => sessionStorage.setItem("m-active", item.value)}>
                                        {item.img ? <img src={item.img} /> : ""}
                                        <div style={{ padding: "10px 0" }}>
                                            <div style={{ fontSize: "16px", color: '#000000E6', fontWeight: 500 }} className={`${active == item.value ? "dobold" : ''}`}> {item.name}</div>
                                            <div style={{ fontSize: "12px", color: '#00000099' }}> {item.caption}</div>
                                        </div>
                                    </Card>
                                </Link>
                            )
                        })}
                    </> :
                    <>
                        <div style={{ display: 'flex', justifyContent: "space-between", gap: "10px", padding: "3px" }}>
                            {array1.map((item, i) => {
                                return (
                                    <Link key={i} to={`${url}/${item.url}`} >
                                        <Card elevation={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "90px", height: "80px", gap: "5px", }} onClick={() => sessionStorage.setItem("m-active", item.value)}>
                                            {item.img ? <img src={item.img} /> : ""}

                                            <p style={{
                                                fontSize: "12px",
                                                textAlign: "center"
                                            }} className={`${active == item.value ? "dobold" : ''}`}> {item.name}</p>
                                        </Card>
                                    </Link>
                                )
                            })}
                        </div>
                    </>
                }
            </ThemeProvider >

        </>
    );
};

export default MedscopeSideBar;
