import axios from "axios"
import { useEffect, useState } from "react"
import { Box, Typography, Checkbox, Grid, Button, Snackbar, Tooltip } from "@mui/material"
import { DateInput, DateTime, Decimal, Integer, String, Text, Time } from "./forminputs/inputs"
import Choice from "./forminputs/choice"
import OpenChoice from "./forminputs/open-choice"
import Quantity from "./forminputs/Quantity"
import { ValidatorForm } from "react-material-ui-form-validator"
import moment from "moment"
import { useSelector } from "react-redux"
import URL from "../apiurl"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BootstrapTooltip } from "../Molecule/Post/commonFun"
import { useParams } from "react-router-dom"

const formatAns = (value, type) => {
    let itemType = ''
    var val;
    if (type !== 'open-choice' && type !== 'choice' && type !== 'text') {
        itemType = `value${type.charAt(0).toUpperCase() + type.slice(1)}`
    }
    else {
        itemType = 'valueString'
    }
    switch (itemType) {
        case 'valueBoolean': val = value || false;
            break;
        case 'valueDate': val = value ? moment(value).format('YYYY-MM-DD') : null;
            break;
        case 'valueDateTime': val = value ? new Date(value).toISOString() : null;
            break;
        case 'valueTime': val = value ? moment(value).format('HH:mm:ss') : null;
            break;
        case 'valueString': val = value || '';
            break;
        case 'valueInteger': val = value ? parseInt(value) : 0;
            break;
        case 'valueDecimal': val = value ? parseFloat(value) : 0.00;
            break;
        case 'valueQuantity': val = {
            system: ``,
            value: value ? parseFloat(value.value) : 0.00,
            unit: value && value.units
        }
            break;
    }
    let data = { [itemType]: val }
    return data
}
const formatItems = (questionnaire, data) => {
    let form = questionnaire?.questionnaireData?.item?.map((item, i) =>
        item?.linkId === data[i][item.linkId]?.linkId &&
        {
            "linkId": item.linkId,
            "text": item.text,
            "answer": data[i][item.linkId]?.value !== null && Array.isArray(data[i][item.linkId].value) ? data[i][item.linkId].value.map(val => formatAns(val, item.type)) : [formatAns(data[i][item.linkId].value, item.type)],
        }
    )
    return form
}
export default function FormRender(props) {

    const [data, setData] = useState({})
    const [ans, setAns] = useState([])
    const questionnaire = useSelector(state => state.userProfile.getQuestionnaire)
    const [output, setOutput] = useState('')
    const [open, setOpen] = useState(false)
    const { name } = useParams()

    const ids = [5, 6, 9, 12, 18, 7, 32, 30, 43, 44, 21, 22, 25, 26, 28, 48, 49, 50, 59, 72, 115, 157, 155, 77, 39, 40, 80, 205, 206, 207, 208, 198, 201, 202, 195, 196, 173, 221, 222, 223, 200, 224, 225, 227, 209, 228, 229, 210, 211, 212, 230, 213, 226, 217, 218, 219, 220, 231, 232, 233, 216, 215, 214]

    useEffect(() => {
        if (questionnaire?.questionnaireData) {
            setData(questionnaire)
            setFormAns()
        }
    }, [questionnaire])

    const setFormAns = () => {
        let formAns = questionnaire.questionnaireData?.item?.map(item => ({ [item.linkId]: { linkId: item.linkId, text: item.text, type: item.type, value: item.type === 'quantity' ? { value: '', units: item.answerOption[0].valueCoding.display } : null } }))
        setAns(formAns)
    }

    const getOptions = async (valueset) => {
        let options = await axios.get(valueset).then(res => {
            if (res.data?.data?.resource?.concept) {
                let data = res.data?.data?.resource?.concept?.map(value => ({
                    valueCoding: {
                        display: `${value.display}`
                    }
                }))
                return data
            }
            else {

                let data = res.data?.data?.resource?.compose?.include?.[0]?.concept?.map(value => ({
                    valueCoding: {
                        display: `${value.display}`
                    }
                }))
                return data
            }
        })
        return options
    }

    const handleOptions = (linkId, value) => {
        let data = [...ans]
        data = data?.map(item => item[linkId] && item[linkId].linkId == linkId ? { ...item, [linkId]: { ...item[linkId], ['value']: value } } : { ...item })
        setAns(data)
    }


    const handleSubmit = () => {

        let response = {
            questionnaire_id: questionnaire.id,
            publisher: questionnaire.questionnaireData.publisher,
            response: {
                "resourceType": "QuestionnaireResponse",
                "status": "in-progress",
                "item": formatItems(data, ans)
            }
        }
        if (ids?.includes(questionnaire?.id)) {
            axios.post(URL.submitQuestionnaire, response).then(res => {
                setOutput(res.data)
                var element = document.getElementById("output")
                element.scrollTop = element.scrollHeight - element.clientHeight
            }).catch(err => setOutput({ value: null, description: "No Records" }))
        }
        else {
            axios.post(URL.submitTestFlow, response).then(res => {
                // console.log(res.data)
                if (res.data !== 'no records----') {
                    setOutput(res.data)
                }
                else {
                    setOutput({ value: null, description: "No Records" })
                }
                var element = document.getElementById("output")
                element.scrollTop = element.scrollHeight - element.clientHeight
            }).catch(err => setOutput({ value: null, description: "No Records" }))
        }


    }

    // const setColor = (range) => {
    //     let data = range.split(':')
    //     console.log(data)
    //     let bgColor = 'transparent',color= 'white'
    //     if(data[1].toLowerCase().includes('high risk')){
    //         bgColor = 'red';
    //     }
    //     else if(data[1].toLowerCase().includes('moderate risk')){
    //         bgColor = 'yellow';
    //         color = 'black'
    //     }
    //     else if(data[1].toLowerCase().includes('low risk')){
    //         bgColor = 'green';
    //     }
    //     else {
    //         color='black'
    //     }

    //     return (
    //         <span style={{backgroundColor: bgColor,color: color,padding: '10px'}}>{range}</span>
    //     )
    // }

    return (
        <Box sx={{ textAlign: 'left', overflowY: 'auto' }}>
            <ValidatorForm onSubmit={() => handleSubmit()} onError={(err) => { setOpen(true) }}>
                <Grid container sx={{ mt: 2, px: 2 }}>
                    <p className="font-weight">
                        Calculator
                    </p>
                    <Grid item xs={12} sx={{ border: '1px solid lightgrey', mt: 1, maxHeight: 'calc(100vh - 280px)', overflowY: 'auto', borderRadius: '10px', p: 2 }}>
                        {output !== '' ? <div id="output" style={{ overflow: 'auto' }}>
                            {/* <p className="font-weight">Output</p> */}
                            {(output?.value && output?.value !== null) ?
                                <>
                                    {/* <Typography sx={{ my: 2 }}>Result: {Math.round((output.value) * 100) / 100} {output.unit}</Typography> */}
                                    {output.description ?
                                        <p className="article-para" dangerouslySetInnerHTML={{ __html: output.description?.replace(/{{Result}}/g, Math.round((output?.value) * 100) / 100) }} /> : ''}
                                </> :
                                output?.value == 0 ?
                                    <>

                                        {/* <Typography sx={{ my: 2 }}>Result: {Math.round((output.value) * 100) / 100} {output.unit}</Typography> */}

                                        {output.description ?
                                            <p className="article-para" dangerouslySetInnerHTML={{ __html: output.description?.replace(/{{Result}}/g, Math.round((output.value) * 100) / 100) }} /> : ''}

                                    </> :
                                    output.data ?
                                        Object.entries(output.data)?.map(([key, value]) => {
                                            return <Typography sx={{ my: 2 }}>{`${key}: ${value}`}</Typography>
                                        }
                                        )
                                        :
                                        name === 'check-list' ? ans?.map((val, i) => (
                                            <div style={{ padding: '10px', alignItems: 'flex-start' }} className="d-flex"><span>{i + 1}.&nbsp;</span> <p className="d-flex" style={{ justifyContent: 'space-between', flex: 1 }}><p style={{ flex: 1 }}>{ans[i][i + 1].text}</p><p className="font-bold">&nbsp;{ans[i][i + 1].value ? "Yes" : "No"}</p></p> </div>
                                        )) :
                                            <Typography sx={{ my: 2 }}>{output.description}</Typography>}
                            <Button className="submit-btn capitalize" sx={{ px: '20px!important', py: '2px!important', float: 'right' }} type='button' onClick={() => { setOutput(''); setFormAns() }}>Try Again</Button>

                        </div>
                            :
                            <>
                                <p className="font-weight">Input</p>
                                {data?.questionnaireData && ans?.length > 0 && data.questionnaireData?.item?.map((item, i) => {
                                    if (item?.extension?.length > 0) {
                                        let code = item?.extension?.[0]?.valueCodeableConcept
                                        var help = code?.coding?.find(itemcode => itemcode.code === 'help')
                                    }
                                    return (
                                        <Box key={i}>
                                            {item.type !== 'boolean' ?
                                                <>
                                                    <Typography sx={{ my: 2 }} className="d-flex e-align">{item.prefix}) {item.text} &nbsp; <BootstrapTooltip placement='top' title={help ? help.display : ''}><InfoOutlinedIcon className="cursor" style={{ fontSize: '20px' }} /></BootstrapTooltip></Typography>
                                                    <Typography component="div" sx={{ ml: 2 }}>
                                                        {
                                                            item.type === 'integer' ?
                                                                <>
                                                                    <Integer
                                                                        sx={{ width: { sm: '300px', xs: '100%' } }}
                                                                        id={`link_${item.linkid}`}
                                                                        value={ans[i][item.linkId].value || ''}
                                                                        onChange={(e) => handleOptions(item.linkId, e.target.value)}
                                                                        validators={item.required ? ['required'] : []}
                                                                        errorMessages={item.required ? ['Enter a value'] : []}
                                                                    /></> :
                                                                item.type === 'choice' ?
                                                                    <Choice
                                                                        id={`link_${item.linkid}`}
                                                                        value={ans[i][item.linkId].value}
                                                                        onChange={(value) => handleOptions(item.linkId, value)}
                                                                        item={item}
                                                                        getOptions={getOptions}
                                                                    // onChange={(value) => handleOptions(item.linkId,value)}
                                                                    /> :
                                                                    item.type === 'open-choice' ?
                                                                        <OpenChoice
                                                                            item={item}
                                                                            getOptions={getOptions}
                                                                            id={`link_${item.linkid}`}
                                                                            value={ans[i][item.linkId].value}
                                                                            onChange={(value) => handleOptions(item.linkId, value)}
                                                                        /> :
                                                                        item.type === 'quantity' ?
                                                                            <Quantity item={item}
                                                                                id={`link_${item.linkid}`}
                                                                                value={ans[i][item.linkId].value}
                                                                                onChange={(value) => handleOptions(item.linkId, value)}
                                                                                validators={item.required ? ['required'] : []}
                                                                                errorMessages={item.required ? ['Enter a value'] : []}
                                                                            /> :
                                                                            item.type === 'date' ?
                                                                                <DateInput
                                                                                    id={`link_${item.linkid}`}
                                                                                    value={ans[i][item.linkId].value}
                                                                                    onChange={(value) => handleOptions(item.linkId, value)}
                                                                                    validators={item.required ? ['required'] : []}
                                                                                    errorMessages={item.required ? ['Select Date'] : []}
                                                                                /> :
                                                                                item.type === 'dateTime' ?
                                                                                    <DateTime
                                                                                        id={`link_${item.linkid}`}
                                                                                        value={ans[i][item.linkId].value}
                                                                                        onChange={(value) => handleOptions(item.linkId, value)}
                                                                                        validators={item.required ? ['required'] : []}
                                                                                        errorMessages={item.required ? ['Select Date and Time'] : []}
                                                                                    /> :
                                                                                    item.type === 'time' ?
                                                                                        <Time
                                                                                            id={`link_${item.linkid}`}
                                                                                            value={ans[i][item.linkId].value}
                                                                                            onChange={(value) => handleOptions(item.linkId, value)}
                                                                                            validators={item.required ? ['required'] : []}
                                                                                            errorMessages={item.required ? ['Select Time'] : []}
                                                                                        /> :
                                                                                        item.type === 'decimal' ?
                                                                                            <Decimal
                                                                                                id={`link_${item.linkid}`}
                                                                                                value={ans[i][item.linkId].value || ''}
                                                                                                onChange={(e) => handleOptions(item.linkId, e.target.value)}
                                                                                                sx={{ width: { sm: '300px', xs: '100%' } }}
                                                                                                validators={item.required ? ['required'] : []}
                                                                                                errorMessages={item.required ? ['Enter a value'] : []}
                                                                                            /> :
                                                                                            item.type === 'string' ?
                                                                                                <String
                                                                                                    id={`link_${item.linkid}`}
                                                                                                    value={ans[i][item.linkId].value || ''}
                                                                                                    onChange={(e) => handleOptions(item.linkId, e.target.value)}
                                                                                                    sx={{ width: { sm: '300px', xs: '100%' } }}
                                                                                                    validators={item.required ? ['required', 'maxStringLength:75'] : ['maxStringLength:75']}
                                                                                                    errorMessages={item.required ? ['Enter Text', 'Text length should not exceed 75 characters'] : ['Text length should not exceed 75 characters']}
                                                                                                /> :
                                                                                                item.type === 'text' ?
                                                                                                    <Text
                                                                                                        id={`link_${item.linkid}`}
                                                                                                        value={ans[i][item.linkId].value || ''}
                                                                                                        onChange={(e, value) => handleOptions(item.linkId, e.target.value)}
                                                                                                        sx={{ width: { sm: '300px', xs: '100%' } }}
                                                                                                        validators={item.required ? ['required', 'maxStringLength:300'] : ['maxStringLength:300']}
                                                                                                        errorMessages={item.required ? ['Enter Text', 'Text length should not exceed 300 characters'] : ['Text length should not exceed 300 characters']}
                                                                                                    /> : ''
                                                        }
                                                    </Typography>
                                                </>
                                                :
                                                <Typography sx={{ my: 2, alignItems: 'flex-start' }} className="d-flex"> {name !== 'check-list' && item.prefix + ")"}
                                                    <Checkbox sx={{ pt: '0!important' }} value={ans[i][item.linkId].value || false} onChange={(e) => handleOptions(item.linkId, e.target.checked)} />
                                                    <span>{item.text}</span>
                                                </Typography>
                                            }

                                        </Box>
                                    )
                                })}

                                <div style={{ textAlign: 'right' }}>
                                    <Button className="submit-btn capitalize" sx={{ px: '24px!important' }} type="submit">Submit</Button>
                                    {/* <Button className="capitalize" color="error" onClick={()=>history.goBack()} sx={{px: 3}} type="submit">Cancel</Button> */}
                                </div></>}
                    </Grid>
                </Grid>
            </ValidatorForm>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                open={open}
                onClose={() => setOpen(false)}
                message={'Please fill the required fields'}
            />
        </Box>
    )
}