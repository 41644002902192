import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListHeader from './ListHeader';
import CloseIcon from '@mui/icons-material/Close';
import Follow from './followButton'
import Connections from './connections';
import './peer.css';
import { useSelector, useDispatch } from 'react-redux';
import { getHashtags } from '../../redux/Actions/connectActions';
import axios from 'axios';
import URL from '../apiurl';
import UserService from '../../services/UserService';
import Button from '@mui/material/Button'
import DialogBox from '../DialogBox/dialog';
import { useHistory } from 'react-router-dom';

export default function Topics({ type, name, name1 }) {

  const [suggestedTopics, setSuggestedTopics] = React.useState([])
  const [modal, setModal] = React.useState({ open: false, id: '' })
  const hashtags = useSelector(state => state.connect.hashtags)
  const history = useHistory()
  const dispatch = useDispatch()

  React.useEffect(() => {
    setSuggestedTopics([])
    if (name === 'Topics') {
      getTopics()
    }
    else {
      dispatch(getHashtags())
    }
  }, [name])

  React.useEffect(() => {
    if (name !== 'Topics') {
      setSuggestedTopics(hashtags)
    }
  }, [hashtags])

  const getTopics = () => {

    axios.get(`${URL.getFollowHashtags}`, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`
      }
    }).then(
      res => {
        // console.log(res)
        setSuggestedTopics(res.data?.data)

      }).catch(err => setSuggestedTopics([]))
  }

  const follow = (name) => {
    // console.log(name)
    const data = {
      hashtag: name,
      follow: true
    }
    axios.post(`${URL.followHashtag}follow`, data, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`
      }
    }).then(
      res => dispatch(getHashtags())
    ).catch(err => setSuggestedTopics([]))
  }

  const unFollow = (name) => {
    const data = {
      hashtag: name,
      follow: false
    }
    axios.put(`${URL.followHashtag}unfollow`, data, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`
      }
    }).then(
      res => {
        getTopics();
        setModal({ open: false, id: '' })
      }
    ).catch(err => setSuggestedTopics([]))
  }

  const closeCard = (id) => {
    let data = [...suggestedTopics]
    data = data.filter(value =>
      value.id !== id
    )
    setSuggestedTopics(data)
  }

  const showhashtag = (value) => {
    const tag = value.hashtag;
    // console.log(tag)
    history.push({
      pathname: `/hashtagpost`,
      state: tag
    })
  }

  return (

    <ListHeader HeaderName={name} displayname={name1}
    >
      <DialogBox width="xs" open={modal.open} handleDialog={() => setModal({ open: false, id: '' })} Content={<p>Do you want to unfollow ?</p>}
        Actions={<div>
          <Button variant="contained" className="add-option" sx={{ mr: 2 }} onClick={() => { unFollow(modal.id) }}>Yes</Button>

          <Button variant="contained" className="add-option" onClick={() => setModal({ open: false, id: '' })}>No</Button>
        </div>} />
      <ListItem className="req-list" sx={{ height: `${type ? 'auto' : 'auto'}`, maxHeight: `${type ? '100%' : '250px'}` }}>
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid container wrap={`${type ? 'wrap' : 'nowrap'}`} spacing={2} sx={{ height: `${type ? 'auto' : 'auto'}` }}>
              {suggestedTopics?.length > 0 ? suggestedTopics?.map((value, i) => (
                <Grid key={i} item lg={type ? 3 : false} sm={type ? 4 : false} xs={type ? 6 : false}>
                  <Paper sx={{ height: 160, width: `${type ? '100%' : '180px'}` }} className="custom-card">
                    <Grid item xs={12}>
                      <div>
                        <div style={{ padding: '10px 10px' }} className="text-right close-op">
                          {name !== 'Topics' ? <span className="close-icon" onClick={() => closeCard(value.id)}>
                            <CloseIcon />
                          </span> : <p style={{ height: '15px' }} />}
                        </div>
                        <ul className='card-font list-style' style={{ marginTop: '10px', paddingLeft: '0' }}>
                          <li className="font-bold font text-ellipsis cursor" style={{ padding: `${name === 'Topics' ? '10px' : '0px'}` }} onClick={() => showhashtag(value)}>{value.hashtag}</li>
                          {value.users && value.peers ? <li className="cap card-pad" >
                            <Connections total={value.users} peers={value.peers} />
                          </li> : ''}
                          <li className='card-pad'>
                            {name === 'Topics' ?
                              <Button variant="outlined" className={`pad accept`} onClick={() => setModal({ open: true, id: value.hashtag })}>
                                <p className='followtext'>Following</p>
                              </Button>
                              : <Follow follow={() => follow(value.hashtag)} />}
                          </li>
                        </ul>

                      </div>

                    </Grid>
                  </Paper>
                </Grid>
              )) : <p className="text-primary" style={{ margin: '10px 15px' }}>No {name}</p>}
            </Grid>
          </Grid>
        </Grid>

      </ListItem>
    </ListHeader>

  );
}
