import React, { useState, useEffect } from "react";
import Feed from "../../Molecule/Feed/Feed";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import add from "../../../images/add.svg";
import "./myprofile.css";
import "../../Molecule/Feed/Feed.css";
import "../../utility/style.css";
import Experience from "./organisation/experience";
import Accomplishment from "./organisation/accomplishments";
import accomplishments from "../../Atom/modifiedicons/accomplishments.svg";
import DialogBox from "../../DialogBox/dialog";
import Settings from "../../../icons/settings.svg";
import Education from "./organisation/education";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import Update from "./organisation/userupdate";
import CreatePage from "./organisation/createpage";
import { ClickAwayListener } from "@mui/material";
import Verifyprofile from "../profile/Verifyprofile";
import Drawer from "@mui/material/Drawer";
import { fetchProfile, updateProfile } from "../../../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Mail from "../../Atom/modifiedicons/Mail.svg";
import MailIcon from "../../Atom/modifiedicons/Mail.png";
import call from "../../Atom/modifiedicons/call.svg";
import location_on from "../../Atom/modifiedicons/location_on.svg";
import birthday from "../../Atom/modifiedicons/birthday.svg";
// import seen from '../../Atom/modifiedicons/seen.svg'
import { Box, Grid, Avatar, Tab } from "@mui/material";
import axios from "axios";
import URL from "../../apiurl";
import { useHistory, useLocation } from "react-router-dom";
import Doc from "../../../images/Logo2.svg";
import Snackbar from "@mui/material/Snackbar";
import TextField from "../../Fields/TextFields";
import { getPageById } from "../../../redux/Actions/actions";
import { ValidatorForm } from "react-material-ui-form-validator";
import "../profile/profile.css";
import ShowMobilenum from "../profile/showmobilenum";
import ShowEmail from "../profile/showemail";
import ShowLocation from "../profile/showlocation";
import Showdateofbirth from "../profile/showdateofbirth";
import certificate from "../../Atom/svg/certificate.svg";
import ribbon from "../../Atom/svg/ribbon.svg";
import medal from "../../Atom/svg/medal.svg";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import { styled } from '@mui/material/styles';
import { styled } from "@mui/styles";
import Development from "../../Molecule/EbmKit/development";
import ShareIcon from "@mui/icons-material/Share";
import Popover from "@mui/material/Popover";
import {
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import edit1 from "../../../icons/editt.svg";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import Dialog from "@mui/material/Dialog";
import { Typography, Divider } from "@mui/material";
import { useParams } from "react-router";
import { makeStyles } from "@mui/styles";
import shareicon from "../../../icons/share_icon.svg";
import profimage from "../../../icons/Userprofilelogo.svg";
import QRCODE from "../../../images/qrcode.svg";
import LinkedAccount from "../../../icons/linkedaccount.svg";
import Medxperts from "../../../icons/medxperts.svg";
import Idimage from "../../../icons/id.svg";
import Globe from "../../../icons/globe.svg";
import Languageimg from "../../../icons/langimage.svg";
import Vollmond from "../../../icons/vollmond.svg";
import "./updateprofile.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Navigate from "../../../icons/navigate.svg";
import Verified from "../../../icons/verifieduser.svg";
import { FiEdit3 } from "react-icons/fi";
import ErrorIcon from "@mui/icons-material/Error";
import { element } from "prop-types";
import LinkAccount from "../LinkAccount/linkaccount";
import { CreateOrg } from "./organisation/AdminOrganization/create";
import OrgLogo from "../../../images/orglogo.svg";
import { D_API, DOCS_WITHOUT_TOKEN } from "../../../services/APIService";
import UserService from "../../../services/UserService";
import CustomInput from "../../common/inputs/customInput";
import { getPractitionerFromFhir } from "../../../redux/Actions/regActions";
import { headers } from "../../../redux/Actions/connectActions";
import ProfileInfo from "../profile/profileInfo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Card } from "@mui/material";

const CustomButton = (props) => {
  return (
    <Button
      className="add-option"
      sx={{
        mt: "0px!important",
        mr: 2,
        fontWeight: "550!important",
        padding: "5px 12px!important",
      }}
      variant="outlined"
    >
      {props.children}&nbsp;{props.name}
    </Button>
  );
};

const CustomRow = (props) => {
  return (
    <Typography component="div" sx={{ px: 2, pt: 2 }}>
      <Typography component="p" sx={{ pb: 1 }} className="e-align d-flex">
        {props.children}
      </Typography>
      {props.true ? (
        ""
      ) : (
        <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.12)" }} />
      )}
    </Typography>
  );
};

const formatAddress = (address) => {
  return [
    {
      text:
        address.line1 +
        "," +
        address.line2 +
        "," +
        address.city +
        "," +
        address.state +
        "," +
        address.country +
        "," +
        address.postalCode,
      line: [address.line1, address.line2],
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country,
    },
  ];
};

const AboutBox = (props) => {
  const [about, setAbout] = useState(props.stateUsserNonFhr?.about);
  const handleSubAbout = (e) => {
    console.log(e);
    let data = {
      ...props?.stateUsserNonFhr,
      about: e,
    };
    console.log(data, "8989--");
    axios
      .put(`${URL.updateUser}/${props?.stateUsserNonFhr?.id}`, data, headers())
      .then((response) => {
        console.log(response);
        console.log(response, "uiuiui9jh");
        // dispatch(fetchProfile(user?.id))
        props.setStateUserNonFhr(response?.data?.data || "");
        props.close();
      })
      .catch((error) => { });
  };
  return (
    <DialogBox
      open={props.open}
      handleDialog={props.close}
      Title="About"
      Content={
        <>
          <textarea
            rows="5"
            value={about}
            style={{
              width: "100%",
              fontFamily: "Helvetica,Neue",
              padding: "5px",
              fontSize: "16px",
            }}
            onChange={(e) => setAbout(e.target.value)}
          />
          <div style={{ textAlign: "right" }}>
            <Button
              //  onClick={(e) => props.submit(about)}
              onClick={() => handleSubAbout(about)}
            >
              Update
            </Button>
            <Button onClick={props.close}>Cancel</Button>
          </div>
        </>
      }
    />
  );
};

export const AntTabs = styled(TabList)({
  "& .MuiTabs-indicator": {
    backgroundColor: "black",
  },
});

const useStyles = makeStyles({
  // customizeToolbar: {
  //     display: "flex",
  //     justifycontent: "space-between"
  // },
  dialog: {
    position: "absolute",
    left: 10,
    top: 50,
  },
});

const DrawerApp = (props) => {
  // console.log(props)
  const { open, toggleDrawer } = props;

  return (
    <ClickAwayListener onClickAway={toggleDrawer}>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer}
        // variant="persistent"
        sx={{
          "& .MuiPaper-root": {
            top: "65px!important",
            right: "0px!important",
          },
          "& .MuiBackdrop-root": {
            opacity: "0.7!important",
            backgroundColor: "white!important",
            top: "60px!important",
          },
          position: "absolute",
          top: "60px",
        }}
      >
        <Box className="drawer_width" role="presentation">
          <Verifyprofile toggleDrawer={toggleDrawer} />
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
};

export default function MyProfile({ showvalue, active, handleActive }) {
  const history = useHistory();
  // const classes = useStyles()
  const location = useLocation();
  const [user, setUser] = useState({});
  const [open, setOpen] = React.useState(false);
  const [contact, setContact] = useState([]);
  const [address, setAddress] = useState([]);
  const [edit, setEdit] = useState(false);
  const [about, setAbout] = useState(false);
  const [page, setPage] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [email, setEmail] = useState(false);
  const [area, setArea] = useState(false);
  const [birth, setBirth] = useState(false);
  const [dialogopen, setDialogopen] = useState(false);
  const [dialogphone, setDialogphone] = useState(false);
  const [view, setView] = useState(false);
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();
  const [hprId, setHprId] = useState("");
  const state = useSelector((state) => state.userProfile.userProfile);
  console.log(state, "stateee")
  const userOrg = useSelector((state) => state.registration.userOrg);
  console.log(state, "0000", userOrg);

  // console.log(state, "0007")
  // console.log(state)
  const myPages = useSelector((state) => state.userProfile.pages);
  const datapage = useSelector((state) => state.userProfile.pageData);
  // console.log(datapage)
  const [verify, setVerify] = React.useState({
    open: false,
    key: "",
    value: "",
    index: "",
  });
  const [otp, setOtp] = React.useState("");
  const [alertSnack, setAlertSnack] = useState({ open: false, msg: "" });
  const [isReadMore, setIsReadMore] = useState(true);
  const [contactemail, setContactemail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [qualificationDetail, setQualificationDetail] = useState([]);
  const [licenceDet, setLicenceDet] = useState([]);
  const [usersDatas, setUsersDatas] = useState();
  const [stepUpdate, setStepUpdate] = useState();
  const [stateUsserNonFhr, setStateUserNonFhr] = useState();
  // const [isanAdmin, setIsanAdmin] = useState(null);

  const [identifyHpr, setIdentifyHpr] = useState("");

  const practitionerDetails = useSelector(
    (state) => state.registration.fhirUserData
  );
  const isAdmin = useSelector((state) => state.registration?.isAdmin);
  // console.log("0000", isAdmin)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  let { name } = useParams();
  // console.log(name)
  let { id } = useParams();
  const classes = useStyles();
  const title = `${state.fullName} - ${user.caption} | Docsteth `;
  const url1 = `https://www.docsteth.com/profile/${name}-${id}`;
  const image = user.photo;
  const description = state.about;

  const HandleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_DOCSTETH}/myprofile/${name}-${id}`
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showmore = () => {
    setDialogopen(true);
  };
  const handledialogclose = () => {
    setDialogopen(false);
  };
  const handlephone = () => {
    setDialogphone(true);
  };
  const handlenumberclose = () => {
    // console.log("rtrt")
    setDialogphone(false);
  };

  // const handleshowphone = () => {
  //     setMobile(!mobile)
  // }
  // const handleshowemail = () => {
  //     setEmail(!email)
  // }
  // const handleshowlocation = () => {
  //     setArea(!area)
  // }
  // const handleshowbirthday = () => {
  //     setBirth(!birth)
  // }
  // const handleclosephone = () => {
  //     setMobile(false)
  // }
  // const handlecloseemail = () => {
  //     setEmail(false)
  // }
  // const handlecloselocation = () => {
  //     setArea(false)
  // }
  // const handleclosedateofbirth = () => {
  //     setBirth(false)
  // }

  // useEffect(() => {

  //     if (UserService?.getUserId() == id) {
  //         setIsanAdmin(true)
  //     } else {
  //         setIsanAdmin(null);
  //     }

  // }, [id])

  useEffect(() => {
    dispatch(fetchProfile(state?.id));
  }, [state?.id]);

  // console.log(state, "statezz")

  useEffect(() => {
    dispatch(getPractitionerFromFhir());
  }, [practitionerDetails?.id]);

  console.log(practitionerDetails, "practitionerDetails");

  useEffect(() => {
    DOCS_WITHOUT_TOKEN.get(
      `Practitioner/identifier/new/dev.docsteth.com/${UserService.getUserId()}`
    )
      .then((res) => {
        console.log(res);
        setUsersDatas(res?.data?.entry?.[0] || {});
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(usersDatas, "users");

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_CLINIDESK +
        `getStepsByUserId/${UserService.getUserId()}`
      )
      .then((res) => {
        console.log(res, "89898989898");
        setStepUpdate(res?.data || {});
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //     let data = state
  //     if (Object?.keys(data)?.length > 0) {
  //         let name = data.practioner.name[0]
  //         let contact = data.practioner.telecom
  //         let address = data.practioner.address ? data.practioner.address[0] : []

  //         const cerQualifications = [];
  //         const otherQualifications = [];
  //         console.log(data?.practioner?.qualification, "50")
  //         data?.practioner?.qualification?.forEach(qualification => {
  //             if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
  //                 cerQualifications.push({
  //                     degree: {
  //                         system: qualification?.code?.coding?.[0]?.system,
  //                         display: qualification?.code?.text
  //                     },
  //                     course: qualification?.code?.coding?.[0],
  //                     orgName: "rtyu",
  //                     issuer: {
  //                         name: qualification?.issuer?.display,
  //                         id: qualification?.issuer?.reference?.split('/')?.[1]
  //                     },
  //                     dateIss: qualification?.period?.end,
  //                     identifier: qualification?.identifier?.[0]?.value
  //                 });
  //             } else {
  //                 otherQualifications.push({
  //                     council: qualification?.issuer?.display,
  //                     regNo: qualification?.identifier?.[0]?.value,
  //                     date: qualification?.period?.start,
  //                     dueDate: qualification?.period?.end
  //                 });
  //             }
  //         });

  //         setQualificationDetail(cerQualifications);
  //         setLicenceDet(otherQualifications);
  //         setUser({
  //             family: name.family,
  //             firstName: name.given[0],
  //             middleName: name.given[1],
  //             prefix: name.prefix ? name.prefix[0] : '',
  //             suffix: name.suffix ? [...name.suffix] : "",
  //             text: name.text,
  //             gender: data.practioner.gender,
  //             birthDate: data.practioner.birthDate !== null ? data.practioner.birthDate : '',
  //             caption: data.captionAndTaglines,
  //             about: data.about,
  //             id: data.id,
  //             photo: data.practioner.photo[0].url,
  //             coverPic: data.coverPic,
  //             qualification: data.practioner?.qualification,
  //             languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : []
  //         })
  //         // console.log(address)
  //         const addressData = {
  //             facilityName: address.facilityName ? address.facilityName : '',
  //             text: address.text,
  //             line1: address.hasOwnProperty('line') ? address?.line[0] : '',
  //             line2: address.hasOwnProperty('line') ? address?.line[1] : '',
  //             city: address.city,
  //             state: address.state,
  //             postalCode: address.postalCode,
  //             country: address.country,
  //             district: address.district,
  //         }

  //         setContact(contact)
  //         setAddress(addressData)
  //     }
  //     else {
  //         let userid = sessionStorage.getItem("userId")
  //         // dispatch(fetchProfile(userid))
  //     }
  // }, [state, edit])
  useEffect(() => {
    let data = practitionerDetails;

    if (Object?.keys(data)?.length > 0) {
      let name = data?.name?.[0]?.given?.[0];
      // let address = data.practioner.address ? data.practioner.address[0] : []
      const cerQualifications = [];
      const otherQualifications = [];
      data?.qualification?.forEach((qualification) => {
        if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
          cerQualifications.push({
            degree: {
              system: qualification?.code?.coding?.[0]?.system,
              display: qualification?.code?.text,
            },
            course: qualification?.code?.coding?.[0],
            orgName: "rty",
            issuer: {
              name: qualification?.issuer?.display,
              id: qualification?.issuer?.reference?.split("/")?.[1],
            },
            dateIss: qualification?.period?.end,
            identifier: qualification?.identifier?.[0]?.value,
          });
        } else {
          otherQualifications.push({
            council: qualification?.issuer?.display,
            regNo: qualification?.identifier?.[0]?.value,
            date: qualification?.period?.start,
            dueDate: qualification?.period?.end,
          });
        }
      });
      setQualificationDetail(cerQualifications);
      setLicenceDet(otherQualifications);

      setContact(data?.telecom ? data.telecom : []);

      let hasHprorNot = data?.identifier?.some(
        (e) => e.system === "https://healthid.ndhm.gov.in/"
      );

      console.log(hasHprorNot, "hasHprorNot")

      let indexOfHpr =
        data?.identifier?.findIndex(
          (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
        ) != -1 && hasHprorNot
          ? data?.identifier?.findIndex(
            (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
          )
          : -1;
      //   console.log(hasHprorNot,"hasHprorNot",indexOfHpr)
      let getHprId =
        hasHprorNot && indexOfHpr != -1
          ? data?.identifier[indexOfHpr].value
          : "";
      setIdentifyHpr(getHprId ? getHprId : "");

      setUser({
        family: data?.name?.[0]?.family,
        firstName: data?.name?.[0]?.given?.[0],
        middleName: data?.name?.[0]?.given?.[1],
        prefix: data?.name?.[0]?.prefix ? data?.name?.[0]?.prefix?.[0] : "",
        suffix: data?.name?.[0]?.suffix ? [...data?.name?.[0]?.suffix] : [],
        birthDate: data?.birthDate,
        qualification: data?.qualification,
        text: data?.name?.[0]?.text,
        languages:
          data?.communication?.length > 0
            ? data?.communication?.map((e) => e?.coding?.[0])
            : [],
        gender: data.gender,
        photo: data?.photo?.[0]?.url ? data?.photo?.[0]?.url : "",
        caption:
          data.identifier &&
            data.identifier?.find(
              (e) => e["system"] == "https://profiletagline/" && e["value"]
            )
            ? data.identifier?.find(
              (e) => e["system"] == "https://profiletagline/" && e["value"]
            )?.value
            : "",
        // caption: data.captionAndTaglines,
        // about: data.about,
        // id: data.id,
        // photo: data.practioner.photo[0].url,
        // coverPic: data.coverPic,
        // languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : []
      });
      // const addressData = {
      //         facilityName: address.facilityName ? address.facilityName : '',
      //         text: address.text,
      //         line1: address.hasOwnProperty('line') ? address?.line[0] : '',
      //         line2: address.hasOwnProperty('line') ? address?.line[1] : '',
      //         city: address.city,
      //         state: address.state,
      //         postalCode: address.postalCode,
      //         country: address.country,
      //         district: address.district,
      //     }

      const addressData = {
        text: [
          `${data?.address?.[0]?.line?.[0]},${data?.address?.[0]?.line?.[1]},${data?.address?.[0].city},${data?.address?.[0]?.state},${data?.address?.[0]?.country},${data?.address?.[0]?.postalCode}`,
        ]?.filter((val) => Boolean(val)),
        line1: data?.address?.[0]?.line?.[0]
          ? data?.address?.[0]?.line?.[0]
          : "",
        line2: data?.address?.[0]?.line?.[1]
          ? data?.address?.[0]?.line?.[1]
          : "",
        city: data?.address?.[0].city ? data?.address?.[0].city : "",
        state: data?.address?.[0]?.state ? data?.address?.[0]?.state : "",
        postalCode: data?.address?.[0]?.postalCode
          ? data?.address?.[0]?.postalCode
          : "",
        country: data?.address?.[0]?.country ? data?.address?.[0]?.country : "",
        district: data?.address?.[0].city,
      };
      setAddress(addressData);
    }
    // if (Object?.keys(data)?.length > 0) {
    //     let name = data.practioner.name[0]
    //     let contact = data.practioner.telecom
    //     let address = data.practioner.address ? data.practioner.address[0] : []
    //     const cerQualifications = [];
    //     const otherQualifications = [];
    //     console.log(data?.practioner?.qualification, "50")
    //     data?.practioner?.qualification?.forEach(qualification => {
    //         if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
    //             cerQualifications.push({
    //                 degree: {
    //                     system: qualification?.code?.coding?.[0]?.system,
    //                     display: qualification?.code?.text
    //                 },
    //                 course: qualification?.code?.coding?.[0],
    //                 orgName: "rtyu",
    //                 issuer: {
    //                     name: qualification?.issuer?.display,
    //                     id: qualification?.issuer?.reference?.split('/')?.[1]
    //                 },
    //                 dateIss: qualification?.period?.end,
    //                 identifier: qualification?.identifier?.[0]?.value
    //             });
    //         } else {
    //             otherQualifications.push({
    //                 council: qualification?.issuer?.display,
    //                 regNo: qualification?.identifier?.[0]?.value,
    //                 date: qualification?.period?.start,
    //                 dueDate: qualification?.period?.end
    //             });
    //         }
    //     });

    //     setQualificationDetail(cerQualifications);
    //     setLicenceDet(otherQualifications);
    //     setUser({
    //         family: name.family,
    //         firstName: name.given[0],
    //         middleName: name.given[1],
    //         prefix: name.prefix ? name.prefix[0] : '',
    //         suffix: name.suffix ? [...name.suffix] : "",
    //         text: name.text,
    //         gender: data.practioner.gender,
    //         birthDate: data.practioner.birthDate !== null ? data.practioner.birthDate : '',
    //         caption: data.captionAndTaglines,
    //         about: data.about,
    //         id: data.id,
    //         photo: data.practioner.photo[0].url,
    //         coverPic: data.coverPic,
    //         qualification: data.practioner?.qualification,
    //         languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : []
    //     })
    //     // console.log(address)
    //     const addressData = {
    //         facilityName: address.facilityName ? address.facilityName : '',
    //         text: address.text,
    //         line1: address.hasOwnProperty('line') ? address?.line[0] : '',
    //         line2: address.hasOwnProperty('line') ? address?.line[1] : '',
    //         city: address.city,
    //         state: address.state,
    //         postalCode: address.postalCode,
    //         country: address.country,
    //         district: address.district,
    //     }

    //     setContact(contact)
    //     setAddress(addressData)
    // }
    // else {
    //     let userid = sessionStorage.getItem("userId")
    // }
  }, [practitionerDetails]);

  // console.log(state, "state")

  console.log(practitionerDetails, "practitionerDetails");

  useEffect(() => {
    if (state.practioner) {
      if (state.practioner.birthDate === null) {
        // setEdit(true)
      }
    }
  }, [state]);

  const handleSubmit = (form, contact, address, edu, licence, Userss, hprIdDetails) => {
    let UsersDatas = usersDatas;

    let educationDetails = [];
    let licenceDetails = [];
    edu?.map((e) => {
      if (
        e?.course?.code &&
        e?.degree?.system &&
        e?.issuer?.name &&
        e?.dateIss
      ) {
        educationDetails.push(e);
      }
    });

    licence?.map((li) => {
      if (li?.regNo && li?.council && li?.date && li?.dueDate) {
        licenceDetails.push(li);
      }
    });

    console.log("lice", hprIdDetails);
    // console.log(usersDatas, "9999")
    // dispatch(updateProfile(form, contact, formatAddress(address), edu, licence, stepUpdate, UsersDatas))
    dispatch(updateProfile(form, contact, formatAddress(address), educationDetails, licenceDetails, UsersDatas, hprIdDetails))

    setEdit(false);
  };

  const submitAbout = (userAbout) => {
    let UsersDatas = usersDatas;
    console.log(user, "555");
    setUser({ ...user, about: userAbout });
    let hprIdDetails = identifyHpr;
    dispatch(
      updateProfile(
        { ...user, about: userAbout },
        contact,
        formatAddress(address),
        qualificationDetail,
        licenceDet,
        UsersDatas,
        hprIdDetails
      )
    );
    // dispatch(updateProfile({ ...user, about: userAbout }, contact, formatAddress(address), user.qualification,))
    // setAbout(false)
  };

  const handleDelete = (id) => {
    let UsersDatas = usersDatas;
    let contacts = [...contact];
    contacts = contacts.filter((contact, i) => (id !== i ? { ...i } : ""));

    setContact(contacts);
    let hprIdDetails = identifyHpr;
    dispatch(
      updateProfile(
        user,
        contacts,
        formatAddress(address),
        qualificationDetail,
        licenceDet,
        UsersDatas,
        hprIdDetails
      )
    );
    // dispatch(updateProfile(user, contacts, formatAddress(address), user.qualification))
  };

  const handleVisibility = (data) => {
    let UsersDatas = usersDatas;
    let length = contact.length;
    if (
      contact[length - 1].system !== "" &&
      contact[length - 1].use !== "" &&
      contact[length - 1].value !== ""
    ) {
      setContact(data);
      let hprIdDetails = identifyHpr;
      dispatch(
        updateProfile(
          user,
          data,
          formatAddress(address),
          qualificationDetail,
          licenceDet,
          UsersDatas,
          hprIdDetails
        )
      );
      // dispatch(updateProfile(user, data, formatAddress(address), user.qualification))
    }
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("length", (value) => {
      if (value.length !== 6) {
        return false;
      }
      return true;
    });
  }, []);

  useEffect(() => {
    let link = window.location.href.split("#")[1];
    const element = document.getElementById(`${link}`);
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  const submitOTP = () => {
    let con = [...contact];
    con[verify.index]["verify"] = true;
    const data = {
      otp: otp,
      metadata: {},
      [verify.key]: verify.value,
    };
    axios
      .post(`${verify.key === "email" ? URL.verifyEmail : URL.verifyOTP}`, data)
      .then(
        (res) => {
          if (res["status"] === 200) {
            setAlertSnack({ open: true, msg: `${res.data.message}` });
            setVerify({ open: false, key: "", value: "", index: "" });
            handleVisibility(con);
          } else {
            setAlertSnack({ open: true, msg: `${res.data.message}` });
            setVerify({ open: false, key: "", value: "", index: "" });
          }
        },
        (error) => {
          setAlertSnack({
            open: true,
            msg: "Either otp is expired or invalid.",
          });
          setVerify({ open: false, key: "", value: "", index: "" });
        }
      );
  };

  const sendOTP = (value, i) => {
    let length = contact.length;
    if (
      contact[length - 1].system !== "" &&
      contact[length - 1].use !== "" &&
      contact[length - 1].value !== ""
    ) {
      const data = {
        metadata: {},
        phoneNumber: value,
      };
      axios.post(`${URL.signupSendOTP}`, data).then(
        (res) => {
          if (res["status"] === 200) {
            if (res.data.status === 201) {
              setAlertSnack({ open: true, msg: `${res.data.message}` });
              setVerify({
                open: true,
                key: "phoneNumber",
                value: value,
                index: i,
              });
            } else if (res.data.status === 409) {
              setAlertSnack({ open: true, msg: `${res.data.message}` });
              setVerify({ open: false, key: "", value: "", index: "" });
            }
          } else {
            setAlertSnack({ open: true, msg: "Internal server error" });
            setVerify({ open: false, key: "", value: "", index: "" });
          }
        },
        (error) => {
          setAlertSnack({ open: true, msg: "Error " });
        }
      );
    } else {
      alert("Please fill the empty fields");
    }
  };

  const sendEmailOTP = (value, i) => {
    let length = contact.length;
    if (
      contact[length - 1].system !== "" &&
      contact[length - 1].use !== "" &&
      contact[length - 1].value !== ""
    ) {
      const data = {
        metadata: {},
        email: value,
      };
      axios.post(`${URL.sendEmailOTP}`, data).then(
        (res) => {
          if (res["status"] === 200) {
            if (res.data.status === 200) {
              setAlertSnack({ open: true, msg: `${res.data.message}` });
              setVerify({ open: true, key: "email", value: value, index: i });
            } else if (res.data.status === 409) {
              setAlertSnack({ open: true, msg: `${res.data.message}` });
              setVerify({ open: false, key: "", value: "", index: "" });
            }
          } else {
            setAlertSnack({ open: true, msg: "Internal server error" });
            setVerify({ open: false, key: "", value: "", index: "" });
          }
        },
        (error) => {
          setAlertSnack({ open: true, msg: "Error " });
        }
      );
    } else {
      alert("Please fill the empty fields");
    }
  };
  // let objectkeys = contact[0]

  // console.log(contact, "contact")

  useEffect(() => {
    if (contact.length > 0) {
      let data1 = contact.filter((element) => {
        return element.system === "email";
      });
      setContactemail(data1);
    }
    if (contact.length > 0) {
      let data2 = contact.filter((element) => {
        return element.system === "phone";
      });
      setPhone(data2);
    }
  }, [contact]);

  useEffect(() => {
    console.log("rerererssgfgnk", state);
    if (state?.id) {
      axios
        .get(`${URL.findUserById}/${state?.id}`, headers())
        .then((response) => {
          console.log(response, "rerererssgfgnk");
          // dispatch(fetchProfile(user?.id))
          setStateUserNonFhr(response?.data?.data);
        })
        .catch((error) => { });
    }
  }, [state]);

  // console.log(contactemail)
  // console.log(phone)

  const handleOrg = () => {
    // setOpenOrg(prev => !prev)
    setPage(false);
  };

  const handleClickOrg = () => {
    alert("Please Verify your Profile");
  };

  function formatNumber(number) {
    // var formattedNumber = number.match(/.{1,4}/g).join('-');
    var formattedNumber =
      number.substring(0, 2) +
      "-" +
      number.substring(2, 6) +
      "-" +
      number.substring(6, 10) +
      "-" +
      number.substring(10, 14);

    return formattedNumber;
  }

  const submitHpr = () => {
    // dispatch(updateProfile(form, contact, formatAddress(address), edu, licence, stepUpdate, UsersDatas, ident))
    let hprIdDetails = formatNumber(hprId);

    //   console.log("lice",hprIdDetails)
    dispatch(
      updateProfile(
        { ...user },
        contact,
        formatAddress(address),
        qualificationDetail,
        licenceDet,
        usersDatas,
        hprIdDetails
      )
    );
    setView(false);

    console.log("hpr");
    // let formattedNumber = formatNumber(hprId);
    // let data1 = {
    //     "about": usersDatas?.about,
    //     "captionAndTaglines": usersDatas?.captionAndTaglines,
    //     "fullName": usersDatas?.fullName,
    //     "id": usersDatas?.id,
    //     "keyclockId": usersDatas?.keyclockId,
    //     "coverPic": usersDatas?.coverPic,
    //     "metadata": usersDatas?.metadata,
    //     "active": false,
    //     "practioner": {
    //         "resourceType": "Practitioner",
    //         "id": usersDatas?.id,
    //         "text": {
    //             "status": "generated",
    //             "div": `<div xmlns=http://www.w3.org/1999/xhtml>\n      <p>${usersDatas?.fullName}</p>\n    </div>`
    //         },
    //         "name": [...usersDatas?.practioner?.name],
    //         "telecom": usersDatas?.practioner?.telecom,
    //         "gender": user?.gender,
    //         identifier: [
    //             ...(usersDatas?.practioner?.identifier?.length >= 2
    //                 ? [
    //                     ...usersDatas?.practioner?.identifier,
    //                     {
    //                         "system": "https://healthid.ndhm.gov.in/",
    //                         "value": formattedNumber
    //                     }
    //                 ]
    //                 : [
    //                     {
    //                         "system": "https://dev.docsteth.com",
    //                         "value": `${UserService.getUserId()}`
    //                     },
    //                     {
    //                         "system": "https://dev.docsteth.com",
    //                         "value": usersDatas?.userName
    //                     },
    //                     {
    //                         "system": "https://healthid.ndhm.gov.in/",
    //                         "value": formattedNumber
    //                     }
    //                 ]
    //             )
    //         ],
    //         "birthDate": usersDatas?.practioner?.birthDate,
    //         "photo": usersDatas?.practioner?.photo,

    //         "address": usersDatas?.practioner?.address,
    //         ...(usersDatas?.practioner?.qualification?.length > 0 &&
    //             { qualification: usersDatas?.practioner?.qualification }
    //         ),
    //     }
    // }

    // let dataAcc = {
    //     "resourceType": "Practitioner",

    //     "name": [
    //         {
    //             "use": "official",
    //             "text": usersDatas?.practioner?.name?.[0]?.text,
    //             "family": usersDatas?.practioner?.name?.[0]?.family,
    //             "given": [
    //                 usersDatas?.practioner?.name?.[0]?.given?.[0]
    //             ],
    //             "prefix": usersDatas?.practioner?.name?.[0]?.prefix,
    //             "suffix": usersDatas?.practioner?.name?.[0]?.suffix?.[0]
    //         }
    //     ],

    //     ...(usersDatas?.practioner?.telecom?.length > 0 && {
    //         telecom: usersDatas?.practioner?.telecom?.map(each => (
    //             each.system === "phone" ? {
    //                 "system": "phone",
    //                 "use": each?.use,
    //                 "value": each?.value
    //             } : {
    //                 "system": "email",
    //                 "use": each?.use,
    //                 "value": each?.value
    //             }
    //         ))
    //     }),

    //     ...(user?.gender && { "gender": user?.gender }),
    //     active: false,
    //     identifier: [
    //         ...(usersDatas?.practioner?.identifier?.length >= 2
    //             ? [
    //                 ...usersDatas?.practioner?.identifier,
    //                 {
    //                     "system": "https://healthid.ndhm.gov.in/",
    //                     "value": formattedNumber
    //                 }
    //             ]
    //             : [
    //                 {
    //                     "system": "https://dev.docsteth.com",
    //                     "value": `${UserService.getUserId()}`
    //                 },
    //                 {
    //                     "system": "https://dev.docsteth.com",
    //                     "value": usersDatas?.userName
    //                 },
    //                 {
    //                     "system": "https://healthid.ndhm.gov.in/",
    //                     "value": formattedNumber
    //                 }
    //             ]
    //         )
    //     ],
    //     // "birthDate": "2024-05-01",
    //     "birthDate": dayjs(usersDatas?.practioner?.birthDate).format('YYYY-MM-DD'),
    //     ...(usersDatas?.practioner?.address?.[0]?.line?.[0] && usersDatas?.practioner?.address?.[0]?.line?.[1] && { "address": usersDatas?.practioner?.address?.[0] }),
    //     // "photo": usersDatas?.practioner?.photo,
    //     // ...(usersDatas?.practioner?.qualification?.length > 0 &&
    //     //     { qualification: usersDatas?.practioner?.qualification }
    //     // ),

    // }
    // // console.log("jkdjskjsksjk", deleteEmptyKeys(dataAcc))
    // console.log(data1)
    // let dataAccc = deleteEmptyKeys(dataAcc)
    // axios.put(`${URL.updateUser}/${user.id}`, data1, headers())
    //     .then(response => {
    //         console.log(response)
    //         // updateStepsData("1", stepUpdate)
    //         dispatch(fetchProfile(user.id))
    //         setView(false)
    //         // window.location.reload();
    //     })
    //     .catch(error => { })
    // if (userAccountDet?.resource?.id) {
    //     D_API_FHIR.put(`Practitioner/${userAccountDet?.resource?.id}`, { ...dataAcc, id: userAccountDet?.resource?.id }, headers())
    //         .then(response => {
    //             console.log(response)
    //             // updateStepsData("1", stepUpdate)
    //             // dispatch(fetchProfile(user.id))
    //         })
    //         .catch(error => { })
    // }
    // else {
    //     D_API_FHIR.post(`Practitioner`, dataAcc, headers())
    //         .then(response => {
    //             console.log(response)
    //             // updateStepsData("1", stepUpdate)
    //             // dispatch(fetchProfile(user.id))
    //         })
    //         .catch(error => { })
    // }
  };
  return (
    <div>

      {edit ? (
        <Update
          open={edit}
          data={user}
          address={address}
          con={contact}
          handleDelete={handleDelete}
          handleVisibility={handleVisibility}
          handleSubmit={handleSubmit}
          handleDialog={() => setEdit(false)}
          width="md"
          sendOTP={sendOTP}
          sendEmailOTP={sendEmailOTP}
          hprInfo={identifyHpr}
        />
      ) : (
        ""
      )}
      {/* <CreatePage open={page} handleDialog={() => setPage(false)} width="md" /> */}
      {page && <CreateOrg close={handleOrg} branch="mainBranch" />}
      {about ? (
        <AboutBox
          submit={submitAbout}
          open={about}
          about={user.about}
          close={() => setAbout(false)}
          stateUsserNonFhr={stateUsserNonFhr}
          setStateUserNonFhr={setStateUserNonFhr}
        />
      ) : (
        ""
      )}

      <DialogBox
        open={verify.open}
        handleDialog={() =>
          setVerify({ open: false, key: "", value: "", index: "" })
        }
        Title="Verify OTP"
        width="xs"
        Content={
          <>
            <ValidatorForm onSubmit={() => submitOTP()}>
              <TextField
                label="OTP"
                placeholder="Enter OTP"
                value={otp}
                name="OTP"
                handleChange={(name, value) => setOtp(value)}
                validators={["required", "length"]}
                errorMessages={["Please Enter OTP", "Enter Valid OTP"]}
              />
              <div style={{ textAlign: "right" }}>
                <Button type="submit">Verify</Button>
                <Button
                  onClick={() =>
                    setVerify({ open: false, key: "", value: "", index: "" })
                  }
                >
                  Cancel
                </Button>
              </div>
            </ValidatorForm>
          </>
        }
      />
      <Box className="myprofile_header">
        {/* <Grid container spacing={2}>
                    <Grid item md={8} xs={12}> */}
        <div className="border__stat">
          {/*----------------- LARGE SCREEN &&  DESTOPS -----------------*/}
          <div className="bigscreens mt-10">
            <div
              className="border_img"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <ProfileInfo
                phoneDetails={phone}
                emailDetails={contactemail}
                addressDetails={address}
                profileQualif={qualificationDetail}
                setFnView={setView}
                setHprRest={setHprId}
              />
            </div>

            <div className="border_img">
              <div id="qualification">
                <Education />
              </div>
            </div>

            {/* {
                            isanAdmin === true && */}
            <LinkAccount />
            {/* } */}

            {/* <LinkAccount /> */}
            {/* <div className='border_img mt-10'> */}
            {/* <div style={{ display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                                <h3 className='fw__normal' style={{ fontSize: "18px", fontWeight: "550", color: "rgb(35, 122, 196)", padding: "12px 12px 0px 20px" }}>Contact Details</h3>
                            </div> */}
            {/* <div className='sidebar__padding' style={{ backgroundColor: "#fff" }}> */}

            {/* <div className="sidebar__stat sidebar__pt ">
                                    <div className="sidebar__substat">
                                        <img src={location_on} alt="" style={{ fontSize: "16px" }} />
                                        <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                            {address.city || address.state || address.country ?
                                                <>
                                                    <span>{address.city ? address.city : ''}</span>{" "}

                                                    {address.city && address.state ? ',' : ''}<span>{address.state ? address.state + ',' : ''}</span>{" "}

                                                    <span>{address.country ? address.country : ''}</span>

                                                </> : 'N/A'}
                                        </div>
                                    </div>



                                </div> */}

            {/* 
                                <div>
                                    hello
                                    {
                                        state?.followersCount ? state?.followersCount + " " + 'Followers' : ""

                                    }
                                </div> */}
            {/*cake */}
            {/* <div className="sidebar__stat sidebar__pt ">
                                        <div className="sidebar__substat sidebar__pt">
                                            <img src={birthday} alt="" style={{ fontSize: "16px" }} />
                                            <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>{user.birthDate ? moment(user.birthDate).format('DD-MM-YYYY') : 'N/A'}</div>
                                        </div>
                                    </div> */}

            {/* email */}

            {/* <div className="sidebar__stat pt-2 sidebar__pt ">
                                    <div className="sidebar__substat ta__center">
                                        <img src={MailIcon} alt="emailicon" style={{ fontSize: "16px" }} />
                                        <div className="ml-2">
                                            <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                {contactemail[0]?.value}{" "}
                                            </span>
                                            {contactemail.length > 1 ?
                                                <span onClick={showmore} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {"+" + (contactemail.length - 1) + "more"}
                                                </span> : ""
                                            }
                                        </div>
                                    </div>
                                </div>

                                <Dialog
                                    open={dialogopen}
                                    onClose={handledialogclose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Box className='contact_details'>
                                        <div className='space_btw_center'>
                                            <h5>Contact Details</h5>
                                            <ClearIcon className='cursor' onClick={handledialogclose} />
                                        </div>
                                        <div className="pt-2 sidebar__pt ">
                                            <ul className="ta__center" id="mobilecontact_list">
                                                {contactemail.map((element, index) => {
                                                    return (
                                                        <div className="mt-10" key={index}>
                                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                {element.value}
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </Box>
                                </Dialog> */}
            {/* phone */}
            {/* <div className="sidebar__stat pt-2 sidebar__pt ">
                                    <div className="sidebar__substat ta__center">
                                        <img src={call} alt="emailicon" style={{ fontSize: "16px" }} />
                                        <div className="ml-2">
                                            <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                {phone?.[0]?.value}{" "}
                                            </span>
                                            {phone?.length > 1 ?
                                                <span onClick={handlephone} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {"+" + (phone?.length - 1) + "more"}
                                                </span> : ""
                                            }
                                        </div>
                                    </div>
                                </div> */}
            {/* <Dialog
                                    open={dialogphone}
                                    onClose={handlenumberclose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Box className='contact_details'>
                                        <div className='space_btw_center'>
                                            <h5>Contact Details</h5>
                                            <ClearIcon className='cursor' onClick={handlenumberclose} />
                                        </div>
                                        <div className="pt-2 sidebar__pt ">
                                            <ul className="ta__center" id="mobilecontact_list">
                                                {phone.map((element, index) => {
                                                    return (
                                                        <div className="mt-10" key={index}>
                                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                {element.value}
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </Box>
                                </Dialog> */}
            {/* <div className='mt-20'>
                                        <span style={{ display: "flex", alignItems: "center" }}><img src={Globe} width="25px" height="25px" style={{ paddingRight: "10px" }}></img>Website</span>
                                    </div> */}
            {/* </div> */}
            {/* </div> */}

            <div
              className="sidebar__padding border_img mt-10"
              style={{ paddingBottom: "20px" }}
            >
              <div
                className="mt-10"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <img src={Languageimg} height="30px" width="30px"></img>
                </div>
                <div style={{ paddingLeft: "10px" }}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "550",
                      color: "rgb(35, 122, 196)",
                    }}
                  >
                    Communication (Language)
                  </span>
                  <p style={{ padding: "8px 0px 0px 10px" }}>English</p>
                </div>
              </div>
            </div>

            {/* Pages */}

            {state.kycStatus === "PENDING" || state.kycStatus === "REJECTED" ? (
              <div
                className="border_img sidebar__padding mt-10 d-flex"
                style={{ justifyContent: "space-between" }}
                onClick={handleClickOrg}
              >
                <div className="sidebar__substat ta__center">
                  <div
                    className="fw__semibold sidebar__lh  sidebar__ml"
                    style={{
                      fontSize: "18px",
                      fontWeight: "550",
                      color: "#237AC4",
                    }}
                  >
                    Organisation Pages
                  </div>
                </div>
                <div className="sidebar__statNumber">
                  <IconButton
                    aria-label="visible"
                    onClick={() => "setPage(true)"}
                  >
                    <img src={add} alt="add" />
                    {/* <span className='create_orz_text'>Create Organisation Page</span> */}
                  </IconButton>
                </div>
              </div>
            ) : (
              <div
                className="border_img sidebar__padding mt-10 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="sidebar__substat ta__center">
                  <div
                    className="fw__semibold sidebar__lh  sidebar__ml"
                    style={{
                      fontSize: "18px",
                      fontWeight: "550",
                      color: "#237AC4",
                    }}
                  >
                    Organisation Pages
                  </div>
                </div>
                <div className="sidebar__statNumber">
                  <IconButton
                    aria-label="visible"
                    onClick={() => setPage(true)}
                  >
                    <img src={add} alt="add" />
                    {/* <span className='create_orz_text'>Create Organisation Page</span> */}
                  </IconButton>
                </div>
              </div>
            )}
            {/* <div className="border_img sidebar__padding mt-10 d-flex" style={{ justifyContent: "space-between" }}>
                            <div className="sidebar__substat ta__center">
                                <div className="fw__semibold sidebar__lh  sidebar__ml" style={{ fontSize: "18px", fontWeight: "550", color: "#237AC4" }}>Organisation Pages1111</div>
                            </div>
                            <div className="sidebar__statNumber">
                                <IconButton aria-label="visible" onClick={() => setPage(true)}>
                                    <img src={add} alt="add" />
                        
                                </IconButton>
                            </div>
                        </div> */}
            <div className="sidebar__stat sidebar_column height_pages bg__white border_img">
              <div>
                {myPages ? (
                  (myPages ?? []).map((item, ind) => {
                    return (
                      <div
                        key={ind}
                        className="sidebar_statPage"
                        onClick={() =>
                          history.push(`/admin/organization/${item.id}`)
                        }
                      >
                        <div
                          className="sidebar_pages"
                          style={{ alignItems: "center" }}
                        >
                          <div>
                            {/* <Avatar style={{ height: "32px", width: "32px", borderRadius: "7px" }} src={item.logo ? item.logo : Doc} /> */}
                            {item.logo ? (
                              <Avatar
                                style={{
                                  height: "32px",
                                  width: "32px",
                                  borderRadius: "7px",
                                }}
                                src={item.logo}
                              />
                            ) : (
                              // <Avatar style={{ height: "32px", width: "32px", borderRadius: "7px" }} src={OrgLogo} />
                              <Avatar
                                variant="rounded"
                                src={OrgLogo}
                                style={{
                                  height: "32px",
                                  width: "32px",
                                  borderRadius: "7px",
                                }}
                                sx={{
                                  backgroundColor: "#A8E8FE",
                                  ".MuiAvatar-img": {
                                    height: "25px",
                                    width: "25px",
                                  },
                                }}
                              />
                            )}
                          </div>
                          <div className="sidebar_pagesName cursor">
                            <b
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "16px",
                                textTransform: "capitalize",
                                fontWeight: "400",
                              }}
                            >
                              {item.name}
                            </b>
                            <p
                              className="fontItalic captntag"
                              style={{ fontSize: "14px", fontWeight: "300" }}
                            >
                              {" "}
                              {item.captionAndTagline}
                            </p>
                          </div>
                        </div>
                        <p
                          className="sidebar_countPAge"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "0px",
                          }}
                        >
                          {item.count}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <p>
                      If your managing an organisation build page for your
                      organisation
                    </p>
                  </>
                )}
              </div>
            </div>

            {/* Education */}

            {/* <div className='border_img mt-10'>
                            <div className='recent_activity dflex_al_jc' style={{ padding: "15px" }}>
                                <h2 style={{ fontSize: "18px", fontWeight: "400" }}>Recent Activity (28)</h2>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                            </div>
                            <div style={{ padding: "10px 20px" }}>
                                <div className='dflex_al_jc'>
                                    <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                        <p>Started following</p>
                                    </div>
                                    <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                        <p>Activity time</p>
                                    </div>
                                </div>
                                <div className='mt-10' style={{ display: "flex" }}>
                                    <div>
                                        <img src={Vollmond} height="65px" width="65px">
                                        </img>
                                    </div>
                                    <div className='mt-10' style={{ color: "#000000" }}>
                                        <h3 style={{ fontSize: "15px", fontWeight: "400" }}>Organization/Person</h3>
                                        <p style={{ fontSize: "13px", fontWeight: "300" }}>Caption</p>
                                        <p style={{ fontSize: "13px", fontWeight: "300" }}>Followers</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: "10px 20px" }}>
                                <div className='dflex_al_jc'>
                                    <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                        <p>Created a Post</p>
                                    </div>
                                    <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                        <p>Activity time</p>
                                    </div>
                                </div>
                                <div className='mt-10' style={{ display: "flex" }}>
                                    <div>
                                        <img src={Vollmond} height="65px" width="65px">
                                        </img>
                                    </div>
                                    <div className='mt-10' style={{ color: "#000000" }}>
                                        <h3 style={{ fontSize: "14px", fontWeight: "400" }}>Post Meta data</h3>
                                        <p style={{ fontSize: "13px", fontWeight: "300" }}>Caption</p>
                                        <p style={{ fontSize: "13px", fontWeight: "300" }}>Followers</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: "10px 20px" }}>
                                <div className='dflex_al_jc'>
                                    <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                        <p>Registered For a event</p>
                                    </div>
                                    <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                        <p>Activity time</p>
                                    </div>
                                </div>
                                <div className='mt-10' style={{ display: "flex" }}>
                                    <div>
                                        <img src={Vollmond} height="65px" width="65px">
                                        </img>
                                    </div>
                                    <div className='mt-10' style={{ color: "#000000" }}>
                                        <h3 style={{ fontSize: "15px", fontWeight: "400" }}>Event Title</h3>
                                        <p style={{ fontSize: "13px", fontWeight: "300" }}>Timing</p>
                                        <p style={{ fontSize: "13px", fontWeight: "300" }}>Organisers</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            {/* Publications */}

            {/* <div className='mt-15 publications_text'>
                                    <h2>Publications(8)</h2>
                                </div>
                                <div className=" mt-15 bg_dark_blue research_article dflex_al_jc">
                                    <div className="research_text sidebar__lh ">Research Articles (12)</div>
                                    <div className='see__all'><h4>seeAll</h4></div>
                                </div>
                                <div className='border_img sidebar__padding'>
                                    <div className='article_text'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h5>Authors 1, Author 2, Author 3</h5>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                    <div className='article_text mt-10'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h6>Authors 1, Author 2, Author 3</h6>
                                        <h6>Organisation/ Institution of Primary author</h6>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                </div>

                                <div className=" mt-15 bg_dark_blue research_article dflex_al_jc">
                                    <div className="research_text sidebar__lh ">Docsteth Published (4)</div>
                                    <div className='see__all'><h4>seeAll</h4></div>
                                </div>
                                <div className='border_img sidebar__padding'>
                                    <div className='article_text'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h5>Authors 1, Author 2, Author 3</h5>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                    <div className='article_text mt-10'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h6>Authors 1, Author 2, Author 3</h6>
                                        <h6>Organisation/ Institution of Primary author</h6>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                </div>

                                <div className=" mt-15 bg_dark_blue research_article dflex_al_jc">
                                    <div className="research_text sidebar__lh ">Patient Education Resources (121)</div>
                                    <div className='see__all'><h4>seeAll</h4></div>
                                </div>
                                <div className='border_img sidebar__padding'>
                                    <div className='article_text'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h5>Authors 1, Author 2, Author 3</h5>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                    <div className='article_text mt-10'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h6>Authors 1, Author 2, Author 3</h6>
                                        <h6>Organisation/ Institution of Primary author</h6>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                </div>
                                <div className=" mt-15 bg_dark_blue research_article dflex_al_jc">
                                    <div className="research_text sidebar__lh ">Evidence Review (92)</div>
                                    <div className='see__all'><h4>seeAll</h4></div>
                                </div>
                                <div className='border_img sidebar__padding'>
                                    <div className='article_text'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h5>Authors 1, Author 2, Author 3</h5>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                    <div className='article_text mt-10'>
                                        <h4>Article title must be a full title that can take one to two
                                            lines</h4>
                                        <h6>Authors 1, Author 2, Author 3</h6>
                                        <h6>Organisation/ Institution of Primary author</h6>
                                        <div className='d-flex url_text justify_content'>
                                            <p>DOI:<span style={{ color: "#6A75FD" }}>http://dx.doi.org/10.7554/eLife.00778</span></p>
                                            <p>DOP 12 Mar 2020</p>
                                        </div>
                                    </div>
                                </div> */}

            {/* accomplishment */}

            {/* <Accomplishment /> */}

            {/* <div className=" sidebar__mt"> */}

            {/* PAges */}
            {/* <div className="sidebar__stat sidebar_column  height_pages bg__white">
                                        <div>
                                            {(myPages) ? ((myPages ?? []).map((item, ind) => {
                                                return (
                                                    <div key={ind} className="sidebar_statPage" onClick={() => history.push(`/admin/organization/${item.id}`)}>
                                                        <div className="sidebar_pages" style={{ alignItems: "center" }}>
                                                            <div ><Avatar style={{ height: "32px", width: "32px", borderRadius: "7px" }} src={item.logo ? item.logo : Doc} /></div>
                                                            <div className="sidebar_pagesName capitalize">
                                                                <b style={{ fontSize: "16px" }} className='cursor d-flex e=align'>{item.name}</b>
                                                                {/* <p className="fontItalic "> Name</p>
                                                                <p className="fontItalic"> {item.captionAndTagline}</p>
                                                            </div>
                                                        </div>
                                                        <p className="sidebar_countPAge" style={{ display: "flex", alignItems: "center", marginTop: "0px" }}>{item.count}</p>
                                                    </div>
                                                )
                                            })) : <p>If your managing an organisation build page for your organisation </p>}
                                        </div>
                                    </div> */}

            {/* </div> */}
            <div></div>
          </div>

          {/*----------------- TABLET && MOBILE-----------------*/}

          <div className="small_screens">
            <div
              className="profile__header1"
              id="smallscreens"
              style={{ backgroundColor: "white" }}
            >
              <div className="profile__left">
                <div className="show-image">
                  <Avatar src={user.photo || profimage} id="profile__pic" />
                  <p className="cover-link">
                    <label
                      htmlFor="img1"
                      className="follow-link font-weight add-cover cursor"
                    >
                      <span className="edit-icon" id="profile_edit_icon">
                        <EditIcon className="editcursor" />
                      </span>
                    </label>
                  </p>
                </div>
              </div>
              <div className="profile_details">
                <Grid container>
                  <Grid item xs={12}>
                    <div className="details__were description_height">
                      <div
                        className="bg__white"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* name */}
                        <div
                          className="sidebar__stat1"
                          style={{ width: "55%" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <span
                              className="sidebar__title capitalize"
                              id="user_title"
                            >
                              {user.text}
                            </span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <p
                              className="sidebar__title"
                              style={{
                                fontSize: "12px",
                                paddingTop: "0px",
                                paddingBottom: "2px",
                                fontWeight: "",
                                color: "rgb(54, 69, 79)",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {user.caption?.replace(/,/g, " , ")}
                            </p>
                          </div>
                          <div style={{ paddingBottom: "2px" }}>
                            {state.practioner &&
                              (state.practioner?.address?.[0]?.city ||
                                state?.practioner?.address?.[0]?.state ||
                                state?.practioner?.address?.[0]?.country) ? (
                              <div
                                style={{
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <span
                                  className="sidebar__title"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "rgb(54, 69, 79)",
                                  }}
                                >
                                  {state.hasOwnProperty("practioner")
                                    ? state.practioner.address[0].city
                                    : ""}
                                </span>
                                {state.practioner &&
                                  state?.practioner?.address?.[0]?.city &&
                                  state?.practioner?.address?.[0]?.state
                                  ? ","
                                  : ""}{" "}
                                <span
                                  className="sidebar__title"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "rgb(54, 69, 79)",
                                  }}
                                >
                                  {state.hasOwnProperty("practioner")
                                    ? state.practioner?.address?.[0]?.state
                                    : ""}
                                </span>
                                {state.practioner &&
                                  state.practioner?.address?.[0]?.state &&
                                  state?.practioner?.address?.[0]?.country
                                  ? ","
                                  : ""}{" "}
                                <span
                                  className="sidebar__title"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "rgb(54, 69, 79)",
                                  }}
                                >
                                  {state.hasOwnProperty("practioner")
                                    ? state.practioner?.address?.[0]?.country
                                    : ""}
                                </span>
                              </div>
                            ) : (
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "rgb(54, 69, 79)",
                                }}
                              >
                                N/A
                              </p>
                            )}
                          </div>

                          {
                            // state?.followersCount && <div style={{ fontSize: "16px", fontWeight: "400", color: "rgb(54, 69, 79)" }}>{`${state.followersCount} + Followers`}</div>
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "rgb(54, 69, 79)",
                                paddingTop: "2px",
                              }}
                            >
                              {Number(state.followersCount) >= 0
                                ? `${state.followersCount}  Followers`
                                : ""}
                            </div>
                          }
                          {/* 
                                            {

                                                <div style={{ fontSize: "12px", fontWeight: "500", color: "rgb(54, 69, 79)", paddingTop: "2px" }}>{Number(state.followersCount) >= 0 ? `${state.followersCount} + Followers` : ""}</div>
                                            } */}
                          {/* <div>
                                                {
                                                    state?.followersCount ? state?.followersCount + " " + 'Followers' : ""

                                                }
                                            </div> */}
                        </div>
                        <div style={{ width: "45%" }}>
                          {/* {
                                                        usersDatas?.resource?.active === true ?
                                                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                                <Button className='verify_text'><img src={Verified} style={{ paddingRight: "10px" }} width="20px" height="20px"></img>Verified</Button>
                                                            </div>
                                                            :
                                                            usersDatas?.resource?.active === false && (usersDatas?.resource?.identifier === null || state?.practioner?.identifier?.length >= 3) ?
                                                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                                    <Button className='error_text'>
                                                                        <ErrorIcon className='error_icon' />

                                                                        Pending</Button>
                                                                </div>
                                                                :
                                                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "10px" }}>
                                                                    <Button onClick={() => setView(true)} className='error_text'><ErrorIcon className='error_icon' />
                                                                      
                                                                        Get Verified
                                                                 
                                                                    </Button>
                                                                </div>

                                                    } */}
                        </div>

                        {/* 
                                        {
                                            (

                                                state?.practioner?.active === false && (state?.practioner?.identifier === null || state?.practioner?.identifier?.length <= 2)
                                            ) ?

                                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "10px" }}>
                                                    <Button onClick={() => setView(true)} className='error_text'><ErrorIcon className='error_icon' />
                                                        Get Verified
                                             
                                                    </Button>
                                                </div>
                                                :
                                                (
                                                    state?.practioner?.active === false
                                                ) ?
                                                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                        <Button className='error_text'>
                                                            <ErrorIcon className='error_icon' />

                                                            Pending</Button>
                                                    </div>
                                                    :
                                                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                        <Button className='verify_text'><img src={Verified} style={{ paddingRight: "10px" }} width="20px" height="20px"></img>Verified</Button>
                                                    </div>
                                        } */}
                      </div>

                      {/* new flow for  hprId */}

                      <DialogBox
                        open={view}
                        handleDialog={() => setView(false)}
                        Title={`Get Verified With Ayushman Bharath Digital Health Mission`}
                        onClose={() => setView(false)}
                        Content={
                          <>
                            <Divider />
                            <ValidatorForm onSubmit={() => submitHpr()}>
                              {/* <Typography>Get your HPR ID</Typography> */}
                              <h3 className="mt1">Existing HPR - User</h3>
                              <div className="flx-center">
                                <Grid container spacing={2}>
                                  <Grid item xs={10}>
                                    <CustomInput
                                      type="Number"
                                      placeholder="Enter 14 Digits HPR ID"
                                      Icon={<>@hpr.abdm</>}
                                      value={hprId}
                                      name="userName"
                                      handleChange={(name, value) =>
                                        setHprId(value?.slice(0, 14))
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      type="submit"
                                      sx={{ color: "red" }}
                                      className={!hprId ? "" : "clr-btn"}
                                      variant={"contained"}
                                      disabled={hprId.length !== 14}
                                    // onClick={initHPR}
                                    >
                                      {"Submit"}
                                    </Button>
                                  </Grid>
                                </Grid>
                                {/* <p style={{ flex: props.popup ? 1 : 0.3 }}> */}

                                {/* </p> */}
                                {/* <div className="ml2" style={{ flex: 0.4 }}> */}

                                {/* </div> */}
                              </div>
                            </ValidatorForm>
                          </>
                          // Content={<Add state={state} handleChange={handleChange} handleOrg={handleOrg} cancel={() => handleCancel()} handleSubmit={edit ? handleUpdate : handleAdd} submit={edit ? "Update" : "Add"} check={check} handleCheck={(e) => setCheck(e.target.checked)} handlecity={handlecity}
                          // />
                        }
                      />

                      {/* <div className='place_icon'>
                                            <div className='icon_visibility'>
                                                <div style={{ paddingLeft: '25px' }}>
                                                    <img src={call} alt="" onClick={handleshowphone} style={{ fontSize: "16px" }} />
                                                </div>
                                                <div style={{ paddingLeft: '25px' }}>
                                                    <img src={Mail} alt="" onClick={handleshowemail} style={{ fontSize: "16px" }} />
                                                </div>
                                                <div style={{ paddingLeft: '25px' }}>
                                                    <img src={location_on} alt="" onClick={handleshowlocation} style={{ fontSize: "16px" }} />
                                                </div>
                                                <div style={{ paddingLeft: '25px' }}>
                                                    <img src={birthday} alt="" onClick={handleshowbirthday} style={{ fontSize: "16px" }} />
                                                </div>
                                            </div>

                                            <ShowMobilenum handleshowphone={mobile} handleclosephone={handleclosephone} ></ShowMobilenum>
                                            <ShowEmail handleshowemail={email} handlecloseemail={handlecloseemail}></ShowEmail>
                                            <ShowLocation handleshowlocation={area} handlecloselocation={handlecloselocation}></ShowLocation>
                                        <Showdateofbirth handleshowbirthday={birth} handleclosedateofbirth={handleclosedateofbirth}></Showdateofbirth> */}
                    </div>
                  </Grid>
                </Grid>

                <div
                  className="e-align d-flex"
                  style={{
                    justifyContent: "",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  <div>
                    <IconButton
                      aria-label="visible"
                      onClick={() => {
                        setEdit(true);
                      }}
                    >
                      <div className="edit_btn">
                        <img width="14px" height="14px" src={edit1}></img>
                        <span>Edit Profile</span>
                        {/* <FiEdit3 style={{ color: "rgb(46, 59, 67)" }}></FiEdit3> */}
                      </div>
                    </IconButton>
                  </div>
                  <div>
                    <a
                      className="setting-btn"
                      href={`${process.env.REACT_APP_API_ACCOUNTS}`}
                      target="_blank"
                    >
                      <img width="18px" height="18px" src={Settings}></img>
                      <span>Settings</span>
                    </a>
                  </div>
                  <div>
                    <Button
                      className="button_share_icon"
                      variant="outlined"
                      onClick={handleClickOpen}
                    >
                      <img
                        src={shareicon}
                        alt="share"
                        width="18px"
                        height="18px"
                      />
                    </Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <Box>
                        <Typography
                          sx={{
                            p: "15px",
                            backgroundColor: "#007dcd",
                            color: "white",
                          }}
                        >
                          Invite Your Peers
                        </Typography>
                        <CustomRow>
                          <WhatsappShareButton
                            windowWidth="800px"
                            windowHeight="800px"
                            url={url1}
                            title={title}
                            onShareWindowClose={() => handleClose}
                          >
                            <CustomButton name="share Profile">
                              <WhatsAppIcon
                                className="inside_icon"
                                sx={{ color: "black" }}
                              />
                            </CustomButton>
                          </WhatsappShareButton>

                          <TwitterShareButton
                            windowWidth="800px"
                            windowHeight="800px"
                            url={url1}
                            title={title}
                          >
                            <CustomButton name="share in Twitter">
                              <TwitterIcon
                                className="inside_icon"
                                sx={{ color: "black" }}
                              />
                            </CustomButton>
                          </TwitterShareButton>
                        </CustomRow>

                        <CustomRow>
                          <LinkedinShareButton
                            url={`https://www.docsteth.com/myprofile/${name}-${id}`}
                            title={`${state.fullName} - ${user.caption} | Docsteth `}
                            summary={`${state.about}`}
                          >
                            <CustomButton name="share in Linkedin">
                              <LinkedInIcon
                                className="inside_icon"
                                sx={{ color: "black" }}
                              />
                            </CustomButton>
                          </LinkedinShareButton>
                          <FacebookShareButton
                            windowWidth="800px"
                            windowHeight="800px"
                            url={url1}
                            quote={`${state.fullName} - ${user.caption} | Docsteth `}
                            hashtag="#docsteth"
                          >
                            <CustomButton name="share in Facebook">
                              <FacebookIcon
                                className="inside_icon"
                                sx={{ color: "black" }}
                              ></FacebookIcon>
                            </CustomButton>
                          </FacebookShareButton>
                        </CustomRow>

                        <CustomRow true={true}>
                          <EmailShareButton
                            url={`https://www.docsteth.com/myprofile/${name}-${id}`}
                            separator={"    =>  "}
                            subject={`${state.fullName} - ${user.caption} | Docsteth `}
                            body={`${state.about}`}
                          >
                            <CustomButton name="Email Invite">
                              <EmailIcon
                                className="inside_icon"
                                sx={{ color: "black" }}
                              />
                            </CustomButton>
                          </EmailShareButton>
                          {/* <CustomButton name="SMS">
                                                            <SmsIcon className='inside_icon' sx={{ color: 'black' }} />
                                                            </CustomButton> */}
                          <div onClick={HandleCopy}>
                            <CustomButton name="Copy Invite">
                              <ContentCopyIcon
                                className="inside_icon"
                                sx={{ color: "black" }}
                              />
                            </CustomButton>
                          </div>
                        </CustomRow>

                        <Typography
                          onClick={handleClose}
                          className="textCenter font-bold cursor"
                          sx={{
                            mt: 1,
                            p: "10px",
                            backgroundColor: "#e4eef5",
                            color: "#0f3f5d",
                          }}
                        >
                          Done
                        </Typography>
                      </Box>
                    </Dialog>
                  </div>
                  {/* <div style={{ marginRight: "15px" }}>
                                        <MoreVertIcon></MoreVertIcon>
                                    </div> */}
                </div>
              </div>
              {/* <div className='badges_responsive'>
                                    <div className="sidebar__icon1">
                                        <div className="sidebar__title sidebar__pb fw__normal">Certifications</div>
                                        <img src={certificate} alt="certificate" />
                                        
                                    </div>
                                    <div className="sidebar__icon1">
                                        <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                                        <img src={ribbon} alt="certificate" />
                                        
                                    </div>
                                    <div className="sidebar__icon1">
                                        <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                                        <img src={medal} alt="certificate" />
                                        
                                    </div>
                                    <div className="sidebar__statNumber1">
                                        <IconButton aria-label="visible" onClick={() => setEdit(true)}>
                                            <EditIcon style={{ fontSize: "20px", color: '#057fcd' }} />
                                        </IconButton>
                                    </div>
                                </div> */}
              {/* </div>
                            </div> */}
            </div>
            <div className="profile__info border_img" id="profile_tablet">
              <ul>
                <li
                  className={`${active == 0 ? "profile-active1" : ""}`}
                  onClick={() => handleActive("0")}
                >
                  Overview
                </li>
                <li
                  className={`${active == 1 ? "profile-active1" : ""}`}
                  onClick={() => handleActive("1")}
                >
                  Timeline
                </li>
                <li
                  className={`${active == 2 ? "profile-active1" : ""}`}
                  onClick={() => handleActive("2")}
                >
                  Publications
                </li>
                <li
                  className={`${active == 3 ? "profile-active1" : ""}`}
                  onClick={() => handleActive("3")}
                >
                  Bookmark
                </li>
              </ul>
            </div>
            {/* test */}

            {showvalue ? (
              <div className="bg__white ">
                <ProfileInfo
                  phoneDetails={phone}
                  emailDetails={contactemail}
                  addressDetails={address}
                  profileQualif={qualificationDetail}
                  setFnView={setView}
                  setHprRest={setHprId}
                />
                {/* <div className='bg__white border_img'> */}

                {/* About */}
                <div>
                  <div className=" border_img mv1" id="about__height">
                    <div className="sidebar__padding">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="ta__center">
                          <div
                            className="font-caption fw__semibold sidebar__lh"
                            style={{
                              fontSize: "18px",
                              color: "rgb(35, 122, 196)",
                              fontWeight: "550",
                            }}
                          >
                            About
                          </div>
                        </div>
                        <div className="sidebar__statNumber">
                          <IconButton
                            aria-label="visible"
                            onClick={() => setAbout(true)}
                          >
                            <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                            {/* <EditIcon style={{ fontSize: "16px", color: '#057fcd' }} /> */}
                          </IconButton>
                        </div>
                      </div>
                      <div className="sidebar__stat bg__white">
                        {/* {(user.about) ?
                                            <>
                                                <span>{isReadMore && user.about ? user.about.substr(0, 300) : user.about}</span>
                                                <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "600", fontSize: "14px" }}>{isReadMore && user.about.length > 300 ? "...Read More" : ""}</span>
                                            </> :
                                            <p className="about___text">what would you like to share about you ?</p>
                                        } */}
                        {stateUsserNonFhr?.about ? (
                          <>
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                display: isReadMore ? "-webkit-box" : "",
                                WebkitBoxOrient: "vertical ",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 2,
                                lineswhiteSpace: "normal",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {isReadMore &&
                                stateUsserNonFhr?.about?.length > 300
                                ? stateUsserNonFhr.about.substr(0, 300)
                                : stateUsserNonFhr.about}
                            </span>
                            {/* <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "400", fontSize: "14px" }}>{isReadMore && stateUsserNonFhr?.about?.length > 300 ? " ...Read More" : !isReadMore && stateUsserNonFhr?.about?.length > 300 ? " ...Read Less" : ""}</span> */}
                          </>
                        ) : (
                          <p className="about___text">
                            Write some thing about your self ?
                          </p>
                        )}

                        {/* 
                                                {(stateUsserNonFhr?.about) ?
                                                    <>
                                                        <div> <span>{isReadMore && stateUsserNonFhr?.about?.length > 300 ? stateUsserNonFhr?.about?.substr(0, 300) : stateUsserNonFhr?.about}</span>
                                                            <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "600", fontSize: "14px", }}>{isReadMore && stateUsserNonFhr?.about?.length > 300 ? " ...Read More" : !isReadMore && stateUsserNonFhr?.about?.length > 300 ? " ...Read Less" : ""}</span></div>
                                                    </> :
                                                    <p className="about___text">what would you like to share about you ?</p>
                                                } */}
                        {/* {console.log(user.about)} */}
                        {/* <p style={{ overflowY: 'auto', overflowWrap: 'break-word' }}>{user.about}</p> */}
                      </div>
                    </div>
                    {stateUsserNonFhr?.about &&
                      stateUsserNonFhr?.about.length > 300 && (
                        <Card
                          className="p1"
                          style={{
                            borderTop: "1px solid lightgrey",
                            borderTopRightRadius: "0px",
                            borderTopLeftRadius: "0px",
                          }}
                        >
                          {isReadMore && (
                            <div
                              className="flx-center cursor"
                              style={{ justifyContent: "center" }}
                              onClick={toggleReadMore}
                            >
                              <span className="fntSm14 fntSemiBold">
                                Show More
                              </span>{" "}
                              <span style={{ height: "15px" }}>
                                <KeyboardArrowDownIcon fontSize="" />
                              </span>
                            </div>
                          )}{" "}
                          {!isReadMore && (
                            <div
                              className="flx-center cursor"
                              style={{ justifyContent: "center" }}
                              onClick={toggleReadMore}
                            >
                              <span className="fntSm14 fntSemiBold">
                                Show Less
                              </span>{" "}
                              <span style={{ height: "15px" }}>
                                <KeyboardArrowUpIcon fontSize="" />
                              </span>
                            </div>
                          )}
                        </Card>
                      )}
                  </div>
                </div>
                {/* experience */}

                <Experience />

                {/* Education */}
                <div className="border_img mv1">
                  <Education />
                </div>

                {/* accomplishment */}
                <div className="border_img">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 20px",
                    }}
                  >
                    <img
                      src={accomplishments}
                      style={{ marginRight: "10px" }}
                      width="25px"
                      height="25px"
                    />
                    <span className="acc_Heading_text">Accomplishments</span>
                  </div>
                  <div>
                    <Accomplishment />
                  </div>
                </div>

                {/* Linked Accounts */}
                {/* <div className="sidebar__padding bg__white mt-10" style={{ paddingBottom: "20px" }}>
                                    <div style={{ backgroundColor: "white" }}>
                                        <div>
                                            <h3 className='fw__normal' style={{ color: "#5119A2", fontSize: "18px", color: "#000000", display: "flex", alignItems: "center" }}>
                                                <img style={{ paddingRight: "15px", paddingLeft: "10px" }} src={LinkedAccount}></img>Linked Accounts</h3>
                                        </div>
                                        <div>
                                            <div className='pt-2' style={{ display: "flex", alignItems: "center" }}>
                                                <div>
                                                    <img src={Medxperts} height="60px" width="60px"></img>
                                                </div>
                                                <div style={{ paddingLeft: "8px" }}>
                                                    <h3 style={{ fontSize: "18px", fontWeight: "500" }}>Medxperts</h3>
                                                    <h3 className='link_text'>MedxpertsAppointments</h3>
                                                    <h5 className='link_text'>www.Medxperts.in/Venkatreddy</h5>
                                                </div>
                                            </div>
                                            <div className='pt-2' style={{ display: "flex", alignItems: "center" }}>
                                                <div>
                                                    <img src={Idimage} height="50px" width="50px"></img>
                                                </div>
                                                <div style={{ paddingLeft: "18px" }}>
                                                    <h3 style={{ fontSize: "18px", fontWeight: "500" }}>Medxperts</h3>
                                                    <h3 className='link_text'>Research Publications</h3>
                                                    <h5 className='link_text'>www.orcid.org/0392999..</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div > */}
                {/* RecentActivity */}

                {/* <div className='border_img' >
                                    <div className='recent_activity dflex_al_jc' style={{ padding: "15px" }}>
                                        <h2 style={{ fontSize: "18px", fontWeight: "400" }}>Recent Activity (28)</h2>
                                        <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                                    </div>
                                    <div style={{ padding: "10px 20px" }}>
                                        <div className='dflex_al_jc'>
                                            <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                                <p>Started following</p>
                                            </div>
                                            <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                                <p>Activity time</p>
                                            </div>
                                        </div>
                                        <div className='mt-10' style={{ display: "flex" }}>
                                            <div>
                                                <img src={Vollmond} height="65px" width="65px">
                                                </img>
                                            </div>
                                            <div className='mt-10' style={{ color: "#000000" }}>
                                                <h3 style={{ fontSize: "15px", fontWeight: "400" }}>Organization/Person</h3>
                                                <p style={{ fontSize: "13px", fontWeight: "300" }}>Caption</p>
                                                <p style={{ fontSize: "13px", fontWeight: "300" }}>Followers</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "10px 20px" }}>
                                        <div className='dflex_al_jc'>
                                            <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                                <p>Created a Post</p>
                                            </div>
                                            <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                                <p>Activity time</p>
                                            </div>
                                        </div>
                                        <div className='mt-10' style={{ display: "flex" }}>
                                            <div>
                                                <img src={Vollmond} height="65px" width="65px">
                                                </img>
                                            </div>
                                            <div className='mt-10' style={{ color: "#000000" }}>
                                                <h3 style={{ fontSize: "14px", fontWeight: "400" }}>Post Meta data</h3>
                                                <p style={{ fontSize: "13px", fontWeight: "300" }}>Caption</p>
                                                <p style={{ fontSize: "13px", fontWeight: "300" }}>Followers</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "10px 20px" }}>
                                        <div className='dflex_al_jc'>
                                            <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                                <p>Registered For a event</p>
                                            </div>
                                            <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                                <p>Activity time</p>
                                            </div>
                                        </div>
                                        <div className='mt-10' style={{ display: "flex" }}>
                                            <div>
                                                <img src={Vollmond} height="65px" width="65px">
                                                </img>
                                            </div>
                                            <div className='mt-10' style={{ color: "#000000" }}>
                                                <h3 style={{ fontSize: "15px", fontWeight: "400" }}>Event Title</h3>
                                                <p style={{ fontSize: "13px", fontWeight: "300" }}>Timing</p>
                                                <p style={{ fontSize: "13px", fontWeight: "300" }}>Organisers</p>
                                            </div>

                                        </div>
                                    </div >
                                </div> */}

                {/* publication */}

                {/* < div className='sidebar__padding' style={{ backgroundColor: "white" }
                                }>
                                    <h1 style={{ fontSize: "18px", fontWeight: "400" }}>Publications</h1>
                                    <div className="sidebar__pt">
                                        <div className="space_btw_center" style={{ padding: "8px" }}>
                                            <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Research Articles (8)</h4>
                                            <img src={Navigate} width="20px" height="20px"></img>
                                        </div>
                                        <div className="space_btw_center" style={{ padding: "8px" }}>
                                            <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Patient Education Articles (3)</h4>
                                            <img src={Navigate} width="20px" height="20px"></img>
                                        </div>
                                        <div className="space_btw_center" style={{ padding: "8px" }}>
                                            <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Docsteth Articles (!4)</h4>
                                            <img src={Navigate} width="20px" height="20px"></img>
                                        </div>
                                        <div className="space_btw_center" style={{ padding: "8px" }}>
                                            <h4 className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>Evidence Review (9)</h4>
                                            <img src={Navigate} width="20px" height="20px"></img>
                                        </div>
                                    </div>
                                </div> */}

                {/* Contact */}
                {/* < div className='border_img mt-10' > */}
                {/* <h3 className='fw__normal' style={{ fontSize: "18px", fontWeight: "550", color: "rgb(35, 122, 196)", padding: "12px 12px 0px 20px" }}>Contact Details</h3> */}
                {/* <div className='sidebar__padding' style={{ backgroundColor: "white" }}> */}

                {/* <div className="sidebar__stat sidebar__pt ">
                                            <div className="sidebar__substat">
                                                <img src={location_on} alt="" style={{ fontSize: "16px" }} />
                                                <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                    {address.city || address.state || address.country ?
                                                        <>
                                                            <span>{address.city ? address.city : ''}</span>{" "}

                                                            {address.city && address.state ? ',' : ''}<span>{address.state ? address.state + ',' : ''}</span>{" "}

                                                            <span>{address.country ? address.country : ''}</span>

                                                        </> : 'N/A'}
                                                </div>
                                            </div>
                                        </div> */}
                {/* <div className="sidebar__stat sidebar__pt">
                                            <div className="sidebar__substat">
                                                <img src={location_on} alt="" style={{ fontSize: "16px" }} />
                                                <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>

                                                    {address.city ?
                                                        <div className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>{address.city}</div>
                                                        :
                                                        <div className='sidebar__ml'>
                                                            <h2 style={{ fontSize: "16px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                                        </div>
                                                    }
                                                </div>
                                            </div> */}
                {/*cake */}
                {/* <div className="sidebar__stat sidebar__pt">
                                            <div className="sidebar__substat sidebar__pt">
                                                <img src={birthday} alt="" style={{ fontSize: "16px" }} />
                                                <div className="sidebar__title sidebar__ml" style={{ fontSize: "16px", fontWeight: "400" }}>{user.birthDate ? moment(user.birthDate).format('DD-MM-YYYY') : 'N/A'}</div>
                                            </div>
                                        </div> */}

                {/* email */}
                {/* <div className="sidebar__stat pt-2 sidebar__pt ">
                                            <div className="sidebar__substat ta__center">
                                                <img src={MailIcon} alt="emailicon" style={{ fontSize: "16px" }} />
                                                <div className="sidebar__ml">
                                                    <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                        {contactemail[0]?.value}{" "}
                                                    </span>
                                                    {contactemail.length > 1 ?
                                                        <span onClick={showmore} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                            {"+" + (contactemail.length - 1) + "more"}
                                                        </span> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div> */}
                {/* <Dialog
                                            open={dialogopen}
                                            onClose={handledialogclose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <Box className='contact_details'>
                                                <div className='space_btw_center'>
                                                    <h5>Contact Details</h5>
                                                    <ClearIcon className='cursor' onClick={handledialogclose} />
                                                </div>
                                                <div className="pt-2 sidebar__pt ">
                                                    <ul className="ta__center" id="mobilecontact_list">
                                                        {contactemail.map((element, index) => {
                                                            return (
                                                                <div className="mt-10" key={index}>
                                                                    <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                        {element.value}
                                                                    </li>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </Box>
                                        </Dialog> */}
                {/* phone */}
                {/* <div className="sidebar__stat pt-2 sidebar__pt ">
                                            <div className="sidebar__substat ta__center">
                                                <img src={call} alt="emailicon" style={{ fontSize: "16px" }} />
                                                <div className="sidebar__ml">
                                                    <span style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                        {phone[0]?.value}{" "}
                                                    </span>
                                                    {phone.length > 1 ?
                                                        <span onClick={handlephone} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                            {"+" + (phone.length - 1) + "more"}
                                                        </span> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div> */}
                {/* <Dialog
                                            open={dialogphone}
                                            onClose={handlenumberclose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        // PaperProps={{ sx: { position: "fixed", top: 10, left: 10, m: 0 } }}
                                        >
                                            <Box className='contact_details'>
                                                <div className='space_btw_center'>
                                                    <h5>Contact Details</h5>
                                                    <ClearIcon className='cursor' onClick={handlenumberclose} />
                                                </div>
                                                <div className="pt-2 sidebar__pt ">
                                                    <ul className="ta__center" id="mobilecontact_list">
                                                        {phone.map((element, index) => {
                                                            return (
                                                                <div className="mt-10" key={index}>
                                                                    <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                                        {element.value}
                                                                    </li>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </Box>
                                        </Dialog> */}
                {/* <div className='mt-20'>
                                            <h3 className='fw__normal' style={{ color: "#5119A2", fontSize: "18px" }}>Social Accounts</h3>
                                        </div>
                                        <div className='mt-10' style={{ display: "flex", alignItems: "center" }}>
                                            <div>
                                                <TwitterIcon style={{ color: 'rgb(29, 155, 240)', width: "25px", height: "25px", paddingRight: "10px" }} />
                                            </div>
                                            <div>
                                                <FacebookIcon style={{ color: '#385898', width: "25px", height: "25px", paddingRight: "10px" }} />
                                            </div>
                                            <div>
                                                <LinkedInIcon style={{ color: '#007DCD', width: "25px", height: "25px", paddingRight: "10px" }} />
                                            </div>
                                            <div>
                                                <WhatsAppIcon style={{ color: 'green', width: "25px", height: "25px", paddingRight: "10px" }} />
                                            </div>
                                        </div> */}
                {/* <div className='mt-20'>
                                        <span style={{ display: "flex", alignItems: "center" }}><img src={Globe} width="25px" height="25px" style={{ paddingRight: "10px" }}></img>Website</span>
                                    </div> */}
                {/* </div> */}
                {/* </div > */}

                <LinkAccount />

                {/* Communication */}
                <div
                  className="sidebar__padding bg__white border_img mt-10"
                  style={{ paddingBottom: "20px" }}
                >
                  {/* <div>
                                        <h3 className='fw__normal' style={{ color: "#5119A2", fontSize: "18px", color: "#000000", display: "flex", alignItems: "center" }}><img style={{ paddingRight: "15px" }} src={LinkedAccount}></img>Linked Accounts</h3>
                                    </div */}
                  <div
                    className="mt-10"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div>
                      <img src={Languageimg} height="30px" width="30px"></img>
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "rgb(35, 122, 196)",
                        }}
                      >
                        Communication (Language)
                      </span>
                      <p style={{ padding: "8px 0px 0px 10px" }}>English</p>
                    </div>
                  </div>
                </div>
                <div className="sidebar__mt mt-10 border_img">
                  {/* Pages */}
                  <div className="sidebar__mt">
                    <div className="sidebar__stat sidebar__padding">
                      <div className="sidebar__substat ta__center">
                        <div
                          className="font-caption fw__semibold sidebar__lh"
                          style={{
                            fontSize: "18px",
                            fontWeight: "550",
                            color: "#237AC4",
                          }}
                        >
                          Organisation Pages
                        </div>
                      </div>
                      <div className="sidebar__statNumber">
                        <IconButton
                          aria-label="visible"
                          onClick={() => setPage(true)}
                        >
                          <img src={add} alt="add" />
                        </IconButton>
                      </div>
                    </div>
                    {/* PAges */}
                    <div className="sidebar__stat sidebar_column  height_pages bg__white">
                      <div>
                        {myPages ? (
                          (myPages ?? []).map((item, ind) => {
                            return (
                              <div
                                key={ind}
                                className="sidebar_statPage"
                                onClick={() =>
                                  history.push(`/admin/organization/${item.id}`)
                                }
                              >
                                <div
                                  className="sidebar_pages"
                                  style={{ alignItems: "center" }}
                                >
                                  <div>
                                    <Avatar
                                      style={{
                                        height: "32px",
                                        width: "32px",
                                        borderRadius: "7px",
                                      }}
                                      src={item.logo ? item.logo : Doc}
                                    />
                                  </div>
                                  <div className="sidebar_pagesName cursor">
                                    <b
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "16px",
                                        textTransform: "capitalize",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {item.name}
                                    </b>
                                    <p className="fontItalic captntag">
                                      {" "}
                                      {item.captionAndTagline}
                                    </p>
                                  </div>
                                </div>
                                <p
                                  className="sidebar_countPAge"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "0px",
                                  }}
                                >
                                  {item.count}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <p>
                            If your managing an organisation build page for your
                            organisation{" "}
                          </p>
                        )}
                      </div>
                      {/* <p>count</p> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={alertSnack.open}
        onClose={() => setAlertSnack({ open: false, msg: "" })}
        message={alertSnack.msg}
      />
    </div>
  );
}
