import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';

const filter = createFilterOptions();

export default function AutocompleteForm({ getOptions, freeSolo, ...props }) {
  const [value, setValue] = useState(props?.multiple ? [] : '')

  useEffect(() => {

    if (props?.multiple) {
      setValue(props?.value && props?.value?.isArray() ? props?.value : [])
    }
    else {
      setValue(props?.value && !props?.value?.isArray() ? props?.value : '')
    }

  }, [props?.multiple])

  return (
    <Autocomplete
      multiple={props?.multiple}
      disablePortal
      freeSolo={freeSolo}
      id="combo-box-demo"
      options={props?.options?.map(option => option?.valueCoding?.display)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (freeSolo && params.inputValue !== '') {
          filtered.push(
            `${params.inputValue}`
          );
        }

        return filtered;
      }}
      value={value}
      onChange={(e, value) => { setValue(value); props.onChange(value) }}
      sx={{ '& .MuiAutocomplete-inputRoot': { paddingRight: '0px!important' }, width: { sm: '300px', xs: '100%' } }}
      // sx={{ width: '50%' }}
      renderInput={(params) => <TextValidator maxRows='1' {...props}
        variant='standard' {...params} placeholder='search options' value={value}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <SearchIcon />
          ),
        }}
      />}
    />
  );
}