import { Autocomplete, Avatar, Box, Card, debounce, Grid, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { D_API } from "../../../../../../services/APIService";
import HealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg";
import { Search } from "@mui/icons-material";
import ParticularHealthDepartmentDemo from "./particularHealthDepartDemo";

const Departments = ({ selectedDept, tabs, setSelectedServ }) => {
    const userOrg = useSelector(state => state.registration.userOrg);
    const healthDeptOrg = useSelector(state => state.registration.healthDeptDetails);
    const healthDeptLoc = useSelector(state => state.registration.healthDeptLocationDetails);
    const [listOfHealthDept, setListOfHealthDept] = useState([]);
    const [filteredHealthDept, setFilteredHealthDept] = useState([]);
    const [value, setValue] = useState(null)
    const [departmentId, setDepartmentId] = useState(null)

    useEffect(() => {
        if (tabs != 4) {
            setSelectedServ(null)
        }
    }, [])
    useEffect(() => {
        if (userOrg?.id) {

            D_API.get(`${userOrg?.id}/resource/authorization/docsteth/Organization?partof=${userOrg?.id}&type=dept`).then((res) => {
                console.log(res.data, "7878")
                let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value) : []
                setListOfHealthDept(sortList);
                // setListOfHealthDept(res?.data?.entry);
            }).catch((err) => console.log(err));
        }
    }, [healthDeptLoc, healthDeptOrg, userOrg])
    console.log(listOfHealthDept, "mani900")

    useEffect(() => {
        if (value?.length >= 3) {
            const filteredList = listOfHealthDept.filter((dept) =>
                dept?.resource?.name?.toLowerCase().includes(value.toLowerCase()) ||
                dept?.resource?.alias?.[0]?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredHealthDept(filteredList);
        } else {
            setFilteredHealthDept(listOfHealthDept); // Reset to full list when input length is less than 3
        }
    }, [value, listOfHealthDept])
    console.log(filteredHealthDept, "filteredHealthDept")

    useEffect(() => {
        if (selectedDept) {
            handleDepartment(selectedDept)
        }
    }, [selectedDept])

    const handleDepartment = (val) => {
        setDepartmentId(val)
        setValue(null)
    }
    console.log(departmentId, "val90")
    return (
        <>
            <Grid container sx={{ height: "700px", }} spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={4}
                    sx={{ height: "100%" }}
                >
                    <Card sx={{
                        padding: "5px", borderRadius: "", height: { sm: "100%", md: "100%", lg: "100%" },
                        boxShadow: "0px 0px 5px #888",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            width: "3px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#888", // Color of the scrollbar thumb
                            borderRadius: "10px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#555", // Hover color for the scrollbar thumb
                        },
                        scrollbarWidth: "thin", // Makes scrollbar thinner
                        scrollbarColor: "#888 #f0f0f0", // Thumb color and track color
                    }}>

                        <Box sx={{ width: "90%", padding: "0 4px", height: '36px', border: "1px solid black", borderRadius: "10px", margin: "7px 0 15px 12px", display: "flex", alignItems: "center" }}>
                            <Search sx={{ fontSize: "30px" }} />
                            <TextField
                                id="name"
                                sx={{
                                    width: "85%",
                                    margin: "0 0 0px 6px",
                                    "& .MuiInput-underline:before": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                        borderBottom: "none",
                                    }
                                }}
                                value={value || ""}
                                onChange={(event) => {
                                    console.log(event.target.value, "900")
                                    setValue(event.target.value)
                                }}
                                placeholder="Search Healthcare Department"
                                type="text"
                                variant="standard"
                            />
                        </Box>
                        {
                            filteredHealthDept?.length > 0 && filteredHealthDept?.map((val) => {

                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <Card sx={{
                                            padding: "5px", borderRadius: "", height: { sm: "45px", md: "45px", lg: "45px" },
                                            backgroundColor: departmentId?.id === val?.resource?.id ? "lightblue" : "none",
                                        }} className='mv1 cursor'
                                            onClick={() => {
                                                handleDepartment(val?.resource)
                                                //     history.push({
                                                //         pathname: `/admin/organization/${val?.resource?.id}/healthDepartment`,
                                                //         state: val
                                                //     }
                                                //     );
                                                //     dispatch(getUserOrg(userOrg))
                                            }}
                                        >

                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <div>
                                                    <Avatar variant="square" sx={{
                                                        width: '40px !important', height: '40px !important',

                                                    }}
                                                        src={val?.resource?.identifier?.filter((val) => val["system"] == `${process.env.REACT_APP_API_URL}v1/logo` && val["value"]?.startsWith("https"))?.[0]?.value || HealthDeptIcon} />
                                                </div>

                                                <div style={{ height: "40px", width: "100%" }}>
                                                    <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                                    <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.alias?.[0]}</div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                )
                            }
                            )
                        }

                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={8}
                    sx={{ height: "100%" }}>

                    {
                        departmentId &&
                        (
                            <Card sx={{
                                padding: "5px", borderRadius: "", height: "100%",
                                boxShadow: "0px 0px 5px #888",
                                overflowY: "scroll",
                                "&::-webkit-scrollbar": {
                                    width: "3px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#888",
                                    borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    backgroundColor: "#555",
                                },
                                scrollbarWidth: "thin",
                                scrollbarColor: "#888 #f0f0f0",
                            }}>
                                <ParticularHealthDepartmentDemo departmentId={departmentId} />
                            </Card>
                        )
                    }

                </Grid>

            </Grid >

        </>
    )
}
export default Departments