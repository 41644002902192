import { Avatar, Button, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './common.css';
import '../Layout/MyProfile/myprofile.css';
import '../Layout/MyProfile/organisation/organization.css';
import axios from 'axios';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { updateProfile } from '../../redux/Actions/actions.js';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProfile } from '../../redux/Actions/actions';
import { headers } from '../../redux/Actions/connectActions';


function CompleteProfile() {

  const state = useSelector(state => state.userProfile.userProfile)
  const [image, setimage] = useState("")
  const [display, setDisplay] = useState(false);
  const [user, setUser] = React.useState({})
  const [alert, setAlert] = useState({ open: false, msg: '', upload: false })
  const dispatch = useDispatch();

  const [identifyHpr,setIdentifyHpr]=useState("");



  // console.log(state.practioner?.photo[0], "state.....>>>>>>>>>>>>", state)
  // console.log(image, "image")
  // console.log(display, "display")
  // console.log(user, "user")

  useEffect(() => {
    if (state?.practioner?.[0]) {
      setimage(state?.practioner?.photo?.[0]?.url)
      setDisplay(state?.practioner?.photo?.[0]?.show)
    } else {
      setimage("")
      setDisplay(false)
    }

  }, [state])

  useEffect(() => {
    dispatch(fetchProfile())
  }, [])

  useEffect(() => {
    let data = state

    if (Object?.keys(data)?.length > 0) {
      let name = data?.practioner?.name?.[0]

      let addressList = [{
        text: (data?.practioner?.address?.[0]?.text) ? data?.practioner?.address?.[0]?.text : "",
        line: (data?.practioner?.address?.[0]?.line) ? data?.practioner?.address?.[0]?.line : "",
        city: (data?.practioner?.address?.[0]?.city) ? data?.practioner?.address?.[0]?.city : "",
        state: (data?.practioner?.address?.[0]?.state) ? data?.practioner?.address?.[0]?.state : "",
        postalCode: (data?.practioner?.address?.[0]?.postalCode) ? data?.practioner?.address?.[0]?.postalCode : "",
        country: (data?.practioner?.address?.[0]?.country) ? data?.practioner?.address?.[0]?.country : ""
      }]
      setUser({
        family: name?.family,
        firstName: name?.given?.[0],
        middleName: name?.given?.[1],
        prefix: name?.prefix ? name?.prefix?.[0] : '',
        suffix: name?.suffix,
        text: name?.text,
        gender: data?.practioner?.gender,
        birthDate: data?.practioner?.birthDate,
        caption: data?.captionAndTaglines,
        photo: data?.practioner?.photo?.[0]?.url,
        show: data?.practioner?.photo?.[0]?.show,
        about: data?.about,
        id: data?.id,
        keycloakId: data?.keycloakId,
        coverPic: data?.coverPic,
        address: addressList,
        qualification: (data?.practioner?.qualification) ? data?.practioner?.qualification : null,
        telecom: data?.practioner?.telecom,
        fullName: data?.fullName,
        // metadata: {}
      })


      
      let hasHprorNot = data?.practioner?.identifier?.some(
        (e) => e.system === "https://healthid.ndhm.gov.in/"
      );

      let indexOfHpr =
        data?.practioner?.identifier?.findIndex(
          (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
        ) != -1 && hasHprorNot
          ? data?.practioner?.identifier?.findIndex(
              (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
            )
          : -1;
      //   console.log(hasHprorNot,"hasHprorNot",indexOfHpr)
      let getHprId =
        hasHprorNot && indexOfHpr != -1
          ? data?.practioner?.identifier[indexOfHpr].value
          : "";
      setIdentifyHpr(getHprId ? getHprId : "");

    
    }
    if (alert?.upload) {
      setAlert({ open: true, msg: 'Uploaded Successfully', upload: false })
      // setImageCrop({ open: false, image: '' })
    }
  }, [state])




  const imageHandler = (e) => {
    if (e.target.files && e.target.files?.[0]) {
      const reader = new FileReader();
      let type = e.target.files?.[0].type?.split("/");

      reader.readAsDataURL(e.target.files?.[0]);
      reader.onload = x => {
        let file = [];
        file.push(reader.result?.split(',')?.[1])
        handlesubmit(file, type[1])
      }
    }
  };

  const handlesubmit = (Image, type) => {
    console.log(Image, "Imageeee")
    let picdata = {
      "file": Image,
      "fileType": type,
      "multiple": false,
      "subType": "profilePic",
      "type": "userProfilePics"
    }
    axios.post(`${process.env.REACT_APP_API_URL}v1/upload/file`, picdata, headers()).then(res => {
      if (res.data?.status === 200) {
        let data = { ...user }
        console.log(data, "dataa")
        data = { ...user, photo: res.data?.data?.[0], show: display }
        setimage(res.data.data?.[0])
        
    let  hprIdDetails = identifyHpr;
        dispatch(updateProfile(data, data?.telecom, data?.address, data?.qualification,"","",hprIdDetails))
      }
    })
  }

  return (
    <>
      <Grid
        container
        style={
          {
            // backgroundColor: "white",
            // boxShadow: "0px 3px 6px #00000029
          }
        }
      >
        <Grid item xs={12} md={12}>
          <div style={{ marginTop: "30px" }}>
            <h1 className="comp_profile">Complete Your Profile</h1>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <h3 className="step_mobile">Step 1 of 3</h3>
          </div>
          <div className="add_profile">
            <h2>Add Your Profile Picture</h2>
          </div>
          <div
            id="above_upload"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "red",
              padding: "10px",
            }}
          >
            <div className="profile__upload">
              <Avatar src={image ? image : ""} id="imag_profile" />
              <div
                className="upload__profile"
              // style={{ marginBottom: "100px" }}
              // style={{border:"2px solid red",backgroundColor:"yellow",paddingBottom:"10px"}}
              >
                <label
                  htmlFor="filePicker"
                  className="profile-attach"
                // style={{ paddingBottom: "50px" }}
                >
                  Upload Picture
                </label>
                <input
                  id="filePicker"
                  style={{ visibility: "hidden", display: "contents" }}
                  type={"file"}
                  onChange={imageHandler}
                />
              </div>
            </div>
          </div>
          {/* <h3 className='profile_display'>Who can see your Profile Picture</h3> */}
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormControl className='checked' component="fieldset">
                            <RadioGroup row name="row-radio-buttons-group">
                                <FormControlLabel
                                    value="Any one"
                                    control={<Radio size="small" color="primary" />}
                                    label="Any one"
                                    onChange={() => setDisplay(true)}
                                    checked={display}
                                    style={{ color: "#3E7EA5", fontWeight: "550" }}
                                />
                                <FormControlLabel
                                    value="Only Me"
                                    control={<Radio size="small" color="primary" />}
                                    label="Only Me"
                                    onChange={() => setDisplay(false)}
                                    checked={!display}
                                    style={{ color: "#3E7EA5" }}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div> */}
          <div className="skip_btn" id="next_padding">
            <Link to={`/addeducation`}>
              <Button
                className="next__btn"
                variant="contained"
                type="submit"
              // onClick={handleuploadimage}
              >
                <ArrowForwardIcon
                  style={{
                    color: "white",
                    fontSize: "18px",
                    paddingRight: "10px",
                  }}
                />
                next
              </Button>
            </Link>
          </div>
          <div className="skip_btn" style={{ marginBottom: "73px" }}>
            <Link to={`/addeducation`}>
              <Button
                style={{
                  color: "#47AEE1",
                  textTransform: "capitalize",
                  margin: "20px 0px",
                }}
                type="submit"
              >
                Skip
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default CompleteProfile