import { Grid } from '@mui/material'
import React from 'react'
import MarketPlaceSidebar from '../MarketPlacePage/MarketPlaceSidebar'
import SubscriptionAppVault from './SubscriptionAppVault'


function SubscriptionReviewPage() {
    return (
       <Grid container spacing={3}>
           <Grid item xs={3} style={{marginTop:'10px'}}>
                <MarketPlaceSidebar/>
           </Grid>

           <Grid item xs={9}>
               <SubscriptionAppVault/>
           </Grid>
       </Grid>
    )
}

export default SubscriptionReviewPage
