import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid } from '@mui/material'
import Post from '../Post/Post';
import URL from "../../apiurl";
import { headers } from "../../../redux/Actions/connectActions";

const Hashtagpost = (props) => {
    // console.log(props.location.state)
    const tag = props?.location?.state
    const [post, setPost] = useState([])


    useEffect(() => {
        showhashtag()
    }, [])

    const showhashtag = () => {
        // console.log(tag, "fghjklfghjk")
        const family = tag?.split('#');
        // console.log(family)
        const hashname = family?.[1];
        // console.log(hashname)
        axios.get(`${URL.findhashtag}/${hashname}`, headers()).then(res => {
            // console.log(res.data.data)
            setPost(res.data.data)
        })
    }

    return (
        <>
            <Grid container>
                <Grid item md={2} xs={12}>

                </Grid>
                <Grid item md={8} xs={12}>
                    <div className="hashpost_margin">
                        {
                            post?.map(post => (
                                <div key={post.id}>
                                    <Post
                                        post={post}
                                    // getPosts={() => { setAlert({ open: true, msg: 'Deleting...', post: true }); getPosts() }} 
                                    />
                                </div>
                            ))
                        }
                    </div>
                </Grid>
                <Grid item md={2} xs={12}>

                </Grid>

            </Grid>

        </>
    )
}
export default Hashtagpost