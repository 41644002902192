
import React, { useState, useEffect } from "react";
import { Grid, Switch } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Actions, OrgLayout } from "../../../../../common/components/layout";
import { D_API, TERMINOLOGY_VALUESET } from "../../../../../../../src/services/APIService";
import { FormDivider } from "../../../../../utility/utils";
import { useDispatch, useSelector } from "react-redux";
import { URI } from "../../../../../utility/uri";
import { deleteKeys } from "../../../../../common/functions/functions";
import { handleHealthDeptLoc } from "../../../../../../../src/redux/Actions/regActions";

// switchTabs,
const HealthDeptLocation = ({ clsn, switchTabs, healthid, Reset }) => {


    const orgData = useSelector(state => state.registration.userOrgData);
    const orgLoc = useSelector(state => state.registration.location);
    console.log(orgLoc, "orgLoc")

    const healthDeptInfo = useSelector((state) => state.registration.healthDeptDetails);
    const healthDeptLoc = useSelector(state => state.registration.healthDeptLocationDetails);
    console.log(orgData, "orgData1")


    console.log(healthDeptInfo, "healthDeptInfo")

    console.log(healthDeptLoc, "healthDeptLoc")






    // useEffect(() => {

    // }, [orgData])

    const [locationDetails, setLocationDetails] = useState({
        locName: "",
        locDescription: "",
        locServiceType: null,
        locPhysicalType: null,
        address: null,
        contact: null,
    })
    const [managingOrgInfo, setManagingOrgInfo] = useState("");
    const [managingOrgLocInfo, setManagingOrgLocInfo] = useState("");
    const [loc, setLoc] = useState({ lat: "", log: "" });


    // const [parentOrgDetails, setParentOrgDetails] = useState({
    //     managingOrgInfo: "",
    //     managingOrgLocInfo: "",
    // })
    const [physicalLocList, setPhysicalLocList] = useState([]);
    const [modeStatus, setModeStatus] = useState(false);
    const [showPhysicalRes, setShowPhysicalRes] = useState(false);
    const [locationServiceTypeList, setLocationServiceTypeList] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (healthDeptInfo?.id) {
            setLocationDetails((prev) => {
                return {
                    ...prev, locName: healthDeptInfo?.name ? healthDeptInfo?.name : "",
                    contact: healthDeptInfo?.telecom ? healthDeptInfo.telecom : null,
                }
            })
            D_API.get(`${orgData?.id}/${URI.getLocation}${healthDeptInfo?.id}`).then((res) => {
                console.log(res.data, "++")
                console.log(res.data)
                let data = res.data?.entry?.[0]?.resource;
                dispatch(handleHealthDeptLoc(data))
            }).catch((err) => console.log(err))
        }


    }, [healthDeptInfo])



    useEffect(() => {
        let url = "http://terminology.hl7.org/ValueSet/v3-ServiceDeliveryLocationRoleType"
        TERMINOLOGY_VALUESET.get(`$expand?url=${url}`).then((res) => {
            setLocationServiceTypeList(res.data?.expansion?.contains)
        }).catch((err) => console.log(err))

    }, [])




    useEffect(() => {
        if (orgData?.id) {
            D_API.get(`${orgData?.id}/Organization/${orgData?.id}`).then((res) => {
                setManagingOrgInfo(res?.data)
            }).catch((err) => console.log(err));
            D_API.get(`${orgData?.id}/${URI.getLocation}${orgData?.id}`).then((res) => {
                setManagingOrgLocInfo(res.data.entry?.[0]?.resource)
            })
        }

    }, [orgData?.id])


    useEffect(() => {


        setLocationDetails((prev) => {
            return {
                ...prev, locDescription: healthDeptLoc?.healthDeptLoc ? healthDeptLoc?.healthDeptLoc : "",
                locPhysicalType: healthDeptLoc?.physicalType?.coding?.[0] ? healthDeptLoc?.physicalType?.coding?.[0] : null,
                locDescription: healthDeptLoc?.description ? healthDeptLoc?.description : "",
                address: healthDeptLoc?.address?.line ? healthDeptLoc?.address?.line?.[0] : null,
                position: `${healthDeptLoc?.position?.latitude ? healthDeptLoc?.position?.latitude : ""}${healthDeptLoc?.position?.longitude ? `,${healthDeptLoc?.position?.longitude}` : ""}`,
                locServiceType: healthDeptLoc?.type?.[0]?.coding?.[0] ? healthDeptLoc?.type?.[0]?.coding?.[0] : ""
            }
        })

        // setModeStatus(healthDeptLoc?.mode == "kind" ? true : false);

    }, [healthDeptLoc])



    // if (modeStatus == false && res.data.entry?.[0]?.resource?.position) {

    //     setLoc({})

    // }

    console.log(managingOrgInfo, managingOrgLocInfo, "6666")


    // useEffect(() => {
    //     if (orgData.id) {
    //         D_API.get(`Organization / ${ orgData?.id }`).then((res) => {
    //             setManagingOrgInfo(res.data)
    //         }).catch((err) => console.log(err));
    //         D_API.get(URI.getLocation`${ orgData?.id }`).then((res) => {
    //             setManagingOrgLocInfo(res.data)

    //         })
    //         console.log("hii")
    //     }
    // }, [orgData])


    useEffect(() => {
        getLocPhysicalType();
    }, [])

    useEffect(() => {
        modeStatus == true ? setShowPhysicalRes(true) : setShowPhysicalRes(false);
    }, [modeStatus])


    // const handleLocandLog = (val) => {

    //     setLoc()
    // }

    const getLocPhysicalType = () => {
        let val = "http://hl7.org/fhir/ValueSet/location-physical-type"
        TERMINOLOGY_VALUESET.get(`$expand?url=${val}`).then((res) => {
            setPhysicalLocList(res.data?.expansion?.contains)
            console.log(res.data?.expansion?.contains, "physicalList")
        }).catch((err) => console.log(err))
    }

    const handleModeStatus = (event) => {
        setModeStatus(event.target.checked)
    }


    const handleLocDetails = (name, value) => {
        setLocationDetails((prev) => {
            return { ...prev, [name]: value }
        })
    }

    console.log(modeStatus, "modeStatus")

    console.log(locationDetails, "locationDetails")



    const handleSubmit = () => {

        let data = {

            "resourceType": "Location",
            status: "inactive",
            ...(healthDeptLoc?.id && { id: healthDeptLoc?.id }),

            name: locationDetails?.locName ? locationDetails?.locName : "",
            description: locationDetails?.locDescription ? locationDetails?.locDescription : "",
            // name: address.line1,
            // line: [`${address.line1 && address.line1}`, `${address.line2 && address.line2}`, `${address.district && address.district}`, `${loc.lat && loc.lat}`, `${loc.lng && loc.lng}`],
            // address: {
            //     ...(address.line1 && { line: address.line1 }),
            //     ...(address.line2 && { line: address.line2 }),
            //     ...(address.state && { state: address.state }),
            //     ...(address.district && { district: address.district }),
            //     ...(address.subDistrict && { city: address.subDistrict }),
            //     ...(address.postalCode && { postalCode: address.postalCode }),
            //     ...(address.country && { country: address.country }),
            //     line: [address.line1, address.line2, address.subDistrict, address.location].filter(Boolean),
            //     use: 'work',
            //     type: 'physical',
            // },
            // ... ((loc.lng && loc.lat) && { position: { "longitude": loc.lng, "latitude": loc.lat } }),
            type: locationDetails.locServiceType?.display ? [{ coding: [{ ...locationDetails.locServiceType }] }] : "",
            // mode: modeStatus == true ? "kind" : "instance",
            mode: "kind",
            telecom: orgData?.telecom ? orgData.telecom : "",
            managingOrganization: {
                "reference": `${"Organization" + "/" + healthDeptInfo?.id
                    }`
            },
            partOf: {
                "reference": `${"Location" + "/" + managingOrgLocInfo?.id}`
            },
            address: locationDetails.address ? {
                line: [`${locationDetails.address} `]
            } : "",
            ...((locationDetails.position && locationDetails.position?.split(",")?.[1] && locationDetails.position?.split(",")?.[0]) && { position: { "longitude": parseFloat(locationDetails.position?.split(",")?.[1]), "latitude": parseFloat(locationDetails.position?.split(",")?.[0]) } }),
            ...(locationDetails.locPhysicalType && {
                physicalType: {
                    coding: [
                        { ...locationDetails.locPhysicalType }
                    ]
                }

            }),

            // address: modeStatus == true && locationDetails.address ? {
            //     line: [`${locationDetails.address} `]
            // } : "",
            // ...((modeStatus == true && locationDetails.position && locationDetails.position?.split(",")?.[1] && locationDetails.position?.split(",")?.[0]) && { position: { "longitude": parseFloat(locationDetails.position?.split(",")?.[1]), "latitude": parseFloat(locationDetails.position?.split(",")?.[0]) } }),
            // ...((modeStatus == true && locationDetails.locPhysicalType) && {
            //     physicalType: {
            //         coding: [
            //             { ...locationDetails.locPhysicalType }
            //         ]
            //     }

            // }),
            ...(healthDeptLoc?.hoursOfOperation && { hoursOfOperation: healthDeptLoc.hoursOfOperation })
        }

        let val = deleteKeys({ ...data })
        console.log(val, "val")


        if (healthDeptLoc?.id) {
            D_API.put(`${orgData?.id}/${URI.addLocation}/${healthDeptLoc?.id}`, val).then(res => {
                // console.log(res.data, "02")
                D_API.get(`${orgData?.id}/${URI.addLocation}/${res.data}`).then(res => {
                    dispatch(handleHealthDeptLoc(res.data));
                    switchTabs();
                }).catch((err) => alert(err));

            }).catch((err) => {
                console.log(err)
            })
        } else {
            D_API.post(`${orgData?.id}/${URI.addLocation}`, val).then((res) => {
                console.log(res.data)
                D_API.get(`${orgData?.id}/${URI.addLocation}/${res.data}`).then(res => {
                    dispatch(handleHealthDeptLoc(res.data));
                    switchTabs();
                }).catch((err) => alert(err));
            }).catch((err) => {
                console.log(err);
                alert(err);
            })

        }




        // D_API.post(URI.uploadhealthDepartment, val).then((res) => {
        //     console.log(res.data, "after post")
        //     D_API.get(`Organization/${res.data}`).then((res) => {
        //         dispatch(handleHealthDeptLoc(res.data));
        //         switchTabs();
        //         console.log(res.data)
        //     }).catch((err) => console.log(err));

        // }).catch(err => {
        //     alert(err)
        // })
    }




    function adjustRows() {
        const textarea = document.getElementById('myTextarea');
        const rows = textarea.value.split('\n').length;
        textarea.rows = rows;
    }




    return (
        <div className="tohideScrollbar">
            <ValidatorForm onSubmit={handleSubmit}>
                <Grid
                    container
                    spacing={2}
                    className="ph1 tohideScrollbar "
                    sx={{ height: "", overflow: "auto", }}
                >

                    <Grid item xs={12} sm={12} md={3}>
                        <div>Location Name:</div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <div>{locationDetails.locName}</div>
                    </Grid>


                    {/* <Grid item xs={12}>
                        Location Description
                    </Grid> */}

                    {/* <Grid item xs={12}>
                        <textarea name="locDescription" value={locationDetails.locDescription} onChange={(e) => handleLocDetails("locDescription", e.target.value)} placeholder="Write something here" autoFocus id="myTextarea" rows="3" onInput={() => adjustRows()} className="w-100 ss_textarea .tohideScrollbar"
                            style={{
                                boxShadow: "2px 3px 10px lightgrey",
                                borderRadius: "10px",
                                backgroundColor: "white",
                                width: "100%",
                                fontSize: "16px",
                                lineHeight: "1.5", 
                                padding: "10px",
                            }}
                        ></textarea>
                    </Grid> */}

                    <OrgLayout
                        label="Location Service Type"
                        input="select"
                        name="locServiceType"
                        value={locationDetails.locServiceType}
                        options={locationServiceTypeList}
                        getOptionLabel={(option) => option?.display || ""}
                        handleChange={handleLocDetails}
                        placeholder="Select Type of Location"
                    />

                    {/* <Grid item xs={12} sm={12} md={3}>
                        <div>Location PartOf:</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <div>{managingOrgInfo?.name}</div>
                    </Grid> */}

                    {/* <Grid item xs={12} sm={12} md={3}>
                        <div>Managing Organisation :</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <div>{managingOrgInfo?.name}</div>
                    </Grid> */}

                    {/* <Grid item xs={12}>
                        <div>Has Physical Location</div>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <Switch
                            checked={modeStatus == true ? true : false}
                            value={modeStatus}
                            onChange={handleModeStatus}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <span>mode:{modeStatus == true ? "kind" : "instance"}</span>
                    </Grid> */}



                    <OrgLayout
                        label="Physical Ward"
                        input="select"
                        name="locPhysicalType"
                        value={locationDetails.locPhysicalType}
                        options={physicalLocList || []}
                        getOptionLabel={(option) => option.display || ""}
                        handleChange={handleLocDetails}
                        placeholder="Select Physical Type"

                    />
                    <OrgLayout
                        label="Address"
                        name="address"
                        value={locationDetails.address}
                        handleChange={handleLocDetails}
                        placeholder="Enter Address"
                    />
                    <OrgLayout
                        label="Position(Lat,long)"
                        name="position"
                        value={locationDetails.position}
                        handleChange={handleLocDetails}
                        placeholder="exact in this format ex:17.06737,20.0998"
                    />



                    {/* 
                    {
                        showPhysicalRes && (
                            <>
                                <OrgLayout
                                    label="Physical Ward"
                                    input="select"
                                    name="locPhysicalType"
                                    value={locationDetails.locPhysicalType}
                                    options={physicalLocList || []}
                                    getOptionLabel={(option) => option.display || ""}
                                    handleChange={handleLocDetails}
                                    placeholder="Select Physical Type"

                                />
                                <OrgLayout
                                    label="Address"
                                    name="address"
                                    value={locationDetails.address}
                                    handleChange={handleLocDetails}
                                    placeholder="Enter Address"
                                />
                                <OrgLayout
                                    label="Position(Lat,long)"
                                    name="position"
                                    value={locationDetails.position}
                                    handleChange={handleLocDetails}
                                    placeholder="exact in this format ex:17.06737,20.0998"
                                />
                            </>
                        )
                    } */}


                    <Grid item xs={12}></Grid>
                </Grid>
                <FormDivider />

                <Actions
                    close={() => {
                        clsn();
                        Reset();
                    }}
                    name={healthid ? "Update" : "Create"}
                />
            </ValidatorForm>



        </div >
    )
}
export default HealthDeptLocation;