
import React, { useState } from "react";
import { Box, Grid, IconButton, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import "./particularMediaGallery.css";
import DeleteDeptGalleryDialog from "../addContent/deleteDeptGalleryDialog";

const ParticularMediaGallery = ({ particularItem, close, images, setItem, getGallery }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmDelDialog, setOpenConfirmDelDialog] = useState(false);
    const [deletingObjInfo, setDeletingObjInfo] = useState(null);

    const open = Boolean(anchorEl);



    const getpostedUpdatedList = () => {
        getGallery();
    }


    const handleOpenMenu = (event, obj) => {
        setAnchorEl(event.currentTarget);
        setDeletingObjInfo(obj);
    };

    const handleClose = () => {
        setAnchorEl(null);

    };



    const handleOpenDeleteGallery = () => {
        setOpenConfirmDelDialog(true);
    }

    const handleClsDeleteGallery = () => {
        setOpenConfirmDelDialog(false);
    }


    return (



        <div >

            <div className="p1">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CloseIcon onClick={close} style={{ cursor: 'pointer' }} />
                </div>
            </div>


            <Grid container sx={{ height: 'calc(100vh - 108px)', overflow: "scroll", '&::-webkit-scrollbar': { display: 'none' } }}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: "10px",
                            padding: "10px",
                            boxSizing: "border-box",
                            maxHeight: 'calc(100vh - 108px)',
                            overflow: "scroll",
                            '&::-webkit-scrollbar': { display: 'none' }
                        }}
                    >
                        {images?.map((val, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: "7px",
                                    height: "100px",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                }}
                                onClick={() => {
                                    if (val.id !== particularItem.id) {
                                        setItem(val);
                                    }
                                }}
                            >
                                {val.mediaType === "IMAGE" && (
                                    <img
                                        src={val.sourceLink}
                                        alt={`image`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'fit',
                                            objectPosition: 'center',
                                            display: 'block',
                                            borderRadius: "7px",
                                        }}
                                    />
                                )}
                                {val.mediaType === "VIDEO" && val?.sourceLink !== null && (
                                    <video
                                        style={{ backgroundColor: 'black', borderRadius: "7px", height: '100%' }}
                                        width="100%"
                                        height="100%"
                                        src={val?.sourceLink}
                                        controlsList="nodownload"
                                        controls
                                    />
                                )}
                            </div>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                    <div style={{ overflow: "hidden", paddingTop: "10px" }}>
                        <div style={{ position: "relative" }}>
                            <div style={{
                                position: "absolute",
                                background: "#777C81",
                                opacity: 0.7,
                                borderRadius: "50%",
                                right: 10,
                                top: 10,
                                width: 40,
                                height: 40,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                zIndex: 1000,

                            }}>
                                <MoreVertIcon onClick={(e) => handleOpenMenu(e, particularItem)} />
                            </div>

                        </div>

                        <div id="view">
                            <Typography className="description_ele" sx={{
                                width: {
                                    xs: 'calc(100vw - 100px)',
                                    sm: 'calc(100vw - 300px)',
                                    md: 'calc(100vw - 320px)',
                                    lg: 'calc(100vw - 430px)',
                                }
                            }}>
                                {particularItem?.description}
                            </Typography>

                            {particularItem?.mediaType === "IMAGE" && (
                                <img
                                    src={particularItem?.sourceLink}
                                    alt={`image`}
                                    style={{
                                        height: 'calc(100vh - 118px)',
                                        width: '100%',
                                        display: 'block',
                                        objectFit: "cover",
                                        objectPosition: "center"
                                    }}
                                />

                            )}
                            {particularItem?.mediaType === "VIDEO" && particularItem?.sourceLink !== null && (
                                <video
                                    style={{ backgroundColor: 'black', width: '100%', height: 'calc(100vh - 118px)', }}
                                    src={particularItem?.sourceLink}
                                    controlsList="nodownload"
                                    controls
                                />
                            )}
                        </div>

                    </div>
                </Grid>

            </Grid >

            {/* <div style={{ display: "flex", }}>
                <div style={{
                    width: "260px",
                    height: "calc(100vh - 110px)",
                    overflow: "hidden",
                    padding: "5px",
                }}>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: "10px",
                            padding: "10px",
                            boxSizing: "border-box",
                        }}
                    >
                        {images?.map((val, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: "7px",
                                    height: "100px",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                }}
                                onClick={() => {
                                    if (val.id !== particularItem.id) {
                                        setItem(val);
                                    }
                                }}
                            >
                                {val.mediaType === "IMAGE" && (
                                    <img
                                        src={val.sourceLink}
                                        alt={`image`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            display: 'block',
                                            borderRadius: "7px",
                                        }}
                                    />
                                )}
                                {val.mediaType === "VIDEO" && val?.sourceLink !== null && (
                                    <video
                                        style={{ backgroundColor: 'black', borderRadius: "7px", height: '100%' }}
                                        width="100%"
                                        height="100%"
                                        src={val?.sourceLink}
                                        controlsList="nodownload"
                                        controls
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ width: 'calc(100% - 260px)', overflow: "hidden", paddingTop: "15px", }}>
                    <div style={{ position: "relative" }}>
                        <div style={{
                            position: "absolute",
                            background: "#777C81",
                            opacity: 0.7,
                            borderRadius: "50%",
                            right: 10,
                            top: 10,
                            width: 40,
                            height: 40,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            zIndex: 1000,

                        }}>
                            <MoreVertIcon onClick={(e) => handleOpenMenu(e, particularItem)} />
                        </div>

                    </div>

                    <div id="view">

                        <p className="description_ele">
                            {particularItem?.description}
                        </p>

                        {particularItem?.mediaType === "IMAGE" && (
                            <img
                                src={particularItem?.sourceLink}
                                alt={`image`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'block',
                                    objectFit: "cover",
                                    objectPosition: "center"
                                }}
                            />
                        )}
                        {particularItem?.mediaType === "VIDEO" && particularItem?.sourceLink !== null && (
                            <video
                                style={{ backgroundColor: 'black', width: '100%', height: '100%' }}
                                src={particularItem?.sourceLink}
                                controlsList="nodownload"
                                controls
                            />
                        )}
                    </div>

                </div>
            </div> */}


            < Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}

                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleOpenDeleteGallery}>
                    <div className="flx-center">
                        <DeleteIcon
                            sx={{
                                color: "#0D80D8"
                            }} fontSize="small" />
                        <ListItemText sx={{ color: "#0D80D8" }}> Delete</ListItemText>
                    </div>
                </MenuItem>

            </Menu >


            <DeleteDeptGalleryDialog open={openConfirmDelDialog} close={handleClsDeleteGallery} delObj={deletingObjInfo} parentDialogCls={close} getUpdatedList={getpostedUpdatedList} />
        </div >

        // <div style={{
        //     height: "100%",

        // }}>

        //     <div className="p1">
        //         < div style={{ display: "flex", justifyContent: "flex-end", }}>
        //             <CloseIcon onClick={close} style={{ cursor: 'pointer' }} />
        //         </div>
        //     </div >

        //     <div style={{ display: 'flex' }}>
        //         <div

        //             style={{
        //                 width: "50%",
        //                 paddingLeft: "10px",
        //                 paddingRight: "10px",
        //                 boxSizing: "border-box",
        //                 height: "calc(100vh - 110px)",
        //                 overflowY: "scroll"
        //             }}>
        //             <div style={{
        //                 display: 'flex',
        //                 flexWrap: 'wrap',
        //                 gap: '10px',
        //             }}>
        //                 {images?.map((val, index) => {

        //                     return (
        //                         <div key={index}
        //                             className="cursor"
        //                             style={{
        //                                 borderRadius: "10px",
        //                                 overflow: "hidden",
        //                                 position: "relative",
        //                                 minWidth: "200px",
        //                                 flexGrow: 1,
        //                                 flexBasis: "calc(50% - 10px)",
        //                                 height: "auto",
        //                             }}

        //                             onClick={() => {
        //                                 if (val?.id != particularItem?.id) {
        //                                     setItem(val);
        //                                 }

        //                             }}
        //                         >
        //                             {
        //                                 val?.mediaType == "IMAGE" &&
        //                                 <img
        //                                     src={val.sourceLink}
        //                                     alt={`image-${index}`}
        //                                     style={{
        //                                         width: '100%',
        //                                         height: '100%',
        //                                         objectFit: 'cover',
        //                                         objectPosition: 'center',
        //                                         display: 'block'
        //                                     }}
        //                                 />
        //                             }
        //                             {val?.mediaType == "VIDEO" &&
        //                                 val?.sourceLink !== null && <video style={{ backgroundColor: 'black' }} width="100%" height="100%" src={val?.sourceLink} controlsList="nodownload" controls />
        //                             }



        //                         </div>
        //                     );
        //                 })}
        //             </div>
        //         </div>

        //         <div style={{
        //             width: "50%",
        //             // height: "100%",
        //             overflow: "hidden"
        //         }}>
        //             <div
        //                 className="cursor"
        //                 style={{
        //                     backgroundColor: "#F1F3F4",
        //                     display: 'flex',
        //                     alignItems: 'center',
        //                     justifyContent: 'center',
        //                     width: "100%",
        //                     height: '100%'

        //                 }}>
        //                 <div style={{
        //                     width: "350px",
        //                     height: "350px",
        //                     backgroundColor: "white",
        //                     position: "relative"

        //                 }}
        //                     id="view"
        //                 >
        //                     {
        //                         particularItem?.mediaType == "IMAGE" &&
        //                         <img
        //                             src={particularItem?.sourceLink}
        //                             alt="particularitem"
        //                             style={{
        //                                 width: "100%",
        //                                 height: "100%",
        //                                 objectFit: "cover",
        //                                 objectPosition: "center"
        //                             }}
        //                         />
        //                     }

        //                     {particularItem?.mediaType == "VIDEO" &&
        //                         particularItem?.sourceLink !== null && <video style={{ backgroundColor: 'black' }} width="100%" height="100%" src={particularItem?.sourceLink} controlsList="nodownload" controls />
        //                     }
        //                     <p className="description_ele">{particularItem?.description?.length > 45 ? particularItem?.description?.slice(0, 45) + "...." : particularItem?.description}</p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

    );
};

export default ParticularMediaGallery;