import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogBox({ open, handleDialog, Title, Content, Actions, style, width, headerStyle }) {
    return (
        <Dialog open={open} onClose={handleDialog} fullWidth maxWidth={width ? width : "sm"}>
            {Title ?
                <DialogTitle style={headerStyle}>
                    {Title}
                </DialogTitle> : ''}
            {/* <IconButton
                aria-label="close"
                onClick={"handleClose"}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton> */}
            <DialogContent style={style}>
                {Content}
            </DialogContent>
            {Actions ?
                <DialogActions>
                    {Actions}
                </DialogActions> : ''}
        </Dialog>
    )
}