import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography, Box } from '@mui/material';
import '../MyProfile/updateprofile.css';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import URL from '../../apiurl';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import moment from 'moment';
import description from '../../../images/article.svg'
import DisplayAcc from '../MyProfile/organisation/DisplayAcc';
import UserService from '../../../services/UserService';
import { FiEdit3 } from 'react-icons/fi';
import Linksource from '../../../icons/linksource.svg';
import { GrAddCircle } from 'react-icons/gr';
import Dialog from "@mui/material/Dialog";
import { useDispatch } from 'react-redux';
import celebration from '../../../images/celebration.svg';
import MobileStepper from '@mui/material/MobileStepper';
import InputOption from "../../Molecule/Feed/InputOption";
import ClearIcon from '@mui/icons-material/Clear';
import Downloadicon from '../../../icons/downloadicon.svg';
import Accomplishments from '../MyProfile/organisation/accomplishments';
import { accomplishment, getMetaData } from '../../../redux/Actions/actions';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableViews from 'react-swipeable-views';
import { saveAs } from 'file-saver'
import { useHistory } from 'react-router-dom';
import CreatePost from '../../Molecule/Feed/createpost';
import './profile.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { headers } from '../../../redux/Actions/connectActions';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const CustomSlider = ({ carouselState }) => {
//     let value = 0;
//     let carouselItemWidth = 0;
//     const [state, setState] = useState("")
//     if (Carousel) {
//         carouselItemWidth = Carousel.state.itemWidth;
//         const maxTranslateX = Math.round(
//             // so that we don't over-slide
//             carouselItemWidth *
//             (Carousel.state.totalItems -
//                 Carousel.state.slidesToShow) +
//             150
//         );
//         value = maxTranslateX / 100; // calculate the unit of transform for the slider
//     }
//     const { transform } = carouselState;
//     return (
//         <div className="custom-slider">
//             <input
//                 type="range"
//                 value={Math.round(Math.abs(transform) / value)}
//                 defaultValue={0}
//                 max={
//                     (carouselItemWidth *
//                         (carouselState.totalItems - carouselState.slidesToShow) +
//                         (state.additionalTransfrom === 150 ? 0 : 150)) /
//                     value
//                 }
//                 onChange={e => {
//                     if (Carousel.isAnimationAllowed) {
//                         Carousel.isAnimationAllowed = false;
//                     }
//                     const nextTransform = e.target.value * value;
//                     const nextSlide = Math.round(nextTransform / carouselItemWidth);
//                     if (
//                         e.target.value == 0 &&
//                         state.additionalTransfrom === 150
//                     ) {
//                         Carousel.isAnimationAllowed = true;
//                         setState({ additionalTransfrom: 0 });
//                     }
//                     Carousel.setState({
//                         transform: -nextTransform, // padding 20px and 5 items.
//                         currentSlide: nextSlide
//                     });
//                 }}
//                 className="custom-slider__input"
//             />
//         </div>
//     );
// };

function ProfilePublication() {
    const theme = useTheme();
    const maxWidth = useMediaQuery('(max-width: 961px)')
    const minWidth = useMediaQuery('(min-width: 960px)')
    const [state, setState] = useState([])
    const [activeStep, setActiveStep] = React.useState(0);
    const [count, setCount] = useState(3)
    const [jrcount, setJrcount] = useState(3)
    const [patientcount, setPatientcount] = useState(5)
    const [journal, setJournal] = useState([])
    const [media, setMedia] = useState([])
    const [isReadMore, setIsReadMore] = useState(true);
    const [open, setOpen] = useState(false)
    const [badges, setBadges] = useState([])
    const [form, setForm] = useState(3)
    const [Patientdata, setPatientdata] = useState([])
    const [publication, setPublication] = useState({})
    const [postopen, setpostopen] = useState(0)
    const history = useHistory()
    const dispatch = useDispatch()
    const toggleReadMore = () => {
        // console.log("hello")
        setIsReadMore(!isReadMore);
    };
    // const handlePopup = (key) => {
    //     setKeyState(key)
    // }
    const sub = () => {
        // console.log("hello")
    }

    const handleAddAcc = async (data, image, metatags, meta) => {
        // console.log(data, image, metatags, meta)
        let submitdata = {
            "date": data.date ? (moment(data.date).format('YYYY-MM-DD')) : new Date(),
            "accomplishmentCategory": data.accomplishmentCategory.value,
            "summary": data.summary || metatags?.description || meta?.description,
            "attachment": image || metatags?.image || meta?.image,
            "title": data.title || metatags?.title || meta?.title,
            "userId": Number(sessionStorage.getItem("userId")),
            "status": "SHOW",
            "metadata": await getMetaData(),
            "coAuthors": data.coAuthors,
            "link": data.link,
            "organisation": {
                "displayName": data.organisationName,
                "id": data.organisation,
                "system": ""
            }
        }
        axios.post(`${URL.addAccomplishment}`, submitdata, headers())
            .then((response) => response.data)
            .then(data => {
                // console.log(data.data)\
                setpostopen(1)
                setBadges(data.data)
                dispatch(accomplishment(data.data))
                // getAcc();
            })
            .catch((error) => { })

    }

    const handleClose = () => {
        setOpen(false)
    }

    // useEffect(()=>{
    //     if(publication.title)
    // },[])

    useEffect(() => {
        axios.get(`${URL.getpublicationbyuserid}/${UserService.getUserId()}`, headers())
            .then((response) => {
                // console.log(response.data.data)
                setState(response.data.data)
            })
    }, [])

    // console.log(state)

    useEffect(() => {
        if (state.length > 0) {
            let journaldata = state.filter((element) => {
                return element.accomplishmentCategory !== "News_or_Media"
            })
            // console.log(journaldata)
            setJournal(journaldata)
        }
        if (state.length > 0) {
            let mediadata = state.filter((element) => {
                return element.accomplishmentCategory === "News_or_Media"
            })
            setMedia(mediadata)
            // console.log(mediadata)
        }
    }, [state])

    useEffect(() => {
        axios.get(`${URL.findArticleByUserId}/${UserService.getUserId()}`, headers()).then((res) => {
            // console.log(res.data.data)
            setPatientdata(res.data.data)
        })
    }, [])

    const handleopen = () => {
        // console.log("abcd")
        setOpen(true)
    }

    const handledialogclose = () => {
        // console.log("1234")
        setOpen(false)
        setpostopen(0)
        setBadges([])
    }

    const handlegoback = () => {
        setpostopen(1)
        // setOpen(false)
    }

    const handlepost = () => {
        setpostopen(2)
    }

    // const downloadfiles = (value) => {
    //     // console.log("first")
    //     const url = window.URL.createObjectURL(
    //         new Blob([value.attachment])
    //     )
    //     console.log(url)
    //     const link = document.createElement('a');
    //     link.href = url
    //     link.setAttribute(
    //         'download',
    //         'FileName.pdf'
    //     );
    //     console.log(link)
    //     link.click();
    //     link.parentNode.removeChild(link);
    // }

    // working fine for pdf not for images is opening on new tab
    const downloadfiles = (value) => {
        const filedata = value.attachment.split(".")
        const data = filedata[filedata.length - 1]
        saveAs(value.attachment)
    }

    const maxSteps = media.length;

    // const downloadfiles = (value) => {
    //     const filedata = value.attachment.split(".")
    //     const data = filedata[filedata.length - 1]
    //     axios.get(value.attachment, {
    //         responseType: 'blob',
    //     }).
    //         then((res) => {
    //             // console.log(res.data)
    //             // fileDownload(res.data, data)
    //             // console.log(fileDownload(res.data, data))
    //             res.blob().then(blob => {
    //                 let url = window.URL.createObjectURL(blob);
    //                 let a = document.createElement('a');
    //                 a.href = url;
    //                 a.download = 'employees.json';
    //                 a.click();
    //             });
    //         })
    // }

    // const downloadfiles = (value) => {
    //     console.log(value.attachment)
    //     const filedata = value.attachment.split(".")
    //     const data = filedata[filedata.length - 1]
    //     fileDownload(value.attachment, `download${data}`)
    // }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handlearticle = (value) => {
        history.push(
            {
                pathname: "/patientarticle",
                state: value
            }
        )
    }



    return (
        <div>
            {minWidth ?
                <Grid container>
                    {/* <Grid item xs={12} md={12}>
                    <Typography variant='h6' style={{ textAlign: "center" }}>Work In Progress</Typography>
                    </Grid> */}
                    <Grid item xs={12} md={4} className="margin_myprofile">
                        <div className='border_img sidebar__padding mt-10'>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <p className='gallery_text'>Media Gallery</p>
                                    <div>
                                        <IconButton aria-label="visible" onClick={handleopen}>
                                            <GrAddCircle color='#2E3B43' />
                                        </IconButton>
                                        {/* <IconButton aria-label="visible">
                                            <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                        </IconButton> */}
                                    </div>
                                </div>
                                {media.length > 0 ?
                                    <div>
                                        {media.slice(0, count).map((value, index) => {
                                            return (
                                                <div key={index} className="mt-15 border_img">
                                                    {value?.attachment ?
                                                        <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                    }
                                                    <div className='ppub' >
                                                        <div className='publication_text' style={{ width: "100%" }}>
                                                            <div style={{ width: "60%" }}>
                                                                {value?.title?.length > 80 ?
                                                                    <p>{value.title.slice(0, 80)}...</p> : <p>{value.title}</p>
                                                                }
                                                            </div>
                                                            <div style={{ width: "40%" }}>
                                                                <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                            </div>
                                                        </div>
                                                        <div className='source_text'>
                                                            {value.link.length > 80 ?
                                                                <a href={value.link} target="_blank">
                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link.slice(0, 80)}... </p>
                                                                </a> : <a href={value.link} target="_blank">
                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link}</p>
                                                                </a>
                                                            }
                                                            {value?.description?.length > 80 ?
                                                                <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                            }
                                                            {/* {isReadMore ?
                                                                <p className='sidebar__pt'>{value?.description?.slice(0, 80)}</p> : <p>{value?.description}</p>
                                                            }
                                                            {value?.description && value?.description > 80 ?
                                                                <span onClick={toggleReadMore} style={{ color: "red" }} className="read-or-hide">
                                                                    {isReadMore ? "readmore" : "showless"}
                                                                </span> : ""
                                                            } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="sidebar__substat ta__center">
                                                {media !== null && media.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(media.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                    </div>
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div className='feed_desktop mt-10'>
                            <div className="feed__inputContainer border_img">
                                <div className="feed__input cursor"
                                    onClick={() => history.push('/newarticle')}
                                >
                                    <p>Write Content and share what’s on your Mind</p>
                                </div>
                                <div className="feed__inputOptions border__rr border__lr">
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <div className="feed__inputOption" style={{ marginLeft: '0px' }}>
                                            <InputOption src={description} title="Write Article" alt="Article" handleClick={() => history.push('/newarticle')} />
                                        </div>
                                        {/* <div className="">
                                            <Button className="share_article evnt-btn"
                                                onClick={handleopen}
                                            //  onClick={() => { handlePopup('text') }}
                                            >Share Article</Button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p className='journal_publication_text'>Journal Publications</p>
                            {/* <div>
                                <IconButton aria-label="visible">
                                    <GrAddCircle color='#2E3B43' />
                                </IconButton>
                                <IconButton aria-label="visible">
                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                </IconButton>
                            </div> */}
                        </div>
                        <div className='border_img'>
                            {journal.length > 0 ?
                                <div>
                                    {journal.slice(0, jrcount).map((value, index) => {
                                        return (
                                            <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", paddingTop: "20px" }}>
                                                <div className='pl-2 pr-2' style={{ width: "85%" }}>
                                                    {(value?.accomplishmentCategory === "Original_Research_Article") ? <p className='article_description'> Original Research Article </p> :
                                                        (value?.accomplishmentCategory === "Review_Article") ? <p className='article_description'> Review Article </p> :
                                                            (value?.accomplishmentCategory === "Evidence_Review") ? <p className='article_description'> Evidence Review </p> :
                                                                (value?.accomplishmentCategory === "Case_Study_or_Series") ? <p className='article_description'> Case Study/Case Series </p> :
                                                                    (value?.accomplishmentCategory === "Patient_Education") ? <p className='article_description'> Patient Education </p> :
                                                                        (value?.accomplishmentCategory === "Patent") ? <p className='article_description'> Patent </p> : ""
                                                    }
                                                    <p className='art_head'>
                                                        {value.title ? value.title : ""}
                                                    </p>
                                                    <p className='article_description'>
                                                        {value.organisation.displayName}
                                                    </p>
                                                    {value?.coAuthors ?
                                                        <div style={{ paddingTop: "8px" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} /> : ""
                                                    }
                                                    <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "8px" }}>
                                                        {value?.link ? <a href={value.link} target="_blank"><p className='publication_dop'>DOI:<span className='link_publication'>{value?.link}</span></p></a> : ""}
                                                        {value.date ? <p className='publication_dop'>DOP: <span className='publication_date'>{moment(value.date).format('DD-MMM-yyyy')}</span></p> : ""}
                                                    </div>
                                                </div>
                                                {/* {console.log(value.attachment)} */}
                                                <div className='space_evenly_center' style={{ width: "15%" }}>
                                                    {value.attachment ?
                                                        <div onClick={() => { downloadfiles(value) }}>
                                                            {/* <a href={value.attachment} download={value.attachment} target="_blank"> */}
                                                            <img src={Downloadicon} alt="downloadicon" width="15px" height="15px" className='image_download'></img>
                                                            <Button className='download_journal'>Download</Button>
                                                            {/* </a> */}
                                                        </div>
                                                        : ""
                                                    }
                                                    <div>
                                                        <a href={value.link} target="_blank">
                                                            <img src={Linksource} alt="readicon" width="15px" height="15px" className='image_download'></img>
                                                            <Button className='read_journal'>Read</Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="sidebar__substat ta__center">
                                            {journal !== null && journal.length > 3 ? jrcount === 3 ? <p id="border_exp_btn" onClick={() => setJrcount(journal.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{journal.length - 3} More Article{(journal.length - 3) > 1 ? 's' : ''}&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setJrcount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ padding: "15px" }}>
                                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                </div>
                            }

                        </div>
                        <div className='mt-15'>
                            <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#257FC4", fontSize: "18px", padding: "10px" }}>Patient Education Articles</p>
                                {/* <IconButton aria-label="visible">
                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                </IconButton> */}
                            </div>
                            {Patientdata.length > 0 ?
                                <div className='border_img'>
                                    {Patientdata?.slice(0, patientcount)?.map((value, index) => {
                                        return (
                                            <div style={{ padding: "15px" }} key={index}>
                                                <div>
                                                    {value.articleTitle ?

                                                        <p className='article_title' onClick={() => handlearticle(value)}>{value.articleTitle}</p> : ""
                                                    }
                                                </div>
                                                <div>
                                                    {value.coAuthors ?
                                                        <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} ></p> : ""
                                                    }
                                                    {value.contributors ?
                                                        <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.contributors }} ></p> : ""
                                                    }
                                                    {value.organisation ?
                                                        <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.organisation }} ></p> : ""
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="sidebar__substat ta__center">
                                            {Patientdata !== null && Patientdata.length > 5 ? patientcount === 5 ? <p id="border_exp_btn" onClick={() => setPatientcount(Patientdata.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setPatientcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                        </div>
                                    </div>
                                </div> :
                                <div className='border_img' style={{ height: "60px" }}>
                                    <p style={{ fontSize: "18px", textAlign: "center", paddingTop: "10px", color: "#36454F" }}>No Data</p>
                                </div>
                            }

                        </div>
                        {/* <div>
                            <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#257FC4", fontSize: "24px" }}>Evidence Review</p>
                                <IconButton aria-label="visible">
                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                </IconButton>
                            </div>
                            <div style={{ height: "100px" }}>

                            </div>
                        </div> */}
                    </Grid>
                </Grid> : ""
            }
            {maxWidth ?
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <div className='border_img sidebar__padding'>
                            <div className='react_swipable_view_960'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <p className='gallery_text'>Media Gallery</p>
                                    {/* <div>
                                        <IconButton aria-label="visible" onClick={handleopen}>
                                            <GrAddCircle color='#2E3B43' />
                                        </IconButton>
                                        <IconButton aria-label="visible">
                                            <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                        </IconButton>
                                    </div> */}
                                </div>
                                {media.length > 0 ?
                                    <div>
                                        {media.slice(0, count).map((value, index) => {
                                            return (
                                                <div key={index} className="mt-15 border_img">
                                                    {value?.attachment ?
                                                        <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                    }
                                                    <div className='ppub' >
                                                        <div className='publication_text' style={{ width: "100%" }}>
                                                            <div style={{ width: "80%" }}>
                                                                {isReadMore ?
                                                                    <p>{value?.title?.slice(0, 30)}</p> : <p>{value.title}</p>
                                                                }
                                                                {value.title && value.title > 30 ?
                                                                    <span onClick={toggleReadMore} style={{ color: "red" }} className="read-or-hide">
                                                                        {isReadMore ? "readmore" : "showless"}
                                                                    </span> : ""
                                                                }
                                                            </div>
                                                            <div style={{ width: "20%", textAlign: "center" }}>
                                                                <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                            </div>
                                                        </div>
                                                        <div className='source_text'>
                                                            {value.link.length > 80 ?
                                                                <a href={value.link} target="_blank">
                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link.slice(0, 80)}... </p>
                                                                </a> : <a href={value.link} target="_blank">
                                                                    <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link}</p>
                                                                </a>
                                                            }
                                                            {value?.description?.length > 80 ?
                                                                <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="sidebar__substat ta__center">
                                                {media !== null && media.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(media.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                    </div>
                                }
                            </div>
                            <div className='react_swipable_view_769'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <p className='gallery_text'>Media Gallery</p>
                                    <div>
                                        <IconButton aria-label="visible" onClick={handleopen}>
                                            <GrAddCircle color='#2E3B43' />
                                        </IconButton>
                                        {/* <IconButton aria-label="visible">
                                            <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                        </IconButton> */}
                                    </div>
                                </div>
                                {/* <Carousel
                                    ssr={false}
                                    ref={el => (this.Carousel = el)}
                                    partialVisbile={false}
                                    customButtonGroup={<CustomSlider />}
                                    itemClass="slider-image-item"
                                    // responsive={responsive}
                                    containerClass="carousel-container-with-scrollbar"
                                    additionalTransfrom={state.additionalTransfrom}
                                    beforeChange={nextSlide => {
                                        if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
                                            this.setState({ additionalTransfrom: 150 });
                                        }
                                        if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
                                            this.setState({ additionalTransfrom: 0 });
                                        }
                                    }}
                                >
                                    {media?.map((value, index) => {
                                        return (
                                            <div key={index} className="mt-15 border_img">
                                                <img src={value.attachment} className="media_metaimage"></img>
                                                <div className='ppub' >
                                                    <div className='publication_text' style={{ width: "100%" }}>
                                                        <div style={{ width: "80%" }}>
                                                            {value?.title?.length > 80 ?
                                                                <p>{value.title.slice(0, 80)}...</p> : <p>{value.title}</p>
                                                            }
                                                        </div>
                                                        <div style={{ width: "20%", textAlign: "center" }}>
                                                            <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                        </div>
                                                    </div>
                                                    <div className='source_text'>
                                                        <p style={{ paddingTop: "5px" }}>{value.link} </p>
                                                        {value?.description?.length > 80 ?
                                                            <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Carousel> */}
                                {media.length > 0 ?
                                    <Box sx={{ maxWidth: 400, flexGrow: 1 }} style={{ margin: '0 auto' }}>
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={activeStep}
                                            onChangeIndex={handleStepChange}
                                            enableMouseEvents
                                        >
                                            {media?.map((value, index) => {
                                                return (
                                                    <div key={index} className="mt-15 border_img">
                                                        {value?.attachment ?
                                                            <img src={value?.attachment} className="media_metaimage"></img> : <img className="media_metaimage1"></img>
                                                        }
                                                        <div className='ppub' >
                                                            <div className='publication_text' style={{ width: "100%" }}>
                                                                <div style={{ width: "80%" }}>
                                                                    {value?.title?.length > 80 ?
                                                                        <p>{value.title.slice(0, 80)}...</p> : <p>{value.title}</p>
                                                                    }
                                                                </div>
                                                                <div style={{ width: "20%", textAlign: "center" }}>
                                                                    <p>DOP : {moment(value.date).format('DD-MMM-yyyy')} </p>
                                                                </div>
                                                            </div>
                                                            <div className='source_text'>
                                                                {value.link.length > 80 ?
                                                                    <a href={value.link} target="_blank">
                                                                        <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link.slice(0, 80)}... </p>
                                                                    </a> : <a href={value.link} target="_blank">
                                                                        <p className='publication_link' style={{ paddingTop: "5px" }}>{value.link}</p>
                                                                    </a>
                                                                }
                                                                {value?.description?.length > 80 ?
                                                                    <p className='sidebar__pt'>{value?.description?.slice(0, 80)}...</p> : <p>{value?.description}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </SwipeableViews>
                                        <MobileStepper
                                            style={{ backgroundColor: 'transparent' }}
                                            steps={maxSteps}
                                            position="static"
                                            activeStep={activeStep}
                                            className="mobile_swipper"
                                            nextButton={
                                                <Button
                                                    size="large"
                                                    onClick={handleNext}
                                                    disabled={activeStep === maxSteps - 1}
                                                >

                                                    {theme.direction === 'rtl' ? (
                                                        <KeyboardArrowLeft />
                                                    ) : (
                                                        <KeyboardArrowRight />
                                                    )}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                                                    {theme.direction === 'rtl' ? (
                                                        <KeyboardArrowRight />
                                                    ) : (
                                                        <KeyboardArrowLeft />
                                                    )}

                                                </Button>
                                            }
                                        />
                                    </Box>
                                    :
                                    <div style={{ padding: "15px" }}>
                                        <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                                    </div>
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginTop: "10px", marginBlockEnd: "75px" }}>
                        <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p className='journal_publication_text'>Journal Publications</p>
                            {/* <IconButton aria-label="visible">
                                <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                            </IconButton> */}
                        </div>
                        <div>
                            {journal.length > 0 ?
                                <div className='border_img'>
                                    {journal.slice(0, jrcount).map((value, index) => {
                                        return (
                                            <div key={index} style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px" }}>
                                                <div style={{ width: "65%" }}>
                                                    {(value?.accomplishmentCategory === "Original_Research_Article") ? <p className='article_description'> Original Research Article </p> :
                                                        (value?.accomplishmentCategory === "Review_Article") ? <p className='article_description'> Review Article </p> :
                                                            (value?.accomplishmentCategory === "Evidence_Review") ? <p className='article_description'> Evidence_Review </p> :
                                                                (value?.accomplishmentCategory === "Case_Study_or_Series") ? <p className='article_description'> Case Study/Case Series </p> :
                                                                    (value?.accomplishmentCategory === "Patient_Education") ? <p className='article_description'> Patient Education </p> :
                                                                        (value?.accomplishmentCategory === "Patent") ? <p className='article_description'> Patent </p> : ""
                                                    }
                                                    <p className='art_head'>
                                                        {value.title ? value.title : ""}
                                                    </p>
                                                    <p className='article_description'>
                                                        {value.organisation.displayName}
                                                    </p>
                                                    {value?.coAuthors ?
                                                        <div style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} /> : ""
                                                    }
                                                    <div style={{ alignItems: "center", paddingTop: "8px" }}>
                                                        {value?.link ? <a href={value.link} target="_blank"><p className='publication_dop'>DOI:<span className='link_publication'>{value?.link}</span></p></a> : ""}
                                                        {value.date ? <p className='publication_dop'>DOP: <span className='publication_date'>{moment(value.date).format('DD-MMM-yyyy')}</span></p> : ""}
                                                    </div>
                                                </div>
                                                {/* {console.log(value.attachment)} */}
                                                <div className='space_evenly_center' style={{ width: "35%" }}>
                                                    {value.attachment ?
                                                        <a href={value.attachment} target="_blank" download>
                                                            <img src={Downloadicon} alt="downloadicon" width="15px" height="15px" className='image_download'></img>
                                                            <Button className='download_journal'>Download</Button>
                                                        </a> : ""
                                                    }
                                                    <div>
                                                        {value.link && <a href={value.link} target="_blank">
                                                            <img src={Linksource} alt="readicon" width="15px" height="15px" className='image_download'></img>
                                                            <Button className='read_journal'>Read</Button>
                                                        </a>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="sidebar__substat ta__center">
                                            {journal !== null && journal.length > 3 ? jrcount === 3 ? <p id="border_exp_btn" onClick={() => setJrcount(journal.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{journal.length - 3} More Article{(journal.length - 3) > 1 ? 's' : ''}&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setJrcount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='border_img' style={{ height: "60px" }}>
                                    <p style={{ fontSize: "18px", textAlign: "center", paddingTop: "10px", color: "#36454F" }}>No Data</p>
                                </div>
                            }
                        </div>
                        <div className='mt-15'>
                            <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#257FC4", fontSize: "18px", padding: "10px" }}>Patient Education Articles</p>
                                {/* <IconButton aria-label="visible">
                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                </IconButton> */}
                            </div>
                            {Patientdata.length > 0 ?
                                <div className='border_img'>
                                    {Patientdata?.slice(0, patientcount)?.map((value, index) => {
                                        return (
                                            <div style={{ padding: "15px" }} key={index}>
                                                <div>
                                                    {value.articleTitle ?

                                                        <p className='article_title' onClick={() => handlearticle(value)}>{value.articleTitle}</p> : ""
                                                    }
                                                </div>
                                                <div>
                                                    {value.coAuthors ?
                                                        <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.coAuthors }} ></p> : ""
                                                    }
                                                    {value.contributors ?
                                                        <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.contributors }} ></p> : ""
                                                    }
                                                    {value.organisation ?
                                                        <p style={{ paddingTop: "8px", fontSize: "14px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: value.organisation }} ></p> : ""
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="sidebar__substat ta__center">
                                            {Patientdata !== null && Patientdata.length > 5 ? patientcount === 5 ? <p id="border_exp_btn" onClick={() => setPatientcount(Patientdata.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>See All&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setPatientcount(5)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='border_img' style={{ height: "60px" }}>
                                    <p style={{ fontSize: "18px", textAlign: "center", paddingTop: "10px", color: "#36454F" }}>No Data</p>
                                </div>
                            }
                        </div>
                        {/* <div>
                            <div className='border_img' style={{ padding: "8px 15px", display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#257FC4", fontSize: "24px" }}>Evidence Review</p>
                                <IconButton aria-label="visible">
                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                </IconButton>
                            </div>
                            <div style={{ height: "100px" }}>

                            </div>
                        </div> */}
                    </Grid>
                </Grid> : ""

            }

            <Dialog
                open={open}
                onClose={handledialogclose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <Grid container>
                        {
                            (postopen == 0) ?
                                <Grid item xs={12} md={12} style={{ width: "100%", height: "100%", padding: "20px" }}>
                                    <Accomplishments add={true} value={form} handleAdd={handleAddAcc} close={handledialogclose} publication />
                                </Grid> :
                                (postopen == 1) ?
                                    <Grid item xs={12} md={12} style={{ padding: "20px" }}>
                                        <div>
                                            <div className='cursor' style={{ display: "flex", justifyContent: "flex-end" }} onClick={handlegoback}>
                                                <ClearIcon />
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                                <img src={celebration} width="25px" height="25px"></img><span className='congo_text'>Congratulations</span>
                                            </div>
                                            <div className='acc_badge sidebar__pt'>
                                                <h1>You Have New Accomplishment Badge</h1>
                                                {/* <h1>{`${`Your Have New Accomplishment ${(filterdata.typeOfAccomplishment).toLowerCase()}`}`}</h1> */}
                                            </div>
                                            <DisplayAcc badges={badges} />
                                            <div className='space_btw_center pt-2'>
                                                <h2 style={{ color: "#4A33FF", fontSize: "18px" }}>Celebrate you Accomplishment ?</h2>
                                                <div>
                                                    <Button onClick={handleClose} className='skipp'>Skip</Button>
                                                    <Button onClick={handlepost} className='create_post_btn'>Create Post</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid> :
                                    (postopen == 2) ?
                                        <CreatePost postdata={badges} openpopup={true} handleClose={handledialogclose} />
                                        : ""
                        }
                    </Grid>
                </Box>
            </Dialog>
        </div>
    )
}

export default ProfilePublication