import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import './signup.css'
import { common } from '@mui/material/colors';
import './publicview.css'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Yashoda from "../Atom/svg/yashoda.svg";
import Practo from "../Atom/svg/practo.svg";
import Med from "../Atom/img/photo.png";
import Lyberate from "../Atom/svg/lib.svg";
import Checkbox from '@mui/material/Checkbox';
import Peers from "../Atom/svg/peers.svg";
import Link from '@mui/material/Link';
import Doc from '../../images/Logo2.svg'
import UserService from '../../services/UserService';
import { Avatar } from '@mui/material';

export default function Header(props) {

  const [open, setOpen] = useState(false)
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const Hosp = ({ image, name }) => {
    return (
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <img src={image} alt={name} />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    )
  }

  const login = (() => {
    UserService.doLogin();
  })


  return (
    <Box sx={{ flexGrow: 1 }} className="bg-header">
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle sx={{ p: 0 }}>
          <p className="book-header">Book Consultation Appointment</p>
        </DialogTitle>
        <DialogContent sx={{ p: 3, px: 0 }}>
          <div style={{ padding: '0 24px' }}>
            <p className="appointment">Select Your Platform to Book Appoinment</p>
            <List sx={{ pl: 3, mt: 1 }}>

              <Hosp image={Yashoda} name="Yashoda" />
              <Hosp image={Practo} name="Practo" />
              <Hosp image={Med} name="MedXperts" />
              <Hosp image={Lyberate} name="Lyberate" />

            </List>
          </div>
          <p className="book-header" style={{ fontSize: '18px' }}>
            <Checkbox disablePadding
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: common.white,
                '&.Mui-checked': {
                  color: common.white,
                },
              }}
            /> I'm a Doctor</p>
          <div style={{ padding: '0 24px' }}>
            <p className="appointment">Docsteth</p>
            <p className="peer-connect">
              <img src={Peers} alt='Peers' />
              <Link href="#" className='b-color'>Send Peer Connection Request</Link>
            </p>
          </div>
        </DialogContent>
      </Dialog>

      <AppBar position="static" className="tab-app">
        <Toolbar>
          <div className='' style={{ display: 'flex', alignItems: 'center', width: "60%" }}>
            {/* <img src={Logo} alt='docsteth' height='35px' width='37px'/> */}
            {props?.header ? props?.header?.user?.practioner?.photo?.[0]?.url ? <Avatar style={{ height: "40px", width: "40px", borderRadius: '7px' }} src={props?.header?.user?.practioner?.photo?.[0]?.url} /> : <img src={Doc} alt='logo' height="40px" width="40px" /> :
              <img src={Doc} alt='logo' height="40px" width="40px" />}
            {props?.header ?
              <div style={{ textAlign: "left", width: '50%' }}>
                <div className="view-header-name">
                  {props?.header?.user?.fullName}
                </div>
                <div className="header-tagline">
                  {props?.header?.user?.captionAndTaglines}
                </div>
              </div>
              : ""}
            <Button variant="outlined" className="connect-btn" onClick={() => setOpen(true)}>Connect</Button>
          </div>
          <Typography variant="h6" component="p" className="nav-header sign-color" textAlign='right'>
            <span>Site Map</span>
            <span>FAQs</span>
            <Button variant="outlined" className='sign-in-btn' onClick={login}>Sign in</Button>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
