import { AppBar, Card, CardContent, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MedscopeSideBar from './MedscopeSideBar'
import { createTheme, ThemeProvider } from '@mui/material';
import interactive_apps from '../../../images/interactive-apps.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './MedscopePageRightContainer.css';
import { Link, useLocation } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 769,
            md: 960,
            lg: 1280,
            xl: 1536,
        },
    },
});


const AppBar1 = () => {
    const { pathname } = useLocation()
    const [path, setPath] = useState([])

    useEffect(() => {
        let url = pathname.split('/')
        let loc = []
        for (let i = 2; i < url.length; i++) {
            if (url[i] !== 'home') {
                loc.push(url[i])
            }
        }
        setPath(loc)
    }, [pathname])

    return (
        <AppBar id='medscopeappbar' position="static" style={{ background: '#FFFFFF', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
            <Toolbar style={{ boxShadow: '0px 3px 6px #00000029' }}>
                <Grid container>
                    <Grid item className="w-100">
                        <div id='medscope_homepage'>
                            <div className="medscope_rightcontainer_icon1 w-100">
                                <img src={interactive_apps} alt="app_vault" width='30px' height='30px' />
                                <Typography variant="h4" color="black" className="user-data" style={{ marginLeft: '6px', fontSize: '23px' }}>
                                    <div><h4 className='d-flex e-align jc__center'><Link to="/marketplace/home"> MedScope <InfoOutlined style={{ color: '#0054B5', width: '15px', height: '15px', position: 'relative', top: '-5px' }} /></Link>
                                        {path[0] ? <> {<KeyboardArrowRightIcon />}<Link className={`specialities ${path[1] ? "" : path[0] ? 'b-color' : ''}`} to={`/marketplace/${path[0]}`}>{path[0].split('-')[0].replace(/__/g, '-').replace(/_/g, ' ')}</Link></> : ""}
                                        {path[1] ? <> <KeyboardArrowRightIcon /><Link className={`specialities ${path[2] ? "" : path[1] ? 'b-color' : ''}`} to={`/marketplace/${path[0]}/${path[1]}`}>{path[1].split('-')[0].replace(/__/g, '-').replace(/_/g, ' ')}</Link> </> : ""}
                                        {path[2] ? <> <KeyboardArrowRightIcon /><Link className={`specialities ${path[2] ? 'b-color' : ''}`} to={`/marketplace/${path[0]}/${path[1]}/${path[2]}`}>{path[2].split('-')[0].replace(/__/g, '-').replace(/_/g, ' ')}</Link> </> : ""}
                                    </h4></div>
                                </Typography>
                            </div>

                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}


const MedscopePage = (props) => {

    const screenWidth = useMediaQuery('(min-width:960px)')



    return (
        <ThemeProvider theme={theme}>
            {screenWidth ?
                <div className='largedevices'>
                    <Grid container spacing={2} sx={{ padding: "16px" }}>
                        <Grid item md={3} xs={12}>
                            <MedscopeSideBar />
                        </Grid>
                        <Grid item md={9} sx={{ width: "100%" }}>
                            <AppBar1 />
                            <Card style={{ minHeight: '80vh' }}>
                                <CardContent>
                                    {props.children}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div> :
                <div className='smalldevices'>
                    <Grid container spacing={2} sx={{ padding: "16px" }} >
                        <Grid item xs={12} sx={{ overflowX: "scroll", height: "120px" }}>
                            <MedscopeSideBar />
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ width: "100%" }}>
                            <AppBar1 />

                            {props.children}
                        </Grid>
                    </Grid>
                </div>
            }


            {/* <Grid container spacing={2} sx={{ padding: "16px" }}>
                <Grid item md={3} xs={12}>
                    <MedscopeSideBar />
                </Grid>
                <Grid item md={9} sx={{ width: "100%" }}>
                    <AppBar1 />
                    {props.children}
                </Grid>
            </Grid> */}
        </ThemeProvider>
    )

}

export default MedscopePage