import { Avatar, Typography } from "@mui/material";
import { Actions, Description } from '../Post/commonFun'
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import PublicIcon from '@mui/icons-material/Public';
import Peers from '../../Atom/svg/peers.svg';
import Doc from '../../../images/Logo2.svg'
import UserService from "../../../services/UserService";
import { timeSince } from "../Post/commonFun";

export default function ImageView(props) {
    // console.log(props)
    const { post } = props

    const data = post.userId ? post.userData : post.pageId ? post.pageData : {}

    return (
        <div style={{ padding: '15px 10px' }}>

            <div className="post__header" >
                <Avatar src={data.profilePic} style={{ borderRadius: '7px', border: '0.5px solid lightgrey', marginLeft: "10px !important" }} />
                <div className="post__info">
                    <div>
                        <h2 className="capitalize">{data.fullName}</h2>
                        <p className="capitalize fontItalic" style={{ marginTop: '3px' }}>{data.caption}</p>
                        <Typography style={{ marginTop: '4px' }} className="fabdetail e-align">
                            <span>{`${timeSince(post.createDate)}`}</span>&nbsp;
                            {post.postPrivacy === 'ANYONE' ? <PublicIcon style={{ fontSize: '19px' }} /> : post.postPrivacy === 'DOCSTETH_USERS' ? <img src={Doc} alt="DOCSTETH USERS" width="22px" /> : <img src={Peers} alt="Peers" width="30px" />}
                        </Typography>
                    </div>
                    <div style={{ float: 'right' }} className="cursor" onClick={() => props.onClose()}>
                        <CloseIcon />
                    </div>
                </div>
            </div>
            {/* {console.log(props)} */}
            <div style={{ maxHeight: '520px', overflow: 'scroll' }}>
                {post.description && <Description description={post.description} />}

                {post.postType ? props.children : ''}

                {UserService.getToken() ? <Actions post={post} getPostById={props.getPostById} openComment={true} showLikes={true} /> : ''}
            </div>
        </div>
    )
}