import * as React from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DomainIcon from '@mui/icons-material/Domain'
import IconButton from '@mui/material/IconButton'
import Drop from '../../../Fields/CssTextField'
import URL from '../../../apiurl';
import { getMetaData } from '../../../../redux/Actions/actions';
import { D_API } from '../../../../services/APIService';
import { useSelector } from 'react-redux';

const filter = createFilterOptions();



export default function FreeSoloCreateOptionDialog(props) {


  // console.log(props)
  const [value, setValue] = React.useState(props.value ? props.value : '');
  const [open, toggleOpen] = React.useState(false);
  const [org, setOrg] = React.useState([]);
  const orgTypes = ["Healthcare Provider", "Organizational team", "Government", "Insurance Company",
    "Payer", "Educational Institute", "Religious Institution", "Clinical Research Sponsor",
    "Community Group", "Non - Healthcare Businessor Corporation", "Other"]

  let userId = sessionStorage.getItem("userId")


  const userOrg = useSelector(state => state.registration.userOrg)

  const [locations, setLocations] = React.useState([])
  const [text, setText] = React.useState('Search Locations')

  console.log(value, "valvo")

  React.useEffect(() => {
    getOrg();
  }, [])

  const getOrg = () => {
    // axios.get(`${URL.getPagesByName}`, {
    //   headers: {
    //     'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
    //   }
    // })
    //   .then(response => response.data)
    //   .then(res => {
    //     let organisation = res.data

    //     organisation.unshift({ id: Math.random(), name: 'Add Organisation', description: '', addressId: '' })
    //     console.log(organisation, "organisationorganisation")
    //     // setOrg(organisation)
    //   })
    //   .catch(error => { })

    D_API.get(`/Organization/List`).then(res => {
      console.log(res)
      let transformedData;
      transformedData = res.data?.entry?.map(entry => ({

        ...entry.resource,

      }));
      // organisation1.unshift({ id: Math.random(), name: 'Add Organisation', description: '', addressId: '' })
      console.log(transformedData, "organisationorganisation")
      setOrg(transformedData || [])
      // setOrg(res?.data?.entry)
      // setOrganizationList(res?.data?.entry)
    }).catch(err => err)
  }
  console.log(org, "organisationorganisation")
  const handleClose = () => {
    setDialogValue({
      organisationName: '',
      addressId: '',
      description: ''
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    organisationName: '',
    addressId: '',
    description: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValue({
      name: dialogValue.organisationName,
      location: dialogValue.addressId,
      organisationType: dialogValue.description
    });
    if (dialogValue.organisationName !== '') {
      axios.post(`${URL.addOrganisation}`, { ...dialogValue, userId: userId, metadata: await getMetaData() }, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
        }
      })
        .then(res => {

          props.handleOrg(res.data.data.organisationName, res.data.data.id, true, res.data.data.logo)
          // getOrg()
          handleClose();
        }).catch(error => { })
    }
  };

  const getLocation = (value) => {
    if (value !== '') {
      setText('No Options')
      axios.get(`${URL.searchLocation}/${value}`).then(res => setLocations(res.data.data.predictions.map(data => data.description)))
    }
    else {
      setText('Search Location')
      setLocations([])
    }
  }
  console.log(value, "vvvvvvvvvv")
  return (
    <React.Fragment>
      {!open ?
        <Autocomplete
          value={value}
          onChange={(e, newValue) => {
            if (newValue !== null) {
              if (newValue.name === "Add Organisation") {
                toggleOpen(true)
                setDialogValue({
                  organisationName: '',
                  addressId: '',
                  description: ''
                })
              }
              else if (newValue && newValue.inputValue) {
                toggleOpen(true)
                setDialogValue({
                  organisationName: newValue.inputValue,
                  addressId: '',
                  description: ''
                })
              }
              else {
                console.log(newValue, "unofficialOrganisation")
                props.handleOrg(newValue, newValue.id, false, newValue.logo)
                setValue(newValue)
              }
            }
            else {
              setValue({ organisationName: '' })
            }
          }}
          onInputChange={(e) => {
            if (e !== null) {
              setValue(e.target.value)
              if (e.target.value !== 0) {
                props.handleOrg(e.target.value, null, true)
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add Organisation '${params.inputValue}'`,
              });
            }

            return filtered;
          }}
          id="free-solo-dialog-demo"
          options={org}
          freeSolo
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name || '';

          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys

          renderOption={

            (props, option) =>
              <li {...props} key={option.id || option} value={option.name}>
                <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <div>
                    {option?.identifier?.find(
                      (val) =>
                        val["system"] === `${process.env.REACT_APP_API_URL}v1/orgImg` &&
                        val["value"]?.startsWith("https")
                    )?.value ? (
                      <img
                        src={
                          option?.identifier?.find(
                            (val) =>
                              val["system"] === `${process.env.REACT_APP_API_URL}v1/orgImg` &&
                              val["value"]?.startsWith("https")
                          )?.value
                        }
                        width="30px"
                        height="30px"
                      // alt={option.name} 
                      />
                    ) : (
                      <DomainIcon sx={{ width: 30, height: 30 }} /> // Using MUI sx prop for styling
                    )}
                  </div>
                  <div> {option.name}</div>
                </div>



              </li>


          }
          sx={{ mt: 2 }}
          renderInput={(params) => {
            return (
              <Drop {...params} label={props.label} value={params.inputProps.value} validators={['required']}
                errorMessages={[`Please Select ${props.label}`]} />)
          }}
        />
        :
        <Grid container columnSpacing={1}>
          <Grid item xs={12}>
            <Drop
              fullWidth
              margin="dense"
              id="name"
              value={dialogValue.organisationName}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  organisationName: event.target.value,
                })
              }
              }
              label="Organisation Name"
              type="text"
              variant="outlined"
              validators={['required']}
              errorMessages={['Enter Organisation Name']}
            />
          </Grid>
          {/* <Grid item sm={6} xs={12}>
            <Autocomplete
              options={orgTypes}
              getOptionLabel={option => option || ''}
              value={dialogValue.description}
              onChange={(event, value) =>
                setDialogValue({
                  ...dialogValue,
                  description: value,
                })
              }
              renderInput={params => (
                <Drop
                  {...params}
                  margin="dense"
                  label="Organisation Type"
                  variant="outlined"
                  value={dialogValue.description}
                  fullWidth
                />
              )}
            />
          </Grid> */}
          {/* <Grid item sm={6} xs={12}>
            <Autocomplete
              options={locations}
              getOptionLabel={option => option ? option : ''}
              isOptionEqualToValue={(option, value) => option === value}
              value={dialogValue.addressId}
              onChange={(event, value) => {
                setDialogValue({ ...dialogValue, addressId: value })
              }
              }
              noOptionsText={text}
              onInputChange={(e, value) => getLocation(value)}
              filterOptions={(x) => x}
              renderInput={params => (
                <Drop
                  {...params}
                  margin="dense"
                  label="Location"
                  variant="outlined"
                  value={dialogValue.addressId}
                  fullWidth
                />
              )}
            />

          </Grid> */}
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button onClick={handleClose}>Cancel</Button>
            {dialogValue.organisationName !== '' ? <Button onClick={handleSubmit}>Add</Button> : ''}
          </Grid>
        </Grid>}
      {/* {option.logo ? <img src={option.logo} width="20px" height="20px" alt="organisation" style={{ paddingRight: "10px", paddingLeft: "10px" }} /> : <IconButton><DomainIcon /></IconButton>}{option.name}</li>} */}
    </React.Fragment>
  );
}
