import React from 'react'
import '../feedposts/events/event.css'
import '../feedposts/poll/poll.css'
import "../../utility/style.css";
import '../../Molecule/CelebratePage/Celebrate.css'
import { Autocomplete } from '@mui/material';
import { CelTextField } from '../../Fields/PollFields';

function Celebrate(props) {

    // const [cel,setCel] = React.useState([{id: 1,occ: 'Work Anniversary'},{id: 2,occ: 'Starting a new position'}])
    const [val,setVal] = React.useState({occ: props.val === 7 ? 'Starting a new position' : 'Work Anniversary' ,org:''})

    React.useEffect(()=>{
        if(Object.keys(props.formData).length>0){

            // console.log(props.formData)
            let data = props.formData.content.replace(/Celebrating/g,'').split('at ')
            
            // console.log(data,data[0],data[1])

            if(data[0] == 'Work Anniversary '){
                setVal({occ: data[0] ,org: data[1]})
            }
            else{
                setVal({occ: 'Starting a new position',org: data[1]})
            }
        }
        // setVal({occ: props.formData})
    },[])

    React.useEffect(() => {
        props.handleForm(val,'celebrate')
    }, [val])

    const handleChange = (name,value) => {
        setVal({...val,[name]: value})
    }
 
    return (
        <div className="celebratepoll">
             {/* <div className="d-flex profile__top e-align" >
                <div className="normalfont">Occasion</div>
                <div  className="c-width" >
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={cel}
                    getOptionLabel={(option) => option.occ}
                    onChange={(event, newValue) => {
                        // console.log(newValue)
                        handleChange('occ',newValue)
                    }}
                    value={val.occ}
                    sx={{ width: '100%', mx: 'auto' }}
                    
                    renderInput={(params) => <CelTextField {...params} placeholder="Select Occasion" value={params.inputProps.value} validators={['required']}
                    errorMessages={['Select Occasion']}
                    />}
                />
                </div>
            </div> */}
            {/* At */}
            <div className="meeting__link profile__top e-align">
                <div className="normalfont">Occasion</div>
                <div className='c-width'>
                    <CelTextField type="text" sx={{width: "100%"}} placeholder="Occasion" value={val.occ}/>
                </div>
            </div>
            <br/>
            <div className="meeting__link profile__top e-align">
                <div className="normalfont">At</div>
                <div className='c-width'>
                    <CelTextField type="text" sx={{width: "100%"}} placeholder="Organisation Name" value={val.org} onChange={(e)=> handleChange('org',e.target.value)}
                    validators={['required']}
                    errorMessages={['Enter Organisation Name']}
                    />
                </div>
            </div>
        </div>
    )
}

export default Celebrate
