import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DialogBox from '../../DialogBox/dialog';
import { updateProfile } from '../../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import '../MyProfile/myprofile.css';
import '../MyProfile/updateprofile.css';
import accomplishments from '../../Atom/modifiedicons/accomplishments.svg';
import Experience from '../MyProfile/organisation/experience';
import Accomplishment from '../MyProfile/organisation/accomplishments';
import { FiEdit3 } from 'react-icons/fi';
import MyProfile from '../MyProfile/myprofile';
import { DOC_API, D_API } from '../../../services/APIService';
import UserService from '../../../services/UserService';
import axios from 'axios';
import { Card, Grid } from '@mui/material';
import { getPractitionerFromFhir } from '../../../redux/Actions/regActions';
import { headers } from '../../../redux/Actions/connectActions';
import URL from '../../apiurl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FormDivider } from '../../utility/utils';
// 
// icons / accomplishment.svg

const formatAddress = (address) => {
    return [
        {
            text: address.line1 + "," + address.line2 + "," + address.city + "," + address.state + "," + address.country + "," + address.postalCode,
            line: [address.line1, address.line2],
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country,
        }
    ]
}

const AboutBox = (props) => {
    const [about, setAbout] = useState(props?.stateUsserNonFhr?.about)
    console.log(props, "fjskfjsklfjioe77")


    const handleSubAbout = (e) => {
        console.log(e)
        let data = {
            ...props?.stateUsserNonFhr,
            about: e
        }
        console.log(data, "8989--")
        axios.put(`${URL.updateUser}/${props?.stateUsserNonFhr?.id}`, data, headers())
            .then(response => {
                console.log(response)
                console.log(response, "uiuiui9jh")
                // dispatch(fetchProfile(user?.id))
                props.setStateUserNonFhr(response?.data?.data || "")
                props.close()
            })
            .catch(error => { });
    }
    return (
        <DialogBox open={props.open} handleDialog={props.close}
            ////p
            Title="About"
            Content={
                <>
                    <textarea rows="5" value={about} style={{ width: "100%", fontFamily: 'Helvetica,Neue', padding: '5px', fontSize: '16px' }}
                        onChange={(e) => setAbout(e.target.value)}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            // onClick={(e) => props.submit(about)}
                            onClick={() => handleSubAbout(about)}
                        >Update</Button>
                        <Button onClick={props.close}>Cancel</Button>
                    </div>
                </>
            }
        />
    )
}

function UserProfileOverview() {
    const [user, setUser] = useState({})
    const [about, setAbout] = useState(false)
    const dispatch = useDispatch();
    const [contact, setContact] = useState([])
    const [address, setAddress] = useState([])
    const state = useSelector(state => state.userProfile.userProfile);
    console.log(state, "12345678")
    const [isReadMore, setIsReadMore] = useState(true);
    const [qualificationDetail, setQualificationDetail] = useState([])
    const [licenceDet, setLicenceDet] = useState([])
    const [usersDatas, setUsersDatas] = useState()
    const [stepUpdate, setStepUpdate] = useState()
    const [identity1, setIdentity1] = React.useState("");
    const[identifyHpr,setIdentifyHpr]=useState("");

    const [stateUsserNonFhr, setStateUserNonFhr] = useState()

    const practitionerDetails = useSelector(state => state.registration.fhirUserData)

    useEffect(() => {

        dispatch(getPractitionerFromFhir());
    }, [practitionerDetails?.id])

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    useEffect(() => {
        let data = practitionerDetails;

        if (Object?.keys(data)?.length > 0) {
            let name = data?.name?.[0]?.given?.[0];
            // let address = data.practioner.address ? data.practioner.address[0] : []
            const cerQualifications = [];
            const otherQualifications = [];
            data?.qualification?.forEach((qualification) => {
                if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
                    cerQualifications.push({
                        degree: {
                            system: qualification?.code?.coding?.[0]?.system,
                            display: qualification?.code?.text
                        },
                        course: qualification?.code?.coding?.[0],
                        orgName: "rty",
                        issuer: {
                            name: qualification?.issuer?.display,
                            id: qualification?.issuer?.reference?.split('/')?.[1]
                        },
                        dateIss: qualification?.period?.end,
                        identifier: qualification?.identifier?.[0]?.value

                    })
                }
                else {
                    otherQualifications.push({
                        council: qualification?.issuer?.display,
                        regNo: qualification?.identifier?.[0]?.value,
                        date: qualification?.period?.start,
                        dueDate: qualification?.period?.end
                    });
                }
            })
            setQualificationDetail(cerQualifications);
            setLicenceDet(otherQualifications);

            setContact(data?.telecom ? data.telecom : [])

            setUser({
                family: data?.name?.[0]?.family,
                firstName: data?.name?.[0]?.given?.[0],
                middleName: data?.name?.[0]?.given?.[1],
                prefix: data?.name?.[0]?.prefix ? data?.name?.[0]?.prefix?.[0] : '',
                suffix: data?.name?.[0]?.suffix ? [...data?.name?.[0]?.suffix] : "",
                birthDate: data?.birthDate,
                qualification: data?.qualification,
                text: data?.name?.[0]?.text,
                languages: data?.communication?.length > 0 ? data?.communication?.map(e => e?.coding?.[0]) : [],
                gender: data.gender,
            })

            let hasHprorNot = data?.identifier?.some(
                (e) => e.system === "https://healthid.ndhm.gov.in/"
              );
        
              let indexOfHpr =
                data?.identifier?.findIndex(
                  (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
                ) != -1 && hasHprorNot
                  ? data?.identifier?.findIndex(
                      (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
                    )
                  : -1;
              //   console.log(hasHprorNot,"hasHprorNot",indexOfHpr)
              let getHprId =
                hasHprorNot && indexOfHpr != -1
                  ? data?.identifier[indexOfHpr].value
                  : "";
              setIdentifyHpr(getHprId ? getHprId : "");


            const addressData = {
                text: [`${data?.address?.[0]?.line?.[0]},${data?.address?.[0]?.line?.[1]},${data?.address?.[0].city},${data?.address?.[0]?.state},${data?.address?.[0]?.country},${data?.address?.[0]?.postalCode}`]?.filter((val) => Boolean(val)),
                line1: data?.address?.[0]?.line?.[0] ? data?.address?.[0]?.line?.[0] : "",
                line2: data?.address?.[0]?.line?.[1] ? data?.address?.[0]?.line?.[1] : '',
                city: data?.address?.[0].city ? data?.address?.[0].city : "",
                state: data?.address?.[0]?.state ? data?.address?.[0]?.state : "",
                postalCode: data?.address?.[0]?.postalCode ? data?.address?.[0]?.postalCode : "",
                country: data?.address?.[0]?.country ? data?.address?.[0]?.country : "",
                district: data?.address?.[0].city,
            }
            setAddress(addressData)

        }

    }, [practitionerDetails])
    console.log(user, "userser")
    useEffect(() => {
        D_API.get(`Practitioner/identifier/dev.docsteth.com/${UserService.getUserId()}`)
            .then(res => {
                console.log(res, "787878787q")
                setUsersDatas(res?.data?.entry?.[0] || {})
            })
            .catch(err => console.log(err))
    }, [])
    useEffect(() => {
        axios.get(process.env.REACT_APP_CLINIDESK + `getStepsByUserId/${UserService.getUserId()}`)
            .then(res => {
                console.log(res, "89898989898")
                setStepUpdate(res?.data)
            })
            .catch(err => console.log(err))
    }, [])
    console.log(user, "dfghjklkjhg", stepUpdate)
    const submitAbout = (userAbout) => {
        // let qualification=[
        //     {
        //         "council": "",
        //         "regNo": "",
        //         "date": "",
        //         "dueDate": "",
        //         "doc": ""
        //     }
        // ]
        //         user.qualification.map(e => e?.identifier?.[0]?.type?.code === 'CER' ? qualification.push())


        setUser({ ...user, about: userAbout })
                
    let  hprIdDetails = identifyHpr;
        dispatch(updateProfile({ ...user, about: userAbout }, contact, formatAddress(address), qualificationDetail, licenceDet,usersDatas,hprIdDetails))
        setAbout(false)
    }


    useEffect(() => {
        console.log("rerererssgfgnk", state)
        axios.get(`${URL.findUserById}/${state?.id}`, headers())
            .then(response => {
                console.log(response, "rerererssgfgnk")
                // dispatch(fetchProfile(user?.id))
                setStateUserNonFhr(response?.data?.data)
            })
            .catch(error => { });
    }, [state])

    console.log(state, "stateeee")
    useEffect(() => {
        let data = state
        console.log(data, "data1111")
        if (Object.keys(data).length > 0) {
            // let name = data.practioner.name[0]
            // let contact = data.practioner.telecom
            // let address = data.practioner.address ? data.practioner.address[0] : []
            // const cerQualifications = [];
            // const otherQualifications = [];

            // data?.practioner?.qualification?.forEach(qualification => {
            //     if (qualification?.identifier?.[0]?.type?.code === "CER") {
            //         cerQualifications.push({
            //             degree: {
            //                 system: qualification?.code?.coding?.[0]?.system,
            //                 display: qualification?.code?.text
            //             },
            //             course: qualification?.code?.coding?.[0],
            //             orgName: "rtyu",
            //             issuer: {
            //                 name: qualification?.issuer?.display,
            //                 id: qualification?.issuer?.reference.split('/')[1]
            //             },
            //             dateIss: qualification?.period?.end,
            //             identifier: qualification?.identifier?.[0]?.value
            //         });
            //     } else {
            //         otherQualifications.push({
            //             council: qualification?.issuer?.display,
            //             regNo: qualification?.identifier?.[0]?.value,
            //             date: qualification?.period?.start,
            //             dueDate: qualification?.period?.end
            //         });
            //     }
            // });

            const identifiers = data?.practioner?.identifier?.length > 0 ? data?.practioner?.identifier : [];
            const identity12 = identifiers?.filter(e => e.system === "https://healthid.ndhm.gov.in/")
                .map(e => e.value)[0];
            setIdentity1(identity12)





            // setQualificationDetail(cerQualifications);
            // setLicenceDet(otherQualifications);


            // Now `qualifications` contains the mapped data with the required structure.

            setUser({

                // family: name?.family,
                // firstName: name?.given?.[0],
                // middleName: name?.given?.[1],
                // prefix: name?.prefix ? name.prefix?.[0] : '',
                // suffix: name?.suffix,
                // text: name?.text,
                // gender: data?.practioner?.gender,
                // birthDate: data?.practioner?.birthDate !== null ? data?.practioner?.birthDate : '',
                // qualification: data.practioner?.qualification,
                // languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : [],
                ...user,
                caption: data?.captionAndTaglines,
                about: data?.about,
                id: data?.id,
                photo: data?.practioner?.photo?.[0]?.url,
                coverPic: data?.coverPic,
                identifier: data?.practioner?.identifier,



            })

            // const addressData = {
            //     facilityName: address?.facilityName ? address?.facilityName : '',
            //     text: address.text,
            //     line1: address.hasOwnProperty('line') ? address?.line[0] : '',
            //     line2: address.hasOwnProperty('line') ? address?.line[1] : '',
            //     city: address?.city,
            //     state: address?.state,
            //     postalCode: address?.postalCode,
            //     country: address?.country,
            //     district: address?.district,
            // }
            // setContact(contact)
            // setAddress(addressData)

        }
        else {
            let userid = sessionStorage.getItem("userId")
            // dispatch(fetchProfile(userid))
        }
        return () => { }
    }, [state])

    console.log(qualificationDetail, "eeeee11eeeeeee", stateUsserNonFhr)

    return (
        <>
            {about ? <AboutBox submit={submitAbout} open={about} about={user.about} stateUsserNonFhr={stateUsserNonFhr} setStateUserNonFhr={setStateUserNonFhr} close={() => setAbout(false)} /> : ''}
            <Grid container>
                <Grid item xs={12} md={4} className="margin_myprofile">
                    <MyProfile />
                </Grid>
                <Grid item xs={12} md={8}>
                    {/* About */}
                    {/* <Card className="mv1"> */}
                    <div className="mt-10 tablet_mobile border_img " id="about__height">
                        <div className="sidebar__stat sidebar__padding">
                            <div className="sidebar__substat ta__center">
                                <div className="fw__semibold sidebar__lh" style={{ fontSize: "18px", color: "rgb(35, 122, 196)", fontWeight: "550" }}>About</div>
                            </div>
                            <div className="sidebar__statNumber">
                                <IconButton aria-label="visible" onClick={() => setAbout(true)}>
                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                </IconButton>
                            </div>
                        </div>
                        {/* About */}

                        <div className="sidebar__padding bg__white">
                            {/* {(user.about) ?
                                <>
                                    <span style={{ fontSize: "16px", fontWeight: "400" }}>{isReadMore && user.about ? user.about.substr(0, 300) : user.about}</span>
                                    <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "400", fontSize: "14px" }}>{isReadMore && user.about.length > 300 ? "...Read More" : ""}</span>
                                </> :
                                <p className="about___text">Write some thing about your self ?</p>
                            } */}



                            {(stateUsserNonFhr?.about) ?
                                <>
                                    <span style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        display: isReadMore ? '-webkit-box' : "",
                                        WebkitBoxOrient: 'vertical ',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 2,
                                        lineswhiteSpace: 'normal',
                                        whiteSpace: 'pre-wrap'
                                    }}
                                    >
                                        {isReadMore && stateUsserNonFhr?.about?.length > 300 ? stateUsserNonFhr.about.substr(0, 300) : stateUsserNonFhr.about}</span>
                                    {/* <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "400", fontSize: "14px" }}>{isReadMore && stateUsserNonFhr?.about?.length > 300 ? " ...Read More" : !isReadMore && stateUsserNonFhr?.about?.length > 300 ? " ...Read Less" : ""}</span> */}
                                </> :
                                <p className="about___text">Write some thing about your self ?</p>
                            }


                            {/* {(user.about && user.about.length < 301) ? <p style={{ overflowY: 'auto', overflowWrap: 'break-word', fontSize: '18px', textAlign: 'justify' }}>{(user.about).substr(0, 300)}<Button onClick={handleabout}></Button></p> : <p className="about___text">what would you like to share about you ?</p>} */}
                            {/* <p style={{ overflowY: 'auto', overflowWrap: 'break-word' }}>{user.about}</p> */}
                        </div>
                        {
                            stateUsserNonFhr?.about && stateUsserNonFhr?.about.length > 300 &&

                            <Card className='p1' style={{ borderTop: "1px solid lightgrey", borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>
                                {
                                    isReadMore && <div className='flx-center cursor' style={{ justifyContent: "center" }} onClick={toggleReadMore}><span className="fntSm14 fntSemiBold">Show More</span> <span style={{ height: "15px", }}><KeyboardArrowDownIcon fontSize='' /></span></div>
                                } {
                                    !isReadMore && <div className='flx-center cursor' style={{ justifyContent: "center" }} onClick={toggleReadMore}><span className="fntSm14 fntSemiBold">Show Less</span> <span style={{ height: "15px", }}><KeyboardArrowUpIcon fontSize='' /></span></div>
                                }

                            </Card>
                        }
                    </div>
                    {/* </Card> */}
                    <div id="experience">
                        <Experience />
                    </div>
                    {/* <div className="sidebar__padding bg__white mt-10" style={{ paddingBottom: "20px" }}>
                        <div style={{ backgroundColor: "white" }}>
                            <div>
                                <h3 className='fw__normal' style={{ color: "#5119A2", fontSize: "18px", color: "#000000", display: "flex", alignItems: "center" }}>
                                    <img style={{ paddingRight: "15px", paddingLeft: "10px" }} src={LinkedAccount}></img>Linked Accounts</h3>
                            </div>
                            <div style={{ display: "flex" }} className="pt-2">
                                <div className='border_img' style={{ display: "flex", alignItems: "center", padding: "15px" }}>
                                    <div>
                                        <img src={Medxperts} height="60px" width="60px"></img>
                                    </div>
                                    <div style={{ paddingLeft: "8px" }}>
                                        <h3 style={{ fontSize: "18px", fontWeight: "500" }}>Medxperts</h3>
                                        <h3 className='link_text'>MedxpertsAppointments</h3>
                                        <h5 className='link_text'>www.Medxperts.in/Venkatreddy</h5>
                                    </div>
                                </div>
                                <div className='border_img' style={{ display: "flex", alignItems: "center", padding: "15px", marginLeft: "20px" }}>
                                    <div>
                                        <img src={Idimage} height="50px" width="50px"></img>
                                    </div>
                                    <div style={{ paddingLeft: "18px" }}>
                                        <h3 style={{ fontSize: "18px", fontWeight: "500" }}>Medxperts</h3>
                                        <h3 className='link_text'>Research Publications</h3>
                                        <h5 className='link_text'>www.orcid.org/0392999..</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <Accomplishment /> */}
                    <div className='border_img mv1'>
                        <div style={{ display: "flex", alignItems: "center", padding: "10px 20px" }}>
                            <img src={accomplishments} style={{ marginRight: "10px" }} width="25px" height="25px" /><span className='acc_Heading_text'>Accomplishments</span>
                        </div>
                        <div>
                            <Accomplishment />
                        </div>
                    </div>
                    {/* <div className='border_img mt-10' style={{ height: "418px" }}>
                <div className='sidebar__padding' style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
                    <img src={Acmplsh} width="28px" height="28px"></img>
                    <span style={{ fontSize: "21px", color: "#247BC4", paddingLeft: "20px" }}>Accomplishment</span>
                </div>
                <div className='badges_responsive pt-2'>
                    <div className="sidebar__icon1" id='flex_align'>
                        <div className="sidebar__title sidebar__pb fw__normal">Certifications</div>
                        <img src={certificate} alt="certificate" />
                        <div className="sidebar__statNumber">{user.certificatesCount}</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <div className="sidebar__title sidebar__pb fw__normal">Badges</div>
                        <img src={ribbon} alt="certificate" />
                        <div className="sidebar__statNumber">{user.badgesCount}</div>
                    </div>
                    <div className="sidebar__icon1" id='flex_align'>
                        <div className="sidebar__title sidebar__pb fw__normal">Achievements</div>
                        <img src={medal} alt="certificate" />
                        <div className="sidebar__statNumber">{user.achievementsCount}</div>
                    </div>
                    <div className="sidebar__statNumber1">
                    </div>
                </div>
            </div> */}
                </Grid>
            </Grid>

        </>
    )
}

export default UserProfileOverview