import { Typography, Box, Grid, Button, TextField, FormGroup, FormControlLabel, Checkbox, Snackbar, Alert } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getAppsByUserId, headers } from '../../../../redux/Actions/connectActions'
import InputAdornment from '@mui/material/InputAdornment';
import CouponImg from "../../../Atom/svg/Icon material-local-offer.svg"

import URL from '../../../apiurl'
import { useDispatch, useSelector } from 'react-redux'
import { getMetaData } from '../../../../redux/Actions/actions'
import UserService from '../../../../services/UserService'

const FormCustomInput = ({ ...props }) => {
    return (
        <>

            <TextField
                {...props}
                fullWidth
                variant='outlined'
                size="small"
                sx={{ boxShadow: '0px 0px 13px 0px rgba(222,222,222,1)', borderRadius: "10px", '.MuiOutlinedInput-notchedOutline': { border: 0 } }}


            />
        </>
    )
}

const Coupon = () => {
    return <img src={CouponImg} alt="coupon" />
}



const Confirm = () => {
    const [formData, setFormData] = useState({
        couponcode: "",
        gstno: "",
        pancard: "",
        tdsdeclare: true,
        tdsno: "",
        deductionvalue: 5,
        // rbicheck: false,
        // autodebit: false
    })

    const [orderid, setOrderid] = useState('')
    const [razorPayId, setRazorPayID] = useState("")
    const [razorOrderId, setRazorOrderId] = useState("")
    const [razorSignature, setRazorSignature] = useState("")

    const [data, setData] = useState([])

    const prefilledData = useSelector((state) => state.userProfile.billingDetail)
    const [openSnack, setOpenSnack] = useState(false)

    const [confirmDetailid, setConfirmDetailid] = useState("")

    console.log(prefilledData, 'prefilled data here')

    let { appname, id, paymentid } = useParams()
    let params = useParams()

    let location = useLocation();
    const history = useHistory();
    const userId = sessionStorage.getItem("userId")
    const dispatch = useDispatch()


    useEffect(() => {
        sessionStorage.removeItem("isConfirm")
    }, [])


    const handleClose = () => {
        setOpenSnack(false);
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target
        setFormData((prev) => {
            return {
                ...prev,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }


    const getOrderId = {
        amount: 200,
        currency: "INR"
    }

    let amount = "200"

    const subscribeToApp = async (razorpay_payment_id) => {
        let data = {
            "active": true,
            "appId": id,
            "endDate": null,
            "id": 0,
            "metadata": await getMetaData(),
            "paymentId": razorpay_payment_id,
            "startDate": new Date(),
            "userId": userId
        }
        axios.post(URL.subscribeToApp, data, headers())
            .then(res => {
                dispatch(getAppsByUserId())

                //end of razorpayhere
                setTimeout(() => {
                    history.push('/marketplace/myapps')
                }, 1000)
            })
            .catch(err => console.log(err))
    }


    const transaction = async (razorpay_payment_id, razorpay_order_id, razorpay_signature, status) => {

        // let paymentid = parseInt(location.pathname.split('/')[6])
        // let billingid = paymentid

        let data = {
            description: "",
            metadata: await getMetaData(),
            order_id: razorpay_order_id,
            payment_id: razorpay_payment_id,
            signature: razorpay_signature,
            // transaction_mode: "card",
            user_id: userId,
            amount: amount,
            currency: "INR",
            transaction_status: status.status,
            payment_details: status.paymen_details,
            transaction_status: status.status,
            billingid: prefilledData.billingid,
            // billingid: prefilldata.billingid,
            confirmdetailsid: confirmDetailid,
        }

        console.log(data, 'ASDFGHJKL;S')

        await axios.post(`${URL.transactionCreate}`, data, headers()).then(res => {
            // setOpenSnack(true);
            subscribeToApp(razorpay_payment_id)
        }).catch(res => {
            console.log(res)
        })
    }



    const checkoutdetail = async (razorpay_payment_id, razorpay_order_id, razorpay_signature,) => {

        const data = {
            razorpay_payment_id,
            razorpay_order_id,
            razorpay_signature,
            order_id: orderid,
            amount,
        }

        await axios.post(`${URL.verifyPayment}`, data, {
            headers: {
                Authorization: `Bearer ${UserService.getToken()}`
            }
        }).then(res => {
            // history.push(`${url}/billing`)
            const status = res.data
            transaction(razorpay_payment_id, razorpay_order_id, razorpay_signature, status)

        }).catch(err => {
            console.log(err)
        })

    }

    useEffect(() => {

        if (amount !== 0 && orderid.length > 0) {
            var options = {
                // "key": "rzp_test_O3F6y2GzIeJKoF", // Enter the Key ID generated from the Dashboard
                "key": `${process.env.REACT_APP_RAZORPAY_KEYID}`, // Enter the Key ID generated from the Dashboard
                "amount": `${amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": "Docsteth ",
                "description": "hellooo Transaction",
                "image": "https://www.docsteth.com/static/media/Logo2.d92e5ec401ec97597945e37776bafd7f.svg",

                "order_id": `${orderid}`,
                "save": 1,

                "handler": function (response) {
                    setRazorPayID(response.razorpay_payment_id)
                    setRazorOrderId(response.razorpay_order_id)
                    setRazorSignature(response.razorpay_signature)
                    // checkoutdetail()
                    checkoutdetail(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
                },
                "prefill": {
                    email: prefilledData ? prefilledData.email : null,
                    contact: prefilledData ? prefilledData.phone_num : null,
                },


                "theme": {
                    "color": "#f9f9f9 "
                },

            };
            // console.log(options.order_id, "options id")

            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    }, [amount, orderid])



    // const SubmitForm = async (e) => {
    const OpenRazorPAy = async () => {

        // e.preventDefault()
        // console.log(getOrderId)
        await axios.post(`${URL.createRazorpayOrder}`, getOrderId, {
            headers: {
                Authorization: `Bearer ${UserService.getToken()}`
            }
        }).then(res => {

            setOrderid(res.data.id)
            // if (res.data.id.length > 0) {
            // rzp1.open();
            // } //opening razorpay popup from here
        }).catch(err => {
            console.log(err)
        })
    }

    const handleSubmit = () => {

        // let paymentid = parseInt(location.pathname.split('/')[6])
        // let data = { ...formData, billingid: paymentid }
        let data = { ...formData, billingid: prefilledData.billingid }


        axios.post(`${URL.confirmDetail}`, data, headers())
            .then(res => {
                // confirmDetailid = res.data.id
                setConfirmDetailid(res.data.id)
                setOpenSnack(true);
                OpenRazorPAy()
            })

    }


    useEffect(() => {
        axios.get(URL.findAppById + id, headers()).then(res => setData(res.data.data))
    }, [id])

    return (
        <div>
            <Typography sx={{ paddingBottom: { xs: "10px", sm: 0, md: 0 } }}>Confirm Subscription</Typography>
            <Box sx={{ margin: { xs: 0, sm: "20px", md: "30px" } }}>

                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                    <img src={data.logo} alt='' width="40" />
                    <div>
                        <Typography>{appname}</Typography>
                        {/* <Typography variant="body2">{data.captionAndTagLine}</Typography> */}
                    </div>
                </div>

                <Grid container spacing={2} sx={{ margin: "10px 0", }}>
                    <Grid item xs={12} sm={12} md={6} sx={{ padding: "0 !important" }}>
                        <Typography variant="body1">Pricing</Typography>
                        <Typography variant="body2">0.5$ Per Out-Patient record +18% GST
                            Cancel Any-time no extra Charges</Typography>
                    </Grid >
                    {/* <Grid item xs={12} sm={12} md={6} sx={{ padding: "5px !important", textAlign: { xs: "end", md: "center" } }}> */}
                    <Grid item xs={12} sm={12} md={6} sx={{ padding: "5px !important", textAlign: { xs: "end", md: "center" } }} >
                        <Typography variant="body2" sx={{ marginBottom: "5px", }}>PAY AS YOU GO</Typography>
                        <Button variant="contained" size='small' sx={{ backgroundColor: "#B7ACFF", height: "24px" }}>Billed Monthly</Button>
                    </Grid >
                </Grid>

                <Grid container spacing={2} my={1} >
                    <Grid item xs={7} sm={7} md={6}>
                        <FormCustomInput
                            name="couponcode"
                            value={formData.couponcode}
                            onChange={handleChange}
                            placeholder="Coupon Code"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Coupon /></InputAdornment>,
                            }} />
                    </Grid>
                    {/* <Grid item xs={4} sm={4} md={6} sx={{ display: "flex", justifyContent: "center" }}> */}
                    <Grid item xs={4} sm={4} md={6} sx={{ textAlign: { xs: "end", md: "center" } }} >
                        <Button variant="outlined" size="small" sx={{ width: "120px", }}>Apply</Button>
                    </Grid>
                </Grid>

                <Typography>Optional Information</Typography>
                <Grid container spacing={2} my={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormCustomInput name="gstno" value={formData.gstno} onChange={handleChange} placeholder="GST No." />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormCustomInput name="pancard" value={formData.pancard} onChange={handleChange} placeholder="PAN CARD" />
                    </Grid>
                </Grid>

                <FormGroup>
                    <FormControlLabel control={<Checkbox size='large' name="tdsdeclare" checked={formData.tdsdeclare} onChange={handleChange} sx={{ backgroundColor: "transparent" }} />} label="Declare TDS Deduction" />
                </FormGroup>


                <Typography sx={{ marginLeft: "52%", display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>Deduction value in Percentage</Typography>
                <Grid container spacing={2} my={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormCustomInput name="tdsno" value={formData.tdsno} onChange={handleChange} placeholder="TDS No." />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>Deduction value in Percentage</Typography>
                        <Grid container spacing={2} >
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                                <FormCustomInput name="deductionvalue" value={formData.deductionvalue} onChange={handleChange} placeholder="5" />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                                {/* <FormCustomInput value="%" disabled /> */}
                                <FormCustomInput value="%" disabled inputProps={{ style: { textAlign: 'center' } }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <FormGroup>
                    <FormControlLabel control={<Checkbox size='large' name='rbicheck' checked={formData.rbicheck} onChange={handleChange} />} label="Save Card as Per RBI Guideline" />
                </FormGroup>

                <FormGroup>
                    <FormControlLabel control={<Checkbox size='large' name="autodebit" checked={formData.autodebit} onChange={handleChange} />} label="Consent Recurring Billing / Autodebit" />
                </FormGroup> */}

                <Typography my={2}>
                    * On-clicking confirm you agree to the <a href="#" style={{ color: "#0D2DAF" }}>terms and conditions</a>
                    <br />
                    And your card will be verified with 2 Rs deduction
                </Typography>
                {/* <Box sx={{ display: "flex", justifyContent: "space-between", padding: { xs: "0 0 40px" } }} my={{ md: 2 }}> */}

                <Box sx={{ display: "flex", gap: "20px", justifyContent: "end", alignItems: "center", padding: { xs: "0 0 40px" } }} my={{ md: 1 }}>

                    {/* <Button>Cancel</Button> */}
                    <Button onClick={() => history.goBack()} sx={{ textTransform: "lowercase", fontWeight: "400", color: "black" }}><Typography sx={{ fontSize: "20px" }}>Cancel</Typography></Button>

                    {/* <Button variant="contained" sx={{ backgroundColor: "#1500A0", padding: "0 20px" }} onClick={handleSubmit}>Confirm</Button> */}
                    <Button variant="contained" sx={{ backgroundColor: "#1500A0", padding: "0 20px !important", height: "30px", borderRadius: "8px" }} onClick={handleSubmit}>Confirm</Button>

                </Box>

            </Box>
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            // message="Transaction Success"
            >
                <Alert severity="success">Billing Details Confirmed  </Alert>
            </Snackbar>

        </div >
    )
}

export default Confirm