import React from 'react'
import '../../Molecule/Feed/Feed.css';
import '../../utility/style.css';
import './QuizModal.css'

function QuizModal() {
    return(
                <div>
                    <div className="clini_cases input">
                        <input type="text" placeholder="Diagnosis"/>
                    </div>
                    <div className="clini_cases input">
                        <input type="text" placeholder="Speciality"/>
                    </div>
                    <div className="category">
                        <h5>Category</h5>
                            <div>
                                <ul>
                                    <li>Case Study</li>
                                    <li>Case Series</li>
                                    <li>New Findings</li>
                                    <li>Knowledge Sharing</li>
                                </ul>
                            </div>
                    </div>

                    
                       

                </div>
    )
}

export default QuizModal
