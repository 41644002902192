import React, { useEffect, useState } from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { useParams, useHistory } from 'react-router'
import axios from 'axios'
import URL from '../apiurl'
import UserService from '../../services/UserService'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Actions } from '../Molecule/Post/commonFun'
import DialogBox from '../DialogBox/dialog'
import Snackbar from '@mui/material/Snackbar'
import UsersData from '../Molecule/Post/usersData'
import Postuserdata from '../Molecule/Post/Postuserdata'
import { useDispatch, useSelector } from 'react-redux'
import { getPost } from '../../redux/Actions/actions'

export default function ArticleView() {

    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [article, setArticle] = useState(null)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [alert, setAlert] = useState({ open: false, msg: '', post: false })
    const postData = useSelector(state => state.userProfile.getPost)

    console.log(article)
    // console.log(article.likesData)
    // console.log(article.likeCount)

    useEffect(() => {
        getPostById()
    }, [])

    useEffect(() => {
        if (postData?.id) {
            setArticle(postData)
        }
    }, [postData])

    const getPostById = () => {
        dispatch(getPost(id))
    }

    return (
        <Box sx={{ minHeight: 'calc(100vh - 77px)', backgroundColor: 'white', paddingTop: '15px' }}>

            {openDialog && article?.likedData ? article?.likeCount > 0 ? <DialogBox width="xs" open={openDialog} handleDialog={() => setOpenDialog(false)}
                Content={<Postuserdata title="Post Likes" data={article?.likedData} closeDialog={() => setOpenDialog(false)} />
                }
            /> : '' : ''}

            <Typography component="div" sx={{ p: 3, pt: 0, wordBreak: 'break-all' }}>
                <ArrowBackIcon onClick={() => history.goBack()} sx={{ mr: 2 }} />
                {article !== null ? <>
                    <h1 className="textCenter capitalize">{article?.articleData?.articleTitle}</h1>

                    <div className="article-para" style={{ fontFamily: 'inherit', marginBottom: '10px' }} dangerouslySetInnerHTML={{ __html: article?.articleData?.articleDescription }} />
                    <Divider />

                </>

                    : ''}

                {article ?
                    <Typography component="div" sx={{ py: 3, pt: 0 }}>
                        <Actions post={article} getPostById={getPostById} openDialog={() => setOpenDialog(true)} />
                    </Typography> : ''}
            </Typography>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={alert.post ? null : 2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '', post: false })}
                message={alert.msg}
            />
        </Box>
    )
}