import React, { useEffect, useState } from 'react';
import './poll.css';
import "../../../utility/style.css";
import { CssTextField } from '../../../Fields/PollFields'
import { Typography, Button, Divider, Box } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const PollField = (props) => {
  // console.log(props)
  return (
    <>
      {/* <Typography sx={{ my: '5px!important', display: 'flex' }} justifyContent="space-between" alignItems="center">{props.heading} <p>{props.children}</p></Typography> */}
      <CssTextField sx={{ my: '5px!important', width: '100%' }} {...props} onChange={(e) => props.handleChange(e.target.value)}
        errorMessages={['This field is required']}
        validators={['required']}
      />
    </>
  )

}

export default function PollForm(props) {

  const [pollPost, setPollPost] = useState({
    pollQuestion: '', pollExpiry: null, pollTitle: '', pollOptions: [{ votes: 0, option: '' }, { votes: 0, option: '' }]
  })

  // const durationList = [{ name: '1 day', value: 1 }, { name: '3 days', value: 3 }, { name: '5 days', value: 5 }, { name: '1 week', value: 7 }, { name: '2 weeks', value: 14 }];


  useEffect(() => {
    props.handlePollForm(pollPost, 'poll')
    // console.log(pollPost)
  }, [pollPost])

  const handleChange = (value, key, index) => {
    if (key === "pollQuestion" || key === "pollTitle") {
      setPollPost({ ...pollPost, [key]: value })
    }
    else {
      let val = [...pollPost['pollOptions']]
      val[index]['option'] = value
      setPollPost({ ...pollPost, pollOptions: val })
    }
  }

  const addOption = (e) => {
    e.preventDefault();
    let options = [...pollPost.pollOptions]
    options.push({ votes: 0, option: '' })
    setPollPost({ ...pollPost, pollOptions: options })

  }

  const deleteOption = (i) => {
    let options = [...pollPost.pollOptions]
    options = options.filter((op, index) => index !== i)
    // console.log(options)
    setPollPost({ ...pollPost, pollOptions: options })
  }
  return (
    <div className="p-3">
      <PollField
        label="Title"
        value={pollPost.pollTitle}
        handleChange={(value) => handleChange(value, "pollTitle")}
      />
      {/* <Autocomplete
                disableClearable={true}
                value={pollPost.duration}
                onChange={(event, newValue) => {
                    setPollPost({ ...pollPost, duration: newValue });
                }}
                sx={{ mt: '10px!important' }}
                label="Poll Duration"
                id="controllable-states-demo"
                options={durationList}
                getOptionLabel={(option) => option.name}
                // sx={{ width: 300 }}
                renderInput={(params) => <CssTextField {...params} label="Poll Duration" value={params.inputProps.value} errorMessages={['This field is required']} validators={['required']} />}
            /> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="End Date"
          // inputFormat="dd/MM/yyyy"
          format="DD/MM/YYYY"
          value={dayjs(pollPost.pollExpiry)}
          onChange={(newValue) => {
            setPollPost({ ...pollPost, pollExpiry: new Date(newValue) });
          }}
          // minDate={new Date()}
          className="w-100"
          renderInput={(params) => {
            return (
              <CssTextField
                {...params}
                className="w-100"
                sx={{ my: "5px!important" }}
                value={params.inputProps.value}
                errorMessages={["This field is required"]}
                validators={["required"]}
              />
            );
          }}
        />
      </LocalizationProvider>
      <Divider sx={{ my: "10px" }} />
      <PollField
        label="Question"
        value={pollPost.pollQuestion}
        handleChange={(value) => handleChange(value, "pollQuestion")}
      />
      {pollPost.pollOptions.map((option, index) => (
        <Box className="d-flex e-align" sx={{ justifyContent: "flex-start" }}>
          <Typography sx={{ width: { md: "70%", xs: "85%" } }}>
            <PollField
              key={index}
              label={`Option ${index + 1}`}
              value={option.option}
              handleChange={(value) =>
                handleChange(value, "pollOptions", index)
              }
            />
          </Typography>
          {index < 2 ? (
            ""
          ) : (
            <IconButton onClick={(e) => deleteOption(index)} title={"Remove"}>
              <RemoveCircleOutlineIcon
                style={{
                  fontSize: "25px",
                  verticalAlign: "middle",
                  color: "red",
                }}
              />
            </IconButton>
          )}
        </Box>
      ))}
      {pollPost.pollOptions[0].option === "" ||
        pollPost.pollOptions[1].option === "" ||
        pollPost.pollOptions[pollPost.pollOptions.length - 1].option === "" ? (
        <Button className="add-option" disabled sx={{ mt: 2 }}>
          + Add Option
        </Button>
      ) : (
        <Button
          className="add-option"
          type="submit"
          onClick={(e) => addOption(e)}
          sx={{ mt: 2 }}
        >
          {" "}
          + Add Option
        </Button>
      )}
      {/* <Typography sx={{ my: '5px!important' }}>Poll Duration</Typography> */}
    </div>
  );
}
