import { Button, Grid, Box } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react';
import Elib from '../../../images/e-library.svg'
import '../../Molecule/Elibrary/ElibraryRightContainer.css'
import './EbmKit.css'
import axios from 'axios';
import URL from '../../apiurl';
import { Helmet } from 'react-helmet';
import { AppBar, Toolbar, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import PracticeTools from "../../../images/stethoscope.svg";
import ebmpractice_kit from '../../Atom/modifiedicons/ebmpractice_kit.svg';
import drug_info from '../../Atom/modifiedicons/drug_info.svg';
import video_guides from '../../Atom/modifiedicons/video_guides.svg';
import share from "../../../images/open_external.svg";
import standard from '../../Atom/modifiedicons/standard.svg';
import dose_calculator from '../../Atom/modifiedicons/dose_calculator.svg';
import bio_calculator from '../../Atom/modifiedicons/bio_calculator.svg';
import check_list from '../../Atom/modifiedicons/check_list.svg';
import clinical_calculator from '../../Atom/modifiedicons/clinical_calculator.svg';
import neon from "../../Atom/svg/neon.svg";
import lexi from "../../Atom/svg/lexi.svg";
import Moreapps from '../../../images/Moreapps.svg';
import ArrowRight from '../../../images/arrow_circle_right_blue.svg';
import Clinical_tool from '../../../images/Clinical_tools.svg';
import interactive_new from '../../../images/interactive_new.svg';
import Arrow_White from '../../../images/arrow_circle_right_white.svg';
import Share_white from '../../../images/open_external_white.svg';
import Libray_book from '../../../images/libary_fill_book.svg';
import SearchHomepage from './SearchHomepage';
import Search from './search'
import { useHistory } from 'react-router-dom';
import UserService from '../../../services/UserService';
// import {useStyles as MenuStyles } from '../Header/search'
import CircularProgress from '@mui/material/CircularProgress';
import { Search_Elib } from './specialitites';
import { InfoOutlined } from '@mui/icons-material';

const arrdata = [
    {
        id: '1',
        icon: ebmpractice_kit,
        heading: "Evidence Review",
        subtext: "Evidence based Disease information and management guide backed by Cochrane protocols, systematic reviews"
    },
    {
        id: '2',
        icon: drug_info,
        heading: "Drug Information",
        subtext: "High quality, in-depth information on Indications, safety, kinetics and more.All you need to know"
    },
    {
        id: '3',
        icon: video_guides,
        heading: "Video Guides",
        subtext: "Procedure videos,educational content for care givers.Expert opinions, clinical discussion and many more"
    },
    {
        id: '4',
        icon: standard,
        heading: "Standard Practices",
        subtext: "Clinical and administrative standards meant to be implemented for efficient and legally binding practices"
    }
]
let arrdata1 = [
    {
        id: '1',
        icon: dose_calculator,
        heading: "Dose Calculators",
        subtext: "A tools for safe and effective prescribing. Calculators and nomograms for dosing various narrow therapeutic index drugs"
    },
    {
        id: '2',
        icon: bio_calculator,
        heading: "Biomedical Calc",
        subtext: "Efficiently evaluate biomedical parameters to its clinical relevance.A set of mathematicaltools useful in day to day practice"
    },
    {
        id: '3',
        icon: clinical_calculator,
        heading: "Assessment Tools",
        subtext: "Assess the patient condition, with standard tools that can help you with scoring and grading the clinical status"
    },
    {
        id: '4',
        icon: check_list,
        heading: "Check-lists",
        subtext: "Never miss-out any thing a detailed checklist for better quality of care is need of the hour"
    }
]

export const d_name = (name) => {
    return name.replace(/ /g, '_').replace(/-/g, '__').toLowerCase()
}

export const drugName = (name) => {
    return name.replace(/ /g, '_').replace(/\//g, '__').replace(/-/g, '--').toLowerCase()
}

function Ebmkithomepage(props) {

    const [search, setSearch] = useState(false)
    const myRef = useRef(null)
    const history = useHistory()
    // const styles = MenuStyles()
    const [value, setValue] = useState('')
    const [edata, setEData] = useState([])
    const [load, setLoad] = useState(false)
    const [svalue, setSvalue] = useState('')

    const handleChange = (name) => {
        setValue(name)
        if (name.length > 2) {
            setLoad(true)
            axios.get(`${URL.elib_search}/${name}`, {
                headers: {
                    Authorization: `Bearer ${UserService.getToken()}`
                }
            }).then(res => {
                setEData(res.data.data)
                setLoad(false)
            }).catch(err => setLoad(false))
        }
        else {
            setEData([])
        }
    }

    return (
        <Grid container>
            <Grid item md={12} xs={12}>
                <div ref={myRef} id="display_tablet" >
                    <Helmet>
                        <title>EBM Practice Kit specialities | Docsteth</title>
                        <meta name="description" content="EBM Practice Kit specialities" />
                        <meta name="canonical" content="https://www.docsteth.com/elib/specialities" />
                        <meta name="keyWords" content={` Docsteth, Hyderabad, EBM, EBM Practice Kit, EBM Practice, Specialities `} />
                        {/* <meta name="keywords" content={data.map((data) => (data.speciality))} /> */}
                    </Helmet>
                    <AppBar position="static" style={{ background: '#FFFFFF', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                        <Toolbar style={{ boxShadow: '0px 3px 6px #00000029' }}>
                            <Grid container>
                                <Grid item className="w-100">
                                    <div id='ebm_homepage'>
                                        <div className="elibrary_rightcontainer_icon1">
                                            <img src={Elib} alt="app_vault" width='30px' height='30px' />
                                            <Typography variant="h4" color="black" className="user-data" style={{ marginLeft: '6px', fontSize: '23px' }}>
                                                <div className='display_index'><h4>E-Practice <InfoOutlined style={{ color: '#0054B5', width: '15px', height: '15px', position: 'relative', top: '-5px' }} /></h4></div>
                                            </Typography>
                                        </div>
                                        <div className='d-flex e-align justify-flex-end'>
                                            {search ?
                                                <Search_Elib data={edata} load={load} value={value} handleClick={() => { setEData([]); setValue('') }}>
                                                    <Search value={value} handleSearch={handleChange} placeholder="Search Diseases / Drugs" />
                                                </Search_Elib>

                                                : <SearchIcon style={{ color: 'black', fontSize: '35px' }} onClick={(event) => setSearch(true)} />}
                                            <span className='more_icon'><MoreVertIcon style={{ fontsize: '45px', paddingLeft: '15px' }} /></span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box className="elibrary_homepage_body" sx={{ height: { md: '79vh', sm: 'auto', xs: 'auto' }, overflow: { md: 'scroll', xs: 'scroll' } }}>
                        <Grid item md={12} xs={12}>
                            <Typography variant='h4' className='text_heding'>
                                A Powerful tool to enhance knowledge and Evidence based decision-making
                            </Typography>
                            <Typography variant='h4' className='test_sub_head'>
                                Open Access Journals and Articles
                            </Typography>
                            <div className='search_bar'>
                                <SearchHomepage value={svalue} handleSearch={(value) => setSvalue(value)} />
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div className='practice_toolkit'>
                                <div id='set_content'>
                                    <img src={PracticeTools} id='header_icon'></img>
                                    <h2>Evidence Based Practice Tools</h2>
                                </div>
                                <div className='main_card'>
                                    {arrdata.map((value, index) => {
                                        return (
                                            <Card key={index} className='card_display'>
                                                <CardContent key={index.id} style={{ padding: '4px', height: "70%" }} >
                                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                                        <div className='d-flex'>
                                                            <img src={value.icon} alt="doctor" className='img_sizes'></img>
                                                        </div>
                                                        <div>
                                                            <Typography variant="h5" component="div" className='card_head_text'>{value.heading}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className='sub_text' style={{ lineHeight: '17px' }}>
                                                        <span id="p_text">{value.subtext}</span><br />
                                                    </div>
                                                </CardContent>
                                                <CardActions className='card_actions' style={{ height: "30%" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div>
                                                            <Button size="small" id='btn_learn_more'>Learn More</Button>
                                                        </div>
                                                        <div>
                                                            <img src={share} width='15px' height="15px" id="img_position"></img>
                                                        </div>
                                                    </div>
                                                    {(value.heading === 'Evidence Review') ? <Button onClick={() => history.push('/elib/specialities')} size="small" id='btn_start'>Start<img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                        : (value.heading === 'Drug Information') ? <Button onClick={() => history.push('/elib/drugspecialities')} size="small" id='btn_start'>Start <img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                            : (value.heading === 'Video Guides') ? <Button onClick={() => history.push('/elib/development')} size="small" id='btn_start'>Start <img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                                : (value.heading === 'Standard Practices') ? <Button onClick={() => history.push('/elib/development')} size="small" id='btn_start'>Start <img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                                    : null
                                                    }
                                                </CardActions>
                                            </Card>
                                        )
                                    })}
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div className='practice_toolkit'>
                                <div id='set_content'>
                                    <img src={Clinical_tool} id='header_icon'></img>
                                    <h2>Clinical Tools</h2>
                                </div>
                                <div className='main_card'>
                                    {arrdata1.map((value, index) => {
                                        return (
                                            <Card key={index} className='card_display'>
                                                <CardContent key={index.id} style={{ padding: '4px', height: '70%' }}>
                                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                                        <div className='d-flex'>
                                                            <img src={value.icon} alt="doctor" className='img_sizes'></img>
                                                        </div>
                                                        <div>
                                                            <Typography variant="h5" component="div" className='card_head_text'>{value.heading}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className='sub_text' style={{ lineHeight: '17px' }}>
                                                        <span id="span_text">{value.subtext}</span>
                                                    </div>
                                                </CardContent>
                                                <CardActions className='card_actions' style={{ height: "30%" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div>
                                                            <Button size="small" id='btn_learn_more'>Learn More</Button>
                                                        </div>
                                                        <div>
                                                            <img src={share} width='15px' height="15px" id="img_position"></img>
                                                        </div>
                                                    </div>
                                                    {(value.heading === 'Dose Calculators') ? <Button onClick={() => history.push('/elib/drug-calculator')} size="small" id='btn_start'>Start <img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                        : (value.heading === 'Biomedical Calc') ? <Button onClick={() => history.push('/elib/biomedical-calculator')} size="small" id='btn_start'>Start <img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                            : (value.heading === 'Assessment Tools') ? <Button onClick={() => history.push('/elib/clinical-assessment')} size="small" id='btn_start'>Start <img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                                : (value.heading === 'Check-lists') ? <Button onClick={() => history.push('/elib/check-list')} size="small" id='btn_start'>Start <img src={ArrowRight} style={{ paddingLeft: '6px', width: '20px', height: '20px' }}></img></Button>
                                                                    : null
                                                    }
                                                </CardActions>
                                            </Card>
                                        )
                                    })}
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <div className='practice_toolkit'>
                                <div id='set_content' style={{ paddingBottom: "15px" }}>
                                    <img src={interactive_new} id='header_icon'></img>
                                    <h2>Interactive Learning Apps</h2>
                                </div>
                                <div className='interactive_apps_icons'>
                                    <Card>
                                        <CardContent className='card_icon_display'>
                                            <div>
                                                <img src={neon} className='app_display'></img>
                                                <h4 style={{ textAlign: 'center', fontSize: '14px' }}>NeonatAI</h4>
                                            </div>
                                        </CardContent>
                                    </Card>
                                    <div style={{ paddingLeft: '20px' }}>
                                        <Card>
                                            <CardContent className='card_icon_display'>
                                                <div>
                                                    <img src={lexi} className='app_display'></img>
                                                    <h4 style={{ textAlign: 'center', fontSize: '14px' }}>Lexicom</h4>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div style={{ paddingLeft: '20px' }}>
                                        <Card>
                                            <CardContent className='card_icon_display'>
                                                <div>
                                                    <img src={Moreapps} className='more_app_icon'></img>
                                                    <h4 className='more_app_text'>More Apps</h4>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div className='footer_content'>
                                <div id='footer_text'>
                                    <div><img src={Libray_book} style={{ position: 'relative', top: '6px', width: '30px', height: '30px' }}></img></div>
                                    <div><h4 style={{ color: "#2177C4", paddingLeft: '28px', fontSize: '18px' }}>Contribute Knowledge</h4></div>
                                </div>
                                <div className='below_text'>
                                    <h4>Help us keep information and evidence based data upto date</h4>
                                </div>
                                <div style={{ display: 'flex', marginTop: '25px' }}>
                                    <div><h3 className='enroll_text'>Enroll</h3></div>
                                    <div><img src={Arrow_White} style={{ width: '18px', height: '18px', paddingLeft: '6px' }}></img></div>
                                </div>
                                <div className='footer_last_text'>
                                    <div id='review_text'>
                                        <h3>Policies & Guidelines for Contributors</h3>
                                        <img src={Share_white} id='footer_icon'></img>
                                    </div>
                                    <div id='review_text'>
                                        <h3>Reviewer Policy</h3>
                                        <img src={Share_white} id='footer_icon'></img>
                                    </div>
                                    <div id='review_text'>
                                        <h3>Ethical Legal Terms</h3>
                                        <img src={Share_white} id='footer_icon'></img>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Box>
                </div>
            </Grid>
        </Grid>
    )
}
export default Ebmkithomepage;


