import React, { useState, useEffect } from 'react';
import { Grid, Button, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import PracticeTools from "../../images/stethoscope.svg";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, getMetaData } from '../../redux/Actions/actions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Expcrud from '../Layout/MyProfile/organisation/expcrud';
import axios from 'axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from 'moment';
import URL from '../apiurl'
import { Avatar } from '@mui/material';
import Organisation from '../Atom/modifiedicons/Organisations.svg'
import DeleteIcon from '@mui/icons-material/Delete';

function Addexperience() {

    const [check, setCheck] = useState(false);
    const [addexp, setAddexp] = useState(true);
    const [addexp1, setAddexp1] = useState(false)
    const experience = useSelector(state => state.userProfile.exp)
    console.log(experience, "experience")
    const [exper, setExper] = useState([])
    const dispatch = useDispatch()
    const [state, setState] = useState(
        { id: null, organisation: null, designation: '', city: '', fromDate: null, toDate: null, status: '' })

    useEffect(() => {
        if (experience) {
            getData()
        }
    }, [])

    useEffect(() => {
        setExper(experience)
    }, [experience])

    const handleChange = (name, value) => {
        // console.log(name,value)
        if (name === 'organisation') {
            setState({ ...state, [name]: value })

        }
        else {
            setState({ ...state, [name]: value })

        }
    }
    // const props = "props"
    const getData = () => {
        dispatch(fetchProfile())
    }
    const handleOrg = (name, value, newOrg, logo) => {
        // console.log(logo,name,value,newOrg)
        if (newOrg) {
            setState({ ...state, organisationName: "", organisation: 0, unofficialOrganisation: value, unofficialOrganisationName: name })
        } else {
            setState({ ...state, organisationName: name, organisation: value, unofficialOrganisation: 0, unofficialOrganisationName: "", logo: logo })
        }
    }
    const handleCheck = (e) => {
        setCheck(e.target.checked)
    }
    const handleAdd = async () => {
        let data = {
            "designation": state?.designation,
            "fromDate": moment(state?.fromDate).format('YYYY-MM-DD'),
            "metadata": await getMetaData(),
            "organisation": state?.organisation,
            "city": state?.city,
            "status": "SHOW",
            "toDate": check ? '' : moment(state?.toDate).format('YYYY-MM-DD'),
            "userId": sessionStorage?.getItem("userId"),
            "unofficialOrganisation": state?.unofficialOrganisation,
            "unofficialOrganisationName": state?.unofficialOrganisationName
        }
        axios.post(`${URL.addExperience}`, data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`,
            }
        })
            .then(res => res.data).then(resdata => {
                // console.log(resdata, '**********************')
                // setExper(resdata.data)
                // console.log(exper)
                // setExper([...exper, resdata.data])
                // console.log("dfghjkl")
                getData();
                setAddexp(false)
                setAddexp1(true)

            }
            )
        // .catch(error => { })
        // setCheck(true)
        // setOpen(false)

    }
    const handleremoveinput = () => {
        setState({ name: '', designation: '', city: '', toDate: null, fromDate: null })
        setAddexp(true)
        setAddexp1(false)
    }




    const deleteExperience = (id) => {
        axios.delete(`${URL.deleteExperience}/${id}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        })
            .then(response => {
                getData();
            }).catch(error => { })

    }


    // const deleteExperience = (idx) => {
    //     let experienceList = [...exper]
    //     let list = experienceList.filter((each, index) => idx !== index)
    //     console.log(list, "list")
    //     setExper(list);
    // }
    console.log(exper, "exper")




    return (
        <>
            {(addexp) ?
                <Grid container spacing={2} className="mobile_padding" sx={{ height: { md: '100%' } }}
                    style={{
                        // backgroundColor: "white", boxShadow: "0px 3px 6px #00000029",
                        display: "flex", justifyContent: "center"
                    }}>
                    <Grid item xs={12} md={12}>
                        <div className='complete_prof'>
                            <Link to={`/addeducation`}><ArrowBackIosIcon /></Link>
                            <h1 className='comp_profile'>Complete Your Profile</h1>
                        </div>
                        <div style={{ paddingTop: "20px" }}>
                            <h3 className='step_mobile'>Final Step</h3>
                        </div>
                        <div className='quali_text'>
                            <span className="e-align d-flex" style={{ color: "#01CDE5", fontSize: "21px", fontWeight: "550" }}><img src={PracticeTools} alt="Scholar" width="20px" height="20px" />&nbsp;Experience</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div>
                            <Expcrud state={state} handleChange={handleChange} handleOrg={handleOrg} check={check} handleCheck={handleCheck} nextbtn={true} handleSubmit={handleAdd} skip={true} />
                        </div>
                    </Grid>
                </Grid>
                :
                (addexp1) ?
                    <Grid container sx={{ height: { md: '100vh', xs: "100vh" } }} style={{ backgroundColor: "white", marginTop: "0px", boxShadow: "0px 3px 6px #00000029" }}>
                        <Grid item xs={12} md={12}>
                            <div className='complete_prof'>
                                {/* <Link to={`/addeducation`}> */}
                                <Button
                                    onClick={() => { setAddexp(true); setAddexp1(false) }}
                                >
                                    <ArrowBackIosIcon style={{ color: "#000000" }} />
                                </Button>
                                <h1 className='comp_profile'>Complete Your Profile</h1>
                                {/* </Link> */}
                            </div>
                            <div style={{ paddingTop: "20px" }}>
                                <h3 className='step_mobile'>Final Step</h3>
                            </div>
                            <div className='quali_text'>
                                <span className="e-align d-flex" style={{ color: "#01CDE5", fontSize: "21px", fontWeight: "550" }}><img src={PracticeTools} alt="Scholar" width="20px" height="20px" />&nbsp;Experience</span>
                            </div>
                            {exper !== null && exper?.length > 0 ?
                                <div style={{ display: "flex", justifyContent: "center", }}>
                                    <div className="list" style={{ display: "flex", flexDirection: "column" }}>
                                        {exper?.map((e, i) => (
                                            <div className="sidebar__substat1 sidebar__padding bg__white e-align icon-show" key={i}>
                                                <div className="sidebar__substat ta__center">
                                                    {/* {console.log(e)} */}
                                                    <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                                        {e.logo ?
                                                            <Avatar src={e.logo} style={{ width: '100%', height: "100%", borderRadius: '7px' }} />
                                                            :
                                                            <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                                        }
                                                    </div>
                                                    <div className="sidebar__ml " style={{ fontSize: '13px', textTransform: 'capitalize', }}>
                                                        <p style={{ fontWeight: 'bold', paddingBottom: '3px' }}>{`${(e?.unofficialOrganisationName) ? e?.unofficialOrganisationName : e?.organisationName}`}</p>
                                                        <p style={{ paddingBottom: '3px' }}>{e?.designation}</p>
                                                        <p>{moment(e?.fromDate).format('MMM yyyy')} - {e?.toDate ? moment(e?.toDate).format('MMM yyyy') : 'present'}</p>
                                                    </div>
                                                    <div>
                                                        <IconButton onClick={() => deleteExperience(e.id)}>
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </div>

                                                </div>
                                            </div>
                                        ))

                                        }
                                    </div>
                                </div>
                                :
                                ""
                            }

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container style={{ display: "flex", justifyContent: "center" }}>
                                <div>

                                    <Button
                                        style={{ textTransform: "capitalize" }}
                                        onClick={handleremoveinput}
                                    >
                                        <AddCircleOutlineIcon style={{ color: "#47AEE1", height: "30px", width: "30px", paddingRight: "15px" }} />
                                        <span style={{ color: "#47AEE1", fontSize: "20px" }}>Add Another</span>
                                    </Button>

                                </div>
                                <Grid item xs={12} md={12}>
                                    <div className='skip_btn' style={{ marginTop: "30px" }}>
                                        <Link to={`/`}>
                                            <Button
                                                className="next__btn"
                                                variant="contained"
                                                type="submit"
                                            ><ArrowForwardIcon style={{ color: "white", fontSize: "18px", paddingRight: "10px" }} />Finish</Button>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    ""
            }

        </>
    )
}

export default Addexperience


