import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import { Button, Avatar } from '@mui/material';
// import ribbon from "../../Atom/svg/ribbon.svg";
import badges from '../../../../icons/80X80.svg';
import certificate from '../../../../icons/Group1.svg'
import medal from '../../../../icons/newmedal.svg';
import Link from '../../../../icons/linksource.svg';
import '../../../Molecule/Post/inputOption.css';
import ClearIcon from '@mui/icons-material/Clear';
import '../updateprofile.css';
import '../../../Molecule/Post/inputOption.css';

function DisplayAcc(props) {
    // console.log(props.badges)
    const filterdata = (props.badges) ? props.badges : ""
    const linktype = filterdata && (filterdata.attachment) ? filterdata.attachment.split(".") : ""
    const type = linktype ? linktype[linktype.length - 1] : ""
    // console.log(type)
    const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
    // console.log(regex.test(filterdata.link))
    // let val1 = 
    // console.log(filterdata.link)
    // console.log(type)
    // const dispatch = useDispatch()
    // useEffect(() => {
    //     console.log('first')
    //     dispatch(accomplishment())
    // }, [])

    const previous = "< previous";
    const next = "next >";
    const history = useHistory()
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    function onpreload(loaded, total) {
        // alert('Loading a document: ' + (loaded / total) * 100 + '%');
    }
    const handleclick = () => {
        // history.push(
        //     {
        //         pathname: `/pdfviewer`,
        //         state: ,
        //     }
        // )
    }

    return (
        <>
            {(filterdata && filterdata.hasOwnProperty("title")) ?
                <div className='box_shadow'>
                    {/* {console.log(filterdata.typeOfAccomplishment)} */}
                    {(filterdata.typeOfAccomplishment == "BADGES") ?
                        <div className='box_shadow'>
                            {(type === "pdf") ?
                                // <iframe style={{ width: "100%", maxHeight: "100%" }} className='hide_iframe_border' scrolling="no" src={filterdata.attachment}></iframe>
                                <div>
                                    <div onClick={handleclick}>
                                        <Document
                                            // canvasBackground="transparent"
                                            file={`${filterdata.attachment}`}
                                            renderMode="canvas"
                                            options={{ workerSrc: "/pdf.worker.js" }}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadProgress={onpreload}
                                            className={"pdf_post"}
                                        >
                                            <Page
                                                // width={300}
                                                // height={300}
                                                pageNumber={pageNumber}
                                                renderAnnotationLayer={true}
                                                renderTextLayer={false}
                                                renderForms={true}
                                            />
                                        </Document>
                                    </div>
                                    <div className='bg_transperency'>
                                        <div>
                                            <Button
                                                type="button"
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                                className={`${pageNumber <= 1 ? 'buttondisable' : 'buttonc'}`}
                                            >
                                                {`${previous}`}
                                            </Button>
                                        </div>
                                        <div className="pagec">
                                            {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                disabled={pageNumber >= numPages}
                                                onClick={nextPage}
                                                className={`${pageNumber >= numPages ? 'buttondisable' : 'buttonc'}`}
                                            >
                                                {`${next}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                : <div style={{ display: "flex", justifyContent: "center", maxHeight: "250px", overflow: "hidden" }}>
                                    {(filterdata.attachment) ? <img style={{ width: "100%", height: "inherit", objectFit: "cover" }} src={filterdata.attachment}></img> : <img style={{ maxWidth: "100%", height: "100px", padding: "30px" }} src={badges}></img>}
                                </div>
                            }
                        </div>
                        : (filterdata.typeOfAccomplishment == "CERTIFICATES") ?
                            <div className='box_shadow'>
                                {(type === "pdf") ?
                                    // <iframe style={{ width: "100%", maxHeight: "100%" }} className='hide_iframe_border' scrolling="no" src={filterdata.attachment}></iframe>
                                    <div>
                                        <div onClick={handleclick}>
                                            <Document
                                                // canvasBackground="transparent"
                                                file={`${filterdata.attachment}`}
                                                renderMode="canvas"
                                                options={{ workerSrc: "/pdf.worker.js" }}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                onLoadProgress={onpreload}
                                                className={"pdf_post"}
                                            >
                                                <Page
                                                    // width={300}
                                                    // height={300}
                                                    pageNumber={pageNumber}
                                                    renderAnnotationLayer={true}
                                                    renderTextLayer={false}
                                                    renderForms={true}
                                                />
                                            </Document>
                                        </div>
                                        <div className='bg_transperency'>
                                            <div>
                                                <Button
                                                    type="button"
                                                    disabled={pageNumber <= 1}
                                                    onClick={previousPage}
                                                    className="cursor buttonc"
                                                >
                                                    {`${previous}`}
                                                </Button>
                                            </div>
                                            <div className="pagec">
                                                {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                                            </div>
                                            <div>
                                                <Button
                                                    type="button"
                                                    disabled={pageNumber >= numPages}
                                                    onClick={nextPage}
                                                    className={`${pageNumber >= numPages ? 'buttondisable' : 'buttonc'}`}
                                                >
                                                    {`${next}`}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    : <div style={{ display: "flex", justifyContent: "center", maxHeight: "250px", overflow: "hidden" }}>
                                        {(filterdata.attachment) ? <img style={{ width: "100%", height: "inherit", objectFit: "cover" }} src={filterdata.attachment}></img> : <img style={{ maxWidth: "100%", height: "100px", padding: "30px" }} src={certificate}></img>}
                                    </div>
                                }
                            </div>
                            : (filterdata.typeOfAccomplishment == "ACHIEVEMENTS") ?
                                <div className='box_shadow'>
                                    {(type === "pdf") ?
                                        // <iframe style={{ width: "100%", maxHeight: "100%" }} className='hide_iframe_border' scrolling="no" src={filterdata.attachment}></iframe>
                                        <div>
                                            <div onClick={handleclick}>
                                                <Document
                                                    // canvasBackground="transparent"
                                                    file={`${filterdata.attachment}`}
                                                    renderMode="canvas"
                                                    options={{ workerSrc: "/pdf.worker.js" }}
                                                    onLoadSuccess={onDocumentLoadSuccess}
                                                    onLoadProgress={onpreload}
                                                    className={"pdf_post"}
                                                >
                                                    <Page
                                                        // width={300}
                                                        // height={300}
                                                        pageNumber={pageNumber}
                                                        renderAnnotationLayer={true}
                                                        renderTextLayer={false}
                                                        renderForms={true}
                                                    />
                                                </Document>
                                            </div>
                                            <div className='bg_transperency'>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        disabled={pageNumber <= 1}
                                                        onClick={previousPage}
                                                        className={`${pageNumber <= 1 ? 'buttondisable' : 'buttonc'}`}
                                                    >
                                                        {`${previous}`}
                                                    </Button>
                                                </div>
                                                <div className="pagec">
                                                    {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        disabled={pageNumber >= numPages}
                                                        onClick={nextPage}
                                                        className={`${pageNumber >= numPages ? 'buttondisable' : 'buttonc'}`}
                                                    >
                                                        {`${next}`}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        : <div style={{ display: "flex", justifyContent: "center", maxHeight: "250px", overflow: "hidden" }}>
                                            {(filterdata.attachment) ? <img style={{ width: "100%", height: "inherit", objectFit: "cover" }} src={filterdata.attachment}></img> : <img style={{ maxWidth: "100%", height: "100px", padding: "30px" }} src={medal}></img>}
                                        </div>
                                    }
                                </div> : ""
                    }
                    <div>
                        <div className='sidebar__pt pl-1' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ width: "70%" }}>
                                {(regex.test(filterdata.link) === true) ? <a href={(regex.test(filterdata.link) === true) ? filterdata.link : ""} target="_blank"><p style={{ textTransform: "capitalize", color: "rgb(54, 69, 79)" }} className='title_acc_text sidebar__pt'>{filterdata.title ? (filterdata.title) : ""}{" "}<img src={Link}></img></p></a> : <p style={{ textTransform: "capitalize", color: "rgb(54, 69, 79)" }} className='title_acc_text sidebar__pt'>{filterdata.title ? (filterdata.title) : ""}{" "}</p>}
                                <p style={{ textTransform: "capitalize", marginTop: "10px" }} className='title_acc_text1'>{filterdata.issuer ? filterdata.issuer.displayName : filterdata.organisation ? filterdata.organisation.displayName : filterdata.organisationName || filterdata.unofficialOrganisationName}</p>
                                {/* <p style={{ textTransform: "capitalize", paddingBlockEnd: "10px", marginTop: "10px" }} className='title_acc_text1'>{filterdata.coAuthors ? filterdata.coAuthors : ""}</p> */}
                                {/* {console.log(filterdata.coAuthors)} */}
                                <div style={{ paddingBlockEnd: "10px", marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: filterdata.coAuthors }} />

                            </div>
                            <div style={{ paddingRight: "5px", width: "30%" }}>
                                {(filterdata.date) ?
                                    <p style={{ fontSize: "14px", fontWeight: "300", textAlign: "center" }}>DOP:{" "}{moment(filterdata.date).format('DD-MMM-yyyy')}</p> : ""
                                }
                            </div>
                            {/* <h6 style={{ fontSize: "15px", fontWeight: "400", textTransform: "capitalize", paddingBottom: "15px", color: "gray" }}>{filterdata.summary || filterdata.description}</h6> */}
                        </div>
                    </div>
                </div > : ""
            }
        </>
    )
}

export default DisplayAcc