import { Avatar, Typography } from "@mui/material";
import { Actions, Description, Postview, MetaView } from '../Post/commonFun'
import React, { useState, useEffect } from "react";
import PublicIcon from '@mui/icons-material/Public';
import Peers from '../../Atom/svg/peers.svg';
import Doc from '../../../images/Logo2.svg'
import UserService from "../../../services/UserService";
import './feedpost.css';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { timeSince } from "../Post/commonFun";
import { Box } from "@mui/material";
import Photo from '../feedposts/photo';
import Event from '../feedposts/events/eventPost'
import { getPost } from "../../../redux/Actions/actions";
import Poll from '../feedposts/poll/pollPost';
import axios from "axios";
import PdfView from "../Post/PdfView";
import ArticleView from '../../Article/articlepost';
import DisplayAcc from "../../Layout/MyProfile/organisation/DisplayAcc";
import MemoCom from "../Post/Post";


export default function Mobileviewpost(props) {
    // console.log(props.post)
    const [post, setPost] = useState(props.post)
    const [metatags, setMetatags] = useState({})
    const [meta, setMeta] = useState({})
    const [index, setIndex] = useState(0)
    const [shareEdit, setShareEdit] = useState(false)

    const history = useHistory()
    const image = props.post.sourceLink
    const data = post?.sourceLink?.split(',').slice(0, -1)
    // console.log(data)
    // console.log(data.length)

    useEffect(() => {
        setPost(props.post)
    }, [props.post])

    useEffect(() => {
        const url = post.description.split(" ")
        const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
        let val1 = regex.test(url[url.length - 1])
        if (val1 === true) {
            // axios.post('http://localhost:3001/scrapUrl', { url: url[i] }).then(res => {
            axios.post(`${process.env.REACT_APP_API_SCRAPE}`, { url: url[url.length - 1] }).then(res => {
                // console.log(res.data);
                if (res.data !== null) {
                    setMetatags(res.data.og)
                    setMeta(res.data.meta)
                }
            })
        }
        else {
            setMetatags({})
            setMeta({})
        }
    }, [post.description])

    const goToNextSlide = () => {

        const newPointer = index === data.length - 1 ? 0 : index + 1;
        setIndex(newPointer)
    }

    const goToPrevSlide = () => {
        const newPointer = index === 0 ? data.length - 1 : index - 1;
        // console.log(newPointer)
        setIndex(newPointer)
    }

    const handlesharebutton = () => {
        setShareEdit({ open: true, name: 'share' })
    }

    return (
        <div className="padding_post" id='comment_margin_end'>
            <div className="Mobilepost__header" id="display_view_post" style={{ padding: "10px 0px 0px 10px" }}>
                <Avatar src={post.userData.profilePic} style={{ borderRadius: '7px', border: '1px solid lightgrey' }} />
                <div className="post__info">
                    <div>
                        <h2 className="cursor capitalize" style={{ color: 'black' }}><span onClick={() => post.userId !== 0 ? history.push(`/profile/${(post.userData.fullName.replace(/ /g, '')).toLowerCase()}-${post.userId}`) : history.push(`/organization/${post.pageId}`)}>{post.userData.fullName} </span>
                            <span style={{ textTransform: 'initial', color: '#635660', fontWeight: 'normal', cursor: 'text' }}>
                                {post.shared ? ' shared a post' : ''}
                            </span></h2>
                        <p className="capitalize">{post.caption}</p>
                        <Typography style={{ marginTop: '4px' }} className="fabdetail e-align">
                            <span>{`${timeSince(post.createDate)}`}</span>&nbsp;
                            {post.postPrivacy === 'ANYONE' ? <PublicIcon style={{ fontSize: '19px' }} /> : post.postPrivacy === 'DOCSTETH_USERS' ? <img src={Doc} alt="DOCSTETH USERS" width="22px" /> : <img src={Peers} alt="Peers" width="30px" />}
                        </Typography>
                    </div>
                </div>
            </div>


            <div style={{ padding: '10px 0px' }}>
                {post.description && <Description description={post.description} />}
            </div>
            <div>
                {((post.postType !== "IMAGE")) ?
                    <Box style={{ pointerEvents: props.share ? 'none' : 'all' }}>
                        {post.postType !== 'TEXT' && post.postType !== 'CELEBRATE' ?

                            post.postType === "POLL" ? <Poll post={post} border={props.share ? true : false} />

                                : post.postType === 'FILE' ?

                                    <PdfView data={`${post.sourceLink}`} stateview={true} />
                                    // <iframe height="400px" width="100%" src={`${post.sourceLink[0]}#toolbar=0&navpanes=0&scrollbar=0`} scrolling="no" frameBorder="0" />

                                    : post.postType === 'VIDEO' ? <video style={{ backgroundColor: 'black' }} width="100%" height="400" src={post.sourceLink.split(',')[0]} controlsList="nodownload" controls />

                                        : post.postType === 'EVENT' ? <Event post={post} getPostById={getPost} />

                                            : post.postType === 'ARTICLE' ? <ArticleView post={post} handlesharebutton={handlesharebutton} /> : ''

                            : metatags.title || meta.title ? <MetaView metatags={metatags} meta={meta} /> : post.postType === 'CELEBRATE' && post.accomplishmentData && post.accomplishmentData.accomplishmentId !== 0 ? <DisplayAcc badges={post.accomplishmentData} /> : ""}
                    </Box> :
                    <>
                        {(post.postType === "IMAGE" && (data.length === 1)) ?
                            <div>
                                {/* {console.log(data[0])} */}
                                <img alt="Post Image" style={{ width: "100%" }} className="image_sizes" src={data[0]}>
                                </img>
                            </div>
                            :
                            <div style={{ maxHeight: '500px', overflow: 'hidden', position: 'relative' }}>
                                <img alt="Post Image" className="image_sizes" src={data[index]}></img>
                                <ArrowBackIosIcon onClick={() => goToPrevSlide()} className="left_arrow_image" />
                                <ArrowForwardIosIcon onClick={() => goToNextSlide()} className="right_arrow_image" />
                            </div>


                        }
                    </>
                }
                {/* {console.log(image[0])} */}
            </div>
            <div className="posting_view" style={{ padding: '10px' }}>
                {post.postType ? props.children : ''}
                <Actions post={post} getPostById={props.getPostById} openComment={true} showLikes={true} signup={true} />
            </div>
        </div >
    )
}