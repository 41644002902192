import React, { useState, useEffect } from 'react';
import '../MyProfile/updateprofile.css';
import Bookmarkposts from './Bookmarkposts';
import { Grid } from '@mui/material';


export default function Bookmark() {
    const [state, setState] = useState(0)
    useEffect(() => {
        setState(1)
    }, [])
    return (
        <>
            <Grid container>
                {/* <Grid item xs={12} md={12}>
                    <Typography variant='h6' style={{ textAlign: "center" }}>Work In Progress</Typography>
                </Grid> */}
                <Grid item xs={12} md={4} className="margin_myprofile" style={{ marginTop: "10px" }}>
                    <div className='border_img sidebar__padding'>
                        <div className='sidebar__pt'>
                            <p id="bookmark_padd" className={`${state == 1 ? 'media_text' : ''}`} onClick={() => setState(1)}>Posts / Media</p>
                            <p id="bookmark_padd" className={`${state == 2 ? 'media_text' : ''}`} onClick={() => setState(2)}>E-practice</p>
                            <p id="bookmark_padd" className={`${state == 3 ? 'media_text' : ''}`} onClick={() => setState(3)}>Medscope</p>
                        </div>
                    </div>
                    <div className='border_img sidebar__padding mt-10'>
                        <div className='sidebar__pt'>
                            <p className='media_text3 cursor'>Notes</p>
                            <p className='media_text3 cursor'>Annotation</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    {
                        (state == 1) ?
                            <div className='mt-10'>
                                <Bookmarkposts />
                            </div> :
                            (state == 2) ?
                                <div>
                                    <div className='mt-10'>
                                        <div className='border_img sidebar__padding' style={{ maxHeight: "100px" }}>
                                            <div>
                                                <p style={{ fontSize: "18px", color: "#1900B2", fontWeight: "500" }}>Evidence based Articles</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border_img sidebar__padding mt-10' style={{ maxHeight: "200px" }}>
                                        <div className='inter_job'>
                                            <p>Journal Citations</p>
                                        </div>
                                    </div>
                                    <div className='border_img sidebar__padding mt-10' style={{ maxHeight: "200px" }}>
                                        <div className='inter_job'>
                                            <p>Calculators</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                (state == 3) ?
                                    <div>
                                        <div className='mt-10'>
                                            <div>
                                                <p style={{ fontSize: "18px", color: "#1900B2", paddingLeft: "10px" }}>Events</p>
                                            </div>
                                            <div className='border_img sidebar__padding' style={{ maxHeight: "100px" }}>
                                                <p className='media_text1'>Upcomming</p>
                                                <p className='media_text1'>Completed</p>
                                            </div>
                                        </div>
                                        <div className='border_img sidebar__padding mt-10' style={{ maxHeight: "200px" }}>
                                            <div className='inter_job'>
                                                <p>Interested Jobs</p>
                                            </div>
                                        </div>
                                        <div className='border_img sidebar__padding mt-10' style={{ maxHeight: "200px" }}>
                                            <div className='inter_job'>
                                                <p>Interested Courses</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                    }
                </Grid>
            </Grid>
        </>
    )
}
