import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './signup.css'
import Logo from './docstethLogo';
import UserService from '../../services/UserService';
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchComponent from '../Molecule/Header/search';


export default function Header(props) {


  const history = useHistory()

  const login = useCallback(() => {
    history.push('/');
    UserService.doLogin();
  }, [])

  // const handleForgot = () => {
  //   history.push('/forgotusername')
  // }


  return (
    <Box sx={{ flexGrow: 1 }} className="bg-header" id="z_index">
      <AppBar position="static" className="tab-app" elevation={5}>
        <Toolbar sx={{ mt: 1, justifyContent: { xs: 'space-between' } }}>
          <div style={{ display: 'grid' }}>
            <Logo subtext='header-logo-text' header='header-logo' />
            <p className='logo-sub'>A Global Practitioners Consortium</p>
          </div>
          {props.children}
          {/* <Typography variant="h6" component="p" sx={{ display: { md: 'flex', sm: 'flex', xs: `${props.children ? 'none' : 'block'}` } }} className="nav-header sign-color"> */}
          {/* <Box component="span" sx={{ pr: { sm: '7px', xs: '0px!important' } }}>FAQs</Box> */}
          {/* </Typography> */}
          <div className='search-component'>
            <SearchComponent />
          </div>
          <div></div>

          {props.search}
          <div className='space_btw_center'>
            {/* <Button sx={{ textTransform: "none !important" }} onClick={handleForgot} ><Typography variant='body2' sx={{ color: "#4985aa" }}>Forgot Username?</Typography></Button> */}
            <Box component="span" className='header-sign sign-color site-map' sx={{ display: { sm: 'inline-flex', xs: 'block' } }}>Sitemap</Box>
            <Button color="inherit" className="header-sign sign-color header-signin" sx={{ display: { sm: 'flex', xs: `${props.children ? 'none' : 'flex'}` } }} onClick={login}><ArrowForwardIcon style={{ marginRight: "1rem" }} />SignIn</Button>
          </div>
        </Toolbar>
        {props.children ?
          <Toolbar sx={{ mt: 1, display: { sm: 'none' }, justifyContent: 'space-between' }}>
            <Typography variant="h6" component="p" sx={{ pl: 0 }} className="nav-header sign-color">
              <Box component="span">Site Map</Box>
              <Box component="span">FAQs</Box>
            </Typography>
            <Button id='download_app'><SecurityUpdateIcon />Download App</Button>
            <Button color="inherit" className="header-sign sign-color" style={{ display: "flex", alignItems: 'center' }} onClick={login}>Sign In</Button>
          </Toolbar> : ''}
      </AppBar>
    </Box >
  );
}
