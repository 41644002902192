import { Box, Typography, Button, Paper, ClickAwayListener, MenuList, MenuItem, Grid, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import MoreVert from '@mui/icons-material/MoreVert';
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export const ImgView = ({ logo, size }) => {
    return (
        <div style={{ padding: size ? '10px' : '6px', boxShadow: '2px 3px 10px grey', borderRadius: '10px', width: size ? '40px' : '38px', height: size ? '40px' : "38px" }}>
            <img src={logo} alt="logo" style={{ objectFit: 'contain' }} height={'100%'} width="100%" />
        </div>
    )
}

const AppInfo = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const { id, logo, appName, description, link } = props.data
    const history = useHistory()
    const { vault } = useParams()

    const [comingsoon, setComingSoon] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Box key={id} className="space_btw_center" sx={{ my: 1, width: '100%', }}>

            <Grid container spacing={2}>
                <Grid item xs="auto">
                    {/* <div style={{ padding: '6px', boxShadow: '2px 3px 10px grey', borderRadius: '10px', width: '38px', height: "38px" }}>
                        <img src={logo} alt="logo" style={{ objectFit: 'contain' }} height={'100%'} width="100%" />
                    </div> */}
                    <ImgView logo={logo} />
                </Grid>
                <Grid item xs sx={{ overflow: 'hidden' }}>
                    {props.suggested ?
                        // <Typography onClick={() => { history.push(`${vault ? vault : '/marketplace/appvault'}/${appName.toLowerCase()}-${id}`); props.toggleDrawer && props.toggleDrawer() }} sx={{ fontWeight: "600", textTransform: "capitalize", cursor: "pointer" }} >{appName}</Typography> :
                        <Typography onClick={() => setComingSoon(true)} sx={{ fontWeight: "600", textTransform: "capitalize", cursor: "pointer" }} >{appName}</Typography> :
                        <p className='font-bold'>
                            <a href={link} target="_blank" style={{ textTransform: "capitalize" }}>{appName}</a>
                        </p>
                    }
                    <p className='font-caption text-ellipsis mt-10' style={{ fontWeight: 300 }}>{description}</p>
                </Grid>
                <Grid item xs={props.suggested ? 3 : 2} className="textCenter">
                    {props.suggested ?
                        <Button className="app_gallery" onClick={() => { history.push(`${vault ? vault : '/marketplace/appvault'}/${appName.toLowerCase()}-${id}`); props.toggleDrawer && props.toggleDrawer() }}>Get</Button>
                        // <Button className="app_gallery" onClick={() => setComingSoon(true)}>Get</Button>
                        :
                        <>
                            <MoreVert style={{ position: 'relative' }} className='cursor' onClick={(event) => { setAnchorEl(event.currentTarget); setOpen(true) }} />
                            {open ? <Box style={{ position: 'absolute', right: '15px', zIndex: 1 }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                        >
                                            <MenuItem onClick={handleClose} sx={{ fontSize: '13px', padding: '5px!important' }}><a href={link} target="_blank">Open in New Tab</a></MenuItem>
                                            <MenuItem onClick={handleClose} sx={{ fontSize: '13px', padding: '5px!important' }}>Hide</MenuItem>
                                            <MenuItem onClick={handleClose} sx={{ fontSize: '13px', padding: '5px!important' }}>Learn More</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Box> : ''}
                        </>}
                </Grid>
            </Grid>
            <Dialog onClose={() => setComingSoon(false)} open={comingsoon}>
                <DialogContent>
                    {/* <Typography sx={{ fontSize: "20px" }}>Launching on 26<sup>th</sup> January</Typography> */}
                    <Typography sx={{ fontSize: "20px", width: "280px", textAlign: "center" }}>Coming Soon.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setComingSoon(false)} sx={{ color: "black" }}>OK</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

const Apps = ({ Component, ...props }) => {
    return Component ? <Component>{props.children}</Component> : <>{props.children}</>
}

export const AppsView = ({ heading, apps, suggested, Component, gallery, toggleDrawer, ...props }) => {

    return (
        <>
            <Typography className='dflex font-bold b-color' {...props}>{heading}</Typography>
            <Apps Component={Component}>
                <Grid container spacing={1}>
                    {apps?.length > 0 ? apps.map((app, i) => (
                        <Grid item md={gallery ? 6 : 12} xs={12} key={i}>
                            <AppInfo data={app} suggested={suggested} gallery={gallery} toggleDrawer={toggleDrawer} />
                        </Grid>
                    )) : <Grid item md={gallery ? 6 : 12} xs={12} sx={{ p: 2, ml: 1 }}>No Apps</Grid>}
                </Grid>
            </Apps>
        </>
    )
}