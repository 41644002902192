import { Box, Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, MenuItem, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useMemo, useState } from 'react'
import { useEffect } from 'react';
import { TextValidator, SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { headers } from '../../../../redux/Actions/connectActions';
import UserService from '../../../../services/UserService';
import URL from '../../../apiurl';



const FormCustomInput = ({ label, width, ...props }) => {
    return (
        <>
            {label && <Typography style={{ padding: "5px 0" }}>{label}</Typography>}
            <TextValidator
                {...props}
                variant='outlined'
                size="small"
                // sx={{ width: { xs: "100%", sm: "100%", md: "350px", lg: "350px" } }}
                // sx={{ width: width ? width : { xs: "100%", sm: "100%", md: "350px", lg: "350px" }, boxShadow: '0px 0px 13px 0px rgba(222,222,222,1)', borderRadius: "10px", '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                sx={{ width: width ? width : { xs: "100%", sm: "100%", md: "100%", lg: "350px" }, boxShadow: '0px 0px 13px 0px rgba(222,222,222,1)', borderRadius: "10px", '.MuiOutlinedInput-notchedOutline': { border: 0 } }}

            />
        </>
    )
}

const FormCustomSelect = ({ label, placeholder, arr, width, ...props }) => {

    return (
        <>
            {label && <Typography style={{ padding: "5px 0" }}>{label}</Typography>}

            <SelectValidator
                size="small"
                id="formSelect"
                label={placeholder}
                {...props}
                inputProps={{ 'aria-label': 'Without label' }}
                defaultValue=""
                // sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                // sx={{ width: width ? width : { xs: "100%", sm: "100%", md: "350px", lg: "350px" }, boxShadow: '0px 0px 13px 0px rgba(222,222,222,1)', borderRadius: "10px", '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                sx={{ width: width ? width : { xs: "100%", sm: "100%", md: "100%", lg: "350px" }, boxShadow: '0px 0px 13px 0px rgba(222,222,222,1)', borderRadius: "10px", '.MuiOutlinedInput-notchedOutline': { border: 0 } }}


            >

                {arr?.map((item) => (
                    <MenuItem
                        key={item}
                        value={item}
                    >
                        {item}
                    </MenuItem>
                ))}

            </SelectValidator>
        </>
    )
}


const Billing = () => {

    const [formData, setFormData] = useState({
        user_id: parseInt(sessionStorage.getItem("userId")),
        firstname: "",
        middlename: "",
        lastname: "",
        organizationname: "",
        billOnOrganization: false,
        address: "",
        state: "",
        district: "",
        subdistrict: "",
        town: "",
        pin: "",
        payment_method: "",
        email: "",
        phone_num: "",

    })

    console.log(formData, 'formmm')


    const [prefilledData, setPrefilledData] = useState({
        email: "",
        phone_num: "",
    })
    console.log(prefilledData, 'ppp')

    const handlePrefilledData = (e) => {
        const { name, value } = e.target
        setPrefilledData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const [allStates, setAllStates] = useState([])
    const [allDistricts, setAllDistricts] = useState(["select state "])
    const [allSubDistricts, setallSubDistricts] = useState(["select district "])
    const [allVillages, setAllVillages] = useState(["select sub-district "])

    const [prevBillingDetails, setPrevBillingDetails] = useState([])
    console.log(prevBillingDetails, "prevv")
    const [checkedSelectedBilling, setCheckedSelectedBilling] = useState({});
    console.log(checkedSelectedBilling, "cccc")
    const [newBill, setNewBill] = useState(false)

    const [spinner, setSpinner] = useState(false)

    const dispatch = useDispatch()

    const handlecheckedSelectedBilling = (item) => {
        if (item.id == checkedSelectedBilling.id) {
            setCheckedSelectedBilling({})
            setFormData({
                user_id: parseInt(sessionStorage.getItem("userId")),
                firstname: "",
                middlename: "",
                lastname: "",
                organizationname: "",
                billOnOrganization: false,
                address: "",
                state: "",
                district: "",
                subdistrict: "",
                town: "",
                pin: "",
                payment_method: "",
                email: "",
                phone_num: "",
            })
            setNewBill(false)

        } else {
            setCheckedSelectedBilling(item);
            setFormData(item)
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target
        setFormData((prev) => {
            return {
                ...prev,
                [name]: type === "checkbox" ? checked : value
            }
        })

    }


    const { url } = useRouteMatch()
    const history = useHistory()

    const all = useParams()



    useEffect(() => {
        setSpinner(true)
        // axios.get(`${URL.getBillingDetail}/${UserService.getUserId()}`)
        axios.get(`${URL.getBillingDetail}/${UserService.getUserId()}`, headers())
            .then(res => {
                // setPrevBillingDetails([])
                setPrevBillingDetails(res.data)
                if (res.data[0]) {
                    setCheckedSelectedBilling(res.data[0]);
                    setFormData(res.data[0])
                }
                // console.log(res.data[0], "hey")
                setSpinner(false)


            })
            // .then(res => setPrevBillingDetails([]))
            .catch(err => {
                setSpinner(false)
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (prevBillingDetails.length == 0) {
            setNewBill(true)
        } else {
            setNewBill(false)
        }
    }, [prevBillingDetails])

    const handleSave = () => {
        if (Object.keys(checkedSelectedBilling).length == 0 && newBill === false) {
            return true
        } else {
            return false
        }
    }


    const SubmitForm = (e) => {

        sessionStorage.setItem("isConfirm", true)
        // history.push(`${url}/confirm`)

        // let data = { ...formData, ...prefilledData }
        // console.log(data)

        const prefilledData1 = { email: formData.email, phone_num: formData.phone_num }

        axios.post(`${URL.billingDetail}`, formData, headers())
            .then(res => {
                const billingid = res.data.id
                dispatch({ type: 'BILLINGDETAILS', payload: { ...prefilledData1, billingid: billingid } })
                history.push(`${url}/${billingid}/confirm`)
            }).catch(err => {
                console.log(err)
            })
    }

    const getAllState = () => {
        axios.get(`${URL.getAllState}`, headers())
            .then(res => {
                setAllStates(res.data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    const getAllDistrict = () => {
        axios.get(`${URL.getAllDistrict}/${formData.state}`, headers())
            .then(res => {
                setAllDistricts(res.data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    const getAllSubDistrict = () => {
        axios.get(`${URL.getAllSubDistrict}/${formData.district}`, headers())
            .then(res => {
                setallSubDistricts(res.data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    const getAllVillage = () => {
        axios.get(`${URL.getAllVillage}/${formData.subdistrict}`, headers())
            .then(res => {
                setAllVillages(res.data.data)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        console.log(formData.subdistrict, "kkkk")
        if (formData.subdistrict !== "") {

            getAllVillage()
        }
    }, [formData])

    useEffect(() => {
        if (formData.district !== "") {
            getAllSubDistrict()
        }
    }, [formData])

    useEffect(() => {
        if (formData.state !== "") {
            getAllDistrict()
        }
    }, [formData])

    useEffect(() => {
        getAllState()
    }, [])

    const generateArrayOfYears = () => {
        var min = new Date().getFullYear()
        var max = min + 20
        var years = []

        for (var i = min; i <= max; i++) {
            years.push(i)
        }
        return years
    }

    const year = useMemo(() => {
        return generateArrayOfYears()
    }, [])


    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


    const creditcard = ['Credit Card', 'Debit Card']

    const paymentMethodOptions = ["Credit Card", "Debit Card", "UPI", "Net Banking"]

    return (
        <div>
            {spinner && <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}

            {prevBillingDetails.length > 0 && spinner == false ?
                <div style={{ border: "1px solid lightgray", borderRadius: "4px", padding: "5px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0 30px 0 10px", }}>
                        <Typography sx={{ padding: "10px 0", fontWeight: "500", fontSize: "20px" }} >Select Billing Address</Typography>
                        <Typography sx={{ padding: "10px 0", fontWeight: "500" }} >Select</Typography>
                    </div>
                    <Box sx={{ height: "300px", overflow: "scroll", marginBottom: "15px" }}>
                        {prevBillingDetails.map((item, i) => {
                            return (<Card key={i} sx={{ margin: 1 }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={6}>
                                            <Typography variant='body1' mb={1}>{item?.firstname} {item?.lastname}</Typography>
                                            <Typography variant='body2' mb={1}>{item?.phone_num}</Typography>
                                            <Typography variant='body2' mb={1}>{item?.email}</Typography>
                                            <Typography variant='body2' mb={1}>{item?.address}</Typography>
                                            <Typography variant='body2' mb={1}>{item?.town} {item.district}</Typography>
                                            <Typography variant='body2'>{item?.state} - {item?.pin}</Typography>
                                        </Grid>
                                        <Grid item sm={6} md={4}>
                                            <Typography variant='body2'>Payment Method</Typography>
                                            <Typography variant='body1'>Credit / Debit Card</Typography>
                                        </Grid>
                                        <Grid item sm={6} md={2} sx={{ display: 'flex', justifyContent: "end", alignItems: "center", }}>
                                            <Checkbox checked={item.id == checkedSelectedBilling.id} onChange={() => handlecheckedSelectedBilling(item)} />
                                        </Grid>
                                    </Grid>
                                    {/* <Box sx={{ width: "280px" }}>
                                        <Typography variant='body1'>{item?.firstname} {item?.lastname}</Typography>
                                        <Typography variant='body1'>{item?.address}</Typography>
                                        <Typography variant='body1'>{item?.town} {item.district}</Typography>
                                        <Typography variant='body1'>{item?.state} - {item?.pin}</Typography>
                                    </Box>
                                    <div style={{ alignSelf: "self-start" }}>
                                        <Typography variant='body2'>Payment Method</Typography>
                                        <Typography variant='body1'>Credit / Debit Card</Typography>
                                    </div>
                                    <div>
                                        <Checkbox checked={item.id == checkedSelectedBilling.id} onChange={() => handlecheckedSelectedBilling(item)} />
                                    </div> */}
                                </CardContent>
                            </Card>)
                        })}
                    </Box>
                </div>

                : null
            }

            {spinner == false ? <>
                <Box sx={{ margin: { xs: 0, sm: "20px", md: "20px" } }}>
                    <ValidatorForm onSubmit={SubmitForm} onError={() => { }}>
                        {Object.keys(checkedSelectedBilling).length == 0 && newBill ?
                            <>
                                <Typography sx={{ padding: "10px 0", fontWeight: "500", fontSize: "20px" }} mb={1} >Billing Details</Typography>
                                <Box sx={{ marginLeft: { xs: 0, sm: 0, md: "15px" } }}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Typography mb={1} sx={{ fontWeight: "500" }}>Payment Method</Typography>
                                        <FormCustomSelect name="payment_method" value={formData.payment_method} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="Credit / Debit Card" arr={paymentMethodOptions} />
                                    </Grid>
                                    <Typography mt={2} sx={{ fontWeight: "500" }}>Billing Address</Typography>
                                    <Grid container spacing={2} my={1}>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <FormCustomInput name="firstname" value={formData.firstname} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="First Name" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomInput name="middlename" value={formData.middlename} onChange={handleChange} placeholder="Middle Name" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomInput name="lastname" value={formData.lastname} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="Last Name" />
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2} my={1}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            {formData.billOnOrganization ?
                                                <FormCustomInput name="organizationname" value={formData.organizationname} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="Organistion Name" />
                                                :
                                                <FormCustomInput name="organizationname" value={formData.organizationname} onChange={handleChange} placeholder="Organistion Name" />
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="billOnOrganization" value={formData.billOnOrganization} onChange={handleChange} />} label="Bill on Organisation Name" />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} my={1}>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomInput name="address" value={formData.address} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="Address Line 1 (Building name/ flat no. )" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomSelect name="state" value={formData.state} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="State" arr={allStates} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomSelect name="district" value={formData.district} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="District" arr={allDistricts} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomSelect name="subdistrict" value={formData.subdistrict} onChange={handleChange} placeholder="Sub-District" arr={allSubDistricts} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomSelect name="town" value={formData.town} onChange={handleChange} placeholder="Town / village" arr={allVillages} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomInput name="pin" value={formData.pin} onChange={handleChange} validators={['required']} errorMessages={['Required']} placeholder="Postal PIN" />
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2} my={1}>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <FormCustomInput name="email" value={formData.email} placeholder="Enter Email" onChange={handleChange} validators={['required']} errorMessages={['Required']} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormCustomInput name="phone_num" value={formData.phone_num} placeholder="Enter Phone no" onChange={handleChange} validators={['required']} errorMessages={['Required']} />
                                        </Grid>
                                    </Grid>

                                </Box>
                            </>
                            : null}

                        <Box sx={{ marginLeft: { xs: 0, sm: 0, md: "15px" } }} mt={2}>


                            <Grid item xs={12} sm={12} md={11}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px", padding: { xs: "0 0 40px" }, margin: "0" }} my={{ md: 2 }}>
                                    <div>
                                        {
                                            Object.keys(checkedSelectedBilling).length == 0 && newBill == false ?
                                                <div style={{ display: 'flex', justifyContent: "center", margin: "10px 0" }}>
                                                    <Button onClick={() => setNewBill(true)} variant="outlined" sx={{ borderRadius: "40px", padding: "4px 15px !important", color: "#3b10cc", borderColor: "#3b10cc !important" }}>Create New </Button>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                        <Button onClick={() => history.goBack()} sx={{ textTransform: "lowercase", color: "black" }}><Typography sx={{ fontSize: "20px" }}>Cancel</Typography></Button>
                                        <Button disabled={handleSave()} type="submit" variant="contained" sx={{ backgroundColor: "#3b10cc", padding: "0 15px !important", height: "30px", borderRadius: "8px", width: "100px" }}>Save</Button>
                                    </div>
                                </Box>
                            </Grid>
                        </Box>

                    </ValidatorForm >
                </Box >
            </> : null}







        </div >
    )
}

export default Billing