import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

import { useHistory } from 'react-router-dom';
import URL from '../../../../../../apiurl';



const DeleteDeptGalleryItem = ({ open, close, delObj, parentDialogCls, getUpdatedList }) => {

    const [alert, setAlert] = useState({
        open: false,
        msg: ""
    })

    const history = useHistory();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({
            open: false,
            msg: ""
        })
    };


    const DeleteOrgFn = (Obj) => {
        console.log(Obj, "0000")

        axios.delete(`${URL.deleteGalleryItem}${Obj?.id}`).then((res) => {
            setAlert({
                open: true,
                msg: "Successfully deleted the Item"
            })
            close();
            parentDialogCls();
            getUpdatedList();
            console.log(res.data)
        }).catch((err) => {
            setAlert({
                open: true,
                msg: "Somewent wrong unable to delete Please try after some time"
            })
        });



    }

    // this piece of code should keep above the return only don't change it
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>


            <React.Fragment>

                <Dialog
                    open={open}
                    onClose={close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Caution</div>
                    </DialogTitle>
                    <DialogContent>
                        Are sure do you want to delete the particular Gallery Item because it will not revert back further !
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={() => DeleteOrgFn(delObj)}>Yes</Button>
                        < Button variant="contained" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>No</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

            <Snackbar
                open={alert?.open}
                // autoHideDuration={6000}
                message={alert.msg}
                onClose={handleClose}
                action={action}
            />
        </div>
    )
}

export default DeleteDeptGalleryItem;