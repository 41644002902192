import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { getAppsByUserId, headers } from '../../../../redux/Actions/connectActions'
import URL from '../../../apiurl'
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import UserService from '../../../../services/UserService'
import { getMetaData } from '../../../../redux/Actions/actions'
import { useDispatch } from 'react-redux'


const CountrySelect = () => {
  let { appname, id } = useParams()

  const [country, setCountry] = useState('INDIA')
  const [orderid, setOrderid] = useState('')
  const [razorPayId, setRazorPayID] = useState("")
  const [razorOrderId, setRazorOrderId] = useState("")
  const [razorSignature, setRazorSignature] = useState("")

  const dispatch = useDispatch()

  // console.log(razorPayId, razorOrderId, razorSignature, orderid, "state fff")

  // const countryarr = ["India", "America", "Russia"]

  const [allcountries, setAllCountries] = useState([])

  const [openSnack, setOpenSnack] = useState(false)
  const handleClose = () => {
    setOpenSnack(false);
  };


  const handleChange = (e) => {
    setCountry(e.target.value)
  }

  const [data, setData] = useState([])

  const { url } = useRouteMatch()
  const history = useHistory()

  // const getOrderId = {
  //   amount: 200,
  //   currency: "INR"
  // }

  const userId = sessionStorage.getItem("userId")

  // const transaction = async (razorpay_payment_id, razorpay_order_id, razorpay_signature, status) => {
  //   let data = {
  //     description: "",
  //     metadata: await getMetaData(),
  //     order_id: razorpay_order_id,
  //     payment_id: razorpay_payment_id,
  //     signature: razorpay_signature,
  //     transaction_mode: "card",
  //     user_id: userId,
  //     amount: amount,
  //     currency: "INR",
  //     transaction_status: status
  //   }

  //   await axios.post(`${URL.transactionCreate}`, data, headers()).then(res => {
  //     setOpenSnack(true);
  //     subscribeToApp(razorpay_payment_id)
  //     // setTimeout(() => {
  //     //   history.push(`${url}/${country.iso2}/billing`)
  //     // }, 2000)
  //   }).catch(res => {
  //     console.log(res)
  //   })
  // }

  // const subscribeToApp = async (razorpay_payment_id) => {
  //   let data = {
  //     "active": true,
  //     "appId": id,
  //     "endDate": null,
  //     "id": 0,
  //     "metadata": await getMetaData(),
  //     "paymentId": razorpay_payment_id,
  //     "startDate": new Date(),
  //     "userId": UserService.getUserId()
  //   }
  //   axios.post(URL.subscribeToApp, data, headers())
  //     .then(res => {
  //       dispatch(getAppsByUserId())
  //       setTimeout(() => {
  //         history.push(`${url}/${country.iso2}/billing`)
  //       }, 500)
  //     })
  //     .catch(err => console.log(err))
  // }

  useEffect(() => {
    // axios.get(`${URL.elib}all_countries`, headers()).then((res) => {
    //   setAllCountries(res.data.data)
    // })

    setAllCountries([{ name: 'INDIA', iso2: 'IN' }])

  }, [])

  // let amount = "200"

  // const checkoutdetail = async (razorpay_payment_id, razorpay_order_id, razorpay_signature,) => {

  //   const data = {
  //     razorpay_payment_id,
  //     razorpay_order_id,
  //     razorpay_signature,
  //     order_id: orderid,
  //     amount,
  //   }

  //   await axios.post(`${URL.verifyPayment}`, data, {
  //     headers: {
  //       Authorization: `Bearer ${UserService.getToken()}`
  //     }
  //   }).then(res => {
  //     // history.push(`${url}/billing`)
  //     const status = res.data
  //     transaction(razorpay_payment_id, razorpay_order_id, razorpay_signature, status)

  //   }).catch(err => {
  //     console.log(err)
  //   })

  // }



  // useEffect(() => {

  //   if (amount !== 0 && orderid.length > 0) {
  //     var options = {
  //       // "key": "rzp_test_O3F6y2GzIeJKoF", // Enter the Key ID generated from the Dashboard
  //       "key": `${process.env.REACT_APP_RAZORPAY_KEYID}`, // Enter the Key ID generated from the Dashboard
  //       "amount": `${amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  //       "currency": "INR",
  //       "name": "Docsteth ",
  //       "description": "hellooo Transaction",
  //       "image": "https://www.docsteth.com/static/media/Logo2.d92e5ec401ec97597945e37776bafd7f.svg",

  //       "order_id": `${orderid}`,
  //       "save": 1,

  //       "handler": function (response) {
  //         setRazorPayID(response.razorpay_payment_id)
  //         setRazorOrderId(response.razorpay_order_id)
  //         setRazorSignature(response.razorpay_signature)
  //         // checkoutdetail()
  //         checkoutdetail(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
  //       },


  //       "theme": {
  //         "color": "#f9f9f9 "
  //       },

  //     };
  //     // console.log(options.order_id, "options id")

  //     var rzp1 = new window.Razorpay(options);
  //     rzp1.open();
  //   }
  // }, [amount, orderid])

  // var rzp1

  const SubmitForm = async (e) => {

    // e.preventDefault()
    // // console.log(getOrderId)
    // await axios.post(`${URL.createRazorpayOrder}`, getOrderId, {
    //   headers: {
    //     Authorization: `Bearer ${UserService.getToken()}`
    //   }
    // }).then(res => {

    //   setOrderid(res.data.id)
    //   // if (res.data.id.length > 0) {
    //   // rzp1.open();
    //   // } //opening razorpay popup from here
    // }).catch(err => {
    //   console.log(err)
    // })

    history.push(`${url}/${country.iso2}/billing`)

  }


  useEffect(() => {
    axios.get(URL.findAppById + id, headers()).then(res => setData(res.data.data))
  }, [id])

  return (
    <div>

      {/* <Typography sx={{ paddingBottom: { xs: "10px", sm: 0, md: 0 }, fontSize: "18px", fontWeight: "500" }}>Confirm Subscription</Typography> */}
      <ValidatorForm onSubmit={SubmitForm} onError={() => { }}>

        <Box sx={{ margin: { xs: 0, sm: "20px", md: "30px" } }}>

          <div style={{ display: "flex", alignItems: "center", gap: "15px", marginBottom: "20px" }}>
            <img src={data.logo} alt='' width="40" />
            <div>
              <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>{appname} <span style={{ marginLeft: "4px", background: "lightgray", padding: "3px 5px", borderRadius: "4px", fontSize: "16px" }}>beta</span></Typography>
              <Typography variant="body2">{data.captionAndTagLine}</Typography>
            </div>
          </div>

          <Box sx={{ margin: "20px 0" }}>
            <Typography sx={{ fontSize: "18px" }}>Country of Practice</Typography>
            <Typography sx={{ fontSize: "16px" }} variant="body2">Declare in which Jurisdiction of your practice, this helps us customise
              the app and verify you are a doctor</Typography>
          </Box>


          <Box sx={{ width: { xs: "100%", sm: "100%", md: "300px", } }}>
            <FormControl fullWidth sx={{ margin: "10px 0" }}>
              {/* <InputLabel size="small" id="demo-simple-select-label">Country</InputLabel> */}
              <SelectValidator
                validators={['required']}
                errorMessages={['Required']}
                fullWidth
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Country"
                onChange={handleChange}
                // disabled
                size="small"
                sx={{ boxShadow: '0px 0px 13px 0px rgba(222,222,222,1)', borderRadius: "10px", borderColor: "transparent", '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              >
                {allcountries?.map((item, i) => {
                  return <MenuItem key={i} value={item.name}>{item.name}</MenuItem>
                })}

              </SelectValidator>
            </FormControl>
          </Box>

          <Typography sx={{ margin: "10px 0" }}>* On-clicking continue you agree to the<a href="#" style={{ color: "#0D2DAF" }}> terms and conditions</a>,
          </Typography>
          <Typography sx={{ margin: " 0 0 10px" }}>and your card will be verified with 2 Rs deduction
          </Typography>
          <Box sx={{ display: "flex", gap: "20px", justifyContent: "end", alignItems: "center", padding: { xs: "0 0 40px" } }}>
            <Button onClick={() => history.goBack()} sx={{ textTransform: "lowercase", fontWeight: "400", color: "black" }}><Typography sx={{ fontSize: "20px" }}>Cancel</Typography></Button>

            {/* <Link to={`${url}/billing`}> */}
            <Button variant="contained" type="submit" sx={{ backgroundColor: "#1500A0", padding: "0 20px !important", height: "30px", borderRadius: "8px" }}>Continue</Button>
            {/* </Link> */}
          </Box>
        </Box>
      </ValidatorForm>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      // message="Transaction Success"
      >
        <Alert severity="success">Payment Success </Alert>
      </Snackbar>

    </div>
  )
}

export default CountrySelect