
import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EPracticeCard from "./EPracticeCard";
import { styled } from '@mui/styles';


export const MultiTabs = styled(TabList)({
  '& .MuiTabs-indicator': {
    background: "white",
  },
});

function EPracticeCardsHeader() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{ width: "100%", typography: "body1", backgroundColor: 'transparent' }}
    >
      <TabContext value={value}>
        <Box >
          <MultiTabs onChange={handleChange} aria-label="lab API tabs example" centered className="e-practice-header-tab" sx={{ '& .Mui-selected': { color: 'white' } }}>
            <Tab label="Practice Tools Kit" value="1" />
            <Tab label="Interactive Apps" value="2" />
            <Tab label="E-Library" value="3" />
          </MultiTabs>
        </Box>
        <TabPanel value="1"><EPracticeCard /></TabPanel>
        <TabPanel value="2">Interactive Apps</TabPanel>
        <TabPanel value="3">E-Library</TabPanel>
      </TabContext>
      <Tabs value={value} onChange={handleChange} centered></Tabs>
    </Box>
  );
}

export default EPracticeCardsHeader;
