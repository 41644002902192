export default function Timeline() {
    return (
        <div className="timeline--height">
            <div className="timeline--header">
                <p className="timeline">Timeline</p>
                <p className="timeline">Publications</p>
                {/* <p className="timeline">share</p> */}
            </div>
        </div>
    )
}