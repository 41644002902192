import { AppBar, Grid, InputBase, Toolbar, Rating, Typography, Button, Divider} from '@mui/material'
import React from 'react'
import app_vault from '../../Atom/svg/app_vault.svg'
import filter_icon from '../../Atom/svg/filter_icon.svg'
import { Card, CardContent, CardHeader} from '@mui/material'
import MedExperts from '../../Atom/svg/MedExperts.png'
import './SubscriptionAppVault.css'
import gallery_icon from '../../Atom/svg/gallery_icon.svg'
import tv_icon from '../../Atom/svg/tv_icon.svg'
import Mark_icon from '../../Atom/svg/Mark_icon.svg'
import Avatar from '@mui/material/Avatar';
import human from '../../Atom/svg/human.jfif'
import dislike_icon from '../../Atom/svg/dislike_icon.svg'
import like_icon from '../../Atom/svg/like_icon.svg'
import SendIcon from '@mui/icons-material/Send';
import { InputAdornment } from '@mui/material'

function SubscriptionAppVault() {
    const[rating,setRating]=React.useState(4);
    return (
        <div className='appvault'>
            <div className="AppVault_header">
                <AppBar position="static" style={{backgroundColor:'#FFFFFF',height:'55px',marginTop:'10px',borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>

                    <Toolbar style={{boxShadow: '0px 3px 6px #00000029'}}>
                        <Grid container alignItems="center" justifyContent="space-between" >
                            <Grid item>
                                <div className="Appvault_icon">
                                    <img src={app_vault} alt="app_vault" style={{height:'75px'}}/>
                                    <Typography variant="h4" color="black" style={{marginTop:'19px',color:'#000000',fontSize:'23px'}}>APP VAULT</Typography>

                                </div>
                             </Grid>

                             <Grid item>
                                 {/* <InputBase  endAdornment={<img src={filter_icon} alt="filter_icon"/>}/> */}
                                 <InputAdornment position="start">
                                 <img src={filter_icon} alt="filter_icon"/>
                                 </InputAdornment>
                             </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>

            <div className="AppVault_body">
                 <Grid container spacing={3}  style={{padding:'25px'}}>
                      
                            <div className="App_Details">
                                    <div className="Subscription_App">
                                        <div className="App_img">
                                            <img src={MedExperts} alt="MedExperts_icon"/>
                                        </div>

                                        <div className="Subscription_App_Content">
                                            <div className="Subscription_App_Details">
                                                <h3>Application Name</h3>
                                                <p>Captions and Taaglines</p>
                                            </div>

                                            <div className="Subscription_App_Ratings">
                                                <Rating value={rating} precision={0.5} onChange={(e,value)=>setRating(value)} style={{fontSize:'21px'}} />
                                                <div className="Subscription_App_Reviews_content Subscription_App_Details p">
                                                    <p>Users:<span>123</span></p>
                                                    <p>Reviews:<span>12</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="instalations_details">
                                            <div className="instalation_source_name Subscription_App_Details p">
                                                <h6>Installtion</h6>
                                                <p>CLINIVAULT CLOUD</p>
                                            </div>  

                                            <div className="instalation_country_name Subscription_App_Details p">
                                                <h6>Country</h6>
                                                <p>India</p>
                                            </div>
                                    </div>

                                    <div className="app_category_details instalations_details h6">
                                            <div className="category_name instalation_source_name Subscription_App_Details p">
                                                <h6>Category</h6>
                                                <p>Medical Learning</p>
                                            </div>  

                                            <div className="language_details Subscription_App_Details p">
                                                <h6>Language</h6>
                                                <p>English</p>
                                            </div>
                                    </div>

                                    <div className="buttons">
                                        <Button variant="contained" color="primary" style={{borderRadius:'4px'}}>Subscribe</Button>
                                        <p>Free</p>
                                        <Button variant="outlined" color="primary" style={{borderRadius:'4px',marginTop:'10px',width:'100%'}}>Review</Button>
                                    </div>
                            </div>


                           <div className="subscription_content">
                                <div className="images Subscription_App_Details p">
                                        <div className="tv_icon_image">
                                            <img src={tv_icon} alt="tv_icon"/>
                                            <p>Promo</p>
                                        </div>

                                        <div className="gallery_icon_image">
                                            <img src={gallery_icon} alt="gallery_icon"/>
                                            <p>Screenshot 1</p>
                                        </div>

                                        <div className="gallery_icon_image">
                                            <img src={gallery_icon} alt="gallery_icon"/>
                                            <p>Screenshot 2</p>
                                        </div>

                                        <div className="gallery_icon_image">
                                            <img src={gallery_icon} alt="gallery_icon"/>
                                            <p>Screenshot 3</p>
                                        </div>
                                    
                                    </div>

                                    <div className="Content_box">
                                        <div className="tags trail_box h6"><h6>Tags:</h6></div>

                                        <div className="description_box">
                                            <p> Description:</p>
                                             <div className="description_content">
                                                 Genotypic and phenotypic profiles of Escherichia coli isolates belonging to clinical sequence type 131 (ST131), clinical non-ST131, and fecal non-ST131 lineages from India A Hussain, A Ranjan, N Nandanwar, A Babbar, S Jadhav, N Ahmed Antimicrobial agents and chemotherapy 58 (12), 7240-7249
                                                 <br/><br/>
                                                  Genotypic and phenotypic profiles of Escherichia coli isolates belonging to clinical sequence type 131 (ST131), clinical non-ST131, and fecal non-ST131 lineages from India A Hussain, A Ranjan, N Nandanwar, A Babbar, S Jadhav, N Ahmed Antimicrobial agents and chemotherapy 58 (12), 7240-7249
                                             </div>
                                        </div>

                                        <div className="trail_box">
                                             <div className="trail_details">
                                                <h6>Trail</h6>                                               
                                                <p>Free Trail: <span>5 users</span></p>
                                            </div>

                                            <div className="pricing_details">
                                                <h6>Pricing</h6>
                                                <p>Price:<span>1$/m</span></p>
                                            </div>

                                           <h6>Developer</h6>
                                        </div>

                                        <div className="privacy_box">
                                            <h6>Privacy Statements</h6>
                                            <h6>Terms and Conditions</h6>
                                        </div>
                                    </div>


                                   
                                        <Card style={{width:'26%',background:'#FFF',marginRight:'-58px',boxShadow:'rgb(0 0 0 / 16%) 1px 0px 10px',borderRadius:'12px 0px 0px 0px'}}>
                                            <CardHeader style={{height:'20px'}} 
                                                title={
                                                    <div className="Main_Card_Header">
                                                        <img src={Mark_icon} alt=" Mark_icon" height="18px"/>
                                                       <h5> &nbsp; Reviews(12)</h5>
                                                    </div>
                                                }
                                            />
                                            <Divider/>
                                            <CardContent style={{overflowY:'auto',height:'364px'}}>
                                            
                                                   <Card style={{boxShadow:'0px 3px 6px #00000029',border: '1px solid #F5F0F0',borderRadius: '12px',marginBottom:'24px'}}>
                                                        <CardHeader style={{marginLeft:'-13px',height:'14px',fontWeight:'500',fontSize:'14px'}}
                                                            title={
                                                                <div className="Main_Card_Header">
                                                                    <Avatar src={human}/>
                                                                    <h5> &nbsp; Full Name</h5>
                                                                </div>
                                                            }
                                                        />
                                                       
                                                            <Card style={{border: '1px solid #F5F0F0',borderRadius: '12px',margin:'5px'}}>
                                                                <CardContent>
                                                                   <div>
                                                                   Genotypic and phenotypic profiles of Escherichia coli isolates belonging to clinical 
                                                                   </div>
                                                                </CardContent>
                                                            </Card>
                                                       

                                                        <div className="like_or_dislike">
                                                           <div className="like_icon">                                                             
                                                                <img src={like_icon} alt="like_icon"/>
                                                                <a href="#">1.3k</a>
                                                           </div>

                                                            <div className="dislike_icon like_icon like_icon img like_icon a">
                                                                    <img src={dislike_icon} alt="like_icon"/>
                                                                    <a href="#">1.3k</a>
                                                            </div>                                                                                                                        
                                                        </div>
                                                    </Card>  

                                                     <Card style={{boxShadow:'0px 3px 6px #00000029',border: '1px solid #F5F0F0',borderRadius: '12px'}}>
                                                        <CardHeader style={{marginLeft:'-13px',height:'14px',fontWeight:'500',fontSize:'14px'}}
                                                            title={
                                                                <div className="Main_Card_Header">
                                                                    <Avatar src={human}/>
                                                                    <h5> &nbsp; Full Name</h5>
                                                                </div>
                                                            }
                                                        />
                                                       
                                                            <Card style={{border: '1px solid #F5F0F0',borderRadius: '12px',margin:'5px'}}>
                                                                <CardContent>
                                                                   <div>
                                                                   Genotypic and phenotypic profiles of Escherichia coli isolates belonging to clinical
                                                                   </div>
                                                                </CardContent>
                                                            </Card>
                                                       

                                                        <div className="like_or_dislike">
                                                           <div className="like_icon">                                                             
                                                                <img src={like_icon} alt="like_icon"/>
                                                                <a href="#">1.3k</a>
                                                           </div>

                                                            <div className="dislike_icon like_icon like_icon img like_icon a">
                                                                    <img src={dislike_icon} alt="like_icon"/>
                                                                    <a href="#">1.3k</a>
                                                            </div>                                                                                                                        
                                                        </div>
                                                    </Card>                                            
                                           </CardContent>
                                                                                       
                                                <div style={{height:'20px',borderTop:'2px solid #F5F0F0'}}>
                                                    <InputBase placeholder="Search By Name" endAdornment={<SendIcon/>} style={{marginLeft:'18px',width:'259px',display:'flex',alignItems:'center',justifyContent:'center'}}/>
                                               </div> 
                                            
                                        </Card>
                                   
                           </div>
                       
                  </Grid>  
            </div>
        </div>
    )
}

export default SubscriptionAppVault
