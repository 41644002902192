import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import UserService from '../../../../../../../services/UserService';
import { useDispatch } from 'react-redux';
import Polling from "react-polling";
import poll_icon from "../../../../../../../images/poll.svg";
import "./pollstyle.css";
import axios from 'axios';
import { headers } from '../../../../../../../redux/Actions/connectActions';
import URL from '../../../../../../apiurl';

export function expiryFun(expiryDate) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = new Date(expiryDate) - new Date();
    const days = Math.round(differenceMs / ONE_DAY);

    return days > 0 ? (days > 1 ? `Expires in ${days} days` : `Expires in ${days} day`) : days === 0 ? 'Expires Today' : 'Expired';
}

const Poll = ({ docFeed, ...props }) => {

    const [polldata, setPolldata] = useState(docFeed);
    const [pollAnswers1, setPollAnswers1] = useState(docFeed?.pollData?.pollResponseData);

    const [expanded, setExpanded] = useState(false);
    const [load, setLoad] = useState(false);


    const dispatch = useDispatch();

    useEffect(() => {
        setLoad(true);
    }, [props]);

    // const getPostById = async (id) => {
    //     let pdata = await getPost(id || post.id)
    //     setPost(pdata)
    // }


    const handleVote = async (voteAnswer, pollAnswers) => {
        const newPollAnswers = pollAnswers?.map((answer) => {
            if (answer.option === voteAnswer) answer.votes++;
            return answer;
        });
        setPollAnswers1(newPollAnswers);

        let data = {
            // "metadata": await getMetaData(),
            "postId": polldata.id,
            "questionId": polldata.pollData.id,
            "response": voteAnswer,
            "userId": sessionStorage.getItem('userId')
        };

        axios.post(`${URL.addPoll}`, data, headers()).then(res => {
            if (res.status === 200 || 201) {
                setLoad(true);
                UpdatePostCache("add_pollresponse", polldata.id);
            }
            getpolldata();
        }).catch(err => console.log(err));
    };

    const UpdatePostCache = async (type, id) => {
        let postData = "";
        await axios.get(`${URL.postById}/${id}`, headers())
            .then((response) => {
                postData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });

        let data = {
            update: type,
            new_post: postData,
            post_id: id,
        };
        UpdateChacheFeed(data);
    };

    const UpdateChacheFeed = async (data) => {
        await axios.post(`${URL.updateChacheFeed}`, data, headers())
            .then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
    };

    const handleresponse = () => {
        let id = polldata.pollData.pollId;
        setLoad(false);
        axios.delete(`${URL.deleteresponse}/${id}`, headers()).then(res => {
            UpdatePostCache("delete_pollresponse", polldata.id);
            getpolldata();
        });
    };

    const getpolldata = async () => {
        // let data = await getPostById(polldata.id);
        // if (data.id) {
        //     setPolldata(data);
        //     setPollAnswers1(data.pollData.pollResponseData);
        //     setLoad(true);
        // } else if (data.error) {
        //     setLoad(true);
        // }
    };






    return (
        <div>
            <div style={{ padding: "0px 10px", height: "60px" }}>
                {docFeed.description?.length > 100 ? docFeed.description?.slice(0, 100) : docFeed?.description}
                <span>
                    {
                        docFeed?.description?.length > 100 && <Typography
                            component={"span"}
                            sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                        >
                            {expanded ? "...see less" : "...see more"}{" "}
                        </Typography>
                    }
                </span>
            </div>

            <Box sx={{
                border: "1px solid lightgrey", padding: "10px", borderRadius: "10px", margin: "10px",
                maxHeight: "300px"
            }} >
                {
                    polldata &&

                    <Grid container sx={{ width: "100%" }} >
                        <Grid item
                            xs={12}
                            // className={`poll-class ${props.border ? 'poll-width' : ''}`}
                            style={{ pointerEvents: props.border || !UserService.getToken() ? 'none' : new Date(new Date(polldata.pollData.expiry).toDateString()) < new Date(new Date().toDateString()) ? 'none' : 'all', }}
                        >
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <p className="" style={{ fontSize: '18px' }}>
                                    {polldata.border ? <><img src={poll_icon} alt={'poll'} width="25px" height="25px" className="celebrate" />&nbsp;</> : ''}
                                    {polldata.pollTitle}
                                </p><p> {expiryFun(polldata.pollData.expiry)}</p>
                            </div>
                            {load && (
                                <>
                                    <Polling

                                        url={`${URL.postById}/${polldata.id}`}
                                        interval={10000} // Poll every 10 seconds
                                        retryCount={3}
                                        headers={{
                                            Authorization: `Bearer ${UserService.getToken()}`
                                        }}
                                        onSuccess={(response) => {
                                            // const data = response.data;
                                            // setPolldata(data);
                                            // setPollAnswers1(data.pollData.pollResponseData);
                                        }}
                                        onFailure={(error) => {
                                            console.log(error);
                                            return true; // Continue polling
                                        }}
                                    >
                                        {() => (
                                            <>
                                                <div>
                                                    <Typography component="div" sx={{ mt: 2, height: "30px" }}>
                                                        {polldata.pollData?.question?.length > 40 ? polldata.pollData?.question?.slice(0, 40) + "....." : polldata.pollData?.question}
                                                    </Typography>
                                                    <ul style={{}} >
                                                        {pollAnswers1?.map((answer, index) => (
                                                            <li key={index} style={{ listStyleType: 'none', margin: '8px 0' }}>
                                                                <Button className="capitalize"
                                                                    sx={{
                                                                        border: '1px solid #4F70D6',
                                                                        borderRadius: '12px',
                                                                        width: '100%',
                                                                        justifyContent: 'center'
                                                                    }}
                                                                    // onClick={() => handleVote(answer.option, pollAnswers1)}
                                                                    disabled={polldata.pollData.pollAnswered}
                                                                >
                                                                    {answer.option} - {answer.votes} votes
                                                                </Button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {/* {(!load || polldata.pollData.pollAnswered) && (
                                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        {expiryFun(polldata.pollData.expiry) !== "Expired" && (
                                                            <Button onClick={handleresponse} className="undo_btn">undo</Button>
                                                        )}
                                                    </div>
                                                )} */}
                                            </>
                                        )}
                                    </Polling>
                                </>
                            )}
                        </Grid>
                    </Grid>

                }


            </Box>
        </div >
    )
}

export default Poll