import React, { useState } from 'react'
import Button from '@mui/material/Button';
import CustomTextField from '../Fields/TextFields';
import CreateAccount from './common'
import { Typography } from '@mui/material';
import Logo from '../../images/loginLogo.svg'
import Orchid from '../../images/orchid.svg'
import { useHistory } from 'react-router-dom'
import { ValidatorForm } from 'react-material-ui-form-validator';

export default function Login() {

    const [state, setState] = useState({ username: '', password: '' })
    const history = useHistory()

    const handleChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    return (
        <CreateAccount t1="Login">
            <ValidatorForm
                // ref="form"
                onSubmit={() => history.push('/feed')}
                onError={errors => {}}
            >
                <CustomTextField label="User Name" value={state.username} name="username" placeholder="Email Associated with Account" handleChange={handleChange}
                    validators={['required']}
                    errorMessages={['Please Enter Username']} />
                <CustomTextField label="Password" type="password" value={state.password} name='password' placeholder="Enter Your Password" handleChange={handleChange}
                    validators={['required']}
                    errorMessages={['Please Enter Password']}
                />
                <Typography sx={{ mt: 1 }}>Forgot Password? </Typography>
                <div style={{ textAlign: 'center' }}>
                    <Button className='btn-signup contained-btn' style={{ color: 'white' }} variant='contained' type="submit">Sign in</Button>
                    <Typography variant="h6" sx={{ mt: 2, fontSize: '18px' }}>Join Docsteth</Typography>
                    <Typography sx={{ color: 'black', fontSize: '18px', mt: 1 }}>Login With</Typography>
                    <img src={Logo} alt='' style={{ marginRight: '20px', marginTop: '15px' }} />
                    <img src={Orchid} alt='orchid' />
                </div>
            </ValidatorForm>
        </CreateAccount>
    )
}

// `${state.username}@docsteth`