import React from 'react';
import "../../utility/style.css";
import './inputOption.css'
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import DialogBox from '../../DialogBox/dialog';
// import { Avatar, Button, Divider } from '@mui/material';
import UsersData from './usersData';
// import ShareIcon from '@mui/icons-material/Share';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import Postuserdata from './Postuserdata';

export default function InputOption({ Src, title, color, path, handleClick, count, likeData }) {

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false)
  const anchorRef = React.useRef(null);
  const matches = useMediaQuery('(max-width:425px)');

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event) => {
  //   setOpen(false);
  // };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (

    <div className="input-Option">
      {likeData ? likeData.length > 0 ? <DialogBox width="xs" open={openDialog} handleDialog={() => setOpenDialog(false)}
        Content={<Postuserdata title="Post Likes" data={likeData} closeDialog={() => setOpenDialog(false)} />
        }
      /> : '' : ''}
      <span onClick={handleClick} className="cursor" style={{ display: 'flex', alignItems: 'center' }}>
        <p className='flex font-caption'>{Src}</p>&nbsp;
        {matches ? '' : <p className="like_display font-caption">{title}</p>}&nbsp;</span>
      <div>
        {/* {count > 0 ? <span className="sidebar__statNumber add-cover cursor input__title " style={{ paddingTop: '0px' }}
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onMouseEnter={handleToggle}
          onMouseLeave={handleClose}
          onClick={() => setOpenDialog(true)}
        >
          {`(${count})`}
        </span> : ''} */}
        {likeData ? likeData.length > 0 ? <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={{ borderRadius: '3px!important', backgroundColor: 'black', p: 0.5 }}>
                {likeData.slice(0, 20).map(user => (<Typography sx={{ backgroundColor: 'black', color: 'white', fontSize: '12px!important' }} className="capitalize" key={user.id}> {user.name} </Typography>))}
              </Paper>
            </Grow>
          )}
        </Popper> : '' : ''}
      </div>
      {/* <p className="share_display"><ShareIcon style={{ color: '#007dcd' }} /></p> */}

    </div>
  )
}
