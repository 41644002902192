import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './croppedImage'
import Avatar from '@mui/material/Avatar'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'

const styles = makeStyles((theme) => ({
  cropContainer: {
    position: 'relative',
    width: '600px',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: '200px',
    },
  }
}))


const Demo = ({ url, save, state, handleDialog }) => {
  const classes = styles();
  const dogImg = url
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        dogImg,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  useEffect(() => {
    showCroppedImage()
  }, [croppedAreaPixels])

  return (
    <div>
      <div className="d-flex e-align">
        <div className={classes.cropContainer}>
          <Cropper
            image={dogImg}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={state === 'cover' ? 30 / 6 : 5 / 5}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

        </div>
        {state === 'profile' ?
          <Avatar src={croppedImage} className={`profile-pic`} style={{ height: "144px", width: "144px", marginLeft: '30px', borderRadius: '20px' }} />
          : ''}
      </div>
      {state === 'cover' ? <img src={croppedImage} className="" alt="" width="100%" style={{ marginTop: '20px' }} /> : ''}

      <div style={{ textAlign: 'right', marginTop: '15px' }}>
        <Button type="submit" className="save-btn add-option btn__primary" onClick={() => save(croppedImage, state)}>Upload</Button>
        <Button className="save-btn add-option btn__primary" style={{ marginLeft: '10px' }} onClick={() => handleDialog()}>Cancel</Button>
      </div>

    </div>
  )
}

export default Demo

