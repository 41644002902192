import { C_API, DOC_API, D_API, PY_API, PY_SEARCH_LOC } from "../../../services/APIService"
import { URI } from "../../utility/uri"
import UserService from "../../../services/UserService"
import { headers } from "../../../redux/Actions/connectActions";
import axios from "axios";

export const updateSteps = async (step, id) => {

    const data = {
        id: id,
        steps: step,
        dateTime: new Date()?.toISOString(),
        userId: UserService?.getUserId()

    }
    console.log(data)
    return await C_API[id ? 'put' : 'post'](URI[id ? 'updateStep' : 'addStep'], data).then(() => {
        return true
    })
}
export const updateStepsData = async (step, id, userId) => {
    const data = {
        id: id?.id,
        steps: step,
        dateTime: new Date()?.toISOString(),
        userId: UserService?.getUserId()
    };

    try {
        if (id) {
            // If id is provided, update the data
            console.log(data, id, "dfghjkljhgf")
            await axios.put(process.env.REACT_APP_CLINIDESK + 'updateSteps', data);
        } else {
            // If no id is provided, add new step data
            await axios.post(process.env.REACT_APP_CLINIDESK + 'addSteps', data);
        }
        return true; // Return true if request is successful
    } catch (error) {
        console.error("Error updating steps data:", error);
        return false; // Return false if there's an error
    }
};

export const getStates = async () => {
    const res = await PY_API.get(URI.getStates)
    return res.data?.data?.map(state => state.toLowerCase())
}

export const getDistricts = async (state) => {
    const res = await PY_API.get(URI.getDistricts + state.toUpperCase())
    return res.data?.data?.map(district => district.toLowerCase())
}

export const getSubDistricts = async (district) => {
    const res = await PY_API.get(URI.getSubDistricts + district.toUpperCase())
    return res.data?.data?.map(subDistrict => subDistrict)
}

export const getVillages = async (subDistrict) => {
    const res = await PY_API.get(URI.getVillages + subDistrict)
    return res.data?.data?.map(village => village)
}


export const getSearchLocInfo = async (searchVal) => {
    // const res = await PY_SEARCH_LOC.get(URI.getsearchLocation + searchVal, headers())
    const res = await PY_SEARCH_LOC.get(URI.getsearchLocation + searchVal, {
        headers: {
            Usertoken: "True"
        }
    })
    return res.data
}
export const getSearchLocByPin = async (searchVal) => {
    const res = await PY_SEARCH_LOC.get(URI.getsearchLocByPincode + searchVal)
    return res;
}

export const getLongitudeandLatitude = async (searchVal) => {
    // const res = await PY_SEARCH_LOC.get(URI.getLongAndLatVal + searchVal, headers());
    const res = await PY_SEARCH_LOC.get(URI.getLongAndLatVal + searchVal, {
        headers: {
            Usertoken: "True"
        }
    });
    return res;
}

export const getLocationByLongandLatitVals = async (val1, val2) => {
    // const res = await PY_SEARCH_LOC.get(URI.getLocationDetails + val1 + "," + val2, headers());
    const res = await PY_SEARCH_LOC.get(URI.getLocationDetails + val1 + "," + val2, {
        headers: {
            Usertoken: "True"
        }
    });
    return res;
}


export const filter_data = (data) => {
    return [...data].map(val => {
        Object?.keys({ ...val })?.map(key => {
            if (!val[key]) {
                delete val[key]
            }
        })
        return { ...val }
    }).filter(val => Object?.keys(val)?.length > 0)
}

export const deleteKeys = (data) => {
    Object?.keys(data)?.map(key => {
        if (!data[key])
            delete data[key]
    })
    return data
}
export const deleteKeyAddre = (data) => {
    Object?.keys(data)?.forEach(key => {
        if (data[key] === "undefined" || data[key] === null || data[key] === "") {
            delete data[key];
        }
    });
    return data;
};
export const deleteEmptyKeys = (obj) => {
    let result = {};
    for (const [key, value] of Object?.entries(obj)) {
        if (value !== null && value !== undefined && value !== "") {
            if (typeof value == "object" && !Array.isArray(value)) {
                const nestedResult = deleteEmptyKeys(value);
                if (Object?.keys(nestedResult)?.length > 0) {
                    result[key] = nestedResult;
                }
            } else if (Array.isArray(value)) {
                let filterList = value?.map((each) => (typeof each == "object" ? deleteEmptyKeys(each) : each))
                    .filter((item) => !(typeof item === "object" && Object?.keys(item)?.length === 0))
                if (filterList.length > 0) {
                    result[key] = filterList;
                }
            } else {
                result[key] = value;
            }
        }
    }
    return result;
}


export const getFile = async (data) => {
    let file = data.split(',')
    let type = file[0].split('/')
    let ty = type[1].split(';')
    let picData = {
        "file": [file[1]],
        "fileType": ty[0],
        "multiple": false,
        "subType": '',
        "type": "userKycDocuments"
    }
    return await uploadFile(picData)
}

export const uploadFile = async (fileData) => {
    return await DOC_API.post(URI.fileUpload, fileData).then(res => res.data?.data?.[0])
}

export const generateAadharOTP = async (aadhaar) => {
    return await C_API.post(URI.generateAadharOTP, { "aadhaar": aadhaar }).then(res => res.data?.txnId).catch(err => ({ error: err?.response?.data?.message || err?.data || 'Couldn\'t send OTP. Please try after sometime.' }))
}

export const verifyAadharOTP = async (data) => {
    return await C_API.post(URI.verifyAadharOTP, data).then(() => true).catch(() => { alert('Incorrect OTP'); return false })
}

export const getPractitionerData = async () => {
    return await D_API.get(URI.getPractitioner + UserService.getUserId()).then(res => res.data)
}
export const getPractitionerDatas = async () => {
    return await D_API.get('Practitioner/' + UserService.getUserId()).then(res => res.data)
}

export const displayAddress = (data) => {
    let address = data
    return (`${[address?.line?.slice(0, 2), address?.city, address?.district, address?.line?.[2], address?.state, address?.country]?.filter(Boolean)?.toString()?.replace(/,/g, ', ')}${address?.postalCode ? " - " + address?.postalCode : ''}`)
}

export const getLanguages = async () => {
    try {
        const languages = await getLanguages();
        return languages;
    } catch (err) {
        console.error("Error:", err);
    }
}