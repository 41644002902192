import { Button, Grid } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import '../../Molecule/Elibrary/ElibraryRightContainer.css'
import '../../Molecule/EbmKit/EbmKit.css'
import DruginformationHeader from './DruginformationHeader';
import ElibraryRightContainer from './drugClasses';
import DoseDetails from '../DoseDetails/DoseDetails'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import URL from '../../apiurl';
import Search from '../EbmKit/search';
import Popover from '@mui/material/Popover';
import { RadioGroup, FormControl, Radio, FormControlLabel, Box } from '@mui/material'
import { Search_Elib } from '../EbmKit/specialitites'

function DrugInformation(props) {
    const [path, setPath] = useState({ druginfo: '', dosedetails: '' })
    const [com, setCom] = useState(0)
    const [search, setSearch] = useState('')
    const [value, setValue] = useState('')
    const handlePath = (val, name, value) => {
        setCom(val)
        setPath({ ...path, [name]: value })
    }
    const [data, setData] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const myRef = useRef(null)
    const [filter, setFilter] = useState([])
    const [load, setLoad] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {

        axios.get(`${URL.dSpecialities}`).then(res => setData(res.data.data.sort((a, b) => a.speciality.toLowerCase() > b.speciality.toLowerCase() ? 1 : -1)))

    }, [])

    const handleSearch = (data) => {
        setSearch(data)
        if (data.length > 2) {
            setLoad(true)
            axios.get(`${URL.search_drugs}/${data}`)
                .then(res => {
                    setFilter(res.data.data)
                    setLoad(false)
                })
        }
    }

    const SearchView = () => {
        return (
            <Search_Elib data={filter} load={load} value={search} handleClick={() => { setFilter([]); setSearch('') }}>
                <Search value={search} handleSearch={(value) => handleSearch(value)} placeholder="Search Classes / Drugs" />
            </Search_Elib>
        )
    }

    return (
        <div className='elibrary_rightcontainer' ref={myRef}>
            {/* <Helmet>
                <title>Docsteth | DrugList</title>
            </Helmet> */}

            <DruginformationHeader />

            <Box className="elibrary_rightcontainer_body" sx={{ height: { md: 'calc(100vh - 171px)', sm: 'auto', xs: 'auto' }, overflow: { md: 'scroll', xs: 'hidden' } }}>
                {com == 0 ? <Grid container style={{ height: '100%', display: 'block' }} >
                    <Grid item style={{ width: '100%' }}>
                        <div className='drugs_specialities_Desktop'>
                            <div className="Ebm_components ta__left" >
                                <div className="sort_icon_display"><SortOutlinedIcon /></div>
                                <Grid container rowSpacing={2} sx={{mt: '5px'}}>
                                    <Grid item xs={12} md={8} lg={9} sx={{pt: 2}} className='e-align'>
                                        <div className="sort_icon">
                                        <h4>Index</h4>

                                            <div className='display_classes_drugs e-align d-flex'>
                                                <SortOutlinedIcon />
                                                <Button variant="outlined" className="drug-btn">Specialities</Button>
                                                <Button variant="outlined" className="drug-btn"><Link style={{ borderColor: 'rgba(25, 118, 210, 0.5)', color: '#1976d2' }} to='/elib/drugclasses'>Classes</Link></Button>
                                                <Button variant="outlined" className="drug-btn"><Link style={{ borderColor: 'rgba(25, 118, 210, 0.5)', color: '#1976d2' }} to='/elib/alldrugs'>Drugs</Link></Button>
                                            </div>

                                        </div>
                                    </Grid>

                                    <Grid item lg={3} md={4} xs={3}>
                                        {SearchView()}
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                        <div className='drugs_specialities_Mobile'>
                            <div className="Ebm_components ta__left" >
                                <div className="Ebm_filtering">
                                    <ul>
                                        <li>
                                            <div className="sort_icon">
                                                <h4>Specialities</h4>
                                            </div>
                                        </li>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <FormControl sx={{ ml: 2 }}>
                                                <RadioGroup row className="radio"
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                >
                                                    <FormControlLabel value="Specialities" control={<Radio size="small" />} label="Specialities" ></FormControlLabel>
                                                    <Link to='/elib/drugclasses'><FormControlLabel value="Classes" control={<Radio size="small" />} label="Classes"></FormControlLabel></Link>
                                                    <Link to='/elib/alldrugs'><FormControlLabel value="Drugs" control={<Radio size="small" />} label="Drugs"></FormControlLabel></Link>
                                                </RadioGroup>
                                            </FormControl>
                                        </Popover>
                                    </ul>
                                    <div className='search__filter' style={{ paddingTop: '14px' }}>
                                        <div className='mobile_hide'>{SearchView()}</div>
                                        <div>
                                            <SortOutlinedIcon onClick={handleClick} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='mobile_show'>
                                {SearchView()}
                            </div>
                        </div>

                    </Grid>
                    <Grid item className="w-100 speciality_height">
                        <div className="druglist" >
                            <Grid container className='drug_list_padding'>
                                <Grid item sm={6} className="sp-padding w-100">
                                    {data.length > 0 ? data.slice(0, (data.length / 2) + 1).map((val, i) => (
                                        val.id !== 29 ?
                                            <p key={val.id} className="text-ellipsis capitalize cursor" onClick={props.scroll}><Link to={`/elib/drugspecialities/${val.speciality.replace(/ /g, '').replace(/-/g, '_').toLowerCase()}-${val.id}`}>{val.speciality.replace(/-/g, ' ')}</Link></p>
                                            : ''
                                    )) : ''}</Grid>
                                <Grid item sm={6} className="sp-padding w-100">
                                    {data.length > 0 ? data.slice((data.length / 2) + 1, data.length).map((val, i) => (
                                        val.id !== 29 ?
                                            <p key={val.id} className="text-ellipsis capitalize cursor" onClick={props.scroll}><Link to={`/elib/drugspecialities/${val.speciality.replace(/ /g, '').replace(/-/g, '_').toLowerCase()}-${val.id}`}>{val.speciality.replace(/-/g, ' ')}</Link></p>
                                            : ''
                                    )) : ''}</Grid>
                            </Grid>

                            {/* <ul>
                                   {
                                       data.sort().map(val=>{
                                           return(<li style={{cursor:'pointer'}} onClick={props.scroll}><Link to={`/elib/drugspecialities/${val.replace(/ /g,'').toLowerCase()}`}>{val}</Link></li>)
                                       })
                                   }
                               </ul> */}
                        </div>
                    </Grid>
                </Grid> : com == 1 ? <ElibraryRightContainer handlePath={handlePath} /> : <DoseDetails />}
            </Box>
        </div>
    )
}
export default DrugInformation;