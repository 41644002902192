import { DialogActions, Grid } from "@mui/material"
import axios from "axios"
import { useState } from "react"
import { ValidatorForm } from "react-material-ui-form-validator"
import URL from "../../apiurl"
import { CloseBtn, CreateBtn, Inputs } from "./linkaccount"
import { headers } from "../../../redux/Actions/connectActions"

const EditAccount = (props) => {

    const [state, setState] = useState(props.data)

    const handleChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const updateAccount = () => {
        axios.put(URL.updateAccount,state,headers())
        .then(res => {console.log(res);props.getAccounts();props.close(null)})
        .catch(err => console.log(err))
    }

    return (
        <ValidatorForm>
            <Grid container>
                <Grid item sm={6} xs={12}>
                    <Inputs placeholder="Select Account Visibility"
                        handleChange={(e, value) => handleChange('visibility', value)}
                        options={['Public', 'Private']}
                        value={state.visibility}
                        validators={['required']}
                        errorMessages={['Please select account visibility']}
                        hide
                    />
                </Grid>
            </Grid>
            <DialogActions>
                <CloseBtn name="Close" onClick={() => props.close(null)} />
                <CreateBtn onClick={updateAccount} name="Update" />
            </DialogActions>
        </ValidatorForm>
    )
}

export default EditAccount