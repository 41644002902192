import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';

export default function FollowButton({ pad, follow, id, text }) {

    return (

        <Button variant="outlined" className={`${pad ? 'pad' : 'pad'} accept`} onClick={() => follow(id)}>
            <KeyboardArrowLeftIcon style={{ fontSize: '15px', marginRight: '-6px' }} />
            <PersonIcon style={{ fontSize: '17px' }} />
            <p className='followtext'>{text || 'Follow'}</p>
        </Button>

    );
}
