import * as React from 'react';
import Button from '@mui/material/Button';
import DialogBox from '../DialogBox/dialog'
import UserService from '../../services/UserService';
import { getRequests } from '../../redux/Actions/connectActions';
import { getMetaData } from '../../redux/Actions/actions';
import URL from '../apiurl';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../socket';
import axios from 'axios';


export default function SuggMenu({ Follow, menus, handleFollow, value, handleConnect, handleCancel, handleUnfollow, ...props }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userData, setUserData] = React.useState(props.userData)
    const open = Boolean(anchorEl);
    const [modal, setModal] = React.useState(false)
    const state = useSelector(state => state.userProfile.userProfile)
    const username = state.fullName
    const userID = state.id
    const receiverimage = state.hasOwnProperty("practioner") ? state?.practioner?.photo?.[0]?.url : ""

    const dispatch = useDispatch()

    React.useEffect(() => {
        setUserData(props.userData)
    }, [props.userData])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (event.target.textContent === 'Connect') {
            handleConnect(value.id)
        }
        if (event.target.textContent === 'Requested') {
            setModal(true)
        }
    };

    const changeStatusFn = (status) => {

        if (status === "requested") {
            return "Requested"
        }
    }

    const handleNotification = (data, notificationFrom) => {
        socket?.emit("sendNotification", {
            name: username,
            senderID: userID,
            receiverID: data.id,
            notificationFrom: notificationFrom,
            profilePic: receiverimage
        });
    };

    const AcceptRequest = async (data, status) => {
        let req = {
            "id": data.acceptRequestId,
            "metadata": await getMetaData(data.metadata),
            "requestedUser": UserService.getUserId(),
            "requestingFrom": data.id,
            "status": status,
            "userId": UserService.getUserId()
        }
        axios.put(`${URL.acceptRequest}/${data.acceptRequestId}`, req, {
            headers: {
                'Authorization': `Bearer ${UserService.getToken()}`
            }
        }).then(res => {
            props.closeCard(data.id)
            dispatch(getRequests())
            handleNotification(data, "REQUEST_ACCEPTED")
        })
            .catch(error => { })
    }

    const cancelRequest = (data) => {
        axios.delete(`${URL.deleteRequestById}/${data.acceptRequestId}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            setUserData({ ...userData, acceptStatus: 'not accepted', status: 'no request' })
            dispatch(getRequests())
            handleNotification(data, "REQUEST_DELETED")
        }).catch(err => { })
    }

    return (
        <>
            <DialogBox width="xs" open={modal} handleDialog={() => setModal(false)} Content={<p>Do you want to cancel request ?</p>}
                Actions={<div>
                    <Button variant="contained" className="add-option" sx={{ mr: 2 }} onClick={() => { handleCancel(value.id, value.requestId); setModal(false) }}>Yes</Button>
                    <Button variant="contained" className="add-option" onClick={() => setModal(false)}>No</Button>
                </div>} />
            {userData.acceptStatus === "accepted" ?
                <>
                    <Button onClick={() => AcceptRequest(userData, "ACCEPTED")} variant="outlined" sx={{ px: 1, py: 0, mr: 1, textAlign: 'center' }} className='pad accept'>Accept</Button>
                    <Button onClick={() => cancelRequest(userData)} variant="outlined" sx={{ px: 1, py: 0, textAlign: 'center' }} className='pad accept'>Decline</Button>
                </>

                :
                <Button
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant="outlined" sx={{ px: 2, py: 0, textAlign: 'center' }} className='pad accept'
                >
                    {(userData.status === "requested") ? changeStatusFn(userData.status, userData.followStatus) : <Follow value={value.id} />}
                </Button>}
        </>
    );
}
