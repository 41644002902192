import { FormControl, RadioGroup } from "@mui/material"
import CustomRB from "./radioButton"

const RadioInput = ({ options, value, handleChange, name, disabled }) => {
    return (
        <FormControl>
            <RadioGroup row aria-label="speciality" name="row-radio-buttons-group"
                value={value}
                onChange={(e, value) => handleChange(name, value)}
            >
                {options?.map((op, i) => <CustomRB key={i} disabled={disabled?.includes(i)} label={op} value={op} />)}

            </RadioGroup>
        </FormControl>
    )
}

export default RadioInput