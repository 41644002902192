import React, { useState } from 'react'
import './HeaderOption.css';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom'

function HeaderOption({ Icon, title, path, value, active, handleActive }) {

    return (
        <Link to={path}>
            <MenuItem className={`${active == value ? "active-item" : ''} headerOption h-100`} disableRipple onClick={() => handleActive(value)}>
                <div>
                    {Icon && <img src={Icon} className="headerOption__icon" />}
                    <p className="headerOption__title">{title}</p>
                </div>
            </MenuItem>
        </Link>
    )
}

export default HeaderOption
