import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import SidebarAppSubscription from './SidebarAppSubscription'

const AppSubscription = (props) => {

    let { appname, id } = useParams()


    return (
        <div>
            <Grid container spacing={2} sx={{ padding: "16px" }}>
                {/* <Grid item md={3} xs={12} sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block" } }}> */}
                <Grid item md={3} xs={12} >
                    <SidebarAppSubscription appname={appname} id={id} />
                </Grid>
                <Grid item md={9} sx={{ width: "100%" }}>

                    <Card style={{ minHeight: '80vh' }}>
                        <CardContent>
                            {props.children}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default AppSubscription