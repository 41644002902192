import { configureStore } from '@reduxjs/toolkit';
import Reducer from '../redux/reducer/reducer'
import connectReducer from '../redux/reducer/connectReducer';
import Registration from '../redux/reducer/regReducer'

export const store = configureStore({
  reducer: {
    userProfile: Reducer,
    connect: connectReducer,
    registration: Registration
  },
});
