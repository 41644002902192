import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Table, TableHead, TableBody, TableRow, TableCell, List, ListItem } from '@mui/material'


const oxford = [
    { id: '1a', value: 'Systematic reviews (with homogeneity) of randomized controlled trials' },
    { id: '1b', value: 'Individual randomized controlled trials (with narrow confidence interval)' },
    { id: '1c', value: 'All or none randomized controlled trials' },
    { id: '2a', value: 'Systematic reviews (with homogeneity) of cohort studies' },
    { id: '2b', value: 'Individual cohort study or low quality randomized controlled trials (e.g. <80% follow-up)' },
    { id: '2c', value: `"Outcomes" Research; ecological studies` },
    { id: '3a', value: 'Systematic review (with homogeneity) of case-control studies' },
    { id: '3b', value: `Individual case-control study` },
    { id: '4', value: 'Case-series (and poor quality cohort and case-control studies)' },
    { id: '5', value: `Expert opinion without explicit critical appraisal, or based on physiology, bench research or "first principles"` },
]

const sort = [
    { code: 'A', def: 'Consistent, good-quality patient-oriented evidence *' },
    { code: 'B', def: 'Inconsistent or limited-quality patient-oriented evidence *' },
    { code: 'C', def: 'Consensus, disease-oriented evidence *, usual practice, expert opinion, or case series for studies of diagnosis, treatment, prevention, or screening' }
]

const grade = [
    {
        code: 'A',
        qoe: 'High',
        heading: 'Further research is very unlikely to change our confidence in the estimate of effect.',
        list: ['Several high-quality studies with consistent results', 'In special cases: one large, high-quality multi-centre trial']
    },
    {
        code: 'B',
        qoe: 'Moderate',
        heading: 'Further research is likely to have an important impact on our confidence in the estimate of effect and may change the estimate.',
        list: ['One high-quality study', 'Several studies with some limitations']
    },
    {
        code: 'C',
        qoe: 'Low',
        heading: 'Further research is very likely to have an important impact on our confidence in the estimate of effect and is likely to change the estimate.',
        list: ['One or more studies with severe limitations']
    },
    {
        code: 'D',
        qoe: 'Very Low',
        heading: 'Any estimate of effect is very uncertain.',
        list: ['Expert opinion', 'No direct research evidence', 'One or more studies with very severe limitations']
    }
]
export default function LOETabs(props) {
    const [value, setValue] = React.useState('1');

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <TabContext value={value}>
                <Box>
                    <TabList onChange={handleChange} variant="fullWidth">
                        <Tab label="Oxford-CEBM" value="1" />
                        <Tab label="SORT" value="2" />
                        <Tab label="Grade" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <List>
                        {oxford?.map((val, i) => (
                            <ListItem button onClick={() => props.addLOE(val?.id)} key={i}>{val?.id} - {val?.value}</ListItem>
                        ))}
                    </List>
                </TabPanel>
                <TabPanel value="2">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: '16px' }}>Code</TableCell>
                                <TableCell style={{ fontSize: '16px' }} align="center">Definition</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sort?.map((val, i) => (
                                <TableRow className="cursor" onClick={() => props?.addLOE(val?.code)} key={i}>
                                    <TableCell style={{ fontSize: '16px' }}>{val?.code}</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>{val?.def}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TabPanel>
                <TabPanel value="3">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: '16px' }}>Code</TableCell>
                                <TableCell style={{ fontSize: '16px' }}>Quality of Evidence</TableCell>
                                <TableCell style={{ fontSize: '16px' }} align="center">Definition</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {grade?.map((val, i) => (
                                <TableRow className="cursor" onClick={() => props?.addLOE(val?.code)} key={i}>
                                    <TableCell style={{ fontSize: '16px' }}>{val?.code}</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>{val?.qoe}</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>
                                        <p>{val?.heading}</p>
                                        <ul>
                                            {val?.list?.map((l, i) => (<li key={i}>{l}</li>))}
                                        </ul>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TabPanel>
            </TabContext>
        </Box>
    );
}
