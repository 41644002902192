import { useState, useEffect } from 'react';
import { Grid, Box } from "@mui/material";
import accomplishments1 from '../../../Atom/modifiedicons/accomplishments.svg';
import InputField from '../../../Fields/TextFields';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';
import CssTextField from '../../../Fields/CssTextField';
import DatePicker from '@mui/lab/DatePicker';
import { Link, useHistory } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import UserService from '../../../../services/UserService';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { Button, Avatar } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import CloseIcon from '@mui/icons-material/Close';
import '../updateprofile.css';
import celebration from '../../../../images/celebration.svg';
import Upload from '../../../../icons/upload.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from 'react-redux';
import { MentionsInput, Mention } from "react-mentions";
import URL from '../../../apiurl';
import DeleteIcon from '@mui/icons-material/Delete';
import Dropdown from './dropdown';
import ShowMoreText from "react-show-more-text";
import { accomplishment } from '../../../../redux/Actions/actions';
import Snackbar from '@mui/material/Snackbar';
import DisplayAcc from './DisplayAcc';
// import Upload from '../../../icons/upload.svg';
import axios from 'axios';
import CreatePost from '../../../Molecule/Feed/createpost';
const SelectTextField = styled(SelectValidator)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '12px',
            height: '54px',
            boxShadow: '0px 3px 4px #756d6d29',
            color: 'grey',
        },
    }
});



export default function Crud({ state, handleChange, handleSubmit, handleOrg, imageHandler, image, cancel, show, submit, badges, metatags, meta, metasub, handledeleteimage, pdfviewer, fileimg, filename, url, ...props }) {

    const degree = [
        { option: "Fellowship", value: "Fellowship", type: "degree" },
        { option: "Workshop / Training module", value: "Workshop_or_Training_Module", type: "degree" },
        { option: "CME / CPD Programe", value: "CME_or_CPD_Programe", type: "degree" },
        { option: "Practice License", value: "Practice_License", type: "degree" }
    ]
    const category = [
        { option: "Original Research Article", value: "Original_Research_Article", type: "category" },
        { option: "Review Article", value: "Review_Article", type: "category" },
        { option: "Evidence Review", value: "Evidence_Review", type: "category" },
        { option: "Patent", value: "Patent", type: "category" },
        { option: "Case Study/Case Series", value: "Case_Study_or_Series", type: "category" },
        { option: "Patient Education", value: "Patient_Education", type: "category" },
        { option: "News / Media", value: "News_or_Media", type: "category" },
    ]
    const category1 = [
        { option: "Guest Speaker", value: "Guest_Speaker", type: "category1" },
        { option: "Presentation", value: "Presentation", type: "category1" },
        { option: "Research Excellence", value: "Research_Excellence", type: "category1" },
        { option: "Practice Excellence", value: "Practice_Excellence", type: "category1" },
        { option: "Humanitarian/ Social", value: "Humanitarian_or_Social", type: "category1" },
    ]
    const practise = [
        { option: "Treatment of Rare Condition", value: "Treatment_of_Rare_Condition", type: "practise" },
        { option: "Innovative Intervention", value: "Innovative_Intervention", type: "practise" },
        { option: "Complex Case/Procedure", value: "Complex_Case_or_Procedure", type: "practise" }
    ]
    const Volunter = [
        { option: "Social Habitat", value: "Social_Habitat", type: "Volunter" },
        { option: "Rural Health Programs", value: "Rural_Health_Programs", type: "Volunter" },
        { option: "Public Awareness", value: "Public_Awareness", type: "Volunter" },
        { option: "Skill Development Training", value: "Skill_Development_Training", type: "Volunter" },
    ]

    // console.log(state.coAuthors)
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [input, setInput] = useState('');
    const [pdfFile, setPdfFile] = useState('')
    const [form, setForm] = useState(props.formValue || 1)
    const [imgLoad, setImgLoad] = useState(false)
    const [users, setUsers] = useState([])
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    // const [pollForm, setPollForm] = useState({})
    // const [hashtag, setHashtag] = useState({ key: false, value: '' })
    const [alert, setAlert] = useState({ open: false, msg: '', post: false })
    const [view, setView] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(accomplishment())
    }, [])
    const accdata = useSelector(state => state.userProfile.accomplishment)
    // console.log(accdata)
    const filterdata = (accdata) ? accdata[accdata.length - 1] : ""
    // console.log(state.accomplishmentCategory)
    const combinedarray = [...degree, ...practise, ...category, ...category1, ...Volunter]
    // const mentions = useSelector(state => state.connect.mentions)
    // console.log(mentions)
    // console.log(image)

    // useEffect(() => {
    // if (pdfviewer) {
    // console.log(pdfviewer)
    const linktype1 = pdfviewer !== undefined && pdfviewer.length > 0 ? pdfviewer.split(';') : ""
    const variable = linktype1 ? linktype1[0].split('/') : ""
    const type = variable ? variable[1] : ""
    // }
    // }, [pdfviewer])

    // const linktype = image[0] ? image[0].split(".") : ""
    // const type = linktype ? linktype[linktype.length - 1] : ""

    const handlepdf = () => {
        setOpen(true)
    }
    const handleclosepdf = () => {
        setOpen(false);
    };


    const mentionsdata = () => {
        if (state.coAuthors !== '') {
            let org = state.coAuthors.split(' ')
            org = org[org.length - 1].replace(/\n/g, '').startsWith('@') ? org[org.length - 1].replace(/@/g, '') : ''
            if (org && !org.includes('href') && !org.includes('</a>')) {
                axios.get(`${URL.mentions}/${org}`, {
                    headers: {
                        Authorization: `Bearer ${UserService.getToken()}`
                    }
                }).then(res => {
                    // console.log(res.data)
                    let list = []
                    list = res.data.data.map(data => {
                        // console.log(data)
                        return {
                            ...data,
                            display: data.name.replace(/ /g, ''),
                            name: data.name,
                            id: data.type === 'user' ?
                                `/profile/${data.name.replace(/ /g, '').toLowerCase()}-${data.id}` : `/organization/${data.id}`
                        }
                    })
                    setUsers(list)
                    // console.log(list)
                })
            }
        }
    }

    useEffect(() => {
        mentionsdata()
    }, [state.coAuthors])

    // useEffect(() => {
    //     let list = []
    //     list = mentions.map(data => {
    //         return {
    //             ...data,
    //             display: data.name.replace(/ /g, ''),
    //             name: data.name,
    //             id: data.type === 'user' ?
    //                 `/profile/${data.name.replace(/ /g, '').toLowerCase()}-${data.id}` : `/organization/${data.id}`
    //         }
    //     })
    //     setUsers(list)
    //     // console.log(list)
    // }, [mentions])


    // console.log(users)

    // useEffect(() => {
    //     if (mentions.length === 0) {
    //         dispatch(getMentions())
    //     
    // }, [])

    useEffect(() => {
        if (props.formValue) {
            setForm(props.formValue)
        }
    }, [props.formValue])

    useEffect(() => {
        // console.log(state.accomplishmentCategory)
        combinedarray.find(element => {
            if (state.accomplishmentCategory === element.value) {
                // console.log(state.accomplishmentCategory === element.value ? element : '1')
                if (element.type === 'degree') {
                    setForm(2)
                }
                else if (element.type === "category") {
                    setForm(3)
                }
                else if (element.type === "category1") {
                    setForm(4)
                }
                else if (element.type === "practise") {
                    setForm(5)
                }
                else if (element.type === "Volunter") {
                    setForm(6)
                }
                handleChange("accomplishmentCategory", element)
            }
        })
    }, [])

    // const dispatch = useDispatch()
    // useEffect(() => {
    //     dispatch(accomplishment())
    // }, [])
    // const accdata = useSelector(state => state.userProfile.accomplishment)
    // console.log(accdata)
    // const filterdata = (accdata) ? accdata[accdata.length - 1] : ""
    // console.log(filterdata)
    // const handlegoback = () => {
    //     props.handlegoback()
    // }
    const handlepost = () => {
        setForm(8)
    }

    // console.log(mentions)
    const handlesubmited = () => {
        setView(true)
        setForm(0)
    }
    const handlegoback = () => {
        // (!show)
        cancel()
    }
    // console.log(state)


    const sub = () => {
        // console.log(pdfviewer, fileimg, state.link)
        if (pdfviewer.length > 0 || fileimg.length > 0 || state.link.length > 0) {
            handleSubmit()
            setForm(7)
        }
        else {
            setAlert({ open: true, msg: "Add Attachment or link" })
        }
    }


    // console.log(state)
    // console.log(state.organisationName.displayName, "dfghjkl", state.unofficialOrganisationName)
    return (
        <>
            <Grid container spacing={2}>
                {(form == 1) ?
                    <Grid item xs={12} md={12} className="cursor">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={accomplishments1} width="25px" height="25px"></img>
                            <span className='header_acc_title'>New Accomplishments</span>
                        </div>
                        <div style={{ padding: "15px" }}>
                            <p style={{ fontSize: "19px", fontWeight: "550", paddingTop: "10px" }}>Select Accomplishment You like to Add</p>
                        </div>
                        <div style={{ padding: "15px" }}>
                            {/* <p onClick={() => { setForm1(false); setForm2(true) }} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Licence & Certification</p> */}
                            <p onClick={() => setForm(2)} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Licence & Certification</p>
                        </div>
                        <div style={{ padding: "15px" }}>
                            {/* <p onClick={() => { setForm1(false); setForm2(false); setForm3(true) }} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Publication</p> */}
                            <p onClick={() => setForm(3)} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Publication</p>
                        </div>
                        <div style={{ padding: "15px" }}>
                            {/* <p onClick={() => { setForm1(false); setForm3(false); setForm4(true) }} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add honors & awards</p> */}
                            <p onClick={() => setForm(4)} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add honors & awards</p>
                        </div>
                        <div style={{ padding: "15px" }}>
                            {/* <p onClick={() => { setForm1(false); setForm4(false); setForm5(true) }} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Practice Accomplishment</p> */}
                            <p onClick={() => setForm(5)} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Practice Accomplishment</p>
                        </div>
                        <div style={{ padding: "15px" }}>
                            {/* <p onClick={() => { setForm1(false); setForm5(false); setForm6(true) }} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Volunteer Experience</p> */}
                            <p onClick={() => setForm(6)} style={{ fontSize: "21px", fontWeight: "400", marginLeft: "15px" }}>Add Volunteer Experience</p>
                        </div>
                    </Grid>
                    :
                    (form == 2) ?
                        <Grid item xs={12} md={12}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={accomplishments1} width="25px" height="25px"></img>
                                    <span className='header_acc_title'>Licence & Certification</span>
                                </div>
                                <div className='cursor' onClick={handlegoback}>
                                    {/* <Link to={`/myprofile/${name}-${id}`}> */}
                                    <ClearIcon />
                                    {/* </Link> */}
                                </div>
                            </div>
                            <ValidatorForm
                                // ref="form"
                                onSubmit={sub}
                                onError={errors => { }}
                            >
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={degree}
                                            isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                            // isOptionEqualToValue={(option, value) => console.log(option, value)}
                                            getOptionLabel={(option) => {
                                                // console.log(option)
                                                if (option.option) {
                                                    return option.option
                                                }
                                                else {
                                                    return option
                                                }
                                            }}
                                            onChange={(event, newValue) => {
                                                // console.log(newValue, "data")
                                                handleChange('accomplishmentCategory', newValue)
                                            }}
                                            value={state.accomplishmentCategory || null}
                                            fullWidth
                                            renderInput={(params) =>
                                                <CssTextField sx={{ mt: '16px' }} {...params} label="Category"
                                                    validators={['required']}
                                                    errorMessages={['Please Select Category']}
                                                    value={state.accomplishmentCategory}
                                                />}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} style={{ paddingLeft: "10px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label='Date'
                                                disableFuture
                                                value={state.date}
                                                onChange={(value) => handleChange('date', new Date(value))}
                                                // inputFormat="dd/MM/yyyy"
                                                format="DD/MM/YYYY"
                                                renderInput={(params) => {
                                                    return (
                                                        <CssTextField sx={{ mt: 2 }}
                                                            fullWidth
                                                            // helperText={params?.error ? 'Enter Valid date' : ''}
                                                            {...params}
                                                        />
                                                    )
                                                }
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <InputField
                                            validators={['required']}
                                            errorMessages={['Please Enter Title']}
                                            label="Title"
                                            placeholder="Enter Title"
                                            value={state.title} name="title" handleChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {/* {console.log(state)} */}
                                        <Dropdown handleOrg={handleOrg} value={state ? state.organisationName : state.unofficialOrganisationName} label="Issued By" name="organisation" />
                                        {/* {console.log(state.organisationName.displayName, "dfghjkl", state.unofficialOrganisationName)} */}
                                        {/* <InputField
                                            label="Issued By"
                                            placeholder="Enter Issued By"
                                            value={state.organisation} name="organisation" handleChange={handleChange}
                                        /> */}
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ width: "100%", paddingTop: "10px" }}>
                                        <InputField
                                            label="Description"
                                            placeholder="Enter summary"
                                            value={state.summary} name="summary" handleChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                            label="Link"
                                            placeholder="Enter Link"
                                            value={state.link || ''} name="link" handleChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {/* {console.log(image)} */}
                                        {/* <Button onClick={imageHandler} className='attachment_btn'><img src={Upload}  style={{ fontWeight: "600" }}></img></Button> */}
                                        {/* {console.log(show)} */}
                                        {(!show) ?
                                            <div className='space_btw_center'>
                                                <div>
                                                    {(type === "pdf") ?
                                                        <p style={{ fontSize: "14px", fontWeight: "450" }}>{filename}</p>
                                                        :
                                                        <div style={{ borderRadius: "8px", border: "1px solid grey" }}>
                                                            <Button onClick={handlepdf} className="icon__ml open_pdf">open image</Button>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    <button className='del_btn cursor' onClick={handledeleteimage}><DeleteIcon /></button>
                                                </div>
                                            </div>
                                            :
                                            <div className='attachment_btn'>
                                                <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                                    onChange={imageHandler}
                                                />
                                                <label htmlFor="post-img2" className="cursor">
                                                    <div className="inputOption" style={{ display: "flex" }}>
                                                        <img src={Upload} alt="media" width="20px" height="20px" />
                                                        <div className="icon__ml add_attach">Add Attachment</div>
                                                    </div>
                                                </label>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "8px" }}>
                                        {/* <div style={{ textAlign: 'right', display: "flex", justifyContent: "center" }}>
                                            <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg" style={{ display: 'none' }}
                                                onChange={imageHandler}
                                            />
                                            <label htmlFor="post-img2" className="cursor">
                                                <div className="inputOption media_class">
                                                    <img src={media} alt="media" style={{ fontSize: "16px" }} />
                                                    <div className="input__title icon__ml">Photo</div>
                                                </div>
                                            </label>
                                            <input type="file" name="uploadVideo" multiple id="uploadVideo" accept=".mp4" style={{ display: 'none' }}
                                                onChange={videoHandler}
                                            />
                                            <label htmlFor="uploadVideo" className="cursor">
                                                <div className="inputOption media_class">
                                                    <img src={video} alt="video" width="20px" height='24px' />
                                                    <div className="input__title icon__ml">Video</div>
                                                </div>
                                            </label>
                                            <input type="file" name="upload_file" id="upload_file" accept=".pdf" style={{ display: 'none' }}
                                                onChange={fileHandler}
                                            />
                                            <label htmlFor="upload_file" className="cursor">
                                                <div className="inputOption media_class" style={{ padding: '5px 10px' }}>
                                                    <img src={note_add} alt="file" style={{ fontSize: "16px" }} />
                                                    <div className="input__title icon__ml">File</div>
                                                </div>
                                            </label>
                                        </div> */}
                                        <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                            <Button type="submit" className='form1_submit'>{submit}</Button>
                                        </div>
                                    </Grid>

                                </Grid>
                            </ValidatorForm>
                        </Grid> :
                        /* ----------------SECOND FORM--------------------  */
                        (form == 3) ?
                            <Grid item xs={12} md={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={accomplishments1} width="25px" height="25px"></img>
                                        <span className='header_acc_title'>New Publication</span>
                                    </div>
                                    <div className='cursor' onClick={handlegoback}>
                                        <ClearIcon />
                                    </div>
                                </div>
                                <ValidatorForm
                                    onSubmit={sub}
                                    onError={errors => { }}
                                >
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={category}
                                                isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                                getOptionLabel={(option) => {
                                                    // console.log(option)
                                                    if (option.option) {
                                                        return option.option
                                                    }
                                                    else {
                                                        return option
                                                    }
                                                }}
                                                onChange={(event, newValue) => {
                                                    // console.log(newValue, "data")
                                                    handleChange('accomplishmentCategory', newValue)
                                                }}
                                                value={state.accomplishmentCategory || null}
                                                fullWidth
                                                renderInput={(params, data) =>
                                                    <CssTextField sx={{ mt: '16px' }} {...params} label="Category"
                                                        validators={['required']}
                                                        errorMessages={['Please Select Category']}
                                                        value={state.accomplishmentCategory}
                                                    />}
                                            />
                                        </Grid>
                                        {(state?.accomplishmentCategory?.value === "News_or_Media") ?
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <InputField
                                                        validators={['required']}
                                                        errorMessages={['Please Enter Link']}
                                                        label="Link"
                                                        placeholder="Enter Link"
                                                        value={state.link || ''} name="link" handleChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <div style={{ padding: "5px", maxWidth: "100%", borderRadius: "12px" }}>
                                                        {/* {console.log(Object.keys(metatags || meta).length > 0, "abcdef")} */}
                                                        {Object?.keys(metatags || meta)?.length > 0 ?
                                                            <div style={{ border: "0.65px solid black", borderRadius: "12px", padding: "5px" }}>
                                                                <div className='news_media_img'>
                                                                    {(metatags.image || meta.image) ? <img className='meta_display_img1' src={(metatags.image) ? metatags.image : (meta.image) ? (meta.image) : ""}></img> : ""}
                                                                </div>
                                                                <div style={{ display: "flex", padding: "8px 0px 0px 10px" }}>
                                                                    <h3 style={{ fontSize: "15px", color: "blue", fontWeight: "300" }}>{(metatags.url) ? metatags.url : (meta.url) ? meta.url : ""}</h3>
                                                                </div>
                                                                <div style={{ display: "flex", padding: "8px 0px 0px 10px" }}>
                                                                    <h3 className='meta_title_text'>{(metatags.title) ? metatags.title : (meta.title) ? meta.title : ""}</h3>
                                                                </div>
                                                                <div style={{ display: "flex", padding: "8px 0px 0px 10px" }}>
                                                                    <ShowMoreText
                                                                        lines={1}
                                                                        more="Seemore"
                                                                        less="...Showless"
                                                                        className='text_color1'
                                                                    >
                                                                        {(metatags.description) ? metatags.description : (meta.description) ? meta.description : ""}
                                                                    </ShowMoreText>
                                                                </div>
                                                            </div> : ""
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                                        <Button type="submit" className='form1_submit'>{submit}</Button>
                                                    </div>
                                                </Grid>
                                            </> :
                                            <Grid item xs={12} md={12}>
                                                <Grid item sm={12} xs={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label='Date of Publication'
                                                            value={state.date}
                                                            disableFuture
                                                            onChange={(value) => handleChange('date', new Date(value))}
                                                            // inputFormat="dd/MM/yyyy"
                                                            format="DD/MM/YYYY"
                                                            renderInput={(params) => {
                                                                return (
                                                                    <CssTextField sx={{ mt: 2 }}
                                                                        fullWidth
                                                                        // helperText={params?.error ? 'Enter Valid date' : ''}
                                                                        {...params}
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <InputField
                                                        validators={['required']}
                                                        errorMessages={['Please Enter Title']}
                                                        label="Title"
                                                        placeholder="Enter Title"
                                                        value={state.title} name="title" handleChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Dropdown handleOrg={handleOrg} value={state ? state.organisationName : state.unofficialOrganisationName} label="Journal" name="organisation" />
                                                </Grid>
                                                <Grid item xs={12} md={12} style={{ width: "100%", paddingTop: "10px" }}>
                                                    <InputField
                                                        label="Summary"
                                                        placeholder="Enter Summary"
                                                        value={state.summary} name="summary" handleChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <MentionsInput
                                                        value={state.coAuthors}
                                                        placeholder="+ Co-Authors"
                                                        autoFocus
                                                        // name='coAuthors'
                                                        onChange={(e) => handleChange('coAuthors', e.target.value)}
                                                        // onChange={(e) => setInput(e.target.value)}
                                                        allowSuggestionsAboveCursor={true}
                                                        // allowSpaceInQuery={true}
                                                        // allowSpaceInQuery={true}
                                                        className="mentions"
                                                        id='mentions1'
                                                    >
                                                        <Mention trigger="@" data={users} type="user"
                                                            // appendSpaceOnAdd={true}
                                                            markup="<a class='b-color textLowerCase' href='__id__'>@__display__</a> "
                                                            renderSuggestion={(entry, search, highlightedDisplay, index, focused) => { return (<div className="d-flex e-align"><Avatar src={entry.profilePic} width="20px" height="20px" />{entry.name}</div>) }}
                                                            className="mentions__mention"
                                                            displayTransform={(id, display) => `@${display} `}
                                                        />
                                                    </MentionsInput>
                                                    {/* {console.log(state.coAuthors)} */}
                                                    {/* {console.log(input)} */}
                                                </Grid>
                                                <Grid item xs={12} md={12} style={{ display: "flex" }}>
                                                    <Grid item xs={12} md={6}>
                                                        <InputField
                                                            // validators={['required']}
                                                            // errorMessages={['Please Enter Link']}
                                                            label="Link"
                                                            placeholder="Enter Link"
                                                            value={state.link || ''} name="link" handleChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{ padding: "13px 0px 0px 20px" }}>
                                                        {(!show) ?
                                                            <div className='space_btw_center'>
                                                                <div>
                                                                    {(type === "pdf") ?
                                                                        <p style={{ fontSize: "14px", fontWeight: "450" }}>{filename}</p>
                                                                        :
                                                                        <div style={{ borderRadius: "8px", border: "1px solid grey" }}>
                                                                            <Button onClick={handlepdf} className="icon__ml open_pdf">open image</Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <button className='del_btn cursor' onClick={handledeleteimage}><DeleteIcon /></button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='attachment_btn'>
                                                                <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                                                    onChange={imageHandler}
                                                                />
                                                                <label htmlFor="post-img2" className="cursor">
                                                                    <div className="inputOption" style={{ display: "flex" }}>
                                                                        <img src={Upload} alt="media" width="20px" height="20px" />
                                                                        <div className="icon__ml add_attach">Add Attachment</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                                        <Button type="submit" className='form1_submit'>{submit}</Button>
                                                    </div>
                                                </Grid>
                                                {/* <Button className='attachment_btn'><img src={Upload} width="20px" height="20px" style={{ fontWeight: "600" }}></img>Add Attachment</Button> */}
                                            </Grid>

                                        }
                                    </Grid>
                                </ValidatorForm>
                            </Grid>
                            /* ----------------THIRD FORM-------------------- */
                            :
                            (form == 4) ?

                                <Grid item xs={12} md={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={accomplishments1} width="25px" height="25px"></img>
                                            <span className='header_acc_title'>New Honors & Awards</span>
                                        </div>
                                        <div className='cursor' onClick={handlegoback}>
                                            <ClearIcon />
                                        </div>
                                    </div>
                                    {/* <div style={{ display: "flex", alignItems: "center" }}><img src={accomplishments} width="25px" height="25px"></img><span className='header_acc_title'>New Honors & Awards</span></div> */}
                                    <ValidatorForm
                                        // ref="form"
                                        onSubmit={sub}
                                        onError={errors => { }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={category1}
                                                    isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                                    getOptionLabel={(option) => {
                                                        // console.log(option)
                                                        if (option.option) {
                                                            return option.option
                                                        }
                                                        else {
                                                            return option
                                                        }
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        // console.log(newValue, "data")
                                                        handleChange('accomplishmentCategory', newValue)
                                                    }}
                                                    value={state.accomplishmentCategory || null}
                                                    fullWidth
                                                    renderInput={(params) =>
                                                        <CssTextField sx={{ mt: '16px' }} {...params} label="Category"
                                                            validators={['required']}
                                                            errorMessages={['Please Select Category']}
                                                            value={state.accomplishmentCategory}
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item sm={6} xs={12} style={{ paddingLeft: "10px" }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label='Date'
                                                        value={state.date}
                                                        disableFuture
                                                        onChange={(value) => handleChange('date', new Date(value))}
                                                        // inputFormat="dd/MM/yyyy"
                                                        format="DD/MM/YYYY"
                                                        renderInput={(params) => {
                                                            return (
                                                                <CssTextField sx={{ mt: 2 }}
                                                                    fullWidth
                                                                    helperText={params?.error ? 'Enter Valid date' : ''}
                                                                    {...params}
                                                                />
                                                            )
                                                        }
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <InputField
                                                    validators={['required']}
                                                    errorMessages={['Please Enter Title']}
                                                    label="Award Title"
                                                    placeholder="Enter Award Title"
                                                    value={state.title} name="title" handleChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Dropdown handleOrg={handleOrg} value={state ? state.organisationName : state.unofficialOrganisationName} label="Issuer" name="organisation" />

                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ width: "100%", paddingTop: "10px" }}>
                                                <InputField
                                                    label="Summary"
                                                    placeholder="Enter Summary"
                                                    value={state.summary} name="summary" handleChange={handleChange}
                                                />

                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InputField
                                                    // validators={['required']}
                                                    // errorMessages={['Please Enter Link']}
                                                    label="Link"
                                                    placeholder="Enter Link"
                                                    value={state.link || ''} name="link" handleChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {(!show) ?
                                                    <div className='space_btw_center'>
                                                        <div>
                                                            {(type === "pdf") ?
                                                                <p style={{ fontSize: "14px", fontWeight: "450" }}>{filename}</p>
                                                                :
                                                                <div style={{ borderRadius: "8px", border: "1px solid grey" }}>
                                                                    <Button onClick={handlepdf} className="icon__ml open_pdf">open image</Button>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <button className='del_btn cursor' onClick={handledeleteimage}><DeleteIcon /></button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='attachment_btn'>
                                                        <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                                            onChange={imageHandler}
                                                        />
                                                        <label htmlFor="post-img2" className="cursor">
                                                            <div className="inputOption" style={{ display: "flex" }}>
                                                                <img src={Upload} alt="media" width="20px" height="20px" />
                                                                <div className="icon__ml add_attach">Add Attachment</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                                    <Button type="submit" className='form1_submit'>{submit}</Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </Grid>
                                :
                                (form == 5) ?

                                    <Grid item xs={12} md={12}>
                                        {/* <div style={{ display: "flex", alignItems: "center" }}><img src={accomplishments} width="25px" height="25px"></img><span className='header_acc_title'>Practice Accomplishment</span></div> */}
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img src={accomplishments1} width="25px" height="25px"></img>
                                                <span className='header_acc_title'>Practice Accomplishment</span>
                                            </div>
                                            <div className='cursor' onClick={handlegoback}>
                                                <ClearIcon />
                                            </div>
                                        </div>
                                        <ValidatorForm
                                            // ref="form"
                                            onSubmit={sub}
                                            onError={errors => { }}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={practise}
                                                        isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                                        getOptionLabel={(option) => {
                                                            // console.log(option)
                                                            if (option.option) {
                                                                return option.option
                                                            }
                                                            else {
                                                                return option
                                                            }
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            // console.log(newValue, "data")
                                                            handleChange('accomplishmentCategory', newValue)
                                                        }}
                                                        value={state.accomplishmentCategory || null}
                                                        fullWidth
                                                        renderInput={(params) =>
                                                            <CssTextField sx={{ mt: '16px' }} {...params} label="Category"
                                                                validators={['required']}
                                                                errorMessages={['Please Select Category']}
                                                                value={state.accomplishmentCategory}
                                                            />}
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12} style={{ paddingLeft: "10px" }}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label='Date'
                                                            value={state.date}
                                                            disableFuture
                                                            onChange={(value) => handleChange('date', new Date(value))}
                                                            // inputFormat="dd/MM/yyyy"
                                                            format="DD/MM/YYYY"
                                                            renderInput={(params) => {
                                                                return (
                                                                    <CssTextField sx={{ mt: 2 }}
                                                                        fullWidth
                                                                        helperText={params?.error ? 'Enter Valid date' : ''}
                                                                        {...params}
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <InputField
                                                        validators={['required']}
                                                        errorMessages={['Please Enter Title']}
                                                        label="Title"
                                                        placeholder="Enter Title"
                                                        value={state.title} name="title" handleChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    {/* {console.log(state)} */}
                                                    <Dropdown handleOrg={handleOrg} value={state ? state.organisationName : state.unofficialOrganisationName} label="Organisations" name="organisation" />
                                                </Grid>
                                                <Grid item xs={12} md={12} style={{ width: "100%", paddingTop: "10px" }}>

                                                    <InputField
                                                        label="Summary"
                                                        placeholder="Enter Summary"
                                                        value={state.summary} name="summary" handleChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <InputField
                                                        // validators={['required']}
                                                        // errorMessages={['Please Enter Link']}
                                                        label="Link"
                                                        placeholder="Enter Link"
                                                        value={state.link || ''} name="link" handleChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    {(!show) ?
                                                        <div className='space_btw_center'>
                                                            <div>
                                                                {(type === "pdf") ?
                                                                    <p style={{ fontSize: "14px", fontWeight: "450" }}>{filename}</p>
                                                                    :
                                                                    <div style={{ borderRadius: "8px", border: "1px solid grey" }}>
                                                                        <Button onClick={handlepdf} className="icon__ml open_pdf">open image</Button>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div>
                                                                <button className='del_btn cursor' onClick={handledeleteimage}><DeleteIcon /></button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='attachment_btn'>
                                                            <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                                                onChange={imageHandler}
                                                            />
                                                            <label htmlFor="post-img2" className="cursor">
                                                                <div className="inputOption" style={{ display: "flex" }}>
                                                                    <img src={Upload} alt="media" width="20px" height="20px" />
                                                                    <div className="icon__ml add_attach">Add Attachment</div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    {/* <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                                        <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg" style={{ display: 'none' }}
                                                            onChange={imageHandler}
                                                        />
                                                        <label htmlFor="post-img2" className="cursor">
                                                            <div className="inputOption media_class">
                                                                <img src={media} alt="media" style={{ fontSize: "16px" }} />
                                                                <div className="input__title icon__ml">Photo</div>
                                                            </div>
                                                        </label>
                                                        <input type="file" name="uploadVideo" multiple id="uploadVideo" accept=".mp4" style={{ display: 'none' }}
                                                            onChange={videoHandler}
                                                        />
                                                        <label htmlFor="uploadVideo" className="cursor">
                                                            <div className="inputOption media_class">
                                                                <img src={video} alt="video" width="20px" height='24px' />
                                                                <div className="input__title icon__ml">Video</div>
                                                            </div>
                                                        </label>
                                                        <input type="file" name="upload_file" id="upload_file" accept=".pdf" style={{ display: 'none' }}
                                                            onChange={fileHandler}
                                                        />
                                                        <label htmlFor="upload_file" className="cursor">
                                                            <div className="inputOption media_class" style={{ padding: '5px 10px' }}>
                                                                <img src={note_add} alt="file" style={{ fontSize: "16px" }} />
                                                                <div className="input__title icon__ml">File</div>
                                                            </div>
                                                        </label>
                                                    </div> */}
                                                    <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                                        <Button type="submit" className='form1_submit'>{submit}</Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </ValidatorForm>
                                    </Grid>
                                    :
                                    (form == 6) ?
                                        <Grid item xs={12} md={12}>
                                            {/* <div style={{ display: "flex", alignItems: "center" }}><img src={accomplishments} width="25px" height="25px"></img><span className='header_acc_title'></span></div> */}
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={accomplishments1} width="25px" height="25px"></img>
                                                    <span className='header_acc_title'>Volunteer Experience</span>
                                                </div>
                                                <div className='cursor' onClick={handlegoback}>
                                                    <ClearIcon />
                                                </div>
                                            </div>
                                            <ValidatorForm
                                                // ref="form"
                                                onSubmit={sub}
                                                onError={errors => { }}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} md={6}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={Volunter}
                                                            isOptionEqualToValue={(option, value) => option.value === value || option.value === value.value || ''}
                                                            // isOptionEqualToValue={(option, value) => console.log(option, value)}
                                                            getOptionLabel={(option) => {
                                                                // console.log(option)
                                                                if (option.option) {
                                                                    return option.option
                                                                }
                                                                else {
                                                                    return option
                                                                }
                                                            }}
                                                            onChange={(event, newValue) => {
                                                                // console.log(newValue, "data")
                                                                handleChange('accomplishmentCategory', newValue)
                                                            }}
                                                            value={state.accomplishmentCategory || null}
                                                            fullWidth
                                                            renderInput={(params, data) =>
                                                                <CssTextField sx={{ mt: '16px' }} {...params} label="Category"
                                                                    validators={['required']}
                                                                    errorMessages={['Please Select Category']}
                                                                    value={state.accomplishmentCategory}
                                                                />}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={12} style={{ paddingLeft: "10px" }}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label='Date'
                                                                value={state.date}
                                                                disableFuture
                                                                onChange={(value) => handleChange('date', new Date(value))}
                                                                // inputFormat="dd/MM/yyyy"
                                                                format="DD/MM/YYYY"
                                                                renderInput={(params) => {
                                                                    return (
                                                                        <CssTextField sx={{ mt: 2 }}
                                                                            fullWidth
                                                                            helperText={params?.error ? 'Enter Valid date' : ''}
                                                                            {...params}
                                                                        />
                                                                    )
                                                                }
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <InputField
                                                            validators={['required']}
                                                            errorMessages={['Please Enter Title']}
                                                            label="Title"
                                                            placeholder="Enter Title"
                                                            value={state.title} name="title" handleChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        {/* {console.log(state)} */}

                                                        <Dropdown handleOrg={handleOrg} value={state ? state.organisationName : state.unofficialOrganisationName} label="Organiser" name="organisation" />
                                                    </Grid>
                                                    <Grid item xs={12} md={12} style={{ width: "100%", paddingTop: "10px" }}>
                                                        <InputField
                                                            label="Summary"
                                                            placeholder="Enter Summary"
                                                            value={state.summary} name="summary" handleChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <InputField
                                                            // validators={['required']}
                                                            // errorMessages={['Please Enter Link']}
                                                            label="Link"
                                                            placeholder="Enter Link"
                                                            value={state.link || ''} name="link" handleChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        {(!show) ?
                                                            <div className='space_btw_center'>
                                                                <div>
                                                                    {(type === "pdf") ?
                                                                        <p style={{ fontSize: "14px", fontWeight: "450" }}>{filename}</p>
                                                                        :
                                                                        <div style={{ borderRadius: "8px", border: "1px solid grey" }}>
                                                                            <Button onClick={handlepdf} className="icon__ml open_pdf">open image</Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <button className='del_btn cursor' onClick={handledeleteimage}><DeleteIcon /></button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='attachment_btn'>
                                                                <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                                                    onChange={imageHandler}
                                                                />
                                                                <label htmlFor="post-img2" className="cursor">
                                                                    <div className="inputOption" style={{ display: "flex" }}>
                                                                        <img src={Upload} alt="media" width="20px" height="20px" />
                                                                        <div className="icon__ml add_attach">Add Attachment</div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                        {/* <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                                            <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg" style={{ display: 'none' }}
                                                                onChange={imageHandler}
                                                            />
                                                            <label htmlFor="post-img2" className="cursor">
                                                                <div className="inputOption media_class">
                                                                    <img src={media} alt="media" style={{ fontSize: "16px" }} />
                                                                    <div className="input__title icon__ml">Photo</div>
                                                                </div>
                                                            </label>
                                                            <input type="file" name="uploadVideo" multiple id="uploadVideo" accept=".mp4" style={{ display: 'none' }}
                                                                onChange={videoHandler}
                                                            />
                                                            <label htmlFor="uploadVideo" className="cursor">
                                                                <div className="inputOption media_class">
                                                                    <img src={video} alt="video" width="20px" height='24px' />
                                                                    <div className="input__title icon__ml">Video</div>
                                                                </div>
                                                            </label>
                                                            <input type="file" name="upload_file" id="upload_file" accept=".pdf" style={{ display: 'none' }}
                                                                onChange={fileHandler}
                                                            />
                                                            <label htmlFor="upload_file" className="cursor">
                                                                <div className="inputOption media_class" style={{ padding: '5px 10px' }}>
                                                                    <img src={note_add} alt="file" style={{ fontSize: "16px" }} />
                                                                    <div className="input__title icon__ml">File</div>
                                                                </div>
                                                            </label>
                                                        </div> */}
                                                        <div style={{ textAlign: 'right', marginTop: '15px', display: "flex", justifyContent: "center" }}>
                                                            <Button type="submit" className='form1_submit'>{submit}</Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </ValidatorForm>
                                        </Grid> :
                                        (form == 7) ?
                                            <Grid item xs={12} md={12}>
                                                <div>
                                                    <div className='cursor' style={{ display: "flex", justifyContent: "flex-end" }} onClick={handlegoback}>
                                                        <ClearIcon />
                                                    </div>
                                                    <div style={{ textAlign: "center" }}>
                                                        <img src={celebration} width="25px" height="25px"></img><span className='congo_text'>Congratulations</span>
                                                    </div>
                                                    <div className='acc_badge sidebar__pt'>
                                                        <h1>Your Have New Accomplishment Badge</h1>
                                                        {/* <h1>{`${`Your Have New Accomplishment ${(filterdata.typeOfAccomplishment).toLowerCase()}`}`}</h1> */}
                                                    </div>
                                                    <DisplayAcc handlegoback={handlegoback} badges={badges} metatags={metatags} meta={meta} url={url} />
                                                    <div className='space_btw_center pt-2'>
                                                        <h2 style={{ color: "#4A33FF", fontSize: "18px" }}>Celebrate you Accomplishment ?</h2>
                                                        <div>
                                                            <Button onClick={handlegoback} className='skipp'>Skip</Button>
                                                            <Button onClick={handlepost} className='create_post_btn'>Create Post</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid> :
                                            (form == 8) ?
                                                <Grid item xs={12} md={12}>
                                                    <CreatePost postdata={badges} openpopup={true} handleClose={handlegoback}></CreatePost>
                                                </Grid> : ""
                }

            </Grid >
            <Dialog
                open={open}
                onClose={handleclosepdf}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <div>
                        <div style={{ textAlign: "end" }}>
                            <CloseIcon className='cursor' onClick={handleclosepdf} />
                        </div>
                        <div style={{ maxHeight: '300px', overflow: 'hidden', padding: "10px" }}>
                            <img src={fileimg} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', width: '100%' }}></img>
                        </div>
                    </div>
                </Box>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />
            {/* <Grid>
                <ProfilePublication form={setForm(3)} />
            </Grid> */}
        </>
    )
}