// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import HttpService from "./services/HttpService";
// import UserService from "./services/UserService";
// import * as serviceWorker from './serviceWorker';



// const renderApp = () => ReactDOM.render(<App />, document.getElementById("root"));

// UserService.initKeycloak(renderApp);
// HttpService.configure();
// serviceWorker.unregister();

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import HttpService from "./services/HttpService";
import UserService from "./services/UserService";
import * as serviceWorker from './serviceWorker';

// Create the root container where the React tree will be rendered
const container = document.getElementById("root");
const root = createRoot(container);

// Define the renderApp function using root.render instead of ReactDOM.render
const renderApp = () => root.render(<App />);

// Initialize Keycloak and configure the HttpService
UserService.initKeycloak(renderApp);
HttpService.configure();

// Unregister the service worker
serviceWorker.unregister();


