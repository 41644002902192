import { Button, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserService from "../../../services/UserService";
import { headers } from "../../../redux/Actions/connectActions";

const BackToPage = () => {
  let getLocation = sessionStorage.getItem("backLinkPath");
  let fbToken = sessionStorage.getItem("FBtoken");
  console.log(
    fbToken,
    "kckckckc",
    getLocation,
    "paramsssss222222222",
    UserService.getToken()
  );
  const valuesss = sessionStorage.getItem("valueOfAouth");

  const history = useHistory();
  const handleBack = () => {
    console.log("first");
    let paramsCode = fbToken?.split("&code=")?.[1];
    console.log(fbToken.split("&iss")?.[1]?.includes("parimitha"), "vinay");
    if (fbToken.split("&iss")?.[1]?.includes("parimitha")) {
      if (paramsCode) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL_PYI}/paramithaOID/token?code=${paramsCode}`,{},
            {
              headers: {
                Authorization: `Bearer ${UserService.getToken()}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            history.push(`/myprofile/${UserService.getUsername().split("@")[0]}-${UserService.getUserId()}`)
          })
          .catch((err) => console.log(err));
      }
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL_PYI +
            `/upload${valuesss}Code${fbToken}`,
          {},
          {
            headers: {
              Authorization: `bearer ${UserService.getToken()}`,
            },
          }
        )
        .then((res) => {
          console.log(res, "9090sai");
          history.push(getLocation);
          Window.location.reload();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          backgroundColor: "#f0f0f0",
          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Back To Docsteth Page{" "}
        </Typography>
        <Button
          size="small"
          sx={{ textTransform: "capitalize", mt: 2 }}
          variant="contained"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default BackToPage;
