import React, { useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import NotificationsNoneIcon from "../../Atom/img/Icon material-notifications-none.png";
import { Badge, Divider, Menu } from '@mui/material';
import axios from 'axios';
import URL from '../../apiurl';
import UserService from '../../../services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { getMetaData, getNotifications, fetchProfile } from '../../../redux/Actions/actions';
import { Avatar } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import './dropdown.css';
import { useEffect } from "react";
import { socket } from '../../../socket';
import { useHistory } from 'react-router-dom';
import { getConnectCount, getPeople, getRequests } from '../../../redux/Actions/connectActions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '60px',
        [theme.breakpoints.up('xl')]: {
            width: '17%!important',
        },
        [theme.breakpoints.up('lg')]: {
            width: '20%!important',
        },
        width: '320px',
        marginLeft: '58px',
        height: 'inherit'
    }
}))

const DisplayNotification = ({ data, toggleDrawer, click }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const clickNotification = async (e, id, notificationFrom, clicked, postId) => {
        if (id) {
            axios.put(`${URL.updateNotificationById}/${id}`, { id: id, clicked: false, metadata: await getMetaData() }, {
                headers: {
                    Authorization: `Bearer ${UserService.getToken()}`
                }
            }).then(res => {
                dispatch(getNotifications())
            })
        }
        click()
        if (notificationFrom === 'REQUESTED') {
            history.push('/connect/requests')
        }
        else if (notificationFrom === 'REQUEST_ACCEPTED') {
            history.push('/connect/peers')
        }
        else if (notificationFrom === 'FOLLOWING') {
            history.push('/connect/followers')
        }
        else if (notificationFrom === 'LIKED') {
            history.push({
                pathname: `/post/${postId}`,
                state: UserService?.getUserId()
            })
            toggleDrawer(e)
        }
        else if (notificationFrom === 'COMMENTED') {
            history.push({
                pathname: `/post/${postId}`,
                state: UserService?.getUserId()
            })
            toggleDrawer(e)
        }
        toggleDrawer(e)
    }
    // console.log(data)
    return (
        data.map((not, i) => {
            const { name, notificationFrom, profilePic, clicked, notificationId, count, postId } = not
            // console.log(data, postId)
            return (
                <ul style={{ padding: "8px", display: "flex", alignItems: 'center', cursor: "pointer" }} key={i}
                    className={clicked === false ? 'n_bg' : ''}
                    onClick={(e) => clickNotification(e, notificationId, notificationFrom, clicked, postId)}
                >
                    <p style={{ height: '5px', width: '5px', backgroundColor: clicked === false ? 'blue' : 'transparent', borderRadius: '50%' }} />&nbsp;&nbsp;
                    <Avatar style={{ borderRadius: '7px', border: '1px solid lightgrey' }} src={profilePic} />&nbsp;
                    {notificationFrom === "LIKED" ?
                        <li className="notification">
                            {count > 1 ? `${name} and ${count - 1} people ` : name} {notificationFrom.toLowerCase()} your post.
                        </li> :
                        notificationFrom === "COMMENTED" ?
                            <li className="notification">
                                {name} {notificationFrom.toLowerCase()} on your post .
                            </li> :
                            <li className="notification cursor">
                                {name} {notificationFrom === "REQUESTED" ? 'sent you a request.'
                                    : notificationFrom === "REQUEST_ACCEPTED" ? 'accepted your request.'
                                        : notificationFrom === "FOLLOWING" ? 'started following you.' : ''}
                            </li>
                    }
                    {/* {(notificationFrom === "LIKED" || notificationFrom === "COMMENTED") ?

                        <li className="notification">
                             {count > 1 ? `${count} people ` : name} {notificationFrom.toLowerCase()} {notificationFrom === 'COMMENTED' ? 'on' : ''} your post .
                        </li> :
                        <li className="notification cursor">
                            {name} {notificationFrom === "REQUESTED" ? 'sent you a request.'
                                : notificationFrom === "REQUEST_ACCEPTED" ? 'accepted your request.'
                                    : notificationFrom === "FOLLOWING" ? 'started following you.' : ''}
                        </li>
                    } */}
                </ul>
            )
        })
    )

};

export default function Notifications() {

    const userID = UserService.getUserId();
    const [notify, setNotify] = useState([])
    const [data, setData] = React.useState([])
    const dispatch = useDispatch();
    const notification = useSelector(state => state.userProfile.notifications)
    // const [open, setOpen] = React.useState(false)
    const [clicked, setClicked] = useState(false)
    const [isLoading,setIsLoading]=useState(false);
    const styles = useStyles()
    const history = useHistory(0)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setData(notification)
        let a = []
        notification?.map(data => data.status === false ? a?.push(data) : '')
        setNotify(a)
    }, [notification])

    useEffect(() => {
        if (notification?.length === 0) {
            getNotification()
        }
    }, [])

    useEffect(() => {
        if (clicked === true) getNotification()
    }, [clicked])

    const getNotification = () => {
        dispatch(getNotifications())
    }


    // const toggleDrawer = (event) => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }
    //     setOpen(false);
    // };

    const imgClick = (event) => {
        if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
            return;
        }
        // setOpen(prev => !prev)
        handleClick(event)
        openNotification()
    }

    const openNotification = () => {
        if (notify.length > 0) {
            axios.put(`${URL.updateNotificationByUserId}/${sessionStorage?.getItem('userId')}`, {}, {
                headers: {
                    Authorization: `bearer ${UserService.getToken()}`
                }
            }).then(res => {
                setNotify([])
                getNotification()
            })
        }
    }

    useEffect(() => {
        if ((userID !== undefined) && (socket?.connected)) {
            socket?.emit("newUser", userID, 'Browser');
        }
    }, [socket, userID]);

    useEffect(() => {
        socket?.on('getnotificationbyid', (Snotification) => {
            // console.log("getnotificationbyid", Snotification)
            if (Snotification.notificationFrom !== 'REQUEST_DELETED') {
                setNotify((prev) => [{ ...Snotification, clicked: false }, ...prev]);
            }
            let path = window.location.pathname
            if (path?.includes('/connect')) {
                dispatch(getConnectCount())
            }

            switch (path) {
                case '/connect':
                    if (Snotification?.notificationFrom === 'REQUESTED' || Snotification?.notificationFrom === 'REQUEST_DELETED') {
                        dispatch(getRequests())
                        dispatch(getPeople('suggestedPeople'))
                    }
                    else if (Snotification?.notificationFrom === 'REQUEST_ACCEPTED') dispatch(getPeople('suggestedPeople'))
                    break;
                case '/connect/peers': if (Snotification?.notificationFrom === 'REQUEST_ACCEPTED' || Snotification?.notificationFrom === 'REQUEST_DELETED') dispatch(getPeople('getPeers'))
                    break;
                case '/connect/suggestedpeople': if (Snotification?.notificationFrom === 'REQUEST_ACCEPTED' || Snotification?.notificationFrom === 'REQUESTED' || Snotification?.notificationFrom === 'REQUEST_DELETED') dispatch(getPeople('suggestedPeople'))
                    break;
                case '/connect/followers': if (Snotification?.notificationFrom === 'FOLLOWING' || Snotification?.notificationFrom === 'REQUEST_DELETED') dispatch(getPeople('getFollowers'))
                    break;
                case '/connect/sentrequests': if (Snotification?.notificationFrom === 'REQUEST_ACCEPTED' || Snotification?.notificationFrom === 'REQUEST_DELETED') history.push(path)
            }
        })
    }, []);

    useEffect(() => {
        if (notify?.length > 0) {
            setData([...notify, ...notification])
        }
    }, [notify])


    return (
        <div style={{ position: "relative" }}>
            <span
                onClick={(e) => { imgClick(e) }}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            ><img
                    // onClick={(e) => imgClick(e)}
                    style={{ cursor: 'pointer' }}
                    src={NotificationsNoneIcon} className="header-bell__png" alt="bell" /></span>
            {notify?.length > 0 &&
                <div className='counter1'>
                    <Badge sx={{ '& .MuiBadge-badge': { backgroundColor: 'red', color: 'white' } }} badgeContent={notify?.length} max={999} />
                </div>
            }
            {open ? <ClickAwayListener onClickAway={handleClose}>
                {/* <Drawer
                    anchor='left'
                    open={open}
                    onClose={(e) => toggleDrawer(e)}
                    variant="persistent"
                    sx={{ width: '20%', position: 'absolute' }}
                    classes={{ paper: styles.paper }}
                > */}
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    sx={{ '& .MuiPaper-root': { width: { md: '280px', sm: '350px', xs: '100%' } } }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                        },
                    }}
                    // sx={{ width: '20%', position: 'absolute' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <Paper sx={{ boxShadow: 'none', maxHeight: 'auto', width: { md: '280px', sm: '350px', xs: '100%' } }}>
                        <MenuList
                            id="composition-menu"
                            aria-labelledby="composition-button"
                        >
                            <p style={{ padding: '0 10px 10px 10px' }}>Notifications</p>
                            <Divider />
                            <Box className='notification' sx={{ maxHeight: { md: 'calc(100vh - 140px)', xs: 'calc(100vh - 200px)' }, overflow: 'auto' }}>
                                <DisplayNotification data={data} toggleDrawer={handleClose} click={() => setClicked(!clicked)} />
                            </Box>
                        </MenuList>
                    </Paper>
                </Menu>
                {/* </Drawer> */}
            </ClickAwayListener>
                : ''}
        </div>
    )
}

