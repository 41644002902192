import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CertificateData = () => {

    const [courses, setCourses] = useState([])

    useEffect(() => {
        const url = `http://lms.docsteth.com/api/courses/v1/courses/?page=1&page_size=5`;
        axios.get(url).then(res => {
            setCourses(res.data.results);
        }).catch(err => {
            // console.log(err);
            setCourses([])
        });
    }, [])

    return (
        <>
            {
                courses.length > 0 ?
                    courses.map((course, i) => (
                        <a key={i} className="space_btw_center" href={`http://apps.lms.docsteth.com/learning/course/${course.course_id}/home`} target="_blank">
                            <div className="d-flex sidebar__pt1 textAlign capitalize e-align" style={{ width: '69%' }}>
                                <div style={{ width: '45px', height: '45px', borderRadius: '8px' }}>
                                    <img src={course.media.image.raw} alt="course" width='45px' height="45px" style={{ borderRadius: "8px", fontSize: '10px' }} />
                                </div>
                                <div className="sidebar__ml sidebar__pt1 overflow_hidden">
                                    <p className='job_title text-ellipsis'>{course.name}</p>
                                    <p className='job_sub_title text-ellipsis'>{course.org}</p>
                                    {/* <p className='job_city'>Duration</p> */}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className='job_sub_title'>Start date</div>
                                <div className='job_city'>{moment(course.start).format('MM-DD-YYYY')}</div>
                            </div>
                            <div className='sidebar__pt1'><ArrowForwardIosIcon fontSize='small' /></div>
                        </a>
                    ))
                    : <div className='job_title' style={{ padding: '5px', textAlign: 'center' }}>No Courses</div>
            }
        </>
    )
}

export default CertificateData