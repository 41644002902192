import React from "react";
import { ValidatorComponent } from 'react-form-validator-core'
import AttachmentIcon from '@mui/icons-material/Attachment';
import { FileUploader } from "react-drag-drop-files";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';

class FileValidator2 extends ValidatorComponent {
    renderValidatorComponent() {

        const { errorMessages, validators, requiredError, validatorListener, value, id, name, disabled, ...rest } = this.props;

        return (
            <>
                <div className="input_shadow" style={{ padding: '8px 15px', boxShadow: '2px 3px 10px lightgrey', borderRadius: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }} className={`file_input flx-center justify-between ${disabled ? "c_fileUploader" : ''}`}>
                        {/* <input type="file" name={name} id={id} accept='.jpg, .png, .jpeg, .pdf' className="d-none" onChange={this.props.onChange}/>
                        <label htmlFor={id} className="cursPoint" >
                            <span className="upload flx-center"><AttachmentIcon style={{ transform: 'rotate(-50deg)', fontSize: '1.7rem' }} /> Select Documents to upload</span>
                        </label> */}
                        {console.log(value)}
                        <FileUploader
                            handleChange={this.props.onChange}
                            name="file"
                            types={['JPG', 'PNG', 'JPEG', 'PDF']}
                            classes={`width100 cursPoint`}
                            fileOrFiles={value ? value : null}
                            disabled={disabled}
                            children={
                                <span style={{ display: 'flex', alignItems: 'center' }} className="flx-center">
                                    {value ?
                                        <><UploadFileIcon />&nbsp;{value}</> :
                                        <>
                                            <FileUploadOutlinedIcon sx={{ color: '#3719B2', fontSize: '25px' }} />&nbsp;
                                            Select or Drag & drop document
                                        </>}
                                </span>
                            }
                        />{!disabled && value && <span className="cursPoint" onClick={rest.clear}><CloseIcon /></span>}
                    </div>
                </div>
                {/* {this.props.value} */}
                {this?.errorText()}
            </>
        );
    }

    errorText() {
        const { isValid } = this.state;

        // console.log(isValid)

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'rgb(211 47 47)', fontSize: '12px', marginTop: '5px' }}>
                {this.getErrorMessage()}
            </div>
        )

    }
}

export default FileValidator2;