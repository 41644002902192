import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../SignUp/header'
import './contributor.css'
import Container from '@mui/material/Container'
import EBM from '../Atom/modifiedicons/ebmpractice_kit.svg';
import DrugInfo from '../Atom/modifiedicons/drug_info.svg';
import Media from '../Atom/modifiedicons/video_guides.svg';
import Footer from '../SignUp/footer'
import CopyRights from '../SignUp/copyrights';
import Search from '../Molecule/Header/search'
import { Form1, Form2 } from './form'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Video from '../../images/contributor.mp4'

const Card = ({ img, heading, subtext }) => {
    return (
        <Grid item sm={4} xs={12} sx={{ mb: 3 }}>
            <img src={img} alt={heading} width="56px" />
            <Typography className="ebm-color" sx={{ fontSize: '24px', mt: 1 }}>{heading}</Typography>
            <Typography className="appointment" sx={{ mt: 1 }}>{subtext}</Typography>
        </Grid>
    )
}

export default function MainPage() {

    const [state, setState] = useState(true)
    const [data, setData] = useState({ firstName: '', lastName: '', systemOfPractice: '', speciality: '', organisation: '', qualification: '', countryOfPractice: '', role: '' })

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value })
    }

    const SearchBox = () => {
        return (
            <Box component="span" sx={{display: {sm: 'contents',xs: 'none'}}}><Search width={{md: '20%',xs: '35%'}}/></Box>
        )
    }

    return (
        <div className="sign-bg" style={{ minHeight: '100vh' }}>
            <Header search={<SearchBox/>}>
                <Box component="span" sx={{display: {sm: 'none',xs: 'block'}}}><Search/></Box>
            </Header>
            <Grid className='reg-left' sx={{ mt: 2,textAlign:'center' }} justifyContent='center' alignItems='center'>

                <Container>
                    <div className="con-color">
                        <Typography className="join-text con-color">Join Us</Typography>
                        <Divider className="con-hr m-auto" sx={{ width: { md: '25%', xs: '50%' } }} />
                        <Typography className="join-text con-color">Contribute for Better Practices</Typography>

                    </div>
                    <Typography className="platform ebm-color" sx={{ mt: 2 }}>Help us develop evidence based medicine, contribute with your knowledge, research and practice. Your contribution make healthcare better</Typography>
                    <Grid container sx={{ mt: 6 }}>
                        <Card img={EBM} heading="EBM Resources" subtext="Review Evidence based Guidelines. Keep EBM Resources updated" />
                        <Card img={DrugInfo} heading="Drug Information" subtext="Special tools to report Adverse Drug Reactions, and clinical efficacy" />
                        <Card img={Media} heading="Media Library" subtext="Contribute images and videos, To help others understand and practice" />
                    </Grid>
                </Container>
                <Container maxWidth="xl" sx={{backgroundColor:'#007DCD',height:'656px',display: 'flex'}} className="elib-right__content">
                    <Container>
                        <iframe src={Video} title='Video' height="617px" width="1097px"></iframe>
                    </Container>    
                </Container>
                <Container>
                    <Typography sx={{ color: '#007DCD', fontSize: '30px', mt: 3 }}>Join Exclusive club of Doctors</Typography>
                    <Typography className="platform ebm-color" sx={{ mt: 2 }}>Publish Evidence based practice guidelines and research article, adding value to your profession is adding value to your profile</Typography>
                    {state ? <Form1 next={() => setState(false)} data={data} handleChange={handleChange} /> : <Form2 />}
                </Container>
            </Grid>
            <Footer />
            <CopyRights />
        </div>
    )
}