import React, { useState } from 'react'
import { Typography, Button } from '@mui/material'

export default function ArticlePost(props) {
    // console.log(props)
    const articleData = props?.post?.articleData
    const post = props?.post
    // console.log(articleData)

    return (
        <div style={{ border: '1px solid lightgrey', color: 'black', margin: "10px" }}>

            {/* <div style={{ padding: '10px' }}>
                <div style={{ fontFamily: 'inherit' }} className="article-para" dangerouslySetInnerHTML={{__html: articleData.description}}/>
            </div> */}
            {post?.articleData?.coAuthors ? <Typography sx={{ p: '5px 10px', fontSize: '14px' }}>Co-Authors: <span dangerouslySetInnerHTML={{ __html: post?.articleData?.coAuthors }} /></Typography> : ''}
            {post?.articleData?.contributors ? <Typography sx={{ p: '0px 10px 5px 10px', fontSize: '14px' }}>Contributors: <span dangerouslySetInnerHTML={{ __html: post?.articleData?.contributors }} /></Typography> : ''}

            <div>
                <div style={{ maxHeight: '300px', overflow: 'hidden', position: 'relative' }}>
                    {post?.sourceLink !== null && post?.sourceLink !== '' && post?.sourceLink?.length > 0 ? <img src={post?.sourceLink} alt="cover" style={{
                        maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', width: '100%',
                    }} /> :
                        <div style={{ fontFamily: 'inherit', padding: '10px' }} className="article-para" dangerouslySetInnerHTML={{ __html: articleData?.articleDescription }} />
                    }
                </div>
            </div>
            {articleData?.articleTitle !== null ? <a href={`/viewarticle/${post?.id}`}>
                <div style={{ padding: '15px 20px', backgroundColor: '#e8e8e0', justifyContent: 'space-between' }} className="d-flex cursor">

                    {articleData?.articleTitle ?
                        <p style={{ overflow: "hidden", flex: 1 }}>
                            <Typography className="font-bold capitalize add-cover text-ellipsis" style={{ pt: 1, fontSize: '20px' }}>
                                {articleData?.articleTitle}
                            </Typography></p> : ''}

                    <Button variant="outlined" sx={{ py: '3px!important' }}>Read Article</Button>

                </div>
            </a> : ''}
        </div>
    )
}