import { io } from "socket.io-client";

// export const socket = io(`http://localhost:4000`)
// console.log(socket)

export const socket = io(process.env.REACT_APP_API_NOTIFICATION)

// addlike,deletelike,add comment,
// delete comment, add post, get feed, get public feed
//

// console.log("connected", socket.connected)https://dev.api.docsteth.com
// export const socket = io(process.env.REACT_APP_API_NOTIFICATION, { transports: ['websocket'] })