// import React, { useState, useEffect } from "react";
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import HealthDeptIcon from "../../../../../icons/Healthcare_Department.svg";
// import { useSelector } from "react-redux";
// import scroll from "../../../../Atom/svg/scroll.svg";
// import { D_API } from "../../../../../services/APIService";
// import { URI } from "../../../../utility/uri";
// import DefaultHealthServiceIcon from "../../../../../icons/Healthcare_Service.svg";
// import { FormDivider } from "../../../../utility/utils";

// const RearrangingServices = ({ open, close, servicesList, updateGetList }) => {
//     const [info, setInfo] = useState([]);
//     const orgData = useSelector(state => state.registration.userOrgData);

//     useEffect(() => {
//         // Initialize info with sorted services
//         if (servicesList) {
//             let sortList = [...servicesList].sort((a, b) => {
//                 const aVal = a?.resource?.identifier?.find(e => e.system === "https://positionofservices/")?.value || 0;
//                 const bVal = b?.resource?.identifier?.find(e => e.system === "https://positionofservices/")?.value || 0;
//                 return aVal - bVal;
//             });
//             setInfo(sortList);
//         }
//     }, [servicesList]);

//     console.log(info, "ser")

//     const getListOfHealthServices = (orgID) => {
//         D_API.get(`${URI.getHealthCareServiceList}${orgID}`).then((res) => {
//             let sortList = res.data?.entry ? res.data?.entry?.sort((a, b) => {
//                 const aVal = a?.resource?.identifier?.find(e => e.system === "https://positionofservices/")?.value || 0;
//                 const bVal = b?.resource?.identifier?.find(e => e.system === "https://positionofservices/")?.value || 0;
//                 return aVal - bVal;
//             }) : [];
//             updateGetList(sortList);
//         }).catch((err) => console.log(err));
//     }

//     const updateService = (data) => {
//         const { resource } = data;
//         if (resource?.id) {
//             D_API.put(`${URI.updateHealthServiceDept}/${resource.id}`, resource)
//                 .then(() => {
//                     if (orgData?.id) {
//                         getListOfHealthServices(orgData.id);
//                     }
//                 })
//                 .catch((err) => console.log(err));
//         }
//     }

//     const handleDragEnd = (result) => {
//         if (!result.destination) {
//             return;
//         }

//         const { source, destination } = result;
//         const updatedList = Array.from(info);
//         const [movedItem] = updatedList.splice(source.index, 1);
//         updatedList.splice(destination.index, 0, movedItem);

//         // Update the info state with the new order
//         setInfo(updatedList);

//         // Update the identifiers of the moved items
//         updatedList.forEach((item, index) => {
//             const updatedItem = {
//                 ...item,
//                 resource: {
//                     ...item.resource,
//                     identifier: item.resource.identifier?.map(id =>
//                         id.system === "https://positionofservices/"
//                             ? { ...id, value: index.toString() }
//                             : id
//                     )
//                 }
//             };
//             updateService(updatedItem);
//         });
//     };

//     return (
//         <Dialog
//             open={open}
//             onClose={close}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//             maxWidth="sm"
//             fullWidth
//             style={{ height: "500px", overflow: "hidden" }}
//         >
//             <DialogTitle id="alert-dialog-title">
//                 <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Re-Arrange Services</div>
//             </DialogTitle>
//             <DialogContent
//                 style={{ overflow: "auto", '&::-webkit-scrollbar': { display: 'none' } }}
//             >
//                 <DragDropContext onDragEnd={handleDragEnd}>
//                     <Droppable droppableId="Services">
//                         {(provided, snapshot) => (
//                             <div
//                                 {...provided.droppableProps}
//                                 ref={provided.innerRef}
//                                 style={{ overflowY: "auto", height: "100%" }}
//                             >
//                                 {info.length > 0 ? (
//                                     info?.map((val, indx) => (
//                                         <Draggable
//                                             key={val.resource.id || indx}  // Use unique id or index for key
//                                             draggableId={val.resource.id || indx.toString()}  // Use unique id or index for draggableId
//                                             index={indx}  // Use zero-based index for sorting
//                                         >
//                                             {(provided) => (
//                                                 <div
//                                                     ref={provided.innerRef}
//                                                     {...provided.draggableProps}
//                                                     {...provided.dragHandleProps}
//                                                     style={{
//                                                         ...provided.draggableProps.style,
//                                                         display: "flex",
//                                                         alignItems: "center",
//                                                         gap: "10px",
//                                                         padding: "10px",
//                                                         marginBottom: "10px",
//                                                         backgroundColor: snapshot.isDragging ? "#e3f2fd" : "white",
//                                                         borderRadius: "7px",
//                                                         border: "1px solid #ddd"
//                                                     }}
//                                                 >
//                                                     <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
//                                                         <img
//                                                             src={val?.resource?.photo?.url || DefaultHealthServiceIcon}
//                                                             alt="service"
//                                                             width='30px'
//                                                             height="30px"
//                                                         />
//                                                     </div>
//                                                     <div style={{ height: "40px", width: "100%" }}>
//                                                         <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
//                                                             {val?.resource?.name}
//                                                         </div>
//                                                         <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
//                                                             {val?.resource?.alias?.[0]}
//                                                         </div>
//                                                     </div>
//                                                     <div>
//                                                         <img src={scroll} alt="scroll" />
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </Draggable>
//                                     ))
//                                 ) : (
//                                     <p className="flx-center justify-center fntSemiBold fntSm14">No Data Available</p>
//                                 )}
//                                 {provided.placeholder}
//                             </div>
//                         )}
//                     </Droppable>
//                 </DragDropContext>
//             </DialogContent>
//             <FormDivider />
//             <DialogActions>
//                 <Button variant="text" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>Close</Button>
//             </DialogActions>
//         </Dialog>
//     );
// };

// export default RearrangingServices;



















// testend
import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HealthDeptIcon from "../../../../../icons/Healthcare_Department.svg";

import { useSelector } from "react-redux";
import scroll from "../../../../Atom/svg/scroll.svg";
import { D_API } from "../../../../../services/APIService";
import { URI } from "../../../../utility/uri";

import DefaultHealthServiceIcon from "../../../../../icons/Healthcare_Service.svg";
import { FormDivider } from "../../../../utility/utils";

const RearrangingServices = ({ open, close, servicesList, updateGetList }) => {
    const [info, setInfo] = useState([]);
    const orgData = useSelector(state => state.registration.userOrgData);

    useEffect(() => {
        let sortList = servicesList?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value);
        if (sortList?.length > 0) {
            setInfo(sortList);
        } else {
            setInfo([]);
        }

    }, [servicesList]);


    const getListOfHealthServices = (orgID) => {
        D_API.get(`${orgData?.id}/${URI.getHealthCareServiceList}${orgID}`).then((res) => {
            let sortList = res.data?.entry ? res.data?.entry?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value) : []
            updateGetList(sortList);
        }).catch((err) => console.log(err));
    }

    const updateDepartments = (data) => {
        const { resource, ...rest } = data;
        if (resource?.id) {
            D_API.put(`${orgData?.id}/${URI.updateHealthServiceDept}/${resource?.id}`, { ...resource }).then((res) => {
                if (orgData?.id) {
                    getListOfHealthServices(orgData?.id)
                }
            }).catch((err) => console.log(err));
        }

    }


    console.log(info, "info")


    const handleDragEnd = (result) => {

        let list = [...info];
        if (!result?.destination) {
            return;
        }

        const { source, destination } = result;
        console.log(result, "result")
        const [sourceObj] = list?.splice(source?.index, 1);
        list?.splice(destination?.index, 0, sourceObj);


        const updatedList = list.map((item, index) => {
            if (item?.resource?.identifier?.some((val) => val.system === "https://positionofservices/")) {
                let data = {
                    ...item,
                    resource: {
                        ...item.resource,
                        identifier: item.resource?.identifier?.map((e) =>
                            e.system === "https://positionofservices/"
                                ? { ...e, value: (index).toString() }
                                : e
                        ),
                    },
                }
                updateDepartments(data);

                return {
                    ...item,
                    resource: {
                        ...item?.resource,
                        identifier: item?.resource?.identifier?.map((e) =>
                            e?.system === "https://positionofservices/"
                                ? { ...e, value: (index)?.toString() }
                                : e
                        ),
                    },
                };
            } else {
                let data = {
                    ...item, resource: { ...item.resource, identifier: item.resource?.identifier ? [...item?.resource?.identifier, { system: "https://positionofservices/", value: index?.toString() }]?.filter(((val) => val != undefined)) : [{ system: "https://positionofservices/", value: index?.toString() }] }
                }
                // console.log(data, "v000")
                updateDepartments(data);
                return {
                    ...item, resource: {
                        ...item.resource, identifier: item.resource?.identifier ? [...item?.resource?.identifier, {
                            system: "https://positionofservices/", value: (index)?.toString()
                        }]?.filter(((val) => val != undefined)) : [{ system: "https://positionofservices/", value: (index)?.toString() }]
                    }
                }
            }

        })



        // const updatedList = list?.map((item, index) => {

        //     if (item?.resource?.identifier?.some((val) => val.system === "https://positionofservices/")) {
        //         if (index === source?.index) {
        //             let data = {
        //                 ...item,
        //                 resource: {
        //                     ...item.resource,
        //                     identifier: item.resource.identifier?.map((e) =>
        //                         e.system === "https://positionofservices/"
        //                             ? { ...e, value: (source?.index).toString() }
        //                             : e
        //                     ),
        //                 },
        //             }
        //             updateDepartments(data);

        //             return {
        //                 ...item,
        //                 resource: {
        //                     ...item?.resource,
        //                     identifier: item?.resource?.identifier?.map((e) =>
        //                         e?.system === "https://positionofservices/"
        //                             ? { ...e, value: (source?.index)?.toString() }
        //                             : e
        //                     ),
        //                 },
        //             };
        //         }
        //         if (index === destination?.index) {
        //             let data = {
        //                 ...item,
        //                 resource: {
        //                     ...item?.resource,
        //                     identifier: item?.resource?.identifier?.map((e) =>
        //                         e?.system === "https://positionofservices/"
        //                             ? { ...e, value: (destination?.index)?.toString() }
        //                             : e
        //                     ),
        //                 },
        //             }

        //             updateDepartments(data);

        //             return {
        //                 ...item,
        //                 resource: {
        //                     ...item?.resource,
        //                     identifier: item?.resource?.identifier?.map((e) =>
        //                         e?.system === "https://positionofservices/"
        //                             ? { ...e, value: (destination?.index)?.toString() }
        //                             : e
        //                     ),
        //                 },
        //             };
        //         }
        //     } else {


        //         let data = {
        //             ...item, resource: { ...item.resource, identifier: [...item?.resource?.identifier, { system: "https://positionofservices/", value: index?.toString() }]?.filter(((val) => val != undefined)) }
        //         }
        //         updateDepartments(data);
        //         return {
        //             ...item, resource: {
        //                 ...item.resource, identifier: [...item?.resource?.identifier, {
        //                     system: "https://positionofservices/", value: (index)?.toString()
        //                 }]?.filter(((val) => val != undefined))
        //             }
        //         }
        //     }

        // })




        setInfo(updatedList)

    };


    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            style={{
                height: "500px",
                overflow: "hidden",

            }}
        >
            <DialogTitle id="alert-dialog-title">
                <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Re-Arrange Services</div>
            </DialogTitle>
            <DialogContent
                style={{
                    overflow: "auto",  // Ensure only DialogContent is scrollable
                    '&::-webkit-scrollbar': { display: 'none' }
                }}
            >
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="Services">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    overflowY: "auto",
                                    height: "100%",
                                }}
                            >
                                {info.length > 0 ? (
                                    info.map((val, indx) => (
                                        <Draggable
                                            key={indx.toString()}  // Use one-based index as string for key
                                            draggableId={indx.toString()}  // Use one-based index as string for draggableId
                                            index={indx}  // Use zero-based index for sorting
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        padding: "10px",
                                                        marginBottom: "10px",
                                                        backgroundColor: snapshot.isDragging ? "#e3f2fd" : "white",
                                                        borderRadius: "7px",
                                                        border: "1px solid #ddd"

                                                    }}
                                                >
                                                    <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px', }}>
                                                        <img
                                                            src={val?.resource?.photo?.url || DefaultHealthServiceIcon}
                                                            alt="organisation"
                                                            width='30px'
                                                            height="30px"
                                                        />
                                                    </div>
                                                    <div style={{ height: "40px", width: "100%" }}>
                                                        <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
                                                            {`${val?.resource?.name}`}
                                                        </div>
                                                        <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
                                                            {val?.resource?.alias?.[0]}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <img src={scroll} alt="scroll" />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <p className="flx-center justify-center fntSemiBold fntSm14">No Data Available</p>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>
            <FormDivider />
            <DialogActions>
                <Button variant="text" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RearrangingServices;
