import { Box, Button, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import CVLogo from '../../images/cvlogo.png'
import UserService from "../../services/UserService";

const Text = ({ text, className, color, mt }) => {
    return (
        <Typography variant="h5" className={!className ? "font-bold" : ''} sx={{ color: color || 'black', mt: mt }}>
            {text}
        </Typography>
    )
}

export default function CVLogin() {

    const [visit, setVisit] = useState(false)
    const { id } = useParams()

    return (
        <Box className="textCenter">
            <Paper sx={{ width: '50%', m: 'auto', p: 3 }}>
                <Text text="Unlock the Greatest Potential of Docsteth" />
                <Text text="With" className mt="10px"/>
                <img src={CVLogo} width="140px" height="70px" className="mt-20"/>
                <Text text="ENTREPRISE APPLICATIONS FOR HEALTHCARE" mt={1} color="#007DCD" />
            </Paper>

            <Text text="Create your enterprise business account" mt="40px" />
            <Button variant="contained" className="header-signin" href={`${process.env.REACT_APP_CLINIVAULT}/login/${UserService.getUserId()}/${id}`} sx={{ backgroundColor: '#007DCD!important', mt: "40px", ml: "0!important" }}>Signup</Button>
            {!visit ? <p className="cursor mt-20" onClick={() => setVisit(true)}>Learn more</p> :
                <div>
                    <Typography className="font-bold mt-20">or Visit</Typography>
                    <Typography className="mt-10">
                        <a href="www.clinivault.org" target="_blank" className="add-cover mt-10" style={{ color: "#0012A0" }}>www.clinivault.org</a>
                    </Typography>
                    <Box sx={{ backgroundColor: '#59D8FA', p: 6,pt: 3 }} className="mt-20">
                        <Typography variant="h5" sx={{ font: 'normal normal bold 35px/66px Nunito', color: '#3D6596' }}>How it Works ?</Typography>
                        <div style={{ fontSize: '16px', lineHeight: 1.5 }}>
                            <p>An Integrated solution connecting Stakeholders of Healthcare.</p>
                            <p>Sales, Marketing, Jobs, Billing , E-commerce, POS, billing, invoicing, account and more in one place</p>
                        </div>
                        <div style={{backgroundColor: 'white',height: '200px'}} className="mt-20">

                        </div>
                    </Box>
                </div>
            }

        </Box>
    )
}