// import React from 'react'

// function PdfViewer(props) {
//     const url = props.location.state
//     return (
//         <div>
//             <iframe height="650px" width="100%" src={url} scrolling="no" frameBorder="0" />
//         </div>
//     )
// }

// export default PdfViewer


// import React, { useState } from 'react'
// import { Document, Page, pdfjs } from 'react-pdf';
// import { Button } from "@mui/material";
// import { useHistory } from 'react-router-dom';
// import './Post.css';
// import './inputOption.css';

// function PdfViewer(props) {
//     console.log(props)
//     const url = props.location.state
//     const previous = "< previous";
//     const next = "next >";
//     const history = useHistory()
//     pdfjs.GlobalWorkerOptions.workerSrc =
//         `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//     const [numPages, setNumPages] = useState(null);
//     const [pageNumber, setPageNumber] = useState(1);

//     function onDocumentLoadSuccess({ numPages }) {
//         setNumPages(numPages);
//         setPageNumber(1);
//     }
//     function changePage(offset) {
//         setPageNumber(prevPageNumber => prevPageNumber + offset);
//     }

//     function previousPage() {
//         changePage(-1);
//     }

//     function nextPage() {
//         changePage(1);
//     }


//     return (
//         <>
//             <div className=''>
//                 <Document
//                     // className="pdf_view"
//                     // canvasBackground="transparent"
//                     file={`${url}`}
//                     renderMode="canvas"
//                     options={{ workerSrc: "/pdf.worker.js" }}
//                     onLoadSuccess={onDocumentLoadSuccess}
//                 // onLoadProgress={onpreload}
//                 // className={`${stateview ? "react-pdf__Document" : "pdf__view"}`}
//                 >
//                     <Page width={600}
//                         height={600}
//                         scale={0.5}
//                         pageNumber={pageNumber}
//                         renderAnnotationLayer={false}
//                         renderTextLayer={false}
//                     />
//                     {/* <Page width={580} scale={0.9} pageNumber={pageNumber} /> */}
//                 </Document>
//             </div>
//             <div className='bg_transperency1'>
//                 <div>
//                     <Button
//                         type="button"
//                         disabled={pageNumber <= 1}
//                         onClick={previousPage}
//                         className="cursor buttonc"
//                     >
//                         {`${previous}`}
//                     </Button>
//                 </div>
//                 <div className="pagec">
//                     {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
//                 </div>
//                 <div>
//                     <Button
//                         type="button"
//                         disabled={pageNumber >= numPages}
//                         onClick={nextPage}
//                         className="cursor buttonc"
//                     >
//                         {`${next}`}
//                     </Button>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default PdfViewer

// import React from 'react';
// import { Worker } from '@react-pdf-viewer/core';
// import { Document, Page, pdfjs } from "react-pdf";
// // Import the main component
// import { Viewer } from '@react-pdf-viewer/core';
// import { SpecialZoomLevel } from '@react-pdf-viewer/core';
// // Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import './Post.css'

// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// function PdfViewer(props) {
//     const url = props.location.state;
//     // console.log(url)
//     // const defaultLayoutPluginInstance = defaultLayoutPlugin();
//     return (
//         <div className='width_pdf'>
//              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
//                 <Viewer
//                     // plugins={[defaultLayoutPluginInstance]}
//                     fileUrl={url[0]}
//                     defaultScale={SpecialZoomLevel.PageFit}
//                 />
//             </Worker>
//         </div>
//     )
// }

// test
import React,{useState} from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import "./inputOption.css";
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';


import './Post.css';

function PdfViewer(props) {

    // let pdf = data ? data.split(",") : "";
    // let url = pdf.slice(0, -1);
    // console.log(url, "pdfurl");
    // console.log(image)
    // const accpdf = image
    // console.log(pdf)
    // console.log(url[0])
    const previous = "< previous";
    const next = "next >";
    const history = useHistory();
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  //   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
    function changePage(offset) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    }
  
    function onpreload(loaded, total) {
      // alert('Loading a document: ' + (loaded / total) * 100 + '%');
    }
    const handleclick = () => {
    //   history.push({
    //     pathname: `/pdfviewer`,
    //     state: url,
    //   });
    };


    // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    // Access the URL correctly
    //  pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    const url = props.location.state?.[0] || ''; 

    if (!url) {
        return <div>Error: No PDF URL provided</div>;
    }


    console.log(url,"pdfUrl")
    return (
        // <div className='width_pdf'>
        //     {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>  */}
        //     {/* <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>  */}
        //      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js`}>
           
        //         <Viewer
        //             fileUrl={url}
        //             defaultScale={SpecialZoomLevel.PageFit}
        //         />
        //     </Worker>
         
        // </div>
        <div>
        <div onClick={handleclick}>
          <Document
            file={`${url}`}
            loading="Please wait!"
            renderMode="canvas"
            // options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={onpreload}
            // className={`${stateview ? "pdf_post" : "pdf_route"}`}
            // className="pdf_post"
        
          >
            <Page
              // width={300}
              // height={300}
              pageNumber={pageNumber}
              renderAnnotationLayer={true}
              renderTextLayer={false}
              renderForms={true}
            />
          </Document>
        </div>
        <div className="bg_transperency">
          <div>
            <Button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className={`${pageNumber <= 1 ? "buttondisable" : "buttonc"}`}
            >
              {`${previous}`}
            </Button>
          </div>
          <div className="pagec">
            {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
          </div>
          <div>
            <Button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className={`${
                pageNumber >= numPages ? "buttondisable" : "buttonc"
              }`}
            >
              {`${next}`}
            </Button>
          </div>
        </div>
      </div>

    );
}

export default PdfViewer;





// export default PdfViewer