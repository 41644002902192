import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './signup.css';
import Dialog from "@mui/material/Dialog";
import QRCODE from '../../images/qrcode.svg';
import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Divider } from '@mui/material';
import rotate from '../../images/rotate.svg';
import downloadapp from '../../images/download-icon-in-white.png'



const CustomButton = (props) => {
  return (
    <Button className="add-option" id='share_social' variant="outlined" onClick={() => navigator.clipboard.writeText('https://www.docsteth.com')}>{props.children}&nbsp;{props.name}</Button>
  )
}

const CustomRow = (props) => {
  return (
    <Typography component="div" sx={{ px: 2, pt: 2 }} >
      <Typography component="p" sx={{ pb: 1 }} className="e-align d-flex">
        {props.children}
      </Typography>
      {props?.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
    </Typography>
  )
}

function Downloadapp() {

  const [open, setOpen] = React.useState(false);
  const [dialogopen, setDialogopen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledialogopen = () => {
    setDialogopen(true);
  }

  const handledialogclose = () => {
    setDialogopen(false);
  }

  const HandleCopy = () => {
    navigator?.clipboard?.writeText(`https://www.docsteth.com/`)
  }
  return (
    <>
      <Button className=' download_app' onClick={handleClickOpen}><img src={downloadapp} width="15px" height="20px" ></img><span className='download_text'>Download App</span></Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='down_app_text'>
          <h3>Download the Docsteth App</h3>
          <p className='now_avalaible'>Now availiable for Android and Ios</p>
          <p>Scan QR code</p>
          <div style={{ textAlign: "center" }}>
            <img src={QRCODE} width="80px" height="80px" ></img>
          </div>
          <h5 >Or visit <span style={{ fontSize: "12px", textAlign: "center" }}><a href='http://onelink.to/ebcha8' target='_blank' style={{ color: "blue" }}>http://onelink.to/ebcha8</a></span></h5>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBlockEnd: "20px" }}>
          <Button onClick={handledialogopen} style={{ textTransform: "capitalize", display: "flex", alignItems: "center", border: "1px solid grey" }}><img src={rotate} height="20px" width="20px" style={{ marginRight: "10px" }}></img> Share Invite</Button>
          <Dialog
            open={dialogopen}
            onClose={handledialogclose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Box>
              <Typography sx={{ p: '15px', backgroundColor: '#007dcd', color: 'white', textAlign: "center" }}>
                Invite Your Peers
              </Typography>
              <CustomRow>
                <WhatsappShareButton
                  windowWidth='800px'
                  windowHeight='800px'
                  url={`https://www.docsteth.com/`}
                  title={"Docsteth"}
                  onShareWindowClose={() => handleClose}
                >
                  <CustomButton name="DM Invitation" >
                    <WhatsAppIcon className='inside_icon' sx={{ color: 'black' }} />
                  </CustomButton>
                </WhatsappShareButton>

                <TwitterShareButton
                  windowWidth='800px'
                  windowHeight='800px'
                  url={`https://www.docsteth.com/`}
                  title={"Docsteth"}
                  via='Dr Akhil Kolli'
                >
                  <CustomButton name="Tweet Invite">
                    <TwitterIcon className='inside_icon' sx={{ color: 'black' }} />
                  </CustomButton>
                </TwitterShareButton>
              </CustomRow>

              <CustomRow>
                <LinkedinShareButton
                  url={`https://www.docsteth.com/`}
                  title={"Docsteth"}
                  summary={"Global Practitioners Consortium"}
                >
                  <CustomButton name="Post in Linkedin" >
                    <LinkedInIcon className='inside_icon' sx={{ color: 'black' }} />
                  </CustomButton>
                </LinkedinShareButton>
                <FacebookShareButton
                  windowWidth='800px'
                  windowHeight='800px'
                  url={`https://www.docsteth.com/`}
                  quote={"Docsteth"}
                  hashtag='#docsteth'
                >
                  <CustomButton name="post in Facebook">
                    <FacebookIcon className='inside_icon' sx={{ color: 'black' }}></FacebookIcon>
                  </CustomButton>
                </FacebookShareButton>
              </CustomRow>

              <CustomRow true={true}>
                <EmailShareButton
                  url={`https://www.docsteth.com/`}
                  separator={"    =>  "}
                  subject={"Docsteth"}
                  body={"This is an invitation for Docsteth"}
                >
                  <CustomButton name="Email Invite">
                    <EmailIcon className='inside_icon' sx={{ color: 'black' }} />
                  </CustomButton>
                </EmailShareButton>
                <div onClick={HandleCopy}>
                  <CustomButton name="Copy Invite" >
                    <ContentCopyIcon className='inside_icon' sx={{ color: 'black' }} />
                  </CustomButton>
                </div>
              </CustomRow>

              <Typography onClick={handledialogclose} className="textCenter font-bold cursor" sx={{ mt: 1, p: '10px', backgroundColor: '#e4eef5', color: '#0f3f5d' }}>
                Done
              </Typography>
            </Box>
          </Dialog>
        </div>
      </Dialog>
    </>
  )
}

export default Downloadapp