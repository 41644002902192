import React from 'react'
import { useHistory } from 'react-router-dom'

const Protected = (props) => {
    const history = useHistory()
    const isValid = sessionStorage.getItem("isConfirm")

    if (!isValid) {
        return history.goBack()
    }
    return (
        // <div>{isValid ? <props.component /> : history.goBack()}</div>
        <div>{<props.component />}</div>
    )
}

export default Protected




// const Protected = ({ component: Children }) => {
//     const isValid = sessionStorage.getItem("isConfirm")
//     console.log(isValid, "isvalid")
//     return (
//         <div><Children /></div>
//     )
// }

// export default Protected