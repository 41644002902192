import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAppsByUserId } from '../../../../redux/Actions/connectActions'
import { AppsView } from '../AppVault/appview'

const MyApps = () => {
    const appsbyuserId = useSelector(state => state.connect.appsbyuserId)
    const dispatch = useDispatch()
    console.log(appsbyuserId, 'apppby userid ')

    useEffect(() => {
        if (appsbyuserId.length == 0) {
            dispatch(getAppsByUserId())
        }
    }, [])

    return (
        <div style={{ height: "80vh", overflowY: "auto", padding: "10px" }}>

            <AppsView apps={appsbyuserId[0]?.myApps} />

        </div>
    )
}

export default MyApps