import React, { useState, useEffect } from 'react'
import { Box, Button, Dialog, DialogContent, FormHelperText, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, FormLayout } from '../../../../common/components/layout';
import { ValidatorForm } from 'react-form-validator-core';
import { C_API, D_API } from '../../../../../services/APIService';
import axios from 'axios';
import CustomSelect from '../../../../common/inputs/customSelect';
import { FormDivider } from '../../../../utility/utils';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { getUserOrg } from '../../../../../redux/Actions/regActions';
import { Close, Search } from '@mui/icons-material';

const header2 = ['Facility Name', 'OwnerShip', 'Facility ID', 'Verify OwnerShip']


export const FacilityContact = () => {
    const [facilityId, setFacilityId] = useState('')
    const [open, setOpen] = useState(false)
    const [openHFR, setOpenHFR] = useState(false)
    const [state, setState] = useState(1)
    const [location, setLocation] = useState({ state: "", district: "", facilityName: "", subDistrict: '', pincode: '', count: '5', ownerShip: '' })
    const [otp, setOtp] = useState('')
    const [ownerShipCodes, setOwnerShipCodes] = useState([])
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [subDistricts, setSubDistricts] = useState([])
    const [facilities, setFacilities] = useState([])
    const [facility, setFacility] = useState({})
    const dispatch = useDispatch()
    // const orgData = useSelector((state) => state.registration.userOrgData);
    const org = useSelector(state => state.registration.userOrg)
    const userData = useSelector(state => state.userProfile.userProfile)
    const userOrg = useSelector(state => state.userProfile.userOrg)
    const userOrgData = useSelector(state => state.userProfile.userOrgData)
    const orgResource = useSelector(state => state.userProfile.orgResource)


    console.log(org, "userOrg", "orgDatassss", userData)
    useEffect(() => {
        // if (open && state === 1) {
        C_API.get(`ownershipCode/OWNER`).then(res => setOwnerShipCodes(res.data.data))
        C_API.get(`stateLgdCodes`).then(res => {
            setStates(res.data)
            handleLocation('district', '')
        })
        // }
    }, [open])
    useEffect(() => {
        if (location?.state) {
            C_API.get(`districLgdCodes/${location?.state?.code}`).then(res => {
                setDistricts(res.data)
            })
        }
        handleLocation('district', '')
    }, [location?.state])

    useEffect(() => {
        if (location?.district) {
            C_API.get(`subdistricLgdCodes/${location?.district?.code}`).then(res => {
                setSubDistricts(res.data)
            })
        }
        handleLocation('subDistrict', '')
    }, [location?.district])

    useEffect(() => {
        handleLocation('pincode', '')
    }, [location?.subDistrict])



    const validateFacility = (facilityId) => () => {

        axios.post(process.env.REACT_APP_CLINIDESK + 'ValidateFacility', {
            facilityId: facilityId
        },
            {
                headers: {
                    gatewayToken: "clinidesk"
                }
            }).then(res => {
                if (res.data?.facility) {
                    setOpen(true)
                    setState(3)
                    setFacility({ ...res.data?.addressInfo?.facilities?.[0], ...res?.data?.facility })
                }
                else {
                    alert('Enter valid Facility Id')
                }
            })
    }

    const searchFacility = () => {
        C_API.post('search/hfrName', {
            ownershipCode: location?.ownerShip?.code,
            stateLGDCode: location?.state?.code,
            districtLGDCode: location?.district?.code,
            subDistrictLGDCode: location?.subDistrict?.code,
            pincode: location?.state?.pincode,
            facilityName: location?.facilityName,
            facilityId: "",
            page: 1,
            resultsPerPage: location?.state?.count
        }).then(res => {
            setFacilities(res.data?.facilities)
            setState(2)
        })
    }
    const verifyPassword = () => {
        // console.log(facility, otp)
        C_API.post('hpr/loginViaPassword', {
            hprId: facility.hprid,
            password: otp
        }).then(res => {
            // console.log(res)
            if (res.data?.token) {
                setState(4)
                handleDialog()
                // console.log(org)
                let data = {
                    ...org, identifier: [...org.identifier, {
                        system: 'https://hprId/',
                        value: facility.hprid
                    },
                    {
                        system: 'https://hfrId/',
                        value: facility.facilityId
                    }
                    ]
                }
                let serviceData =
                {
                    "id": facility.facilityId,
                    "name": facility.facilityName,
                    "type": "HIP",
                    "active": true,
                    "alias": [org.name]
                }

                // console.log(data)
                C_API.put('addUpdateBridgeServices', serviceData).then(() => {
                    D_API.put(`${org?.id}/Organization/${org?.id}`, data).then(() => {
                        dispatch(getUserOrg(data))
                        window.location.reload();
                    }).catch(() => alert('Internal Server Error. Could not link your HFR ID.Please try again later.'))
                }).catch(() => alert('Couldn\'t update'))
            }
        }).catch(() => alert('Incorrect Password'))
    }

    const handleLocation = (name, value) => {
        setLocation({ ...location, [name]: value })
    }
    const handleDialog = () => {
        setOpen(prev => !prev)
        setLocation({ state: "", district: "", facilityName: "", subDistrict: '', pincode: '', count: '5', ownerShip: '' });
        setFacilities([]);
        setState(1);
    }
    const handleClickHFR = () => {
        console.log("handleHFR", openHFR)
        setOpenHFR(prev => !prev)
    }
    return (
        <Box>

            <Dialog open={open} id="register" onClose={handleDialog}
                sx={{ height: "600px", position: 'absolute', top: "-40px" }}
                maxWidth="md" fullWidth>
                <DialogContent>
                    <div
                        // style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}
                        style={{ float: 'right', cursor: 'pointer' }}
                    >
                        <Close
                            onClick={handleDialog}
                            className="cursPoint"
                            edge="end"
                        />
                    </div>
                    {/* <Close onClick={handleDialog} className="cursPoint" sx={{ position: 'absolute', right: 0, top: 0 }} edge="end" /> */}
                    {state === 1 || state === 2 ? <ValidatorForm onSubmit={searchFacility}>
                        <p className="fntBold">Search Hospital Facility</p>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <FormLayout size={8} name="facilityName" value={location.facilityName} handleChange={handleLocation} placeholder="Search with Hospital Name (Facility Name)" required errorMessages={['Enter Facility Name']} Icon={<Search />} />
                            <Grid item xs={12} className="flx-center">
                                <FilterListIcon />&nbsp;<span>Filter Results</span>
                            </Grid>
                            <FormLayout type="select" size={4} name="state" options={states} getOptionLabel={option => option.name?.toLowerCase() || ''} value={location.state} handleChange={handleLocation} placeholder="Select State" required errorMessages={['Select State']} />
                            <FormLayout type="select" size={4} name="ownerShip" options={ownerShipCodes} value={location.ownerShip} handleChange={handleLocation} placeholder="Owner Ship" required errorMessages={['Select OwnerShip code']} />
                            <Grid item md={4} className="flx-center">
                                <span style={{ flex: 1 }}>Results per page </span>
                                <div>
                                    <CustomSelect name="count" placeholder="10" options={[5, 10, 15, 20]} value={location.count} handleChange={handleLocation} />
                                </div>
                            </Grid>
                            <FormLayout type="select" size={4} name="district" options={districts} getOptionLabel={option => option.name?.toLowerCase() || ''} value={location.district} handleChange={handleLocation} placeholder="Select District" />
                            <FormLayout type="select" size={4} name="subDistrict" options={subDistricts} getOptionLabel={option => option.name?.toLowerCase() || ''} value={location.subDistrict} handleChange={handleLocation} placeholder="Select Sub District" />
                            <FormLayout size={4} name="pincode" value={location.pincode} handleChange={handleLocation} placeholder="Area Pincode" />
                            <Grid item className="flx justify-flexEnd" xs={12}>
                                <Button variant="contained" className="capitalize" type="submit" sx={{ padding: '3px 10px', borderRadius: '15px', backgroundColor: '#5757EA', color: 'white' }}><Search /> Search</Button>
                            </Grid>
                        </Grid>
                        <FormDivider />
                        {state === 2 &&

                            <>
                                {
                                    facilities?.length > 0 &&
                                    <>
                                        <h3>Search Results</h3>
                                        <Paper>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {header2?.map(val => <TableCell sx={{ fontWeight: 'bold' }} align="center">{val}</TableCell>)}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {facilities?.map(facility => (
                                                        <TableRow>
                                                            <TableCell>{facility?.facilityName}</TableCell>
                                                            <TableCell>{facility?.ownership}</TableCell>
                                                            <TableCell>{facility?.facilityId}</TableCell>
                                                            <TableCell align="center">
                                                                <Button variant="outlined" className="capitalize" onClick={validateFacility(facility.facilityId, facility)} sx={{ padding: '3px 10px', borderRadius: '15px', borderColor: '#5757EA', color: '#5757EA!important' }}>Validate</Button>
                                                            </TableCell>
                                                        </TableRow>))}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </>
                                }

                            </>
                        }
                    </ValidatorForm>
                        : state === 3 ?
                            <ValidatorForm onSubmit={() => { }}>
                                <h3 className="mt0">Verify Facility Ownership</h3>
                                <FormDivider />
                                <Grid container columnSpacing={4} rowSpacing={2} sx={{ fontSize: '15px' }}>
                                    <Grid item sm={6} xs={12}>
                                        <p><span className="fntBold">HFR ID:</span> {facility?.facilityId}</p>
                                        <p><span className="fntBold">Facility Name:</span> {facility?.facilityName}</p>
                                        <p className="fntBold">Address: </p>
                                        <p>{facility?.address}</p>
                                        <p>{facility?.villageCityTownName !== "NA" && facility?.villageCityTownName + ","} {facility?.districtName}, {facility?.subDistrictName}</p>
                                        <p>{facility?.stateName}, {facility?.pincode}</p>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <p>
                                            <span className="fntBold">HPR ID:</span> {facility.hprid}</p>
                                        <FormLayout type="select" size={12} options={['Verify with password']} value={'Verify with password'} />
                                        <br />
                                        <FormLayout size={12} name="password" type="password" value={otp} handleChange={(name, value) => setOtp(value)} placeholder="Enter password" />
                                    </Grid>
                                    <Grid item xs={12} className="tar">
                                        <Button variant="contained" className="capitalize" onClick={verifyPassword} sx={{ padding: '3px 25px', borderRadius: '15px', backgroundColor: '#5757EA', color: 'white', float: 'right' }}>Verify</Button>
                                    </Grid>
                                </Grid>

                            </ValidatorForm> : ''}
                </DialogContent>
            </Dialog>

            {state === 4 ? <>
                <h5 className="flx-center"><VerifiedUserIcon style={{ color: '#65A300' }} />&nbsp;Verified with ABDM-HFR</h5>
                <p><span className="fntBold">HPR ID:</span> {facility?.hprid}</p>
                <p><span className="fntBold">Facility Name:</span> {facility?.facilityName}</p>
                <p className="fntBold">Address: </p>
                <p>{facility?.address}</p>
                <p>{facility?.villageCityTownName !== "NA" && facility?.villageCityTownName + ","} {facility?.districtName}, {facility?.subDistrictName}</p>
                <p>{facility?.stateName}, {facility?.pincode}</p>
            </> :
                <ValidatorForm
                    onSubmit={validateFacility(facilityId)}
                >
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item md={8} xs={12}>
                            <Grid container className="align-flexEnd">
                                <FormLayout size={12} placeholder="Enter Facility ID" required errorMessages={['Enter Facility Id']} Icon={<Search />} label="Validate Existing HFR" value={facilityId} name="facilityId" handleChange={(name, value) => setFacilityId(value)} />
                                <Grid item xs={12} >
                                    <Button sx={{ color: "#0A0AAF" }} className="capitalize" onClick={handleDialog} >Forgot Facility ID</Button>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <div style={{ marginTop: '25px' }}>
                                <label className="d-block" >&nbsp;</label>
                                <CustomButton className="clr-btn" name="Submit" type="submit"
                                />
                                <FormHelperText>&nbsp;</FormHelperText>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <label >Register New Health Facility - Ayushman Bharat</label>
                            <div className="mt1" style={{ textAlign: 'right' }} onClick={handleClickHFR}><CustomButton disabled name="Create New" /></div>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            }
        </Box>

    )
}
