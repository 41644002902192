import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import Slider from "infinite-react-carousel";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Dialog, DialogContent, DialogContentText, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import UserService from "../../../../../../services/UserService";
import '../../../../../../Components/Molecule/Feed/Feed.css'
import { makeStyles } from "@mui/styles";

export default function PictureGrid({ ImgContent, ...props }) {
  const useStyles = makeStyles((theme) => ({
    title: {
      color: "black",
      marginRight: 10,
      fontWeight: 600,
      fontSize: 25,
    },
    imgContainer: {

    }
  }));
  const classes = useStyles();
  const [showImage, setShowImage] = useState(null);
  const [showGallaryView, setShowGallaryView] = useState(false);
  const [img, setImg] = useState([])
  const [index, setIndex] = useState(0)
  const matches = useMediaQuery('(max-width:769px)');
  const history = useHistory()
  const [alert, setAlert] = useState({ open: false, msg: '' })
  const [delalertPhoto, setDelalertPhoto] = useState(false)
  const [toBeDeleteID, setTobeDeleteId] = useState("")
  const userOrg = useSelector(state => state.registration.userOrg)
  const AllImages = props?.url

  const handleDel = (id) => {
    setDelalertPhoto(true)
    setTobeDeleteId(id)
  }

  const handleDelImage = (indextobedel) => {
    axios.delete(`https://dev.api.docsteth.com/api/v1/deleteImage/${userOrg.id}/${UserService.getUserId()}`, { data: [AllImages[indextobedel]] })
      .then(res => {
        const imglist = img.filter((val, i) => i != indextobedel)
        setAlert({ open: true, msg: 'Photo deleted successfully' })
        setDelalertPhoto(false)
        if (imglist.length > 0) {
          setImg(imglist)
        } else {
          setImg([])
        }
        if (indextobedel === 0) {
          goToNextSlide()
        } else {
          goToPrevSlide()
        }
        props.getImagePost()
      }).catch(err => {
        console.log(err)
      })
  }


  const goToPrevSlide = () => {
    const newPointer = index === 0 ? img.length - 1 : index - 1;
    setIndex(newPointer)
  }

  const goToNextSlide = () => {
    const newPointer = index === img.length - 1 ? 0 : index + 1;
    setIndex(newPointer)
  }

  var displayGallaryImage = (images) => {
    if (images) {
      if (images.length === 1) {
        return (
          <div
            style={{
              backgroundImage: `url(${images[0]}`,
              backgroundSize: "cover",
              width: "100%",
              height: 300,
              marginLeft: 20,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              justifyContent: 'center'
            }}
          ></div>
        );
      } else {
        return (
          <Slider dots arrows={true}>
            {images.map((image, index) => {
              if (isAVideo(image)) {
                return (
                  <Grid item md={12} lg={12} xl={12} xs={12} sm={12} key={index}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <video width="100%" height="auto" src={image} controls />
                    </Grid>
                  </Grid>
                );
              }
              return (
                <Grid item md={12} lg={12} xl={12} xs={12} sm={12}>
                  <IconButton style={{ marginLeft: '20px' }} onClick={() => { setShowGallaryView(false); }} >
                    <CloseIcon></CloseIcon>
                  </IconButton>
                  <div direction="row"
                    style={{
                      backgroundImage: `url(${image}`,
                      backgroundSize: "contain",
                      width: "100%",
                      height: 300,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      textAlign: "left",
                      justifyContent: "center"
                    }}
                    onDoubleClick={() => {
                      setShowImage(image);
                    }}
                  >
                  </div>
                </Grid>
              );
            })}
          </Slider>
        );
      }
    }
  };

  const isAVideo = (path) => {
    if (props.children === null) {
      if (path.includes(".mp4") || path.includes(".mov")) {
        return true;
      }
    }

    return false;
  };

  const hasAVideo = (paths) => {
    var hasVideo = false;
    if (props?.children === null) {
      //   paths.map((path) => {
      //     if (path.includes(".mp4") || path.includes(".mov")) {
      //       console.log("checking videos", path);
      //       hasVideo = true;
      //     }
      //   });
    }

    return hasVideo;
  };

  const displayImage = (images, ImgContent) => {
    if (images) {
      if (images?.length === 1) {
        if (isAVideo(images[0])) {
          return (
            <Grid container direction="row" justifyContent="center">
              <video width="95%" height="400" src={images[0]} controls />
            </Grid>
          );
        } else {
          return (
            <div>
              {ImgContent && <ImgContent handledelete={() => props.handledelete(index)} />}
              <img src={images[0]} alt="Post" onClick={() => setImg(images)} style={{
                objectFit: 'cover',
                // maxWidth: '100%',
                // maxHeight: '20%',
                width: '100%',
                height: 'auto'
              }} />
            </div>
          );
        }
      } else if (images?.length === 2) {
        return (
          <Grid container spacing={1} sx={{ maxHeight: '200px!important', overflow: 'hidden' }}>
            {images.map((image, index) => {
              return (
                <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={index}
                >
                  {ImgContent && <ImgContent handledelete={() => props.handledelete(index)} />}
                  <div onClick={() => { setImg(images); setIndex(index) }} style={{ height: '100%' }}>
                    <img src={image} alt="Post" style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%'
                    }} />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        );

      } else if (images?.length === 3) {
        return (
          <Grid container spacing={1} style={{ height: '300px' }}>
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6}
            >
              {ImgContent && <ImgContent handledelete={() => props.handledelete(index)} />}
              <div style={{ width: "100%", height: "100%" }} onClick={() => { setImg(images); setIndex(0) }}>
                <img src={images[0]} alt="Post"
                  style={{
                    objectFit: 'cover',
                    borderRadius: 5,
                    width: '100%',
                    height: '100%'
                  }} />
              </div>
            </Grid>{" "}
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6} style={{ height: '100%' }}>
              {images?.map((image, index) => {
                if (index != 0) {
                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      justifyContent="center"
                      style={{ marginBottom: index !== 2 ? 5 : '', height: '50%' }}
                    >
                      {ImgContent && <div className="margin_clear">
                        <ImgContent handledelete={() => props.handledelete(index)} /></div>}
                      <div style={{ width: "100%", height: "100%" }} onClick={() => { setImg(images); setIndex(index) }}>
                        <img src={image} alt="Post"
                          style={{
                            objectFit: 'cover',
                            borderRadius: 5,
                            width: '100%',
                            height: '100%'
                          }} />
                      </div>

                    </Grid>
                  );

                }
              })}
            </Grid>
          </Grid>
        );
      } else if (images?.length === 4) {
        return (
          <Grid container spacing={1} style={{ height: '500px' }} >
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6}
              style={{ height: '100%' }}>
              {ImgContent && <ImgContent handledelete={() => props.handledelete(index)} />}
              <div style={{ width: "100%", height: "100%" }} onClick={() => { setImg(images); setIndex(0) }}>
                <img src={images[0]} alt="Post" style={{
                  objectFit: 'cover',
                  borderRadius: 5,
                  width: '100%',
                  height: '100%'
                }} />
              </div>
            </Grid>{" "}
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6} style={{ height: '100%', padding: 0 }}>
              {images?.map((image, index) => {
                if (index != 0) {
                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      justifyContent="center"
                      style={{
                        height: '33.3333%',
                        padding: 3,
                      }}
                    >
                      {ImgContent && <div className="margin_clear">
                        <ImgContent handledelete={() => props.handledelete(index)} /></div>}
                      <div style={{ width: "100%", height: "100%" }} onClick={() => { setImg(images); setIndex(index) }}>
                        <img src={image} alt="Post" onDoubleClick={() => {
                          setShowImage(image);
                        }} style={{
                          objectFit: 'cover',
                          borderRadius: 5,
                          width: '100%',
                          height: '100%'
                        }} />
                      </div>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container spacing={1}>
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6}>
              <Grid container direction="row" justifyContent="center"
                style={{
                  backgroundImage: `url(${images[0]}`,
                  // width: 250,
                  height: 400,
                  borderRadius: 5,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                onClick={() => { setImg(images); setIndex(0) }}
                onDoubleClick={() => {
                  setShowImage(images[0]);
                }}
              ></Grid>
            </Grid>{" "}
            <Grid item md={6} lg={6} xl={6} xs={6} sm={6}>
              <Grid container spacing={1}>
                {images?.map((image, index) => {
                  if (index != 0) {
                    if (index > 3 && images?.length > 5) {
                      if (index === 4) {
                        return (
                          <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={index}>
                            <Grid container direction="row" justifyContent="center" alignItems="center"
                              style={{
                                backgroundImage: `url(${image}`,
                                background: "gray",
                                width: "auto",
                                height: 195,
                                borderRadius: 5,
                                cursor: "pointer",
                                // // marginBottom: 5,
                                // backgroundSize: "cover",
                                // backgroundRepeat: "no-repeat",
                                // backgroundPosition: "center",
                              }}
                              onClick={() => { setShowGallaryView(true); }}
                            >
                              <Typography style={{ color: "white" }} className={classes.title}>
                                +{images.length - (index + 1)}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      }
                    } else {
                      if (hasAVideo(images) && images?.length <= 5) {
                        if (index > 2) {
                          if (isAVideo(image)) {
                            return (
                              <Grid key={index} item md={12} lg={12} xl={12} xs={12} sm={12}>
                                <Grid container direction="row" justifyContent="center">
                                  <video width="100%" height="130" src={image} controls />
                                </Grid>
                              </Grid>
                            );
                          }
                          return (
                            <Grid item md={12} lg={12} xl={12} xs={12} sm={12} key={index}>
                              <Grid container direction="row" justifyContent="center"
                                style={{
                                  backgroundImage: `url(${image}`,
                                  width: "auto",
                                  height: 105,
                                  borderRadius: 5,
                                  // marginBottom: 5,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}

                                onClick={() => { setImg(images); setIndex(index) }}
                              ></Grid>
                            </Grid>
                          );
                        }
                        return (
                          <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={index} onClick={() => { setImg(images); setIndex(index) }}>
                            <Grid container direction="row" justifyContent="center"
                              style={{
                                backgroundImage: `url(${image}`,
                                width: "auto",
                                height: 155,
                                borderRadius: 5,
                                // marginBottom: 5,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}

                            ></Grid>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item md={6} lg={6} xl={6} xs={6} sm={6} key={index} onClick={() => { setImg(images); setIndex(index) }}>
                            <Grid container direction="row" justifyContent="center"
                              style={{
                                backgroundImage: `url(${image}`,
                                width: "auto",
                                height: 195,
                                borderRadius: 5,
                                // marginBottom: 5,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}
                            ></Grid>
                          </Grid>
                        );
                      }
                    }
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }
  };
  return (
    <>
      <div style={{ width: props.width, maxWidth: props.maxWidth }} className="cursor">
        {showGallaryView === false
          ? displayImage(props.images || props.children) != null && (
            <div style={{ maxHeight: 'auto', overflow: 'hidden' }}>
              {displayImage(props.images || props.children, ImgContent)}</div>
          )
          : displayGallaryImage(props.images || props.children) != null && (
            <div>{displayGallaryImage(props.images || props.children)}</div>
          )
        }
        {!matches ?
          img?.length > 0 && (
            <Dialog
              scroll={"body"}
              open={img?.length > 0 ? true : false}
              onClose={() => {
                setImg([]);
              }}
              maxWidth={'lg'}
              fullWidth
            >
              <div className="post_view_Desktop">
                <Grid container direction="row" style={{ height: '600px' }}>
                  <Grid item xs={12} style={{ height: '100%', position: 'relative', backgroundColor: '#1d2226' }} className="elib-right__content">
                    {img?.length === 1 ?
                      <div>
                        <TransformWrapper>
                          {({ zoomIn, zoomOut, ...rest }) => (
                            <>
                              <div className="tools" style={{ position: 'absolute', left: "20px", top: "20px", display: "flex", gap: "20px", cursor: "pointer", zIndex: 1 }}>
                                <div onClick={() => zoomIn()} style={{ background: "rgba(255, 255, 255, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                                  <AddIcon />
                                </div>
                                <div onClick={() => zoomOut()} style={{ background: "rgba(255, 255, 255, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                                  <RemoveIcon />
                                </div>
                              </div>
                              <TransformComponent>
                                <img alt="Post" style={{ margin: "auto", maxWidth: '100%', maxHeight: '100%' }} src={img[0]} />
                              </TransformComponent>
                            </>
                          )}
                        </TransformWrapper>
                        {props.photoDelAdmin &&
                          <div onClick={() => handleDel(index)} style={{ position: 'absolute', right: "20px", top: "20px", background: "rgba(255, 255, 255, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                            <DeleteIcon />
                          </div>
                        }
                      </div>
                      :
                      <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <TransformWrapper>
                          {({ zoomIn, zoomOut, ...rest }) => (
                            <>
                              <div className="tools" style={{ position: 'absolute', left: "20px", top: "20px", display: "flex", gap: "20px", cursor: "pointer", zIndex: 1 }}>
                                <div onClick={() => zoomIn()} style={{ background: "rgba(255, 255, 255, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                                  <AddIcon />
                                </div>
                                <div onClick={() => zoomOut()} style={{ background: "rgba(255, 255, 255, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                                  <RemoveIcon />
                                </div>
                              </div>
                              <TransformComponent>
                                <img alt="Post" style={{ margin: "auto", maxWidth: '100%', maxHeight: '100%', backgroundColor: 'white' }} src={img[index]} />
                              </TransformComponent>
                            </>
                          )}
                        </TransformWrapper>
                        {/* <img alt="Post" style={{ margin: "auto", position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, maxWidth: '100%', maxHeight: '100%', backgroundColor: 'white' }} src={img[index]}></img> */}
                        <ArrowBackIosIcon onClick={() => goToPrevSlide()} style={{ position: 'absolute', top: '50%', left: 10, color: 'white', fontSize: '35px' }} />
                        <ArrowForwardIosIcon onClick={() => goToNextSlide()} style={{ position: 'absolute', top: '50%', right: 0, color: 'white', fontSize: '35px' }} />
                        {props.photoDelAdmin &&
                          <div onClick={() => handleDel(index)} style={{ position: 'absolute', right: "20px", top: "20px", background: "rgba(255, 255, 255, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "7px", cursor: "pointer" }}>
                            <DeleteIcon />
                          </div>
                        }
                      </div>
                    }
                  </Grid>
                </Grid>
              </div>
              <Dialog open={delalertPhoto} onClose={() => setDelalertPhoto(false)}>
                <DialogContent>
                  <DialogContentText>Do you want to delete ?</DialogContentText>
                  <div style={{ display: "flex", justifyContent: "end", gap: "10px", marginTop: "10px" }}>
                    <Button size='small' variant='outlined' sx={{ textTransform: "capitalize", height: "30px" }} onClick={() => setDelalertPhoto(false)}>Cancel</Button>
                    <Button size='small' variant='outlined' sx={{ textTransform: "capitalize", height: "30px" }} onClick={() => handleDelImage(toBeDeleteID)}>Yes</Button>
                  </div>
                </DialogContent>
              </Dialog>
            </Dialog>
          )
          : img?.length > 0 && history.push({
            // pathname: `/admin/${props.post.id}`,
            pathname: `/admin/organization/post/${props.id}`,
            // state: { data: props.post }
          })}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        open={alert.open}
        onClose={() => setAlert({ open: false, msg: '' })}
        message={alert.msg}
      />
    </>
  );
}