import React, { useEffect, useState } from 'react'
import './article.css'
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromHTML, Modifier, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CssTextField from '../Fields/CssTextField';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AddIcon from "../../images/add.svg";
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import TextField from '@mui/material/TextField';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';
import URL from '../apiurl'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { Dialog, DialogContent, DialogTitle, List, ListItem, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { headers } from '../../redux/Actions/connectActions';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LOETabs from './LOE';
import { Close } from '@mui/icons-material';

// const styleMap = {
//     'color': {  // STRIKETHROUGH is the one which should be applied to inlineStyle
//       color: 'blue',
//     },
//   };

export const CssText = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '12px',
            // height: '54px',
            boxShadow: '0px 3px 4px #756d6d29',
            color: 'grey',
            // width: '40%',
        },
    }
});

export const AddSub = (props) => {
    return (
        <ValidatorForm onSubmit={(e) => props?.handleSubtitle(e, props?.name)} onError={() => { }} style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '15px' }}>
            <div style={{ width: '70%', display: 'inline-flex', justifyContent: '' }}>
                <CssTextField style={{ width: '100%' }} variant="outlined"
                    label="Subtitle Name" type="text" value={props?.sub?.child_subtitle}
                    onChange={(e) => props?.handleAdd(props?.name, e.target.value, props?.i)}
                    validators={['required']}
                    errorMessages={['Enter subtitle']}
                />
            </div>
            <Button type="submit">Save</Button>
        </ValidatorForm>
    )
}

const CustomOption = (props) => {

    const { onChange, editorState } = props
    const [loe, setLoe] = useState(null)
    const [expand, setExpand] = useState(null)

    const handleExpand = () => {
        setExpand(prev => !prev)
    }

    const addLOE = (val) => {
        setLoe(val)
        handleExpand()
    }

    useEffect(() => {
        (async () => {
            if (loe) {
                const html = `<b><a href="/loe">[LOE-${loe}]</a> </b>`
                const blocksFromHTML = htmlToDraft(html)
                const content = ContentState.createFromBlockArray(blocksFromHTML)

                const contentState = Modifier.replaceWithFragment(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    content.getBlockMap(),
                    // 'color',
                );
                let state = EditorState.push(editorState, contentState, 'insert-fragment')
                let estate = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(stateToHTML(state.getCurrentContent()))))
                await onChange(estate)
                setLoe(0)
            }
            else if (loe === 0) {
                EditorState.moveSelectionToEnd(editorState);
                onChange(EditorState.forceSelection(editorState, editorState.getSelection()));
            }
        })()
    }, [loe])

    return (
        <>
            <Dialog maxWidth="md" open={expand}>
                <DialogTitle className='space_btw_center'>Levels of Evidence<Close className="cursor" onClick={handleExpand} /></DialogTitle>
                <DialogContent sx={{ px: 0, py: 1 }}>
                    <LOETabs addLOE={addLOE} />
                </DialogContent>
            </Dialog>
            <div onClick={handleExpand} className="rdw-remove-wrapper" aria-label="rdw-remove-control">
                <div className="rdw-option-wrapper" title="LOE">
                    <div>LOE</div>
                </div>
            </div>
        </>
    );
}

export const Accord = (props) => {
    const [edit, setEdit] = useState(0)

    return (
        <>
            <Accordion expanded={props?.expanded === props?.name} onChange={props?.handleChange(props?.name)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }} className="capitalize">
                        {props?.name?.replace(/_/g, ' ')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0 }}>
                    <Typography component="div">

                        {props.array.sort((a, b) => a.id > b.id ? 1 : -1).map((title, i) => {
                            var editorContent;
                            if (props?.subexpanded === `${props?.panel}-${title?.child_subtitle?.replace(/\s+/g, '-')?.toLowerCase()}`) {
                                if (typeof (title?.body) === 'string') {
                                    // const html = title.body
                                    // const blocksFromHTML = htmlToDraft(html)
                                    // const content = ContentState.createFromBlockArray(blocksFromHTML)
                                    // editorContent = EditorState.createWithContent(content)
                                    editorContent = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(title?.body)))
                                }
                                else {
                                    editorContent = title?.body
                                }
                            }

                            return (
                                <Box className="fabdetail d-flex-start" sx={{ mb: 2 }} key={i}>
                                    <div>
                                        <Accordion expanded={props?.subexpanded === `${props?.panel}-${title?.child_subtitle?.replace(/\s+/g, '-')?.toLowerCase()}`} onChange={props?.handleSub(`${props?.panel}-${title?.child_subtitle?.replace(/\s+/g, '-')?.toLowerCase()}`)}>
                                            <AccordionSummary
                                                expandIcon={<KeyboardArrowRightIcon />}
                                                aria-controls="panel9bh-content"
                                                id="panel9bh-header"
                                                style={{ flexDirection: 'row-reverse' }}
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                    {title?.child_subtitle}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div>
                                                    <Editor
                                                        editorState={editorContent}
                                                        // customStyleMap={styleMap}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbarCustomButtons={[<CustomOption />]}
                                                        toolbar={{
                                                            // options: ['fontFamily','inline','colorPicker','link','textAlign','history','image']
                                                            image: {
                                                                urlEnabled: true,
                                                                uploadEnabled: true,
                                                                alignmentEnabled: true,
                                                                uploadCallback: props?.uploadImageCallBack,
                                                                alt: { present: true, mandatory: true },
                                                                previewImage: true,
                                                                defaultSize: {
                                                                    height: 200,
                                                                    width: 200,
                                                                },

                                                            },
                                                            fontFamily: {
                                                                options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'inherit'],
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                            },
                                                            fontSize: {
                                                                options: [8, 9, 10, 11, 12, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24, 30, 36, 48, 60, 72, 96],
                                                            }
                                                        }}
                                                        onEditorStateChange={(editorState) => props.handleText(editorState, i, props?.name)}
                                                    />
                                                    <div className="article-style">

                                                        <Button variant="outlined" className="publish-article article-common" onClick={() => props?.submitArticle(title, props?.name)}>Publish</Button>

                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        {edit === title?.id ?
                                            <AddSub name={props?.name} i={i}
                                                sub={title} handleAdd={(name, value, i) => props?.handleEdit(name, value, i)}
                                                handleSubtitle={() => { props?.submitArticle(title, props?.name); setEdit(false) }}
                                            /> : ""}
                                    </div>
                                    <IconButton onClick={() => setEdit(title?.id)}><EditIcon /></IconButton>
                                    <IconButton onClick={() => props.deleteArticle(title?.id)}><Delete /></IconButton>
                                </Box>

                            )
                        })}
                        <Typography component="div" color="primary">Add Subtitle<IconButton className="icon-btn" onClick={props?.addfun}><img src={AddIcon} alt="Add" /></IconButton></Typography>

                        {props.add ?
                            <AddSub name={props?.name}
                                sub={props?.sub} handleAdd={props?.handleAdd}
                                handleSubtitle={props?.handleSubtitle}
                            /> : ""}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>

    )
}

export default function Article() {

    const [title, setTitle] = useState([])
    const [disease, setDisease] = useState(null)
    const [specialities, setSpecialities] = useState([])
    const [speciality, setSpeciality] = useState(null)
    // const [url, setUrl] = useState('')
    const [expanded, setExpanded] = React.useState(false);
    const [subexpanded, setSubExpanded] = React.useState(false);
    const list = [
        { name: 'Summary', panel: 'panel-1' },
        // { name: 'Epidemiology', panel: 'panel-2' },
        // { name: 'Etiology', panel: 'panel-3' },
        { name: 'Subjective', panel: 'panel-4' },
        { name: 'Diagnosis', panel: 'panel-5' },
        { name: 'Management', panel: 'panel-6' },
        { name: 'Followup', panel: 'panel-7' },
        { name: 'Resources', panel: 'panel-8' },
        { name: 'References', panel: 'panel-9' },
        { name: 'Info', panel: 'panel-10' },
    ]
    const [sub, setSub] = useState({ subtitle: '', child_subtitle: '', body: null })
    const [add, setAdd] = useState(false)
    const [article, setArticle] = useState({
        Summary: [],
        Epidemiology: [],
        Etiology: [],
        Subjective: [],
        Diagnosis: [],
        Management: [],
        Followup: [],
        Resources: [],
        References: [],
        Info: []
    })

    const [alert, setAlert] = useState({ open: false, msg: '' })

    const [subtitle, setSubtitle] = useState('Summary')

    const errorMessages = (err) => {
        if (err?.response?.status === 500) {
            setAlert({ open: true, msg: `${err?.response?.statusText}. Try reloading the page` })
        }
        else if (err?.response?.status === 502) {
            setAlert({ open: true, msg: `${err?.response?.statusText}. Try reloading the page` })
        }
        else if (err?.response?.status === 401) {
            setAlert({ open: true, msg: `Unauthorized.Please login with valid credentials` })
        }
        else {
            setAlert({ open: true, msg: `Please login with valid credentials` })
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setSub({ subtitle: '', child_subtitle: '', body: null })
        setSubtitle(panel)
        setAdd(false)
    };
    const handleSub = (panel) => (event, isExpanded) => {
        setSubExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {

        axios.get(`${URL.elib}allSpecialities/`).then(res => {
            setSpecialities(res.data?.data)
        }).catch(err => errorMessages(err))

    }, [])

    useEffect(() => {
        setDisease(null)
        if (speciality !== null) {
            axios.get(`${URL.elib}filterDiseases/${speciality.id}`).then(res => {
                setTitle(res.data.data.diseases)
            }).catch(err => errorMessages(err))
        }
    }, [speciality])

    useEffect(() => {
        if (disease !== null) {
            getArticle()
        }
    }, [disease, subtitle])

    const getArticle = async () => {
        if (subtitle === 'Summary') {
            axios.get(`${URL.elib}getDiseaseSummaryData/${disease?.id}-${subtitle}`)
                .then(res => {
                    // console.log(res)
                    setArticle({ ...article, [subtitle]: res.data?.data })
                }).catch(err => errorMessages(err))
        }
        else {
            axios.get(`${URL.elib}diseaseArticlesData/${disease?.id}-${subtitle}`, headers()).then(res => {
                setArticle({ ...article, [subtitle]: res.data?.data })
            }).catch(err => errorMessages(err))
        }
    }

    const getFileBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        // Since FileReader is asynchronous,
        // we need to pass data back.
        reader.onload = () => callback(reader.result);
        // TODO: catch an error
        reader.onerror = error => { };
    };

    const uploadImageCallBack = file => new Promise(
        (resolve, reject) => getFileBase64(
            file,
            data => resolve({ data: { link: data } })
        )
    );


    // const uploadFile = (e) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(e.target.files[0]);

    //     reader.onloadend = () => {
    //         setUrl(reader.result)
    //     }
    //     if (e.target.files[0]) {
    //         setUrl(reader.result)
    //     }
    // }

    const handleSubtitle = async (e, name) => {
        e.preventDefault()
        let s = [...article[name]]
        if (disease !== null) {
            let t = {
                disease_id: disease?.id,
                subtitle: name,
                child_subtitle: sub?.child_subtitle,
                body: `<p></p>`,
                disease_name: disease?.disease,
                summary: ''
            }
            s.push(sub)
            // setArticle({ ...article, [name]: s })
            axios.post(`${URL.elib}diseaseArticlesData/post`, t, headers())
                .then(async res => {
                    await getArticle()
                    setSubExpanded(false)

                }).catch(err => errorMessages(err))
            setAdd(false)
            setSub({ child_subtitle: '' })
        }
        else {
            alert('Select Disease')
        }
    }

    const handleText = (editorState, i, name) => {
        let text = [...article[name]]
        text[i]['body'] = editorState
        // console.log(text, text[i]['body'])
        // console.log(convertToRaw(editorState.getCurrentContent()))
        // let d = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/<[^>]+>/g, '')
        // var tempDivElement = document.createElement("div");

        // // Set the HTML content with the given value
        // tempDivElement.innerHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        // // Retrieve the text property of the element 
        // console.log(tempDivElement.textContent || tempDivElement.innerText || "")
        // console.log(d)
        setArticle({ ...article, [name]: text })
    }

    const submitArticle = (data, type) => {
        // console.log(draftToHtml(convertToRaw(data.text.getCurrentContent())))
        if (disease !== null) {
            // console.log(data.body)
            var tempDivElement = document.createElement("div");
            // console.log(tempDivElement)
            // Set the HTML content with the given value
            tempDivElement.innerHTML = typeof (data.body) === "string" ? data.body : draftToHtml(convertToRaw(data.body.getCurrentContent()));

            let article = {
                id: data?.id,
                subtitle: type,
                child_subtitle: data?.child_subtitle,
                body: typeof (data?.body) === "string" ? data?.body : `${draftToHtml(convertToRaw(data?.body?.getCurrentContent()))}`,
                disease_id: disease?.id,
                disease_name: disease?.disease,
                summary: type === 'Summary' ? tempDivElement?.textContent || tempDivElement?.innerText || "" : ''
            }
            axios.put(`${URL.elib}diseaseArticlesData/update`, article, headers())
                .then(res => {
                    setSubtitle(type)
                    setSubExpanded(false)
                }).catch(err => errorMessages(err))
        }
        else {
            alert("Select Disease")
        }
    }

    const handleAdd = (name, value) => {

        setSub({ ...sub, subtitle: name, child_subtitle: value, body: '' })

    }

    const handleEdit = (name, value, i) => {

        let text = [...article[name]]
        text[i]['child_subtitle'] = value
        setArticle({ ...article, [name]: text })

    }

    const deleteArticle = (id) => {
        axios.delete(`${URL.elib}diseaseArticlesData/${id}`, headers())
            .then(async res => {
                // console.log(res)
                getArticle()
            }).catch(err => errorMessages(err))
    }

    return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
            <Container>
                <br />
                <h2 className="textCenter">Diseases</h2>
                <br />
                <div style={{ display: 'flex' }}><Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={specialities}
                    getOptionLabel={(option) => option?.speciality}
                    onChange={(event, newValue) => {
                        setSpeciality(newValue)
                    }}
                    value={speciality}
                    sx={{ mb: 3, width: '40%', mx: 'auto' }}
                    renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Specialities" value={speciality}
                    />}
                />

                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={title}
                        getOptionLabel={(option) => option?.disease}
                        onChange={(event, newValue) => {
                            setDisease(newValue)
                        }}
                        value={disease}
                        sx={{ mb: 3, width: '40%', mx: 'auto' }}
                        renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Disease" value={disease}
                        />}
                    /></div>

                {disease !== null ?
                    list?.map((l, i) => (

                        <Accord key={i} array={article[l?.name]} subexpanded={subexpanded} handleSub={handleSub}
                            uploadImageCallBack={uploadImageCallBack} handleText={handleText}
                            submitArticle={submitArticle} addfun={() => setAdd(true)} name={l?.name} panel={l?.panel} handleEdit={handleEdit}
                            deleteArticle={deleteArticle}
                            expanded={expanded} sub={sub} handleAdd={handleAdd} add={add} handleSubtitle={handleSubtitle} handleChange={handleChange}
                        />

                    )) : ''}

            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2500}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />
        </div>
    )
}