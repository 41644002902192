import React from "react";
import description from "../../images/article.svg";
import event_icon from "../../images/event.svg";
import celebration from "../../images/celebration.svg";
import poll_icon from "../../images/poll.svg";
import InputOption from "../Molecule/Feed/InputOption";
import like from '../../images/like.png'
import comment from '../../images/comment.png'
import share from '../../images/share.png'
import reportIcon from '../../images/report-icon.png'
// import '../SignUp/signup.css';

function Expertise() {
  return (
    <div className='section5-expertise'>
      {/* <div className="expertise">
        <h2 className="expertise-sectionwise-headers">Share your Expertise</h2>
        <p className="expertise-sectionwise-desc">Create post, write articles and share your expertise and experiences</p>

        <div className="section5-inputs">
          <InputOption src={celebration} alt="Celebrate" title="Celebrate" />
          <InputOption src={poll_icon} title="Create Poll" alt="Poll" />
          <InputOption src={event_icon} title="Create Event" alt="Event" />
          <InputOption src={description} title="Write Article" alt="Article" />
        </div>
      </div>

      <div className="share">
        <h2 className="expertise-sectionwise-headers">Like Comment Share</h2>
        <p className="expertise-sectionwise-desc">Appreciate other work , express your opinion and share what you admire</p>
        <div className="like-comment-share">
          <img src={like} alt='like' />
          <img src={comment} alt='comment' />
          <img src={share} alt='share' />
        </div>
      </div>

      <div className="report-misuse">
        <h2 className="expertise-sectionwise-headers">Report Misuse</h2>
        <p className="expertise-sectionwise-desc">Every user is a stakeholder and responsibility for Safe guarding the network </p>
        <div className="report">
          <img src={reportIcon} alt='report-icon' />
          <p>Misinformation</p>
          <p>Abuse</p>
        </div>
      </div> */}
    </div>
  );
}

export default Expertise;
