
import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import Organisation from "../../../../Atom/modifiedicons/Organisations.svg";
import scroll from "../../../../Atom/svg/scroll.svg";
import { DOC_API_WITHOUT_TOKEN } from "../../../../../services/APIService";
import { URI } from "../../../../utility/uri";
import CloseIcon from '@mui/icons-material/Close';
import { FormDivider } from "../../../../utility/utils";




const RearrangingServiceComponents = ({ open, close, componentsList, updateGetList, healthServiceId }) => {


    const [info, setInfo] = useState([]);
    const [alert, setAlert] = useState({
        open: false,
        msg: "",
    })
    const orgData = useSelector(state => state.registration.userOrgData);

    useEffect(() => {
        let sortList = componentsList?.sort((a, b) => a?.id - b?.id);
        if (sortList?.length > 0) {
            setInfo(sortList);
        } else {
            setInfo([]);
        }
        // if (componentsList?.length > 0) {
        //     setInfo(componentsList)
        // } else {
        //     setInfo([])
        // }

    }, [componentsList]);



    const getUpdatedComponentInfo = (serviceid) => {
        if (serviceid) {
            DOC_API_WITHOUT_TOKEN.get(`${URI.getAddedComponents}${serviceid}`).then((res) => {
                // console.log(res.data, "get")
                let sortedList = res.data?.hashcomponents ? res.data?.hashcomponents?.sort((a, b) => a.id - b.id) : []
                updateGetList(sortedList)
                // if (res.data?.hashcomponents) {
                //     updateGetList(res.data?.hashcomponents)
                // }
            }).catch((err) => console.log(err));
        }
    }


    const updateDepartmentsComps = (healthServceId, data) => {
        if (healthServceId) {
            DOC_API_WITHOUT_TOKEN.put(`${URI.updateAddedComponents}${healthServceId}`, data).then((res) => {
                console.log(res.data);
                getUpdatedComponentInfo(healthServceId);
            }).catch((err) => console.log(err));
        } else {

        }
    }


    console.log(info, "info")


    const handleDragEnd = (result) => {

        let list = [...info];
        if (!result?.destination) {
            return;
        }

        const { source, destination } = result;
        console.log(result, "result")
        const [sourceObj] = list?.splice(source?.index, 1);
        list?.splice(destination?.index, 0, sourceObj);

        const updatedList = list?.map((item, index) => {


            return {
                ...item,
                id: source?.index
            }
            // if (index === source?.index) {
            //     return {
            //         ...item,
            //         id: source?.index
            //     };
            // }
            // if (index === destination?.index) {
            //     return {
            //         ...item,
            //         id: destination.index
            //     };
            // }
            // return item;

        });
        updateDepartmentsComps(healthServiceId, updatedList);
        setInfo(updatedList);

    };


    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            style={{
                height: "500px",
                overflow: "hidden",

            }}
        >
            <DialogTitle id="alert-dialog-title">
                <div className="flx-center justify-between" >
                    <div className='fntMd fntBold9' style={{ color: '#007cdc' }}>Re-Arrange Services Components</div>
                    {/* <div><CloseIcon sx={{ fontSize: "14px" }} /></div> */}
                </div>

            </DialogTitle>
            <DialogContent
                style={{
                    overflow: "auto",  // Ensure only DialogContent is scrollable
                }}
            >
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="ServiceComp">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    overflowY: "auto",
                                    height: "100%",
                                }}
                            >
                                {info.length > 0 ? (
                                    info.map((val, indx) => (
                                        <Draggable
                                            key={indx.toString()}  // Use one-based index as string for key
                                            draggableId={indx.toString()}  // Use one-based index as string for draggableId
                                            index={indx}  // Use zero-based index for sorting
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        padding: "10px",
                                                        marginBottom: "10px",
                                                        backgroundColor: snapshot.isDragging ? "#e3f2fd" : "white",
                                                        borderRadius: "7px",
                                                        border: "1px solid #ddd"

                                                    }}
                                                >
                                                    <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                                        <img src={Organisation} alt="organisation" width='25px' height="25px" />
                                                    </div>
                                                    <div style={{ height: "40px", width: "100%" }}>
                                                        <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
                                                            {`${val?.name}`}
                                                        </div>
                                                        {/* <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>
                                                            {val?.resource?.alias?.[0]}
                                                        </div> */}
                                                    </div>

                                                    <div>
                                                        <img src={scroll} alt="scroll" />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <p className="flx-center justify-center fntSemiBold fntSm14">No Data Available</p>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>
            <FormDivider />
            <DialogActions>
                <Button variant="text" className='capitalize' sx={{ padding: "4px 20px !important", borderRadius: "20px" }} onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RearrangingServiceComponents;