import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



const UpComingData = () => {

    const [count, setCount] = React.useState(3)

    const events = useSelector(state => state.userProfile.events)
    // console.log(events, "upcoming")




    return (
        <>
            {events && events[0].calenderEvents.length > 0 ? events[0].calenderEvents.slice(0, count).map((event, i) => (
                <a key={i} href={`/webinar/eventdata-${event.eventId}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="d-flex sidebar__pt1 textAlign capitalize e-align" style={{ overflow: "hidden" }}>
                        <div style={{ width: '45px', height: '45px', borderRadius: '8px' }}>
                            <img src={event.eventImage ? event.eventImage : "https://docstethdevimages.s3.ap-south-1.amazonaws.com/userProfilePics/profilePic/webinar.png"} alt="organisation" width='45px' height="45px" style={{ borderRadius: "8px" }} />
                        </div>
                        <div className="sidebar__ml sidebar__pt1 " style={{ overflow: "hidden" }}>
                            <p className='job_title text-ellipsis'>{event.title}</p>
                            <p className='job_sub_title'>{moment.utc(event.openingTime).local().format('DD MMM YYYY | hh:mm A')}</p>


                        </div>
                    </div>
                    <div className='sidebar__pt1'><ArrowForwardIosIcon fontSize='small' /></div>
                </a>

            )) : <div className="e-align profile__top sidebar__pb"><p>No upcoming events</p></div>}

        </>

    )
}

export default UpComingData