import React, { useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CustomTextField from '../Fields/TextFields';
import CssTextField from '../Fields/CssTextField';
import Header from './header';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
import axios from "axios";
import URL from '../apiurl';
import { Typography, MenuItem, Select, FormControl, InputLabel, Avatar, Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import './signup.css';
import MuiPhoneNumber from 'material-ui-phone-number';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from 'moment';
import SignUpLeft from './signup-left';
// import { parsePhoneNumber } from 'react-phone-number-input'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { debounce } from 'lodash';
const CssText = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '12px ',
      // height: '54px',
      boxShadow: '0px 3px 4px #756d6d29',
      color: 'grey',
      // width: '40%',
    },
  }
});

const countryData = [
  { name: 'India', countryCode: "+91", flag: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg' },
  { name: 'Australia', countryCode: "+61", flag: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Australia.svg' },
  { name: 'Canada', countryCode: '+1', flag: 'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Canada.svg' },
  { name: 'UAE', countryCode: '+971', flag: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg' },
  { name: 'United States', countryCode: '+1', flag: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg' },
  { name: 'United Kingdom', countryCode: '+44', flag: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg' },
]

export default function Register() {
  const history = useHistory()
  const [state, setState] = useState({ dob: null, phone: null })
  // const [shrink, setShrink] = useState(false)
  const [dob, setDob] = useState(null)
  const [profession, setProfession] = useState(null)
  // const [countryList, setCountryList] = useState(Country);
  const [countryName, setCountryName] = useState("India");
  const [check, setCheck] = useState(true);
  const [alert, setAlert] = useState({ open: false, msg: '' })
  const [verifymobilenum, setVerifymobilenum] = useState(null)
  const [avaliable, setAvaliable] = React.useState(false)




  // const [countryCode, setCountryCode] = useState("+91");
  // const [mobileObj, setMobileObj] = useState({
  //   phoneno: "",
  //   error: ""
  // })

  // console.log(moment(dob).format("YYYY-MM-DD"), "dob")
  // console.log(mobileObj.phoneno, "phoneNumber")
  // console.log(countryCode, "code")



  const handleChange = (name, value, country) => {
    // console.log(name, value, country)
    setState({ ...state, [name]: value })
  }
  // console.log(state.phone)


  // const handleOnPhone = (e) => {
  //   let inputValue = e.replace(/\D/g, '');// Remove non-digit characters
  //   console.log(inputValue, "inputValue")
  //   var val;
  //   if (countryCode == "+91") {
  //     val = inputValue.slice(0, 10);
  //   }
  //   else {
  //     val = inputValue;
  //   }
  //   setMobileObj((prev) => {
  //     return { ...prev, phoneno: val }
  //   })
  //   let countrycodewithNumber = (`${countryCode}${inputValue}`).replace(/[^A-Z0-9]+/ig, "");
  //   NumberCheck(countrycodewithNumber)
  //   // // console.log(countrycodewithNumber, "codewithNumber")
  //   // NumberCheck(countrycodewithNumber)

  // }

  const mobileNumberCheck = debounce((name, value) => {
    // console.log(value, "phonenumber1111")
    handleChange('phone', value)
    let number = value.replace(/[^A-Z0-9]+/ig, "")

    axios.get(`${URL.verifyMobilenumber}+${number}`).then(res => {

      if (res.status === 200) {
        if (res.data?.status === 200) {
          setVerifymobilenum(<BsCheckCircle style={{ color: "green", fontWeight: "600" }} />)
          setAvaliable(true)
        } else if (res.data?.status === 409) {
          setVerifymobilenum(<AiOutlineCloseCircle style={{ color: "red", fontWeight: "600" }} />)
          setAvaliable(false)
          setAlert({ open: true, msg: `${res.data?.message}` });
        }
      } else {
        setVerifymobilenum(null)
        setAlert({ open: true, msg: `${res.data?.message}` });
      }
    }, error => {
      setAlert({ open: true, msg: `${error}` });
    })
  }, 1000)


  // console.log(state, "verify")

  // const NumberCheck = (value) => {
  //   console.log(value, "phonenumber4444")
  //   let number = value.replace(/[^A-Z0-9]+/ig, "")
  //   axios.get(`${URL.verifyMobilenumber}+${number}`).then(res => {
  //     if (res.status === 200) {
  //       if (res.data.status === 200) {
  //         setVerifymobilenum(<BsCheckCircle style={{ color: "green", fontWeight: "600" }} />)
  //         setAvaliable(true)
  //       } else if (res.data.status === 409) {
  //         setVerifymobilenum(<AiOutlineCloseCircle style={{ color: "red", fontWeight: "600" }} />)
  //         setAvaliable(false)
  //         setAlert({ open: true, msg: `${res.data.message}` });
  //       }
  //     } else {
  //       setVerifymobilenum(null)
  //       setAlert({ open: true, msg: `${res.data.message}` });
  //     }
  //   }, error => {
  //     setAlert({ open: true, msg: `${error}` });
  //   })
  // }


  // const NumberCheck = (selectedCountryCode, givenNumber) => {
  //   let number = givenNumber.replace(/[^A-Z0-9]+/ig, "");
  //   axios.post(`https://dev.api.docsteth.com/api/v1/checkUserPhoneNumber?countrycode=${selectedCountryCode}&phonenumber=${number}`).then((res) => {
  //     if (res.status === 200) {
  //       if (res.data.status === 200) {
  //         setVerifymobilenum(<BsCheckCircle style={{ color: "green", fontWeight: "600" }} />)
  //         setAvaliable(true)
  //       } else if (res.data.status === 409) {
  //         setVerifymobilenum(<AiOutlineCloseCircle style={{ color: "red", fontWeight: "600" }} />)
  //         setAvaliable(false)
  //         setAlert({ open: true, msg: `${res.data.message}` });
  //       }
  //       else if (res.data.status === 400) {

  //       }
  //     } else {
  //       setVerifymobilenum(null)
  //       setAlert({ open: true, msg: `${res.data.message}` });
  //     }
  //   }, error => {
  //     setAlert({ open: true, msg: `${error}` });
  //   })


  // }

  // handle on onchange for phone number
  // let number = `${countryCode}${" "}${mobileObj.phoneno}`


  const Submit = () => {
    // this below condition for development check
    // if (check && (profession.length !== 0) && (dob.length !== 0) && (state.phone.length !== 0)) {
    // uncomment below code for production and comment above code 
    if (check && (profession?.length !== 0) && (dob?.length !== 0) && (state?.phone?.length !== 0) && (avaliable == true)) {

      const data = {
        metadata: {},
        phoneNumber: state?.phone
      }

      let number = state.phone?.replaceAll("-", "")
      // console.log(number, "number")
      // console.log(number, "++++++++++++++++")
      // below code is testing purpose
      // let number = `${countryCode}${" "}${mobileObj.phoneno}`
      const sendData = {
        profession: profession,
        dob: (dob) ? moment(dob)?.format("YYYY-MM-DD") : null,
        phoneNumber: number

      }
      history.push({
        pathname: '/createaccount',
        state: sendData,
        prevUrl: '/'
      })
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://www.google.com/recaptcha/api.js?render=6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9"
    document.body.appendChild(script)
  }, [])

  const professionList = [
    "Medical Doctor",
    "Specialist",
    "Register Nurse",
    "Pharmacist",
    "Medical Student",
  ];

  return (
    <>
      <Header />
      <div className=' global-font sign-color section1-parent-container' id="header_width" style={{ display: "flex" }}>
        <div className='signup-left-wrapper' ><SignUpLeft /></div>
        <div className='input_field-wrapper'>
          <div className='input_field'  >

            <div className="main-text-container">
              <h1 className="head register-H1" >Register Here</h1>
              <p className="para register-P" style={{ textAlign: 'center' }}>An Exclusive Platform For Doctors</p>
            </div>
            <ValidatorForm
              // ref="form"
              onSubmit={Submit}
              onError={errors => { }}
            >
              {/* <CustomTextField label="Dr"
            placeholder="Your Name here" Label="Dr Your Name Here"
            value={state.fullname} name="fullname" handleChange={handleChange}
          /> */}
              <Autocomplete
                disablePortal
                id="profession"
                options={professionList}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value || ''}
                onChange={(event, newValue) => {
                  setProfession(newValue)
                }}
                value={profession}
                className='Autocomplete_input'
                renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Profession" value={profession}
                />}
              />
              {/* <Autocomplete
            disablePortal
            id="profession"
            options={professionList}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setProfession(newValue)
            }}
            value={profession}
            className='Autocomplete_input'
            validators={["required"]}
            errorMessages={["Please Enter Your profession"]}
            // sx={{ mb: 3, width: '100%', mx: 'auto' }}
            renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Your Profession" value={profession
            }
            />}
          /> */}
              <div className="dob_padding">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%", '.MuiInputBase-root': { borderRadius: '14px' } }}
                    label='Date Of Birth'
                    disableFuture
                    value={dayjs(dob)}
                    onChange={(value) => setDob(new Date(value))}
                    // inputFormat="dd/MM/yyyy"
                    format="DD/MM/YYYY"
                    renderInput={(params) =>
                      <CssTextField
                        fullWidth
                        onKeyDown={(e) => e?.preventDefault()}
                        {...params} />}
                  />
                </LocalizationProvider>
              </div>
              {/* <Autocomplete
            disablePortal
            id="country-code"
            options={countryList}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              // setCountryCode(newValue)
              setCountryName(newValue)
            }}
            value={countryName}
            className='Autocomplete_input'
            // sx={{ mb: 3, width: '100%', mx: 'auto' }}
            renderInput={(params) => <CssText style={{ marginTop: '16px' }} {...params} label="Country of Practice" value={countryName}
            />}
          /> */}
              <div>
                <MuiPhoneNumber variant="outlined"
                  defaultCountry="in"
                  id="outlined-number"
                  label='Phone Number'
                  className="phone-num"
                  onlyCountries={['in', 'us', 'ca', 'au', 'gb', 'ae']}
                  fullWidth
                  name="phone"
                  value={state.country}
                  onChange={(value, country) => mobileNumberCheck('phone', value, country)}
                />
                {(!avaliable && state?.phone) ?
                  <h4 style={{ color: "red", display: "flex", justifyContent: "flex-start" }}><AiOutlineCloseCircle style={{ color: "red", fontWeight: "600", paddingRight: "10px" }} />Phone number already exist</h4> : ""
                }
              </div>
              {/* 
                <PhoneInput
                  country={'in'}
                  placeholder="Enter Phone Number"
                  value={state.country}
                  onChange={(value, country) => mobileNumberCheck('phone', value, country)}
                  defaultErrorMessage="Enter phone number"
                  inputClass="input_phone"
                  countrydata
                  inputProps={{
                    // name: 'phone',
                    required: true,
                    autoFocus: true
                  }}
                /> */}




              {/* <div style={{ display: "flex" }}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCountry}
                    name="selectedCountry"
                    label="Age"
                    onChange={handlePhoneNumber}
                    size="small"
                  >
                    {countryData?.length > 0 &&
                      countryData.map((each, index) => {
                        return (
                          <MenuItem}>
                      <div key={index} value={`${ each.countryCode }$${ each.flag }`}>
                        < img alt="Cindy Baker" src={each.flag} width="20px" height="20px" />&nbsp;
                        {each.name} {each.countryCode}

                      </div>
                          </MenuItem>
                  );
                      })}
                      }

                </Select>
              </FormControl>
              <TextField sx={{ borderStyle: "none" }} size="small" value={selectedCode.countryCode} />
          </div> */}

              {/* <Grid container alignItems="center">

                <Grid item style={{ width: "30%" }}>
                  <Autocomplete
                    disablePortal
                    id="profession"
                    options={countryData}
                    getOptionLabel={(option) => option?.countryCode}
                    isOptionEqualToValue={(option, value) => {
                      // console.log(typeof (option.countryCode), '99999', typeof (value))
                      return option.countryCode === value || ""

                    }}
                    onChange={(event, newValue) => {
                      console.log(newValue.countryCode, "-------")
                      setCountryCode(newValue?.countryCode)
                    }}
                    value={countryCode}

                    className='Autocomplete_input'
                    renderInput={(params) => <CssText {...params}
                      value={countryCode}
                    />}
                  />
                </Grid>
                <Grid item style={{ marginBottom: "24px", width: "70%" }}>
                  <CssText value={phoneNumber} onChange={(event, newValue) => NumberCheck(newValue)} />
                </Grid>

              </Grid> */}


              {/* 
              <Typography component="div" sx={{
                display: "flex", width: "100%"
                , height: "50px", borderRadius: "12px", border: '1px solid #C4C4C4', boxShadow: '0px 3px 4px #756d6d29', marginTop: 2
              }}>
                <select name="countryCode" id="cars" value={countryCode} onChange={(e) => {
                  setCountryCode(e.target.value);
                }} style={{ width: "20%", height: "50px", border: "none", borderRadius: "12px", fontSize: 16 }} >
                  {
                    countryData.map((each, index) => {
                      return (
                        <option key={index} value={each.countryCode}>{each.countryCode}</option>
                      )
                    })
                  }
                </select>
                <input type="tel" placeholder="Please Enter Mobile number" name="phoneno" value={mobileObj.phoneno} onChange={(e) => handleOnPhone(e.target.value)} style={{ width: "80%", height: "48px", border: "none", borderRadius: "12px", fontSize: 16 }} />

              </Typography>
              {(!avaliable && mobileObj.phoneno) ?
                <h4 style={{ color: "red", display: "flex", justifyContent: "flex-start" }}><AiOutlineCloseCircle style={{ color: "red", fontWeight: "600", paddingRight: "10px" }} />Phone number already exist</h4> : ""
              } */}


              <div style={{ color: '#A2A2A2', fontSize: '18px' }}>
                <h6 className='disclaimer_text'>*Disclaimer</h6>
                <FormGroup className='disclaimer-text'>
                  <FormControlLabel
                    className='accept_text'
                    control={<Checkbox className="r-check" checked={check} disableRipple onChange={() => setCheck(!check)} />}
                    label={<span>I read, understood an hereby accept <span style={{ color: '#0880F8' }} onClick={() => history.push('/termsandcondition')}>terms & conditions</span> of Signup</span>}
                  />
                </FormGroup>
                {check ? '' : <Typography variant="caption" sx={{ color: 'red' }}>Accept terms and conditions</Typography>}

              </div>
              <div style={{ textAlign: 'center' }} className='mv1'>
                {/* <Button data-action='submit' className={`btn - signup  ${!check ? 'cursor-notallowed' : ''} `} disabled={avaliable == false ? true : false} size='small' variant='outlined' type="submit">Sign Up</Button> */}
                {/* <Button data-action='submit' className={`btn - signup  ${!check ? 'cursor-notallowed' : ''} `} disabled={(avaliable == false && (state?.phone?.includes("+91") ? state?.phone?.split(" ")?.[1]?.replaceAll("-", "")?.length == 10 ? false : true : true))} size='small' variant='outlined' type="submit">Sign Up</Button> */}
                <Button data-action='submit' className={`btn - signup  ${!check ? 'cursor-notallowed' : ''} `} disabled={(state?.phone?.includes("+91") ? (state?.phone?.split(" ")?.[1]?.replaceAll("-", "")?.length == 10 && avaliable == true) ? false : true : (avaliable == true ? false : true))} size='small' variant='outlined' type="submit">Sign Up</Button>
              </div>
            </ValidatorForm>
          </div >
        </div >
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={2000}
          open={alert.open}
          onClose={() => setAlert({ open: false, msg: '' })}
          message={alert.msg}
        />
      </div >
    </>
  )
}
