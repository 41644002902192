import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Dropdown from './dropdown';
import InputField from '../../../Fields/TextFields'
import CssTextField from '../../../Fields/CssTextField';
import URL from '../../../apiurl';
import Drop from '../../../Fields/CssTextField'
import { TextField, Typography } from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import UserService from '../../../../services/UserService';
import { D_API, D_API_FHIR } from '../../../../services/APIService';
import { getPractitionerData, getPractitionerDatas } from '../../../common/functions/functions';


export default function Crud({ state, handleChange, handleOrg, handleSubmit, submit, check, handleCheck, cancel, nextbtn, skip, handlecity, }) {

  const [locations, setLocations] = React.useState([])
  const [text, setText] = React.useState('Search Locations')
  const [view, setView] = useState([]);
  const [practitioner, setPractitioner] = useState()
  const [practitionerBool, setPractitionerBool] = useState()
  const [alertMsg, setAlertMsg] = useState({ open: false, msg: "" })
  const userOrg = useSelector(state => state.registration.userOrg);
  const userData = useSelector(state => state.userProfile.userProfile)

  // console.log(userOrg, "143")


  console.log(state, "statesss", handleOrg, "oooooooooooo", submit, "hhh", userData)
  console.log(userData, "userDatauserData11")
  //  const [alert, setAlert] = useState({
  //    open: true,
  //    msg: "Uploaded Successfully",
  //    upload: false,
  //  });

  // const degree = [
  //   { code: "BS", display: "Bachelor of Science (Medical)" },
  //   { code: "MD", display: "Doctor of Medicine" },
  //   { code: "MS", display: "Master of Surgery" },
  //   { code: "DNB", display: "Diplomate of National Board" },
  //   { code: "D.M.", display: "Doctorate of Medicine" },
  //   { code: "M.Ch.", display: "Master of Chirurgiae" },
  // ];

  // const bscode = [
  //   "BAMS - Bachelor of Ayurvedic Medicine and Surgery",
  //   "BDS - Bachelor of Dental Surgery",
  //   "BHMS - Bachelor of Homeopathy Medicine and Surgery",
  //   "BUMS - Bachelor of Unani Medicine and Surgery",
  //   "BYNS- Bachelor of Yoga and Naturopathy Sciences",
  //   "MBBS – Bachelor of Medicine, Bachelor of Surgery",
  // ];

  //  useEffect(() => {
  //    // console.log(state.degree.display)
  //    if (state.degree !== null) {
  //      if (state.degree.display === "Bachelor of Science (Medical)") {
  //        setView(bscode);
  //      } else {
  //        setView(courses);
  //      }
  //    }
  //  }, [state.degree]);

  const [open, setOpen] = React.useState(false);
  const [organizationList, setOrganizationList] = useState([])



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const p_data = await getPractitionerDatas();
  //       console.log(p_data, "pppppppppppddddddddddd");
  //     } catch (error) {
  //       console.error("Error fetching practitioner data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   D_API_FHIR.get(`Practitioner?identifier=https://dev.docsteth.com|${userData?.id}`)
  //     .then(res => {
  //       console.log(res?.data?.entry?.[0], "practitioner")

  //       res?.data?.entry?.[0]?.resource?.identifier?.some(e => e?.value.split('.')[1] === 'abdm' ? setPractitioner(true) : console.log('no hpr'));

  //     })
  //     .catch(err => err)
  // }, [])


  useEffect(() => {
    D_API_FHIR.get(`Practitioner?identifier=https://dev.docsteth.com|${userData?.id}`)
      .then(res => {
        console.log(res?.data, "practitioner12122");
        setPractitionerBool(res?.data?.entry?.[0] || {})
        res?.data?.entry?.[0]?.resource?.identifier?.some(e => e?.value.split('.')[1] === 'abdm' ? setPractitioner(e?.value.split('.')[1]) : console.log('no hpr'));

      })
      .catch(err => err);
  }, []);

  console.log(practitionerBool, "practitioner11")

  // useEffect(() => {
  //   D_API.get("Organization/List").then(res => {
  //     console.log(res)
  //     setOrganizationList(res?.data?.entry)
  //   }).catch(err => err)
  // }, [])

  const getLocation = (value) => {
    if (value !== '') {
      setText('No Options')
      axios.get(`${URL.searchLocation}/${value}`).then(res => setLocations(res.data.data.predictions.map(data => data.description)))
    }
    else {
      setText('Search Location')
      setLocations([])
    }
  }
  // console.log(value)
  console.log(state, "state")

  const data = useSelector(state => state.userProfile.userProfile);
  // console.log(data)
  const profession = data.profession

  const sub = (orgs,requestFrom) => {
    if (state.fromDate !== null && (check == true || state.toDate !== null) && state.organisationName !== undefined) {
      handleSubmit(orgs?.organisationName?.id,requestFrom)
      setOpen(true);
    }
    else if (state.organisationName === undefined) {
      alert('Please add organisation')
    }
    else {
      alert('Enter valid date')
    }
  }

  const handleClick = () => {

  };


  const handleCloseAlert = () => {
    setAlertMsg({
      open: false,
      msg: ""
    })
  }

  const handleClose = () => {
    // if (reason === "clickaway") {
    //   return;
    // }

    setOpen(false);
  };
  console.log("first543", state)

  const handleRequestRole = () => {
    console.log("first", state)

    // if (practitioner === 'abdm' && state?.designation) {


    // old code
    // sub(state)
    let data = userData.practioner
    axios.post(process.env.REACT_APP_API_URL + `v1/sendRequest/${UserService.getUserId()}?IbmOrgId=${state.organisation}`, data, {
      headers: {
        Authorization: `bearer ${sessionStorage.getItem("kctoken")}`
      }
    }).then(res => {


      if (res.status == 200) {
        let riseFrom="RoleRequest"
        sub(state,riseFrom);
        alert("Request Sent")
      }


    }).catch(err => {
      if (err.response.status == 422) {
        alert("You have already raised role request on this organization. You can't raise another request on this organization")
      } else {
        alert(err?.response?.data?.message || 'Couldn\'t send request.Try again later')
      }
      // alert('Couldn\'t send request.Try again later')
      cancel();

    })




    // if (state?.designation && practitionerBool?.resource?.active) {
    //   sub(state)
    //   let data = userData.practioner
    //   axios.post(process.env.REACT_APP_API_URL + `v1/sendRequest/${UserService.getUserId()}?IbmOrgId=${state.organisation}`, data, {
    //     headers: {
    //       Authorization: `bearer ${sessionStorage.getItem("kctoken")}`
    //     }
    //   }).then(res => {
    //     alert("Request Sent")
    //   }).catch(err => {
    //     alert(err?.response?.data?.message || 'Couldn\'t send request.Try again later')
    //   })
    // } else {
    //   setAlertMsg({
    //     open: true,
    //     msg: "Please Verify Your Profile"
    //   })
    // }
  }
  console.log(state, "statestate22")
  // console.log(practitioner?.resource?.identifier?.some(e => e?.value.split('.')[1] === 'abdm'), "4444444444444444")
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <ValidatorForm
          // ref="form"
          onSubmit={() => sub(state,"")}
          onError={(errors) => { }}
        >
          {/* <InputField
                    label="Organisation Name" placeholder="Enter organisation name"
                    value={state.name} name="name" handleChange={handleChange}
                    validators={['required']}
                    errorMessages={['Please Enter Organisation Name']}
                /> */}

          <Grid container spacing={2}>
            {/* <Grid item sm={12} xs={12} style={{ padding: "0px 8px" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={degree}
                  getOptionLabel={(option) =>
                    `${option.code} - ${option.display}`
                  }
                  onChange={(event, newValue) => {
                    handleChange("degree", newValue);
                  }}
                  value={state.degree || null}
                  fullWidth
                  renderInput={(params, data) => (
                    <CssTextField
                      sx={{ mt: "16px" }}
                      {...params}
                      label="Degree"
                      validators={["required"]}
                      errorMessages={["Please Select degree"]}
                      value={state.degree}
                    />
                  )}
                />
              </Grid> */}

            <Grid item xs={12} sm={12} style={{ padding: "0px 8px" }}>
              <Dropdown
                handleOrg={handleOrg}
                submit={submit}
                value={
                  state.organisationName || state.unofficialOrganisationName
                }
                label="Organisation Name"
                name="organisation"
              />
            </Grid>
            <Grid item sm={12} xs={12} style={{ padding: "0px 8px" }}>
              <InputField
                label="Designation"
                placeholder="Enter your Designation"
                value={state.designation}
                name="designation"
                handleChange={handleChange}
                validators={["required"]}
                errorMessages={["Please Enter Your Desgination"]}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} style={{ padding: "0px 8px" }}>
                <Dropdown
                  handleOrg={handleOrg}
                  submit={submit}
                  value={
                    state.organisationName || state.unofficialOrganisationName
                  }
                  label="Organisation Name"
                  name="organisation"
                />
              </Grid> */}

            <Grid item sm={12} xs={12} style={{ padding: "0px 8px" }}>
              <Autocomplete
                options={locations}
                getOptionLabel={(option) => (option ? option : "")}
                isOptionEqualToValue={(option, value) => option === value}
                value={state.city}
                // onChange={(event, value) => {
                //     setDialogValue({ ...dialogValue, addressId: value })
                // }
                // }
                // onChange={handlecity}
                noOptionsText={text}
                onChange={(event, newValue) => {
                  handleChange("city", newValue);
                }}
                onInputChange={(e, value) => getLocation(value)}
                filterOptions={(x) => x}
                renderInput={(params) => (
                  <Drop
                    {...params}
                    margin="dense"
                    label="city"
                    variant="outlined"
                    value={state.city}
                    fullWidth
                  />
                )}
              />
            </Grid>


            <Grid item xs={12} style={{ padding: "10px 10px 20px" }}>
              <Typography className="font-bold">During</Typography>
            </Grid>
            <Grid item sm={6} xs={12} style={{ padding: "0px 8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={dayjs(state.fromDate)}
                  onChange={(value) =>
                    handleChange("fromDate", new Date(value))
                  }
                  // inputFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  views={["day", "month", "year"]}
                  disableFuture
                  renderInput={(params) => (
                    <CssTextField
                      fullWidth
                      {...params}
                      value={params.inputProps.value}
                      validators={["required"]}
                      errorMessages={["Please Select start date"]}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {!check ? (
              <Grid item sm={6} xs={12} style={{ padding: "0px 8px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={dayjs(state.toDate)}
                    onChange={(value) =>
                      handleChange("toDate", new Date(value))
                    }
                    minDate={dayjs(state.fromDate)}
                    // inputFormat="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    disableFuture
                    views={["day", "month", "year"]}
                    // disabled={state.fromDate === null ? true : false}
                    renderInput={(params) => (
                      <CssTextField
                        fullWidth
                        {...params}
                        value={params.inputProps.value}
                        validators={["required"]}
                        errorMessages={["Please Select end date"]}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{ alignItems: "flex-start", mt: 2 }}
                  control={
                    <Checkbox
                      className="r-check"
                      disableRipple
                      checked={check}
                      name="check"
                      onChange={handleCheck}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="To Present"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <div style={{ textAlign: "left", marginTop: "15px" }}>

                {/* <Button onClick={handleRequestRole} disabled={!check} className='capitalize' size='small' sx={{ border: '1px solid lightblue', borderRadius: '5px' }}>
                  Request Role
                </Button> */}

              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ textAlign: "right", marginTop: "15px" }}>
                {submit ? (
                  <Button onClick={handleRequestRole}
                    // disabled={practitionerBool?.resource?.active !== true}
                    // disabled={practitioner !== 'abdm'}
                    disabled={state.organisation == 0 ? true : false}
                    size='small'
                    className='capitalize' >
                    Request Role
                  </Button>
                ) : null}
                {submit ? (
                  <Button type="submit" onClick={handleClose} className='capitalize'>
                    {submit}
                  </Button>
                ) : null}

                <Button type="submit" onClick={cancel} className='capitalize'>
                  close
                </Button>

                {/* {submit ? <Button onClick={cancel}>Cancel</Button> : null} */}
              </div>
              {nextbtn ? (
                <Grid item xs={12} md={12}>
                  <div className="skip_btn" style={{ marginTop: "15px" }}>
                    {/* <Link to={`/addexperience`}> */}
                    <Button
                      className="next__btn"
                      variant="contained"
                      type="submit"
                    // onClick={() => { setAddexp(false); setAddexp1(true) }}
                    >
                      <ArrowForwardIcon
                        style={{
                          color: "white",
                          fontSize: "18px",
                          paddingRight: "10px",
                        }}
                      />
                      next
                    </Button>
                    {/* </Link> */}
                  </div>
                </Grid>
              ) : null}
              {skip ? (
                <div className="skip_btn">
                  {profession === "Medical Student" ? (
                    <Link to={`/`}>
                      <Button
                        style={{
                          color: "#47AEE1",
                          textTransform: "capitalize",
                          margin: "20px 0px",
                        }}
                        type="submit"
                      >
                        Skip
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
      {/* <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={alert.post ? null : 2000}
          open={alert.open}
          onClose={() => setAlert({ open: false, msg: "", post: false })}
          message={alert.msg}
        /> */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={20000}
        open={open}
        onClose={handleClose}
        message="Data Uploaded..."
      //   action={action}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alertMsg.open}
        onClose={handleCloseAlert}
        message={alertMsg.msg}

      />


    </Grid>
  );
}