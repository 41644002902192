import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomValidator } from './customInput';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


export default function DateIn({ maxDate, handleChange, required, errorMessages, ...props }) {

    return (
        <LocalizationProvider
            // dateAdapter={AdapterDateFns}
            dateAdapter={AdapterDayjs}
        >
            <DatePicker
                className='input_shadows input_padding'
                fullWidth
                maxDate={maxDate}
                {...props}
                onChange={value => handleChange(props.name, new Date(value.$d).toISOString())}
                // inputFormat="DD/MM/YYYY"
                format="DD/MM/YYYY"
                renderInput={(params) => <CustomValidator {...params} inputProps={{ ...params.inputProps, readOnly: true }} fullWidth validators={required ? ['required'] : []} errorMessages={errorMessages} {...props} />}
            />
        </LocalizationProvider>
    )
}