import { Grid, Menu, MenuItem, Button, Snackbar, Card, Avatar, Checkbox } from "@mui/material"
import CustomInput from "../inputs/customInput"
import CustomSelect from "../inputs/customSelect"
import PhoneInput from "../inputs/phoneInput"
import RadioInput from "../inputs/RadioInput"
import DateInput from "../inputs/dateInput"
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from "@mui/material"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { useStyles } from "../../common/themes/mui_themes";
import { useStyles } from "../../common/themes/mui_themes";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileValidator2 from "../inputs/FileValidator2"
import TimeInput from "../inputs/timeInput"
import DateInPutIn from "../inputs/DateInputIn"

export const LayoutComp = (props) => {
    return (
        <Box id="prac-reg" className="pv2 ph2 m-auto" sx={{ width: { md: '90%', xs: '100%' } }}>
            {/* <div className="flx-center justify-between">
                <p className="flx-center fntBold cursPoint" onClick={props.back}><ArrowBackIosNewIcon/>Previous Step</p>
                {props.step < 2 && <NextStep onClick={props.next}/>}
            </div> */}
            <div className="mv2">
                <span className="fntBold">Step {props?.step}:</span>&nbsp;
                <span className="txt-clr fntBold">{props?.title}</span>
            </div>
            {props.children}
        </Box>
    )
}

export const FormAccordian = ({ heading, component, ...props }) => {
    const [expanded, setExpanded] = useState(true)
    const styles = useStyles()
    const Component = component

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(prev => !prev)} className="acc_border mv1">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${heading}-content`}
                id={`${heading}-header`}
                classes={{ root: styles.reg_summ_root, content: styles.reg_summ_content }}
                className="acc_shadow"
            >
                <h6 style={{ fontSize: '1.17em' }} className="flx1">{heading}</h6>
            </AccordionSummary>
            <AccordionDetails>
                <Component {...props} />
            </AccordionDetails>
        </Accordion>
    )
}

export const MapComp = ({ data, ...props }) => {
    return (
        data.map((comp, i) => (
            <React.Fragment key={i}>
                <FormAccordian {...comp} {...props} />
            </React.Fragment>
        ))
    )
}

export const AddButton = ({ handleClick, name }) => {
    return (
        <Button className="add_btn flx-center mt1 cursPoint capitalize" onClick={handleClick}><AddCircleOutlineIcon />&nbsp;{name}</Button>
    )
}

export const CustomButton = ({ name, handleClick, ...props }) => {
    return (
        <Button className={`${props.disabled ? "" : "verify"} capitalize`} variant="outlined" onClick={handleClick} {...props}>{name}</Button>
    )
}

export const FormLayout = ({ date, label, sm, size, ...props }) => {
    return (
        <>

            <Grid item md={size ? size : 6} sm={sm || 12} xs={12}>
                {label && <label className="ph1">{label}</label>}
                <InputSelect input={props.type} {...props} />
            </Grid>
        </>
    )
}

export const FormDivider = () => {
    return (
        <Grid item xs={12}>
            <Divider sx={{ my: 1, backgroundColor: 'grey' }} />
        </Grid>
    )
}

export const NextStep = (props) => {
    return (
        <Button className="clr-btn" {...props}>Next Step <KeyboardArrowRightIcon /></Button>
    )
}

export const ProfileLayout = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="fntBold">
                {props.title}
            </Grid>
            {props.children}
        </Grid>
    )
}

export const OrgLayout = ({ date, label, size, comp, ...props }) => {
    return (
        <>
            <Grid item sm={3} xs={comp ? 5 : 12}>
                {label} {props.required && <span style={{ color: 'red' }}>*</span>}
            </Grid>
            <Grid item sm={comp ? 7 : 9} xs={comp ? 9 : 12}>
                <InputSelect {...props} />
            </Grid>
            {comp &&
                <Grid item sm={2} xs={3} className="m-auto">
                    {comp}
                </Grid>}
        </>
    )
}

export const InfoLayout = ({ label, required, ...props }) => {
    return (
        <>
            <Grid item md={6} sm={12} xs={7}>
                {label} {required && <span style={{ color: 'red' }}>*</span>}
            </Grid>
            <Grid item md={6} sm={12} xs={5}>
                <InputSelect {...props} />
            </Grid>
        </>
    )
}
export const GridLayout = ({ label, size, required, ...props }) => {
    return (
        <>
            <Grid item md={size ? size : 4} sm={12} xs={4}>
                {label} {required && <span style={{ color: 'red' }}>*</span>}
            </Grid>
            <Grid item md={size ? true : 8} sm={12} xs={8}>
                <InputSelect {...props} />
            </Grid>
        </>
    )
}

export const InputSelect = ({ input, ...props }) => {
    return (
        <>
            {input === "select" ? <CustomSelect {...props} /> :
                input === "date" ? <DateInput {...props} /> :
                    input === "dateIn" ? <DateInPutIn {...props} /> :
                        input === "phone" ? <PhoneInput {...props} /> :
                            input === 'radio' ? <RadioInput {...props} /> :
                                input === 'radio' ? <RadioInput {...props} /> :
                                    input === "time" ? <TimeInput {...props} /> :
                                        <CustomInput {...props} />}
        </>
    )
}


export const ErrorComponent = (props) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={3000}
            {...props}
        />
    )
}

export const AddPurpose = ({ value, Icon }) => {
    return (<span style={{ height: "100", display: 'flex', justifyContent: 'space-around', textTransform: 'capitalize' }} >{!Icon && <AddCircleIcon sx={{ color: '#00C73D' }} />}<span style={{ display: 'flex' }}>&nbsp;{value || "Purpose"}<KeyboardArrowDownIcon /></span></span>)
}

export const Purpose = ({ name, name_without_public, contact, deleteContact, handleContact, handleType, options, ...props }) => {
    return (
        <>
            {/* <Grid item xs={12}>{name} (for Pubic Display)</Grid> */}
            <Grid item xs={12}>{name ? `${name} (for Public Display)` : name_without_public}</Grid>
            {contact?.map((con, i) => (
                con.system === props.input &&
                <OrgLayout key={i} comp=" "
                    label={<p
                        // className='flx-center justify-around capitalize'
                        style={{ height: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', textTransform: 'capitalize' }}>
                        <RemoveCircleIcon className="cursPoint" sx={{ color: 'red' }} onClick={deleteContact(i)} />
                        {con.use} <KeyboardArrowRightIcon />
                    </p>}
                    name="value" {...props} value={con.value} handleChange={handleContact(i)} />
            ))}
            <Grid item sm={3} xs={5}>
                <DropMenu handleType={handleType} options={options} />
            </Grid>
        </>
    )
}

export const Actions = ({ name, close, disabled }) => {
    return (
        <div style={{ textAlign: 'right', marginTop: "10px" }}>
            <Button onClick={close} className="close-btn" sx={{ padding: "4px 20px !important" }} variant="outlined">Cancel</Button>
            <Button type="submit" sx={{ marginLeft: "20px", padding: "4px 20px !important" }} disabled={disabled} name="save" className="save-btn" variant="contained">{name || "Save"}</Button>
        </div>
    )
}

export const DropMenu = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <span
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                className='cursPoint'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {props.Type ? props.Type : <AddPurpose />}
            </span>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{ minWidth: '180px' }}
            >
                {props.options.map((val, i) => <MenuItem sx={{ padding: '2px 30px' }} key={i} className="capitalize" onClick={() => { props.handleType(val); handleClose() }}>{val?.displayName || val}</MenuItem>)}
            </Menu>
        </>
    )
}

export const FileInput = ({ label, name, value, ...props }) => {

    return (
        <>
            {label && <Grid item xs={12}>{label}</Grid>}
            <Grid item xs={12}>
                <FileValidator2
                    {...props}
                    name={name}
                    id={name}
                    disabled={value.status === 'PENDING'}
                    type="file"
                    value={value.status === 'PENDING' ? value.path + "." + value.value?.split('/')[value.value?.split('/').length - 1]?.split('.')[1] : `${value.path ? value.path.split('.')[0].substring(0, 15) + "." + value.path.split('.')[1] : ''}`}
                    validators={['required', 'allowedExtension']}
                    errorMessages={['*Please Upload document', 'Only .png,.jpg,.jpeg,.pdf extensions allowed']}
                />
            </Grid>
        </>
    )
}

export const RegForm = ({ registerForm, handleDetails }) => {
    return (
        <Grid container alignItems={'center'} spacing={2} className="pt1">
            <OrgLayout label="Full Name" value={registerForm.name} name="name" handleChange={handleDetails} placeholder="Enter Patient Name" />
            <OrgLayout input="date" label="Date of Birth" name="birthDate" handleChange={handleDetails} value={registerForm.birthDate} maxDate={new Date()} />
            <OrgLayout input="phone" label="Phone" name="phone" handleChange={handleDetails} value={registerForm.phone} required errorMessages={['Enter Phone Number']} />
            <Grid item sm={3} xs={12}>
                <p className="pr1 mv0">Gender</p>
            </Grid>
            <Grid item sm={9} xs={12}>
                <div className="opt_btn flx-center justify-evenly mv0">
                    {['male', 'female', 'others'].map((gender, i) =>
                        <Button name="gender" key={i} className={registerForm.gender === gender ? 'opt_select' : 'flx1'} onClick={() => handleDetails('gender', gender)} size="small">{gender}</Button>
                    )}
                </div>
            </Grid>
        </Grid>
    )
}

export const ViewPatient = ({ usersInfo }) => {

    const [checked, setChecked] = React.useState(null);

    const handleChangeCheck = (val) => (event) => {
        if (event.target.checked)
            setChecked(val);
        else setChecked(null)
    };
    return (
        usersInfo.map((option, i) => {
            return (
                <Card className="mv1 mh1" sx={{ backgroundColor: '#F4F4F4' }} key={i}>
                    <Grid container alignItems={'center'} className="ph1 pv0-5">
                        <Grid item xs={2}>
                            <Avatar className="m-auto" sx={{ borderRadius: '10px !important', width: '55px !important', height: '55px !important' }} src={''} />
                        </Grid>
                        <Grid item xs={6}>
                            <div className='fntMd capitalize '>{option?.name?.[0]?.text} <span className="pl-10 capitalize">{option?.gender ? `(${option?.gender?.charAt(0)})` : ''}</span> </div>
                            <div className='fntSm'>Patient ID:</div>
                            <div className='fntSm'>Phone: {option?.telecom?.[0]?.value}</div>
                        </Grid>
                        <Grid item xs={4}>

                            <div className='fntSm capitalize tar'>PHR: {option?.identifier?.[1]?.value || "unavailable"}</div>

                            <div className="tar">

                                <Checkbox
                                    checked={checked === i}
                                    onChange={handleChangeCheck(i)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>

                        </Grid>
                    </Grid>
                </Card>
            )
        })
    )
}


export function OutlinedButton(props) {
    return (
        <div>
            <Button variant="outlined" sx={{ mt: props.mt, width: props.width, border: props.border, textTransform: 'capitalize', color: props.color, "&:hover": { border: props.border, borderColor: props.borderColor }, height: props.height, borderRadius: props.borderRadius, borderColor: props.borderColor }} onClick={props.onClick} disabled={props.disabled} startIcon={props.startIcon} endIcon={props.endIcon}>
                <Typography sx={{ fontSize: props.fontSize, fontWeight: 500 }}> {props.text}</Typography>
            </Button></div >
    )
}