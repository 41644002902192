import React from 'react'
import ebmpractice_kit from '../Atom/modifiedicons/ebmpractice_kit.svg';
import drug_info from '../Atom/modifiedicons/drug_info.svg';
import video_guides from '../Atom/modifiedicons/video_guides.svg';
import standard from '../Atom/modifiedicons/standard.svg';
import dose_calculator from '../Atom/modifiedicons/dose_calculator.svg';
import bio_calculator from '../Atom/modifiedicons/bio_calculator.svg';
import clinical_calculator from '../Atom/modifiedicons/clinical_calculator.svg';
import check_list from '../Atom/modifiedicons/check_list.svg';
import patient_leaflets from '../Atom/modifiedicons/patient-leaflets.png';
import try_now from '../Atom/modifiedicons/try-now.png';
import learn_more from '../Atom/modifiedicons/learn-more.png';
import { Button } from '@mui/material';
import CarouselComponent2 from './CarouselComponent2';


const arrdata = [
    {
        id: '1',
        icon: ebmpractice_kit,
        heading: "Evidience Review",
        subtext: "Evidence based Disease information and management guide backed by Cochrane protocols, systematic reviews"
    },
    {
        id: '2',
        icon: drug_info,
        heading: "Drug Information",
        subtext: "High quality, in-depth information on Indications, safety, kinetics and more.All you need to know"
    },
    {
        id: '3',
        icon: video_guides,
        heading: "Video Guides",
        subtext: "Procedure videos,educational content for care givers.Expert opinions, clinical discussion and many more"
    },
    {
        id: '4',
        icon: patient_leaflets,
        heading: "Patient Leaflets",
        subtext: "Multi-lingual Patient Information  material to educate your patient on do’s & don’t in their condition and treatment"
    },
    {
        id: '5',
        icon: standard,
        heading: "Standard Practices",
        subtext: "Clinical and administrative standards meant to be implemented for efficient and legally binding practices"
    },
    {
        id: '6',
        icon: check_list,
        heading: "Check-lists",
        subtext: "Never miss-out any thing a detailed checklist for better quality of care is need of the hour"
    },
    {
        id: '7',
        icon: dose_calculator,
        heading: "Dose Calculators",
        subtext: "A tools for safe and effective prescribing. Calculators and nomograms for dosing various narrow therapeutic index drugs"
    },
    {
        id: '8',
        icon: bio_calculator,
        heading: "Biomedical Calc",
        subtext: "Efficiently evaluate biomedical parameters to its clinical relevance.A set of mathematical tools useful in day to day practice"
    },
    {
        id: '9',
        icon: clinical_calculator,
        heading: "Assessment Tools",
        subtext: "Assess the patient condition, with standard tools that can help you with scoring and grading the clinical status"
    },
]

function EPracticeCard() {
    return (<>
        <div className='e-practice-card-container-wrapper'>


            <div className='e-practice-card-container'>
                {arrdata?.map((item) => {
                    return <div className='epractice-parent' key={item?.id}>
                        <div className='epractice-child'>
                            <img src={item?.icon} alt={item?.heading} />
                            <h5>{item?.heading}</h5>
                            <p>{item?.subtext}</p>
                            <div className='try-now'>
                                <p>Try Now</p>
                                <img src={try_now} alt='try now' />
                            </div>
                        </div>
                        <div className='learn-more'>
                            <p>Learn More</p>
                            <img src={learn_more} alt='learn more' />
                        </div>
                    </div>
                })}

            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button variant='contained' className='e-practice-bottom-button'>View All</Button>

            </div>
        </div>
        <div className='e-practice-carousel-wrapper'>
            <CarouselComponent2 />
        </div>
    </>

    )
}

export default EPracticeCard